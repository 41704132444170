/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import { CheckOutlined, FilterFilled, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal, Select, Spin, Table, Tooltip } from 'antd';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import _ from 'lodash';
import { StoreProductTrackingModal } from 'modals';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { StockIcon } from 'theme';
import { fetchAPI, foundStringParser, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

const mapStateToProps = state => ({
    brands: selectBrands(state),
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {
    fetchBrands,
    fetchWarehouses
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StockProductsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fetched: false,
            dataSource: [],
            storeFilter: undefined,
            brandFilter: undefined,
            codeFilter: undefined,
            attributesFilters: [],
            inStock: false,
            reserveModalVisible: false,
            listBrands: [],
            page: 1,
            total: 0,
            pageSize: 15
        };

        this.debouncedQuerySearch = _.debounce(() => {
            this.fetchData();
        }, 1000).bind(this);

        this.handleBrandsSearch = _.debounce(value => {
            this.fetchStorageBrands(value);
        }, 1000);

        this.columns = [
            {
                key: 'actions',
                render: row => {
                    return (
                        !row.emptyRow && (
                            <div className='actions'>
                                <Tooltip title={<FormattedMessage id='select' />} zIndex={2001}>
                                    <Button
                                        data-qa='button_handle_ok_select_order_detail_modal'
                                        icon={<CheckOutlined />}
                                        onClick={() => {
                                            this.handleOk(row);
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        )
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'code',
                ...this.getColumnSearchProps('filterCode'),
                render: row => {
                    const code = row.code || row.partNumber;
                    if (row.emptyRow) {
                        return (
                            <FormattedMessage
                                id={
                                    this.state.mode === 0
                                        ? 'order_form_table.directory_spare_parts'
                                        : this.state.mode === 2
                                        ? 'order_form_table.choose_the_desired_product'
                                        : 'order_form_table.replacements_cross_references'
                                }
                            />
                        );
                    }

                    return (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700,
                                    textDecoration: code && 'underline'
                                }}
                            >
                                {code}
                            </div>
                            <div style={{ fontSize: 12 }}>{row.name || row.description}</div>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brand',
                ...this.getColumnSearchProps('filterBrand'),
                render: row => {
                    const code = row.code || row.partNumber;

                    return (
                        <div
                            style={{
                                fontWeight: 700,
                                textDecoration: code && 'underline'
                            }}
                        >
                            {row.brandName}
                        </div>
                    );
                }
            },
            {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='storage' /> / <FormattedMessage id='wms.cell' />
                    </React.Fragment>
                ),
                key: 'businessSupplierName',
                dataIndex: 'businessSupplierName',
                render: (data, elem) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Input
                                disabled
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.supplier'
                                })}
                                style={{ maxWidth: 180, color: 'black' }}
                                value={elem.cellAddress || data}
                            />
                            <DetailWarehousesCountModal
                                onSelect={async (cellAddress, warehouseId, warehouseName) => {
                                    elem.cellAddress = cellAddress;
                                    elem.warehouseId = warehouseId;
                                    await this.setState({});
                                    await this.handleOk(elem);
                                }}
                                productId={elem.id}
                            />
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <span>
                                <FormattedMessage id='storage.in_stock' /> / <FormattedMessage id='storage.available' />
                            </span>
                            <div style={{ fontWeight: '400', fontSize: 12 }}>
                                <FormattedMessage id='in_stock' />
                                <Checkbox
                                    checked={this.state.inStock}
                                    onChange={async () => {
                                        await this.setState({
                                            inStock: !this.state.inStock
                                        });
                                        this.fetchData();
                                    }}
                                    style={{
                                        margin: 8
                                    }}
                                />
                            </div>
                        </div>
                    );
                },
                key: 'store',
                dataIndex: 'store',
                ...this.getColumnSearchProps('filterWarehouse'),
                render: (store, elem) => {
                    return (
                        <span
                            onClick={() =>
                                this.setState({
                                    reserveModalVisible: elem.id
                                })
                            }
                            style={{
                                color: 'var(--link)',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                        >
                            {elem.countInWarehouses} / {elem.available}
                        </span>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                align: 'right',
                render: data => {
                    const strVal = Number((data * 10) / 10).toFixed(2);

                    return data ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'salePrice',
                dataIndex: 'salePrice',
                align: 'right',
                render: data => {
                    const strVal = Number((data * 10) / 10).toFixed(2);

                    return data ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            }
        ];
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ confirm, clearFilters }) => {
            let filter = (
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    onChange={e => {
                        this.setState({
                            [dataIndex]: e.target.value
                        });
                    }}
                    onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                    placeholder={this.props.intl.formatMessage({
                        id: 'search'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.state[dataIndex]}
                />
            );
            if (dataIndex === 'filterBrand') {
                filter = (
                    <Select
                        allowClear
                        
                        onChange={value => {
                            this.setState({
                                filterBrand: value
                            });
                        }}
                        onFocus={() => this.fetchStorageBrands()}
                        onSearch={(input) => this.handleBrandsSearch(input)}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={this.state.filterBrand}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={this.props.intl.formatMessage({
                            id: 'search'
                        })}
                    >
                        {this.state.loadingBrands ? (
                            <Option>
                                <FormattedMessage id='client-mrds-table.data_missing' />
                            </Option>
                        ) : (
                            this.state.listBrands &&
                            this.state.listBrands.map(({ brandId, brandName }) => (
                                <Option key={brandId} value={brandId}>
                                    {brandName}
                                </Option>
                            ))
                        )}
                    </Select>
                );
            }

            if (dataIndex === 'filterWarehouse') {
                filter = (
                    <Select
                        allowClear
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                            zIndex: '9999',
                            minWidth: 220
                        }}
                        onChange={value => {
                            this.setState({
                                filterWarehouse: value
                            });
                        }}
                        placeholder={this.props.intl.formatMessage({ id: 'storage' })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={this.state.filterWarehouse}
                    >
                        {this.props.warehouses.map(warehouse => (
                            <Option key={warehouse.id} value={warehouse.id} warehouse_attribute={warehouse.attribute}>
                                {warehouse.name}
                            </Option>
                        ))}
                    </Select>
                );
            }

            return (
                <div style={{ padding: 8 }}>
                    {filter}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <Button
                            icon={<SearchOutlined style={{ marginRight: 4 }} />}
                            onClick={() => this.handleSearch(confirm, dataIndex)}
                            size='small'
                            type='primary'
                        >
                            <FormattedMessage id='search' />
                        </Button>
                        <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                            <FormattedMessage id='reset' />
                        </Button>
                    </div>
                </div>
            );
        },
        filterIcon: () => (
            <FilterFilled
                style={{
                    fontSize: 14,
                    color: this.state[dataIndex] ? 'var(--primary)' : undefined
                }}
            />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        }
    });

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.fetchData();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({ [dataIndex]: undefined, page: 1 });
        this.fetchData();
    };

    handleOk(elem) {
        const supplierBrandId = elem.supplierBrandId
            ? elem.supplierBrandId
            : elem.price
            ? elem.price.supplierBrandId
            : undefined;
        const brandId = elem.brandId ? elem.brandId : elem.price ? elem.price.brandId : undefined;
        const name = elem.storeGroupId === 1000000 ? elem.description : elem.storeGroupName;
        const supplierOriginalCode = elem.price ? elem.price.supplierOriginalCode : undefined;
        const supplierProductNumber = elem.price ? elem.price.supplierProductNumber : undefined;
        const supplierPartNumber = elem.price ? elem.price.supplierPartNumber : undefined;
        const isFromStock = elem.price ? elem.price.isFromStock : undefined;
        const defaultWarehouseId = elem.price ? elem.price.defaultWarehouseId : undefined;

        if (this.props.onSelect) {
            this.props.onSelect(
                elem.partNumber,
                brandId,
                elem.productId,
                this.props.tableKey,
                elem.storeGroupId,
                name,
                supplierOriginalCode,
                supplierProductNumber,
                supplierPartNumber
            );
        }
        if (this.props.setSupplier) {
            this.props.setSupplier(
                elem.businessSupplierId,
                elem.businessSupplierName,
                supplierBrandId,
                elem.purchasePrice,
                elem.salePrice,
                elem.store,
                supplierOriginalCode,
                supplierProductNumber,
                supplierPartNumber,
                this.props.tableKey,
                isFromStock,
                defaultWarehouseId,
                elem.productId,
                brandId,
                elem.cellAddress,
                elem.warehouseId
            );
        }
        if (this.props.selectProduct) {
            this.props.selectProduct({
                ...elem,
                code: elem.partNumber,
                brandId
            });
        }
        this.handleCancel();
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            fetched: false,
            dataSource: [],
            brandOptions: [],
            brandFilter: undefined,
            storeFilter: undefined,
            storeOptions: [],
            codeFilter: undefined,
            attributesFilters: [],
            inStock: false,
            page: 1
        });
        if (this.props.hideModal) this.props.hideModal();
    };

    fetchStorageBrands = async queryBrands => {
        await this.setState({ loadingBrands: true });
        try {
            const { filterCode, filterBrand, filterWarehouse, query, inStock } = this.state;
            const { result, count } = await fetchAPI(
                'GET',
                '/store_products_brands',
                {
                    query: foundStringParser(query),
                    warehouseId: filterWarehouse,
                    onlyAvailable: inStock,
                    productCode: filterCode,
                    brandId: filterBrand,
                    filterBrand,

                    queryBrands

                    // filterWarehouse: this.getDetailWarehouses()
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            this.setState({
                listBrands: result,
                loadingBrands: false
            });
        } catch (e) {
            this.setState({ loadingBrands: false });
        }
    };

    fetchData = async () => {
        const {
            intl: { formatMessage }
        } = this.props;
        const { page, pageSize, filterCode, filterBrand, filterWarehouse, inStock, query } = this.state;

        try {
            const {
                list,
                listBrands,
                stats: { count }
            } = await fetchAPI(
                'GET',
                'store_products',
                {
                    page,
                    pageSize,
                    query: foundStringParser(query),
                    warehouseId: filterWarehouse,
                    onlyAvailable: inStock,
                    productCode: filterCode,
                    brandId: filterBrand
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            const dataSource = list.map(item => ({
                ...item,
                productId: item.id,
                supplierId: _.get(item, 'brand.id'),
                supplierName: _.get(item, 'brand.name'),
                businessSupplierId: 0,
                businessSupplierName: formatMessage({
                    id: 'navigation.storage'
                }),
                purchasePrice: item.stockPrice,
                salePrice: item.sellingPrice,
                partNumber: item.code,
                description: item.name,
                storeGroupId: item.groupId,
                storeGroupName: item.name,
                store: [item.available, 0, 0, 0]
            }));

            this.setState({
                fetched: true,
                dataSource,
                total: count
            });
        } catch (e) {
            this.setState({ fetched: true });
        }
    };

    componentDidMount() {
        this.props.fetchBrands();
        this.props.fetchWarehouses();
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.visible && !prevProps.visible) {
            await this.setState({
                visible: true,
                query: this.props.codeFilter
            });
            this.fetchData();
        }
    };

    render() {
        const {
            hideButton,
            codeFilter,
            intl: { formatMessage }
        } = this.props;
        const { dataSource, reserveModalVisible, page, pageSize, total, query } = this.state;
        const disabled =
            this.props.disabled ||
            isForbidden(this.props.user, permissions.ACCESS_STOCK) ||
            isForbidden(this.props.user, permissions.ACCESS_TECDOC_MODAL_WINDOW);

        return (
            <div style={{ display: 'flex' }}>
                {!hideButton && (
                    <Tooltip title={<FormattedMessage id='details_table.details_catalogue' />} zIndex={2001}>
                        <Button
                            disabled={disabled}
                            icon={<StockIcon />}
                            onClick={async () => {
                                await this.setState({
                                    visible: true,
                                    query: codeFilter
                                });
                                this.fetchData();
                            }}
                        />
                    </Tooltip>
                )}
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='order_form_table.catalog' />}
                    visible={this.state.visible}
                    width='90%'
                >
                    <Input.Search
                        allowClear
                        onChange={e => {
                            this.setState({ query: e.target.value, page: 1 });
                            this.debouncedQuerySearch(e.target.value);
                        }}
                        placeholder={formatMessage({ id: 'search' })}
                        style={{
                            marginBottom: 16
                        }}
                        value={query}
                    />
                    {this.state.fetched ? (
                        <Table
                            bordered
                            columns={this.columns}
                            dataSource={dataSource}
                            getPopupContainer={trigger => trigger.parentNode}
                            pagination={{
                                pageSize,
                                total,
                                hideOnSinglePage: true,
                                current: page,
                                onChange: async (page, pageSize) => {
                                    await this.setState({ page, pageSize });
                                    this.fetchData();
                                }
                            }}
                            rowClassName={Styles.tableRow}
                            rowKey='id'
                            size='small'
                        />
                    ) : (
                        <Spin indicator={spinIcon} />
                    )}
                </Modal>
                <StoreProductTrackingModal
                    hideModal={() => {
                        this.setState({
                            reserveModalVisible: undefined
                        });
                    }}
                    productId={reserveModalVisible}
                    visible={Boolean(reserveModalVisible)}
                />
            </div>
        );
    }
}

export class DetailWarehousesCountModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            warehousesData: []
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false
        });
        if (this.props.hideModal) this.props.hideModal();
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.fetchData();
            this.setState({
                visible: true
            });
        }
    }

    fetchData = async () => {
        let warehousesData = [];

        const product = await fetchAPI('GET', `store_products/${this.props.productId}`);

        const warehouses = await fetchAPI('GET', 'warehouses');
        warehouses.forEach(({ id, name, attribute }) => {
            warehousesData.push({
                id,
                name,
                count: 0,
                attribute,
                childs: [
                    {
                        // cellAddress: `${id}.`,
                        count: 0
                    }
                ]
            });
        });

        const productWarehouses = await fetchAPI('GET', `store_products/${this.props.productId}/warehouses`);

        for (const [key, value] of Object.entries(productWarehouses)) {
            const index = warehousesData.findIndex(({ id }) => id == key);
            warehousesData[index].count = Number(value);
            warehousesData[index].childs[0].count = Number(value);
        }

        const payload = await fetchAPI('GET', 'wms/cells/statuses', {
            storeProductId: this.props.productId
        });

        payload.list.map(elem => {
            if (elem.warehouse.id) {
                const index = warehousesData.findIndex(({ id }) => id == elem.warehouse.id);
                warehousesData[index].count += Number(elem.sum);
                warehousesData[index].childs.push({
                    cellAddress: elem.wmsCellOptions.address,
                    count: Number(elem.sum)
                });
            }
        });
        warehousesData = warehousesData.filter(({ count }) => count > 0);

        await this.setState({
            warehousesData,
            code: _.get(product, 'code'),
            brandName: _.get(product, 'brand.name'),
            name: _.get(product, 'name')
        });
    };

    render() {
        const { code, name, brandName, warehousesData, visible } = this.state;

        return (
            <div>
                {!this.props.hideButton && (
                    <Button
                        icon={<StockIcon />}
                        onClick={() => {
                            this.fetchData();
                            this.setState({
                                visible: true
                            });
                        }}
                    />
                )}
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    style={{
                        maxWidth: 480,
                        fontSize: 16
                    }}
                    title={<FormattedMessage id='storage.in_stock' />}
                    visible={visible}
                >
                    <div
                        style={{
                            padding: '0px 4px 14px',
                            fontSize: 16,
                            fontWeight: 500
                        }}
                    >
                        <p>{brandName}</p>
                        <p>{code}</p>
                    </div>
                    <div style={{ display: 'flex', fontWeight: 500, padding: 8 }}>
                        <div style={{ width: '25%' }}>
                            <FormattedMessage id='storage' />
                        </div>
                        <div style={{ width: '25%' }}>
                            <FormattedMessage id='wms.cell' />
                        </div>
                        <div style={{ width: '25%' }}>
                            <FormattedMessage id='count' />
                        </div>
                    </div>
                    {warehousesData.map((warehouse, key) => {
                        return (
                            <React.Fragment>
                                <div
                                    key={warehouse.id}
                                    style={{
                                        display: 'flex',
                                        borderTop: '1px solid gray',
                                        padding: '2px 8px',
                                        fontWeight: 500,
                                        alignItems: 'center'
                                    }}
                                >
                                    <div style={{ width: '25%' }}>{warehouse.name}</div>
                                    <div style={{ width: '25%' }} />
                                    <div style={{ width: '25%' }}>{Number(warehouse.count).toFixed(1)}</div>
                                    <Button
                                        onClick={() => {
                                            this.handleCancel();
                                            if (this.props.onSelect) {
                                                this.props.onSelect(undefined, warehouse.id, warehouse.name);
                                            }
                                        }}
                                        style={{
                                            opacity: 0,
                                            pointerEvents: 'none'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='select' />
                                    </Button>
                                </div>
                                {warehouse.childs.map(elem => (
                                    <div
                                        key={elem.cellAddress}
                                        style={{
                                            display: 'flex',
                                            padding: '2px 8px',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ width: '25%' }}>{warehouse.name}</div>
                                        <div style={{ width: '25%' }}>{elem.cellAddress}</div>
                                        <div style={{ width: '25%' }}>{Number(elem.count).toFixed(1)}</div>
                                        <Button
                                            disabled={_.get(warehouse, 'attribute') == 'RESERVE'}
                                            onClick={() => {
                                                this.handleCancel();
                                                if (this.props.onSelect) {
                                                    this.props.onSelect(elem.cellAddress, warehouse.id, warehouse.name);
                                                }
                                            }}
                                            type='primary'
                                        >
                                            <FormattedMessage id='select' />
                                        </Button>
                                    </div>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </Modal>
            </div>
        );
    }
}
