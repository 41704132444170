export const PRD = {
    "order": {
        "num": {
            "ua": "номер документа",
            "en": "document number",
            "cz": "číslo dokumentu",
            "bg": "номер на документа"
        },
        "roundedFiscalSum": {
            "ua": "округлена фіскальна сума",
            "en": "rounded fiscal amount",
            "cz": "zaokrouhlená fiskální suma",
            "bg": "окръглена фискална сума"
        },
        "tax": {
            "ua": " ПДВ",
            "en": " VAT",
            "cz": " DPH",
            "bg": " ДДС"
        },
        "detailsTotalSum": {
            "ua": "загальна сума деталей",
            "en": "total sum of items",
            "cz": "celková suma položek",
            "bg": "обща сума на детайли"
        },
        "servicesTotalSum": {
            "ua": "загальна сума послуг",
            "en": "total sum of services",
            "cz": "celková suma služeb",
            "bg": "обща сума на услуги"
        },
        "totalSumWithDiscount": {
            "ua": "загальна сума зі знижкою",
            "en": "total sum with discount",
            "cz": "celková suma se slevou",
            "bg": "обща сума със отстъпка"
        },
        "totalSumWithTax": {
            "ua": "загальна сума з податком ПДВ",
            "en": "total sum with VAT",
            "cz": "celková suma s DPH",
            "bg": "обща сума с ДДС"
        },
        "totalSum": {
            "ua": "загальна сума",
            "en": "total amount",
            "cz": "celková suma",
            "bg": "обща сума"
        },
        "barcode": {
            "ua": "штрих-код",
            "en": "barcode",
            "cz": "čárový kód",
            "bg": "баркод"
        }
    },
    "business": {
        "name": {
            "ua": "назва станції",
            "en": "station name",
            "cz": "název stanice",
            "bg": "име на станцията"
        },
        "address": {
            "ua": "адреса",
            "en": "address",
            "cz": "adresa",
            "bg": "адрес"
        },
        "phones": {
            "ua": "телефони",
            "en": "phones",
            "cz": "telefony",
            "bg": "телефони"
        },
        "logo": {
            "original": {
                "path": {
                    "ua": "посилання",
                    "en": "link",
                    "cz": "odkaz",
                    "bg": "връзка"
                },
                "width": {
                    "ua": "ширина",
                    "en": "width",
                    "cz": "šířka",
                    "bg": "ширина"
                },
                "height": {
                    "ua": "висота",
                    "en": "height",
                    "cz": "výška",
                    "bg": "височина"
                }
            }
        },
        "primaryLogoUrl": {
            "ua": "URL посилання основного логотипу",
            "en": "URL link for primary logo",
            "cz": "URL odkaz hlavního loga",
            "bg": "URL връзка за основен логотип"
        },
        "secondaryLogoUrl": {
            "ua": "URL посилання додаткового логотипу",
            "en": "URL link for secondary logo",
            "cz": "URL odkaz vedlejšího loga",
            "bg": "URL връзка за допълнителен логотип"
        },
        "domain": {
            "ua": "домен",
            "en": "domain",
            "cz": "doména",
            "bg": "домейн"
        },
        "normHourPrice": {
            "ua": "ціна за нормо-годину",
            "en": "price per normal hour",
            "cz": "cena za normo-hodinu",
            "bg": "цена за нормо-час"
        },
        "phoneCode": [
            {
                "code": {
                    "ua": "код",
                    "en": "code",
                    "cz": "kód",
                    "bg": "код"
                },
                "number": {
                    "ua": "номер",
                    "en": "number",
                    "cz": "číslo",
                    "bg": "номер"
                },
                "country": {
                    "ua": "країна",
                    "en": "country",
                    "cz": "země",
                    "bg": "държава"
                }
            }
        ],
        "packageStatus": {
            "ua": "статус пакета",
            "en": "package status",
            "cz": "status balíčku",
            "bg": "статус на пакета"
        },
        "currency": {
            "ua": "валюта",
            "en": "currency",
            "cz": "měna",
            "bg": "валута"
        },
        "base64PrimaryLogo": {
            "ua": "основний логотип у форматі Base64",
            "en": "primary logo in Base64 format",
            "cz": "hlavní logo v Base64 formátu",
            "bg": "основен логотип в Base64 формат"
        },
        "printCode": {
            "ua": "код друку",
            "en": "print code",
            "cz": "tiskový kód",
            "bg": "код за печат"
        },
        "printBrand": {
            "ua": "друк бренду",
            "en": "brand print",
            "cz": "tisk značky",
            "bg": "печат на марка"
        },
        "printClientNameAndTel": {
            "ua": "друк імені клієнта та телефону",
            "en": "print client name and phone",
            "cz": "tisk jména klienta a telefonu",
            "bg": "печат на името на клиента и телефона"
        },
        "discountRow": {
            "ua": "рядок зі знижкою",
            "en": "discount row",
            "cz": "řádek se slevou",
            "bg": "ред с отстъпка"
        },
        "quantityDecimalPlaces": {
            "ua": "кількість десяткових знаків",
            "en": "number of decimal places",
            "cz": "počet desetinných míst",
            "bg": "броя на десетичните знаци"
        },
        "priceDecimalPlaces": {
            "ua": "десяткові знаки для цін",
            "en": "decimal places for prices",
            "cz": "desetinná místa pro ceny",
            "bg": "десетични знаци за цени"
        },
        "sumDecimalPlaces": {
            "ua": "десяткові знаки для сум",
            "en": "decimal places for amounts",
            "cz": "desetinná místa pro součty",
            "bg": "десетични знаци за суми"
        }
    },
    "doc": {
        "id": {
            "ua": "id ідентифікатор",
            "en": "id identifier",
            "cz": "id identifikátor",
            "bg": "id идентификатор"
        },
        "sellingPrice": {
            "ua": "ціна продажу",
            "en": "selling price",
            "cz": "cena prodeje",
            "bg": "цена на продажба"
        },
        "purchasePrice": {
            "ua": "ціна закупівлі",
            "en": "purchase price",
            "cz": "cena nákupu",
            "bg": "цена на закупуване"
        },
        "lastPurchasePrice": {
            "ua": "остання ціна закупівлі",
            "en": "last purchase price",
            "cz": "poslední cena nákupu",
            "bg": "последна цена на закупуване"
        },
        "minMarkup": {
            "ua": "мінімальна націнка",
            "en": "minimum markup",
            "cz": "minimální marže",
            "bg": "минимална надценка"
        },
        "minMargin": {
            "ua": "мінімальний прибуток",
            "en": "minimum profit",
            "cz": "minimální zisk",
            "bg": "минимална печалба"
        },
        "markup": {
            "ua": "націнка",
            "en": "markup",
            "cz": "přirážka",
            "bg": "надценка"
        },
        "margin": {
            "ua": "прибуток",
            "en": "profit",
            "cz": "zisk",
            "bg": "печалба"
        },
        "defaultWarehouseName": {
            "ua": "назва складу за замовчуванням",
            "en": "default warehouse name",
            "cz": "výchozí název skladu",
            "bg": "име на склада по подразбиране"
        },
        "barcode": {
            "ua": "штрих-код",
            "en": "barcode",
            "cz": "čárový kód",
            "bg": "баркод"
        },
        "markupName": {
            "ua": "назва націнки",
            "en": "markup name",
            "cz": "název přirážky",
            "bg": "име на надценка"
        },
        "markupUsing": {
            "ua": "використання націнки",
            "en": "use of markup",
            "cz": "využití přirážky",
            "bg": "използване на надценка"
        },
        "sellingCurrency": {
            "ua": "валюта продажу",
            "en": "selling currency",
            "cz": "měna prodeje",
            "bg": "валута на продажба"
        },
        "purchaseCurrency": {
            "ua": "валюта закупівлі",
            "en": "purchase currency",
            "cz": "měna nákupu",
            "bg": "валута на закупуване"
        },
        "groupId": {
            "ua": "id ідентифікатор групи",
            "en": "id identifier of group",
            "cz": "id identifikátor skupiny",
            "bg": "ид идентификатор на група"
        },
        "name": {
            "ua": "назва",
            "en": "name",
            "cz": "název",
            "bg": "име"
        },
        "code": {
            "ua": "код",
            "en": "code",
            "cz": "kód",
            "bg": "код"
        },
        "brandId": {
            "ua": "id ідентифікатор бренду",
            "en": "id identifier of brand",
            "cz": "id identifikátor značky",
            "bg": "ид идентификатор на марка"
        },
        "brandName": {
            "ua": "назва бренду",
            "en": "brand name",
            "cz": "název značky",
            "bg": "име на марка"
        },
        "unit": {
            "ua": "одиниця",
            "en": "unit",
            "cz": "jednotka",
            "bg": "единица"
        },
        "measureUnit": {
            "ua": "одиниця виміру",
            "en": "unit of measure",
            "cz": "měrná jednotka",
            "bg": "единица за измерване"
        },
        "tradeCode": {
            "ua": "торговий код",
            "en": "trade code",
            "cz": "obchodní kód",
            "bg": "търговски код"
        },
        "certificate": {
            "ua": "сертифікат",
            "en": "certificate",
            "cz": "certifikát",
            "bg": "сертификат"
        },
        "businessId": {
            "ua": "id ідентифікатор станції",
            "en": "id identifier of station",
            "cz": "id identifikátor stanice",
            "bg": "ид идентификатор на станцията"
        },
        "defaultBusinessSupplierId": {
            "ua": "id ідентифікатор постачальника за замовчуванням",
            "en": "id identifier of default supplier",
            "cz": "id identifikátor dodavatele pro výchozí",
            "bg": "ид идентификатор на доставчик по подразбиране"
        },
        "saveOnStock": {
            "ua": "зберігати на складі",
            "en": "store in stock",
            "cz": "uchovávat na skladu",
            "bg": "съхраняване на склад"
        },
        "min": {
            "ua": "мінімум",
            "en": "minimum",
            "cz": "minimálně",
            "bg": "минимум"
        },
        "max": {
            "ua": "максимум",
            "en": "maximum",
            "cz": "maximálně",
            "bg": "максимум"
        },
        "defaultWarehouseId": {
            "ua": "id ідентифікатор складу за замовчуванням",
            "en": "id identifier of default warehouse",
            "cz": "id identifikátor skladu pro výchozí",
            "bg": "ид идентификатор на склада по подразбиране"
        },
        "multiplicity": {
            "ua": "Кратність",
            "en": "Multiplicity",
            "cz": "Množství",
            "bg": "Множественост"
        },
        "externalId": {
            "ua": "зовнішній ідентифікатор",
            "en": "external identifier",
            "cz": "externí identifikátor",
            "bg": "външен идентификатор"
        },
        "height": {
            "ua": "висота",
            "en": "height",
            "cz": "výška",
            "bg": "височина"
        },
        "width": {
            "ua": "ширина",
            "en": "width",
            "cz": "šířka",
            "bg": "ширина"
        },
        "depth": {
            "ua": "глибина",
            "en": "depth",
            "cz": "hloubka",
            "bg": "дълбочина"
        },
        "volume": {
            "ua": "обсяг",
            "en": "volume",
            "cz": "objem",
            "bg": "обем"
        },
        "weight": {
            "ua": "вага",
            "en": "weight",
            "cz": "váha",
            "bg": "тегло"
        },
        "length": {
            "ua": "довжина",
            "en": "length",
            "cz": "délka",
            "bg": "дължина"
        },
        "internalDiameter": {
            "ua": "внутрішній діаметр",
            "en": "internal diameter",
            "cz": "vnitřní průměr",
            "bg": "вътрешен диаметър"
        },
        "externalDiameter": {
            "ua": "зовнішній діаметр",
            "en": "external diameter",
            "cz": "vnější průměr",
            "bg": "външен диаметър"
        },
        "colorId": {
            "ua": "id ідентифікатор кольору",
            "en": "id identifier of color",
            "cz": "id identifikátor barvy",
            "bg": "ид идентификатор на цвят"
        },
        "material": {
            "ua": "матеріал",
            "en": "material",
            "cz": "materiál",
            "bg": "материал"
        },
        "comment": {
            "ua": "коментар",
            "en": "comment",
            "cz": "komentář",
            "bg": "коментар"
        },
        "condition": {
            "ua": "стан",
            "en": "condition",
            "cz": "stav",
            "bg": "състояние"
        },
        "usedInVehicles": {
            "ua": "використовується у транспортних засобах",
            "en": "used in vehicles",
            "cz": "používá se v dopravních prostředcích",
            "bg": "използва се в транспортни средства"
        },
        "right": {
            "ua": "правий",
            "en": "right",
            "cz": "pravý",
            "bg": "десен"
        },
        "left": {
            "ua": "лівий",
            "en": "left",
            "cz": "levý",
            "bg": "ляв"
        },
        "front": {
            "ua": "передній",
            "en": "front",
            "cz": "přední",
            "bg": "преден"
        },
        "rear": {
            "ua": "задній",
            "en": "rear",
            "cz": "zadní",
            "bg": "заден"
        },
        "top": {
            "ua": "верхній",
            "en": "top",
            "cz": "horní",
            "bg": "горен"
        },
        "bottom": {
            "ua": "нижній",
            "en": "bottom",
            "cz": "spodní",
            "bg": "долен"
        },
        "external": {
            "ua": "зовнішній",
            "en": "external",
            "cz": "vnější",
            "bg": "външен"
        },
        "internal": {
            "ua": "внутрішній",
            "en": "internal",
            "cz": "vnitřní",
            "bg": "вътрешен"
        },
        "central": {
            "ua": "центральний",
            "en": "central",
            "cz": "centrální",
            "bg": "централен"
        },
        "cellAddresses": {
            "ua": "адреси комірок",
            "en": "cell addresses",
            "cz": "adresy buněk",
            "bg": "адреси на клетките"
        },
        "priceGroupNumber": {
            "ua": "номер цінової групи",
            "en": "price group number",
            "cz": "číslo cenové skupiny",
            "bg": "номер на ценовата група"
        },
        "fixedSellingPrice": {
            "ua": "фіксована ціна продажу",
            "en": "fixed selling price",
            "cz": "pevná cena prodeje",
            "bg": "фиксирана цена на продажба"
        },
        "minSellingPrice": {
            "ua": "мінімальна ціна продажу",
            "en": "minimum selling price",
            "cz": "minimální cena prodeje",
            "bg": "минимална цена на продажба"
        },
        "fixedPurchasePrice": {
            "ua": "фіксована ціна закупівлі",
            "en": "fixed purchase price",
            "cz": "pevná cena nákupu",
            "bg": "фиксирана цена на закупуване"
        },
        "getSellingPriceFrom": {
            "ua": "отримати ціну продажу від",
            "en": "get selling price from",
            "cz": "získat cenu prodeje od",
            "bg": "вземи цена на продажба от"
        },
        "getPurchasePriceFrom": {
            "ua": "отримати ціну закупівлі від",
            "en": "get purchase price from",
            "cz": "získat cenu nákupu od",
            "bg": "вземи цена на закупуване от"
        },
        "isRound": {
            "ua": "заокруглення",
            "en": "rounding",
            "cz": "zaokrouhlení",
            "bg": "закръгляне"
        },
        "decimalPlaces": {
            "ua": "десяткові місця",
            "en": "decimal places",
            "cz": "desetinná místa",
            "bg": "десетични места"
        },
        "productUnitId": {
            "ua": "ідентифікатор одиниці продукту",
            "en": "product unit id",
            "cz": "identifikátor jednotky produktu",
            "bg": "идентификатор на единица продукт"
        },
        "enabled": {
            "ua": "увімкнено",
            "en": "enabled",
            "cz": "povoleno",
            "bg": "активирано"
        },
        "markupGroupId": {
            "ua": "ідентифікатор групи націнок",
            "en": "markup group id",
            "cz": "identifikátor skupiny přirážek",
            "bg": "ид идентификатор на група надценки"
        },
        "source": {
            "ua": "джерело",
            "en": "source",
            "cz": "zdroj",
            "bg": "източник"
        },
        "purchaseCurrencyId": {
            "ua": "id ідентифікатор валюти закупівлі",
            "en": "id identifier of purchase currency",
            "cz": "id identifikátor měny nákupu",
            "bg": "ид идентификатор на валута на закупуване"
        },
        "sellingCurrencyId": {
            "ua": "id ідентифікатор валюти продажу",
            "en": "id identifier of selling currency",
            "cz": "id identifikátor měny prodeje",
            "bg": "ид идентификатор на валута на продажба"
        },
        "purchasePriceInCurrency": {
            "ua": "ціна закупівлі у валюті",
            "en": "purchase price in currency",
            "cz": "cena nákupu v měně",
            "bg": "цена на закупуване в валута"
        },
        "sellingPriceInCurrency": {
            "ua": "ціна продажу у валюті",
            "en": "selling price in currency",
            "cz": "cena prodeje v měně",
            "bg": "цена на продажба в валута"
        },
        "storageHashes": {
            "ua": "хеші зберігання",
            "en": "storage hashes",
            "cz": "úložiště hash",
            "bg": "хешове на съхранение"
        },
        "brand": {
            "id": {
                "ua": "ідентифікатор",
                "en": "id identifier",
                "cz": "id identifikátor",
                "bg": "ид идентификатор"
            },
            "name": {
                "ua": "назва бренду",
                "en": "brand name",
                "cz": "název značky",
                "bg": "име на марка"
            },
            "supplierId": {
                "ua": "id ідентифікатор постачальника",
                "en": "id identifier of supplier",
                "cz": "id identifikátor dodavatele",
                "bg": "ид идентификатор на доставчик"
            }
        },
        "group": {
            "id": {
                "ua": "id ідентифікатор",
                "en": "id identifier",
                "cz": "id identifikátor",
                "bg": "ид идентификатор"
            },
            "markupGroupId": {
                "ua": "id ідентифікатор групи націнок",
                "en": "id identifier of markup group",
                "cz": "id identifikátor skupiny přirážek",
                "bg": "ид идентификатор на група надценки"
            },
            "parentGroupId": {
                "ua": "id ідентифікатор батьківської групи",
                "en": "id identifier of parent group",
                "cz": "id identifikátor rodičovské skupiny",
                "bg": "ид идентификатор на родителска група"
            },
            "priceGroupNumber": {
                "ua": "номер цінової групи",
                "en": "price group number",
                "cz": "číslo cenové skupiny",
                "bg": "номер на ценовата група"
            },
            "businessId": {
                "ua": "id ідентифікатор станції",
                "en": "id identifier of station",
                "cz": "id identifikátor stanice",
                "bg": "ид идентификатор на станцията"
            },
            "fixedBrand": {
                "ua": "фіксований бренд",
                "en": "fixed brand",
                "cz": "pevná značka",
                "bg": "фиксирана марка"
            }
        },
        "priceGroup": {
            "number": {
                "ua": "номер",
                "en": "number",
                "cz": "číslo",
                "bg": "номер"
            },
            "businessId": {
                "ua": "id ідентифікатор станції",
                "en": "id identifier of station",
                "cz": "id identifikátor stanice",
                "bg": "ид идентификатор на станцията"
            },
            "multiplier": {
                "ua": "множник",
                "en": "multiplier",
                "cz": "násobek",
                "bg": "множител"
            }
        },
        "businessSupplier": {
            "ua": "постачальник",
            "en": "supplier",
            "cz": "dodavatel",
            "bg": "доставчик"
        },
        "uktz": {
            "ua": "УКТЗЕД",
            "en": "UKTZED",
            "cz": "UKTZED",
            "bg": "УКТЗЕД"
        },
        "customCount": {
            "ua": "замовлена кількість",
            "en": "ordered quantity",
            "cz": "objednané množství",
            "bg": "поръчана количест"
        },
        "disablePrice": {
            "ua": "вимкнути ціну",
            "en": "disable price",
            "cz": "deaktivovat cenu",
            "bg": "изключи цена"
        }
    }
};