/* eslint-disable max-classes-per-file */
import { DatePicker, Input, InputNumber, Modal, Select, Switch, Table, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { buildSupplierOptions, fetchAPI, filterTreeNodeByPart, numeralFormatter, numeralParser } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
class AddRecommendationsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            recommendationsData: []
        };

        this.servicesOptions = [];

        this.columns = () => [
            {
                title: <FormattedMessage id='services_table.store_group' />,
                key: 'storeGroupId',
                dataIndex: 'storeGroupId',
                width: '10%',
                render: (data, elem) => (
                    <TreeSelect
                        className={Styles.groupsTreeSelect}
                        disabled={this.state.editing || Boolean(elem.masterLaborId)}
                        filterTreeNode={filterTreeNodeByPart}
                        getPopupContainer={() => document.querySelector('.addRecomModal')}
                        listHeight={440}
                        onSelect={(value, option) => {
                            elem.storeGroupId = value;
                            elem.laborId = undefined;
                            elem.recommendationName = undefined;
                            this.filterOptions(elem.masterLaborId, value);
                            this.setState({});
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'services_table.store_group'
                        })}
                        popupMatchSelectWidth={280}
                        showSearch
                        style={{ maxWidth: 320, minWidth: 100, color: 'var(--text)' }}
                        treeData={this.props.detailsTreeData}
                        value={data}
                    />
                )
            },
            {
                title: <FormattedMessage id='order_form_table.service_type' />,
                key: 'masterLaborId',
                dataIndex: 'masterLaborId',
                width: '10%',
                render: (data, elem) => (
                    <TreeSelect
                        className={Styles.groupsTreeSelect}
                        disabled={this.state.editing || Boolean(elem.storeGroupId)}
                        filterTreeNode={filterTreeNodeByPart}
                        getPopupContainer={() => document.querySelector('.addRecomModal')}
                        listHeight={440}
                        onSelect={(value, option) => {
                            elem.masterLaborId = value;
                            this.filterOptions(value, elem.storeGroupId);
                            this.setState({});
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.service_type'
                        })}
                        popupMatchSelectWidth={280}
                        showSearch
                        style={{ maxWidth: 180, minWidth: 100, color: 'var(--text)' }}
                        treeData={this.props.laborsTreeData}
                        value={data}
                    />
                )
            },
            {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='services_table.labor' /> <span style={{ color: 'red' }}>*</span>
                    </React.Fragment>
                ),
                key: 'laborId',
                dataIndex: 'recommendationLaborId',
                width: '15%',
                render: (data, elem) => {
                    return (
                        <Select
                            ref={node => (this.laborRef = node)}
                            allowClear
                            disabled={this.state.editing || elem.related}
                            filterOption={false}
                            getPopupContainer={() => document.querySelector('.addRecomModal')}
                            onChange={(value, option) => {
                                const val = value && value.replace(/-/g, '');
                                if (option) {
                                    const price = option.price ? option.price : Number(this.props.normHourPrice);
                                    const count = option.norm_hours || 1;
                                    elem.recommendationLaborId = val;
                                    elem.recommendationName = option.children;
                                    elem.masterLaborId = option.master_id;
                                } else {
                                    elem.laborId = val;
                                    elem.recommendationName = val;
                                    elem.masterLaborId = val;

                                    this.state.relatedLabors = [];
                                }
                                this.setState({});
                            }}
                            onSearch={input => this.props.handleSearchLabors(input)}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.labor'
                            })}
                            popupMatchSelectWidth={380}
                            showAction={['focus', 'click']}
                            showSearch
                            style={{ minWidth: 100, color: 'var(--text)' }}
                            value={!elem.related ? data : elem.name}
                        >
                            {elem.laborId
                                ? buildSupplierOptions(this.servicesOptions, {
                                      id: get(elem, 'laborId', undefined),
                                      name: get(elem, 'laborName', undefined)
                                  }).map((elem, index) => (
                                      <Option value={elem.id}>{elem.customName || elem.name}</Option>
                                  ))
                                : this.servicesOptions.map((elem, index) => (
                                      <Option
                                          key={index}
                                          barcode={elem.barcode}
                                          cross_id={elem.crossId}
                                          hours={elem.laborPrice.normHours}
                                          master_id={elem.masterLaborId}
                                          price={elem.laborPrice.price}
                                          specificationId={elem.specificationId}
                                          store_group_id={elem.storeGroupId}
                                          value={elem.id}
                                      >
                                          {elem.customName || elem.name}
                                      </Option>
                                  ))}
                        </Select>
                    );
                }
            },
            {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='name' /> <span style={{ color: 'red' }}>*</span>
                    </React.Fragment>
                ),
                key: 'recommendationName',
                dataIndex: 'recommendationName',
                width: '15%',
                render: (data, elem) => {
                    return (
                        <Input
                            onChange={({ target }) => {
                                const { value } = target;
                                elem.recommendationName = value;
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'name'
                            })}
                            style={{ minWidth: 160 }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'recommendationComment',
                dataIndex: 'recommendationComment',
                render: (data, elem) => {
                    let detail = elem.recommendationName;
                    if (detail && detail.indexOf(' - ') > -1) {
                        detail = detail.slice(0, detail.indexOf(' - '));
                    }

                    return (
                        <Input
                            onChange={({ target }) => {
                                const { value } = target;
                                elem.recommendationComment = value;
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'comment'
                            })}
                            style={{ minWidth: 160 }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='date' /> <span style={{ color: 'red' }}>*</span>
                    </React.Fragment>
                ),
                dataIndex: 'recommendationToDate',
                key: 'date',
                render: (data, elem) => {
                    const date = data ? (dayjs.isDayjs(data) ? data : dayjs(data)) : undefined;

                    return (
                        <DatePicker
                            allowClear={false}
                            format='DD MMM YYYY'
                            onChange={value => {
                                elem.recommendationToDate = value;
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'select_date'
                            })}
                            popupStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            style={{ width: '100%' }}
                            value={date}
                        />
                    );
                }
            },

            {
                title: <FormattedMessage id='orders.odometr' />,
                key: 'recommendationToOdometerValue',
                dataIndex: 'recommendationToOdometerValue',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={0}
                            onChange={value => {
                                elem.recommendationToOdometerValue = value;
                                this.setState({});
                            }}
                            onStep={() => this.setState({})}
                            parser={value => numeralParser(value)}
                            precision={2}
                            value={data || 0}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='status' />
                    </div>
                ),
                key: 'recommendationStatus',
                dataIndex: 'recommendationStatus',
                render: (data, elem) => {
                    return (
                        <Switch
                            checked={data}
                            onChange={() => {
                                elem.recommendationStatus = !elem.recommendationStatus;
                                this.setState({});
                            }}
                            value={data}
                        />
                    );
                }
            }
        ];
    }

    componentDidUpdate(prevProps, prevState) {
        const { visible, recommendation, labors } = this.props;
        const editing = Boolean(recommendation && recommendation.recommendationId);
        if (labors.length && !this.servicesOptions.length) {
            this.servicesOptions = [...labors];
            this.setState({});
        }
        if ((!prevProps.visible && visible) || prevProps.labors !== this.props.labors) {
            this.getOptions();
            if (recommendation) {
                const lbr = labors.find(({ id }) => id === recommendation.laborId);
                this.state.recommendationsData = [
                    {
                        ...recommendation,
                        storeGroupId: _.get(lbr, 'storeGroupId'),
                        masterLaborId: _.get(lbr, 'masterLaborId')
                    }
                ];
            } else {
                this.state.recommendationsData = [
                    {
                        recommendationId: -1,
                        recommendationToDate: dayjs(),
                        recommendationStatus: true
                    }
                ];
            }

            this.setState({
                editing
            });
        }
    }

    handleOk = () => {
        const { updateRecommendation, orderId, vehicleId, aggregateId } = this.props;
        const { editing, recommendationsData } = this.state;

        const recommendations = [];
        recommendationsData.forEach(element => {
            recommendations.push({
                vehicleId: vehicleId || undefined,
                aggregateId: aggregateId || undefined,
                orderId,
                recommendationId: editing ? element.recommendationId : undefined,
                recommendationLaborId: element.recommendationLaborId,
                recommendationName: element.recommendationName,
                recommendationComment: element.recommendationComment,
                recommendationStatus: Boolean(element.recommendationStatus),
                recommendationToOdometerValue: element.recommendationToOdometerValue,
                recommendationToDate: element.recommendationToDate
            });
        });

        if (!editing) {
            this.addRecommendation(recommendations);
        } else {
            updateRecommendation(recommendations);
        }

        this.props.hideModal();
        this.props.handleSearchLabors(undefined);
    };

    handleCancel = () => {
        this.setState({
            recommendationsData: []
        });
        this.props.hideModal();
        this.props.handleSearchLabors(undefined);
    };

    getOptions() {
        this.servicesOptions = [...this.props.labors];
    }

    addRecommendation = async recommendations => {
        const { getRecommendations } = this.props;

        await fetchAPI('POST', '/recommendations/labors', null, recommendations, {
            handleErrorInternally: true
        });
        getRecommendations();
    };

    filterOptions(masterLaborId, storeGroupId, laborId) {
        let servicesOptions = [...this.props.labors];
        if (masterLaborId) {
            servicesOptions = servicesOptions.filter((elem, index) => elem.masterLaborId == masterLaborId);
        }
        if (storeGroupId) {
            servicesOptions = servicesOptions.filter((elem, index) => elem.storeGroupId == storeGroupId);
        }
        if (laborId) {
            servicesOptions = servicesOptions.filter((elem, index) => elem.id == laborId);
        }

        this.servicesOptions = [...servicesOptions];
    }

    render() {
        const { visible } = this.props;
        const { recommendationsData } = this.state;

        return (
            <React.Fragment>
                <Modal
                    forceRender
                    maskClosable={false}
                    okButtonProps={{
                        disabled: ['recommendationName', 'recommendationToDate', 'recommendationLaborId'].some(
                            fld => !get(recommendationsData, `[0].${fld}`)
                        )
                    }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={visible}
                    title={<FormattedMessage id='order_form_table.add_recommendation' />}
                    width='95%'
                    wrapClassName='addRecomModal'
                >
                    <div className={Styles.tableWrap}>
                        <Table
                            bordered
                            columns={this.columns()}
                            dataSource={recommendationsData}
                            pagination={false}
                            rowKey='recommendationId'
                            size='small'
                        />
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddRecommendationsModal;
