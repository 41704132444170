import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { FETCH_ORDERS, fetchOrdersSuccess, selectOrdersQuery, setOrdersFetching } from './duck';

export function* fetchOrdersSaga() {
    while (true) {
        try {
            yield take(FETCH_ORDERS);
            yield put(setOrdersFetching(true));

            const query = yield select(selectOrdersQuery);

            const { orders, stats } = yield call(fetchAPI, 'GET', 'orders', { ...query, showRTLToo: true }, null, {
                handleErrorInternally: true
            });

            yield put(fetchOrdersSuccess({ orders, stats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setOrdersFetching(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchOrdersSaga)]);
}
