import { Input, Modal, Select, notification } from 'antd';
import { selectModalProps } from 'core/modals/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import { createStoreGroup, deleteStoreGroup, updateStoreGroup } from 'core/storage/storeGroups';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const { Option } = Select;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    modalProps: selectModalProps(state),
    priceGroups: selectPriceGroups(state)
});

const mapDispatchToProps = {
    createStoreGroup,
    updateStoreGroup,
    deleteStoreGroup,
    fetchPriceGroups
};

const CreateAccTemplateModal = props => {
    const { open, intl, rowId, onClose, isMobile } = props;

    const [templateName, setTemplateName] = useState();

    const createTemplate = useCallback(async () => {
        try {
            await fetchAPI(
                'POST',
                '/general_ledger/acc_document_template',
                null,
                { id: rowId, name: templateName },
                {
                    handleErrorInternally: true
                }
            );

            notification.success({
                message: intl.formatMessage({
                    id: 'message.success'
                })
            });

            onClose();
        } catch (err) {
            notification.error({
                message: intl.formatMessage({
                    id: 'error'
                })
            });
        }
    }, [intl, onClose, rowId, templateName]);

    const onFinish = async () => {
        await createTemplate();

        setTemplateName(undefined);
        await onClose();
    };

    return (
        <Modal
            maskClosable={false}
            onCancel={() => {
                onClose();
            }}
            onOk={() => onFinish()}
            open={open}
            title={<FormattedMessage id='complexes.create_template' />}
            width={!isMobile ? '25%' : '95%'}
        >
            <Input
                onChange={event => setTemplateName(event.target.value)}
                placeholder={intl.formatMessage({
                    id: 'receipt_document_modal.template_name_title'
                })}
                style={{
                    width: '100%'
                }}
                value={templateName}
            />
        </Modal>
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateAccTemplateModal));
