import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Input, InputNumber, Row, Select, Switch, notification } from 'antd';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const { Option } = Select;

@injectIntl
export class TaxesDeductionTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            taxes: [],
            deductions: [],
            taxesList: []
        };

        this.debouncedSaveByInd = debounce(ind => {
            this.saveDeductionByInd(ind);
        }, 400).bind(this);
    }

    componentDidMount() {
        this.fetchTaxesList();
        this.fetchTaxes();
        this.fetchDeductions();
    }

    fetchTaxes = async () => {
        const { employeeId } = this.props;
        const taxes = await fetchAPI('GET', '/employee/salary/tax', { employeeId });
        this.setState({
            taxes
        });
    };

    saveDeductionByInd = async ind => {
        const { employeeId } = this.props;
        const { id, deduction, deductionValue, isPercent, comment } = this.state.deductions[ind];
        await fetchAPI(
            'PUT',
            '/employee/salary/deduction',
            null,
            {
                employeeId,
                id,
                deduction,
                deductionValue,
                isPercent,
                comment: comment || undefined
            },
            { handleErrorInternally: true }
        );

        notification.success({
            message: this.props.intl.formatMessage({
                id: 'message.success'
            })
        });

        this.fetchDeductions();
    };

    fetchTaxesList = async () => {
        const { salaryTaxes } = await fetchAPI('GET', '/salary/taxes');
        this.setState({
            taxesList: salaryTaxes
        });
    };

    fetchDeductions = async () => {
        const { employeeId } = this.props;
        const deductions = await fetchAPI('GET', '/employee/salary/deduction', { employeeId });
        this.setState({
            deductions
        });
    };

    updateCondition = async (payload, values) => {};

    saveChanges = async () => {};

    render() {
        const { employeeId, initialEmployee, fetchEmployee } = this.props;
        const { taxes, taxesList, deductions, taxRowId } = this.state;

        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        width: '80%',
                        margin: '0 auto'
                    }}
                >
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <span
                            style={{
                                marginRight: 8
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.gross' />
                        </span>
                        <Switch
                            checked={!initialEmployee.isNettoSalary}
                            onChange={async () => {
                                await fetchAPI('PUT', `/employees/${employeeId}`, undefined, {
                                    isNettoSalary: !initialEmployee.isNettoSalary
                                });
                                await setTimeout(() => fetchEmployee(), 100);
                            }}
                            style={{
                                marginRight: 8
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            marginBottom: 8
                        }}
                    >
                        <span
                            style={{
                                marginRight: 8
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.net' />
                        </span>
                        <Switch
                            checked={initialEmployee.isNettoSalary}
                            onChange={async () => {
                                await fetchAPI('PUT', `/employees/${employeeId}`, undefined, {
                                    isNettoSalary: !initialEmployee.isNettoSalary
                                });
                                await setTimeout(() => fetchEmployee(), 100);
                            }}
                        />
                    </div>
                </div>

                <Card
                    style={{ width: '80%', margin: '0 auto' }}
                    title={
                        <React.Fragment>
                            <FormattedMessage id='salary_tax' />
                        </React.Fragment>
                    }
                >
                    {taxes.map(({ id, taxRowId, comment }, ind) => (
                        <Row style={{ marginTop: 8 }}>
                            <Col span={4}>
                                <FormattedMessage id='tax' /> {ind + 1}
                            </Col>
                            <Col span={8}>
                                <Select
                                    disabled
                                    fieldNames={{ label: 'taxName', value: 'id' }}
                                    options={taxesList}
                                    value={taxRowId}
                                />
                            </Col>
                            <Col span={8}>
                                <Input disabled value={comment} />
                            </Col>
                            <Col span={2}>
                                <Button
                                    icon={<DeleteOutlined />}
                                    onClick={async () => {
                                        await fetchAPI(
                                            'DELETE',
                                            '/employee/salary/tax',
                                            null,
                                            {
                                                ids: [id]
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        notification.success({
                                            message: this.props.intl.formatMessage({
                                                id: 'message.success'
                                            })
                                        });
                                        this.fetchTaxes();
                                    }}
                                    type='text'
                                />
                            </Col>
                        </Row>
                    ))}
                    <Row style={{ marginTop: 8 }}>
                        <Col span={8}>
                            <Select
                                fieldNames={{ label: 'taxName', value: 'id' }}
                                onChange={taxRowId => this.setState({ taxRowId })}
                                options={taxesList.filter(
                                    ({ id }) => !taxes.map(({ taxRowId }) => taxRowId).includes(id)
                                )}
                                placeholder={this.props.intl.formatMessage({ id: 'add_tax' })}
                                value={taxRowId}
                            />
                        </Col>
                        <Col span={4}>
                            <Button
                                disabled={!taxesList.length || !taxRowId}
                                icon={<CheckCircleOutlined />}
                                onClick={async () => {
                                    await fetchAPI(
                                        'POST',
                                        '/employee/salary/tax',
                                        null,
                                        {
                                            employeeId,
                                            taxRowId: `${taxRowId}`
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    notification.success({
                                        message: this.props.intl.formatMessage({
                                            id: 'message.success'
                                        })
                                    });
                                    this.fetchTaxes();
                                    this.setState({ taxRowId: undefined });
                                }}
                                type='text'
                            />
                        </Col>
                    </Row>
                </Card>

                {deductions.map(({ id, deduction, deductionValue, isPercent, comment }, ind) => (
                    <Card
                        style={{ width: '80%', margin: '8px auto 0' }}
                        title={
                            <React.Fragment>
                                <FormattedMessage id='deduction' /> {ind + 1}
                            </React.Fragment>
                        }
                    >
                        <Row style={{ marginTop: 8 }}>
                            <Col span={6}>
                                <FormattedMessage id='storage_journal.name' />
                            </Col>
                            <Col span={8}>
                                <Input
                                    onChange={e => {
                                        deductions[ind].deduction = e.target.value;
                                        // this.debouncedSaveByInd(ind);
                                        this.setState({});
                                    }}
                                    value={deduction}
                                />
                            </Col>
                            <Col span={4}>
                                <Button
                                    icon={<DeleteOutlined />}
                                    onClick={async () => {
                                        await fetchAPI(
                                            'DELETE',
                                            '/employee/salary/deduction',
                                            null,
                                            {
                                                ids: [id]
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        notification.success({
                                            message: this.props.intl.formatMessage({
                                                id: 'message.success'
                                            })
                                        });
                                        this.fetchDeductions();
                                    }}
                                    type='text'
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 8 }}>
                            <Col span={6}>
                                <FormattedMessage id='info_modal.value' />
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    decimalSeparator=','
                                    min={0.1}
                                    onChange={deductionValue => {
                                        deductions[ind].deduction = deductions[ind].deduction.replace(
                                            deductions[ind].deductionValue,
                                            deductionValue
                                        );
                                        deductions[ind].deductionValue = deductionValue;
                                        // this.debouncedSaveByInd(ind);
                                        this.setState({});
                                    }}
                                    value={deductionValue}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 8 }}>
                            <Col span={18}>
                                <Checkbox
                                    checked={isPercent}
                                    onChange={() => {
                                        if (isPercent) {
                                            deductions[ind].deduction = deductions[ind].deduction.replace('%', '');
                                        } else {
                                            deductions[ind].deduction = deductions[ind].deduction.replace(
                                                deductions[ind].deductionValue,
                                                `${deductions[ind].deductionValue}%`
                                            );
                                        }
                                        deductions[ind].isPercent = !isPercent;
                                        this.setState({});
                                        // this.saveDeductionByInd(ind);
                                    }}
                                >
                                    <FormattedMessage id='locations.percent' />
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 8 }}>
                            <Col span={6}>
                                <FormattedMessage id='comment' />
                            </Col>
                            <Col span={8}>
                                <Input.TextArea
                                    onChange={e => {
                                        deductions[ind].comment = e.target.value;
                                        // this.debouncedSaveByInd(ind);
                                        this.setState({});
                                    }}
                                    value={comment}
                                />
                            </Col>
                        </Row>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end'
                            }}
                        >
                            <Button onClick={() => this.debouncedSaveByInd(ind)} type='primary'>
                                <FormattedMessage id='save' />
                            </Button>
                        </div>
                    </Card>
                ))}
                <div
                    style={{
                        width: '80%',
                        margin: '14px auto 0',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        onClick={async () => {
                            await fetchAPI(
                                'POST',
                                '/employee/salary/deduction',
                                null,
                                {
                                    employeeId,
                                    deduction: `${this.props.intl.formatMessage({
                                        id: 'deduction'
                                    })} ${deductions.length + 1}, 20%`,
                                    deductionValue: 20,
                                    isPercent: true
                                },
                                { handleErrorInternally: true }
                            );
                            notification.success({
                                message: this.props.intl.formatMessage({
                                    id: 'message.success'
                                })
                            });
                            this.fetchDeductions();
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='add_deduction' />
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}
