import { DeleteOutlined } from '@ant-design/icons';
import { AutoComplete, Input, InputNumber, Modal, notification, Select, Tooltip, TreeSelect } from 'antd';
import _, { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { buildStoreGroupsTree, fetchAPI, filterTreeNodeByPart } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const complexesTypes = {
    OWN: 'complexes.type.OWN',
    PRODUCTION: 'complexes.type.PRODUCTION',
    DISASSEMBLY: 'complexes.type.DISASSEMBLY'
};

const CreateComplexModal = ({
    open,
    hideModal,
    labors,
    details,
    brands,
    fetchComplexes,
    intl: { formatMessage },
    storeProductId,
    source,
    code,
    handleSearchLabors
}) => {
    const [complexName, setComplexName] = useState('');
    const [laborsDataSource, setLaborsDataSource] = useState([]);
    const [detailsDataSource, setDetailsDataSource] = useState([]);
    const [storageProducts, setStorageProducts] = useState([]);
    const [detailCode, setDetailCode] = useState(undefined);
    const [relatedProductId, setProductId] = useState(undefined);
    const [type, setType] = useState('OWN');

    useEffect(() => {
        if (open && open.id) {
            setComplexName(open.name);
            setType(open.type);
            setLaborsDataSource(
                open.labors.map(labor => ({
                    ...labor,
                    name: labor.laborName,
                    count: labor.laborCount,
                    price: labor.laborPrice,
                    id: labor.laborLaborId,
                    laborId: labor.laborId
                }))
            );
            setDetailsDataSource(
                open.products.map(detail => ({
                    ...detail,
                    name: detail.productName,
                    count: detail.productCount,
                    price: detail.productPrice,
                    brandId: detail.productBrandId,
                    id: detail.productStoreGroupId,
                    productId: detail.productId
                }))
            );
        }
        if (storeProductId) {
            setType(source);
            setDetailCode(code);
        }
    }, [open, storeProductId]);

    const detailsTreeData = useMemo(() => buildStoreGroupsTree(details), [details]);

    const handleCancel = () => {
        setComplexName('');
        setLaborsDataSource([]);
        setDetailsDataSource([]);
        setStorageProducts([]);
        setDetailCode(undefined);
        setProductId(undefined);
        setType('OWN');
        hideModal();
    };

    const submitCreate = async () => {
        const complexLaborsData = laborsDataSource.map(
            ({ name, normHours, price, count, laborUnitId, id, laborId }) => ({
                laborName: name,
                laborHours: normHours,
                laborPrice: price,
                laborCount: count,
                ...(laborUnitId ? { laborUnitId } : {}),
                ...(laborId ? { laborId } : {}),
                laborLaborId: id
            })
        );
        const complexProductsData = detailsDataSource.map(
            ({ id, name, count, price, brandId, productCode, productId }) => ({
                productCode,
                productStoreGroupId: id,
                productName: name,
                productCount: count,
                productPrice: price,
                ...(brandId ? { productBrandId: brandId } : {}),
                ...(productId ? { productId } : {})
            })
        );

        const storeProductBody = {
            complexData: {
                name: complexName,
                type: source
            },
            complexLaborsData,
            complexProductsData,
            assignStoreProducts: [storeProductId]
        };

        const body = get(open, 'id')
            ? {
                  complexesData: [
                      {
                          name: complexName,
                          type: type || 'OWN',
                          id: get(open, 'id'),
                          complexLaborsData,
                          complexProductsData,
                          assignStoreProducts: relatedProductId ? [relatedProductId] : undefined
                      }
                  ]
              }
            : storeProductId
            ? storeProductBody
            : {
                  complexData: {
                      name: complexName,
                      type: type || 'OWN'
                  },
                  complexLaborsData,
                  complexProductsData,
                  assignStoreProducts: relatedProductId ? [relatedProductId] : undefined
              };
        try {
            const response = await fetchAPI(get(open, 'id') ? 'PUT' : 'POST', 'business/complexes', null, body, {
                handleErrorInternally: true
            });
            await fetchComplexes();
            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
            handleCancel();
        } catch {
            notification.error({
                message: formatMessage({ id: 'complexes.already_assign_error_message' })
            });
        }
    };

    const getStorageProductsByQuery = async query => {
        const { list: storageProducts } = await fetchAPI(
            'GET',
            'store_products',
            { query, pageSize: 25, withoutPhoto: true },
            null,
            {
                handleErrorInternally: true
            }
        );
        setStorageProducts(storageProducts);
    };

    const getProductId = async (detailCode, brandId, productId) => {
        let storageProduct = null;
        if (productId) {
            storageProduct = await fetchAPI('GET', `store_products/${productId}`, null, null, {
                handleErrorInternally: true
            });
        } else {
            const { list } = await fetchAPI(
                'GET',
                'store_products/',
                {
                    filterCode: detailCode,
                    brandId,
                    pageSize: 15
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            storageProduct = list.find(({ code, brandId: brand }) => code === detailCode && brand === brandId);
        }

        if (storageProduct) {
            setDetailCode(storageProduct.code);
            setProductId(storageProduct.id);

            return true;
        }

        return false;
    };

    const debouncedQuerySearch = useMemo(() => {
        const loadOptions = value => {
            getStorageProductsByQuery(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [storageProducts]);

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{
                disabled: !complexName
            }}
            onCancel={handleCancel}
            onOk={submitCreate}
            open={open}
            title={formatMessage({ id: 'complexes.create_complex' })}
            width='85%'
        >
            <div className={Styles.inputBlock}>
                <div className={!get(open, 'id') ? Styles.firstBlock : Styles.firstBlockEdit}>
                    <Input
                        onChange={e => setComplexName(e.target.value)}
                        placeholder={formatMessage({ id: 'complexes.complex_name' })}
                        style={{ marginBottom: '16px' }}
                        value={complexName}
                    />
                </div>
                <div
                    style={{
                        width: '15%'
                    }}
                >
                    <Select
                        disabled={storeProductId}
                        onChange={value => setType(value)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'complexes.type'
                        })}
                        showSearch
                        style={{ marginBottom: 6, width: '100%' }}
                        value={type}
                    >
                        {Object.entries(complexesTypes).map(([key, value]) => (
                            <Option key={key} value={key}>
                                <FormattedMessage id={value} />
                            </Option>
                        ))}
                    </Select>
                </div>

                <div
                    style={{
                        width: '30%'
                    }}
                >
                    <Tooltip placement='top' title={<FormattedMessage id='order_form_table.detail_code' />}>
                        <AutoComplete
                            disabled={storeProductId || type === 'OWN'}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                onChange={value => {
                                    setDetailCode(String(value).replace(/[^A-Za-z0-9\u0400-\u04FF]/g, ''));

                                    if (value.length >= 3) {
                                        debouncedQuerySearch(value);
                                    }
                                }}
                                placeholder={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div>
                                            <FormattedMessage id='complexes.asign_product' />
                                        </div>
                                    </div>
                                }
                                // onBlur={() => {
                                //     this.getProductId(detailCode, brandId);
                                // }}
                                onSelect={value => {
                                    // await this.setState({
                                    //     detailCode: option.code,
                                    //     detailName: option.detail_name,
                                    //     stockPrice: option.stock_price || option.purchase_price,
                                    //     sellingPrice: option.selling_price
                                    // });
                                    getProductId(undefined, undefined, value);
                                }}
                                style={{
                                    minWidth: 160
                                }}
                                value={detailCode}
                        >
                            {storageProducts.map(elem => {
                                return (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.code}
                                    </Option>
                                );
                            })}
                        </AutoComplete>
                    </Tooltip>
                </div>
            </div>

            <div className={Styles.listsWrapper}>
                <div className={Styles.laborsList}>
                    <div className={Styles.listTitle}>
                        <FormattedMessage id='add_order_form.services' />
                    </div>
                    {laborsDataSource.map((elem, key) => {
                        return (
                            <div key={key} className={Styles.listRow}>
                                <div>{key + 1}</div>
                                <div className={Styles.nameField}>
                                    <Input
                                        data-qa='input_name_labor_complexes_modal'
                                        disabled
                                        style={{ color: 'var(--text)' }}
                                        value={elem.name}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        data-qa='input_number_count_labor_complexes_modal'
                                        decimalSeparator=','
                                        min={0.01}
                                        onChange={value => {
                                            setLaborsDataSource(prev =>
                                                prev.map((e, ind) => (ind === key ? { ...elem, count: value } : e))
                                            );
                                        }}
                                        placeholder={formatMessage({ id: 'clients-page.quantity' })}
                                        step={0.1}
                                        style={{ color: 'var(--text)' }}
                                        value={elem.count}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        // addonBefore={getCurrency()}
                                        data-qa='input_number_price_labor_complexes_modal'
                                        decimalSeparator=','
                                        min={0.1}
                                        onChange={value => {
                                            setLaborsDataSource(prev =>
                                                prev.map((e, ind) => (ind === key ? { ...elem, price: value } : e)));
                                            // elem.price = value;
                                            // this.setState({});
                                        }}
                                        // step={0.1}
                                        placeholder={formatMessage({ id: 'price' })}
                                        style={{ color: 'var(--text)' }}
                                        value={elem.price}
                                    />
                                </div>
                                <div>
                                    <DeleteOutlined
                                        className={Styles.deleteIcon}
                                        onClick={() => {
                                            setLaborsDataSource(prev => prev.filter((e, index) => index != key));
                                            // this.setState({
                                            //     laborsDataSource: laborsDataSource.filter((labor, index) => index != key)
                                            // });
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                    <div className={Styles.listRow}>
                        <div>{laborsDataSource.length + 1}</div>
                        <div className={Styles.nameField}>
                            <Select
                                data-qa='select_laborDataSourse_push_complexes_modal'
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                filterOption={false}
                                onSearch={input => handleSearchLabors(input)}
                                onSelect={(value, { name, hours, price, laborUnitId }) => {
                                    const val = value && value.replace(/-/g, '');
                                    setLaborsDataSource(prev => [
                                        ...prev,
                                        {
                                            id: val,
                                            name,
                                            count: hours || 1,
                                            normHours: hours,
                                            price,
                                            laborUnitId
                                        }
                                    ]);
                                }}
                                placeholder={formatMessage({
                                    id: 'services_table.labor'
                                })}
                                showSearch
                                value={null}
                            >
                                {labors.map(({ id, name, laborPrice, laborUnitId }, key) => (
                                    <Option
                                        key={key}
                                        hours={laborPrice.normHours}
                                        laborUnitId={laborUnitId}
                                        name={name}
                                        price={laborPrice.price}
                                        value={id}
                                    >
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <InputNumber
                                data-qa='input_number_one_labor_complexes_modal'
                                decimalSeparator=','
                                disabled
                                step={0.1}
                                style={{ color: 'var(--text)' }}
                                value={1}
                            />
                        </div>
                        <div>
                            <InputNumber
                                // addonBefore={getCurrency()}
                                data-qa='input_number_price_labor_complexes_modal'
                                decimalSeparator=','
                                disabled
                                placeholder={formatMessage({ id: 'price' })}
                                min={0.1}
                                // step={0.1}
                                style={{ color: 'var(--text)' }}
                            />
                        </div>
                        <div>
                            <DeleteOutlined className={Styles.disabledIcon} />
                        </div>
                    </div>
                </div>

                <div className={Styles.detailsList}>
                    <div className={Styles.listTitle}>
                        <FormattedMessage id='add_order_form.details' />
                    </div>
                    {detailsDataSource.map((elem, key) => {
                        return (
                            <div key={key} className={Styles.listRow}>
                                <div>{key + 1}</div>
                                <div className={Styles.nameField}>
                                    <Input
                                        data-qa='input_name_detail_complexes_modal'
                                        disabled
                                        style={{ color: 'var(--text)' }}
                                        value={elem.name}
                                    />
                                </div>
                                <div>
                                    <Select
                                        data-qa='select_laborDataSourse_push_complexes_modal'
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                            zIndex: '9999',
                                            minWidth: 150
                                        }}
                                        filterOption={(input, option) => {
                                            const brands = input.toLowerCase().split(' ');

                                            return brands.every(part =>
                                                String(option.children).toLowerCase().includes(part));
                                        }}
                                        onSelect={(value, value2) => {
                                            setDetailsDataSource(prev =>
                                                prev.map((e, ind) => (ind === key ? { ...elem, brandId: value } : e)));
                                        }}
                                        placeholder={formatMessage({
                                            id: 'brands_page.title'
                                        })}
                                        showSearch
                                        style={{ width: 150 }}
                                        value={elem.brandId}
                                        width='fit'
                                    >
                                        {brands.map(({ brandId, brandName }) => (
                                            <Option key={brandId} value={brandId}>
                                                {brandName}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div>
                                    <Input
                                        data-qa='input_number_price_labor_complexes_modal'
                                        onChange={event => {
                                            setDetailsDataSource(prev =>
                                                prev.map((e, ind) =>
                                                    ind === key ? { ...elem, productCode: event.target.value } : e
                                                )
                                            );
                                        }}
                                        placeholder={formatMessage({ id: 'order_form_table.detail_code' })}
                                        style={{ color: 'var(--text)', minWidth: 90 }}
                                        value={elem.productCode}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        data-qa='input_number_count_detail_complexes_modal'
                                        decimalSeparator=','
                                        // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        min={0.1}
                                        onChange={value => {
                                            setDetailsDataSource(prev =>
                                                prev.map((e, ind) => (ind === key ? { ...elem, count: value } : e))
                                            );
                                        }}
                                        placeholder={formatMessage({ id: 'clients-page.quantity' })}
                                        precision={1}
                                        style={{ color: 'var(--text)' }}
                                        value={elem.count}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        // addonBefore={getCurrency()}
                                        data-qa='input_number_price_labor_complexes_modal'
                                        decimalSeparator=','
                                        min={0.1}
                                        onChange={value => {
                                            setDetailsDataSource(prev =>
                                                prev.map((e, ind) => (ind === key ? { ...elem, price: value } : e))
                                            );
                                        }}
                                        placeholder={formatMessage({ id: 'price' })}
                                        step={0.1}
                                        style={{ color: 'var(--text)' }}
                                        value={elem.price}
                                    />
                                </div>
                                <div>
                                    <DeleteOutlined
                                        className={Styles.deleteIcon}
                                        onClick={() => {
                                            setDetailsDataSource(prev => prev.filter((e, index) => index != key));
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                    <div className={Styles.listRow}>
                        <div>{detailsDataSource.length + 1}</div>
                        <div className={Styles.nameField}>
                            <TreeSelect
                                data-qa='tree_select_push_detail_complexes_modal'
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                                filterTreeNode={filterTreeNodeByPart}
                                onSelect={(value, { title }) => {
                                    setDetailsDataSource(prev => [
                                        ...prev,
                                        {
                                            id: value,
                                            name: title,
                                            count: 1
                                        }
                                    ]);
                                }}
                                placeholder={formatMessage({
                                    id: 'services_table.complex'
                                })}
                                popupMatchSelectWidth={false}
                                showSearch
                                treeData={detailsTreeData}
                                value={null}
                            />
                        </div>
                        <div>
                            <Select
                                data-qa='select_laborDataSourse_push_complexes_modal'
                                disabled
                                placeholder={formatMessage({
                                    id: 'brands_page.title'
                                })}
                                showSearch
                                style={{ width: 150 }}
                                value={null}
                                width='fit'
                            />
                        </div>
                        <div>
                            <InputNumber
                                data-qa='input_number_price_labor_complexes_modal'
                                disabled
                                placeholder={formatMessage({ id: 'order_form_table.detail_code' })}
                                style={{ color: 'var(--text)' }}
                                value={null}
                            />
                        </div>
                        <div>
                            <InputNumber
                                data-qa='input_number_detail_1_complexes_modal'
                                decimalSeparator=','
                                disabled
                                style={{ color: 'var(--text)' }}
                                value={1}
                            />
                        </div>
                        <div>
                            <InputNumber
                                // addonBefore={getCurrency()}
                                data-qa='input_number_price_labor_complexes_modal'
                                decimalSeparator=','
                                disabled
                                min={0.1}
                                placeholder={formatMessage({ id: 'price' })}
                                // step={0.1}
                                style={{ color: 'var(--text)' }}
                            />
                        </div>
                        <div>
                            <DeleteOutlined className={Styles.disabledIcon} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(CreateComplexModal);
