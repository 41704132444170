import { Table } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { camelToSnake, fetchAPI, numeralFormatter2 } from 'utils';
import ReportSettings from '../../ReportSettings';

const sortArr = [
    'servicesSum',
    'servicesSubSum',
    'appurtenanciesSum',
    'productsSum',
    'mechanicsSalary',
    'servicesSubPurchaseSum',
    'appurtenanciesPurchaseSum',
    'productsPurchaseSum',
    'servicesMargin',
    'servicesSubMargin',
    'appurtenanciesMargin',
    'productsMargin',
    'servicesMarginPercent',
    'servicesSubMarginPercent',
    'appurtenanciesMarginPercent',
    'productsMarginPercent',
    'salary',
    'salaryTax',
    'profit'
];

const IncreaseTab = ({ isDisabledCRUD, user, settings, setSettings, filters, intl: { formatMessage } }) => {
    const [incomeData, setIncomeData] = useState([]);
    const [incomeLength, setIncomeLength] = useState(0);

    const incomeColumns = () => {
        const dataColumns = [];
        for (let i = 0; i < incomeLength; i++) {
            dataColumns.push({
                title: i + 1,
                key: i,
                dataIndex: i,
                align: 'right',
                width: 135,
                onCell: record => ({
                    style: { background: record.name ? 'aliceblue' : void 0, fontWeight: record.name ? 700 : 500 }
                }),
                render: data => numeralFormatter2(data)
            });
        }

        return [
            // {
            //     key: 'name',
            //     dataIndex: 'name',
            //     width: 150,
            //     fixed: 'left',
            //     onCell: record => ({ style: { background: record.name ? 'aliceblue' : 'white', fontWeight: 700 } }),
            //     render: field => field && <FormattedMessage id={field} />
            // },
            {
                key: 'field',
                dataIndex: 'field',
                width: 135,
                fixed: 'left',
                onCell: record => ({
                    style: { background: record.name ? 'aliceblue' : 'white', fontWeight: record.name ? 700 : 500 }
                }),
                // render: field => <FormattedMessage id={camelToSnake(field)} />
                render: (text, record) => (
                    <div>
                        {record.name && <FormattedMessage id={record.name} />}
                        {record.field && record.name && ' / '}
                        {record.field && <FormattedMessage id={camelToSnake(record.field)} />}
                    </div>
                )
            },
            ...dataColumns,
            {
                title: formatMessage({ id: 'sub_total' }),
                width: 135,
                key: 'total',
                dataIndex: 'total',
                fixed: 'right',
                align: 'right',
                maxwidth: '135',
                onCell: record => ({
                    style: { background: record.name ? 'aliceblue' : 'white', fontWeight: record.name ? 700 : 500 }
                }),
                render: data => numeralFormatter2(data)
            }
        ];
    };

    const fetchReports = useCallback(async () => {
        const reports = await fetchAPI(
            'GET',
            '/manager/report/income',
            {
                ...settings,
                filters: { ...filters, responsibleId: filters.managerId, counterpartClientId: filters.clientId },
                startDate: dayjs(settings.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(settings.endDate).format('YYYY-MM-DD')
            },
            null,
            {
                handleErrorInternally: true
            }
        );
        const res = [];

        let length = 0;
        Object.entries(reports).forEach(([key, val]) => {
            if (key !== 'count') {
                if (!length) length = val.data.length;
                const obj = { name: key, field: 'subTotal', total: val.total.subTotal };
                for (let i = 0; i < length; i++) {
                    obj[i] = val.data[i].subTotal;
                }
                res.push(obj);
                Object.entries(val.total)
                    .sort(([key1], [key2]) => sortArr.indexOf(key1) - sortArr.indexOf(key2))
                    .forEach(([key, tot]) => {
                        if (key !== 'subTotal') {
                            const obj2 = { field: key, total: tot };
                            for (let i = 0; i < length; i++) {
                                obj2[i] = val.data[i][key];
                            }
                            res.push(obj2);
                        }
                    });
            }
        });
        setIncomeData(res);
        setIncomeLength(length);
    }, [filters, settings]);

    useEffect(() => {
        fetchReports();
    }, [fetchReports]);

    return (
        <React.Fragment>
            <ReportSettings disabled={isDisabledCRUD} setSettings={setSettings} settings={settings} user={user} />
            <Table
                bordered
                columns={incomeColumns()}
                dataSource={incomeData}
                pagination={false}
                rowKey={({ name, field }) => `${name}_${field}`}
                scroll={{
                    x: 'max-content'
                }}
                size='small'
                sticky={{
                    offsetHeader: -14
                }}
                style={{ marginTop: 14 }}
            />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default connect(mapStateToProps)(injectIntl(IncreaseTab));
