export const rearChassisObjectsData = [
    {
        id: '4157063d-b61a-4fe2-9ede-fda2e39abf7b',
        name: 'Sedan_Back_Axle_2.002',
        'location _x': 50.914,
        location_x: -13.548,
        location_z: 28.667,
        vertices: 114,
        polygons: 96,
        name_ua: 'Сайлентблок лівого заднього верхнього важеля підвіски',
        tooltip: {
            cz: 'Tichý blok páky',
            en: 'Lever silent block',
            ru: 'Сайлентблок рычага',
            ua: 'Сайлентблок важеля',
            bg: 'Тампон на носач'
        },
        location: '[50,914, -13,548, 28,667]'
    },
    {
        id: '814b06b1-8fe9-48d5-bb4a-a3d8d467d023',
        name: 'Sedan_Back_Axle_2.003',
        'location _x': 56.24,
        location_x: -3.876,
        location_z: 27.833,
        vertices: 320,
        polygons: 270,
        name_ua: 'Тарілка пружини підвіски',
        tooltip: {
            cz: 'Talíř pružiny zavěšení',
            en: 'Suspension spring plate',
            ru: 'Тарелка пружины подвески',
            ua: 'Тарілка пружини підвіски',
            bg: 'Плоча на пружината на окачването'
        },
        location: '[56,24, -3,876, 27,833]'
    },
    {
        id: '3b9b2faf-4146-4b93-a57d-b6b8051c057b',
        name: 'Sedan_Back_Axle_2.005',
        'location _x': 65.791,
        location_x: -72.801,
        location_z: -1.866,
        vertices: 329,
        polygons: 336,
        name_ua: 'Кронштейн кріплення підрамника',
        tooltip: {
            cz: 'Držák uchycení pomocného rámu',
            en: 'Subframe mounting bracket',
            ru: 'Кронштейн крепления подрамника',
            ua: 'Кронштейн кріплення підрамника',
            bg: 'Скоба за закрепване на подрамника'
        },
        location: '[65,791, -72,801, -1,866]'
    },
    {
        id: 'cae73a25-fff8-4278-9be7-5c63da7d80a1',
        name: 'Sedan_Back_Axle_2.001',
        'location _x': 57.05,
        location_x: -4.545,
        location_z: 14.732,
        vertices: 800,
        polygons: 799,
        name_ua: 'Пружній елемент задній лівий',
        tooltip: {
            cz: 'Pružina odpružení',
            en: 'Coil spring',
            ru: 'Пружина подвески',
            ua: 'Пружина підвіски',
            bg: 'Окачване пружина'
        },
        location: '[57,05, -4,545, 14,732]'
    },
    {
        id: '6465374d-29b6-4f8c-90a2-938def556009',
        name: 'Sedan_Back_Axle_2.008',
        'location _x': -57.05,
        location_x: -4.545,
        location_z: 14.732,
        vertices: 800,
        polygons: 799,
        name_ua: 'Пружній елемент задній правий',
        tooltip: {
            cz: 'Pružina odpružení',
            en: 'Coil spring',
            ru: 'Пружина подвески',
            ua: 'Пружина підвіски',
            bg: 'Окачване пружина'
        },
        location: '[-57,05, -4,545, 14,732]'
    },
    {
        id: '77ab32c8-e995-4ad0-877e-88dbd1915111',
        name: 'Sedan_Back_Axle_2.012',
        'location _x': -63.607,
        location_x: -12.77,
        location_z: 27.959,
        vertices: 164,
        polygons: 150,
        name_ua: 'Правий задній верхній важіль підвіски',
        tooltip: {
            cz: 'Rameno podélného zavěšení',
            en: 'Wishbone',
            ru: 'Продольный рычаг подвески',
            ua: 'Поздовжній важіль підвіски',
            bg: 'Надлъжен носач за окачване'
        },
        location: '[-63,607, -12,77, 27,959]'
    },
    {
        id: 'fe68b773-21b3-4dbc-8686-9963e09db635',
        name: 'Sedan_Back_Axle_2.013',
        'location _x': -82.161,
        location_x: -17.691,
        location_z: 11.057,
        vertices: 5952,
        polygons: 5988,
        name_ua: 'Гальмівний диск заднього правого колеса',
        tooltip: {
            cz: 'Brzdový disk',
            en: 'Brake disc',
            ru: 'Тормозной диск',
            ua: 'Диск гальмівний',
            bg: 'Спирачен диск'
        },
        location: '[-82,161, -17,691, 11,057]'
    },
    {
        id: 'a868cec7-6571-469c-9d3d-02b2d5cc6399',
        name: 'Sedan_Back_Axle_2.014',
        'location _x': -82.186,
        location_x: -4.556,
        location_z: 13.183,
        vertices: 280,
        polygons: 276,
        name_ua: 'Колодки заднього правого гальмівного супорта',
        tooltip: {
            cz: 'Senzor opotřebení brzdových destiček',
            en: 'Brake pad wear sensor',
            ru: 'Датчик износа тормозных колодок',
            ua: 'Датчик зносу колодок',
            bg: 'Сензор за износ на накладките'
        },
        location: '[-82,186, -4,556, 13,183]'
    },
    {
        id: '12d14a3c-93e9-4f6c-b501-68945b321146',
        name: 'Sedan_Back_Axle_2.015',
        'location _x': -53.93,
        location_x: -17.343,
        location_z: 6.049,
        vertices: 885,
        polygons: 799,
        name_ua: 'Правий задній нижній важіль підвіски',
        tooltip: {
            cz: 'Rameno podélného zavěšení',
            en: 'Wishbone',
            ru: 'Продольный рычаг подвески',
            ua: 'Поздовжній важіль підвіски',
            bg: 'Надлъжен носач за окачване'
        },
        location: '[-53,93, -17,343, 6,049]'
    },
    {
        id: '8e75cb11-dfea-43d5-8319-11d920799608',
        name: 'Sedan_Back_Axle_2.016',
        'location _x': -50.238,
        location_x: -38.771,
        location_z: 2.272,
        vertices: 226,
        polygons: 200,
        name_ua: 'Кульова опора правого заднього нижнього важеля підвіски',
        tooltip: {
            cz: 'Podpora je kulovitá',
            en: 'Ball joint',
            ru: 'Опора шаровая',
            ua: 'Опора кульова',
            bg: 'Шарнир'
        },
        location: '[-50,238, -38,771, 2,272]'
    },
    {
        id: 'e64194e1-c46a-4609-80d5-59aef77fbfff',
        name: 'Sedan_Back_Axle_2.018',
        'location _x': 0.039,
        location_x: -25.064,
        location_z: 12.557,
        vertices: 1700,
        polygons: 1636,
        name_ua: 'Підрамник задньої осі',
        tooltip: {
            cz: 'Přední podvozek',
            en: 'Front subframe',
            ru: 'Передний подрамник',
            ua: 'Підрамник передній',
            bg: 'Преден подрамник'
        },
        location: '[0,039, -25,064, 12,557]'
    },
    {
        id: '5f8d0522-eac7-4388-baf1-be7d5a7d3ae6',
        name: 'Sedan_Back_Axle_2.019',
        'location _x': 39.481,
        location_x: 13.301,
        location_z: 12.091,
        vertices: 276,
        polygons: 247,
        name_ua: 'Кронштейн кріплення підрамника',
        tooltip: {
            cz: 'Držák uchycení pomocného rámu',
            en: 'Subframe mounting bracket',
            ru: 'Кронштейн крепления подрамника',
            ua: 'Кронштейн кріплення підрамника',
            bg: 'Скоба за закрепване на подрамника'
        },
        location: '[39,481, 13,301, 12,091]'
    },
    {
        id: '8edec5f8-ebe0-4297-9c27-f6e9b025e0ad',
        name: 'Sedan_Back_Axle_2.004',
        'location _x': 0,
        location_x: -12.873,
        location_z: 23.086,
        vertices: 198,
        polygons: 198,
        name_ua: 'Задній стабілізатор підвіски',
        tooltip: {
            cz: 'Přední stabilizátor',
            en: 'Front stabilizer',
            ru: 'Передний стабилизатор',
            ua: 'Стабілізатор передній',
            bg: 'Преден стабилизатор'
        },
        location: '[0, -12,873, 23,086]'
    },
    {
        id: '5368d43b-ff88-4302-b84d-90593f39d4fa',
        name: 'Sedan_Back_Axle_2.021',
        'location _x': 82.186,
        location_x: -4.596,
        location_z: 13.44,
        vertices: 280,
        polygons: 276,
        name_ua: 'Колодки заднього лівого гальмівного супорта',
        tooltip: {
            cz: 'Senzor opotřebení brzdových destiček',
            en: 'Brake pad wear sensor',
            ru: 'Датчик износа тормозных колодок',
            ua: 'Датчик зносу колодок',
            bg: 'Сензор за износ на накладките'
        },
        location: '[82,186, -4,596, 13,44]'
    },
    {
        id: 'e949c473-95c6-4c78-8c7e-f53b1544d275',
        name: 'Sedan_Back_Axle_2.022',
        'location _x': 82.16,
        location_x: -17.687,
        location_z: 11.468,
        vertices: 5953,
        polygons: 5988,
        name_ua: 'Гальмівний диск заднього лівого колеса',
        tooltip: {
            cz: 'Brzdový disk',
            en: 'Brake disc',
            ru: 'Тормозной диск',
            ua: 'Диск гальмівний',
            bg: 'Спирачен диск'
        },
        location: '[82,16, -17,687, 11,468]'
    },
    {
        id: '57861ba9-54e2-4bc0-9c3a-7763b11a4a54',
        name: 'Sedan_Back_Axle_2.023',
        'location _x': 57.33,
        location_x: -5.21,
        location_z: 4.563,
        vertices: 90,
        polygons: 80,
        name_ua: 'Тарілка пружини підвіски',
        tooltip: {
            cz: 'Talíř pružiny zavěšení',
            en: 'Suspension spring plate',
            ru: 'Тарелка пружины подвески',
            ua: 'Тарілка пружини підвіски',
            bg: 'Плоча на пружината на окачването'
        },
        location: '[57,33, -5,21, 4,563]'
    },
    {
        id: 'e46d40af-8f43-47c1-a710-db2630d128b0',
        name: 'Sedan_Back_Axle_2.025',
        'location _x': 39.115,
        location_x: -24.074,
        location_z: 23.444,
        vertices: 120,
        polygons: 89,
        name_ua: 'Втулка заднього стабілізатора підвіски',
        tooltip: {
            cz: 'Vložky stabilizátoru',
            en: 'Stabilizer bushings',
            ru: 'Втулки стабилизатора',
            ua: 'Втулки стабілізатора',
            bg: 'Втулки на стабилизатора'
        },
        location: '[39,115, -24,074, 23,444]'
    },
    {
        id: '77496f55-2f0c-4aa0-93db-158d9742b983',
        name: 'Sedan_Back_Axle_2.026',
        'location _x': -37.414,
        location_x: 12.661,
        location_z: 12.963,
        vertices: 225,
        polygons: 201,
        name_ua: 'Кронштейн кріплення підрамника',
        tooltip: {
            cz: 'Držák uchycení pomocného rámu',
            en: 'Subframe mounting bracket',
            ru: 'Кронштейн крепления подрамника',
            ua: 'Кронштейн кріплення підрамника',
            bg: 'Скоба за закрепване на подрамника'
        },
        location: '[-37,414, 12,661, 12,963]'
    },
    {
        id: '5d8b74cd-efa4-489a-b5dd-c9396ec69006',
        name: 'Sedan_Back_Axle_2.028',
        'location _x': -65.316,
        location_x: -68.42,
        location_z: 0.45,
        vertices: 445,
        polygons: 428,
        name_ua: 'Кронштейн кріплення підрамника',
        tooltip: {
            cz: 'Držák uchycení pomocného rámu',
            en: 'Subframe mounting bracket',
            ru: 'Кронштейн крепления подрамника',
            ua: 'Кронштейн кріплення підрамника',
            bg: 'Скоба за закрепване на подрамника'
        },
        location: '[-65,316, -68,42, 0,45]'
    },
    {
        id: '1592792f-1df9-4e3a-aebc-5aaea8b2d30f',
        name: 'Sedan_Back_Axle_2.029',
        'location _x': -57.331,
        location_x: -5.21,
        location_z: 4.512,
        vertices: 94,
        polygons: 81,
        name_ua: 'Тарілка пружини підвіски',
        tooltip: {
            cz: 'Talíř pružiny zavěšení',
            en: 'Suspension spring plate',
            ru: 'Тарелка пружины подвески',
            ua: 'Тарілка пружини підвіски',
            bg: 'Плоча на пружината на окачването'
        },
        location: '[-57,331, -5,21, 4,512]'
    },
    {
        id: '0078f287-5131-49fe-b9aa-e133c03ed3ab',
        name: 'Sedan_Back_Axle_2.030',
        'location _x': -56.24,
        location_x: -3.876,
        location_z: 27.833,
        vertices: 320,
        polygons: 270,
        name_ua: 'Тарілка пружини підвіски',
        tooltip: {
            cz: 'Talíř pružiny zavěšení',
            en: 'Suspension spring plate',
            ru: 'Тарелка пружины подвески',
            ua: 'Тарілка пружини підвіски',
            bg: 'Плоча на пружината на окачването'
        },
        location: '[-56,24, -3,876, 27,833]'
    },
    {
        id: 'fb415e45-b96a-43a9-8729-056916b41f0e',
        name: 'Sedan_Back_Axle_2.031',
        'location _x': -67.753,
        location_x: -7.292,
        location_z: 18.603,
        vertices: 328,
        polygons: 286,
        name_ua: 'Права тяга заднього стабілізатора підвіски',
        tooltip: {
            cz: 'Rack / těžký stabilizátor',
            en: 'Stabilizer link',
            ru: 'Стойка / тяга стабилизатора',
            ua: 'Стійка / тяжка стабілізатора',
            bg: 'Биалетка'
        },
        location: '[-67,753, -7,292, 18,603]'
    },
    {
        id: '0c7ae781-cc70-4823-8765-434d5adaab84',
        name: 'Sedan_Back_Axle_2.032',
        'location _x': -76.077,
        location_x: -17.146,
        location_z: 11.04,
        vertices: 837,
        polygons: 777,
        name_ua: 'Ступична цапфа лівого заднього колеса',
        tooltip: {
            cz: 'Výměna otočného kloubu (čepu)',
            en: 'Replacement of steering knuckle (spindle)',
            ru: 'Замена поворотного кулака (цапфы)',
            ua: 'Заміна кулака поворотного (цапфи)',
            bg: 'Смяна на въртящия се кокал (шпиндела)'
        },
        location: '[-76,077, -17,146, 11,04]'
    },
    {
        id: '92ecea12-0f16-4647-902f-a8254f03cc22',
        name: 'Sedan_Back_Axle_2.033',
        'location _x': -85.278,
        location_x: -17.788,
        location_z: 11.332,
        vertices: 390,
        polygons: 340,
        name_ua: 'Маточина заднього правого колеса',
        tooltip: {
            cz: 'Náboj (matice)',
            en: 'Hub (wheel hub)',
            ru: 'Ступица (маточина)',
            ua: 'Ступиця (Маточина)',
            bg: 'Хъб (маточина)'
        },
        location: '[-85,278, -17,788, 11,332]'
    },
    {
        id: 'd81fc12e-ceb7-4ea4-80e1-a90966d8aebb',
        name: 'Sedan_Back_Axle_2.034',
        'location _x': -85.833,
        location_x: -17.765,
        location_z: 11.249,
        vertices: 79,
        polygons: 69,
        name_ua: 'Підшипник маточини заднього правого колеса',
        tooltip: {
            cz: 'Ložisko náboje kola',
            en: 'Wheel bearing',
            ru: 'Подшипник ступицы колеса',
            ua: 'Підшипник ступиці (маточини) колеса',
            bg: 'Лагер на главината на колелото'
        },
        location: '[-85,833, -17,765, 11,249]'
    },
    {
        id: 'fb993941-a421-419b-ad45-b9e9c82ff006',
        name: 'Sedan_Back_Axle_2.035',
        'location _x': -68.212,
        location_x: -36.617,
        location_z: 8.631,
        vertices: 208,
        polygons: 176,
        name_ua: "З'єднувальний важіль",
        tooltip: {
            cz: 'Spojovací rameno',
            en: 'Connecting arm',
            ru: 'Соединительный рычаг',
            ua: "З'єднувальний важіль",
            bg: 'Свързващо рамо'
        },
        location: '[-68,212, -36,617, 8,631]'
    },
    {
        id: '2f36dec7-35d3-423f-9efd-308144f9a575',
        name: 'Sedan_Back_Axle_2.036',
        'location _x': -65.344,
        location_x: -17.676,
        location_z: 11.256,
        vertices: 230,
        polygons: 210,
        name_ua: 'Зовнішній ШРКШ заднього правого колеса',
        tooltip: {
            cz: 'Vnější spojler',
            en: 'Outer CV joint',
            ru: 'Внешний спойлер',
            ua: 'ШРКШ зовнішній',
            bg: 'Външен спойлер'
        },
        location: '[-65,344, -17,676, 11,256]'
    },
    {
        id: '93f2cf22-005f-4046-88f8-11f62e4d8d96',
        name: 'Sedan_Back_Axle_2.037',
        'location _x': -42.373,
        location_x: -19.21,
        location_z: 13.296,
        vertices: 20,
        polygons: 10,
        name_ua: 'Вал ШРКШ заднього правого колеса',
        tooltip: {
            cz: 'Hnací hřídel',
            en: 'Driving axle',
            ru: 'Вал приводный',
            ua: 'Вал приводний',
            bg: 'Задвижващ вал'
        },
        location: '[-42,373, -19,21, 13,296]'
    },
    {
        id: '6e9d0bf9-beaf-4359-9784-cd93834cefff',
        name: 'Sedan_Back_Axle_2.038',
        'location _x': -58.961,
        location_x: -22.208,
        location_z: 48.033,
        vertices: 181,
        polygons: 171,
        name_ua: 'Пильник, відбійник  правого заднього амортизатора',
        tooltip: {
            cz: 'Nárazník tlumiče',
            en: 'Shock absorber bump',
            ru: 'Отбойник амортизатора',
            ua: 'Відбійник амортизатора',
            bg: 'Амортисьорен накрайник'
        },
        location: '[-58,961, -22,208, 48,033]'
    },
    {
        id: 'b26368f4-4ef9-442c-abce-20b00dbec47c',
        name: 'Sedan_Back_Axle_2.062',
        'location _x': -58.267,
        location_x: -20.988,
        location_z: 55.567,
        vertices: 246,
        polygons: 214,
        name_ua: 'Опора правого заднього амортизатора',
        tooltip: {
            cz: 'Podpora tlumiče',
            en: 'Shock absorber mount',
            ru: 'Опора амортизатора',
            ua: 'Опора амортизатора',
            bg: 'Опора на амортисьора'
        },
        location: '[-58,267, -20,988, 55,567]'
    },
    {
        id: '4ca4ea41-91a0-4987-9f5d-27650755d370',
        name: 'Sedan_Back_Axle_2.040',
        'location _x': -66.16,
        location_x: -29.235,
        location_z: 6.288,
        vertices: 95,
        polygons: 71,
        name_ua: 'Правий задній амортизатор',
        tooltip: {
            cz: 'Odpružený tlumič',
            en: 'Shock absorber',
            ru: 'Амортизатор подвески',
            ua: 'Амортизатор підвіски',
            bg: 'Амортисьор на окачването'
        },
        location: '[-66,16, -29,235, 6,288]'
    },
    {
        id: 'f8a257ec-c35a-4847-9e06-9722e4db6e79',
        name: 'Sedan_Back_Axle_2.041',
        'location _x': 50.238,
        location_x: -38.771,
        location_z: 2.272,
        vertices: 226,
        polygons: 200,
        name_ua: 'Кульова опора лівого заднього нижнього важеля підвіски',
        tooltip: {
            cz: 'Podpora je kulovitá',
            en: 'Ball joint',
            ru: 'Опора шаровая',
            ua: 'Опора кульова',
            bg: 'Шарнир'
        },
        location: '[50,238, -38,771, 2,272]'
    },
    {
        id: '5bf3f90f-5737-4e1b-92b1-7a6468b5917e',
        name: 'Sedan_Back_Axle_2.006',
        'location _x': 67.792,
        location_x: -7.232,
        location_z: 19.727,
        vertices: 303,
        polygons: 266,
        name_ua: 'Ліва тяга заднього стабілізатора підвіски',
        tooltip: {
            cz: 'Rack / těžký stabilizátor',
            en: 'Stabilizer link',
            ru: 'Стойка / тяга стабилизатора',
            ua: 'Стійка / тяжка стабілізатора',
            bg: 'Биалетка'
        },
        location: '[67,792, -7,232, 19,727]'
    },
    {
        id: '61082725-ac6a-42c0-8533-6ef95d59e48b',
        name: 'Sedan_Back_Axle_2.044',
        'location _x': 85.17,
        location_x: -17.795,
        location_z: 11.325,
        vertices: 392,
        polygons: 340,
        name_ua: 'Маточина заднього лівого колеса',
        tooltip: {
            cz: 'Náboj (matice)',
            en: 'Hub (wheel hub)',
            ru: 'Ступица (маточина)',
            ua: 'Ступиця (Маточина)',
            bg: 'Хъб (маточина)'
        },
        location: '[85,17, -17,795, 11,325]'
    },
    {
        id: '3bd14b02-fbac-4f17-b5cd-a7de908fc527',
        name: 'Sedan_Back_Axle_2.045',
        'location _x': 85.918,
        location_x: -17.76,
        location_z: 11.283,
        vertices: 75,
        polygons: 68,
        name_ua: 'Підшипник маточини заднього лівого колеса',
        tooltip: {
            cz: 'Ložisko náboje kola',
            en: 'Wheel bearing',
            ru: 'Подшипник ступицы колеса',
            ua: 'Підшипник ступиці (маточини) колеса',
            bg: 'Лагер на главината на колелото'
        },
        location: '[85,918, -17,76, 11,283]'
    },
    {
        id: '42f82b66-bcda-492c-8b92-ce32fe1a9283',
        name: 'Sedan_Back_Axle_2.046',
        'location _x': 65.592,
        location_x: -17.818,
        location_z: 11.257,
        vertices: 200,
        polygons: 190,
        name_ua: 'Зовнішній ШРКШ заднього лівого колеса',
        tooltip: {
            cz: 'Manžeta vnějšího spojlera',
            en: 'Outer CV joint boot',
            ru: 'Пыльник внешнего шланга',
            ua: 'Пильник ШРКШ зовнішнього',
            bg: 'Маншет на външния шланг'
        },
        location: '[65,592, -17,818, 11,257]'
    },
    {
        id: 'fa7ab171-8c08-444c-870a-9bba7a737614',
        name: 'Sedan_Back_Axle_2.047',
        'location _x': 76.028,
        location_x: -16.861,
        location_z: 10.917,
        vertices: 944,
        polygons: 798,
        name_ua: 'Ступична цапфа правого заднього колеса',
        tooltip: {
            cz: 'Výměna otočného kloubu (čepu)',
            en: 'Replacement of steering knuckle (spindle)',
            ru: 'Замена поворотного кулака (цапфы)',
            ua: 'Заміна кулака поворотного (цапфи)',
            bg: 'Смяна на въртящия се кокал (шпиндела)'
        },
        location: '[76,028, -16,861, 10,917]'
    },
    {
        id: 'b46cc5f8-716a-4a8c-95ed-dcbcc44b0b1f',
        name: 'Sedan_Back_Axle_2.048',
        'location _x': 29.088,
        location_x: -20.562,
        location_z: 15.334,
        vertices: 190,
        polygons: 180,
        name_ua: 'Внутрішній ШРКШ заднього лівого колеса',
        tooltip: {
            cz: 'Manžeta vnějšího spojlera',
            en: 'Outer CV joint boot',
            ru: 'Пыльник внешнего шланга',
            ua: 'Пильник ШРКШ зовнішнього',
            bg: 'Маншет на външния шланг'
        },
        location: '[29,088, -20,562, 15,334]'
    },
    {
        id: 'cdb33bc6-8246-43de-8c7c-938aab02c514',
        name: 'Sedan_Back_Axle_2.049',
        'location _x': -20.734,
        location_x: -20.743,
        location_z: 15.336,
        vertices: 190,
        polygons: 180,
        name_ua: 'Внутрішній ШРКШ заднього правого колеса',
        tooltip: {
            cz: 'Vnější spojler',
            en: 'Outer CV joint',
            ru: 'Внешний спойлер',
            ua: 'ШРКШ зовнішній',
            bg: 'Външен спойлер'
        },
        location: '[-20,734, -20,743, 15,336]'
    },
    {
        id: 'cc1a5ec8-b4b5-4185-9cf4-038ef3359aca',
        name: 'Sedan_Back_Axle_2.055',
        'location _x': 42.622,
        location_x: -19.647,
        location_z: 13.975,
        vertices: 30,
        polygons: 10,
        name_ua: 'Вал ШРКШ заднього лівого колеса',
        tooltip: {
            cz: 'Hnací hřídel',
            en: 'Driving axle',
            ru: 'Вал приводный',
            ua: 'Вал приводний',
            bg: 'Задвижващ вал'
        },
        location: '[42,622, -19,647, 13,975]'
    },
    {
        id: '1ac947ed-73b9-4d77-8e7d-d059d0a2c8a4',
        name: 'Sedan_Back_Axle_2.050',
        'location _x': 3.594,
        location_x: -27.989,
        location_z: 14.154,
        vertices: 2554,
        polygons: 2218,
        name_ua: 'Диференціал задній',
        tooltip: {
            cz: 'Kardanový hřídel',
            en: 'Propeller shaft',
            ru: 'Вал карданный',
            ua: 'Вал карданний',
            bg: 'Кардан'
        },
        location: '[3,594, -27,989, 14,154]'
    },
    {
        id: '9af41e48-027d-424c-b5c6-5130cabec432',
        name: 'Sedan_Back_Axle_2.056',
        'location _x': 58.989,
        location_x: -22.208,
        location_z: 47.937,
        vertices: 182,
        polygons: 175,
        name_ua: 'Пильник, відбійник  лівого заднього амортизатора',
        tooltip: {
            cz: 'Manžeta tlumiče',
            en: 'Shock absorber dust cover',
            ru: 'Пыльник амортизатора',
            ua: 'Пильник амортизатора',
            bg: 'Маншет на амортисьора'
        },
        location: '[58,989, -22,208, 47,937]'
    },
    {
        id: '444de518-e0e6-496e-8bcf-d14b14b2a309',
        name: 'Sedan_Back_Axle_2.057',
        'location _x': 66.744,
        location_x: -29.96,
        location_z: 3.415,
        vertices: 93,
        polygons: 67,
        name_ua: 'Лівий задній амортизатор',
        tooltip: {
            cz: 'Odpružený tlumič',
            en: 'Shock absorber',
            ru: 'Амортизатор подвески',
            ua: 'Амортизатор підвіски',
            bg: 'Амортисьор на окачването'
        },
        location: '[66,744, -29,96, 3,415]'
    },
    {
        id: '6c9d4f73-8ce2-4d42-afd9-30a74534960d',
        name: 'Sedan_Back_Axle_2.058',
        'location _x': 54.04,
        location_x: -16.314,
        location_z: 5.704,
        vertices: 990,
        polygons: 816,
        name_ua: 'Лівий задній нижній важіль підвіски',
        tooltip: {
            cz: 'Rameno podélného zavěšení',
            en: 'Wishbone',
            ru: 'Продольный рычаг подвески',
            ua: 'Поздовжній важіль підвіски',
            bg: 'Надлъжен носач за окачване'
        },
        location: '[54,04, -16,314, 5,704]'
    },
    {
        id: 'ded894b8-0d90-4b9f-b636-19a7e81beb2e',
        name: 'Sedan_Back_Axle_2.061',
        'location _x': 68.281,
        location_x: -36.862,
        location_z: 9.221,
        vertices: 240,
        polygons: 200,
        name_ua: "З'єднувальний важіль",
        tooltip: {
            cz: 'Spojovací rameno',
            en: 'Connecting arm',
            ru: 'Соединительный рычаг',
            ua: "З'єднувальний важіль",
            bg: 'Свързващо рамо'
        },
        location: '[68,281, -36,862, 9,221]'
    },
    {
        id: 'c52dc637-a733-4382-8435-2233ad6cace3',
        name: 'Sedan_Back_Axle_2.039',
        'location _x': 58.267,
        location_x: -20.988,
        location_z: 55.567,
        vertices: 246,
        polygons: 214,
        name_ua: 'Опора лівого заднього амортизатора',
        tooltip: {
            cz: 'Podpora tlumiče',
            en: 'Shock absorber mount',
            ru: 'Опора амортизатора',
            ua: 'Опора амортизатора',
            bg: 'Опора на амортисьора'
        },
        location: '[58,267, -20,988, 55,567]'
    },
    {
        id: 'd64eee8d-f3f1-4e9a-9905-3d547e6774aa',
        name: 'Sedan_Back_Axle_2.063',
        'location _x': -82.305,
        location_x: -5.165,
        location_z: 13.016,
        vertices: 373,
        polygons: 379,
        name_ua: 'Скоба заднього правого гальмівного супорта',
        tooltip: {
            cz: 'Držák brzdového třmenu',
            en: 'Bracket of brake calipers',
            ru: 'Скоба тормозных суппортов',
            ua: 'Скоба гальмівного супорта',
            bg: 'Скоба за спирачен апарат'
        },
        location: '[-82,305, -5,165, 13,016]'
    },
    {
        id: 'de78d455-93e6-41d1-a972-270f4db4ad9b',
        name: 'Sedan_Back_Axle_2.064',
        'location _x': -84.083,
        location_x: -2.495,
        location_z: 13.634,
        vertices: 133,
        polygons: 136,
        name_ua: 'Ремкомплект заднього правого гальмівного супорта',
        tooltip: {
            cz: 'Vodící piny brzdového třmenu',
            en: 'Caliper guide pins',
            ru: 'Направляющие суппортов',
            ua: 'Направляючі супортів',
            bg: 'Направлящи на спиралите'
        },
        location: '[-84,083, -2,495, 13,634]'
    },
    {
        id: '95806f4d-a778-432f-b55c-2fef85ff8c16',
        name: 'Sedan_Back_Axle_2.065',
        'location _x': 82.305,
        location_x: -5.227,
        location_z: 13.413,
        vertices: 373,
        polygons: 379,
        name_ua: 'Скоба заднього лівого гальмівного супорта',
        tooltip: {
            cz: 'Držák brzdového třmenu',
            en: 'Bracket of brake calipers',
            ru: 'Скоба тормозных суппортов',
            ua: 'Скоба гальмівного супорта',
            bg: 'Скоба за спирачен апарат'
        },
        location: '[82,305, -5,227, 13,413]'
    },
    {
        id: '349d50ba-9cb4-4b67-850a-6dca9258e203',
        name: 'Sedan_Back_Axle_2.066',
        'location _x': 84.083,
        location_x: -2.495,
        location_z: 13.639,
        vertices: 133,
        polygons: 136,
        name_ua: 'Ремкомплект заднього лівого гальмівного супорта',
        tooltip: {
            cz: 'Vodící piny brzdového třmenu',
            en: 'Caliper guide pins',
            ru: 'Направляющие суппортов',
            ua: 'Направляючі супортів',
            bg: 'Направлящи на спиралите'
        },
        location: '[84,083, -2,495, 13,639]'
    },
    {
        id: '54937fd4-fc66-4c7a-aef4-6c0161fef146',
        name: 'Sedan_Back_Axle_2.067',
        'location _x': 63.607,
        location_x: -12.77,
        location_z: 27.959,
        vertices: 164,
        polygons: 150,
        name_ua: 'Лівий задній верхній важіль підвіски',
        tooltip: {
            cz: 'Rameno podélného zavěšení',
            en: 'Wishbone',
            ru: 'Продольный рычаг подвески',
            ua: 'Поздовжній важіль підвіски',
            bg: 'Надлъжен носач за окачване'
        },
        location: '[63,607, -12,77, 27,959]'
    },
    {
        id: 'af9beb02-45f4-4b53-9ad8-54dff7ea570c',
        name: 'Sedan_Back_Axle_2.068',
        'location _x': 76.347,
        location_x: -12.07,
        location_z: 28.392,
        vertices: 114,
        polygons: 96,
        name_ua: 'Сайлентблок лівого заднього верхнього важеля підвіски',
        tooltip: {
            cz: 'Tichý blok páky',
            en: 'Lever silent block',
            ru: 'Сайлентблок рычага',
            ua: 'Сайлентблок важеля',
            bg: 'Тампон на носач'
        },
        location: '[76,347, -12,07, 28,392]'
    },
    {
        id: '4b2238a5-63c9-4e8d-98f1-34fc4a1922b2',
        name: 'Sedan_Back_Axle_2.069',
        'location _x': -50.914,
        location_x: -13.548,
        location_z: 28.667,
        vertices: 114,
        polygons: 96,
        name_ua: 'Сайлентблок правого заднього верхнього важеля підвіски',
        tooltip: {
            cz: 'Tichý blok páky',
            en: 'Lever silent block',
            ru: 'Сайлентблок рычага',
            ua: 'Сайлентблок важеля',
            bg: 'Тампон на носач'
        },
        location: '[-50,914, -13,548, 28,667]'
    },
    {
        id: '07ceb52b-2fda-4652-8bce-2a83171fe389',
        name: 'Sedan_Back_Axle_2.070',
        'location _x': -76.347,
        location_x: -12.07,
        location_z: 28.392,
        vertices: 114,
        polygons: 96,
        name_ua: 'Сайлентблок правого заднього верхнього важеля підвіски',
        tooltip: {
            cz: 'Tichý blok páky',
            en: 'Lever silent block',
            ru: 'Сайлентблок рычага',
            ua: 'Сайлентблок важеля',
            bg: 'Тампон на носач'
        },
        location: '[-76,347, -12,07, 28,392]'
    },
    {
        id: '9eb64fe7-5e2c-4f1c-aef4-8ed9bc052350',
        name: 'Sedan_Back_Axle_2.071',
        'location _x': -63.483,
        location_x: -55.973,
        location_z: 10.005,
        vertices: 40,
        polygons: 30,
        name_ua: 'Сайлентблок підрамника правий',
        tooltip: {
            cz: 'Silentblok zadního podvozku',
            en: 'Rear subframe bushing',
            ru: 'Сайлентблок заднего подрамника',
            ua: 'Сайлентблок заднього підрамника',
            bg: 'Silent блок на задния подрамник'
        },
        location: '[-63,483, -55,973, 10,005]'
    },
    {
        id: '44a36740-8513-495b-9cba-f2ec2d088cd0',
        name: 'Sedan_Back_Axle_2.073',
        'location _x': 48.565,
        location_x: 16.337,
        location_z: 23.476,
        vertices: 100,
        polygons: 80,
        name_ua: 'Гідравлічний сайлентблок підрамника лівий',
        tooltip: {
            cz: 'Silentblok zadního podvozku',
            en: 'Rear subframe bushing',
            ru: 'Сайлентблок заднего подрамника',
            ua: 'Сайлентблок заднього підрамника',
            bg: 'Silent блок на задния подрамник'
        },
        location: '[48,565, 16,337, 23,476]'
    },
    {
        id: 'c2c6f612-ae44-4f9b-8d9a-c89c80228982',
        name: 'Sedan_Back_Axle_2.074',
        'location _x': -48.587,
        location_x: 16.298,
        location_z: 18.369,
        vertices: 152,
        polygons: 126,
        name_ua: 'Гідравлічний сайлентблок підрамника правий',
        tooltip: {
            cz: 'Silentblok zadního podvozku',
            en: 'Rear subframe bushing',
            ru: 'Сайлентблок заднего подрамника',
            ua: 'Сайлентблок заднього підрамника',
            bg: 'Silent блок на задния подрамник'
        },
        location: '[-48,587, 16,298, 18,369]'
    },
    {
        id: '08f2eff8-936c-4345-900c-34c39ece2c2a',
        name: 'Sedan_Back_Axle_2.075',
        'location _x': -39.239,
        location_x: -4.324,
        location_z: 2.284,
        vertices: 60,
        polygons: 52,
        name_ua: 'Сайлентблок правого заднього нижнього важеля підвіски',
        tooltip: {
            cz: 'Tichý blok páky',
            en: 'Lever silent block',
            ru: 'Сайлентблок рычага',
            ua: 'Сайлентблок важеля',
            bg: 'Тампон на носач'
        },
        location: '[-39,239, -4,324, 2,284]'
    },
    {
        id: '8c43377c-52fc-4f9d-95d1-d2a3247209be',
        name: 'Sedan_Back_Axle_2.076',
        'location _x': -75.295,
        location_x: -4.778,
        location_z: 0.164,
        vertices: 60,
        polygons: 52,
        name_ua: 'Сайлентблок правого заднього нижнього важеля підвіски',
        tooltip: {
            cz: 'Tichý blok páky',
            en: 'Lever silent block',
            ru: 'Сайлентблок рычага',
            ua: 'Сайлентблок важеля',
            bg: 'Тампон на носач'
        },
        location: '[-75,295, -4,778, 0,164]'
    },
    {
        id: '76a934d8-9942-445b-8058-1a26f2c12614',
        name: 'Sedan_Back_Axle_2.078',
        'location _x': 39.239,
        location_x: -4.324,
        location_z: 2.284,
        vertices: 60,
        polygons: 52,
        name_ua: 'Сайлентблок лівого заднього нижнього важеля підвіски',
        tooltip: {
            cz: 'Tichý blok páky',
            en: 'Lever silent block',
            ru: 'Сайлентблок рычага',
            ua: 'Сайлентблок важеля',
            bg: 'Тампон на носач'
        },
        location: '[39,239, -4,324, 2,284]'
    },
    {
        id: '882f0250-b45f-4481-ad10-2c0550aa7be9',
        name: 'Sedan_Back_Axle_2.079',
        'location _x': 75.294,
        location_x: -4.778,
        location_z: 0.164,
        vertices: 60,
        polygons: 52,
        name_ua: 'Сайлентблок лівого заднього нижнього важеля підвіски',
        tooltip: {
            cz: 'Tichý blok páky',
            en: 'Lever silent block',
            ru: 'Сайлентблок рычага',
            ua: 'Сайлентблок важеля',
            bg: 'Тампон на носач'
        },
        location: '[75,294, -4,778, 0,164]'
    },
    {
        id: 'e5aa7f64-3733-4a94-80e2-7d957030b918',
        name: 'Sedan_Back_Axle_2.080',
        'location _x': 63.716,
        location_x: -55.99,
        location_z: 7.711,
        vertices: 150,
        polygons: 120,
        name_ua: 'Сайлентблок підрамника лівий',
        tooltip: {
            cz: 'Silentblok zadního podvozku',
            en: 'Rear subframe bushing',
            ru: 'Сайлентблок заднего подрамника',
            ua: 'Сайлентблок заднього підрамника',
            bg: 'Silent блок на задния подрамник'
        },
        location: '[63,716, -55,99, 7,711]'
    },
    {
        id: '81afb5c8-21b0-419b-b811-cf487b8330b8',
        name: 'Sedan_Back_Axle_2.081',
        'location _x': -39.115,
        location_x: -24.074,
        location_z: 23.444,
        vertices: 120,
        polygons: 89,
        name_ua: 'Втулка заднього стабілізатора підвіски',
        tooltip: {
            cz: 'Vložky stabilizátoru',
            en: 'Stabilizer bushings',
            ru: 'Втулки стабилизатора',
            ua: 'Втулки стабілізатора',
            bg: 'Втулки на стабилизатора'
        },
        location: '[-39,115, -24,074, 23,444]'
    }
];
