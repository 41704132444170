/* eslint-disable max-classes-per-file */
import { CloseOutlined, DeleteOutlined, FormOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, InputNumber, Modal, Select, Table, Tabs, TreeSelect, notification } from 'antd';
import { AvailabilityIndicator } from 'components';
import { MODALS, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { DetailStorageModal, DetailSupplierModal, OilModal, StockProductsModal } from 'modals';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { TabPane } = Tabs;
const { Option } = Select;
const { confirm } = Modal;

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class DetailProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            radioValue: '1',
            mainTableSource: [],
            relatedDetails: [],
            relatedServices: [],
            relatedDetailsCheckbox: false,
            relatedServicesCheckbox: false,
            defaultBrandName: undefined
        };
        this.labors = [];
        this.storeGroups = [];
        this.suppliers = [];
        this.treeData = [];
        this.servicesOptions = [];
        this.suppliersOptions = [];

        this.setCode = this.setCode.bind(this);
        this.setSupplier = this.setSupplier.bind(this);
        this.setVinDetail = this.setVinDetail.bind(this);

        this.columns = () => [
            {
                key: 'checked',
                dataIndex: 'checked',
                width: 'min-content',
                render: (data, elem) => {
                    return elem.related ? (
                        <Checkbox
                            checked={data}
                            onChange={({ target }) => {
                                elem.checked = target.checked;
                                this.setState({});
                            }}
                        />
                    ) : null;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.store_group' />,
                key: 'storeGroupId',
                dataIndex: 'storeGroupId',
                width: '12%',
                render: (data, elem) => {
                    return (
                        <TreeSelect
                            className={Styles.groupsTreeSelect}
                            data-qa='tree_select_storeGroupId_detail_product_modal'
                            disabled={this.state.editing || elem.related}
                            filterTreeNode={(input, node) => {
                                return (
                                    node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            getPopupContainer={() => document.querySelector('.addDetailModal')}
                            listHeight={440}
                            onSelect={async (value, option) => {
                                elem.storeGroupId = value;
                                elem.detailName = option.name;
                                elem.multiplier = option.multiplier;
                                if (this.state.radioValue !== '2') {
                                    await this.getDefaultValues(value);
                                }
                                if (!elem.related) {
                                    await this.getRelatedDetails(value);
                                }
                                await this.filterOptions(value);
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.store_group'
                            })}
                            popupMatchSelectWidth={280}
                            showSearch
                            treeData={this.props.treeData}
                            value={data}
                        />
                    );
                }
            },
            {
                width: 'min-content',
                key: 'vin',
                render: elem => {
                    return (
                        <Button
                            data-qa='button_setModal_vin_detail_product_modal'
                            disabled={
                                isForbidden(this.props.user, permissions.ACCESS_ORDER_DETAILS_VIN) ||
                                isForbidden(this.props.user, permissions.ACCESS_ORDER_DETAILS_CRUD)
                            }
                            onClick={() => {
                                this.props.setModal(MODALS.VIN_MODAL, {
                                    storeGroupId: this.state.radioValue !== '2' ? elem.storeGroupId : undefined,
                                    setVinDetail: this.setVinDetail
                                });
                            }}
                            style={{
                                fontWeight: 500
                            }}
                        >
                            VIN
                        </Button>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'detailName',
                dataIndex: 'detailName',
                width: '20%',
                render: (data, elem) => {
                    return (
                        <Input
                            data-qa='input_detailName_detail_product_modal'
                            disabled={elem.storeGroupId == null && this.state.radioValue === '1'}
                            onChange={event => {
                                elem.detailName = event.target.value;
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.detail_name'
                            })}
                            style={{ minWidth: 150 }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brandId',
                dataIndex: 'brandId',
                width: '10%',
                className: Styles.brandColumn,
                render: (data, elem) => {
                    if (elem.brandName && !elem.brandId) {
                        const defaultBrand = this.props.brands.find(brand => brand.brandName == elem.brandName);
                        if (defaultBrand) {
                            elem.brandId = defaultBrand.brandId;
                            this.setState({});
                        }
                    }

                    return (
                        <Select
                            allowClear
                            data-qa='select_brandId_detail_product_modal'
                            disabled={elem.storeGroupId == null && this.state.radioValue === '1'}
                            filterOption={(input, option) => {
                                return (
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            getPopupContainer={() => document.querySelector('.addDetailModal')}
                            listHeight={440}
                            onChange={(value, option) => {
                                this.unsetSupplier(elem.key, elem.related);
                                elem.brandId = value;
                                elem.brandName = option ? option.children : undefined;
                                if (!option) {
                                    this.state.relatedDetails = [];
                                }
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.brand'
                            })}
                            popupMatchSelectWidth={280}
                            showSearch
                            style={{ maxWidth: 180, minWidth: 100 }}
                            value={data || undefined}
                        >
                            {this.props.brands.map((elem, index) => (
                                <Option key={index} supplier_id={elem.supplierId} value={elem.brandId}>
                                    {elem.brandName}
                                </Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'detailCode',
                dataIndex: 'detailCode',
                width: '15%',
                render: (data, elem) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Input
                                allowClear
                                data-qa='input_detailCode_detail_product_modal'
                                disabled={elem.storeGroupId == null && this.state.radioValue === '1'}
                                onChange={({ target }) => {
                                    const { value } = target;
                                    elem.detailCode = value;
                                    if (!value) {
                                        this.unsetSupplier();
                                        elem.detailCode = undefined;
                                    }
                                    this.unsetSupplier(elem.key, elem.related);
                                    this.setState({});
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_code'
                                })}
                                style={{ minWidth: 80, color: 'black' }}
                                value={data}
                            />
                            {this.state.radioValue !== '4' ? (
                                this.state.radioValue === '5' ? (
                                    <StockProductsModal
                                        brandFilter={elem.brandName}
                                        brandId={elem.brandId == 8000 ? undefined : elem.brandId}
                                        codeFilter={
                                            elem.detailCode
                                                ? String(elem.detailCode).replace(/[ \\|\//|'"*?.,:;+!#@%()<>{}-]/g, '')
                                                : undefined
                                        }
                                        codeSearch={this.state.radioValue == 3}
                                        defaultBrandName={this.state.defaultBrandName}
                                        disabled={
                                            (elem.storeGroupId == null &&
                                                this.state.radioValue !== '3' &&
                                                this.state.radioValue !== '5') ||
                                            this.state.radioValue === '2' ||
                                            (this.state.radioValue === '3' && (data || '').length < 3) ||
                                            (this.state.radioValue === '5' &&
                                                isForbidden(this.props.user, permissions.ACCESS_ORDER_DETAILS_STOCK))
                                        }
                                        onSelect={(...args) => {
                                            this.setCode(elem.related, ...args);
                                        }}
                                        setSupplier={(...args) => {
                                            this.setSupplier(elem.related, ...args);
                                        }}
                                        storeGroupId={elem.storeGroupId}
                                        supplierId={elem.supplierId}
                                        tableKey={elem.key}
                                        tecdocId={this.props.tecdocId}
                                        user={this.props.user}
                                    />
                                ) : (
                                    <DetailStorageModal
                                        brandFilter={elem.brandName}
                                        brandId={elem.brandId == 8000 ? undefined : elem.brandId}
                                        codeFilter={
                                            elem.detailCode
                                                ? String(elem.detailCode).replace(/[ \\|\//|'"*?.,:;+!#@%()<>{}-]/g, '')
                                                : undefined
                                        }
                                        codeSearch={this.state.radioValue == 3}
                                        defaultBrandName={this.state.defaultBrandName}
                                        disabled={
                                            (elem.storeGroupId == null &&
                                                this.state.radioValue !== '3' &&
                                                this.state.radioValue !== '5') ||
                                            this.state.radioValue === '2' ||
                                            (this.state.radioValue === '3' && (data || '').length < 3) ||
                                            (this.state.radioValue === '5' &&
                                                isForbidden(this.props.user, permissions.ACCESS_ORDER_DETAILS_STOCK))
                                        }
                                        onSelect={(...args) => {
                                            this.setCode(elem.related, ...args);
                                        }}
                                        setSupplier={(...args) => {
                                            this.setSupplier(elem.related, ...args);
                                        }}
                                        storeGroupId={elem.storeGroupId}
                                        supplierId={elem.supplierId}
                                        tableKey={elem.key}
                                        tecdocId={this.props.tecdocId}
                                        user={this.props.user}
                                    />
                                )
                            ) : (
                                <OilModal
                                    brands={this.props.brands}
                                    clearOilData={this.props.clearOilData}
                                    disabled={isForbidden(this.props.user, permissions.ACCESS_ORDER_DETAILS_OILS)}
                                    oilModalData={this.props.oilModalData}
                                    onSelect={(...args) => {
                                        this.setCode(elem.related, ...args);
                                    }}
                                    setSupplier={(...args) => {
                                        this.setSupplier(elem.related, ...args);
                                    }}
                                    showOilModal={this.props.showOilModal}
                                    storeGroupId={elem.storeGroupId}
                                    // codeFilter={elem.detailCode ? String(elem.detailCode).replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g,'') : undefined}
                                    tableKey={elem.key}
                                    tecdocId={this.props.tecdocId}
                                    user={this.props.user}
                                />
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.supplier' />,
                key: 'supplier',
                dataIndex: 'supplierName',
                width: '15%',
                render: (data, elem) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Select
                                data-qa='select_supplierName_detail_product_modal'
                                filterOption={(input, option) => {
                                    return (
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.value).indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                getPopupContainer={() => document.querySelector('.addDetailModal')}
                                listHeight={440}
                                onSelect={(value, option) => {
                                    this.unsetSupplier(elem.key, elem.related);
                                    elem.supplierId = value;
                                    this.setState({});
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.supplier'
                                })}
                                popupMatchSelectWidth={280}
                                showSearch
                                style={{ minWidth: 160, maxWidth: 200 }}
                                value={elem.supplierId || undefined}
                            >
                                {this.suppliersOptions}
                            </Select>
                            <DetailSupplierModal
                                brandId={elem.brandId}
                                detailCode={elem.detailCode}
                                disabled={
                                    (this.state.radioValue !== '2' &&
                                        this.state.radioValue !== '3' &&
                                        elem.storeGroupId == null) ||
                                    !elem.detailCode ||
                                    !elem.brandName ||
                                    this.state.radioValue === '5'
                                }
                                onSelect={item => {
                                    this.setSupplier(elem.related, item);
                                }}
                                storeGroupId={elem.storeGroupId}
                                tableKey={elem.key}
                                user={this.props.user}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'comment',
                dataIndex: 'comment',
                width: '3%',
                render: (data, elem) => {
                    let detail = String(elem.detailName);
                    if (detail && detail.indexOf(' - ') > -1) {
                        detail = detail.slice(0, detail.indexOf(' - '));
                    }

                    return (
                        <CommentaryButton
                            commentary={
                                data || {
                                    comment: undefined,
                                    positions: []
                                }
                            }
                            data-qa='button_comment_detail_product_modal'
                            detail={detail}
                            disabled={
                                elem.storeGroupId == null &&
                                (this.state.radioValue !== '2' || this.state.radioValue !== '4')
                            }
                            setComment={(comment, positions) => {
                                elem.comment = {
                                    comment,
                                    positions
                                };
                                elem.detailName = comment || elem.detailName;
                                this.setState({});
                            }}
                        />
                    );
                }
            },
            {
                title: (
                    <div title={this.props.intl.formatMessage({ id: 'order_form_table.AI_title' })}>
                        <FormattedMessage id='order_form_table.AI' />
                    </div>
                ),
                key: 'AI',
                dataIndex: 'store',
                width: '3%',
                render: store => {
                    return <AvailabilityIndicator indexArray={store} />;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                width: '3%',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.detailNumberInput}
                            data-qa='input_number_purchasePrice_detail_product_modal'
                            decimalSeparator=','
                            disabled={elem.storeGroupId == null && this.state.radioValue !== '2'}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            min={0}
                            onChange={value => {
                                const storeGroup = this.props.allDetails.find(detail => detail.id == elem.storeGroupId);
                                elem.purchasePrice = value;
                                elem.price = elem.purchasePrice * (storeGroup ? storeGroup.priceGroupMultiplier : 1);
                                this.setState({});
                            }}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            value={Math.round(data * 100) / 100 || 0}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                dataIndex: 'price',
                width: '3%',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.detailNumberInput}
                            data-qa='input_number_price_detail_product_modal'
                            decimalSeparator=','
                            disabled={elem.storeGroupId == null && this.state.radioValue !== '2'}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            min={1}
                            onChange={value => {
                                elem.price = value;
                                elem.sum = value * elem.count;
                                this.setState({});
                            }}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            value={Math.round(data * 100) / 100 || 1}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'count',
                dataIndex: 'count',
                width: '3%',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.detailNumberInput}
                            data-qa='input_number_count_detail_product_modal'
                            decimalSeparator=','
                            disabled={elem.storeGroupId == null && this.state.radioValue !== '2'}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            min={0.1}
                            onChange={value => {
                                elem.count = value;
                                elem.sum = value * elem.price;
                                this.setState({});
                            }}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            value={Math.round(data * 10) / 10 || 1}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'sum',
                width: '5%',
                render: elem => {
                    const sum = elem.price * (elem.count || 1);

                    return (
                        <InputNumber
                            className={Styles.detailNumberInput}
                            data-qa='input_number_sum_detail_product_modal'
                            decimalSeparator=','
                            disabled
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            style={{ color: 'black' }}
                            value={sum ? Math.round(sum * 100) / 100 : 1}
                        />
                    );
                }
            },
            {
                key: 'delete',
                width: '3%',
                render: elem => {
                    return (
                        <CloseOutlined
                            data-qa='icon_delete_detail_product_modal'
                            onClick={() => {
                                elem.storeGroupId = this.state.editing || elem.related ? elem.storeGroupId : undefined;
                                elem.detailName = undefined;
                                elem.comment = {
                                    comment: undefined,
                                    positions: []
                                };
                                elem.brandId = undefined;
                                elem.brandName = undefined;
                                elem.detailCode = undefined;
                                elem.supplierName = undefined;
                                elem.supplierId = undefined;
                                elem.supplierBrandId = undefined;
                                elem.store = null;
                                elem.purchasePrice = 0;
                                elem.price = 1;
                                elem.count = 1;
                                elem.sum = undefined;
                                this.setState({});
                            }}
                        />
                    );
                }
            }
        ];
    }

    handleOk = () => {
        const { editing, mainTableSource, relatedServices, relatedDetails, relatedDetailsCheckbox } = this.state;
        if (editing) {
            this.props.updateDetail(this.props.tableKey, { ...mainTableSource[0] });
        } else {
            const data = {
                insertMode: true,
                details: [],
                services: []
            };
            mainTableSource.map(element => {
                if (!element.productId) {
                    data.details.push({
                        storeGroupId: element.storeGroupId,
                        name: element.detailName,
                        productCode: element.detailCode,
                        supplierId: element.supplierId,
                        supplierBrandId: element.supplierBrandId || element.brandId,
                        supplierOriginalCode: element.supplierOriginalCode,
                        supplierProductNumber: element.supplierProductNumber,
                        supplierPartNumber: element.supplierPartNumber,
                        purchasePrice: Math.round(element.purchasePrice * 100) / 100 || 0,
                        count: element.count ? element.count : 1,
                        price: element.price ? Math.round(element.price * 100) / 100 : 1,
                        comment: element.comment || {
                            comment: undefined,
                            positions: []
                        }
                    });
                } else {
                    data.details.push({
                        storeGroupId: element.storeGroupId,
                        name: element.detailName,
                        productId: element.productId,
                        productCode: element.detailCode,
                        supplierBrandId: element.supplierBrandId || element.brandId,
                        purchasePrice: Math.round(element.purchasePrice * 100) / 100 || 0,
                        count: element.count ? element.count : 1,
                        price: element.price ? Math.round(element.price * 100) / 100 : 1,
                        supplierId: element.supplierId,
                        comment: element.comment || {
                            comment: undefined,
                            positions: []
                        },
                        cellAddress: element.cellAddress,
                        warehouseId: element.warehouseId
                    });
                }
            });
            if (relatedDetailsCheckbox) {
                relatedDetails.map(element => {
                    if (element.checked) {
                        if (!element.productId) {
                            data.details.push({
                                storeGroupId: element.storeGroupId,
                                name: element.detailName,
                                productCode: element.detailCode,
                                supplierId: element.supplierId,
                                supplierBrandId: element.supplierBrandId || element.brandId,
                                supplierOriginalCode: element.supplierOriginalCode,
                                supplierProductNumber: element.supplierProductNumber,
                                supplierPartNumber: element.supplierPartNumber,
                                purchasePrice: Math.round(element.purchasePrice * 100) / 100 || 0,
                                count: element.count ? element.count : 1,
                                price: element.price ? Math.round(element.price * 100) / 100 : 1,
                                comment: element.comment || {
                                    comment: undefined,
                                    positions: []
                                }
                            });
                        } else {
                            data.details.push({
                                storeGroupId: element.storeGroupId,
                                name: element.detailName,
                                productId: element.productId,
                                productCode: element.detailCode,
                                supplierBrandId: element.supplierBrandId || element.brandId,
                                purchasePrice: Math.round(element.purchasePrice * 100) / 100 || 0,
                                count: element.count ? element.count : 1,
                                price: element.price ? Math.round(element.price * 100) / 100 : 1,
                                supplierId: element.supplierId,
                                comment: element.comment || {
                                    comment: undefined,
                                    positions: []
                                }
                            });
                        }
                    }
                });
            }
            relatedServices.map(element => {
                if (element.laborId) {
                    data.services.push({
                        serviceId: element.laborId,
                        serviceHours: element.hours ? element.hours : 1,
                        servicePrice: element.price ? element.price : 0
                    });
                }
            });
            this.addDetailsAndLabors(data);
        }
        this.props.hideModal();
    };

    handleCancel = () => {
        this.setState({
            radioValue: '1',
            mainTableSource: [],
            relatedDetails: [],
            relatedDetailsCheckbox: false
        });
        this.props.hideModal();
    };

    async getRelatedDetails(id) {
        if (this.props.isMobile) {
            return;
        }
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = `${__API_URL__}/store_groups/related?id=${id}&limit=3&excludePricelist=false`;
        if (this.props.tecdocId) {
            url += `&modificationId=${this.props.tecdocId}`;
        }
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json();
            this.setState({
                relatedDetails: _.get(result, 'storeGroups', []).map((detail, key) => {
                    let formattedDetail = {
                        ...detail,
                        key,
                        related: true,
                        detailName: detail.name,
                        storeGroupId: detail.id,
                        detailCode: detail.partNumber,
                        price: Math.round(detail.sellingPrice * 10) / 10,
                        comment: {
                            comment: undefined,
                            positions: [],
                            problems: []
                        },
                        checked: true
                    };

                    delete formattedDetail.supplierName;
                    delete formattedDetail.supplierId;

                    if (detail.price) {
                        const {
                            id,
                            itemName,
                            partNumber,
                            supplierBrandId,
                            businessSupplierId,
                            businessSupplierName,
                            store,
                            purchasePrice,
                            supplierOriginalCode,
                            supplierProductNumber,
                            supplierPartNumber,
                            isFromStock,
                            defaultWarehouseId
                        } = detail.price;
                        const markup = detail.markup ? detail.markup : 1.4;

                        formattedDetail = {
                            ...formattedDetail,
                            detailName: itemName,
                            supplierBrandId,
                            supplierId: businessSupplierId,
                            supplierName: businessSupplierName,
                            productId: businessSupplierId == 0 ? id : undefined,
                            store,
                            purchasePrice: Math.round(purchasePrice * 10) / 10,
                            price:
                                Math.round(detail.sellingPrice * 10) / 10 ||
                                Math.round(purchasePrice * markup * 10) / 10,
                            supplierOriginalCode,
                            supplierProductNumber,
                            supplierPartNumber,
                            isFromStock,
                            defaultWarehouseId
                        };
                    }

                    return formattedDetail;
                })
            });
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    getDefaultValues = async storeGroupId => {
        if (!storeGroupId || !this.props.tecdocId) {
            return;
        }
        const result = await fetchAPI(
            'GET',
            'store_groups/default_detail',
            {
                storeGroupId,
                modificationId: this.props.tecdocId,
                excludePricelist: this.state.editing
            },
            null,
            { handleErrorInternally: true }
        );
        if (result) {
            const brands = this.props.brands.slice(0);
            brands.sort((x, y) => {
                return x.brandId == result.brandId ? -1 : y.brandId == result.brandId ? 1 : 0;
            });
            this.state.defaultBrandName = result.brandName;
            if (!this.state.editing) {
                this.state.mainTableSource[0].brandId = result.brandId;
                this.state.mainTableSource[0].brandName = result.brandName;
                this.state.mainTableSource[0].detailCode = result.partNumber;
                this.state.mainTableSource[0].price = Math.round(result.sellingPrice * 10) / 10;
                this.state.mainTableSource[0].count = 1;
                if (result.price) {
                    const {
                        id,
                        supplierBrandId,
                        businessSupplierId,
                        businessSupplierName,
                        store,
                        purchasePrice,
                        supplierOriginalCode,
                        supplierProductNumber,
                        supplierPartNumber,
                        isFromStock,
                        defaultWarehouseId
                    } = result.price;
                    const markup = result.markup ? result.markup : 1.4;

                    this.state.mainTableSource[0].supplierBrandId = supplierBrandId;
                    this.state.mainTableSource[0].supplierId = businessSupplierId;
                    this.state.mainTableSource[0].supplierName = businessSupplierName;
                    this.state.mainTableSource[0].productId = businessSupplierId === 0 ? id : undefined;
                    this.state.mainTableSource[0].store = store;
                    this.state.mainTableSource[0].purchasePrice = Math.round(purchasePrice * 10) / 10;
                    this.state.mainTableSource[0].price =
                        Math.round(result.sellingPrice * 10) / 10 || Math.round(purchasePrice * markup * 10) / 10;
                    this.state.mainTableSource[0].supplierOriginalCode = supplierOriginalCode;
                    this.state.mainTableSource[0].supplierProductNumber = supplierProductNumber;
                    this.state.mainTableSource[0].supplierPartNumber = supplierPartNumber;
                    this.state.mainTableSource[0].isFromStock = isFromStock;
                    this.state.mainTableSource[0].defaultWarehouseId = defaultWarehouseId;
                }
            }
            this.setState({});
        }
    };

    setCode(
        related,
        code,
        brandId,
        productId,
        key,
        storeGroupId,
        storeGroupName,
        supplierOriginalCode,
        supplierProductNumber,
        supplierPartNumber,
        brandName
    ) {
        const { mainTableSource, relatedDetails, radioValue } = this.state;
        const brand = this.props.brands.find(elem => elem.brandId == brandId);
        const currentDetail = related ? relatedDetails[key] : mainTableSource[key];
        this.unsetSupplier(key, related);

        currentDetail.detailCode = code;
        currentDetail.brandId = brandId;
        currentDetail.brandName = _.get(brand, 'brandName', _.get(currentDetail, 'brandName'));
        currentDetail.productId = productId;
        currentDetail.supplierOriginalCode = supplierOriginalCode;
        currentDetail.supplierProductNumber = supplierProductNumber;
        currentDetail.supplierPartNumber = supplierPartNumber;
        if (radioValue === '3' || radioValue === '4' || radioValue === '5') {
            currentDetail.storeGroupId = storeGroupId;
            currentDetail.detailName = String(storeGroupName);
        }

        if (related) {
            relatedDetails[key] = currentDetail;
        } else {
            mainTableSource[key] = currentDetail;
        }

        this.setState({});
    }

    setSupplier(
        related,
        supplierId,
        supplierName,
        supplierBrandId,
        purchasePrice,
        price,
        store,
        supplierOriginalCode,
        supplierProductNumber,
        supplierPartNumber,
        key,
        isFromStock,
        defaultWarehouseId,
        productId,
        brandId,
        cellAddress,
        warehouseId
    ) {
        const { mainTableSource, relatedDetails } = this.state;
        const brand = this.props.brands.find(elem => elem.brandId == brandId);
        const currentDetail = related ? relatedDetails[key] : mainTableSource[key];

        currentDetail.supplierId = supplierId;
        currentDetail.supplierName = supplierName;
        currentDetail.supplierBrandId = supplierBrandId;
        currentDetail.purchasePrice = purchasePrice;
        currentDetail.price = price;
        currentDetail.store = store;
        currentDetail.supplierOriginalCode = supplierOriginalCode;
        currentDetail.supplierProductNumber = supplierProductNumber;
        currentDetail.supplierPartNumber = supplierPartNumber;
        currentDetail.isFromStock = isFromStock;
        currentDetail.productId = isFromStock ? productId : undefined;
        currentDetail.cellAddress = cellAddress || undefined;
        currentDetail.warehouseId = warehouseId || undefined;
        if (brand) {
            currentDetail.brandId = brandId;
            currentDetail.brandName = brand && brand.brandName;
        }

        if (related) {
            relatedDetails[key] = currentDetail;
        } else {
            mainTableSource[key] = currentDetail;
        }

        this.setState({});
    }

    unsetSupplier(key = 0, related) {
        const { mainTableSource, relatedDetails, radioValue } = this.state;
        const currentDetail = related ? relatedDetails[key] : mainTableSource[key];
        console.log(key, currentDetail);
        if (radioValue === '5') {
            currentDetail.supplierId = 0;
            currentDetail.supplierName = this.props.intl.formatMessage({
                id: 'navigation.storage'
            });
        } else {
            currentDetail.productId = undefined;
            currentDetail.isFromStock = false;
            currentDetail.supplierId = null;
            currentDetail.supplierName = undefined;
            currentDetail.supplierBrandId = undefined;
            currentDetail.supplierOriginalCode = undefined;
            currentDetail.supplierProductNumber = undefined;
            currentDetail.supplierPartNumber = undefined;
            currentDetail.store = undefined;
            currentDetail.cellAddress = undefined;
            currentDetail.warehouseId = undefined;
        }

        if (related) {
            relatedDetails[key] = currentDetail;
        } else {
            mainTableSource[key] = currentDetail;
        }

        this.setState({});
    }

    setVinDetail(product, related) {
        const { oem, name, key } = product;
        this.unsetSupplier(0, related);
        this.state.mainTableSource[0].detailName = name;
        this.state.mainTableSource[0].detailCode = oem;

        const oesBrand = this.props.brands.find(brand => brand.brandId == 8000);

        this.state.mainTableSource[0].brandName = oesBrand ? oesBrand.brandName : undefined;
        this.state.mainTableSource[0].brandId = oesBrand ? oesBrand.brandId : undefined;
        this.state.radioValue = '3';

        this.state.mainTableSource[0].purchasePrice = undefined;
        this.state.mainTableSource[0].price = 0;
        this.state.mainTableSource[0].count = 1;

        this.setState({});
    }

    async addDetailsAndLabors(data) {
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            if (result.success) {
                this.props.updateDataSource();
            } else {
                console.log('BAD', result);
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    fetchData() {
        this.labors = this.props.labors;
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/business_suppliers?all=true&cut=true`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((elem, index) => {
                    elem.key = index;
                });
                that.suppliers = data;
                that.getOptions();
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    getOptions() {
        if (this.props.labors) {
            this.servicesOptions = this.props.labors.map((elem, index) => (
                <Option
                    key={index}
                    norm_hours={elem.normHours}
                    price={elem.price}
                    product_id={elem.productId}
                    value={elem.laborId}
                >
                    {elem.name ? elem.name : elem.defaultName}
                </Option>
            ));
        }

        this.suppliersOptions = this.suppliers.map((elem, index) => (
            <Option key={index} value={elem.id}>
                {elem.name}
            </Option>
        ));
    }

    filterOptions(id) {
        const servicesOptions = [];
        this.labors.map((elem, index) => {
            if (elem.productId == id) {
                servicesOptions.push(
                    <Option
                        key={index}
                        norm_hours={elem.normHours}
                        price={elem.price}
                        product_id={elem.productId}
                        value={elem.laborId}
                    >
                        {elem.name ? elem.name : elem.defaultName}
                    </Option>
                );
            } else {
            }
        });

        this.servicesOptions = [...servicesOptions];
    }

    _findByBarcode = async (barcode, referenceId) => {
        const { list } = await fetchAPI('GET', 'store_products');
        const detail = list.find(elem => elem.id == referenceId);
        if (detail) {
            const { id, groupId, brand, code, name, stockPrice, priceGroup } = detail;
            await this.setState({
                editing: false,
                mainTableSource: [
                    {
                        key: 0,
                        productId: id,
                        storeGroupId: groupId,
                        detailCode: code,
                        brandId: brand.id,
                        brandName: brand.name,
                        detailName: name,
                        purchasePrice: Math.round(stockPrice * 10) / 10,
                        price: Math.round(stockPrice * priceGroup.multiplier * 10) / 10
                    }
                ]
            });
        } else {
            notification.warning({
                message: this.props.intl.formatMessage({ id: 'order_form_table.code_not_found' })
            });
            this.props.hideModal();
        }
    };

    deleteDetail = async () => {
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}/details?ids=[${this.props.detail.id}]`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json();
            if (result.success) {
                this.props.updateDataSource();
                this.handleCancel();
            } else {
                console.log('BAD', result);
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    };

    confirmDelete = () => {
        const { formatMessage } = this.props.intl;
        const that = this;
        confirm({
            title: formatMessage({ id: 'add_order_form.delete_confirm' }),
            onOk() {
                that.deleteDetail();
            },
            okType: 'danger'
        });
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const { user, visible, detail, allDetails, showOilModal } = this.props;
        const editing = Boolean(detail && detail.storeGroupId);
        if (prevProps.visible == false && visible) {
            this.setState({
                editing,
                mainTableSource: [{ ...detail, key: 0 }]
            });
            if (editing) {
                this.getDefaultValues(detail.storeGroupId);
            }
        }
        if (!prevProps.showOilModal && showOilModal) {
            this.setState({
                radioValue: '4'
            });
        }
        if (isForbidden(user, permissions.ACCESS_ORDER_DETAILS_FIND_FROM_VEHICLE) && this.state.radioValue === '1') {
            this.setState({ radioValue: '2' });
        }
    }

    getMobileForm() {
        const { mainTableSource } = this.state;
        const dataSource = mainTableSource[0] || {};
        const columns = this.columns();
        columns.pop();

        return columns.map(({ title, key, render, dataIndex }) => {
            if (key === 'purchasePrice' || key === 'AI' || key === 'comment') {
                return;
            }

            return (
                <div
                    key={key}
                    className={`${Styles.mobileTable} ${key === 'storeGroupId' && Styles.mobileTableGroup} ${
                        key === 'vin' && Styles.mobileTableVin
                    } ${(key === 'price' || key === 'count') && Styles.mobileTableNumber} ${
                        key === 'sum' && Styles.mobileTableSum
                    }`}
                >
                    {title}
                    <div>{dataIndex ? render(dataSource[dataIndex], dataSource) : render(dataSource)}</div>
                </div>
            );
        });
    }

    render() {
        const { visible, isMobile, user } = this.props;
        const { editing, mainTableSource, radioValue } = this.state;
        const columns = this.columns();

        return (
            <div>
                <Modal
                    footer={
                        isMobile && editing ? (
                            <div>
                                <Button
                                    data-qa='button_confirm_delete_detail_product_modal'
                                    onClick={() => this.confirmDelete()}
                                    style={{
                                        float: 'left'
                                    }}
                                    type='danger'
                                >
                                    <DeleteOutlined />
                                </Button>
                                <Button
                                    data-qa='button_handle_cancel_detail_product_modal'
                                    onClick={() => this.handleCancel()}
                                >
                                    <FormattedMessage id='cancel' />
                                </Button>
                                <Button
                                    data-qa='button_handle_ok_detail_product_modal'
                                    onClick={() => this.handleOk()}
                                    type='primary'
                                >
                                    <FormattedMessage id='save' />
                                </Button>
                            </div>
                        ) : (
                            void 0
                        )
                    }
                    forceRender
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    style={
                        !isMobile
                            ? {
                                  minWidth: 560
                              }
                            : {
                                  minWidth: '95%'
                              }
                    }
                    title={<FormattedMessage id='add_detail' />}
                    visible={visible}
                    width='95%'
                    wrapClassName='addDetailModal'
                >
                    {!isMobile ? (
                        <React.Fragment>
                            <Tabs
                                activeKey={radioValue}
                                onChange={radioValue => {
                                    if (radioValue === '5') {
                                        this.unsetSupplier();
                                        mainTableSource[0].supplierId = 0;
                                        mainTableSource[0].supplierName = this.props.intl.formatMessage({
                                            id: 'navigation.storage'
                                        });
                                    }
                                    this.setState({
                                        radioValue,
                                        mainTableSource
                                    });
                                }}
                            >
                                <TabPane
                                    key='1'
                                    disabled={isForbidden(user, permissions.ACCESS_ORDER_DETAILS_FIND_FROM_VEHICLE)}
                                    forceRender
                                    tab={<FormattedMessage id='details_table.selection_by_car' />}
                                />
                                <TabPane key='2' tab={<FormattedMessage id='details_table.direct_editing' />} />
                                <TabPane
                                    key='3'
                                    disabled={isForbidden(user, permissions.ACCESS_ORDER_DETAILS_FIND_FROM_CODE)}
                                    tab={<FormattedMessage id='details_table.selection_by_product_code' />}
                                />
                                <TabPane
                                    key='4'
                                    disabled={isForbidden(user, permissions.ACCESS_ORDER_DETAILS_FIND_OILS)}
                                    tab={<FormattedMessage id='details_table.oils_and_liquids' />}
                                />
                                <TabPane
                                    key='5'
                                    disabled={isForbidden(user, permissions.ACCESS_ORDER_DETAILS_FIND_STOCK)}
                                    tab={<FormattedMessage id='navigation.storage' />}
                                />
                            </Tabs>
                            <div className={Styles.tableWrap}>
                                <Table
                                    bordered
                                    columns={columns.slice(1)}
                                    dataSource={mainTableSource}
                                    pagination={false}
                                    size='small'
                                />
                            </div>
                            <div>
                                <FormattedMessage id='add_order_form.related' />
                                : 
                                <FormattedMessage id='add_order_form.details' />
                                <Checkbox
                                    checked={this.state.relatedDetailsCheckbox}
                                    data-qa='checkbox_relatedDetailsCheckbox_detail_product_modal'
                                    disabled={this.state.editing}
                                    onChange={() => {
                                        this.setState({
                                            relatedDetailsCheckbox: !this.state.relatedDetailsCheckbox
                                        });
                                    }}
                                    style={{ marginLeft: 5 }}
                                />
                                 
                            </div>
                            {this.state.relatedDetailsCheckbox && (
                                <div className={Styles.tableWrap}>
                                    <Table
                                        bordered
                                        columns={columns}
                                        dataSource={this.state.relatedDetails}
                                        size='small'
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ) : (
                        this.getMobileForm()
                    )}
                </Modal>
            </div>
        );
    }
}
export default DetailProductModal;

@injectIntl
class CommentaryButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            currentCommentaryProps: {
                name: props.detail,
                positions: []
            },
            currentCommentary: undefined
        };
        this.commentaryInput = React.createRef();
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this._isMounted = false;
    }

    showModal = () => {
        this.setState({
            currentCommentary: this.props.commentary.comment ? this.props.commentary.comment : this.props.detail,
            visible: true
        });
        if (this.commentaryInput.current != undefined) {
            this.commentaryInput.current.focus();
        }
    };

    handleOk = async () => {
        const { currentCommentary, currentCommentaryProps } = this.state;
        this.setState({
            loading: true
        });
        this.props.setComment(currentCommentary, currentCommentaryProps.positions);
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 500);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            currentCommentary: this.props.detail,
            currentCommentaryProps: {
                name: this.props.detail,
                positions: []
            }
        });
    };

    renderHeader = () => {
        return (
            <div>
                <p>{this.props.detail}</p>
            </div>
        );
    };

    getCommentary() {
        const { currentCommentaryProps } = this.state;
        let currentCommentary = this.props.detail;

        if (currentCommentaryProps.positions.length) {
            currentCommentary += ' -';
            currentCommentary += `${currentCommentaryProps.positions.map(
                data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
            )};`;
        }
        this.setState({
            currentCommentary
        });
    }

    setCommentaryPosition(position) {
        const { currentCommentaryProps } = this.state;
        const positionIndex = currentCommentaryProps.positions.indexOf(position);
        if (positionIndex == -1) {
            currentCommentaryProps.positions.push(position);
        } else {
            currentCommentaryProps.positions = currentCommentaryProps.positions.filter(
                (value, index) => index != positionIndex
            );
        }
        this.getCommentary();
    }

    componentDidMount() {
        this._isMounted = true;
        const { commentary, detail } = this.props;
        if (this._isMounted) {
            this.setState({
                currentCommentaryProps: {
                    name: detail,
                    positions: commentary.positions || []
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { TextArea } = Input;
        const { visible, loading, currentCommentaryProps, currentCommentary } = this.state;
        const { disabled, commentary } = this.props;
        const { positions } = this;

        return (
            <div>
                {commentary.comment ? (
                    <Button
                        className={Styles.commentaryButton}
                        data-qa='button_show_modal_edit_commentary_button_detail_product_modal'
                        onClick={this.showModal}
                        title={this.props.intl.formatMessage({ id: 'commentary.edit' })}
                        type='primary'
                    >
                        <FormOutlined className={Styles.commentaryButtonIcon} />
                    </Button>
                ) : (
                    <Button
                        data-qa='button_show_modal_add_commentary_button_detail_product_modal'
                        disabled={disabled}
                        onClick={this.showModal}
                        title={this.props.intl.formatMessage({ id: 'commentary.add' })}
                    >
                        <MessageOutlined />
                    </Button>
                )}
                <Modal
                    footer={
                        disabled
                            ? null
                            : [
                                  <Button
                                      key='back'
                                      data-qa='button_handle_cancel_commentary_button_detail_product_modal'
                                      onClick={this.handleCancel}
                                  >
                                      <FormattedMessage id='cancel' />
                                  </Button>,
                                  <Button
                                      key='submit'
                                      data-qa='button_handle_ok_commentary_button_detail_product_modal'
                                      loading={loading}
                                      onClick={this.handleOk}
                                      type='primary'
                                  >
                                      <FormattedMessage id='save' />
                                  </Button>
                              ]
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={this.renderHeader()}
                    visible={visible}
                >
                    <React.Fragment>
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.where' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {positions.map((position, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            className={Styles.commentaryBlockButton}
                                            data-qa='button_position_commentary_button_detail_product_modal'
                                            onClick={() => {
                                                this.setCommentaryPosition(position);
                                            }}
                                            type={
                                                currentCommentaryProps.positions.findIndex(elem => position == elem) >
                                                -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <FormattedMessage id={position} />
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='order_form_table.diagnostic.commentary' />
                            </p>
                            <TextArea
                                ref={this.commentaryInput}
                                autoFocus
                                data-qa='text_area_currentCommentary_commentary_button_detail_product_modal'
                                disabled={disabled}
                                onChange={() => {
                                    this.setState({
                                        currentCommentary: event.target.value
                                    });
                                }}
                                placeholder={`${this.props.intl.formatMessage({
                                    id: 'comment'
                                })}...`}
                                style={{ width: '100%', minHeight: '150px', resize: 'none' }}
                                value={currentCommentary}
                            />
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}
