import { HomeOutlined } from '@ant-design/icons';
import { Checkbox, Modal } from 'antd';
import { MODALS, resetModal } from 'core/modals/duck';
import { SwitchBusinessForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        modal: state.modals.modal,
        loading: state.ui.searchBusinessesFetching
    };
};

const mapDispatch = {
    resetModal
};

@connect(mapStateToProps, mapDispatch)
export default class SwitchBusinessModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchById: false
        };
    }

    render() {
        const { modal: visible, resetModal, loading, setBusiness } = this.props;
        const { searchById } = this.state;

        return (
            <Modal
                destroyOnClose
                footer={null}
                maskClosable={false}
                onCancel={() => resetModal()}
                open={visible === MODALS.SWITCH_BUSINESS}
                title={
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <HomeOutlined
                            style={{
                                marginRight: 8
                            }}
                        />
                        <span>
                            <FormattedMessage id='accurate_search_by_id' />
                            <Checkbox
                                checked={searchById}
                                onChange={() =>
                                    this.setState({
                                        searchById: !searchById
                                    })
                                }
                                style={{
                                    marginLeft: 8
                                }}
                            />
                        </span>
                    </div>
                }
            >
                <SwitchBusinessForm
                    loading={loading}
                    resetModal={resetModal}
                    searchById={searchById}
                    setBusiness={setBusiness}
                />
            </Modal>
        );
    }
}
