import { Input, Modal, notification, Select } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
export default class AddSourceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            if (this.props.sourceModalRow) {
                this.setState({
                    ...this.props.sourceModalRow
                });
            }
        }
    }

    handleOk = async () => {
        if (this.props.sourceModalRow) {
            await this.editRegulationRow();
            await setTimeout(() => this.props.fetchData(), 500);
            this.handleCancel();
        } else {
            await this.addRegulationRow();
            await setTimeout(() => this.props.fetchData(), 500);
            this.handleCancel();
        }
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'message.success' })
        });
    };

    handleCancel() {
        this.setState({
            storeGroupId: undefined,
            regulationName: undefined,
            regulationKm: undefined,
            regulationMonths: undefined,
            laborId: undefined,
            laborGroupId: undefined
        });
        this.props.hideModal();
    }

    addRegulationRow = async () => {
        const { regulationName, laborId, storeGroupId, regulationKm, regulationMonths, laborGroupId } = this.state;
        const { idForOwnRegulations } = this.props;
        await fetchAPI('POST', 'businesses/add/regulations', undefined, {
            groupId: idForOwnRegulations,
            regulationName,
            regulationKm,
            regulationMonths,
            laborId,
            laborGroupId: storeGroupId,
            visibility: true
        });
    };

    editRegulationRow = async () => {
        const { regulationName, regulationKm, regulationMonths } = this.state;
        const { sourceModalRow } = this.props;
        await fetchAPI('PUT', 'businesses/regulations', undefined, {
            id: sourceModalRow.id,
            regulationName,
            regulationKm,
            regulationMonths,
            visibility: sourceModalRow.visibility
        });
    };

    render() {
        const { detailsTreeData, sourceModalRow } = this.props;
        const { regulationName, laborId, regulationKm, regulationMonths, storeGroupId, name } = this.state;

        return (
            <Modal
                destroyOnClose
                maskClosable={false}
                okButtonProps={{
                    disabled: !regulationKm || !regulationMonths
                }}
                onCancel={() => {
                    this.handleCancel();
                }}
                onOk={() => {
                    this.handleOk();
                }}
                visible={this.props.visible}
                width='fit-content'
                zIndex={200}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        margin: '24px 0 0 0'
                    }}
                >
                    <div className={Styles.addProductItemWrap} style={{ minWidth: 140 }}>
                        <FormattedMessage id='regulations.name' />

                        <Input
                            onChange={event => {
                                this.setState({
                                    regulationName: event.target.value
                                });
                            }}
                            value={regulationName}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}
