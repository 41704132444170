import { Col, InputNumber, Modal, Radio, Row, Select, Space } from 'antd';
import { DateRangePicker } from 'components';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { fetchEmployees } from 'core/employees/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getMechanicSumLaborsPercentStorage, normalizeText, setMechanicSumLaborsPercentStorage } from 'utils';
import {
    DEF_DATE_FORMAT,
    detailings,
    downloadSalesReport,
    selectAppointmentFromDate,
    selectAppointmentToDate,
    selectCreationFromDate,
    selectCreationToDate,
    selectDetailing,
    selectDoneFromDate,
    selectDoneToDate,
    selectFilters,
    setAppointmentFromDate,
    setAppointmentToDate,
    setClient,
    setCreationFromDate,
    setCreationToDate,
    setDetailing,
    setDoneFromDate,
    setDoneToDate,
    setManagerId,
    setManagerMarginPartsPercent,
    setManagerMarginSupplierPercent,
    setManagerSumLaborsPercent,
    setMechanicId,
    setMechanicSumLaborsPercent,
    setOrderId,
    setRecommendations,
    setRequisiteId,
    setSparePartEmployeeId,
    setSparePartEmployeeMarginPartsPercent,
    setStatus,
    setType,
    setVehicle
} from './redux/duck';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),

    creationFromDate: selectCreationFromDate(state),
    creationToDate: selectCreationToDate(state),

    appointmentFromDate: selectAppointmentFromDate(state),
    appointmentToDate: selectAppointmentToDate(state),

    doneFromDate: selectDoneFromDate(state),
    doneToDate: selectDoneToDate(state),

    detailing: selectDetailing(state),
    filters: selectFilters(state),

    clients: state.clients.clients,
    businessRequisites: selectBusinessRequisites(state),

    filter: state.orders.filterReport
});

const mapDispatchToProps = {
    resetModal,

    setType,
    setDetailing,
    setCreationFromDate,
    setCreationToDate,
    setAppointmentFromDate,
    setAppointmentToDate,
    setDoneFromDate,
    setDoneToDate,

    setClient,
    setRecommendations,
    setVehicle,
    setStatus,

    downloadSalesReport,

    fetchClients,
    setClientsSearchFilter,
    fetchEmployees,

    setOrderId,
    setManagerId,
    setMechanicId,
    setSparePartEmployeeId,
    setRequisiteId,
    setMechanicSumLaborsPercent,
    setSparePartEmployeeMarginPartsPercent,
    setManagerSumLaborsPercent,
    setManagerMarginSupplierPercent,
    setManagerMarginPartsPercent,
    fetchBusinessRequisites
};

const title = {
    byDate: 'sales_report.title_byDate',
    byLabors: 'sales_report.title_byLabors',
    byProductGroups: 'sales_report.title_byProductGroups',
    byEmployees: 'sales_report.title_byEmployees',
    byClients: 'sales_report.title_byClients',
    byBrands: 'sales_report.title_byBrands',
    byProducts: 'sales_report.title_byProducts',
    byVehicles: 'sales_report.title_byVehicles',
    byStations: 'sales_report.title_byStations',
    byStatuses: 'sales_report.title_byStatuses',
    byRecommendations: 'sales_report.title_byRecommendations',
    byBusinessIncome: 'navigation.by_business_income'
};
const detailingsMap = {
    byDate: [detailings.without, detailings.orders, detailings.requisites, detailings.stations],
    byLabors: [detailings.without, detailings.orders, detailings.requisites, detailings.stations],
    byProductGroups: [detailings.without, detailings.orders, detailings.requisites, detailings.stations],
    byEmployees: [detailings.without, detailings.orders, detailings.labors],
    byClients: [detailings.without, detailings.orders, detailings.labors, detailings.parts],
    byBrands: [detailings.without, detailings.orders, detailings.requisites, detailings.stations],
    byProducts: [detailings.without, detailings.orders, detailings.requisites, detailings.stations],
    byVehicles: [detailings.without, detailings.orders, detailings.labors, detailings.parts],
    byStations: [detailings.without, detailings.orders, detailings.labors],
    byStatuses: [detailings.without, detailings.orders, detailings.labors],
    byRecommendations: [detailings.without, detailings.orders, detailings.recommendations]
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SalesReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            employees: [],
            labors: []
        };

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    onCreationFromDateChanged = date => {
        this.props.setCreationFromDate(date ? date.format(DEF_DATE_FORMAT) : undefined);
    };

    onCreationToDateChanged = date => {
        this.props.setCreationToDate(date ? date.format(DEF_DATE_FORMAT) : undefined);
    };

    onAppointmentFromDateChanged = date => {
        this.props.setAppointmentFromDate(date ? date.format(DEF_DATE_FORMAT) : undefined);
    };

    onAppointmentToDateChanged = date => {
        this.props.setAppointmentToDate(date ? date.format(DEF_DATE_FORMAT) : undefined);
    };

    onDoneFromDateChanged = date => {
        this.props.setDoneFromDate(date ? date.format(DEF_DATE_FORMAT) : undefined);
    };

    onDoneToDateChanged = date => {
        this.props.setDoneToDate(date ? date.format(DEF_DATE_FORMAT) : undefined);
    };

    fetchLabors = async () => {
        const { labors } = await fetchAPI('GET', 'labors', null, null, {
            handleErrorInternally: true
        });
        this.setState({
            labors
        });
    };

    handleSearchOrders = _.debounce(value => {
        this.fetchOrders(value);
    }, 1000);

    fetchOrders = async query => {
        const { orders } = await fetchAPI('GET', 'orders', { query, pageSize: 50 }, null, {
            handleErrorInternally: true
        });
        this.setState({
            orders
        });
    };

    handleSearchEmployees = _.debounce(value => {
        this.getAllEmployees(value);
    }, 1000);

    getAllEmployees = async query => {
        const employees = await fetchAPI('GET', 'employees', { query }, null, { handleErrorInternally: true });
        this.setState({
            employees
        });
    };

    componentDidUpdate(prevProps) {
        const {
            setType,
            modalProps,
            filters,
            setMechanicSumLaborsPercent,
            setSparePartEmployeeMarginPartsPercent,
            setManagerSumLaborsPercent,
            setManagerMarginSupplierPercent,
            setManagerMarginPartsPercent
        } = this.props;

        const type = _.get(modalProps, 'type', filters.type);

        if (type != filters.type) setType(type);

        if (!prevProps.visible && this.props.visible) {
            if (['byBusinessIncome'].includes(type)) {
                this.getAllEmployees();
            }

            if (['byRecommendations', 'byBusinessIncome'].includes(type)) {
                this.props.fetchClients();
            }
            if (['byRecommendations'].includes(type)) {
                this.fetchLabors();
            }

            if (['byBusinessIncome'].includes(type)) {
                this.fetchOrders();
            }
            setMechanicSumLaborsPercent({
                mechanicSumLaborsPercent: getMechanicSumLaborsPercentStorage('mechanicSumLaborsPercent')
            });
            setSparePartEmployeeMarginPartsPercent({
                sparePartEmployeeMarginPartsPercent: getMechanicSumLaborsPercentStorage(
                    'sparePartEmployeeMarginPartsPercent'
                )
            });
            setManagerSumLaborsPercent({
                managerSumLaborsPercent: getMechanicSumLaborsPercentStorage('managerSumLaborsPercent')
            });
            setManagerMarginSupplierPercent({
                managerMarginSupplierPercent: getMechanicSumLaborsPercentStorage('managerMarginSupplierPercent')
            });
            setManagerMarginPartsPercent({
                managerMarginPartsPercent: getMechanicSumLaborsPercentStorage('managerMarginPartsPercent')
            });
        }
    }

    render() {
        const {
            resetModal,
            setType,
            setDetailing,
            modalProps,

            visible,
            detailing,
            filters,

            creationFromDate,
            creationToDate,

            appointmentFromDate,
            appointmentToDate,

            doneFromDate,
            doneToDate,
            downloadSalesReport,

            intl: { formatMessage },

            clients,

            setClient,
            setVehicle,
            setRecommendations,
            setStatus,

            setOrderId,
            setManagerId,
            setMechanicId,
            setSparePartEmployeeId,
            setRequisiteId,
            setMechanicSumLaborsPercent,
            setSparePartEmployeeMarginPartsPercent,
            setManagerSumLaborsPercent,
            setManagerMarginSupplierPercent,
            setManagerMarginPartsPercent,

            businessRequisites
        } = this.props;

        const {
            vehicleId,
            clientId,
            vehicles,
            recommendationId,
            labors,
            statusOption,
            orderId,
            orders,
            managerId,
            mechanicId,
            sparePartEmployeeId,
            requisiteId,
            mechanicSumLaborsPercent,
            sparePartEmployeeMarginPartsPercent,
            managerSumLaborsPercent,
            managerMarginSupplierPercent,
            managerMarginPartsPercent,
            employees
        } = this.state;

        const type = _.get(modalProps, 'type', filters.type);

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    maskClosable={false}
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={async () => {
                        resetModal();
                        await this.setState({
                            managerId: undefined,
                            orderId: undefined,
                            clientId: undefined,
                            vehicleId: undefined,
                            requisiteId: undefined,
                            mechanicId: undefined,
                            sparePartEmployeeId: undefined
                        });
                    }}
                    onOk={() => {
                        downloadSalesReport();
                        resetModal();
                    }}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id={title[type] || 'sales_report.title'} />
                        </div>
                    }
                    visible={visible === MODALS.SALES_REPORT_MODAL}
                    width={380}
                >
                    {['byRecommendations', 'byBusinessIncome'].includes(type) && (
                        <div>
                            <Select
                                allowClear
                                filterOption={false}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={(value, option) => {
                                    if (option) {
                                        setClient({ clientId: Number(value) });
                                        this.setState({
                                            vehicles: option.props.vehicles
                                        });
                                    } else {
                                        this.setState({
                                            clientId: undefined,
                                            vehicles: undefined
                                        });
                                    }
                                }}
                                onSearch={this.handleSearchClients}
                                placeholder={formatMessage({ id: 'add_order_form.client' })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={clientId}
                            >
                                {clients.map(client => (
                                    <Option
                                        key={client.clientId}
                                        phone={_.get(client, 'phones[0]')}
                                        value={client.clientId}
                                        vehicles={_.get(client, 'vehicles')}
                                    >
                                        {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                            client,
                                            'phones[0]',
                                            ''
                                        )}`}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                allowClear
                                filterOption={(input, option) => {
                                    // Normalize input and vehicle name
                                    const normalizedInput = normalizeText(input);
                                    const normalizedVehicle = normalizeText(option.children);

                                    return normalizedVehicle.includes(normalizedInput);
                                }}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    setVehicle({ vehicleId: Number(value) });
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({ id: 'locations.vehicle' })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={vehicleId}
                            >
                                {(vehicles || []).map(({ id, make, model, number }) => (
                                    <Option key={id} value={id}>
                                        {make} {model} {number}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    )}
                    {['byBusinessIncome'].includes(type) && (
                        <div>
                            <div>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        setOrderId({ orderId: Number(value) });
                                    }}
                                    onSearch={input => this.handleSearchOrders(input)}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'reports_page.orders'
                                    })}
                                    showSearch
                                    style={{
                                        marginBottom: 8
                                    }}
                                    value={orderId}
                                >
                                    {orders.map(({ id, num }) => (
                                        <Option key={id} num={num} value={id}>
                                            {num}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        setManagerId({ managerId: Number(value) });
                                    }}
                                    onSearch={input => this.handleSearchEmployees(input)}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'orders.responsible'
                                    })}
                                    showSearch
                                    style={{
                                        marginBottom: 8
                                    }}
                                    value={managerId}
                                >
                                    {(employees || [])
                                        .filter(({ posts }) =>
                                            posts.find(({ postName }) => postName === 'MASTER_RECEIVER')
                                        )
                                        .map(({ id, name, surname, managerId }) => (
                                            <Option key={id} name={name} surname={surname} value={managerId}>
                                                {`${name} ${surname}`}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        setMechanicId({ mechanicId: Number(value) });
                                    }}
                                    onSearch={input => this.handleSearchEmployees(input)}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'employee.is_mechanic'
                                    })}
                                    showSearch
                                    style={{
                                        marginBottom: 8
                                    }}
                                    value={mechanicId}
                                >
                                    {employees
                                        .filter(({ posts }) => posts.find(({ postName }) => postName === 'MECHANIC'))
                                        .map(({ id, name, surname }) => (
                                            <Option key={id} name={name} surname={surname} value={id}>
                                                {`${name} ${surname}`}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        setSparePartEmployeeId({
                                            sparePartEmployeeId: Number(value)
                                        });
                                    }}
                                    onSearch={input => this.handleSearchEmployees(input)}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'employee.is_spare_parts_specialist'
                                    })}
                                    showSearch
                                    style={{
                                        marginBottom: 8
                                    }}
                                    value={sparePartEmployeeId}
                                >
                                    {employees
                                        .filter(({ posts }) =>
                                            posts.find(({ postName }) => postName === 'SPARE_PARTS_SPECIALIST')
                                        )
                                        .map(({ id, name, surname }) => (
                                            <Option key={id} name={name} surname={surname} value={id}>
                                                {`${name} ${surname}`}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        setRequisiteId({
                                            requisiteId: Number(value)
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'storage.business_requisites'
                                    })}
                                    showSearch
                                    style={{
                                        marginBottom: 8
                                    }}
                                    value={requisiteId}
                                >
                                    {this.props.businessRequisites
                                        .filter(({ used }) => used)
                                        .map(elem => (
                                            <Select.Option key={elem.id} value={elem.id}>
                                                {elem.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </div>

                            <div className={Styles.percentInputStyle}>
                                <div className={Styles.percentTittleStyle}>
                                    <FormattedMessage id='sales_repot_modal.mechanicSumLaborsPercent' />
                                </div>
                                <InputNumber
                                    formatter={mechanicSumLaborsPercent => `${mechanicSumLaborsPercent}%`}
                                    max={100}
                                    min={0}
                                    onChange={value => {
                                        setMechanicSumLaborsPercentStorage('mechanicSumLaborsPercent', Number(value));
                                        setMechanicSumLaborsPercent({
                                            mechanicSumLaborsPercent: Number(value)
                                        });
                                    }}
                                    parser={value => value.replace('%', '').replace(',', '.')}
                                    precision={2}
                                    value={
                                        getMechanicSumLaborsPercentStorage('mechanicSumLaborsPercent') ||
                                        mechanicSumLaborsPercent
                                    }
                                />
                            </div>
                            {/* <div className={Styles.percentInputStyle}>
                                <div className={Styles.percentTittleStyle}>
                                    <FormattedMessage id='sales_repot_modal.sparePartEmployeeMarginPartsPercent' />
                                </div>
                                <InputNumber
                                    decimalSeparator=','
                                    formatter={sparePartEmployeeMarginPartsPercent =>
                                        `${sparePartEmployeeMarginPartsPercent}%`
                                    }
                                    max={100}
                                    min={0}
                                    onChange={value => {
                                        setMechanicSumLaborsPercentStorage(
                                            'sparePartEmployeeMarginPartsPercent',
                                            Number(value)
                                        );
                                        setSparePartEmployeeMarginPartsPercent({
                                            sparePartEmployeeMarginPartsPercent: Number(value)
                                        });
                                    }}
                                    parser={value => value.replace('%', '').replace(',', '.')}
                                    precision={2}
                                    value={
                                        getMechanicSumLaborsPercentStorage('sparePartEmployeeMarginPartsPercent') ||
                                        sparePartEmployeeMarginPartsPercent
                                    }
                                />
                            </div>
                            <div className={Styles.percentInputStyle}>
                                <div className={Styles.percentTittleStyle}>
                                    <FormattedMessage id='sales_repot_modal.managerSumLaborsPercent' />
                                </div>
                                <InputNumber
                                    decimalSeparator=','
                                    formatter={managerSumLaborsPercent => `${managerSumLaborsPercent}%`}
                                    max={100}
                                    min={0}
                                    onChange={value => {
                                        setMechanicSumLaborsPercentStorage('managerSumLaborsPercent', Number(value));
                                        setManagerSumLaborsPercent({
                                            managerSumLaborsPercent: Number(value)
                                        });
                                    }}
                                    parser={value => value.replace('%', '').replace(',', '.')}
                                    precision={2}
                                    value={
                                        getMechanicSumLaborsPercentStorage('managerSumLaborsPercent') ||
                                        managerSumLaborsPercent
                                    }
                                />
                            </div>
                            <div className={Styles.percentInputStyle}>
                                <div className={Styles.percentTittleStyle}>
                                    <FormattedMessage id='sales_repot_modal.managerMarginSupplierPercent' />
                                </div>
                                <InputNumber
                                    decimalSeparator=','
                                    formatter={managerMarginSupplierPercent => `${managerMarginSupplierPercent}%`}
                                    max={100}
                                    min={0}
                                    onChange={value => {
                                        setMechanicSumLaborsPercentStorage(
                                            'managerMarginSupplierPercent',
                                            Number(value)
                                        );
                                        setManagerMarginSupplierPercent({
                                            managerMarginSupplierPercent: Number(value)
                                        });
                                    }}
                                    parser={value => value.replace('%', '').replace(',', '.')}
                                    precision={2}
                                    value={
                                        getMechanicSumLaborsPercentStorage('managerMarginSupplierPercent') ||
                                        managerMarginSupplierPercent
                                    }
                                />
                            </div>
                            <div className={Styles.percentInputStyle}>
                                <div className={Styles.percentTittleStyle}>
                                    <FormattedMessage id='sales_repot_modal.managerMarginPartsPercent' />
                                </div>
                                <InputNumber
                                    decimalSeparator=','
                                    formatter={managerMarginPartsPercent => `${managerMarginPartsPercent}%`}
                                    max={100}
                                    min={0}
                                    onChange={value => {
                                        setMechanicSumLaborsPercentStorage('managerMarginPartsPercent', Number(value));
                                        setManagerMarginPartsPercent({
                                            managerMarginPartsPercent: Number(value)
                                        });
                                    }}
                                    parser={value => value.replace('%', '').replace(',', '.')}
                                    precision={2}
                                    value={
                                        getMechanicSumLaborsPercentStorage('managerMarginPartsPercent') ||
                                        managerMarginPartsPercent
                                    }
                                />
                            </div> */}
                        </div>
                    )}
                    {['byRecommendations'].includes(type) && (
                        <div>
                            <Select
                                ref={node => (this.laborRef = node)}
                                allowClear
                                filterOption={(input, option) => {
                                    const parts = input.toLowerCase().split(' ');

                                    return (
                                        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.value).indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.cross_id).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.barcode).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        parts.every(part => String(option.children).toLowerCase().includes(part))
                                    );
                                }}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    setRecommendations({ recommendationId: Number(value) });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.recommendation'
                                })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={recommendationId}
                            >
                                {labors.map(({ id, customName, masterLaborName, storeGroupName, name, laborPrice }) => (
                                    <Option
                                        key={id}
                                        customName={customName}
                                        laborPrice={laborPrice}
                                        masterLaborName={masterLaborName}
                                        storeGroupName={storeGroupName}
                                        value={id}
                                    >
                                        {name || `${(masterLaborName, storeGroupName)}` || customName || id}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                allowClear
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => {
                                    setStatus({ statusOption: String(value) });
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({ id: 'locations.status' })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={statusOption}
                            >
                                <Option key={statusOption} value='active'>
                                    <FormattedMessage id='locations.active' />
                                </Option>
                                <Option key={statusOption} value='closed'>
                                    <FormattedMessage id='locations.closed' />
                                </Option>
                                <Option key={statusOption} value='all'>
                                    <FormattedMessage id='locations.all' />
                                </Option>
                            </Select>
                        </div>
                    )}
                    {['byRecommendations', 'byBusinessIncome'].includes(type) && (
                        <div
                            style={{
                                marginBottom: 8
                            }}
                        >
                            <DateRangePicker
                                dateRange={[dayjs(doneFromDate), dayjs(doneToDate)]}
                                onDateChange={arr => {
                                    this.onDoneFromDateChanged(arr[0]);
                                    this.onDoneToDateChanged(arr[1]);
                                }}
                            />
                        </div>
                    )}

                    {type !== 'byRecommendations' && type !== 'byBusinessIncome' && (
                        <Row className={Styles.row}>
                            <Col className={Styles.boldCol} span={8}>
                                <FormattedMessage id='calls-table.date' />
                            </Col>
                            <Col span={8}>
                                <FormattedMessage id='date_closing' />
                            </Col>
                            <Col span={8}>
                                <DateRangePicker
                                    allowClear
                                    dateRange={[dayjs(doneFromDate), dayjs(doneToDate)]}
                                    minimize
                                    onDateChange={arr => {
                                        this.onDoneFromDateChanged(arr[0]);
                                        this.onDoneToDateChanged(arr[1]);
                                    }}
                                />
                            </Col>
                        </Row>
                    )}

                    {['byEmployees', 'byClients'].includes(type) && (
                        <React.Fragment>
                            <Row className={Styles.row}>
                                <Col span={8}></Col>
                                <Col span={8}>
                                    <FormattedMessage id='date_appointment' />
                                </Col>
                                <Col span={8}>
                                    <DateRangePicker
                                        allowClear
                                        dateRange={[dayjs(appointmentFromDate), dayjs(appointmentToDate)]}
                                        minimize
                                        onDateChange={arr => {
                                            this.onAppointmentFromDateChanged(arr[0]);
                                            this.onAppointmentToDateChanged(arr[1]);
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row className={Styles.row}>
                                <Col span={8}></Col>
                                <Col span={8}>
                                    <FormattedMessage id='date_creation' />
                                </Col>
                                <Col span={8}>
                                    <DateRangePicker
                                        allowClear
                                        dateRange={[dayjs(creationFromDate), dayjs(creationToDate)]}
                                        minimize
                                        onDateChange={arr => {
                                            this.onCreationFromDateChanged(arr[0]);
                                            this.onCreationToDateChanged(arr[1]);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}

                    {detailingsMap[type] && (
                        <div className={Styles.detailingWrapper}>
                            <div className={Styles.boldCol}>
                                <FormattedMessage id='detailing' />
                            </div>
                            <div>
                                <Radio.Group
                                    className={Styles.radioButtons}
                                    onChange={e => setDetailing(e.target.value)}
                                    value={detailing}
                                >
                                    <Space direction='vertical'>
                                        {detailingsMap[type].map(function ({ title, value }, ind) {
                                            return (
                                                <Radio value={value}>
                                                    <FormattedMessage id={title} />
                                                </Radio>
                                            );
                                        })}
                                    </Space>
                                </Radio.Group>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}
