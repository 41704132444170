/* eslint-disable max-classes-per-file */
import { DeleteOutlined, FormOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Table, Tooltip, TreeSelect, notification } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { buildSupplierOptions, fetchAPI, filterTreeByPart } from 'utils';

import { EmployeeSupplierSelector } from 'components';
import Styles from './styles.m.css';

const { Option } = Select;
const { confirm } = Modal;

@injectIntl
class OrderRequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            dataSource: []
        };

        // this.laborRef = React.createRef();

        this.columns = () => [
            {
                title: <FormattedMessage id='order_form_table_requests.the_reason_for_the_appeal' />,
                key: 'laborRequestName',
                dataIndex: 'laborRequestName',
                render: (laborRequestName, elem) => {
                    let detail = get(laborRequestName, 'comment', '');
                    if (detail && detail.indexOf(' - ') > -1) {
                        detail = detail.slice(0, detail.indexOf(' - '));
                    }

                    return (
                        <div style={{ display: 'flex' }}>
                            <Input
                                data-qa='input_service_name_add_service_modal'
                                onChange={({ target }) => {
                                    const { value } = target;
                                    elem.laborRequestName = {
                                        comment: value,
                                        positions: get(laborRequestName, 'positions', [])
                                    };
                                    this.setState({});
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table_requests.the_reason_for_the_appeal'
                                })}
                                style={{ minWidth: 160 }}
                                value={get(laborRequestName, 'comment')}
                            />
                            <RequestCommentaryButton
                                commentary={
                                    laborRequestName || {
                                        comment: undefined,
                                        positions: []
                                    }
                                }
                                data-qa='button_comment_add_service_modal'
                                detail={detail}
                                setComment={(comment, positions) => {
                                    elem.laborRequestName = {
                                        comment,
                                        positions
                                    };
                                    this.setState({});
                                }}
                            />
                        </div>
                    );
                }
            },
            // {
            //     title: <FormattedMessage id='order_form_table_requests.node' />,
            //     key: 'storeGroupId',
            //     dataIndex: 'storeGroupId',
            //     render: (data, elem) => {
            //         return (
            //             <TreeSelect
            //                 className={Styles.groupsTreeSelect}
            //                 data-qa='tree_select_store_group_id_add_service_modal'
            //                 popupMatchSelectWidth={280}
            //                 filterTreeNode={filterTreeNodeByPart}
            //                 getPopupContainer={() => document.querySelector('.orderRequestModal')}
            //                 listHeight={440}
            //                 onSelect={value => {
            //                     elem.storeGroupId = value;
            //                     this.setState({});
            //                 }}
            //                 placeholder={this.props.intl.formatMessage({
            //                     id: 'order_form_table_requests.node'
            //                 })}
            //                 showSearch
            //                 style={{ maxWidth: 320, minWidth: 100, color: 'var(--text)' }}
            //                 treeData={this.props.detailsTreeData}
            //                 value={data}
            //             />
            //         );
            //     }
            // },
            {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='order_form_table.master' /> /{' '}
                        <FormattedMessage id='order_form_table.supplier' />
                    </React.Fragment>
                ),
                key: 'employeeId',

                render: row => {
                    const employeesOptions = this.props.employees
                        .filter(
                            ({ posts, disabled }) =>
                                !disabled && posts.map(({ postName }) => postName).includes('MECHANIC')
                        )
                        .map(({ name, surname, id }) => ({
                            value: id,
                            label: `${surname || ''} ${name || ''}`
                        }));

                    const suppliersOptions = this.props.suppliers.map(({ name, id }) => ({
                        value: id,
                        label: name
                    }));

                    return (
                        <EmployeeSupplierSelector
                            employeesOptions={employeesOptions}
                            initialState={{
                                type: row.counterparty,
                                options:
                                    row.employeeId || row.businessSupplierId
                                        ? [
                                              {
                                                  value: row.employeeId || row.businessSupplierId,
                                                  label: row.employeeId ? row.employeeName : row.businessSupplierName
                                              }
                                          ]
                                        : undefined
                            }}
                            onChange={async (value, type) => {
                                row.counterparty = type;
                                row.employeeId = type === 'EMPLOYEE' && value ? value.key : null;
                                row.businessSupplierId = type === 'SUPPLIER' && value ? value.key : null;
                                if (type === 'SUPPLIER') {
                                    row.businessSupplierName = value ? value.label : null;
                                } else {
                                    row.employeeName = value ? value.label : null;
                                }
                                this.setState({});
                            }}
                            requestModal
                            servicesStyle={{
                                width: '100%'
                            }}
                            suppliersOptions={suppliersOptions}
                            value={row.employeeId || row.businessSupplierId}
                        />
                        // <TreeSelect
                        //     data-qa='tree_select_employee_id_add_service_modal'
                        //     popupMatchSelectWidth={280}
                        //     filterTreeNode={(input, node) => {
                        //         return (
                        //             node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        //             String(node.props.value).indexOf(input.toLowerCase()) >= 0
                        //         );
                        //     }}
                        //     getPopupContainer={() => document.querySelector('.orderRequestModal')}
                        //     listHeight={440}
                        //     onSelect={async (valueString, option) => {
                        //         const value = JSON.parse(valueString);
                        //         row.counterparty = value.counterparty;
                        //         row.employeeId = value.counterparty == 'EMPLOYEE' ? value.id : null;
                        //         row.businessSupplierId = value.counterparty == 'SUPPLIER' ? value.id : null;
                        //         this.setState({});
                        //     }}
                        //     placeholder={
                        //         <React.Fragment>
                        //             <FormattedMessage id='order_form_table.master' /> /{' '}
                        //             <FormattedMessage id='order_form_table.supplier' />
                        //         </React.Fragment>
                        //     }
                        //     showSearch
                        //     style={{ maxWidth: 180, color: 'var(--text)' }}
                        //     treeData={this.props.counterpartyTreeData}
                        //     treeDefaultExpandedKeys={['EMPLOYEE']}
                        //     value={
                        //         row.employeeId || row.businessSupplierId
                        //             ? JSON.stringify({
                        //                   counterparty: row.counterparty || 'EMPLOYEE',
                        //                   id: row.counterparty == 'SUPPLIER' ? row.businessSupplierId : row.employeeId
                        //               })
                        //             : this.props.defaultEmployeeId
                        //     }
                        // />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table_requests.mechanic_comment' />,
                key: 'employeeComment',
                dataIndex: 'employeeComment',
                render: (employeeComment, elem) => {
                    let detail = get(employeeComment, 'comment', null);
                    if (detail && detail.indexOf(' - ') > -1) {
                        detail = detail.slice(0, detail.indexOf(' - '));
                    }

                    return (
                        <div style={{ display: 'flex' }}>
                            <Input
                                data-qa='input_service_name_add_service_modal'
                                onChange={({ target }) => {
                                    const { value } = target;
                                    elem.employeeComment = {
                                        comment: value || null,
                                        positions: get(employeeComment, 'positions', [])
                                    };
                                    this.setState({});
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table_requests.mechanic_comment'
                                })}
                                style={{ minWidth: 160 }}
                                value={get(employeeComment, 'comment')}
                            />
                            <RequestCommentaryButton
                                commentary={
                                    employeeComment || {
                                        comment: undefined,
                                        positions: []
                                    }
                                }
                                data-qa='button_comment_add_service_modal'
                                detail={detail}
                                setComment={(comment, positions) => {
                                    elem.employeeComment = {
                                        comment: comment || null,
                                        positions
                                    };
                                    this.setState({});
                                }}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table_requests.type' />,
                key: 'actionType',
                dataIndex: 'actionType',
                render: (data, elem) => {
                    return (
                        <Select
                            disabled
                            getPopupContainer={() => document.querySelector('.orderRequestModal')}
                            onChange={value => {
                                elem.actionType = value;
                                this.setState({});
                            }}
                            optionFilterProp='children'
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table_requests.type'
                            })}
                            showSearch
                            style={{ minWidth: 100, color: 'var(--text)' }}
                            value={data}
                        >
                            {['LABOR', 'DIAGNOSTIC', 'COMPLEX'].map(action => (
                                <Option key={action} value={action}>
                                    <FormattedMessage id={`status_requests.${action}`} />
                                </Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='services_table.labor' />,
                key: 'serviceId',
                render: row => {
                    return (
                        <Select
                            ref={node => (this.laborRef = node)}
                            allowClear
                            data-qa='select_labor_id_add_service_modal'
                            filterOption={false}
                            getPopupContainer={() => document.querySelector('.orderRequestModal')}
                            onChange={(value, option) => {
                                const val = value && value.replace(/-/g, '');
                                row.serviceId = val;
                                row.complexId = undefined;

                                this.setState({
                                    storeGroupId: option.store_group_id
                                });
                            }}
                            onSearch={input => this.props.handleSearchLabors(input)}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.labor'
                            })}
                            popupMatchSelectWidth={380}
                            showSearch
                            style={{ minWidth: 160, color: 'var(--text)' }}
                            value={row.serviceId}
                        >
                            {row.serviceId
                                ? buildSupplierOptions(get(this.props, 'labors'), {
                                      id: get(row, 'serviceId', undefined),
                                      name: get(row, 'serviceName', undefined)
                                  }).map(elem => <Option value={elem.id}>{elem.customName || elem.name}</Option>)
                                : get(this.props, 'labors', [])
                                      .filter(
                                          ({ storeGroupId }) =>
                                              !row.storeGroupId || row.storeGroupId === storeGroupId || true
                                      )
                                      .map(elem => (
                                          <Option
                                              key={elem.id}
                                              barcode={elem.barcode}
                                              cross_id={elem.crossId}
                                              hours={elem.laborPrice.normHours}
                                              master_id={elem.masterLaborId}
                                              price={elem.laborPrice.price}
                                              specificationId={elem.specificationId}
                                              store_group_id={elem.storeGroupId}
                                              value={elem.id}
                                          >
                                              {elem.customName || elem.name}
                                          </Option>
                                      ))}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='services_table.complex' />,
                key: 'complexId',
                dataIndex: 'complexId',
                width: '15%',
                render: (data, row) => {
                    const { complexTree, complexesCustom, complexesDefault } = this.props;

                    return (
                        <TreeSelect
                            allowClear
                            dropdownStyle={{
                                overflow: 'auto',
                                zIndex: '9999',
                                maxHeight: 500
                            }}
                            filterTreeNode={filterTreeByPart}
                            onChange={async (value, label, { triggerNode }) => {
                                console.log(triggerNode.props, 'props');
                                const name = triggerNode.props.title;
                                const { assignProductId, type } = triggerNode.props;
                                const labors = triggerNode.props.labors.map(elem => {
                                    elem.checked = true;
                                    elem.count = elem.laborPrice ? elem.laborPrice.normHours : 1;
                                    elem.commentary = {
                                        comment: undefined,
                                        positions: []
                                    };
                                    elem.price = elem.laborPrice ? elem.laborPrice.price : null;
                                    elem.laborUnitId = elem.laborUnitId || null;

                                    return elem;
                                });
                                const details = triggerNode.props.details.map(elem => {
                                    elem.checked = true;
                                    elem.name = elem.singleName;
                                    elem.count = 1;
                                    elem.commentary = {
                                        comment: undefined,
                                        positions: []
                                    };

                                    return elem;
                                });

                                row.complexId = value;
                                row.serviceId = undefined;

                                await this.setState({
                                    selectedComplex: value,
                                    laborsDataSource: labors,
                                    detailsDataSource: details,
                                    complexName: name,
                                    complexType: type
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.complex'
                            })}
                            showSearch
                            style={{
                                width: '100%'
                            }}
                            treeData={complexTree}
                            treeDefaultExpandedKeys={[row.complexId]}
                            value={row.complexId}
                        />
                    );
                }
            }
        ];
    }

    componentDidUpdate(prevProps) {
        const { open, request, defaultEmployeeId, employees } = this.props;
        const editing = Boolean(request && request.id);

        if (!prevProps.open && open) {
            const dataSource = [{ ...request, complexId: request.complexId || request.customComplexId }];

            if (!editing) {
                const employee = employees.find(({ id }) => id == defaultEmployeeId);

                dataSource[0].employeeId = this.props.defaultEmployeeId;
                dataSource[0].employeeName = `${_.get(employee, 'surname', '')} ${_.get(employee, 'name', '')}`;
                dataSource[0].counterparty = 'EMPLOYEE';
                dataSource[0].actionType = 'LABOR';
            }

            this.setState({
                editing,
                dataSource
            });
        }
    }

    handleOk = async () => {
        this.setState({
            loading: true
        });
        const { orderId, updateRequest, updateDataSource } = this.props;
        const { editing, dataSource, complexType, storeGroupId } = this.state;

        const complexId = get(dataSource, '[0].complexId');
        const employeeId = get(dataSource, '[0].employeeId');
        if (editing) {
            updateRequest({
                ...dataSource[0],
                complexId: !complexType ? complexId : undefined,
                customComplexId: complexType ? complexId : undefined,
                serviceId: complexId ? null : get(dataSource, '[0].serviceId', null),
                storeGroupId: !complexId ? storeGroupId : undefined,
                employeeId: employeeId || null,
                employeeName: undefined,
                employeeComment:
                    {
                        comment: get(dataSource, '[0].employeeComment.comment') || null,
                        positions: get(dataSource, '[0].employeeComment.positions') || [],
                        problems: get(dataSource, '[0].employeeComment.problems') || []
                    } || {}
            });
        } else {
            const data = {
                insertMode: true,
                laborRequests: [
                    {
                        ...dataSource[0],
                        storeGroupId: !complexId ? storeGroupId : undefined,
                        complexId: !complexType ? complexId : undefined,
                        customComplexId: complexType && complexId ? complexId : undefined,
                        serviceId: !complexId ? get(dataSource, '[0].serviceId', null) : null,
                        employeeId: employeeId || null,
                        employeeName: undefined,
                        employeeComment:
                            {
                                comment: get(dataSource, '[0].employeeComment.comment') || null,
                                positions: get(dataSource, '[0].employeeComment.positions') || [],
                                problems: get(dataSource, '[0].employeeComment.problems') || []
                            } || {}
                    }
                ],
                services: []
            };

            await fetchAPI('PUT', `orders/${orderId}`, null, data, { handleErrorInternally: true });
            updateDataSource();
        }
        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            dataSource: [],
            loading: false
        });
        this.props.hideModal();
        this.props.handleSearchLabors(undefined);
    };

    getMobileForm() {
        const { dataSource } = this.state;
        const data = dataSource[0] || {};
        const columns = this.columns();

        return columns.map(({ title, key, render, dataIndex }) => {
            if (key == 'actionType') {
                return;
            }

            return (
                <div key={key} className={`${Styles.mobileTable}  ${key == 'employee' && Styles.mobileTableEmployee}`}>
                    {key != 'comment' && title}
                    <div>{dataIndex ? render(data[dataIndex], data) : render(data)}</div>
                </div>
            );
        });
    }

    deleteMobileRow = async () => {
        const { request, orderId } = this.props;

        await fetchAPI('DELETE', `orders/${orderId}/labors_request`, { ids: `[${request.id}]` }, undefined, {
            handleErrorInternally: true
        });
        await notification.success({
            message: this.props.intl.formatMessage({
                id: 'details_table.deleted'
            })
        });
        this.props.updateDataSource();
        this.props.hideModal();
    };

    confirmDelete = () => {
        const { formatMessage } = this.props.intl;
        const that = this;
        confirm({
            title: formatMessage({ id: 'add_order_form.delete_confirm' }),
            onOk() {
                that.deleteMobileRow();
            },
            okType: 'danger'
        });
    };

    render() {
        const { open, isMobile, request } = this.props;
        const { dataSource, editing, loading, storeGroupId } = this.state;

        const columns = this.columns();

        return (
            <React.Fragment>
                <Modal
                    footer={
                        isMobile && editing ? (
                            <div>
                                <Button
                                    data-qa='button_confirm_delete_add_service_modal'
                                    onClick={async () => {
                                        this.confirmDelete();
                                    }}
                                    style={{
                                        float: 'left'
                                    }}
                                    type='danger'
                                >
                                    <DeleteOutlined />
                                </Button>
                                <Button
                                    data-qa='button_handle_cancel_add_service_modal'
                                    onClick={() => this.handleCancel()}
                                >
                                    <FormattedMessage id='cancel' />
                                </Button>
                                <Button
                                    data-qa='button_handle_ok_add_service_modal'
                                    loading={loading}
                                    onClick={() => this.handleOk()}
                                    type='primary'
                                >
                                    <FormattedMessage id='save' />
                                </Button>
                            </div>
                        ) : (
                            void 0
                        )
                    }
                    forceRender
                    maskClosable={false}
                    okButtonProps={{ loading, disabled: !get(dataSource, '[0].laborRequestName.comment') }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={open}
                    title={
                        !editing ? (
                            <FormattedMessage id='order_form_table_requests.add_request' />
                        ) : (
                            <FormattedMessage id='order_form_table_requests.edit_request' />
                        )
                    }
                    width='90%'
                    wrapClassName='orderRequestModal'
                >
                    <div className={Styles.tableWrap}>
                        {!isMobile ? (
                            <Table bordered columns={columns} dataSource={dataSource} pagination={false} size='small' />
                        ) : (
                            this.getMobileForm()
                        )}
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
export default OrderRequestModal;

@injectIntl
export class RequestCommentaryButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            currentCommentaryProps: {
                name: props.detail,
                positions: [],
                problems: []
            },
            currentCommentary: undefined
        };
        this.commentaryInput = React.createRef();
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this._isMounted = false;
    }

    showModal = () => {
        this.setState({
            currentCommentary: this.props.commentary.comment ? this.props.commentary.comment : this.props.detail,
            visible: true
        });
        if (this.commentaryInput.current !== undefined) {
            this.commentaryInput.current.focus();
        }

        if (!this.props.commentary.comment) {
            this.setState({
                currentCommentaryProps: {
                    name: this.props.detail,
                    positions: [],
                    problems: []
                }
            });
        }
    };

    handleOk = async () => {
        const { currentCommentary, currentCommentaryProps } = this.state;
        this.setState({
            loading: true
        });
        this.props.setComment(currentCommentary, currentCommentaryProps.positions, currentCommentaryProps.problems);
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 500);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            currentCommentary: this.props.detail,
            currentCommentaryProps: {
                name: this.props.detail,
                positions: [],
                problems: []
            }
        });
    };

    renderHeader = () => {
        return (
            <div>
                <p>{this.props.detail}</p>
            </div>
        );
    };

    getCommentary() {
        const { currentCommentaryProps } = this.state;
        let currentCommentary = this.props.detail;

        if (currentCommentaryProps.positions.length) {
            currentCommentary += ' -';
            currentCommentary += `${currentCommentaryProps.positions.map(
                data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
            )};`;
        }
        this.setState({
            currentCommentary
        });
    }

    setCommentaryPosition(position) {
        const { currentCommentaryProps } = this.state;
        const positionIndex = currentCommentaryProps.positions.indexOf(position);
        if (positionIndex == -1) {
            currentCommentaryProps.positions.push(position);
        } else {
            currentCommentaryProps.positions = currentCommentaryProps.positions.filter(
                (value, index) => index != positionIndex
            );
        }
        this.getCommentary();
    }

    componentDidMount() {
        this._isMounted = true;
        const { commentary, detail } = this.props;
        if (this._isMounted) {
            this.setState({
                currentCommentaryProps: {
                    name: detail,
                    positions: commentary.positions || [],
                    problems: commentary.problems || []
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { TextArea } = Input;
        const { visible, loading, currentCommentaryProps, currentCommentary } = this.state;
        const { disabled, commentary } = this.props;
        const { positions } = this;

        return (
            <div>
                {commentary.comment ? (
                    <Button
                        className={Styles.commentaryButton}
                        data-qa='button_commentary_edit_add_service_modal'
                        onClick={this.showModal}
                        title={this.props.intl.formatMessage({ id: 'commentary.edit' })}
                        type='primary'
                    >
                        <FormOutlined className={Styles.commentaryButtonIcon} />
                    </Button>
                ) : (
                    <Tooltip title={<FormattedMessage id='commentary.add' />} zIndex={2001}>
                        <Button
                            data-qa='button_commentary_add_add_service_modal'
                            disabled={disabled}
                            onClick={this.showModal}
                        >
                            <MessageOutlined />
                        </Button>
                    </Tooltip>
                )}
                <Modal
                    footer={
                        disabled
                            ? null
                            : [
                                  <Button
                                      key='back'
                                      data-qa='button_handle_cancel_commentary_button_add_service_modal'
                                      onClick={this.handleCancel}
                                  >
                                      <FormattedMessage id='cancel' />
                                  </Button>,
                                  <Button
                                      key='submit'
                                      data-qa='button_handle_ok_commentary_button_add_service_modal'
                                      loading={loading}
                                      onClick={this.handleOk}
                                      type='primary'
                                  >
                                      <FormattedMessage id='save' />
                                  </Button>
                              ]
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={visible}
                    title={this.renderHeader()}
                >
                    <React.Fragment>
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.where' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {positions.map((position, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            className={Styles.commentaryBlockButton}
                                            data-qa='button_set_comementary_block_commentary_button_add_service_modal'
                                            onClick={() => {
                                                this.setCommentaryPosition(position);
                                            }}
                                            type={
                                                currentCommentaryProps.positions.findIndex(elem => position == elem) ===
                                                -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <FormattedMessage id={position} />
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='order_form_table.diagnostic.commentary' />
                            </p>
                            <TextArea
                                ref={this.commentaryInput}
                                autoFocus
                                data-qa='text_area_current_commentary_button_add_service_modal'
                                disabled={disabled}
                                onChange={() => {
                                    this.setState({
                                        currentCommentary: event.target.value
                                    });
                                }}
                                placeholder={`${this.props.intl.formatMessage({
                                    id: 'comment'
                                })}...`}
                                style={{ width: '100%', minHeight: '150px', resize: 'none' }}
                                value={currentCommentary}
                            />
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}
