/* eslint-disable max-classes-per-file */
import {
    CheckOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    FormOutlined,
    LoadingOutlined,
    MenuOutlined,
    MessageOutlined,
    StarFilled
} from '@ant-design/icons';
import { Button, Input, InputNumber, Menu, Modal, Select, Spin, Table, Tooltip, TreeSelect } from 'antd';
import { HamburgerMenu } from 'components';
import { API_URL } from 'core/forms/orderDiagnosticForm/saga';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, getCurrency, isForbidden, numeralFormatter, numeralParser, permissions } from 'utils';
import Styles from './styles.m.css';

const { TreeNode } = TreeSelect;
const { Option } = Select;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

@injectIntl
class FavouriteServicesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            dataSource: [],
            relatedDetailsCheckbox: false,
            groupSearchValue: '',
            selectedRowKeys: [],
            selectedRows: [],
            page: 1,
            pageSize: 25,
            total: 0
        };
        this.labors = [];
        this.masterLabors = [];
        this.storeGroups = [];
        this.laborsTreeData = [];
        this.brandOptions = [];
        this.servicesOptions = [];
        this.employeeOptions = [];
        this.relatedDetailsOptions = [];

        this.setComment = this.setComment.bind(this);
        this.setHours = this.setHours.bind(this);

        this.columns = [
            {
                title: () => {
                    const {
                        orderId,
                        intl: { formatMessage }
                    } = this.props;
                    const { selectedRows, selectedRowKeys } = this.state;

                    const actionsMenu = (
                        <Menu>
                            <Menu.Item disabled={!selectedRows.length}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.favourite_remove'
                                            }),
                                            zIndex: 2001,
                                            onOk: async () => {
                                                await fetchAPI('DELETE', 'orders/frequent/labors', {
                                                    ids: `[${this.state.selectedRows.map(
                                                        ({ frequentLaborId }) => frequentLaborId
                                                    )}]`
                                                });
                                                this.fetchData();
                                                this.props.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                disabled={!selectedRows.length}
                                onClick={() => {
                                    this.handleOk(selectedRows);
                                }}
                            >
                                <div>
                                    <CheckOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'order_form_table.add_to_order_tooltip' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} />;
                },
                key: 'actions',
                align: 'center',
                render: elem => {
                    const actionsMenu = () => (
                        <Menu>
                            <Menu.Item>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.favourite_remove'
                                            }),
                                            zIndex: 2001,
                                            onOk: async () => {
                                                await fetchAPI('DELETE', 'orders/frequent/labors', {
                                                    ids: `[${elem.frequentLaborId}]`
                                                });
                                                this.fetchData();
                                                this.props.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => {
                                    this.handleOk([elem]);
                                }}
                            >
                                <div>
                                    <CheckOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'order_form_table.add_to_order_tooltip' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <HamburgerMenu actionsMenu={actionsMenu}>
                            <Button
                                data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                icon={<MenuOutlined />}
                            />
                        </HamburgerMenu>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.service_type' />,
                key: 'masterLaborId',
                dataIndex: 'masterLaborId',
                width: '10%',
                render: (data, elem) => {
                    return (
                        <TreeSelect
                            className={Styles.groupsTreeSelect}
                            data-qa='tree_select_marterLaborId_favorite_service_modal'
                            disabled
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                            filterTreeNode={(input, node) => {
                                return (
                                    node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onSelect={(value, option) => {
                                this.state.dataSource[elem.key].masterLaborId = value;
                                this.filterOptions(value, elem.storeGroupId);
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder='ID'
                            showSearch
                            style={{ maxWidth: 220, color: 'black' }}
                            treeData={this.props.laborsTreeData}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.store_group' />,
                key: 'storeGroupId',
                dataIndex: 'storeGroupId',
                width: '10%',
                render: (data, elem) => {
                    return (
                        <TreeSelect
                            className={Styles.groupsTreeSelect}
                            data-qa='tree_select_storeGroupId_favorite_service_modal'
                            disabled
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                            filterTreeNode={(input, node) => {
                                return (
                                    node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onSelect={(value, option) => {
                                this.state.dataSource[elem.key].storeGroupId = value;
                                this.state.dataSource[elem.key].laborId = undefined;
                                this.state.dataSource[elem.key].serviceName = undefined;
                                this.filterOptions(elem.masterLaborId, value);
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.store_group'
                            })}
                            showSearch
                            style={{ maxWidth: 220, color: 'black' }}
                            treeData={this.props.detailsTreeData}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'serviceName',
                dataIndex: 'serviceName',
                width: '20%',
                render: (data, elem) => {
                    return (
                        <Input
                            data-qa='input_serviceName_favorite_service_modal'
                            disabled
                            onChange={event => {
                                this.state.dataSource[elem.key].serviceName = event.target.value;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'name'
                            })}
                            style={{ minWidth: 150, color: 'black' }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'comment',
                dataIndex: 'comment',
                width: '5%',
                render: (data, elem) => {
                    let detail = elem.serviceName;
                    if (detail && detail.indexOf(' - ') > -1) {
                        detail = detail.slice(0, detail.indexOf(' - '));
                    }

                    return (
                        <CommentaryButton
                            commentary={
                                data || {
                                    comment: undefined,
                                    positions: []
                                }
                            }
                            data-qa='commentary_button_comment_favorite_service_modal'
                            detail={detail}
                            disabled={elem.laborId == null}
                            setComment={this.setComment}
                            tableKey={elem.key}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                width: '3%',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            data-qa='input_number_purchasePrice_favorite_service_modal'
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={0}
                            onChange={value => {
                                this.state.dataSource[elem.key].purchasePrice = value;
                                this.setState({
                                    update: true
                                });
                            }}
                            parser={value => numeralParser(value)}
                            value={Math.round(data * 10) / 10 || 0}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                dataIndex: 'price',
                width: '3%',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            data-qa='input_number_price_favorite_service_modal'
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={1}
                            onChange={value => {
                                this.state.dataSource[elem.key].price = value;
                                this.state.dataSource[elem.key].sum = value * this.state.dataSource[elem.key].count;
                                this.setState({
                                    update: true
                                });
                            }}
                            parser={value => numeralParser(value)}
                            value={Math.round(data * 10) / 10 || 1}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'count',
                dataIndex: 'count',
                width: '3%',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            data-qa='input_number_count_favorite_service_modal'
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={0.1}
                            onChange={value => {
                                this.state.dataSource[elem.key].count = value;
                                this.state.dataSource[elem.key].sum = value * this.state.dataSource[elem.key].price;
                                this.setState({
                                    update: true
                                });
                            }}
                            parser={value => numeralParser(value)}
                            value={Math.round(data * 10) / 10 || 0}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='hours' />,
                key: 'hours',
                dataIndex: 'hours',
                width: '3%',
                render: (data, elem) => {
                    return (
                        <NormHourModal
                            hours={Math.round(data * 10) / 10}
                            onSelect={this.setHours}
                            storeGroupId={elem.storeGroupId}
                            tableKey={elem.key}
                            tecdocId={this.props.tecdocId}
                            user={this.props.user}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'sum',
                width: '5%',
                render: elem => {
                    const sum = this.state.dataSource[elem.key].price * this.state.dataSource[elem.key].count;

                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            data-qa='input_number_sum_favorite_service_modal'
                            decimalSeparator=','
                            disabled
                            formatter={value => numeralFormatter(value)}
                            parser={value => numeralParser(value)}
                            style={{ color: 'black' }}
                            value={Math.round(sum * 10) / 10 || 0}
                        />
                    );
                }
            }
            // {
            //     key: 'select',
            //     width: '3%',
            //     render: elem => {
            //         return (
            //             <Button
            //                 data-qa='button_select_favorite_service_modal'
            //                 onClick={() => {
            //                     this.handleOk(elem);
            //                 }}
            //                 type='primary'
            //             >
            //                 <FormattedMessage id='select' />
            //             </Button>
            //         );
            //     }
            // }
        ];
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            selectedRows: [],
            selectedRowKeys: [],
            pageSize: 25,
            page: 1
        });
    };

    setComment(comment, positions, index) {
        this.state.dataSource[index].comment = {
            comment,
            positions
        };
        this.state.dataSource[index].serviceName = comment || this.state.dataSource[index].serviceName;
        this.setState({
            update: true
        });
    }

    handleOk = rows => {
        const defaultUse = _.get(
            this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
            '[0].id'
        );

        const payload = {
            insertMode: true,
            services: rows.map(
                ({ laborId, serviceName, employeeId, hours, purchasePrice, count, comment, laborUnitId }) => ({
                    serviceId: laborId,
                    serviceName,
                    employeeId,
                    serviceHours: hours || 0,
                    purchasePrice,
                    count: count || 1,
                    laborUnitId: laborUnitId || defaultUse
                })
            ),
            details: []
        };

        this.addDetailsAndLabors(payload);
        this.handleCancel();
    };

    setHours(hours, index) {
        this.state.dataSource[index].hours = hours;
        this.state.dataSource[index].count = hours;
        this.setState({
            update: true
        });
    }

    async addDetailsAndLabors(data) {
        const { status } = await fetchAPI('GET', 'orders/status', { orderId: this.props.orderId }, null);
        if (status === 'success') {
            window.location.reload();

            return;
        }
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = API_URL;
        const params = `/orders/${this.props.orderId}`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            if (result.success) {
                this.props.updateDataSource();
            } else {
                console.log('BAD', result);
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    fetchData = async () => {
        const { page, pageSize } = this.state;
        const { count, labors } = await fetchAPI('GET', 'orders/frequent/labors', { page, pageSize }, null, {
            handleErrorInternally: true
        });

        this.setState({
            total: count
        });
        this.labors = this.props.labors;
        this.storeGroups = this.props.details;
        labors.forEach((elem, key) => {
            elem.key = key;
            elem.employeeId = this.props.defaultEmployeeId;
            elem.masterLaborId = elem.laborData.masterLaborId;
            elem.storeGroupId = elem.laborData.storeGroupId;
            elem.serviceName = elem.name;
            elem.price = elem.laborData.laborPrice.price || this.props.normHourPrice;
            elem.count = elem.laborData.laborPrice.normHours || 1;
            elem.hours = 0;
            elem.laborUnitId = elem.laborData.laborUnitId;
        });
        this.setState({
            dataSource: labors
        });
        this.getOptions();
    };

    getOptions() {
        this.servicesOptions = this.props.labors.map((elem, index) => (
            <Option
                key={index}
                master_id={elem.masterLaborId}
                norm_hours={elem.laborPrice.normHours}
                price={elem.laborPrice.price}
                product_id={elem.storeGroupId}
                value={elem.laborId}
            >
                {elem.name ? elem.name : elem.defaultName}
            </Option>
        ));

        this.employeeOptions = this.props.employees.map((elem, i) => (
            <Option key={i} value={elem.id}>
                {elem.name} {elem.surname}
            </Option>
        ));
    }

    filterOptions(masterLaborId, storeGroupId) {
        let servicesOptions = [...this.labors];
        if (masterLaborId) {
            servicesOptions = servicesOptions.filter((elem, index) => elem.masterLaborId == masterLaborId);
        }
        if (storeGroupId) {
            servicesOptions = servicesOptions.filter((elem, index) => elem.storeGroupId == storeGroupId);
        }
        servicesOptions = servicesOptions.map((elem, index) => (
            <Option
                key={index}
                master_id={elem.masterLaborId}
                norm_hours={elem.laborPrice.normHours}
                price={elem.laborPrice.price}
                product_id={elem.storeGroupId}
                value={elem.laborId}
            >
                {elem.name ? elem.name : elem.defaultName}
            </Option>
        ));

        this.servicesOptions = [...servicesOptions];
    }

    componentDidMount() {
        // this.fetchData();
    }

    componentDidUpdate(_, prevState) {
        if (this.state.visible && !prevState.visible) {
            this.fetchData();
        }
    }

    render() {
        const { selectedRowKeys, selectedRows, page, pageSize, total } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        const pagination = {
            pageSize,
            size: 'small',
            total: (Math.ceil(total) / pageSize) * pageSize,
            hideOnSinglePage: false,
            showSizeChanger: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchData();
            }
        };

        return (
            <React.Fragment>
                <Tooltip placement='top' title={<FormattedMessage id='labors_table.favorite_labors' />}>
                    <Button
                        data-qa='button_visible_labors_favorite_service_modal'
                        disabled={this.props.disabled}
                        icon={<StarFilled />}
                        onClick={() => {
                            this.setState({
                                visible: true
                            });
                        }}
                    />
                </Tooltip>
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='labors_table.favorite_labors' />}
                    visible={this.state.visible}
                    width='95%'
                >
                    <div className={Styles.tableWrap}>
                        <Table
                            bordered
                            columns={this.columns}
                            dataSource={this.state.dataSource}
                            pagination={pagination}
                            rowKey='laborId'
                            rowSelection={rowSelection}
                        />
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
export default FavouriteServicesModal;

@injectIntl
class NormHourModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataSource: [],
            fetched: false,
            filterValue: undefined
        };

        this.columns = [
            {
                title: <FormattedMessage id='order_form_table.service_type' />,
                key: 'kortext',
                dataIndex: 'kortext',
                width: '15%',
                render: (data, elem) => {
                    return <span style={{ textTransform: 'capitalize' }}>{data}</span>;
                }
            },
            {
                title: () => (
                    <div>
                        <FormattedMessage id='services_table.service_type' />
                        <Input
                            data-qa='input_filter_value_norm_hour_favorite_service_modal'
                            onChange={event => {
                                this.setState({
                                    filterValue: event.target.value
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.service_type'
                            })}
                            value={this.state.filterValue}
                        />
                    </div>
                ),
                key: 'itemmptext',
                dataIndex: 'itemmptext',
                width: '15%',
                render: (data, elem) => {
                    return <span style={{ textTransform: 'capitalize' }}>{data}</span>;
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'qualcoltext',
                dataIndex: 'qualcoltext',
                width: '15%',
                render: (data, elem) => {
                    return <span style={{ textTransform: 'capitalize' }}>{data}</span>;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.price' />,
                key: 'price',
                dataIndex: 'price',
                width: '10%',
                render: (data, elem) => {
                    return (
                        <span>
                            {data} {getCurrency()}
                        </span>
                    );
                }
            },
            {
                title: <FormattedMessage id='services_table.norm_hours' />,
                key: 'worktime',
                dataIndex: 'worktime',
                width: '10%',
                render: (data, elem) => {
                    return (
                        <span>
                            {data} <FormattedMessage id='order_form_table.hours_short' />
                        </span>
                    );
                }
            },
            {
                title: <FormattedMessage id='sum' />,
                key: 'sum',
                dataIndex: 'sum',
                width: '10%',
                render: (data, elem) => {
                    return (
                        <span>
                            {Math.round(elem.price * elem.worktime * 10) / 10} {getCurrency()}
                        </span>
                    );
                }
            },
            {
                key: 'select',
                width: '10%',
                render: (data, elem) => {
                    return (
                        <Button
                            data-qa='button_select_norm_hour_favorite_service_modal'
                            onClick={() => {
                                this.props.onSelect(elem.worktime, this.props.tableKey);
                                this.handleCancel();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='select' />
                        </Button>
                    );
                }
            }
        ];
    }

    fetchData() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = API_URL;
        const params = `/tecdoc/labor_times?modificationId=${this.props.tecdocId}&storeGroupId=${this.props.storeGroupId}`;
        url += params;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.laborTimes.map((elem, i) => {
                    elem.key = i;
                    elem.price = data.priceOfNormHour;
                    elem.worktime = Math.ceil(elem.worktime * 10) / 10;
                });
                that.setState({
                    dataSource: data.laborTimes,
                    fetched: true
                });
            })
            .catch(function (error) {
                console.log('error', error);
                that.setState({
                    fetched: true
                });
            });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            dataSource: [],
            fetched: false
        });
    };

    componentDidUpdate() {
        if (!this.state.fetched && this.state.visible) {
            this.fetchData();
        }
    }

    render() {
        const { hours } = this.props;
        const { dataSource, filterValue } = this.state;
        let tblData = [...dataSource];

        if (filterValue) {
            tblData = tblData.filter(elem => elem.itemmptext.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0);
        }

        return (
            <React.Fragment>
                <Tooltip title={<FormattedMessage id='labors_table.check_labor_hours' />} zIndex={2001}>
                    <Button
                        data-qa='button_visible_horm_hour_favorite_service_modal'
                        disabled={isForbidden(this.props.user, permissions.ACCESS_NORM_HOURS_MODAL_WINDOW)}
                        onClick={() => {
                            this.setState({ visible: true });
                        }}
                    >
                        {hours ? (
                            <React.Fragment>
                                {hours} <FormattedMessage id='order_form_table.hours_short' />
                            </React.Fragment>
                        ) : (
                            <ClockCircleOutlined />
                        )}
                    </Button>
                </Tooltip>
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='services_table.norm_hours' />}
                    visible={this.state.visible}
                    width='75%'
                >
                    {this.state.fetched ? (
                        <Table bordered columns={this.columns} dataSource={tblData} />
                    ) : (
                        <Spin indicator={spinIcon} />
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

@injectIntl
class CommentaryButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            currentCommentaryProps: {
                name: props.detail,
                positions: []
            },
            currentCommentary: undefined
        };
        this.commentaryInput = React.createRef();
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this._isMounted = false;
    }

    showModal = () => {
        this.setState({
            currentCommentary: this.props.commentary.comment ? this.props.commentary.comment : this.props.detail,
            visible: true
        });
        if (this.commentaryInput.current != undefined) {
            this.commentaryInput.current.focus();
        }
    };

    handleOk = async () => {
        const { currentCommentary, currentCommentaryProps } = this.state;
        this.setState({
            loading: true
        });
        this.props.setComment(currentCommentary, currentCommentaryProps.positions, this.props.tableKey);
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 500);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            currentCommentary: this.props.detail,
            currentCommentaryProps: {
                name: this.props.detail,
                positions: []
            }
        });
    };

    renderHeader = () => {
        return (
            <div>
                <p>{this.props.detail}</p>
            </div>
        );
    };

    getCommentary() {
        const { currentCommentaryProps } = this.state;
        let currentCommentary = this.props.detail;

        if (currentCommentaryProps.positions.length) {
            currentCommentary += ' -';
            currentCommentary += `${currentCommentaryProps.positions.map(
                data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
            )};`;
        }
        this.setState({
            currentCommentary
        });
    }

    setCommentaryPosition(position) {
        const { currentCommentaryProps } = this.state;
        const positionIndex = currentCommentaryProps.positions.indexOf(position);
        if (positionIndex == -1) {
            currentCommentaryProps.positions.push(position);
        } else {
            currentCommentaryProps.positions = currentCommentaryProps.positions.filter(
                (value, index) => index != positionIndex
            );
        }
        this.getCommentary();
    }

    componentDidMount() {
        this._isMounted = true;
        const { commentary, detail } = this.props;
        if (this._isMounted) {
            this.setState({
                currentCommentaryProps: {
                    name: detail,
                    positions: commentary.positions || []
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { TextArea } = Input;
        const { visible, loading, currentCommentaryProps, currentCommentary } = this.state;
        const { disabled, commentary } = this.props;
        const { positions } = this;

        return (
            <div>
                {commentary.comment ? (
                    <Button
                        className={Styles.commentaryButton}
                        data-qa='button_edit_commentary_button_favorite_service_modal'
                        onClick={this.showModal}
                        title={this.props.intl.formatMessage({ id: 'commentary.edit' })}
                        type='primary'
                    >
                        <FormOutlined
                            className={Styles.commentaryButtonIcon}
                            style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                        />
                    </Button>
                ) : (
                    <Tooltip title={<FormattedMessage id='commentary.add' />} zIndex={2001}>
                        <Button
                            data-qa='button_add_commentary_button_favorite_service_modal'
                            disabled={disabled}
                            onClick={this.showModal}
                        >
                            <MessageOutlined />
                        </Button>
                    </Tooltip>
                )}
                <Modal
                    footer={
                        disabled
                            ? null
                            : [
                                  <Button key='back' onClick={this.handleCancel}>
                                      <FormattedMessage id='cancel' />
                                  </Button>,
                                  <Button key='submit' loading={loading} onClick={this.handleOk} type='primary'>
                                      <FormattedMessage id='save' />
                                  </Button>
                              ]
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={this.renderHeader()}
                    visible={visible}
                >
                    <React.Fragment>
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.where' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {positions.map((position, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            className={Styles.commentaryBlockButton}
                                            data-qa='button_set_commentary_commentary_button_favorite_service_modal'
                                            onClick={() => {
                                                this.setCommentaryPosition(position);
                                            }}
                                            type={
                                                currentCommentaryProps.positions.findIndex(elem => position == elem) >
                                                -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <FormattedMessage id={position} />
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='order_form_table.diagnostic.commentary' />
                            </p>
                            <TextArea
                                ref={this.commentaryInput}
                                autoFocus
                                data-qa='text_area_commentaryInput_commentary_button_favorite_service_modal'
                                disabled={disabled}
                                onChange={() => {
                                    this.setState({
                                        currentCommentary: event.target.value
                                    });
                                }}
                                placeholder={`${this.props.intl.formatMessage({
                                    id: 'comment'
                                })}...`}
                                style={{ width: '100%', minHeight: '150px', resize: 'none' }}
                                value={currentCommentary}
                            />
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}
