import { Input, InputNumber, Modal, notification, Select, TreeSelect } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, numeralFormatter, numeralParser } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
export default class AddRegulationsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            if (this.props.regulationModalRow) {
                this.setState({
                    ...this.props.regulationModalRow,
                    laborId: this.props.regulationModalRow.laborId,
                    regulationKm: this.props.regulationModalRow.km || 1,
                    regulationMonths: this.props.regulationModalRow.months || 1,
                    storeGroupId: this.props.regulationModalRow.laborGroupId
                });
            } else {
                this.setState({
                    regulationKm: 1,
                    regulationMonths: 1
                });
            }
        }
    }

    handleOk = async () => {
        if (this.props.regulationModalRow) {
            await this.editRegulationRow();
            await setTimeout(() => this.props.fetchData(), 500);
            this.handleCancel();
        } else {
            await this.addRegulationRow();
            await setTimeout(() => this.props.fetchData(), 500);
            this.handleCancel();
        }
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'message.success' })
        });
    };

    handleCancel() {
        this.setState({
            storeGroupId: undefined,
            regulationName: undefined,
            regulationKm: undefined,
            regulationMonths: undefined,
            laborId: undefined,
            laborGroupId: undefined
        });
        this.props.hideModal();
    }

    addRegulationRow = async () => {
        const { regulationName, laborId, storeGroupId, regulationKm, regulationMonths, laborGroupId } = this.state;
        const { idForOwnRegulations } = this.props;
        await fetchAPI(
            'POST',
            'businesses/add/regulations',
            undefined,
            {
                groupId: idForOwnRegulations,
                regulationName,
                regulationKm,
                regulationMonths,
                laborId,
                laborGroupId: storeGroupId,
                visibility: true
            },
            {
                handleErrorInternally: true
            }
        );
    };

    editRegulationRow = async () => {
        const { regulationName, regulationKm, regulationMonths } = this.state;
        const { regulationModalRow } = this.props;
        await fetchAPI('PUT', 'businesses/regulations', undefined, {
            id: regulationModalRow.id,
            regulationName,
            regulationKm,
            regulationMonths,
            visibility: regulationModalRow.visibility
        });
    };

    render() {
        const { detailsTreeData, regulationModalRow } = this.props;
        const { regulationName, laborId, regulationKm, regulationMonths, storeGroupId, name } = this.state;

        return (
            <Modal
                destroyOnClose
                maskClosable={false}
                okButtonProps={{
                    disabled: !regulationKm || !regulationMonths
                }}
                onCancel={() => {
                    this.handleCancel();
                }}
                onOk={() => {
                    this.handleOk();
                }}
                visible={this.props.visible}
                width='fit-content'
                zIndex={200}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        margin: '24px 0 0 0'
                    }}
                >
                    <div className={Styles.addProductItemWrap}>
                        <FormattedMessage id='services_table.labor' /> <span style={{ color: 'red' }}>*</span>
                        <Select
                            ref={node => (this.laborRef = node)}
                            allowClear
                            data-qa='select_labor_id_add_service_modal'
                            disabled={regulationModalRow ? regulationModalRow.default : null}
                            filterOption={false}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={(value, option) => {
                                const val = value && value.replace(/-/g, '');
                                this.setState({
                                    laborId: val,
                                    regulationName: option.name,
                                    name: option.name,
                                    storeGroupId: option.storeGroupId,
                                    laborGroupId: option.masterLaborId
                                });
                            }}
                            onSearch={input => this.props.handleSearchLabors(input)}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.labor'
                            })}
                            popupMatchSelectWidth={420}
                            showAction={['focus', 'click']}
                            showSearch
                            style={{ minWidth: 150, color: 'var(--text)' }}
                            value={laborId ? String(laborId) : undefined}
                        >
                            {this.props.labors.map((elem, index) => (
                                <Option
                                    key={index}
                                    masterLaborId={elem.masterLaborId}
                                    name={elem.name}
                                    storeGroupId={elem.storeGroupId}
                                    value={elem.id}
                                >
                                    {elem.name || elem.customName}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div className={Styles.addProductItemWrap} style={{ minWidth: 140 }}>
                        <FormattedMessage id='regulations.name' />

                        <Input
                            disabled={regulationModalRow ? regulationModalRow.default : null}
                            onChange={event => {
                                this.setState({
                                    regulationName: event.target.value
                                });
                            }}
                            value={regulationName}
                        />
                    </div>
                    <div className={Styles.addProductItemWrap} style={{ minWidth: 140 }}>
                        <FormattedMessage id='order_form_table.labors_code' />
                        <Input
                            disabled
                            onChange={value => {
                                this.setState({
                                    laborId: value
                                });
                            }}
                            style={{
                                color: 'black'
                            }}
                            value={laborId}
                        />
                    </div>
                    <div className={Styles.addProductItemWrap}>
                        <FormattedMessage id='services_table.store_group' />
                        <TreeSelect
                            className={Styles.groupsTreeSelect}
                            disabled
                            getPopupContainer={trigger => trigger.parentNode}
                            listHeight={440}
                            onChange={value => {
                                this.setState({
                                    storeGroupId: value
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.store_group'
                            })}
                            popupMatchSelectWidth={280}
                            showSearch
                            style={{ maxWidth: 320, minWidth: 100, color: 'var(--text)' }}
                            treeData={detailsTreeData}
                            value={storeGroupId}
                        />
                    </div>
                    <div className={Styles.addProductItemWrap}>
                        <div>
                            <FormattedMessage id='regulations.km' />
                        </div>
                        <InputNumber
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={1}
                            onChange={regulationKm => {
                                this.setState({
                                    regulationKm
                                });
                            }}
                            parser={value => numeralParser(value)}
                            style={{
                                color: 'black',
                                minWidth: 80
                            }}
                            value={Math.round(regulationKm * 100) / 100 || 0}
                        />
                    </div>
                    <div className={Styles.addProductItemWrap}>
                        <div>
                            <FormattedMessage id='regulations.months' />
                        </div>
                        <InputNumber
                            min={1}
                            onChange={regulationMonths => {
                                this.setState({
                                    regulationMonths
                                });
                            }}
                            style={{
                                color: 'black',
                                minWidth: 80
                            }}
                            value={regulationMonths}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}
