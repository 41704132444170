import { PrinterOutlined } from '@ant-design/icons';
import { Button, Radio, Select, Space, Table } from 'antd';
import { Numeral } from 'commons';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import DebouncedInput from 'pages/AccountPlanPage/components/DebouncedInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, filterByPartGeneral, getCurrency } from 'utils';
import Styles from './styles.m.css';

const fontOptions = {
    1: 'bold',
    2: 'bold',
    4: 'italic'
};

const radioOptions = Array.from(Array(4)).map((_, index) => index + 1);

const MainBookTab = ({ intl }) => {
    const [query, setQuery] = useState({
        dateFrom: dayjs().startOf('month').toISOString(),
        dateTo: dayjs().toISOString()
    });
    const [entries, setEntries] = useState([]);
    const [requisites, setRequisites] = useState([]);
    const [selectedRequisite, setSelectedRequisite] = useState();
    const [tablePagination, setTablePagination] = useState({ pageSize: 2 });
    const [foundIds, setFoundIds] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [entriesTree, setEntriesTree] = useState([]);
    const [visibleLevel, setVisibleLevel] = useState(3);
    const [filter, setFilter] = useState('');

    const getTree = useCallback((root, res) => {
        const children = res.filter(entry => entry.parentId === root.id);
        if (children.length) {
            return {
                ...root,
                children: children.map(child => getTree(child, res))
            };
        }

        return root;
    }, []);

    const getTrees = useCallback(
        res => {
            const roots = res.filter(node => node.parentId === null);

            return roots.map(root => getTree(root, res));
        },
        [getTree]
    );

    const fetchEntries = useMemo(
        () => async query => {
            const dateFrom = dayjs(query.dateFrom).format('YYYY-MM-DD');
            const dateTo = dayjs(query.dateTo).format('YYYY-MM-DD');
            const res = await fetchAPI('GET', '/general_ledger/entries_tree', { ...query, dateFrom, dateTo }, null, {
                handleErrorInternally: true
            });

            setEntries(res);

            setExpandedRowKeys(res.map(({ id }) => id));

            setEntriesTree(getTrees(res));
        },
        [getTrees]
    );

    const fetchRequisites = useCallback(async () => {
        const res = await fetchAPI('GET', '/businesses/requisites', null, null, {
            handleErrorInternally: true
        });

        setRequisites(res.map(({ id, name }) => ({ value: id, label: name })));
    }, []);

    const handleLevelVisibility = useCallback(
        e => {
            setExpandedRowKeys(entries.filter(({ level }) => level < e.target.value).map(({ id }) => id));
            setVisibleLevel(Number(e.target.value));
        },
        [entries]
    );

    // const handleRequisiteChange = value => setQuery({ ...query });
    const handleRequisiteChange = value => setQuery({ ...query, requisiteId: value });

    const debouncedFilterChange = useMemo(
        () => filter => {
            const foundMap = {};
            const found = entries.filter(
                entry => filterByPartGeneral(filter, entry.name) || filterByPartGeneral(filter, entry.id)
            );

            const findParents = entry => {
                if (!foundMap[entry.id]) {
                    foundMap[entry.id] = entry;
                    const parent = entries.find(genEntry => genEntry.id === entry.parentId);
                    if (parent) findParents(parent);
                }
            };

            found.forEach(foundEntry => {
                findParents(foundEntry);
            });

            const res = Object.values(foundMap);
            if (filter.length > 0) {
                const maxLevel = Math.max(...found.map(({ level }) => level));
                setExpandedRowKeys(res.filter(({ level }) => level < maxLevel).map(({ id }) => id));
                setFoundIds(found.map(({ id }) => id));
            } else {
                setFoundIds([]);
                setExpandedRowKeys(entries.filter(({ level }) => level < visibleLevel).map(({ id }) => id));
            }

            const rows = range => {
                const diff = range[1] - range[0];
                if (diff === 0) return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: 0`;

                return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: ${diff + 1}`;
            };

            if (found.length < 30) {
                setTablePagination({ showTotal: (total, range) => rows(range), pageSize: 9999 });
            } else {
                visibleLevel === 1
                    ? setTablePagination({
                          pageSize: 10,
                          showTotal: (total, range) => rows(range)
                      })
                    : setTablePagination({ pageSize: 2, showTotal: (total, range) => rows(range) });
            }
            setEntriesTree(getTrees(res));
        },
        [entries, getTrees, visibleLevel]
    );

    const renderNestedTable = useCallback(
        roots => {
            const dateFrom = dayjs(query.dateFrom).format('DD.MM.YY');
            const dateTo = dayjs(query.dateTo).format('DD.MM.YY');
            const columns = [
                {
                    key: 'id',
                    render: record => {
                        return (
                            <Link
                                className={`${foundIds.includes(record.id) && Styles.foundEntry}`}
                                onClick={() => {
                                    const data = {
                                        id: record.id,
                                        dateFrom: query.dateFrom,
                                        dateTo: query.dateTo
                                    };
                                    localStorage.setItem('journalEntryData', JSON.stringify(data));
                                }}
                                to={{
                                    pathname: book.journalEntries,
                                    state: {
                                        id: record.id,
                                        dateFrom: query.dateFrom,
                                        dateTo: query.dateTo
                                    }
                                }}
                            >
                                {record.id}
                            </Link>
                        );
                    }
                },
                { dataIndex: 'name', key: 'name' },
                {
                    title: () => (
                        <div>
                            <FormattedMessage id='account_plan.balance_on' /> <span>{dateFrom}</span>
                        </div>
                    ),
                    key: 'balanceOfFrom',
                    align: 'right',
                    children: [
                        {
                            title: <FormattedMessage id='account_plan.debit' />,
                            key: 'debetBalanceFrom',
                            align: 'right',
                            render: row => (
                                <Link
                                    onClick={() => {
                                        const data = {
                                            id: row.id,
                                            dateFrom: dayjs('1.1.0').toISOString(),
                                            dateTo: dayjs(query.dateFrom).subtract(1, 'day').endOf('day').toISOString()
                                        };
                                        localStorage.setItem('journalEntryData', JSON.stringify(data));
                                    }}
                                    to={{
                                        pathname: book.journalEntries,
                                        state: {
                                            id: row.id,
                                            dateFrom: dayjs('1.1.0').toISOString(),
                                            dateTo: dayjs(query.dateFrom).subtract(1, 'day').endOf('day').toISOString()
                                        }
                                    }}
                                >
                                    <Numeral currency={getCurrency()} mask='0,0.00'>
                                        {row.debetBalanceFrom || 0}
                                    </Numeral>
                                </Link>
                            )
                        },
                        {
                            title: <FormattedMessage id='account_plan.credit' />,
                            key: 'creditBalanceFrom',
                            align: 'right',
                            render: row => (
                                <Link
                                    onClick={() => {
                                        const data = {
                                            id: row.id,
                                            dateFrom: dayjs('1.1.0').toISOString(),
                                            dateTo: dayjs(query.dateFrom).subtract(1, 'day').endOf('day').toISOString()
                                        };
                                        localStorage.setItem('journalEntryData', JSON.stringify(data));
                                    }}
                                    to={{
                                        pathname: book.journalEntries,
                                        state: {
                                            id: row.id,
                                            dateFrom: dayjs('1.1.0').toISOString(),
                                            dateTo: dayjs(query.dateFrom).subtract(1, 'day').endOf('day').toISOString()
                                        }
                                    }}
                                >
                                    <Numeral currency={getCurrency()} mask='0,0.00'>
                                        {row.creditBalanceFrom || 0}
                                    </Numeral>
                                </Link>
                            )
                        }
                    ]
                },
                {
                    title: `${intl.formatMessage({ id: 'account_plan.turnover' })} ${dateFrom}-${dateTo}`,
                    key: 'DebetCredit',
                    align: 'right',
                    children: [
                        {
                            title: <FormattedMessage id='account_plan.debit' />,
                            align: 'right',
                            key: 'balanceOfDebet',
                            render: row => (
                                <Link
                                    onClick={() => {
                                        const data = {
                                            id: row.id,
                                            dateFrom: query.dateFrom,
                                            dateTo: query.dateTo,
                                            operationSide: 'DEBET'
                                        };
                                        localStorage.setItem('journalEntryData', JSON.stringify(data));
                                    }}
                                    to={{
                                        pathname: book.journalEntries,
                                        state: {
                                            id: row.id,
                                            dateFrom: query.dateFrom,
                                            dateTo: query.dateTo,
                                            operationSide: 'DEBET'
                                        }
                                    }}
                                >
                                    <Numeral currency={getCurrency()} mask='0,0.00'>
                                        {row.balanceOfDebet || 0}
                                    </Numeral>
                                </Link>
                            )
                        },
                        {
                            title: <FormattedMessage id='account_plan.credit' />,
                            align: 'right',
                            key: 'balanceOfCredit',
                            render: row => (
                                <Link
                                    onClick={() => {
                                        const data = {
                                            id: row.id,
                                            dateFrom: query.dateFrom,
                                            dateTo: query.dateTo,
                                            operationSide: 'CREDIT'
                                        };
                                        localStorage.setItem('journalEntryData', JSON.stringify(data));
                                    }}
                                    to={{
                                        pathname: book.journalEntries,
                                        state: {
                                            id: row.id,
                                            dateFrom: query.dateFrom,
                                            dateTo: query.dateTo,
                                            operationSide: 'CREDIT'
                                        }
                                    }}
                                >
                                    <Numeral currency={getCurrency()} mask='0,0.00'>
                                        {row.balanceOfCredit || 0}
                                    </Numeral>
                                </Link>
                            )
                        }
                    ]
                },
                {
                    title: () => (
                        <div>
                            <FormattedMessage id='account_plan.balance_on' /> <span>{dateTo}</span>
                        </div>
                    ),

                    key: 'balanceOfTo',
                    align: 'right',
                    children: [
                        {
                            title: <FormattedMessage id='account_plan.debit' />,
                            key: 'debetBalanceTo',
                            align: 'right',
                            render: row => (
                                <Link
                                    onClick={() => {
                                        const data = {
                                            id: row.id,
                                            dateFrom: dayjs('1.1.0').toISOString(),
                                            dateTo: dayjs(query.dateTo).endOf('day').toISOString()
                                        };
                                        localStorage.setItem('journalEntryData', JSON.stringify(data));
                                    }}
                                    to={{
                                        pathname: book.journalEntries,

                                        state: {
                                            id: row.id,
                                            dateFrom: dayjs('1.1.0').toISOString(),
                                            dateTo: dayjs(query.dateTo).endOf('day').toISOString()
                                        }
                                    }}
                                >
                                    <Numeral currency={getCurrency()} mask='0,0.00'>
                                        {row.debetBalanceTo || 0}
                                    </Numeral>
                                </Link>
                            )
                        },
                        {
                            title: <FormattedMessage id='account_plan.credit' />,
                            key: 'creditBalanceTo',
                            align: 'right',
                            render: row => (
                                <Link
                                    onClick={() => {
                                        const data = {
                                            id: row.id,
                                            dateFrom: dayjs('1.1.0').toISOString(),
                                            dateTo: dayjs(query.dateTo).endOf('day').toISOString()
                                        };
                                        localStorage.setItem('journalEntryData', JSON.stringify(data));
                                    }}
                                    to={{
                                        pathname: book.journalEntries,

                                        state: {
                                            id: row.id,
                                            dateFrom: dayjs('1.1.0').toISOString(),
                                            dateTo: dayjs(query.dateTo).endOf('day').toISOString()
                                        }
                                    }}
                                >
                                    <Numeral currency={getCurrency()} mask='0,0.00'>
                                        {row.creditBalanceTo || 0}
                                    </Numeral>
                                </Link>
                            )
                        }
                    ]
                }
            ];
            const tableProps = {
                dataSource: roots,
                columns,
                expandable: {
                    expandedRowKeys,
                    onExpandedRowsChange: expandedRowKeys => setExpandedRowKeys(expandedRowKeys)
                },
                onRow: ({ id, level }) => {
                    const classNames = `${Styles[fontOptions[level]] || ''} ${
                        foundIds.includes(id) ? Styles.foundEntry : ''
                    }`.trim();
                    if (classNames.length) {
                        return {
                            className: classNames
                        };
                    }
                },
                rowKey: 'id'
            };

            return <Table {...tableProps} bordered pagination={tablePagination} rowKey='id' />;
        },
        [query, intl, expandedRowKeys, tablePagination, foundIds]
    );

    useEffect(() => {
        fetchRequisites();
    }, [fetchRequisites]);

    useEffect(() => {
        const localeDate = JSON.parse(localStorage.getItem('journalEntryPageDate'));
        console.log(localeDate, 'localeDate');
        if (localeDate) {
            setQuery({
                ...query,
                dateFrom: localeDate && localeDate.dateFrom,
                dateTo: localeDate && localeDate.dateTo
            });
        }
    }, []);

    useEffect(() => {
        fetchEntries(query);
    }, [fetchEntries, query]);

    useEffect(() => {
        setExpandedRowKeys(entries.filter(({ level }) => level < 3).map(({ id }) => id));
        setVisibleLevel(3);
    }, [entries]);

    useEffect(() => {
        debouncedFilterChange(filter);
    }, [entries, filter, getTrees, debouncedFilterChange]);

    const saveXLSX = useCallback(async () => {
        const dateFrom = dayjs(query.dateFrom).format('YYYY-MM-DD');
        const dateTo = dayjs(query.dateTo).format('YYYY-MM-DD');

        const response = await fetchAPI(
            'GET',
            '/general_ledger/entries_tree/xlsx',
            { ...query, level: visibleLevel, query: filter, dateFrom, dateTo },
            null,
            {
                rawResponse: true,
                handleErrorInternally: true
            }
        );
        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
    }, [query, visibleLevel]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Space>
                <Radio.Group optionType='button' value={visibleLevel}>
                    {radioOptions.map(index => (
                        <Radio key={index} onClick={handleLevelVisibility} value={index}>
                            {index}
                        </Radio>
                    ))}
                </Radio.Group>
                <DebouncedInput
                    allowClear
                    placeholder={intl.formatMessage({ id: 'account_plan.search' })}
                    setState={setFilter}
                    width={300}
                />
                <Select
                    allowClear
                    onChange={handleRequisiteChange}
                    options={requisites}
                    placeholder={intl.formatMessage({ id: 'account_plan.filter_by_requisite' })}
                    popupMatchSelectWidth
                    style={{ width: 300 }}
                    value={query.requisite}
                />
                <DateRangePicker
                    dateRange={[dayjs(query.dateFrom), dayjs(query.dateTo)]}
                    minimize
                    onDateChange={([dateFrom, dateTo]) => {
                        const allDates = { dateFrom, dateTo };

                        setQuery({ ...query, dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString() });
                        localStorage.setItem('journalEntryPageDate', JSON.stringify(allDates));
                    }}
                    style={{ margin: '0 8px 0 0' }}
                />
                <Button icon={<PrinterOutlined />} onClick={saveXLSX} type='text' />
            </Space>
            {renderNestedTable(entriesTree)}
        </div>
    );
};

export default injectIntl(MainBookTab);
