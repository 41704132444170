import { Modal, TreeSelect } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { filterTreeNodeByPart } from 'utils';
import './styles.less';

@injectIntl
class StoreGroupSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            visible,
            hideModal,
            onSelect,
            treeData,
            intl: { formatMessage }
        } = this.props;
        const { storeGroupId } = this.state;

        return (
            <Modal
                maskClosable={false}
                onCancel={() => {
                    hideModal();
                }}
                onOk={() => {
                    onSelect(storeGroupId);
                    hideModal();
                }}
                title={<FormattedMessage id='order_form_table.choose_detail_group' />}
                visible={visible}
            >
                <TreeSelect
                    allowClear
                    data-qa='tree_select_storeGroupId_order_detail_modal'
                    filterTreeNode={filterTreeNodeByPart}
                    getPopupContainer={trigger => trigger.parentNode}
                    listHeight={440}
                    onChange={storeGroupId => {
                        this.setState({ storeGroupId });
                    }}
                    placeholder={formatMessage({ id: 'order_form_table.store_group' })}
                    popupMatchSelectWidth={280}
                    showSearch
                    treeData={treeData}
                />
            </Modal>
        );
    }
}

export default StoreGroupSelectModal;
