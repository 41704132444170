import { Form } from '@ant-design/compatible';
import { Col, Input, Modal, Row, Select } from 'antd';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, formatDetailCode } from 'utils';

import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    brands: selectBrands(state)
});

const mapDispatchToProps = {
    fetchBrands
};

const { Option } = Select;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AddOwnCrossModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.fetchBrands();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            this.setState({
                code: this.props.productCode,
                brandId: this.props.brand
            });
        }
    }

    onOk = async () => {
        const { code, brandId, xCode, xBrandId, dir } = this.state;
        await fetchAPI(
            'POST',
            'product_crosses',
            null,
            [
                {
                    code,
                    brandId,
                    xCode,
                    xBrandId,
                    dir
                }
            ],
            { handleErrorInternally: true }
        );

        this.props.fetchProductCrosses();
        this.onCancel();
    };

    onCancel = () => {
        this.setState({
            code: undefined,
            brandId: null,
            xCode: undefined,
            xBrandId: null,
            dir: undefined
        });
        this.props.hideModal();
    };

    render() {
        const {
            productCode,
            brand,
            visible,
            intl: { formatMessage }
        } = this.props;

        const { code, brandId, xCode, xBrandId, dir } = this.state;

        const dirM = ['ONE'];

        return (
            <div>
                <Modal
                    maskClosable={false}
                    okButtonProps={{
                        disabled: !code || !xCode || !dir
                    }}
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    title={
                        <React.Fragment>
                            <FormattedMessage id='product_crosses.add_own_cross' />
                        </React.Fragment>
                    }
                    visible={visible}
                >
                    <Form>
                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='requisite-setting.code' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input
                                    onChange={event => {
                                        this.setState({
                                            code: formatDetailCode(event.target.value)
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'requisite-setting.code'
                                    })}
                                    style={{
                                        maxWidth: 220
                                    }}
                                    value={code}
                                />
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='brand' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            brandId: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 220 }}
                                    value={brandId}
                                >
                                    {this.props.brands
                                        .filter(({ brandName }) => brandName)
                                        .map(({ brandId, brandName }) => (
                                            <Option key={brandId} brandName={brandName} value={brandId}>
                                                {brandName}
                                            </Option>
                                        ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='directories.x_code' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input
                                    onChange={event => {
                                        this.setState({
                                            xCode: formatDetailCode(event.target.value)
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'directories.x_code'
                                    })}
                                    style={{
                                        maxWidth: 220
                                    }}
                                    value={xCode}
                                />
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='directories.x_brand' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            xBrandId: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 220 }}
                                    value={xBrandId}
                                >
                                    {this.props.brands
                                        .filter(({ brandName }) => brandName)
                                        .map(({ brandId, brandName }) => (
                                            <Option key={brandId} brandName={brandName} value={brandId}>
                                                {brandName}
                                            </Option>
                                        ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='directories.direcroty' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            dir: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'directories.direcroty'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 220 }}
                                    value={dir}
                                >
                                    {dirM.map(value => (
                                        <Option key={value} value={value}>
                                            {value}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}
