export const CSH = {
    "id": {
        "ua": "id ідентифікатор",
        "en": "id identifier",
        "cz": "id identifikátor",
        "bg": "id идентификатор"
    },
    "type": {
        "ua": "тип CSH",
        "en": "CSH type",
        "cz": "typ CSH",
        "bg": "тип CSH"
    },
    "cashBoxId": {
        "ua": "id ідентифікатор каси",
        "en": "cash register id",
        "cz": "id identifikátor pokladny",
        "bg": "ид. номер на касата"
    },
    "datetime": {
        "ua": "дата та час",
        "en": "date and time",
        "cz": "datum a čas",
        "bg": "дата и час"
    },
    "createdAt": {
        "ua": "створено CSH",
        "en": "created CSH",
        "cz": "vytvořeno CSH",
        "bg": "създаден CSH"
    },
    "updatedAt": {
        "ua": "оновлено CSH",
        "en": "updated CSH",
        "cz": "aktualizováno CSH",
        "bg": "обновен CSH"
    },
    "orderId": {
        "ua": "id ідентифікатор замовлення",
        "en": "order id",
        "cz": "id identifikátor objednávky",
        "bg": "ид. номер на поръчката"
    },
    "increase": {
        "ua": "збільшення",
        "en": "increase",
        "cz": "zvýšení",
        "bg": "увеличение"
    },
    "decrease": {
        "ua": "зменшення",
        "en": "decrease",
        "cz": "snížení",
        "bg": "намаляване"
    },
    "createdBy": {
        "ua": "ким створено CSH",
        "en": "created by CSH",
        "cz": "kdo vytvořil CSH",
        "bg": "създадено от CSH"
    },
    "updatedBy": {
        "ua": "ким оновлено CSH",
        "en": "updated by CSH",
        "cz": "kdo aktualizoval CSH",
        "bg": "обновен от CSH"
    },
    "description": {
        "ua": "опис",
        "en": "description",
        "cz": "popis",
        "bg": "описание"
    },
    "clientId": {
        "ua": "id ідентифікатор клієнта",
        "en": "client id",
        "cz": "id identifikátor klienta",
        "bg": "ид. номер на клиента"
    },
    "employeeId": {
        "ua": "id ідентифікатор співробітника",
        "en": "employee id",
        "cz": "id identifikátor zaměstnance",
        "bg": "ид. номер на служителя"
    },
    "businessSupplierId": {
        "ua": "id ідентифікатор постачальника бізнесу",
        "en": "business supplier id",
        "cz": "id identifikátor dodavatele podnikání",
        "bg": "ид. номер на бизнес доставчика"
    },
    "otherCounterparty": {
        "ua": "інший контрагент",
        "en": "other counterparty",
        "cz": "jiný dodavatel",
        "bg": "друг контрагент"
    },
    "tag": {
        "ua": "мітка",
        "en": "tag",
        "cz": "nálepka",
        "bg": "етикет"
    },
    "externalId": {
        "ua": "зовнішній ідентифікатор",
        "en": "external id",
        "cz": "externí identifikátor",
        "bg": "външен идентификатор"
    },
    "storeDocId": {
        "ua": "ідентифікатор документа зберігання",
        "en": "storage document id",
        "cz": "identifikátor dokumentu o skladování",
        "bg": "ид. номер на документа за съхранение"
    },
    "salaryDocId": {
        "ua": "ідентифікатор документа зарплати",
        "en": "salary document id",
        "cz": "identifikátor dokumentu o mzdě",
        "bg": "ид. номер на документа за заплата"
    },
    "analyticsUniqueId": {
        "ua": "унікальний ідентифікатор аналітики",
        "en": "unique analytics id",
        "cz": "unikátní identifikátor analýzy",
        "bg": "уникален идентификатор на анализа"
    },
    "isRegisteredWithRst": {
        "ua": "зареєстровано в РРО",
        "en": "registered with RRO",
        "cz": "registrováno v RRO",
        "bg": "регистриран в RRO"
    },
    "cashBoxName": {
        "ua": "назва каси",
        "en": "cash register name",
        "cz": "název pokladny",
        "bg": "име на касата"
    },
    "fiscalNumber": {
        "ua": "фіскальний номер",
        "en": "fiscal number",
        "cz": "fiskální číslo",
        "bg": "фискален номер"
    },
    "rst": {
        "ua": "каса РРО",
        "en": "RRO cash register",
        "cz": "pokladna RRO",
        "bg": "каса RRO"
    },
    "clientName": {
        "ua": "ім'я клієнта",
        "en": "client's first name",
        "cz": "jméno klienta",
        "bg": "име на клиента"
    },
    "clientSurname": {
        "ua": "прізвище клієнта",
        "en": "client's surname",
        "cz": "příjmení klienta",
        "bg": "фамилия на клиента"
    },
    "employeeName": {
        "ua": "ім'я співробітника",
        "en": "employee's first name",
        "cz": "jméno zaměstnance",
        "bg": "име на служителя"
    },
    "employeeSurname": {
        "ua": "прізвище співробітника",
        "en": "employee's surname",
        "cz": "příjmení zaměstnance",
        "bg": "фамилия на служителя"
    },
    "businessSupplierName": {
        "ua": "назва станції",
        "en": "business supplier name",
        "cz": "název dodavatele podnikání",
        "bg": "име на бизнес доставчика"
    },
    "orderNum": {
        "ua": "номер замовлення",
        "en": "order number",
        "cz": "číslo objednávky",
        "bg": "номер на поръчката"
    },
    "businessItn": {
        "ua": "ІПН бізнесу",
        "en": "business IPN",
        "cz": "ITN podnikání",
        "bg": "ИТН на бизнеса"
    },
    "clientItn": {
        "ua": "ІПН клієнта",
        "en": "client IPN",
        "cz": "ITN klienta",
        "bg": "ИТН на клиента"
    },
    "responsible": {
        "ua": "відповідальний",
        "en": "responsible",
        "cz": "odpovědný",
        "bg": "отговорен"
    },
    "documentNumber": {
        "ua": "номер документа",
        "en": "document number",
        "cz": "číslo dokumentu",
        "bg": "номер на документа"
    },
    "businessName": {
        "ua": "назва бізнесу",
        "en": "business name",
        "cz": "název podnikání",
        "bg": "име на бизнеса"
    },
    "sum": {
        "ua": "сума",
        "en": "amount",
        "cz": "částka",
        "bg": "сума"
    },
    "counterpartyName": {
        "ua": "назва контрагента",
        "en": "counterparty name",
        "cz": "název kontrahenta",
        "bg": "име на контрагента"
    }
};