import { Checkbox, DatePicker, Input, Modal, Radio, Select, TreeSelect } from 'antd';
import { DateRangePicker } from 'components';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { StockProductsModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    brands: selectBrands(state),
    warehouses: state.warehouses.warehouses,
    user: state.auth
});

const mapDispatchToProps = {
    resetModal,
    fetchBrands,
    fetchWarehouses
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ProductsMovementModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month'),
            detailing: 'without',
            detailsTreeData: [],
            document: false
        };
    }

    componentDidMount() {
        this.buildStoreGroupsTree();
        this.props.fetchBrands();
        this.props.fetchWarehouses();
    }

    handleOk = async () => {
        const { resetModal } = this.props;
        const { warehouseId, groupId, brandId, productCode, fromDate, toDate, detailing, document } = this.state;

        const response = await fetchAPI(
            'GET',
            'report/excel/movement',
            {
                warehouseId,
                date: {
                    from: dayjs(fromDate).format(dateFormat),
                    to: dayjs(toDate).format(dateFormat)
                },
                brandId,
                groupId,
                productCode,
                detailing,
                document
            },
            null,
            { rawResponse: true }
        );

        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
        await resetModal();
    };

    buildStoreGroupsTree = async () => {
        const storeGroups = await fetchAPI('GET', 'store_groups');
        const treeData = [];
        for (let i = 0; i < storeGroups.length; i++) {
            const parentGroup = storeGroups[i];
            treeData.push({
                title: `${parentGroup.name} (#${parentGroup.id})`,
                name: parentGroup.singleName,
                value: parentGroup.id,
                key: parentGroup.id,
                children: [],
                multiplier: parentGroup.priceGroupMultiplier
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.name} (#${childGroup.id})`,
                    name: childGroup.singleName,
                    value: childGroup.id,
                    key: childGroup.id,
                    children: [],
                    multiplier: childGroup.priceGroupMultiplier
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.name} (#${lastNode.id})`,
                        name: lastNode.singleName,
                        value: lastNode.id,
                        key: lastNode.id,
                        children: [],
                        multiplier: lastNode.priceGroupMultiplier
                    });
                    for (let l = 0; l < lastNode.childGroups.length; l++) {
                        const elem = lastNode.childGroups[l];
                        treeData[i].children[j].children[k].children.push({
                            title: `${elem.name} (#${elem.id})`,
                            name: elem.singleName,
                            value: elem.id,
                            key: elem.id,
                            multiplier: elem.priceGroupMultiplier
                        });
                    }
                }
            }
        }
        this.setState({
            detailsTreeData: treeData
        });
    };

    render() {
        const { resetModal, warehouses, brands, visible, user } = this.props;

        const { warehouseId, groupId, brandId, productCode, fromDate, toDate, detailing, detailsTreeData, document } =
            this.state;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    maskClosable={false}
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={() => resetModal()}
                    onOk={this.handleOk}
                    title={
                        <div className={Styles.title}>
                            {}
                            <FormattedMessage id='reports_page.movement_by_product' />
                        </div>
                    }
                    visible={visible === MODALS.PRODUCTS_MOVEMENT_MODAL}
                    width='40%'
                >
                    <DateRangePicker
                        dateRange={[fromDate, toDate]}
                        onDateChange={arr => {
                            this.setState({ fromDate: arr[0] });
                            this.setState({ toDate: arr[1] });
                        }}
                        style={{
                            width: '100%',
                            marginBottom: 8
                        }}
                    />
                    <Select
                        allowClear
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={warehouseId => {
                            this.setState({ warehouseId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({ id: 'storage' })}
                        showSearch
                        value={warehouseId}
                    >
                        {warehouses.map(({ name, id }) => (
                            <Option key={id} id={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                    <TreeSelect
                        allowClear
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        filterTreeNode={(input, node) => {
                            return (
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        onSelect={groupId => {
                            this.setState({ groupId });
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.store_group'
                        })}
                        showSearch
                        style={{ marginTop: 8 }}
                        treeData={detailsTreeData}
                        value={groupId}
                    />
                    <Select
                        allowClear
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={brandId => this.setState({ brandId })}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.brand'
                        })}
                        showSearch
                        style={{ marginTop: 8 }}
                        value={brandId}
                    >
                        {brands.map(({ brandId, brandName }) => (
                            <Option key={brandId} value={brandId}>
                                {brandName}
                            </Option>
                        ))}
                    </Select>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Input
                            allowClear
                            onChange={({ target }) => this.setState({ productCode: target.value })}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.detail_code'
                            })}
                            style={{ width: '100%', marginTop: 8 }}
                            value={productCode}
                        />
                        <div style={{ marginTop: 8 }}>
                            <StockProductsModal
                                selectProduct={({ code, brandId }) => {
                                    this.setState({ productCode: code, brandId });
                                }}
                                stockMode
                                user={user}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: 14 }}>
                        <div>
                            <FormattedMessage id='date_detailing' />
                        </div>
                        <Radio.Group onChange={e => this.setState({ detailing: e.target.value })} value={detailing}>
                            <Radio value='without'>
                                <FormattedMessage id='date_without_detailing' />
                            </Radio>
                            <React.Fragment>
                                <Radio value='warehouses'>
                                    <FormattedMessage id='by_warehouses' />
                                </Radio>
                                <Radio value='cells'>
                                    <FormattedMessage id='by_cells' />
                                </Radio>
                            </React.Fragment>
                        </Radio.Group>
                    </div>
                    <div style={{ marginTop: 8 }}>
                        <Checkbox
                            checked={document}
                            onChange={() => this.setState({ document: !document })}
                            style={{ marginRight: 8 }}
                        />
                        <FormattedMessage id='reports_page.hide_CRA' />
                    </div>
                </Modal>
            </div>
        );
    }
}
