import { InputNumber, Tooltip } from 'antd';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectCashCollected,
    selectCashOrderSum,
    selectMode,
    setCashOrderSum,
    setCashRemaining
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { numeralFormatter2, numeralParser } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    cashOrderSum: selectCashOrderSum(state),
    cashCollected: selectCashCollected(state),
    mode: selectMode(state),
    user: state.auth
});

const mapDispatchToProps = {
    setCashOrderSum,
    setCashRemaining
};

/**
 * Has built in validators for sum
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SumInput extends React.Component {
    render() {
        const {
            cashOrderSum,
            setCashOrderSum,
            setCashRemaining,
            cashCollected,
            mode,
            user,
            editable,

            intl: { formatMessage }
        } = this.props;

        const disabled = Boolean(mode !== MODES.ADD); // && isForbidden(user, permissions.ACCESS_UPDATE_DATE_SUM_CASH_ORDER);

        return (
            <div>
                <Tooltip
                    placement='bottom'
                    title={<FormattedMessage id='refactored_cash_order_modal.hint_sum' />}
                    zIndex={2001}
                >
                    <InputNumber
                        addonAfter={<span style={{ color: 'var(--primary)' }}><FormattedMessage id="sum" /></span>}
                        className={Styles.inputNumber}
                        data-qa='input_sum'
                        decimalSeparator=','
                        defaultValue={0}
                        disabled={!editable && disabled}
                        formatter={numeralFormatter2}
                        onChange={sum => {
                            setCashOrderSum(sum);
                            setCashRemaining(cashCollected > sum ? cashCollected - sum : 0);
                        }}
                        parser={numeralParser}
                        precision={2}
                        value={cashOrderSum}
                    />
                </Tooltip>
            </div>
        );
    }
}
