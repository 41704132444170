import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    FETCH_CASHBOXES,
    fetchCashboxesSuccess,
    selectSelectedCashboxId,
    setFetchingCashboxes,
    setSelectedCashboxId
} from './duck';

export function* fetchCashboxesSaga() {
    while (true) {
        try {
            yield take(FETCH_CASHBOXES);

            yield put(setFetchingCashboxes(true));
            const response = yield call(fetchAPI, 'GET', '/cash_boxes', null, null, { handleErrorInternally: true });

            const firstCashboxId = _.get(response, '[0].id');

            const selectedCashBoxId = yield select(selectSelectedCashboxId);
            if (!selectedCashBoxId) {
                yield put(setSelectedCashboxId(firstCashboxId)); // Set cashbox id if it is not already selected
            }

            yield put(
                fetchCashboxesSuccess({
                    cashboxes: response
                })
            );

            const rstData = response
                .filter(({ id, fiscalNumber, licenseKey, active }) => id && active && (fiscalNumber || licenseKey))
                .map(({ id, fiscalNumber, licenseKey }) => ({ id, fiscalNumber, licenseKey }));
            if (rstData.length) {
                const statuses = yield call(fetchAPI, 'POST', '/cash_boxes/get_external_statuses', null, rstData, {
                    handleErrorInternally: true
                });

                yield put(
                    fetchCashboxesSuccess({
                        cashboxes: response.map(el => (statuses[el.id] ? { ...el, ...statuses[el.id] } : el))
                    })
                );
            }
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingCashboxes(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchCashboxesSaga)]);
}
