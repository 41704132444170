/* eslint-disable max-classes-per-file */
import {
    IssuesCloseOutlined,
    PlusSquareOutlined,
    QuestionCircleOutlined,
    UndoOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { Button, Drawer, Image, Menu, Modal, Table, Tooltip } from 'antd';
import { Catcher, Numeral } from 'commons';
import { HamburgerMenu } from 'components';
import { setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import { AddLaborOrDetailToOrderModal, AddRegulationsOrderModal, AddVehicleRegulationsModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PencilIcon } from 'theme';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class OrderRegulationsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            selectedRows: [],
            allVehicleRegulations: [],
            data: {
                currentVehicleInfo: {}
            },
            orderData: {
                currentVehicleInfo: {}
            },
            allVehicleOrderRegulations: [],
            allLinks: [],
            helperDrawerOpen: false
        };

        this.columns = (withTitle = true, titleForEachTable) => [
            {
                title: () => {
                    const {
                        intl: { formatMessage }
                    } = this.props;

                    const { selectedRows, selectedRowKeys, allVehicleOrderRegulations } = this.state;

                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.Item key='copy' disabled={!selectedRows.length}>
                                <div
                                    onClick={async () => {
                                        const { vehicleId, setModal, orderId, reloadOrderForm } = this.props;

                                        const defaultUse = _.get(
                                            this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                                            '[0].id'
                                        );

                                        const labors = selectedRows.map(
                                            ({ regulationName, laborId, count, servicePrice, laborUnitId }) => {
                                                return {
                                                    serviceId: laborId,
                                                    serviceName: regulationName,
                                                    count: count || 1,
                                                    servicePrice: Number(servicePrice),
                                                    laborUnitId: Number(laborUnitId) || defaultUse
                                                };
                                            }
                                        );

                                        await fetchAPI(
                                            'PUT',
                                            `orders/${orderId}`,
                                            null,
                                            {
                                                services: labors,
                                                details: [],
                                                insertMode: true
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        await this.updateDataSource();
                                    }}
                                >
                                    <div>
                                        <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                        <FormattedMessage id='order_form_table.add_to_order_tooltip' />
                                    </div>
                                </div>
                            </Menu.Item>

                            <Menu.Item key='yellow_red'>
                                <div
                                    onClick={() => {
                                        const data = allVehicleOrderRegulations
                                            .map(({ regulations }) => regulations)
                                            .flat()
                                            .filter(({ color }) => color === 'RED' || color === 'YELLOW');
                                        this.setState({
                                            selectedRows: data,
                                            selectedRowKeys: data.map(({ id }) => id)
                                        });
                                    }}
                                >
                                    <div>
                                        <WarningOutlined className={Styles.actionMenuIcon} />
                                        <FormattedMessage id='regulations.choose_yellow_n_red' />
                                    </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key='red'>
                                <div
                                    onClick={() => {
                                        const data = allVehicleOrderRegulations
                                            .map(({ regulations }) => regulations)
                                            .flat()
                                            .filter(({ color }) => color === 'RED');
                                        this.setState({
                                            selectedRows: data,
                                            selectedRowKeys: data.map(({ id }) => id)
                                        });
                                    }}
                                >
                                    <div>
                                        <IssuesCloseOutlined className={Styles.actionMenuIcon} />
                                        <FormattedMessage id='regulations.choose_red' />
                                    </div>
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        withTitle && (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <HamburgerMenu actionsMenu={actionsMenu} />
                                </div>
                            </div>
                        )
                    );
                },
                key: 'edit',
                width: '5%',
                align: 'center',
                render: (data, row) => {
                    const actionsMenu = () => (
                        <Menu>
                            <Menu.Item key='recommendationActionMenu.copy'>
                                <div
                                    onClick={async () => {
                                        const { setModal, vehicleId, orderId } = this.props;
                                        const defaultUse = _.get(
                                            this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                                            '[0].id'
                                        );
                                        const labors = [
                                            {
                                                serviceId: row.laborId,
                                                serviceName: row.regulationName,
                                                count: Number(row.count) || 1,
                                                servicePrice: Number(row.servicePrice),
                                                laborUnitId: Number(row.laborUnitId) || defaultUse
                                            }
                                        ];

                                        await fetchAPI(
                                            'PUT',
                                            `orders/${orderId}`,
                                            null,
                                            {
                                                services: labors,
                                                details: [],
                                                insertMode: true
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        await this.updateDataSource();
                                    }}
                                >
                                    <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                    <FormattedMessage id='order_form_table.add_to_order_tooltip' />
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly'
                                }}
                            >
                                <HamburgerMenu actionsMenu={actionsMenu} />
                                <Button
                                    icon={<PencilIcon />}
                                    onClick={() => {
                                        this.editRegulations(row);
                                    }}
                                />
                            </div>
                        </div>
                    );
                }
            },
            {
                title: titleForEachTable,
                key: 'name',
                dataIndex: 'regulationName',
                width: '25%',
                render: (data, row) => {
                    return (
                        <div
                            className={
                                row.color == 'GREEN'
                                    ? Styles.underlineStatusColorGreen
                                    : row.color == 'YELLOW'
                                    ? Styles.underlineStatusColorYellow
                                    : row.color == 'RED'
                                    ? Styles.underlineStatusColorRed
                                    : undefined
                            }
                        >
                            {data}
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.regulation' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.months' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'typeRegulationMonth',
                dataIndex: 'months',
                align: 'center',
                width: '10%'
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.regulation' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'typeRegulationKm',
                dataIndex: 'km',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.last_change' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'lastChangeKm',
                dataIndex: 'lastKm',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.last_change' />
                            </div>
                            <div>
                                <FormattedMessage id='date' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'lastChangeDate',
                dataIndex: 'lastDate',
                align: 'center',
                width: '10%',
                render: data => {
                    return data ? dayjs(data).format('DD.MM.YYYY') : undefined;
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.next_change' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'nextChangeKm',
                dataIndex: 'nextChangeKm',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.next_change' />
                            </div>
                            <div>
                                <FormattedMessage id='date' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'nextChangeDate',
                dataIndex: 'nextChangeDate',
                align: 'center',
                width: '10%',
                render: data => {
                    return data ? dayjs(data).format('DD.MM.YYYY') : undefined;
                }
            }
        ];
    }

    componentDidMount() {
        this.fetchVehicleOrderRegulations();
        this.fetchVehicleRegulations();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.allVehicleOrderRegulations !== prevState.allVehicleOrderRegulations) {
            this.refrechVehicleOrderRegulations();
        }
    }

    refrechVehicleOrderRegulations = async () => {
        const { orderId, vehicleId } = this.props;
        if (get(this.state.allVehicleOrderRegulations, 'length')) {
            fetchAPI('PUT', 'refresh/order/vehicle/regulations', undefined, {
                orderId,
                vehicleId
            });
        }
    };

    fetchVehicleOrderRegulations = async () => {
        if (this.props.vehicleId) {
            const allVehicleOrderRegulations = await fetchAPI(
                'GET',
                'orders/regulations',
                { orderId: this.props.orderId, vehicleId: this.props.vehicleId },
                undefined
            );

            this.setState({
                allVehicleOrderRegulations: allVehicleOrderRegulations.result || [],
                orderData: {
                    currentVehicleInfo: allVehicleOrderRegulations.vehicleOptions
                }
            });
        }
    };

    fetchVehicleRegulations = async () => {
        if (this.props.vehicleId) {
            const allVehicleRegulations = await fetchAPI(
                'GET',
                'vehicle/regulations',
                { vehicleId: this.props.vehicleId, onlyActive: true },
                undefined
            );
            if (allVehicleRegulations.result) {
                allVehicleRegulations.result.forEach(el => {
                    el.regulations.forEach(reg => {
                        reg.groupId = el.groupId;
                    });
                });
            }
            this.setState({
                allVehicleRegulations: allVehicleRegulations.result || [],
                data: {
                    currentVehicleInfo: allVehicleRegulations.vehicleOptions
                }
            });
        }
    };

    editRegulations = row => {
        this.setState({
            visibleAddVehicleRegulationsModal: true,
            regulationModalRow: row
        });
    };

    editRegulationRow = async (id, regulationName, regulationKm, regulationMonths, lastChangeKm, lastChangeMonth) => {
        await fetchAPI('PUT', 'orders/regulations', undefined, {
            id,
            regulationName,
            regulationKm,
            regulationMonths,
            lastKm: lastChangeKm,
            lastDate: lastChangeMonth
        });
        await setTimeout(() => this.fetchVehicleOrderRegulations(), 100);
    };

    updateAllVehicleInfo = async () => {
        await fetchAPI('PUT', `clients/vehicles/${this.props.vehicleId}`, null, undefined);
        setTimeout(() => this.fetchVehicleOrderRegulations(), 100);
    };

    addRegulationsForOrderVehicle = async () => {
        const { orderId, vehicleId } = this.props;
        await fetchAPI('POST', 'businesses/regulations/copy', undefined, {
            vehicleId,
            copyOption: 'vehicle'
        });
        await setTimeout(() => this.fetchVehicleRegulations(), 100);

        setTimeout(
            () =>
                this.setState({
                    visibleAddRegulationsOrderModal: true
                }),
            1000
        );
    };

    updateDataSource = () => {
        const callback = () => {
            this.setState({
                selectedRowKeys: [],
                selectedRows: []
            });
        };
        setTimeout(() => this.props.reloadOrderForm(callback), 500);
    };

    render() {
        const { orderId, vehicleId, labors } = this.props;
        const {
            selectedRows,
            selectedRowKeys,
            allVehicleRegulations,
            data,
            orderData,
            visibleAddRegulationsOrderModal,
            visibleAddVehicleRegulationsModal,
            regulationModalRow,
            allVehicleOrderRegulations,
            allLinks,
            helperDrawerOpen
        } = this.state;

        const rowSelection = (hideSelectAll = true) => ({
            selectedRowKeys,
            preserveSelectedRowKeys: true,
            hideSelectAll,
            onSelectAll: (selected, selectedRows, changeRows) => {
                if (selected) {
                    const allData = allVehicleOrderRegulations.map(({ regulations }) => regulations).flat();

                    const selectedRowKeys = allData.map(({ id }) => id);

                    this.setState({
                        selectedRowKeys,
                        selectedRows: allData
                    });

                    return;
                }

                this.setState({
                    selectedRowKeys: [],
                    selectedRows: []
                });
            },
            onSelect: (record, selected) => {
                if (selected) {
                    this.setState({
                        selectedRowKeys: [...selectedRows, record].map(({ id }) => id),
                        selectedRows: [...selectedRows, record]
                    });

                    return;
                }

                this.setState({
                    selectedRowKeys: selectedRowKeys.filter(id => id !== record.id),
                    selectedRows: selectedRows.filter(({ id }) => id !== record.id)
                });
            }
        });

        return (
            <Catcher>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            const links = await fetchAPI(
                                'GET',
                                '/helps',
                                {
                                    helpId: 'order_mrd_add_regulations_data'
                                },
                                undefined,
                                {
                                    handleErrorInternally: true
                                }
                            );
                            this.setState({
                                allLinks: links,
                                helperDrawerOpen: true
                            });
                        }}
                        style={{
                            fontSize: 22,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                </div>
                <div className={Styles.btnStyle}>
                    <div>
                        <Tooltip title={<FormattedMessage id='regulations.update_tooltip' />}>
                            <Button
                                disabled={!vehicleId}
                                icon={<UndoOutlined />}
                                onClick={async () => {
                                    await fetchAPI(
                                        'POST',
                                        '/mileage/history',
                                        null,
                                        {
                                            vehicleId,
                                            mileage: orderData.currentVehicleInfo.maxMileage || 1,
                                            date:
                                                dayjs(orderData.currentVehicleInfo.maxDate).format('YYYY-MM-DD') ||
                                                undefined,
                                            custom: true
                                        },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await this.updateAllVehicleInfo();
                                }}
                            />
                        </Tooltip>
                        <span
                            style={{
                                marginLeft: 8
                            }}
                        >
                            <FormattedMessage id='regulations.current_odometr_and_date' />
                            {': '}

                            <span
                                style={{
                                    fontWeight: 700
                                }}
                            >
                                {orderData.currentVehicleInfo ? (
                                    <Numeral mask='0,0'>{Number(orderData.currentVehicleInfo.maxMileage)}</Numeral>
                                ) : (
                                    0
                                )}{' '}
                                <FormattedMessage id='online_service_book_page.km' />
                                {', '}
                                {orderData.currentVehicleInfo
                                    ? dayjs(orderData.currentVehicleInfo.maxDate).format('DD.MM.YYYY')
                                    : '-'}
                            </span>
                        </span>
                    </div>
                    <Button
                        disabled={!_.get(allVehicleOrderRegulations, 'length')}
                        onClick={async () => {
                            await fetchAPI('PUT', 'refresh/order/vehicle/regulations', undefined, {
                                orderId,
                                vehicleId
                            });
                            await this.fetchVehicleRegulations();
                            setTimeout(() => this.fetchVehicleOrderRegulations(), 100);
                        }}
                    >
                        <FormattedMessage id='regulations.update_regulations' />
                    </Button>
                    <Button
                        disabled={!vehicleId}
                        onClick={() => {
                            if (!_.get(allVehicleRegulations, 'length')) {
                                Modal.confirm({
                                    title: this.props.intl.formatMessage({
                                        id: 'regulations.modal_confirm_for_update_rows_for_order'
                                    }),
                                    onOk: () => {
                                        this.addRegulationsForOrderVehicle();
                                    },
                                    okType: 'primary',
                                    onCancel() {}
                                });
                            } else {
                                this.setState({
                                    visibleAddRegulationsOrderModal: true
                                });
                            }
                        }}
                    >
                        <FormattedMessage id='regulations.add' />
                    </Button>
                </div>
                <div>
                    {allVehicleOrderRegulations.map(({ groupId, groupName, regulations }, index) => (
                        <Table
                            key={groupId}
                            bordered
                            columns={this.columns(!index, groupName)}
                            dataSource={regulations}
                            pagination={false}
                            rowKey='id'
                            rowSelection={rowSelection(!!index)}
                            size='small'
                        />
                    ))}
                </div>
                <AddRegulationsOrderModal
                    allVehicleRegulations={allVehicleRegulations}
                    data={data}
                    fetchVehicleOrderRegulations={this.fetchVehicleOrderRegulations}
                    hideModal={() => this.setState({ visibleAddRegulationsOrderModal: false })}
                    orderId={orderId}
                    vehicleId={vehicleId}
                    visible={visibleAddRegulationsOrderModal}
                />
                <AddVehicleRegulationsModal
                    editRegulationRow={this.editRegulationRow}
                    fetchVehicleRegulations={this.fetchVehicleOrderRegulations}
                    hideModal={() => {
                        this.setState({
                            visibleAddVehicleRegulationsModal: false
                        });
                    }}
                    regulationModalRow={regulationModalRow}
                    visible={visibleAddVehicleRegulationsModal}
                />
                <AddLaborOrDetailToOrderModal />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}

export default OrderRegulationsTable;
