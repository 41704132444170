// columns.js
import {
    AppstoreAddOutlined,
    BookOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CopyOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    LockOutlined,
    MenuOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    ToolFilled,
    ToolOutlined,
    UnlockOutlined
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { AvailabilityIndicator, HamburgerMenu, ReserveButton } from 'components';
import book from 'routes/book';
import { isDisabledByDetailStatuses } from 'utils';
import { isGrantAccessed } from 'utils/grants';

// Import your menus from separate files (you must create them as shown previously)
import { getMainActionsMenu } from './actionsMenu';
import { getRowActionsMenu } from './rowActionsMenu';

import Styles from './styles.m.css';

/**
 * Returns the columns configuration for the DetailsTable component.
 *
 * @param {Object} params
 * @param {Object} params.props - Props from the DetailsTable component.
 * @param {Object} params.state - State from the DetailsTable component.
 * @param {Function} params.updateDataSource - Function to refresh dataSource.
 * @param {Function} params.updateDetail - Function to update a single detail.
 * @param {Function} params.downloadPhoto - Function to handle file uploads for photos.
 * @returns {Array} columns - Array of column definitions.
 */
export function getColumns({ props, state, updateDataSource, updateDetail, downloadPhoto }) {
    const {
        user,
        intl,
        orderId,
        disabled,
        isRTN,
        isRetailOrder,
        isMobile,
        warehouses,
        showPurchasePrices,
        purchasePrices,
        defaultResponsibleId,
        fetchedOrder,
        discount,
        allDetails,
        clientVehicleVin
    } = props;

    const { selectedRowKeys, selectedRows, statuses, dataSource } = state;

    // Build the main menu (bulk actions) and row menu (per-row actions)
    const mainMenu = getMainActionsMenu({ props, state, updateDataSource });

    // Header-level actions column
    const actionsColumn = {
        title: () => <HamburgerMenu actionsMenu={mainMenu} disabled={disabled} />,
        key: 'actions',
        align: 'center',
        render: row => {
            const rowMenu = getRowActionsMenu({ props, state, updateDataSource, updateDetail, row, downloadPhoto });

            return (
                <HamburgerMenu actionsMenu={rowMenu} disabled={disabled}>
                    <Button disabled={disabled} icon={<MenuOutlined />} />
                </HamburgerMenu>
            );
        }
    };

    // Button group column (add details, catalog, barcode, etc.) from the original code
    const buttonGroupColumn = {
        title: () => {
            const { isRTN, openRTNModal, disabled, isGrantAccessed, user, clientVehicleVin, setModal } = props;

            // If isRTN show RTN-specific header actions, else show normal header actions
            return isRTN ? (
                <div className={Styles.headerActions}>
                    <Button
                        disabled={disabled}
                        icon={<PlusOutlined />}
                        onClick={() => {
                            if (typeof openRTNModal === 'function') openRTNModal();
                        }}
                    />
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={Styles.headerActions}>
                        <Tooltip title={<FormattedMessage id='add' />}>
                            <Button
                                disabled={disabled}
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    // logic to open detail modal in create mode
                                    // This must be handled in the main component
                                    // For example: set state in main component to open modal
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id='order_form_table.catalog' />}>
                            <Button
                                disabled={disabled}
                                icon={<AppstoreAddOutlined />}
                                onClick={() => {
                                    // open catalog modal logic
                                }}
                            />
                        </Tooltip>
                        <Button
                            disabled={!clientVehicleVin || disabled}
                            icon={<QuestionCircleOutlined />} // just an example, replace with VinIcon from your code
                            onClick={() => {
                                // logic for VIN modal
                            }}
                        />
                        {/* Add Barcode and favorite details logic similarly */}
                    </div>
                </div>
            );
        },
        align: 'center',
        key: 'buttonGroup',
        render: row => {
            const { disabled, isRTN, user } = props;

            // Row-level edit and catalog actions
            return (
                <div className={Styles.rowActions}>
                    <Tooltip title={<FormattedMessage id='edit' />}>
                        <Button
                            disabled={isDisabledByDetailStatuses('edit', row, disabled || Number(row.reservedCount))}
                            icon={<EditOutlined />}
                            onClick={() => {
                                // edit detail logic
                                // open detail modal in edit mode
                            }}
                        />
                    </Tooltip>
                    {!isRTN && (
                        <Tooltip title={<FormattedMessage id='order_form_table.catalog' />}>
                            <Button
                                disabled={isDisabledByDetailStatuses(
                                    'edit_catalogue',
                                    row,
                                    disabled || Number(row.reservedCount)
                                )}
                                icon={<BookOutlined />}
                                onClick={() => {
                                    // open catalog modal for this detail
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            );
        }
    };

    // Detail code column
    const codeColumn = {
        title: () => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormattedMessage id='order_form_table.detail_code' />
                {/* Additional header actions if needed */}
            </div>
        ),
        key: 'code',
        dataIndex: 'detailCode',
        render: (data, row) => {
            const productLink = row.productId ? (
                isGrantAccessed(user, 'DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD') ? (
                    <Link to={`${book.product}/${row.productId}`}>{row.detailCode}</Link>
                ) : (
                    row.detailCode
                )
            ) : (
                row.detailCode || <FormattedMessage id='long_dash' />
            );

            return (
                <div>
                    <div style={{ fontWeight: 700, textDecoration: row.detailCode && 'underline' }}>
                        {productLink}
                        {row.detailCode && (
                            <Tooltip title={<FormattedMessage id='vehicle_page.hint_copy_to_clipboard' />}>
                                <CopyOutlined
                                    onClick={() => {
                                        navigator.clipboard.writeText(row.detailCode);
                                        // show success notification
                                    }}
                                    style={{ marginLeft: 2, cursor: 'pointer' }}
                                />
                            </Tooltip>
                        )}
                    </div>
                    <div style={{ fontSize: 12, maxWidth: 360 }}>{row.detailName}</div>
                </div>
            );
        }
    };

    // OE code column (similar logic to codeColumn)
    const oeCodeColumn = {
        title: () => <FormattedMessage id='order_form_table.og_detail_code' />,
        key: 'oeCode',
        dataIndex: 'oeCode',
        render: (data, row) => {
            const oeDisplay = row.oeCode || <FormattedMessage id='long_dash' />;

            return (
                <div>
                    <div style={{ fontWeight: 700, textDecoration: row.oeCode && 'underline' }}>
                        {oeDisplay}
                        {row.oeCode && (
                            <Tooltip title={<FormattedMessage id='vehicle_page.hint_copy_to_clipboard' />}>
                                <CopyOutlined
                                    onClick={() => {
                                        navigator.clipboard.writeText(row.oeCode);
                                        // success notification
                                    }}
                                    style={{ marginLeft: 2, cursor: 'pointer' }}
                                />
                            </Tooltip>
                        )}
                    </div>
                </div>
            );
        }
    };

    // Brand column
    const brandColumn = {
        title: <FormattedMessage id='order_form_table.brand' />,
        key: 'brand',
        dataIndex: 'brandName',
        render: (data, row) => {
            return data ? (
                <Tooltip title={<FormattedMessage id='details_table.catalog_modal_title' />}>
                    <div
                        onClick={() => {
                            if (!row.reservedCount && !disabled && row.agreement !== 'AGREED') {
                                // open catalog modal for this row
                            }
                        }}
                        style={{
                            cursor: !row.reservedCount ? 'pointer' : 'initial',
                            textDecoration: data && 'underline'
                        }}
                    >
                        {data}
                    </div>
                </Tooltip>
            ) : (
                <div
                    onClick={() => {
                        if (!row.reservedCount && !disabled && row.agreement !== 'AGREED') {
                            // open catalog modal
                        }
                    }}
                    style={{
                        cursor: !row.reservedCount ? 'pointer' : 'initial'
                    }}
                >
                    <FormattedMessage id='long_dash' />
                </div>
            );
        }
    };

    // Supplier/Warehouse column (supplierName)
    const supplierNameColumn = {
        title: () => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormattedMessage id='order_form_table.store_supplier_short' />
                {/* Additional header actions if needed */}
            </div>
        ),
        key: 'supplierName',
        width: 70,
        render: row => {
            // logic for supplier and warehouse cell
            // this is simplified
            const warehouseText =
                row.supplierId === 0 ? (
                    row.cellAddress ? (
                        row.cellAddress
                    ) : (
                        row.warehouseName || row.supplierName
                    )
                ) : (
                    <FormattedMessage id='long_dash' />
                );

            const supplierText = row.supplierId ? row.supplierName : <FormattedMessage id='long_dash' />;

            return (
                <div>
                    <Tooltip title={<FormattedMessage id='details_table.stock_availability' />}>
                        <span
                            onClick={() => {
                                if (!Number(row.reservedCount) && row.productId && !disabled) {
                                    // open warehouse modal
                                }
                            }}
                            style={{
                                cursor: !Number(row.reservedCount) && row.productId ? 'pointer' : 'initial',
                                textDecoration: row.supplierId === 0 && 'underline'
                            }}
                        >
                            {warehouseText}
                        </span>
                    </Tooltip>
                    {' / '}
                    <Tooltip title={<FormattedMessage id='details_table.suppliers_availability' />}>
                        <span
                            onClick={() => {
                                if (!Number(row.reservedCount) && !disabled) {
                                    // open supplier modal
                                }
                            }}
                            style={{
                                cursor: !Number(row.reservedCount) ? 'pointer' : 'initial',
                                textDecoration: row.supplierId !== 0 && row.supplierName && 'underline'
                            }}
                        >
                            {supplierText}
                        </span>
                    </Tooltip>
                </div>
            );
        }
    };

    // Responsible column
    const responsibleColumn = {
        title: <FormattedMessage id='order_form_table.appurtenancies_responsible_short' />,
        key: 'responsible',
        align: 'center',
        render: ({ responsibleId, id, storeGroupId }) => {
            // OnChange responsible logic
            return (
                <Tooltip title={<FormattedMessage id='order_form_table.appurtenancies_responsible' />}>
                    <Button icon={responsibleId ? <ToolFilled /> : <ToolOutlined />} type='text' />
                </Tooltip>
            );
        }
    };

    // AvailabilityIndicator column (if not RTN)
    const AIColumn = !isRTN
        ? {
              title: <FormattedMessage id='order_form_table.AI' />,
              key: 'AI',
              align: 'center',
              render: row => <AvailabilityIndicator indexArray={row.store} />
          }
        : null;

    // Purchase price column (conditionally show if purchasePrices = true)
    const purchasePriceColumn = {
        title: () => (
            <div className={Styles.numberColumn}>
                <Button
                    icon={purchasePrices ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    onClick={showPurchasePrices}
                    type='text'
                />
                <FormattedMessage id='order_form_table.purchasePrice' />
            </div>
        ),
        className: Styles.numberColumn,
        key: 'purchasePrice',
        dataIndex: 'purchasePrice',
        render: (data, row) => {
            if (!purchasePrices) return <FormattedMessage id='long_dash' />;

            const strVal = Number(data).toFixed(2);

            // Add tooltip logic for marge, markup, discount if needed
            return (
                <span style={{ whiteSpace: 'normal', color: row.markupCheckFailed ? 'red' : null }}>
                    {data ? strVal.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : <FormattedMessage id='long_dash' />}
                </span>
            );
        }
    };

    // Price column
    const priceColumn = {
        title: (
            <div className={Styles.numberColumn}>
                <FormattedMessage id='order_form_table.price' />
            </div>
        ),
        className: Styles.numberColumn,
        key: 'price',
        dataIndex: 'price',
        render: (data, row) => {
            const strVal = Number(data).toFixed(2);

            return (
                <span style={{ whiteSpace: 'nowrap', color: row.markupCheckFailed ? 'red' : null }}>
                    {data ? strVal.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : <FormattedMessage id='long_dash' />}
                </span>
            );
        }
    };

    // Count column
    const countColumn = {
        title: (
            <div className={Styles.numberColumn}>
                <FormattedMessage id='order_form_table.count' />
            </div>
        ),
        className: Styles.numberColumn,
        key: 'count',
        dataIndex: 'count',
        render: data => {
            return (
                <Tooltip title={<div>{Number(data).toFixed(4)}</div>}>
                    <span>{data ? `${data.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}</span>
                </Tooltip>
            );
        }
    };

    // Units column
    const unitsColumn = {
        title: (
            <div className={Styles.numberColumn}>
                <FormattedMessage id='services_table.units' />
            </div>
        ),
        className: Styles.numberColumn,
        key: 'measureUnit',
        width: 60,
        dataIndex: 'measureUnit',
        render: data => <span>{data || undefined}</span>
    };

    // Reserve column (if not RTN)
    const reserveColumn = !isRTN
        ? {
              title: () => (
                  <div>
                      <div>
                          <FormattedMessage id='storage.RESERVE' />
                      </div>
                      <div className={Styles.headerActions}>
                          <LockOutlined
                              className={Styles.icon}
                              onClick={async () => {
                                  // reserve logic
                                  updateDataSource();
                              }}
                              title={intl.formatMessage({ id: 'details_table.reserve' })}
                          />
                          <UnlockOutlined
                              className={Styles.icon}
                              onClick={async () => {
                                  // unreserve logic
                                  updateDataSource();
                              }}
                              title={intl.formatMessage({ id: 'details_table.unreserve' })}
                          />
                      </div>
                  </div>
              ),
              className: Styles.numberColumn,
              key: 'reserve',
              align: 'center',
              render: elem => {
                  const disabledCondition =
                      disabled ||
                      !elem.id ||
                      (elem.stage === 'INSTALLED' && elem.agreement !== 'REJECTED') ||
                      !isGrantAccessed(user, 'OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS');

                  return (
                      <ReserveButton
                          detail={elem}
                          disabled={disabledCondition || elem.detailCode === null}
                          mainWarehouseId={null} // set if needed
                          onClick={() => updateDataSource()}
                          updateDetail={updateDataSource}
                      />
                  );
              }
          }
        : null;

    // Discount column
    const discountColumn = {
        title: (
            <div className={Styles.numberColumn}>
                <Tooltip placement='topRight' title={intl.formatMessage({ id: 'client_container.discounts.info' })}>
                    <QuestionCircleOutlined style={{ marginRight: 5, color: 'var(--primary)' }} />
                </Tooltip>
                <FormattedMessage id='order_form_table.discount' />
            </div>
        ),
        className: Styles.numberColumn,
        key: 'partRowDiscount',
        dataIndex: 'partRowDiscount',
        render: (data, row) => <span>{`${row.partRowDiscount || 0}%`}</span>
    };

    // Sum column
    const sumColumn = {
        title: (
            <div className={Styles.numberColumn}>
                <FormattedMessage id='order_form_table.sum' />
                <p style={{ color: 'var(--text2)', fontSize: 12, fontWeight: 400 }}>
                    <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                </p>
            </div>
        ),
        className: Styles.numberColumn,
        key: 'sum',
        dataIndex: 'sum',
        render: (data, row) => {
            return (
                <span style={{ whiteSpace: 'nowrap', color: row.markupCheckFailed ? 'red' : null }}>
                    {data ? (
                        Number(data)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    )}
                </span>
            );
        }
    };

    // Agreement column (PD)
    const agreementColumn = !isRTN && {
        title: () => {
            // Bulk agreement change logic if needed
            return (
                <div>
                    <FormattedMessage id='order_form_table.PD' />
                    {/* Add dropdown or actions if needed */}
                </div>
            );
        },
        key: 'agreement',
        align: 'center',
        dataIndex: 'agreement',
        render: (data, row) => {
            // Show icon based on agreement: undefined, agreed, rejected
            let icon = <QuestionCircleOutlined />;
            let color = null;
            const value = data ? data.toLowerCase() : 'undefined';
            if (value === 'rejected') {
                color = 'rgb(255, 126, 126)';
                icon = <CloseCircleOutlined />;
            } else if (value === 'agreed') {
                color = 'var(--green)';
                icon = <CheckCircleOutlined />;
            }

            return <span style={{ fontSize: 24, color }}>{icon}</span>;
        }
    };

    // Status column (if not RTN)
    const statusColumn = !isRTN && {
        title: <FormattedMessage id='order_form_table.status' />,
        key: 'status',
        dataIndex: 'status',
        render: (data, row) => {
            const currentStatus = statuses ? statuses.find(({ status }) => status === data) : null;
            const displayName =
                currentStatus && currentStatus.customStatusName
                    ? currentStatus.customStatusName
                    : intl.formatMessage({ id: `status.${data}` });

            return (
                <Tooltip title={<FormattedMessage id={`status.${data}.title`} />}>
                    <div
                        style={{
                            border: `2px solid ${row.statusColor}`,
                            padding: '6px 2px',
                            textAlign: 'center',
                            fontWeight: 500,
                            borderRadius: 6
                        }}
                    >
                        {displayName}
                    </div>
                </Tooltip>
            );
        }
    };

    // Combine all columns
    const columns = [
        actionsColumn,
        buttonGroupColumn,
        codeColumn,
        oeCodeColumn,
        brandColumn,
        supplierNameColumn,
        responsibleColumn
    ];

    if (!isRTN) {
        columns.push(AIColumn);
    }

    columns.push(purchasePriceColumn, priceColumn, countColumn, unitsColumn);

    if (!isRTN) {
        columns.push(reserveColumn);
    }

    columns.push(discountColumn, sumColumn);

    if (!isRTN) {
        columns.push(agreementColumn, statusColumn);
    }

    return columns.filter(Boolean); // filter out any null columns (e.g., AIColumn if not needed)
}
