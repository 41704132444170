import { ControlOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Input, Modal, Popover, Radio, Select, Table, Upload } from 'antd';
import { getData as getRequisites } from 'core/requisiteSettings/saga';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { RangePicker } = DatePicker;
const ALL = 'ALL';
const FROM_DOCS = 'FROM_DOCS';
const NONE = 'NONE';
const CARBOOK = 'CARBOOK';
const EXTERNAL = 'EXTERNAL';
const MANUAL = 'CUSTOM';
const SUBJECTS = 'SUBJECT';
const DONE = 'DONE';
const OTHER = 'OTHER';
const FROM_PREVIOUS = 'FROM_PREVIOUS';
const FROM_DATE = 'FROM_DATE';
const FILE = 'FILE';
const FTP = 'FTP';
const XML = 'xml';
const XLSX = 'xlsx';
const CSV = 'csv';
const CALL = 'CALL';
const REDUNDANT = 'redundant';
const APPROVED = 'approved';
const CANCELED = 'canceled';
const CREATED = 'created';
const IN_PROGRESS = 'in_progress';
const RESERVED = 'reserved';
const REQUIRED = 'required';
const SUCCESS = 'success';
const INVITE = 'invite';
const STATUSES = [CALL, REDUNDANT, APPROVED, CANCELED, CREATED, IN_PROGRESS, RESERVED, REQUIRED, SUCCESS, INVITE];

const SYNC_CATALOGUES = [
    {
        name: 'clients',
        checked: true,
        sync: ALL,
        priority: CARBOOK,
        table: 'CLIENTS'
    },
    {
        name: 'clients_vehicles',
        checked: true,
        sync: ALL,
        priority: CARBOOK,
        table: 'CLIENTS_VEHICLES'
    },
    {
        name: 'bussiness_suppliers',
        checked: true,
        sync: ALL,
        priority: CARBOOK,
        table: 'BUSINESS_SUPPLIERS'
    },
    {
        name: 'employees',
        checked: true,
        sync: ALL,
        priority: CARBOOK,
        table: 'EMPLOYEES'
    },
    {
        name: 'store_products',
        checked: true,
        sync: ALL,
        priority: CARBOOK,
        table: 'STORE_PRODUCTS'
    },
    {
        name: 'store_groups',
        checked: true,
        sync: ALL,
        priority: CARBOOK,
        table: 'STORE_GROUPS'
    },
    {
        name: 'labors',
        checked: true,
        sync: ALL,
        priority: CARBOOK,
        table: 'LABORS'
    },
    {
        sync: NONE,
        priority: NONE,
        table: NONE
    }
];

const SYNC_ORDERS = [
    {
        name: 'orders',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'ORDERS'
    },
    {
        name: 'orders_details',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'ORDERS_DETAILS'
    },
    {
        name: 'orders_labors',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'ORDERS_LABORS'
    },
    {
        name: 'stations',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'STATIONS'
    },
    {
        sync: NONE,
        priority: NONE,
        table: NONE
    }
];

const SYNC_STOCK = [
    {
        name: 'warehouses',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'WAREHOUSES'
    },
    {
        name: 'store_docs',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'STORE_DOCS'
    },
    {
        name: 'store_doc_products',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'STORE_DOC_PRODUCTS'
    },
    {
        name: 'analytics',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'ANALYTICS'
    },
    {
        sync: NONE,
        priority: NONE,
        table: NONE
    }
];

const SYNC_CASH_ORDERS = [
    {
        name: 'cash_orders',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'CASH_ORDERS'
    },
    {
        name: 'cash_boxes',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'CASH_BOXES'
    },
    {
        name: 'requisites',
        checked: true,
        sync: NONE,
        priority: CARBOOK,
        table: 'REQUISITES'
    }
];

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

@connect(mapStateToProps, void 0)
export default class SyncImportExportModal extends Component {
    constructor(props) {
        super(props);

        const isCatalogueForbidden = isForbidden(props.user, permissions.ACCESS_SYNC_CATALOGUES);
        const isOrderForbidden = isForbidden(props.user, permissions.ACCESS_SYNC_ORDERS);
        const isStockForbidden = isForbidden(props.user, permissions.ACCESS_SYNC_STOCK);
        const isSettingsForbidden = isForbidden(props.user, permissions.ACCESS_SYNC_SETTINGS);

        this.state = {
            requisites: [],
            paramsModalVisible: false,
            dataSource: _.concat(
                !isCatalogueForbidden ? SYNC_CATALOGUES : [],
                !isOrderForbidden ? SYNC_ORDERS : [],
                !isStockForbidden ? SYNC_STOCK : [],
                SYNC_CASH_ORDERS
            )
        };

        this.mainColumns = [
            {
                dataIndex: 'checked',
                key: 'checked',
                render: (data, row) => {
                    return (
                        <Checkbox
                            checked={data}
                            onChange={({ target }) => {
                                row.checked = target.checked;
                                this.setState({});
                            }}
                            style={!row.name ? { opacity: '0' } : {}}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='export_import_pages.data_base' />,
                dataIndex: 'name',
                key: 'name',
                render: (data, row) => {
                    return data ? <FormattedMessage id={`export_import_pages.${data}`} /> : null;
                }
            }
        ];

        this.syncColumn = [
            {
                title: <FormattedMessage id='export_import_pages.sync' />,
                key: 'sync',
                children: [
                    {
                        title: <FormattedMessage id='export_import_pages.all' />,
                        dataIndex: 'sync',
                        key: 'ALL',
                        align: 'center',
                        render: (data, row) => {
                            return row.sync != NONE ? (
                                <Radio
                                    checked={data == ALL}
                                    onChange={({ target }) => {
                                        row.sync = target.value;
                                        this.setState({});
                                    }}
                                    value={ALL}
                                />
                            ) : null;
                        }
                    },
                    {
                        title: <FormattedMessage id='export_import_pages.from_documents' />,
                        dataIndex: 'sync',
                        key: 'FROM_DOCS',
                        align: 'center',
                        render: (data, row) => {
                            return row.sync != NONE ? (
                                <Radio
                                    checked={data == FROM_DOCS}
                                    onChange={({ target }) => {
                                        row.sync = target.value;
                                        this.setState({});
                                    }}
                                    value={FROM_DOCS}
                                />
                            ) : null;
                        }
                    }
                ]
            }
        ];

        this.priorityColumn = [
            {
                title: <FormattedMessage id='export_import_pages.priority' />,
                key: 'priority',
                children: [
                    {
                        title: <FormattedMessage id='export_import_pages.carbook' />,
                        dataIndex: 'priority',
                        key: 'CARBOOK',
                        align: 'center',
                        render: (data, row) => {
                            return row.priority != NONE ? (
                                <Radio
                                    checked={data == CARBOOK}
                                    onChange={({ target }) => {
                                        row.priority = target.value;
                                        this.setState({});
                                    }}
                                    value={CARBOOK}
                                />
                            ) : null;
                        }
                    },
                    {
                        title: <FormattedMessage id='export_import_pages.external' />,
                        dataIndex: 'priority',
                        key: 'EXTERNAL',
                        align: 'center',
                        render: (data, row) => {
                            return row.priority != NONE ? (
                                <Radio
                                    checked={data == EXTERNAL}
                                    onChange={({ target }) => {
                                        row.priority = target.value;
                                        this.setState({});
                                    }}
                                    value={EXTERNAL}
                                />
                            ) : null;
                        }
                    },
                    {
                        title: <FormattedMessage id='export_import_pages.manual' />,
                        dataIndex: 'priority',
                        key: 'MANUAL',
                        align: 'center',
                        render: (data, row) => {
                            return row.priority != NONE ? (
                                <Radio
                                    checked={data == MANUAL}
                                    onChange={({ target }) => {
                                        row.priority = target.value;
                                        this.setState({});
                                    }}
                                    value={MANUAL}
                                />
                            ) : null;
                        }
                    }
                ]
            }
        ];

        this.filterColumn = [
            {
                key: 'filter',
                render: row => {
                    const content = (
                        <div>
                            <RangePicker
                                onChange={([tableFromDate, tableToDate]) => {
                                    console.log(tableFromDate, tableToDate);
                                    row.tableFromDate = tableFromDate;
                                    row.tableToDate = tableToDate;
                                    row.tableFromId = undefined;
                                    row.tableToId = undefined;
                                    this.setState({});
                                }}
                                style={{
                                    width: '100%'
                                }}
                                value={[row.tableFromDate, row.tableToDate]}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    marginTop: 8
                                }}
                            >
                                <Input
                                    onChange={({ target }) => {
                                        row.tableFromId = target.value;
                                        row.tableFromDate = undefined;
                                        row.tableToDate = undefined;
                                        this.setState({});
                                    }}
                                    placeholder='От заданого ID'
                                    value={row.tableFromId}
                                />
                                <Input
                                    onChange={({ target }) => {
                                        row.tableToId = target.value;
                                        row.tableFromDate = undefined;
                                        row.tableToDate = undefined;
                                        this.setState({});
                                    }}
                                    placeholder='До заданого ID'
                                    value={row.tableToId}
                                />
                            </div>
                        </div>
                    );

                    return (
                        row.name && (
                            <Popover content={content} trigger='click'>
                                <ControlOutlined
                                    style={{
                                        fontSize: 24,
                                        verticalAlign: 'middle',
                                        color:
                                            row.tableFromId || row.tableToId || row.tableFromDate
                                                ? 'var(--primary)'
                                                : 'var(--text)'
                                    }}
                                />
                            </Popover>
                        )
                    );
                }
            }
        ];
    }

    handleCancel = () => {
        const { hideModal } = this.props;
        hideModal();
    };

    handleOk = () => {
        this.setState({
            paramsModalVisible: true
        });
    };

    componentDidMount() {
        getRequisites(data => {
            this.setState({
                requisites: data
            });
        });
    }

    render() {
        const { type, visible, tableData, hideModal, showConflictsModal, user } = this.props;
        const { paramsModalVisible, dataSource, requisites } = this.state;

        return (
            <Modal
                destroyOnClose
                maskClosable={false}
                okText={<FormattedMessage id='export_import_pages.next' />}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                title={<FormattedMessage id='export_import_pages.sync_data_base' />}
                visible={visible}
                width='fit-content'
                zIndex={200}
            >
                <Table
                    bordered
                    columns={
                        type == 'IMPORT'
                            ? [...this.mainColumns, ...this.priorityColumn, ...this.filterColumn]
                            : [...this.mainColumns, ...this.syncColumn, ...this.filterColumn]
                    }
                    dataSource={dataSource}
                    pagination={false}
                    rowKey='table'
                    size='small'
                />
                <SyncImportExportParametersModal
                    hideMainModal={() => {
                        hideModal();
                        this.setState({
                            paramsModalVisible: false
                        });
                    }}
                    hideModal={() => {
                        this.setState({
                            paramsModalVisible: false
                        });
                    }}
                    requisites={requisites}
                    showConflictsModal={showConflictsModal}
                    tableData={tableData}
                    tablesOptions={dataSource}
                    type={type}
                    visible={paramsModalVisible}
                />
            </Modal>
        );
    }
}

@injectIntl
class SyncImportExportParametersModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmLoading: false,
            fileList: [],
            ftpList: [],
            fileType: XLSX,
            syncDocs: ALL,
            subjectRequisiteId: [],
            status: DONE,
            statuses: [],
            syncPeriod: FROM_PREVIOUS,
            fromDate: undefined,
            syncThrough: FILE
        };
    }

    handleCancel = () => {
        const { hideModal } = this.props;
        hideModal();
    };

    handleOk = async () => {
        const token = localStorage.getItem('_my.carbook.pro_token');
        const { hideModal, type, tablesOptions, tableData, hideMainModal, showConflictsModal } = this.props;
        const {
            fileList,
            fileType,
            syncDocs,
            subjectRequisiteId,
            status,
            statuses,
            syncPeriod,
            fromDate,
            syncThrough
        } = this.state;

        if (type == 'EXPORT') {
            const payload = {
                syncDocs,
                status,
                syncPeriod,
                tablesOptions: tablesOptions
                    .filter(({ checked }) => checked)
                    .map(({ checked, table, sync, priority, tableFromId, tableToId, tableFromDate, tableToDate }) => {
                        if (checked) {
                            return {
                                table,
                                sync: sync == NONE ? ALL : sync,
                                tableFromId: tableFromId || null,
                                tableToId: tableToId || null,
                                tableFromDate: tableFromDate || null,
                                tableToDate: tableToDate || null
                            };
                        }
                    })
            };

            if (payload.syncDocs == SUBJECTS) {
                payload.subjectRequisiteId = subjectRequisiteId;
            }
            if (payload.status == DONE) {
                payload.status = OTHER;
                payload.statuses = [SUCCESS];
            }
            if (payload.syncPeriod == FROM_DATE) {
                payload.fromDate = fromDate.format('YYYY-MM-DD');
            }
            if (payload.syncPeriod == FROM_PREVIOUS) {
                if (tableData.length) {
                    payload.syncPeriod = FROM_DATE;
                    payload.fromDate = dayjs(tableData[0].datetime).format('YYYY-MM-DD');
                } else if (fromDate) {
                    payload.fromDate = fromDate.format('YYYY-MM-DD');
                } else {
                    payload.syncPeriod = ALL;
                }
            }

            const url = `${__API_URL__}/sync/export/${fileType}`;
            fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        return Promise.reject(new Error(response.statusText));
                    }

                    return Promise.resolve(response);
                })
                .then(function (response) {
                    return response.blob();
                })
                .then(function (file) {
                    saveAs(file, `backup-${dayjs().format('YYYY-MM-DD')}.${fileType}`);
                    hideMainModal();
                })
                .catch(function (error) {
                    console.log('error', error);
                });
        } else if (type == 'IMPORT') {
            this.setState({
                confirmLoading: true
            });
            const normalizedTablesOptions = [
                ...tablesOptions
                    .filter(({ checked }) => checked)
                    .map(({ checked, table, sync, priority, tableFromId, tableToId, tableFromDate, tableToDate }) => {
                        if (checked) {
                            return {
                                table,
                                priority,
                                tableFromId: tableFromId || null,
                                tableToId: tableToId || null,
                                tableFromDate: tableFromDate || null,
                                tableToDate: tableToDate || null
                            };
                        }
                    })
            ];
            const formData = new FormData();
            formData.append('syncThrough', syncThrough);
            formData.append('file', fileList[0]);
            formData.append('tablesOptions', JSON.stringify(normalizedTablesOptions));

            console.log('formData', formData);
            const url = `${__API_URL__}/sync/import/${fileType}`;
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: token
                        // 'content-type': 'application/json'
                    }
                });
                const result = await response.json();
                this.setState({
                    confirmLoading: true
                });
                hideMainModal();
                // showConflictsModal(result.conflictsId);
            } catch (error) {
                console.error('error:', error);
                this.setState({
                    confirmLoading: true
                });
            }
        }
    };

    render() {
        const {
            type,
            visible,
            intl: { formatMessage },
            requisites,
            tableData,
            hideMainModal
        } = this.props;
        const { fileList, ftpList, confirmLoading } = this.state;
        const uploadFileProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));

                return false;
            },
            fileList
        };
        const uploadFtpProps = {
            onRemove: ftp => {
                this.setState(state => {
                    const index = state.ftpList.indexOf(ftp);
                    const newFtpList = state.ftpList.slice();
                    newFtpList.splice(index, 1);

                    return {
                        ftpList: newFtpList
                    };
                });
            },
            beforeUpload: ftp => {
                this.setState(state => ({
                    ftpList: [...state.ftpList, ftp]
                }));

                return false;
            },
            ftpList
        };

        return (
            <Modal
                confirmLoading={confirmLoading}
                destroyOnClose
                maskClosable={false}
                okText={<FormattedMessage id='export_import_pages.sync' />}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                style={{ width: 'fit-content', minWidth: 640 }}
                title={<FormattedMessage id='export_import_pages.sync_parameters' />}
                visible={visible}
            >
                {type == 'EXPORT' && (
                    <div className={Styles.filtersBlock}>
                        <FormattedMessage id='export_import_pages.sync_documents' />
                        <div className={Styles.filterElementWrap}>
                            <FormattedMessage id='export_import_pages.entity' />
                            <Radio.Group
                                className={Styles.radioGroup}
                                defaultValue={ALL}
                                onChange={({ target }) => this.setState({ syncDocs: target.value })}
                            >
                                <Radio value={ALL}>
                                    <FormattedMessage id='export_import_pages.all' />
                                </Radio>
                                <Radio className={Styles.optionWithInput} value={SUBJECTS}>
                                    <FormattedMessage id='export_import_pages.subjects' />
                                    <Select
                                        className={Styles.radioInput}
                                        dropdownStyle={{ zIndex: 9999 }}
                                        mode='multiple'
                                        onChange={value => {
                                            this.setState({
                                                subjectRequisiteId: value
                                            });
                                        }}
                                        placeholder={formatMessage({
                                            id: 'export_import_pages.select_requisite'
                                        })}
                                    >
                                        {requisites.map((elem, key) => (
                                            <Option key={key} value={elem.id}>
                                                {elem.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Radio>
                            </Radio.Group>
                        </div>
                        <div className={Styles.filterElementWrap}>
                            <FormattedMessage id='export_import_pages.status' />
                            <Radio.Group
                                className={Styles.radioGroup}
                                defaultValue={DONE}
                                onChange={({ target }) => this.setState({ status: target.value })}
                            >
                                <Radio value={ALL}>
                                    <FormattedMessage id='export_import_pages.all' />
                                </Radio>
                                <Radio value={DONE}>
                                    <FormattedMessage id='export_import_pages.done' />
                                </Radio>
                                <Radio className={Styles.optionWithInput} value={OTHER}>
                                    <FormattedMessage id='other' />
                                    <Select
                                        className={Styles.radioInput}
                                        dropdownStyle={{ zIndex: 9999 }}
                                        mode='multiple'
                                        onChange={value => {
                                            this.setState({
                                                statuses: value
                                            });
                                        }}
                                        placeholder={formatMessage({
                                            id: 'export_import_pages.select_statuses'
                                        })}
                                    >
                                        {STATUSES.map((status, key) => (
                                            <Option key={key} value={status}>
                                                <FormattedMessage
                                                    id={`export_import_pages.status.${status.toUpperCase()}`}
                                                />
                                            </Option>
                                        ))}
                                    </Select>
                                </Radio>
                            </Radio.Group>
                        </div>
                    </div>
                )}
                {type == 'EXPORT' && (
                    <div className={Styles.filtersBlock}>
                        <div className={Styles.filterElementWrap}>
                            <FormattedMessage id='export_import_pages.sync_period' />
                            <Radio.Group
                                className={Styles.radioGroup}
                                defaultValue={FROM_PREVIOUS}
                                onChange={({ target }) => this.setState({ syncPeriod: target.value })}
                            >
                                <Radio value={FROM_PREVIOUS}>
                                    <FormattedMessage id='export_import_pages.sync_from_previous' />
                                </Radio>
                                <Radio value={ALL}>
                                    <FormattedMessage id='export_import_pages.all' />
                                </Radio>
                                <Radio className={Styles.optionWithInput} value={FROM_DATE}>
                                    <FormattedMessage id='export_import_pages.form_date' />
                                    <DatePicker
                                        onChange={date => {
                                            this.setState({ fromDate: date });
                                        }}
                                        popupStyle={{ zIndex: 9999 }}
                                    />
                                </Radio>
                            </Radio.Group>
                        </div>
                    </div>
                )}
                <div className={Styles.filtersBlock}>
                    {type == 'IMPORT' && (
                        <div className={Styles.filterElementWrap}>
                            <FormattedMessage id='export_import_pages.sync_through' />
                            <Radio.Group
                                className={Styles.radioGroup}
                                defaultValue={FILE}
                                onChange={({ target }) => this.setState({ syncThrough: target.value })}
                            >
                                <Radio className={Styles.optionWithInput} value={FILE}>
                                    <FormattedMessage id='export_import_pages.file' />
                                    <Upload {...uploadFileProps}>
                                        <Button style={{ width: 180 }}>
                                            <UploadOutlined /> <FormattedMessage id='export_import_pages.select_file' />
                                        </Button>
                                    </Upload>
                                </Radio>
                                <Radio className={Styles.optionWithInput} disabled value={FTP}>
                                    <FormattedMessage id='export_import_pages.ftp' />
                                    <Upload {...uploadFtpProps}>
                                        <Button disabled style={{ width: 180 }}>
                                            <UploadOutlined /> <FormattedMessage id='export_import_pages.select_file' />
                                        </Button>
                                    </Upload>
                                </Radio>
                            </Radio.Group>
                        </div>
                    )}
                    <div className={Styles.filterElementWrap}>
                        <FormattedMessage id='export_import_pages.file_format' />
                        <Radio.Group
                            className={Styles.radioGroup}
                            defaultValue={XLSX}
                            onChange={({ target }) => this.setState({ fileType: target.value })}
                        >
                            <Radio disabled={type == 'IMPORT'} value={XML}>
                                <FormattedMessage id='export_import_pages.xml' />
                            </Radio>
                            <Radio value={XLSX}>
                                <FormattedMessage id='export_import_pages.xlsx' />
                            </Radio>
                            <Radio disabled={type == 'IMPORT'} value={CSV}>
                                <FormattedMessage id='export_import_pages.csv' />
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
            </Modal>
        );
    }
}
