import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    FilterFilled,
    InfoOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Input, Popconfirm, Table, Tooltip, notification } from 'antd';
import { Select } from 'antd/lib';
import dayjs from 'dayjs';
import _ from 'lodash';
import { EntriesDirectoryAddModal } from 'modals';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { EntriesDirectoryAddRows, EntriesDirectoryRowsTable } from './modals';

const dateFormat = 'DD.MM.YYYY';
const { Option } = Select;

const EntriesDirectoryContainer = ({ intl, treeDataQuery, accounts, analytics, user }) => {
    const [entries, setEntries] = useState([]);
    const [entriesRows, setEntriesRows] = useState([]);
    const [editMode, setEdit] = useState(undefined);
    const [editModeRow, setRowsEdit] = useState(undefined);
    const [open, openAddModal] = useState(false);
    const [openRows, openRowsModal] = useState(false);
    const [openRowsTable, openRowsTableModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingRows, setLoadingRows] = useState(false);
    const [directoryId, setDirectoryId] = useState(undefined);
    const [tree, setTree] = useState([]);
    const [rows, setEntryRows] = useState([]);
    const [querySearch, setSearchQuery] = useState(undefined);
    const [accountId, setSearchAccountId] = useState(undefined);
    const [filters, setFilters] = useState({ counterpartType: undefined });
    const [requisites, setBusinessesRequisites] = useState([]);

    const fetchEntryRows = useCallback(
        async id => {
            setLoadingRows(true);
            const response = await fetchAPI(
                'GET',
                '/general_ledger/entries_directory_lines',
                { directoryId: directoryId || id },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setEntryRows(response);
            setLoadingRows(false);
        },
        [directoryId]
    );

    const fetchEntries = useCallback(async (querySearch, accountId) => {
        setLoading(true);
        const response = await fetchAPI('GET', '/general_ledger/entries_directory', { querySearch, accountId }, null, {
            handleErrorInternally: true
        });

        setEntries(response);
        setLoading(false);
    }, []);

    const fetchBusinessesRequisites = useCallback(async () => {
        const response = await fetchAPI('GET', '/businesses/requisites', null, null, {
            handleErrorInternally: true
        });

        setBusinessesRequisites(response);
    }, []);

    const filterTreeByActive = useCallback(nodes => {
        return nodes.reduce((filteredNodes, node) => {
            if (node.active === true) {
                filteredNodes.push({
                    value: node.id,
                    title: `(#${node.id}) ${node.name} `,
                    disabled: node.entries === false
                });

                if (node.children) {
                    const filteredChildren = filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNodes[filteredNodes.length - 1].children = filteredChildren;
                    }
                }
            }

            setTree(filteredNodes);

            return filteredNodes;
        }, []);
    });

    useEffect(() => {
        filterTreeByActive(treeDataQuery);
    }, [treeDataQuery]);

    useEffect(() => {
        fetchEntries();
        fetchBusinessesRequisites();
    }, [fetchEntries, fetchBusinessesRequisites]);

    const handleSearch = async confirm => {
        confirm();
        fetchEntries();
    };
    const handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        const tmp = filters;

        delete tmp[dataIndex];

        await setFilters(tmp);
        fetchEntries();
    };

    const handleSearchEnries = _.debounce((value, accountId) => {
        fetchEntries(value, accountId);
    }, 500);

    const createDefaultEntries = async () => {
        setLoading(true);
        await fetchAPI('POST', '/general_ledger/insert_default_directories', null, null, {
            handleErrorInternally: true
        });
        await fetchEntries();
        setLoading(false);
    };

    const getColumnSearchProps = useCallback(
        dataIndex => {
            let filterComponent = (confirm, clearFilters) => (
                <Input
                    onChange={e => {}}
                    onPressEnter={() => handleSearch(confirm, dataIndex)}
                    placeholder={intl.formatMessage({
                        id: 'search'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                />
            );

            if (dataIndex === 'counterpartyType') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={value => {
                            setFilters({ ...filters, [dataIndex]: value });
                            handleSearchEnries(value)
                        }}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={filters[dataIndex]}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                    >
                        <Option key='CUSTOMER' value='CUSTOMER'>
                            <FormattedMessage id='task_page.client' />
                        </Option>
                        <Option key='EMPLOYEE' value='EMPLOYEE'>
                            <FormattedMessage id='employee' />
                        </Option>
                        <Option key='SUPPLIER' value='SUPPLIER'>
                            <FormattedMessage id='suppliers_page.supplier' />
                        </Option>
                        <Option key='OTHER' value='OTHER'>
                            <FormattedMessage id='OTHER' />
                        </Option>
                    </Select>
                );
            }

            return {
                filterDropdown: ({ confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        {filterComponent(confirm, clearFilters)}
                        {dataIndex !== '11' && dataIndex !== 'filterCreatedDate' && dataIndex !== 'filtertDoneDate' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <Button onClick={() => handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                    </div>
                ),
                filterIcon: () => {
                    let colorFilter;
                    if (filters[dataIndex]) {
                        colorFilter = 'var(--primary)';
                    }

                    return (
                        <FilterFilled
                            style={{
                                fontSize: 14,
                                color: colorFilter
                            }}
                        />
                    );
                }
                // onFilterDropdownVisibleChange: visible => {
                //     if (visible) {
                //         setTimeout(() => this.searchInput.select(), 100);
                //     }
                // }
            };
        },
        [handleReset, handleSearch, intl, filters]
    );

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='№' />,
                key: 'num',
                width: '3%',
                render: (row, number, index) => {
                    return index + 1;
                }
            },
            {
                title: () => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Tooltip title={<FormattedMessage id='entries_tab.add' />}>
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={async () => {
                                        await openAddModal(true);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                },
                key: 'actions',
                width: '8%',
                render: row => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Popconfirm
                                onConfirm={async () => {
                                    try {
                                        await fetchAPI(
                                            'DELETE',
                                            '/general_ledger/entries_directory',
                                            null,
                                            { id: row.id },
                                            {
                                                handleErrorInternally: true
                                            }
                                        );
                                        await setTimeout(() => fetchEntries(), 100);
                                    } catch (err) {
                                        notification.error({
                                            message: intl.formatMessage({ id: 'entries_tab.error_delete' })
                                        });
                                    }
                                }}
                                title={`${intl.formatMessage({ id: 'delete' })}?`}
                            >
                                <Button icon={<DeleteOutlined />} />
                            </Popconfirm>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                    openAddModal(true);
                                    setEdit(row);
                                }}
                            />
                            <Tooltip
                                getPopupContainer={trigger => trigger.parentNode}
                                title={<FormattedMessage id='entries_tab.row_tooltip' />}
                            >
                                <Button
                                    icon={<InfoOutlined />}
                                    onClick={async () => {
                                        await openRowsTableModal(true);
                                        await setDirectoryId(row.id);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: '15%',
                render: row => {
                    return row.name ? row.name : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='entries_tab.user_id' />,
                key: 'manager',
                width: '7%',
                render: row => {
                    return row.managerName && row.managerName.length > 2 ? row.managerName : row.managerId;
                }
            },
            {
                title: <FormattedMessage id='entries_tab.modify_date' />,
                key: 'data',
                width: '10%',
                render: row => {
                    return row.modifyDate ? (
                        dayjs(row.modifyDate).format('YYYY-MM-DD')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },

            {
                title: <FormattedMessage id='directory_page.doc_type' />,
                key: 'docType',
                width: '10%',
                // ...getColumnSearchProps('startDate'),
                render: row => {
                    return row.docType ? row.docType : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='storage.operation_type' />,
                key: 'operationType',
                width: '10%',
                render: row => {
                    return row.operationType ? (
                        <FormattedMessage id={`entries_tab.${row.operationType}`} />
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_document.business_requisites' />,
                key: 'requisiteName',
                // ...getColumnSearchProps('startDate'),
                width: '10%',
                render: row => {
                    return row.requisiteName ? row.requisiteName : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='storage_document.counterparty_type' />,
                key: 'counterpartyType',
                ...getColumnSearchProps('counterpartyType'),
                width: '10%',
                render: row => {
                    return row.counterpartType ? (
                        <FormattedMessage id={`entries_tab.${row.counterpartType}`.replace(', ', '_')} />
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'comment',
                // ...getColumnSearchProps('startDate'),
                width: '15%',
                render: row => {
                    return row.comment ? row.comment : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='entries_tab.verified' />,
                key: 'verified',
                width: '5%',
                align: 'center',
                render: row => {
                    return row.verified === true ? (
                        <span
                            style={{
                                fontSize: 20,
                                color: 'green'
                            }}
                        >
                            <CheckCircleOutlined />{' '}
                        </span>
                    ) : (
                        <span
                            style={{
                                fontSize: 20,
                                color: 'red'
                            }}
                        >
                            <CloseCircleOutlined />
                        </span>
                    );
                }
            }
        ];
    }, [intl, rows, entries, setEntryRows]);

    // const pagination = {
    //     pageSize,
    //     total: Math.ceil(totalCount / pageSize) * pageSize,
    //     hideOnSinglePage: true,
    //     current: page,
    //     onChange: async (page, pageSize) => {
    //         await setPage(page);
    //         await setPageSize(pageSize);
    //
    //     }
    // };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    marginBottom: 8
                }}
            >
                <div
                    style={{
                        marginRight: 8
                    }}
                >
                    <Tooltip title={<FormattedMessage id='entries_tab.search' />}>
                        <Input
                            onChange={event => {
                                handleSearchEnries(event.target.value, accountId);
                            }}
                            placeholder={intl.formatMessage({
                                id: 'entries_tab.search'
                            })}
                            style={{
                                width: '100%'
                            }}
                            value={accountId}
                        />
                    </Tooltip>
                </div>
                <div
                    style={{
                        marginRight: 8
                    }}
                >
                    <Tooltip title={<FormattedMessage id='entries_tab.search_by_number' />}>
                        <Input
                            onChange={event => {
                                handleSearchEnries(querySearch, event.target.value);
                            }}
                            placeholder={intl.formatMessage({
                                id: 'entries_tab.search_by_number'
                            })}
                            style={{
                                width: '100%'
                            }}
                            value={querySearch}
                        />
                    </Tooltip>
                </div>
                {isGrantAccessed(user, grants.ACCOUNTING_JOURNAL_ENTRIES, accesses.ROWO) && (
                    <div>
                        <Button
                            disabled={loading}
                            onClick={() => createDefaultEntries()}
                            style={{
                                width: '100%'
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='entries_tab.add_default_etnries' />
                        </Button>
                    </div>
                )}
            </div>
            <div>
                <Table
                    columns={columns}
                    dataSource={entries}
                    loading={loading}
                    pagination={false}
                    rowKey='id'
                    scroll={{ x: 'auto' }}
                    size='small'
                />
                <EntriesDirectoryAddModal
                    editMode={editMode}
                    fetchEntries={fetchEntries}
                    hideModal={() => {
                        openAddModal(false);
                        setEdit(undefined);
                    }}
                    open={open}
                    requisites={requisites}
                />
                <EntriesDirectoryAddRows
                    analytics={analytics}
                    directoryId={directoryId}
                    fetchEntryRows={fetchEntryRows}
                    hideModal={() => {
                        openRowsModal(false);
                        setRowsEdit(undefined);
                        setDirectoryId(undefined);
                    }}
                    open={openRows}
                    tree={tree}
                />
                <EntriesDirectoryRowsTable
                    analytics={analytics}
                    directoryId={directoryId}
                    fetchEntries={fetchEntries}
                    fetchEntryRows={fetchEntryRows}
                    hideModal={() => {
                        openRowsTableModal(false);
                        setEntriesRows([]);
                        setDirectoryId(undefined);
                    }}
                    loadingRows={loadingRows}
                    open={openRowsTable}
                    rows={rows}
                    tree={tree}
                />
            </div>
        </div>
    );
};

export default injectIntl(EntriesDirectoryContainer);
