/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import { DeleteOutlined, FormOutlined, LoadingOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, InputNumber, Modal, Popconfirm, Select, Spin, Tabs, Tooltip, TreeSelect } from 'antd';
import { Space } from 'antd/lib';
import _, { get } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ComplexesIcon } from 'theme';
import { fetchAPI, filterTreeByPart, filterTreeNodeByPart, numeralFormatter } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TabPane } = Tabs;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

const complexesTypes = {
    OWN: 'complexes.type.OWN',
    PRODUCTION: 'complexes.type.PRODUCTION',
    DISASSEMBLY: 'complexes.type.DISASSEMBLY'
};

@injectIntl
export default class ComplexesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataSource: [],
            selectedComplex: undefined,
            laborsDataSource: [],
            detailsDataSource: [],
            brands: [],
            complexTree: [],
            newSellingPrice: undefined,
            type: 'OWN'
        };

        this.handleFetchSellingPrice = _.debounce(
            async (purchasePrice, storeGroupId, detailCode, productPrice, sellingPrice, index) => {
                await this.getMarkupsSellingPrice(purchasePrice, storeGroupId, detailCode, index);
            },
            1000
        ).bind(this);
    }

    buildComplexTree(dataSource) {
        const treeData = [];
        for (let i = 0; i < dataSource.length; i++) {
            const parentGroup = dataSource[i];
            treeData.push({
                title: parentGroup.name,
                value: parentGroup.id,
                key: `${i}`,
                selectable: false,
                children: []
            });
            for (let j = 0; j < parentGroup.childs.length; j++) {
                const childGroup = parentGroup.childs[j];
                treeData[i].children.push({
                    title: childGroup.name,
                    value: childGroup.id,
                    key: `${i}-${j}`,
                    labors: childGroup.labors,
                    details: childGroup.storeGroups || childGroup.products
                });
            }
        }
        this.setState({
            complexTree: treeData
        });
    }

    fetchData = async () => {
        let [data, own, brands] = await Promise.all([
            fetchAPI('GET', 'complexes', { hide: false }, null, { handleErrorInternally: true }),
            fetchAPI('GET', 'business/complexes', { types: ['PRODUCTION', 'OWN', 'DISASSEMBLY'] }, null, {
                handleErrorInternally: true
            }),
            fetchAPI('GET', 'brands', null, null, { handleErrorInternally: true })
        ]);

        own = own.map(el => ({ ...el, id: Number(el.id) * -1 }));

        const dataSource = [
            {
                name: <FormattedMessage id='own' />,
                id: 1,
                childs: own.filter(({ enabled, type }) => enabled && type === 'OWN')
            },
            {
                name: <FormattedMessage id='complexes.type.PRODUCTION' />,
                id: 2,
                childs: own.filter(({ enabled, type }) => enabled && type === 'PRODUCTION')
            },
            {
                name: <FormattedMessage id='complexes.type.DISASSEMBLY' />,
                id: 3,
                childs: own.filter(({ enabled, type }) => enabled && type === 'DISASSEMBLY')
            },
            ...data
        ];
        this.buildComplexTree(dataSource);
        this.setState({
            fetched: true,
            dataSource,
            brands
        });
    };

    async addDetailsAndLabors(data) {
        const { status } = await fetchAPI('GET', 'orders/status', { orderId: this.props.orderId }, null);
        if (status === 'success') {
            window.location.reload();

            return;
        }
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/orders/${this.props.orderId}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            if (result.success) {
                this.props.reloadOrderForm(void 0, 'all');
            } else {
                console.log('BAD', result);
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    handleCancel = async () => {
        await this.handleNewComplexClose();

        this.setState({
            visible: false,
            dataSource: [],
            selectedComplex: undefined,
            laborsDataSource: [],
            detailsDataSource: [],
            newSellingPrice: undefined,
            newComplexOpen: false
        });
    };

    handleOk = () => {
        const { isMobile } = this.props;
        const { laborsDataSource, detailsDataSource, selectedComplex } = this.state;

        this.setState({ visible: false });
        const data = {
            services: [],
            details: [],
            insertMode: true
        };

        if (this.props.tecdocId && selectedComplex && Number(selectedComplex) > 0) {
            data.modificationId = this.props.tecdocId;
        }

        laborsDataSource.map(element => {
            if (element.checked) {
                data.services.push({
                    serviceName:
                        element.commentary && element.commentary.comment
                            ? `${element.commentary.comment}`
                            : element.name || element.laborName,
                    serviceId: element.laborLaborId || element.id || element.laborId,
                    count:
                        Number(element.count * (this.props.laborTimeMultiplier || 1)) ||
                        Number(element.laborCount * (this.props.laborTimeMultiplier || 1)) ||
                        this.props.laborTimeMultiplier,
                    serviceHours: 0,
                    servicePrice:
                        element.price || element.laborPrice
                            ? element.price || element.laborPrice
                            : this.props.normHourPrice
                            ? Number(this.props.normHourPrice)
                            : 1,
                    employeeId: this.props.defaultEmployeeId || null,
                    comment: element.commentary,
                    laborUnitId: !isMobile
                        ? element.laborUnitId ||
                          _.get(
                              this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                              '[0].id'
                          )
                        : element.laborUnitId || 1,
                    serviceRowDiscount: this.props.laborsDiscount || this.props.clientDiscount
                });
            }
        });
        detailsDataSource.map(element => {
            if (element.checked && (element.id || element.productStoreGroupId)) {
                data.details.push({
                    name:
                        element.commentary && element.commentary.comment
                            ? `${element.commentary.comment}`
                            : element.name || element.productName,
                    storeGroupId: element.id || element.productStoreGroupId,
                    count: element.productCount || element.count || 1,
                    comment: element.commentary,
                    productCode: element.productCode,
                    supplierBrandId: element.productBrandId,
                    price: element.productPrice || 1,
                    purchasePrice: element.purchasePrice || 0,
                    supplierId: element.supplierId,
                    partRowDiscount: this.props.detailsDiscount || this.props.clientDiscount
                });
            }
        });
        this.addDetailsAndLabors(data);
        this.handleCancel();
    };

    handleNewComplexClose = () => {
        this.setState({ newComplexOpen: false });
    };

    handleNewComplexOpen = () => {
        this.setState({ newComplexOpen: true });
    };

    submitCreate = async () => {
        const { laborsDataSource, detailsDataSource } = this.state;
        const complexLaborsData = laborsDataSource.map(
            ({ name, laborName, normHours, laborHours, price, laborPrice, count, laborCount, laborUnitId, id }) => ({
                laborName: name || laborName,
                laborHours: normHours || laborHours,
                laborPrice: price || laborPrice,
                laborCount: count || laborCount,
                ...(laborUnitId ? { laborUnitId } : {}),
                laborLaborId: id
            })
        );
        const complexProductsData = detailsDataSource.map(
            ({
                id,
                name,
                productName,
                count,
                productCount,
                price,
                productPrice,
                brandId,
                productBrandId,
                productCode
            }) => ({
                ...(productCode ? { productCode } : {}),
                productStoreGroupId: id,
                productName: name || productName,
                productCount: count || productCount,
                productPrice: price || productPrice,
                ...(brandId || productBrandId ? { productBrandId: brandId || productBrandId } : {})
            })
        );

        const body = {
            complexData: {
                name: this.state.newName,
                type: this.state.type || 'OWN'
            },
            complexLaborsData,
            complexProductsData
        };

        const response = await fetchAPI('POST', 'business/complexes', null, body, {
            handleErrorInternally: true
        });
        this.setState({ newComplexOpen: false });
        this.handleCancel();
    };

    componentDidUpdate() {
        if (!this.state.fetched && this.state.visible) {
            this.fetchData();
        }
    }

    getDefaultDetails = async () => {
        const { tecdocId, bodyId } = this.props;
        const { detailsDataSource, selectedComplex } = this.state;

        if (tecdocId && selectedComplex && Number(selectedComplex) > 0) {
            const modificationId = tecdocId;

            const result = [[]];

            for (let i = 0; i < detailsDataSource.length; i++) {
                if (result[result.length - 1].length < 20) {
                    result[result.length - 1].push({
                        modificationId,
                        bodyId,
                        storeGroupId: detailsDataSource[i].id
                    });
                }
            }

            const args = await Promise.all(
                result.map(el =>
                    fetchAPI(
                        'POST',
                        'store_groups/default_details',
                        null,
                        { details: el },
                        { handleErrorInternally: true }
                    )
                )
            );
            args.flat().forEach((result, i) => {
                detailsDataSource[i].productBrandId = result.brandId;
                detailsDataSource[i].productCode = result.partNumber;
                detailsDataSource[i].name = result.description || detailsDataSource[i].name;
                detailsDataSource[i].productPrice = result.sellingPrice;
                detailsDataSource[i].supplierId = _.get(result, 'price.businessSupplierId');
            });

            this.setState({
                detailsDataSource
            });
        }
    };

    getMarkupsSellingPrice = async (purchasePrice, storeGroupId, detailCode, index) => {
        const newSellingPrice = await fetchAPI(
            'GET',
            '/selling_price',
            {
                purchasePrice,
                storeGroupId: storeGroupId || null,
                clientId: this.props.clientId || null,
                code: detailCode || null
            },
            {
                handleErrorInternally: true
            }
        );
        await this.setState({
            newSellingPrice
        });

        await this.changeDetailsDataSourceField(
            {
                productPrice: newSellingPrice ? newSellingPrice.sellingPrice : 1
            },
            index
        );
    };

    changeDetailsDataSourceField = (fields, index, additionalState = {}) => {
        this.setState(({ detailsDataSource: ds }) => {
            const detailsDataSource = ds;
            Object.entries(fields).forEach(([key, value]) => {
                if (detailsDataSource && detailsDataSource[index]) {
                    detailsDataSource[index][key] = value;
                }
            });

            return {
                detailsDataSource,
                ...additionalState
            };
        });
    };

    renderLaborsBlock(tabMode) {
        const { laborsDataSource } = this.state;
        const { labors } = this.props;
        const { formatMessage } = this.props.intl;

        const allCheckedTrue = laborsDataSource.filter(elem => elem.checked === true);

        return (
            <div className={Styles.laborsList} style={tabMode && { width: '100%' }}>
                <div className={Styles.listTitle}>
                    <Checkbox
                        checked={
                            get(laborsDataSource, 'length') > 0 &&
                            get(laborsDataSource, 'length') === get(allCheckedTrue, 'length')
                        }
                        className={Styles.selectAll}
                        disabled={get(laborsDataSource, 'length') < 1}
                        onChange={({ target }) => {
                            laborsDataSource.forEach(row => {
                                row.checked = target.checked;
                            });
                            this.setState({});
                        }}
                    />
                    <FormattedMessage id='add_order_form.services' />
                </div>

                {laborsDataSource.map((elem, key) => {
                    return (
                        <div key={key} className={Styles.listRow}>
                            <div>{key + 1}</div>
                            <div>
                                <Checkbox
                                    checked={elem.checked}
                                    data-qa='checkbox_labor_data_source_complexes_modal'
                                    onChange={({ target }) => {
                                        elem.checked = target.checked;
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div className={Styles.nameField}>
                                <Tooltip title={elem.name || elem.laborName}>
                                    <Input
                                        data-qa='input_name_labor_complexes_modal'
                                        disabled
                                        style={{ color: 'var(--text)' }}
                                        value={elem.name || elem.laborName}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={<FormattedMessage id='clients-page.quantity' />}>
                                    <InputNumber
                                        data-qa='input_number_count_labor_complexes_modal'
                                        decimalSeparator=','
                                        min={0.01}
                                        onChange={value => {
                                            elem.count = value;
                                            this.setState({});
                                        }}
                                        placeholder={formatMessage({ id: 'clients-page.quantity' })}
                                        step={0.1}
                                        style={{ color: 'var(--text)' }}
                                        value={elem.count || elem.laborCount}
                                    />
                                </Tooltip>
                            </div>

                            <div>
                                <Tooltip title={<FormattedMessage id='price' />}>
                                    <InputNumber
                                        // addonBefore={getCurrency()}
                                        data-qa='input_number_price_labor_complexes_modal'
                                        decimalSeparator=','
                                        min={0.01}
                                        onChange={value => {
                                            elem.price = value;
                                            this.setState({});
                                        }}
                                        placeholder={formatMessage({ id: 'price' })}
                                        // step={0.1}
                                        style={{ color: 'var(--text)' }}
                                        value={elem.price || elem.laborPrice}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <CommentaryButton
                                    commentary={elem.commentary}
                                    data-qa='commentary_button_labor_complexes_modal'
                                    detail={elem.name || elem.laborName}
                                    disabled={elem.name === null || elem.laborName === null}
                                    setComment={(comment, positions) => {
                                        elem.commentary = {
                                            comment,
                                            positions
                                        };
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div>
                                <DeleteOutlined
                                    className={Styles.deleteIcon}
                                    onClick={() => {
                                        this.setState({
                                            laborsDataSource: laborsDataSource.filter((labor, index) => index != key)
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
                <div className={Styles.listRow}>
                    <div>{laborsDataSource.length + 1}</div>
                    <div>
                        <Checkbox checked data-qa='checkbox_laborsDataSource_1_complexes_modal' disabled />
                    </div>
                    <div className={Styles.nameField}>
                        <Select
                            data-qa='select_laborDataSourse_push_complexes_modal'
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            filterOption={false}
                            onSearch={input => this.props.handleSearchLabors(input)}
                            onSelect={(value, { name, hours, price, laborUnitId }) => {
                                const val = value && value.replace(/-/g, '');
                                laborsDataSource.push({
                                    id: val,
                                    name,
                                    count: hours || 1,
                                    normHours: hours,
                                    price,
                                    checked: true,
                                    commentary: {
                                        comment: undefined,
                                        positions: []
                                    },
                                    laborUnitId
                                });
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.labor'
                            })}
                            showSearch
                            value={null}
                        >
                            {labors.map(({ id, name, laborPrice, laborUnitId }, key) => (
                                <Option
                                    key={key}
                                    hours={laborPrice.normHours}
                                    laborUnitId={laborUnitId}
                                    name={name}
                                    price={laborPrice.price}
                                    value={id}
                                >
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <InputNumber
                            data-qa='input_number_one_labor_complexes_modal'
                            decimalSeparator=','
                            disabled
                            placeholder={formatMessage({ id: 'clients-page.quantity' })}
                            step={0.1}
                            style={{ color: 'var(--text)' }}
                            value={1}
                        />
                    </div>
                    <div>
                        <InputNumber
                            // addonBefore={getCurrency()}
                            data-qa='input_number_price_labor_complexes_modal'
                            decimalSeparator=','
                            disabled
                            min={0.1}
                            // step={0.1}
                            style={{ color: 'var(--text)' }}
                        />
                    </div>
                    <div>
                        <CommentaryButton
                            commentary={{
                                comment: undefined,
                                positions: []
                            }}
                            data-qa='commentary_button_labor_complexes_modal'
                            disabled
                        />
                    </div>
                    <div>
                        <DeleteOutlined className={Styles.disabledIcon} />
                    </div>
                </div>
            </div>
        );
    }

    renderDetailsBlock(tabMode) {
        const { detailsDataSource, newSellingPrice } = this.state;
        const { detailsTreeData } = this.props;
        const { formatMessage } = this.props.intl;

        const sellingPrice = newSellingPrice ? newSellingPrice.sellingPrice : 1;

        const allCheckedTrue = detailsDataSource.filter(elem => elem.checked === true);

        return (
            <div className={Styles.detailsList} style={tabMode && { width: '100%' }}>
                <div className={Styles.listTitle}>
                    <Checkbox
                        checked={
                            get(detailsDataSource, 'length') > 0 &&
                            get(detailsDataSource, 'length') === get(allCheckedTrue, 'length')
                        }
                        className={Styles.selectAll}
                        disabled={get(detailsDataSource, 'length') < 1}
                        onChange={({ target }) => {
                            detailsDataSource.forEach(row => {
                                row.checked = target.checked;
                            });
                            this.setState({});
                        }}
                    />
                    <FormattedMessage id='add_order_form.details' />
                </div>
                {detailsDataSource.map((elem, key) => {
                    return (
                        <div key={key} className={Styles.listRow}>
                            <div>{key + 1}</div>
                            <div>
                                <Checkbox
                                    checked={elem.checked}
                                    data-qa='checkbox_detailsDataSource_complexes_modal'
                                    onChange={({ target }) => {
                                        elem.checked = target.checked;
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div className={Styles.nameField}>
                                <Tooltip title={elem.name || elem.productName}>
                                    <Input
                                        data-qa='input_name_detail_complexes_modal'
                                        disabled
                                        style={{ color: 'var(--text)', minWidth: 200 }}
                                        value={elem.name || elem.productName}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={<FormattedMessage id='brands_page.title' />}>
                                    <Select
                                        data-qa='select_laborDataSourse_push_complexes_modal'
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                            zIndex: '9999',
                                            minWidth: 150
                                        }}
                                        filterOption={(input, option) => {
                                            const brands = input.toLowerCase().split(' ');

                                            return brands.every(part =>
                                                String(option.children).toLowerCase().includes(part));
                                        }}
                                        onSelect={(value, value2) => {
                                            elem.brandId = value;
                                            this.setState({});
                                        }}
                                        placeholder={formatMessage({
                                            id: 'brands_page.title'
                                        })}
                                        showSearch
                                        style={{ width: 120 }}
                                        value={elem.brandId || elem.productBrandId}
                                        width='fit'
                                    >
                                        {this.state.brands.map(({ brandId, brandName }) => (
                                            <Option key={brandId} value={brandId}>
                                                {brandName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={<FormattedMessage id='order_form_table.detail_code' />}>
                                    <Input
                                        data-qa='input_number_price_labor_complexes_modal'
                                        onBlur={() => {
                                            this.changeDetailsDataSourceField({
                                                productPrice: sellingPrice
                                            });
                                        }}
                                        onChange={event => {
                                            elem.productCode = event.target.value;
                                            this.setState({});

                                            if (elem.purchasePrice) {
                                                this.handleFetchSellingPrice(
                                                    elem.purchasePrice,
                                                    elem.id,
                                                    elem.productCode,
                                                    elem.productPrice,
                                                    sellingPrice,
                                                    key
                                                );
                                            }
                                        }}
                                        placeholder={formatMessage({ id: 'order_form_table.detail_code' })}
                                        style={{ color: 'var(--text)', minWidth: 90 }}
                                        value={elem.productCode}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={<FormattedMessage id='clients-page.quantity' />}>
                                    <InputNumber
                                        data-qa='input_number_count_detail_complexes_modal'
                                        onChange={value => {
                                            elem.productCount = value;
                                            this.setState({});
                                        }}
                                        placeholder={formatMessage({ id: 'clients-page.quantity' })}
                                        step={0.1}
                                        style={{ color: 'var(--text)', maxWidth: 75 }}
                                        value={elem.productCount}
                                        
                                        decimalSeparator=','
                                        // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        min={0.01}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={<FormattedMessage id='order_form_table.purchasePrice' />}>
                                    <InputNumber
                                        // addonBefore={getCurrency()}
                                        data-qa='input_number_purchase_price_labor_complexes_modal'
                                        decimalSeparator=','
                                        min={0.01}
                                        onBlur={() => {
                                            this.changeDetailsDataSourceField({
                                                productPrice: sellingPrice
                                            });
                                        }}
                                        onChange={value => {
                                            elem.purchasePrice = value;
                                            this.setState({});

                                            console.log(key, 'key');

                                            if (elem.purchasePrice) {
                                                this.handleFetchSellingPrice(
                                                    elem.purchasePrice,
                                                    elem.id,
                                                    elem.productCode,
                                                    elem.productPrice,
                                                    sellingPrice,
                                                    key
                                                );
                                            }
                                        }}
                                        placeholder={formatMessage({ id: 'order_form_table.purchasePrice' })}
                                        step={0.1}
                                        style={{ color: 'var(--text)', maxWidth: 75 }}
                                        value={elem.purchasePrice}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={<FormattedMessage id='price' />}>
                                    <InputNumber
                                        // addonBefore={getCurrency()}
                                        data-qa='input_number_price_labor_complexes_modal'
                                        decimalSeparator=','
                                        min={0.01}
                                        onChange={value => {
                                            elem.productPrice = value;
                                            this.setState({});
                                        }}
                                        placeholder={formatMessage({ id: 'price' })}
                                        step={0.1}
                                        style={{ color: 'var(--text)', maxWidth: 75 }}
                                        value={elem.productPrice}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <CommentaryButton
                                    commentary={elem.commentary}
                                    data-qa='commentary_button_detail_complexes_modal'
                                    detail={elem.name || elem.productName}
                                    disabled={elem.name === null || elem.productName === null}
                                    setComment={(comment, positions) => {
                                        elem.commentary = {
                                            comment,
                                            positions
                                        };
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div>
                                <DeleteOutlined
                                    className={Styles.deleteIcon}
                                    onClick={() => {
                                        this.setState({
                                            detailsDataSource: detailsDataSource.filter((labor, index) => index != key)
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
                <div className={Styles.listRow}>
                    <div>{detailsDataSource.length + 1}</div>
                    <div>
                        <Checkbox checked data-qa='checkbox_detailsDataSource_1_complexes_modal' disabled />
                    </div>
                    <div className={Styles.nameField}>
                        <TreeSelect
                            data-qa='tree_select_push_detail_complexes_modal'
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                            filterTreeNode={filterTreeNodeByPart}
                            onSelect={(value, { title }) => {
                                detailsDataSource.push({
                                    id: value,
                                    name: title,
                                    count: 1,
                                    checked: true,
                                    commentary: {
                                        comment: undefined,
                                        positions: []
                                    }
                                });
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.complex'
                            })}
                            popupMatchSelectWidth={false}
                            showSearch
                            style={{
                                minWidth: 200
                            }}
                            treeData={detailsTreeData}
                            value={null}
                        />
                    </div>
                    <div>
                        <Select
                            data-qa='select_laborDataSourse_push_complexes_modal'
                            disabled
                            placeholder={formatMessage({
                                id: 'brands_page.title'
                            })}
                            showSearch
                            style={{ width: 120 }}
                            value={null}
                            width='fit'
                        />
                    </div>
                    <div>
                        <InputNumber
                            data-qa='input_number_detail_1_complexes_modal'
                            disabled
                            placeholder={formatMessage({ id: 'order_form_table.detail_code' })}
                            style={{ color: 'var(--text)' }}
                        />
                    </div>
                    <div>
                        <InputNumber
                            data-qa='input_number_detail_1_complexes_modal'
                            decimalSeparator=','
                            disabled
                            placeholder={formatMessage({ id: 'clients-page.quantity' })}
                            style={{ color: 'var(--text)', maxWidth: 75 }}
                            value={1}
                        />
                    </div>
                    <div>
                        <InputNumber
                            // addonBefore={getCurrency()}
                            data-qa='input_number_purchase_price_labor_complexes_modal'
                            decimalSeparator=','
                            disabled
                            min={0.1}
                            placeholder={formatMessage({ id: 'order_form_table.purchasePrice' })}
                            // step={0.1}
                            style={{ color: 'var(--text)', maxWidth: 75 }}
                        />
                    </div>
                    <div>
                        <InputNumber
                            // addonBefore={getCurrency()}
                            data-qa='input_number_price_labor_complexes_modal'
                            decimalSeparator=','
                            disabled
                            placeholder={formatMessage({ id: 'price' })}
                            min={0.1}
                            // step={0.1}
                            style={{ color: 'var(--text)', maxWidth: 75 }}
                        />
                    </div>
                    <div>
                        <CommentaryButton
                            commentary={{
                                comment: undefined,
                                positions: []
                            }}
                            data-qa='commentary_button_detail_1_complexes_modal'
                            disabled
                        />
                    </div>
                    <div>
                        <DeleteOutlined className={Styles.disabledIcon} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            disabled,
            isMobile,
            intl: { formatMessage }
        } = this.props;
        const { dataSource, selectedComplex, laborsDataSource, detailsDataSource, complexTree } = this.state;

        const allServicesSum = laborsDataSource
            .filter(({ checked }) => checked)
            .reduce((acc, cur) => acc + (cur.price || cur.laborPrice) * (cur.count || cur.laborCount || 1), 0);
        const allDetailsSum = detailsDataSource
            .filter(({ checked }) => checked)
            .reduce((acc, cur) => acc + cur.productPrice * (cur.productCount || 1), 0);

        return (
            <React.Fragment>
                <Tooltip placement='top' title={<FormattedMessage id='services_table.complexes' />}>
                    <Button
                        data-qa='button_visible_complexes_modal'
                        disabled={disabled}
                        icon={<ComplexesIcon />}
                        onClick={() => {
                            this.setState({ visible: true });
                        }}
                    />
                </Tooltip>
                <Modal
                    footer={[
                        <Space
                            size='large'
                            style={{
                                marginRight: 36
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 700
                                }}
                            >
                                <FormattedMessage id='total_labors' />:{' '}
                            </span>
                            {numeralFormatter(Number(allServicesSum || 0).toFixed(2))}
                            <span
                                style={{
                                    fontWeight: 700
                                }}
                            >
                                <FormattedMessage id='total_parts' />:{' '}
                            </span>
                            {numeralFormatter(Number(allDetailsSum || 0).toFixed(2))}
                            <span
                                style={{
                                    fontWeight: 700
                                }}
                            >
                                <FormattedMessage id='profile.spare_parts_workplace.total' />:{' '}
                            </span>
                            {numeralFormatter(Number((allServicesSum || 0) + (allDetailsSum || 0)).toFixed(2))}
                        </Space>,
                        <Popconfirm
                            description={
                                <div>
                                    <div
                                        style={{
                                            marginBottom: 8
                                        }}
                                    >
                                        <Input
                                            onChange={e => this.setState({ newName: e.target.value })}
                                            placeholder={formatMessage({ id: 'complexes.complex_name' })}
                                            value={this.state.newName}
                                        />
                                    </div>
                                    <div>
                                        <Select
                                            onChange={value => this.setState({ type: value })}
                                            optionFilterProp='children'
                                            placeholder={formatMessage({
                                                id: 'complexes.type'
                                            })}
                                            showSearch
                                            style={{ marginBottom: 6, width: '100%' }}
                                            value={this.state.type}
                                        >
                                            {Object.entries(complexesTypes).map(([key, value]) => (
                                                <Option key={key} value={key}>
                                                    <FormattedMessage id={value} />
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            }
                            onCancel={this.handleNewComplexClose}
                            onConfirm={this.submitCreate}
                            open={this.state.newComplexOpen}
                            title={formatMessage({ id: 'complexes.change_name' })}
                        >
                            <Button onClick={this.handleNewComplexOpen}>
                                <FormattedMessage id='save' />
                            </Button>
                        </Popconfirm>,
                        <Button onClick={this.handleNewComplexClose}>
                            <FormattedMessage id='cancel' />
                        </Button>,
                        <Button onClick={this.handleOk} type='primary'>
                            <FormattedMessage id='ok' />
                        </Button>
                    ]}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='services_table.complexes' />}
                    visible={this.state.visible}
                    width='95%'
                >
                    {this.state.fetched ? (
                        <div>
                            <div className={Styles.complexSelect}>
                                <TreeSelect
                                    allowClear
                                    data-qa='tree_select_modal_complexes_modal'
                                    dropdownStyle={{
                                        overflow: 'auto',
                                        zIndex: '9999',
                                        maxHeight: 500
                                    }}
                                    filterTreeNode={filterTreeByPart}
                                    onChange={async (value, label, { triggerNode }) => {
                                        const labors = triggerNode.props.labors.map(elem => {
                                            elem.checked = true;
                                            elem.count = elem.laborPrice ? elem.laborPrice.normHours : 1;
                                            elem.commentary = {
                                                comment: undefined,
                                                positions: []
                                            };
                                            elem.price = elem.laborPrice ? elem.laborPrice.price : null;
                                            elem.laborUnitId = elem.laborUnitId || null;

                                            return elem;
                                        });
                                        const details = triggerNode.props.details.map(elem => {
                                            elem.checked = true;
                                            elem.name = elem.singleName;
                                            elem.count = 1;
                                            elem.commentary = {
                                                comment: undefined,
                                                positions: []
                                            };

                                            return elem;
                                        });
                                        await this.setState({
                                            selectedComplex: value,
                                            laborsDataSource: labors,
                                            detailsDataSource: details
                                        });
                                        this.getDefaultDetails();
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'services_table.complex'
                                    })}
                                    showSearch
                                    treeData={complexTree}
                                    value={selectedComplex}
                                />
                            </div>
                            {!isMobile ? (
                                <div className={Styles.listsWrapper}>
                                    {this.renderLaborsBlock()}
                                    {this.renderDetailsBlock()}
                                </div>
                            ) : (
                                <Tabs>
                                    <TabPane
                                        key='services'
                                        data-qa='tab_pane_services_complexes_modal'
                                        forceRender
                                        tab={this.props.intl.formatMessage({
                                            id: 'add_order_form.services',
                                            defaultMessage: 'Services'
                                        })}
                                    >
                                        {this.renderLaborsBlock(true)}
                                    </TabPane>
                                    <TabPane
                                        key='details'
                                        data-qa='tab_pane_details_complexes_modal'
                                        forceRender
                                        tab={this.props.intl.formatMessage({
                                            id: 'add_order_form.details',
                                            defaultMessage: 'Details'
                                        })}
                                    >
                                        {this.renderDetailsBlock(true)}
                                    </TabPane>
                                </Tabs>
                            )}
                        </div>
                    ) : (
                        <Spin indicator={spinIcon} />
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

@injectIntl
export class CommentaryButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            currentCommentaryProps: {
                name: props.detail,
                positions: []
            },
            currentCommentary: undefined
        };
        this.commentaryInput = React.createRef();
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this._isMounted = false;
    }

    showModal = () => {
        this.setState({
            currentCommentary: this.props.commentary.comment ? this.props.commentary.comment : this.props.detail,
            visible: true,
            currentCommentaryProps: {
                positions: this.props.commentary.positions || [],
                problems: this.props.commentary.problems || []
            }
        });
        if (this.commentaryInput.current != undefined) {
            this.commentaryInput.current.focus();
        }
    };

    handleOk = async () => {
        const { currentCommentary, currentCommentaryProps } = this.state;
        this.setState({
            loading: true
        });
        this.props.setComment(
            currentCommentary,
            currentCommentaryProps.positions,
            this.props.tableKey,
            currentCommentaryProps.problems
        );
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 500);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            currentCommentary: this.props.detail,
            currentCommentaryProps: {
                name: this.props.detail,
                positions: []
            }
        });
    };

    renderHeader = () => {
        return (
            <div>
                <p>{this.props.detail}</p>
            </div>
        );
    };

    getCommentary() {
        const { currentCommentaryProps } = this.state;
        let currentCommentary = this.props.detail;

        if (currentCommentaryProps.positions.length) {
            currentCommentary += ' -';
            currentCommentary += `${currentCommentaryProps.positions.map(
                data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
            )};`;
        }
        this.setState({
            currentCommentary
        });
    }

    setCommentaryPosition(position) {
        const { currentCommentaryProps } = this.state;
        const positionIndex = currentCommentaryProps.positions.indexOf(position);
        if (positionIndex == -1) {
            currentCommentaryProps.positions.push(position);
        } else {
            currentCommentaryProps.positions = currentCommentaryProps.positions.filter(
                (value, index) => index != positionIndex
            );
        }
        this.getCommentary();
    }

    componentDidMount() {
        this._isMounted = true;
        const { commentary, detail } = this.props;
        if (this._isMounted) {
            this.setState({
                currentCommentaryProps: {
                    name: detail,
                    positions: commentary.positions || []
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { TextArea } = Input;
        const { visible, loading, currentCommentaryProps, currentCommentary } = this.state;
        const { disabled, commentary } = this.props;
        const { positions } = this;

        return (
            <div>
                {commentary.comment ? (
                    <FormOutlined
                        className={Styles.commentaryButtonIcon}
                        onClick={this.showModal}
                        style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                        title={this.props.intl.formatMessage({ id: 'commentary.edit' })}
                    />
                ) : (
                    <MessageOutlined
                        onClick={() => {
                            if (!disabled) this.showModal();
                        }}
                        style={disabled ? { color: 'rgba(0, 0, 0, 0.25)', pointerEvents: 'none' } : {}}
                        title={this.props.intl.formatMessage({ id: 'commentary.add' })}
                    />
                )}
                <Modal
                    footer={
                        disabled
                            ? null
                            : [
                                  <Button
                                      key='back'
                                      data-qa='button_handle_cancel_commentary_button_complexes_modal'
                                      onClick={this.handleCancel}
                                  >
                                      <FormattedMessage id='cancel' />
                                  </Button>,
                                  <Button
                                      key='submit'
                                      data-qa='button_handle_ok_commentary_button_complexes_modal'
                                      loading={loading}
                                      onClick={this.handleOk}
                                      type='primary'
                                  >
                                      <FormattedMessage id='save' />
                                  </Button>
                              ]
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={visible}
                    title={this.renderHeader()}
                >
                    <React.Fragment>
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.where' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {positions.map((position, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            className={Styles.commentaryBlockButton}
                                            data-qa='button_set_commentary_position_commentary_button_complexes_modal'
                                            onClick={() => {
                                                this.setCommentaryPosition(position);
                                            }}
                                            type={
                                                currentCommentaryProps.positions.findIndex(elem => position == elem) >
                                                -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <FormattedMessage id={position} />
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='order_form_table.diagnostic.commentary' />
                            </p>
                            <TextArea
                                ref={this.commentaryInput}
                                autoFocus
                                data-qa='text_area_current_commentary_button_complexes_modal'
                                disabled={disabled}
                                onChange={() => {
                                    this.setState({
                                        currentCommentary: event.target.value
                                    });
                                }}
                                placeholder={`${this.props.intl.formatMessage({
                                    id: 'comment'
                                })}...`}
                                style={{ width: '100%', minHeight: '150px', resize: 'none' }}
                                value={currentCommentary}
                            />
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}
