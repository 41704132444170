import { Input, Table } from 'antd';
import { Catcher } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { columnsConfig } from './storageTableConfig';
import Styles from './styles.m.css';

const mapState = state => ({
    user: state.auth
});

@connect(mapState)
@withRouter
@injectIntl
class StorageTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            storageFilter: { warehouses: this.props.warehouses }
        };

        this.handleDebounceSearch = _.debounce(value => {
            const { onSearch } = this.props;
            onSearch(value);
        }, 600);
    }

    componentDidMount() {
        const { location, onSearch } = this.props;
        if (location.state && location.state.searchValue) {
            onSearch(location.state.searchValue);
        }
    }

    render() {
        const {
            documentsList,
            match,
            listType,
            onSearch,
            loading,
            hideFilters,
            isCRUDForbidden,
            totalCount,
            page,
            pageSize,
            setPage,
            location,
            warehouseId,
            warehouses = [],
            setWarehouseId,
            setCounterpartWarehouseId,
            counterpartWarehouseId,
            pagination
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { storageFilter } = this.state;
        const columns = columnsConfig(
            isCRUDForbidden,
            match.path,
            listType,
            formatMessage,
            { warehouseId, warehouses, setWarehouseId, setCounterpartWarehouseId, counterpartWarehouseId },
            () => {}
        );

        return (
            <Catcher>
                {!hideFilters && (
                    <div className={Styles.filterWrap}>
                        <Input.Search
                            allowClear
                            defaultValue={location.state && location.state.searchValue}
                            onChange={event => {
                                this.handleDebounceSearch(event.target.value);
                            }}
                            placeholder={formatMessage({
                                id: 'orders.search.placeholder'
                            })}
                            maxLength={20}
                        />
                    </div>
                )}
                <div className={Styles.paper}>
                    <Table
                        bordered
                        className={Styles.documentsTable}
                        columns={columns}
                        dataSource={documentsList}
                        loading={loading}
                        locale={{
                            emptyText: <FormattedMessage id='no_data' />
                        }}
                        pagination={pagination}
                        size='small'
                        rowKey='id'
                        // scroll={{ y: 61 * 10 }}
                        sticky
                    />
                </div>
            </Catcher>
        );
    }
}

export default StorageTable;
