import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import { Input } from 'antd';
import React, { forwardRef, memo } from 'react';
import styled from 'styled-components';

// const FormItem = Form.Item;
const FormItem = styled(Form.Item)`
    display: ${props => props.hiddenField && 'none'};
`;

const StyledInput = styled(Input)`
    display: ${props => props.hiddeninput && 'none'};
`;

export const DecoratedInput = memo(
    forwardRef((props, ref) => {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,
            onPressEnter,
            // styles
            hiddenField,
            hiddeninput,
            cnStyles,
            style,
            // input
            getFieldDecorator,
            disabled,
            rules,
            type,
            placeholder,
            icon,
            iconType,
            field,
            initialValue,
            onChange,
            onClick,
            suffix,
            addonAfter,
            onBlur,
            maxLength,
            suffixIcon
        } = props;

        const input = getFieldDecorator(field, {
            ...(initialValue ? { initialValue } : { initialValue: void 0 }),
            rules
        })(
            type !== 'password' ? (
                <StyledInput
                    ref={ref}
                    addonAfter={addonAfter}
                    className={cnStyles || className}
                    disabled={disabled}
                    hiddeninput={hiddeninput}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    onChange={onChange}
                    onClick={onClick}
                    onPressEnter={onPressEnter}
                    placeholder={placeholder}
                    prefix={
                        icon ? (
                            <LegacyIcon
                                style={{
                                    color: 'rgba(0,0,0,.25)'
                                }}
                                type={iconType}
                            />
                        ) : null
                    }
                    style={style}
                    suffix={suffix}
                    suffixIcon={suffixIcon}
                    type={type}
                />
            ) : (
                <Input.Password
                    ref={ref}
                    addonAfter={addonAfter}
                    className={cnStyles || className}
                    disabled={disabled}
                    hiddeninput={hiddeninput}
                    maxLength={maxLength}
                    onChange={onChange}
                    onClick={onClick}
                    onPressEnter={onPressEnter}
                    placeholder={placeholder}
                    prefix={
                        icon ? (
                            <LegacyIcon
                                style={{
                                    color: 'rgba(0,0,0,.25)'
                                }}
                                type={iconType}
                            />
                        ) : null
                    }
                    style={style}
                    suffix={suffix}
                    type={type}
                />
            )
        );

        return formItem ? (
            <FormItem
                className={className}
                colon={colon}
                hasFeedback={hasFeedback}
                hiddenfield={hiddenField}
                label={label}
                {...formItemLayout}
            >
                {input}
            </FormItem>
        ) : (
            input
        );
    })
);
