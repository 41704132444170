import { Form } from '@ant-design/compatible';
import { TreeSelect } from 'antd';
import _ from 'lodash';
import React, { forwardRef, memo } from 'react';

const FormItem = Form.Item;
const { TreeNode } = TreeSelect;

export const DecoratedTreeSelect = memo(
    forwardRef((props, ref) => {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,
            allowClear,

            cnStyles,
            getPopupContainer,
            dropdownStyle,
            getFieldDecorator,
            disabled,
            rules,
            placeholder,

            treeData,
            treeDataNodes,
            treeDefaultExpandAll,
            field,
            initialValue,
            style,
            onChange,
            onSelect
        } = props;

        const treeSelectFilterTreeNode = (input, treeNode) => {
            const compare1 = treeNode.props.title.toLowerCase();
            const compare2 = input.toLowerCase();

            return compare1.indexOf(compare2) >= 0;
        };

        const loop = treeDataNodes =>
            treeDataNodes.map((node, index) => {
                if (!_.isEmpty(node.childGroups)) {
                    return (
                        <TreeNode
                            label={`${node.name} (#${node.id})`}
                            title={`${node.name} (#${node.id})`}
                            value={node.id}
                        >
                            {loop(node.childGroups)}
                        </TreeNode>
                    );
                }

                return (
                    <TreeNode
                        isLeaf
                        label={`${node.name} (#${node.id})`}
                        title={`${node.name} (#${node.id})`}
                        value={node.id}
                    >
                        {!_.isEmpty(node.childGroups)}
                    </TreeNode>
                );
            });

        const treeSelect = getFieldDecorator(field, {
            ...(initialValue ? { initialValue } : { initialValue: void 0 }),
            rules
        })(
            <TreeSelect
                ref={ref}
                allowClear={allowClear}
                className={cnStyles}
                disabled={disabled}
                dropdownStyle={dropdownStyle || { maxHeight: 400, overflow: 'auto' }}
                filterTreeNode={treeSelectFilterTreeNode}
                getPopupContainer={getPopupContainer}
                onChange={onChange}
                onSelect={onSelect}
                placeholder={placeholder}
                popupMatchSelectWidth={280}
                showSearch
                style={style}
                treeData={treeData}
                treeDefaultExpandAll={treeDefaultExpandAll}
            >
                {!_.isEmpty(treeDataNodes) ? loop(treeDataNodes) : null}
            </TreeSelect>
        );

        return formItem ? (
            <FormItem className={className} colon={colon} hasFeedback={hasFeedback} label={label} {...formItemLayout}>
                {treeSelect}
            </FormItem>
        ) : (
            treeSelect
        );
    })
);
