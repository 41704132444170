import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const struct = [
    {
        blockTitle: 'employee_salary_rules.SALE_AUTO_PARTS',
        color: 'var(--db_progress)',
        items: [
            {
                itemName: 'navigation.order_catalogue',
                disabled: false,
                link: book.orderCatalogue
            },
            {
                itemName: 'create_retailReport',
                disabled: false
                // link: book.rtl
            },
            {
                itemName: 'new-document-page.item.rtl',
                disabled: false,
                link: book.rtl
            },
            {
                itemName: 'profile.spare_parts_workplace.title',
                disabled: user => !isGrantAccessed(user, grants.OPERATIONS_JOB_DETAILS),
                link: book.sparePartsWorkplacePage
            }
        ]
    },
    {
        blockTitle: 'carshop.other_docs',
        color: 'var(--db_reserve)',
        items: [
            {
                itemName: 'navigation.new_document',
                disabled: user => !isGrantAccessed(user, grants.WORKPLACE_QUICK_NAVIGATION),
                link: book.newDocumentPage
            },
            {
                itemName: 'navigation.incomes_docs',
                disabled: user => !isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS),
                link: book.storageIncomes
            },

            {
                itemName: 'navigation.expenses_docs',
                disabled: user => !isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES),
                link: book.storageExpenses
            },
            {
                itemName: 'new-document-page.item.interactions',
                disabled: user => !isGrantAccessed(user, grants.CRM_INTERACTIONS_LIST),
                link: book.interactions
            }
        ]
    },
    {
        blockTitle: 'navigation.accounting',
        color: 'var(--db_approve)',
        items: [
            {
                itemName: 'navigation.cash_settings',
                disabled: user => !isGrantAccessed(user, grants.ACCOUNTING_CASH_REGISTERS),
                link: book.cashBankPage
            },
            {
                itemName: 'navigation.flow_of_money',
                disabled: user => !isGrantAccessed(user, grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER),
                link: book.cashFlowPage
            },
            {
                itemName: 'navigation.report_cash_orders_logs',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTER_DEVICES),
                link: book.reportCashOrdersLogs
            },
            {
                itemName: 'navigation.sal',
                disabled: user => !isGrantAccessed(user, grants.ACCOUNTING_SALARIES_DOCUMENT_LIST, accesses.ROWO),
                link: book.salariesPage
            }
        ]
    },
    {
        blockTitle: 'navigation.directories',
        color: 'var(--db_success)',
        items: [
            {
                itemName: 'navigation.client_hot_operations',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST),
                link: book.clientHotOperations
            },
            {
                itemName: 'navigation.products',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST),
                link: book.products
            },
            {
                itemName: 'navigation.other_directories',
                disabled: user => !isGrantAccessed(user, grants.DIRECTORIES),
                link: book.directoriesPage
            },
            {
                itemName: 'navigation.other_settings',
                disabled: user => !isGrantAccessed(user, grants.SETTINGS),
                link: book.settingsDirectoriesPage
            }
        ]
    },
    {
        blockTitle: 'navigation.reports',
        color: 'var(--db_success)',
        items: [
            {
                itemName: 'navigation.manager_reports',
                disabled: user => !isGrantAccessed(user, grants.MANAGER_REPORTS),
                link: book.managerReports
            },
            {
                itemName: 'navigation.graphic_reports',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_GRAPHICAL_REPORTS),
                link: book.reportGraphic
            },
            {
                itemName: 'carshop.sales_per_day',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_GRAPHICAL_REPORTS),
                link: book.reportGraphic
            },
            {
                itemName: 'navigation.reports_xlsx',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS),
                link: book.reportsPage
            }
        ]
    },
    {
        blockTitle: 'carshop.reserves_management',
        color: 'var(--approve)',
        items: [
            {
                itemName: 'carshop.reserves',
                disabled: false,
                link: book.sparePartsWorkplacePage
            },
            {
                itemName: 'calculation_minmax',
                disabled: false,
                link: book.abcReportsPage
            },
            {
                itemName: 'carshop.surpluses',
                disabled: true,
                link: book.analytics
            },
            {
                itemName: 'carshop.xyz_analysis',
                disabled: true,
                link: book.ReferenceBookPage
            }
        ]
    }
    // {
    //     blockTitle: 'directories.general_settings',
    //     color: 'var(--db_not_complete)',
    //     items: [
    //         {
    //             itemName: 'navigation.station_settings',
    //             disabled: false,
    //             link: book.settings
    //         },
    //         {
    //             itemName: 'navigation.theme_settings',
    //             disabled: user => !isAdmin(user),
    //             link: book.themeSettings
    //         },
    //         {
    //             itemName: 'navigation.suppliers_pricelists',
    //             disabled: false,
    //             link: book.availabilitiesPage
    //         },
    //         {
    //             itemName: 'navigation.suppliers_API',
    //             disabled: false,
    //             link: book.apiSettings
    //         },
    //         {
    //             itemName: 'navigation.IP_calls',
    //             disabled: true,
    //             link: book.exception
    //         },
    //         {
    //             itemName: 'navigation.SMS',
    //             disabled: user => isForbidden(user, permissions.ACCESS_SMS_TEMPLATES),
    //             link: book.smsSettings
    //         },
    //         {
    //             itemName: 'navigation.source',
    //             disabled: false,
    //             link: book.source
    //         },
    //         {
    //             itemName: 'navigation.www',
    //             disabled: false,
    //             link: book.webSettings
    //         }
    //     ]
    // }
];

const mapStateToProps = state => {
    return {
        user: state.auth,
        isMobile: state.ui.views.isMobile
    };
};

@connect(mapStateToProps, void 0)
export default class CarshopDirectories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            helperDrawerOpen: false,
            allLinks: [],
            todayDate: true
        };
    }

    componentDidMount() {}

    _renderBlock = ({ blockTitle, items, color }, key) => {
        const { isMobile } = this.props;

        return (
            <div key={key} className={!isMobile ? Styles.block : Styles.blockMobile}>
                <div className={Styles.blockTitle}>
                    <FormattedMessage id={blockTitle} />
                </div>
                <div className={!isMobile ? Styles.blockItems : Styles.blockItemsMobile}>
                    {items.map((item, key) => this._renderItem(blockTitle, item, key, color))}
                </div>
            </div>
        );
    };

    _renderItem = (blockTitle, { itemName, link, disabled, oldApp }, key, color) => {
        const { user, isMobile } = this.props;
        const itemDisabled = _.isFunction(disabled) ? disabled(user) : disabled;

        return (
            <div
                key={key}
                className={
                    !isMobile
                        ? itemDisabled
                            ? `${Styles.disabledItem} ${Styles.item}`
                            : Styles.item
                        : `${itemDisabled ? Styles.disabledItem : ''} ${Styles.itemMobile}`
                }
            >
                {oldApp ? (
                    <a className={Styles.buttonLink} href={link}>
                        <Button
                            className={Styles.itemButton}
                            disabled={itemDisabled}
                            style={{ fontWeight: 500, border: `2px solid ${color}` }}
                        >
                            <FormattedMessage id={itemName} />
                        </Button>
                    </a>
                ) : itemName === 'create_retailReport' ? (
                    <Button
                        className={Styles.itemButton}
                        disabled={!isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT, accesses.ROWO)}
                        onClick={async () => {
                            const res = await fetchAPI('POST', '/retails', undefined, undefined, {
                                handleErrorInternally: true
                            });

                            if (res && res.id) {
                                goTo(`${book.rtl}/${res.id}`);
                            }
                        }}
                        style={{ fontWeight: 500, border: `2px solid ${color}` }}
                    >
                        <FormattedMessage id={itemName} />
                    </Button>
                ) : itemName === 'carshop.sales_per_day' ? (
                    <Link
                        className={Styles.buttonLink}
                        to={{
                            pathname: link,
                            state: {
                                date: this.state.todayDate
                            }
                        }}
                    >
                        <Button
                            className={Styles.itemButton}
                            disabled={itemDisabled}
                            style={{ fontWeight: 500, border: `2px solid ${color}` }}
                        >
                            <FormattedMessage id={itemName} />
                        </Button>
                    </Link>
                ) : itemName === 'carshop.reserves' ? (
                    <Link
                        className={Styles.buttonLink}
                        to={{
                            pathname: link,
                            state: {
                                specificTab: 'storageOrders'
                            }
                        }}
                    >
                        <Button
                            className={Styles.itemButton}
                            disabled={itemDisabled}
                            style={{ fontWeight: 500, border: `2px solid ${color}` }}
                        >
                            <FormattedMessage id={itemName} />
                        </Button>
                    </Link>
                ) : (
                    <Link className={Styles.buttonLink} to={link}>
                        <Button
                            className={Styles.itemButton}
                            disabled={itemDisabled}
                            style={{ fontWeight: 500, border: `2px solid ${color}` }}
                        >
                            <FormattedMessage id={itemName} />
                        </Button>
                    </Link>
                )}
            </div>
        );
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const { helperDrawerOpen, allLinks, isMobile } = this.state;

        return (
            <div>
                <Layout
                    // controls={
                    //     <div>
                    //         <Button
                    //             icon={<QuestionCircleOutlined />}
                    //             onClick={async () => {
                    //                 const links = await fetchAPI(
                    //                     'GET',
                    //                     '/helps',
                    //                     { helpId: 'directories_and_settings' },
                    //                     undefined,
                    //                     {
                    //                         handleErrorInternally: true
                    //                     }
                    //                 );
                    //                 this.setState({
                    //                     allLinks: links,
                    //                     helperDrawerOpen: true
                    //                 });
                    //             }}
                    //             style={{
                    //                 fontSize: 22,
                    //                 display: 'flex',
                    //                 justifyContent: 'center'
                    //             }}
                    //             type='text'
                    //         />
                    //     </div>
                    // }
                    // description={<FormattedMessage id='directories.description' />}
                    title={<FormattedMessage id='car_shop_title_APM' />}
                >
                    {struct.map((block, key) => this._renderBlock(block, key))}
                </Layout>
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </div>
        );
    }
}
