import dayjs from 'dayjs';

/* Constants */
export const moduleName = 'profile';
const prefix = `cpb/${moduleName}`;

export const FETCH_SPARE_PARTS_WORKPLACE_DATA = `${prefix}/FETCH_SPARE_PARTS_WORKPLACE_DATA`;
export const FETCH_SPARE_PARTS_WORKPLACE_DATA_SUCCESS = `${prefix}/FETCH_SPARE_PARTS_WORKPLACE_DATA_SUCCESS`;

export const UPDATE_SPARE_PARTS_WORKPLACE_DATA = `${prefix}/UPDATE_SPARE_PARTS_WORKPLACE_DATA`;
export const UPDATE_SPARE_PARTS_WORKPLACE_DATA_SUCCESS = `${prefix}/UPDATE_SPARE_PARTS_WORKPLACE_DATA`;

export const UPDATE_ORDERS_FOR_SURPLUSES = `${prefix}/UPDATE_ORDERS_FOR_SURPLUSES`;
export const UPDATE_ORDERS_FOR_SURPLUSES_SUCCESS = `${prefix}/UPDATE_ORDERS_FOR_SURPLUSES`;

export const CHANGE_SPARE_PARTS_WORKPLACE_DATA_FILTERS = `${prefix}/CHANGE_SPARE_PARTS_WORKPLACE_DATA_FILTERS`;
export const CHANGE_SPARE_PARTS_WORKPLACE_STORAGE_DATA_FILTERS = `${prefix}/CHANGE_SPARE_PARTS_WORKPLACE_STORAGE_DATA_FILTERS`;

export const CHANGE_ORDERS_SURPLUSES_DATA_FILTERS = `${prefix}/CHANGE_ORDERS_SURPLUSES_DATA_FILTERS`;

export const RETURN_DETAILS_TO_SUPPLIER = `${prefix}/RETURN_DETAILS_TO_SUPPLIER`;

export const CHANGE_SPARE_PARTS_WORKPLACE_TAB = `${prefix}/CHANGE_SPARE_PARTS_WORKPLACE_TAB`;

/* Reducer */
const ReducerState = {
    tab: 'fromOrders',
    sparePartsData: {
        details: [],
        statistics: {
            critical: 0,
            new: 0,
            overdue: 0,
            ending: 0,
            totalCount: 0
        },
        suppliers: []
    },
    ordersToStorageData: {
        details: [],
        statistics: {
            critical: 0,
            new: 0,
            overdue: 0,
            ending: 0,
            totalCount: 0
        },
        suppliers: []
    },
    filters: {
        orderBy: localStorage.getItem('_my.carbook.spare_parts_order_by') || 'ORDER',
        filterBy: undefined,
        fromBeginDate: dayjs().add(-30, 'days').format('YYYY-MM-DD'),
        toBeginDate: dayjs().add(30, 'days').format('YYYY-MM-DD'),
        page: 1,
        pageSize: 15
    },
    filtersStorage: {
        filterBy: undefined,
        fromBeginDate: dayjs().add(-30, 'days').format('YYYY-MM-DD'),
        toBeginDate: dayjs().format('YYYY-MM-DD'),
        page: 1,
        pageSize: 15
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_SPARE_PARTS_WORKPLACE_DATA_SUCCESS:
            return {
                ...state,
                sparePartsData: payload.sparePartsData,
                ordersToStorageData: payload.ordersToStorageData
            };

        case CHANGE_SPARE_PARTS_WORKPLACE_DATA_FILTERS:
            return {
                ...state,
                filters: {
                    orderBy: payload.orderBy || state.filters.orderBy,
                    fromBeginDate: payload.fromBeginDate || state.filters.fromBeginDate,
                    toBeginDate: payload.toBeginDate || state.filters.toBeginDate,
                    page: payload.page || 1,
                    pageSize: payload.pageSize || 10,
                    ...state.filters,
                    ...payload
                }
            };

        case CHANGE_SPARE_PARTS_WORKPLACE_STORAGE_DATA_FILTERS:
            return {
                ...state,
                filtersStorage: {
                    fromBeginDate: payload.fromBeginDate || state.filtersStorage.fromBeginDate,
                    toBeginDate: payload.toBeginDate || state.filtersStorage.toBeginDate,
                    page: payload.page || 1,
                    pageSize: payload.pageSize || 10,
                    ...state.filtersStorage,
                    ...payload
                }
            };

        case CHANGE_ORDERS_SURPLUSES_DATA_FILTERS:
            return {
                ...state,
                filters: {
                    orderBy: localStorage.setItem(
                        '_my.carbook.order_surpluses_order_by',
                        payload.orderBy || state.filters.orderBy
                    ),
                    fromBeginDate: payload.fromBeginDate,
                    toBeginDate: payload.toBeginDate,
                    ...state.filters,
                    ...payload
                }
            };

        case CHANGE_SPARE_PARTS_WORKPLACE_TAB:
            return {
                ...state,
                tab: payload.tab
            };

        default:
            return state;
    }
}

/* Selectors */

export const selectSparePartsWorkplaceData = state => state[moduleName].sparePartsData;
export const selectOrdersToStorageData = state => state[moduleName].ordersToStorageData;
export const selectSparePartsWorkplaceFilters = state => state[moduleName].filters;
export const selectSparePartsWorkplaceStorageFilters = state => state[moduleName].filtersStorage;
export const selectSparePartsWorkplaceTab = state => state[moduleName].tab;

/* Actions */

export const fetchSparePartsWorkplaceData = () => ({
    type: FETCH_SPARE_PARTS_WORKPLACE_DATA
});

export const fetchOrdersForSurplusesData = () => ({
    type: FETCH_ORDERS_FOR_SURPLUSES
});

export const fetchSparePartsWorkplaceDataSuccess = (sparePartsData, ordersToStorageData) => ({
    type: FETCH_SPARE_PARTS_WORKPLACE_DATA_SUCCESS,
    payload: { sparePartsData, ordersToStorageData }
});

export const updateSparePartsWorkplaceData = details => ({
    type: UPDATE_SPARE_PARTS_WORKPLACE_DATA,
    payload: { details }
});

export const updateOrdersForSurpluses = details => ({
    type: UPDATE_ORDERS_FOR_SURPLUSES,
    payload: { details }
});

export const changeSparePartsWorkplaceDataFilters = filters => ({
    type: CHANGE_SPARE_PARTS_WORKPLACE_DATA_FILTERS,
    payload: filters
});

export const changeSparePartsWorkplaceStorageDataFilters = filtersStorage => ({
    type: CHANGE_SPARE_PARTS_WORKPLACE_STORAGE_DATA_FILTERS,
    payload: filtersStorage
});

export const changeOrdersSurplusesDataFilters = filters => ({
    type: CHANGE_ORDERS_SURPLUSES_DATA_FILTERS,
    payload: filters
});

export const returnDetailsToSupplier = ordersAppurtenanciesIds => ({
    type: RETURN_DETAILS_TO_SUPPLIER,
    payload: { ordersAppurtenanciesIds }
});

export const changeSparePartsWorkplaceTab = tab => ({
    type: CHANGE_SPARE_PARTS_WORKPLACE_TAB,
    payload: { tab }
});
