import { Select, Tooltip } from 'antd';
import { cashlessPaymentTypes } from 'forms/CashCreationForm/config';
import { CASH_ORDER_TYPES, MODES } from 'modals/RefactoredCashOrderModal/constants';
import { selectCashboxes } from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import {
    selectCashlessPaymentType,
    selectCashOrderType,
    selectMode,
    selectPaymentType,
    selectRequisiteId,
    selectSelectedCashbox,
    selectSelectedCashboxId,
    setCardMask,
    setCashlessPaymentType
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const { INCOME, EXPENSE, ADJUSTMENT_INCOME } = CASH_ORDER_TYPES;

const mapStateToProps = state => ({
    cashOrderType: selectCashOrderType(state),
    selectedCashbox: selectSelectedCashbox(state),
    selectRequisiteId: selectRequisiteId(state),
    selectPaymentType: selectPaymentType(state),
    selectCashlessPaymentType: selectCashlessPaymentType(state),
    mode: selectMode(state),
    cashboxes: selectCashboxes(state),
    selectedCashboxId: selectSelectedCashboxId(state)
});

const mapDispatchToProps = {
    setCashlessPaymentType,
    setCardMask
};

/**
 * This component serves selecting cash order type
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CashlessPaymentTypeSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        const {
            cashOrderType,
            setRequisites,
            selectRequisiteId,
            mode,
            editable,
            cashboxes,
            selectCashlessPaymentType,
            setCashlessPaymentType,
            setCardMask,
            intl: { formatMessage }
        } = this.props;

        return (
            <Tooltip placement='top' title={<FormattedMessage id='cashless_payment_type' />} zIndex={2001}>
                <Select
                    disabled={mode != MODES.ADD}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={value => {
                        if (!value) {
                            setCashlessPaymentType(null);
                        } else {
                            setCardMask(null);
                            // if (value === 'POS_TERMINAL') {
                            //     setCardMask('**** **** **** ****');
                            // }
                            setCashlessPaymentType(value);
                        }
                    }}
                    placeholder={formatMessage({
                        id: 'cashless_payment_type'
                    })}
                    value={selectCashlessPaymentType}
                >
                    {Object.values(cashlessPaymentTypes).map(item => (
                        <Option key={item} value={item}>
                            {formatMessage({ id: `cash-creation-form.type-${item}` })}
                        </Option>
                    ))}
                </Select>
            </Tooltip>
        );
    }
}
