/* eslint-disable react/sort-comp */
/* eslint-disable react/no-array-index-key */
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
    CheckOutlined,
    LoadingOutlined,
    MinusOutlined,
    PlusOutlined,
    UnorderedListOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from '@ant-design/icons';
import {
    Button,
    Card,
    Checkbox,
    Collapse,
    Flex,
    Image,
    Input,
    Modal,
    Radio,
    Slider,
    Space,
    Spin,
    Table,
    Tree,
    TreeSelect
} from 'antd';
import { MODALS, loadModal } from 'core/modals/duck';
import { CommentaryButton } from 'forms/OrderForm/OrderFormTables/DiagnosticTable';
import classFilterDataSource from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/VinDiagnostic/DiagnosticTableDrawer/classFilterDataSource';
import _, { get, uniqBy } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { GridIcon } from 'theme';
import { fetchAPI, filterTreeNodeByPart } from 'utils';

import {} from 'commons';
import { fetchVehicles, selectVehicles, setSearchQuery } from 'pages/VehiclesPage/redux/duck';
import styled from 'styled-components';
import Styles from './styles.m.css';

const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

const { TreeNode } = Tree;
const { Search } = Input;

const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.row) {
            if (
                node.row.some(
                    item => item.$.quickgroupid === key
                    // item => item.id === key || item.parentGroupId === key,
                )
            ) {
                parentKey = node.$.quickgroupid;
            } else if (getParentKey(key, node.row)) {
                parentKey = getParentKey(key, node.row);
            }
        }
    }

    return Number.isInteger(parentKey) ? String(parentKey) : parentKey;
};

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    modalProps: state.modals.modalProps,
    vehicles: selectVehicles(state)
});

const mapDispatchToProps = {
    loadModal,
    setSearchQuery,
    fetchVehicles
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class VinCodeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayType: 'list',
            categoryMode: false,
            categories: [],
            loading: false,
            tableLoading: false,
            zoomed: false,
            itemsInfo: [],
            blockPositions: [],
            tableHoverCode: undefined,
            imgHoverCode: undefined,
            imgHoverIndex: undefined,
            checkedCodes: [],
            infoModalVisible: false,
            infoItem: undefined,
            imageList: [],
            image: undefined,
            itemsListEmpty: false,
            zoomMultiplier: 0.75,
            allCategories: [],
            listItemPreview: [],
            previewIndex: undefined,
            variantRadioValue: 0,
            index: 0,
            filters: { placeName: '' },
            relatedTableFilters: { name: '' },
            vinTree: [],
            searchValue: '',
            vinTreeFlatten: [],
            responseInfo: []
        };

        this.clickedBlockRef = React.createRef();

        this.columns = () => [
            {
                title: '№',
                key: 'key',
                dataIndex: 'key',
                width: 'auto',
                render: (data, elem, ind) => {
                    const { checkedCodes } = this.state;
                    const itemsInfo = this.state.itemsInfo.length
                        ? this.state.itemsInfo[this.state.itemsInfo.length - 1]
                        : [];
                    const isChecked =
                        checkedCodes.findIndex(({ code: cd, key }) => cd === elem.codeonimage && key === data) >= 0;
                    const variants = itemsInfo.filter(item => item.codeonimage === elem.codeonimage);

                    const isVariant = elem.key > variants[0].key;
                    const outputText = isVariant ? 'Var.' : elem.codeonimage;

                    return (
                        <span
                            style={{
                                color: isVariant ? 'var(--text2)' : 'var(--text1)',
                                fontSize: 12
                            }}
                        >
                            <Checkbox checked={isChecked} />
                            {'  '}
                            {outputText}
                        </span>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'oem',
                width: 'auto',
                render: row => {
                    return <div style={{ borderBottom: 'none', display: 'inline-block' }}>{get(row, 'oem')}</div>;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'name',
                dataIndex: 'name',
                width: 'auto',
                // filterDropdown: () => {
                //     return (
                //         <PaddedWrapper>
                //             <Select
                //                 allowClear
                //                 filterOption={filterByPart}
                //                 getPopupContainer={trigger => trigger.parentNode}
                //                 mode='multiple'
                //                 onChange={selectValue => this.handleRelatedFiltersChange({ name: selectValue })}
                //                 placeholder={this.props.intl.formatMessage({
                //                     id: 'order_form_table.diagnostic.filters.node.placeholder'
                //                 })}
                //                 style={{ minWidth: 300, maxWidth: 500 }}
                //                 value={this.state.relatedTableFilters.name}
                //             >
                //                 {this.state.relatedFilterParams.relatedNames.map(name => {
                //                     return (
                //                         <Option key={name} value={name}>
                //                             {name}
                //                         </Option>
                //                     );
                //                 })}
                //             </Select>
                //         </PaddedWrapper>
                //     );
                // },
                render: (data, elem) => {
                    return (
                        <div>
                            <p>{data}</p>
                            <p style={{ fontSize: 12, color: 'var(--text2)' }}>
                                {(elem.attributes || []).map(attr => `${attr.name}: ${attr.value}`).join('; ')}
                            </p>
                        </div>
                    );
                }
            }
        ];

        this.detailsColumns = () => [
            {
                title: '№',
                key: 'key',
                width: 'auto',
                render: row => (
                    <div style={{ borderBottom: 'none', display: 'inline-block' }}>{get(row, '$.codeonimage')}</div>
                )
            },
            {
                title: <FormattedMessage id='OEM' />,
                key: 'oem',
                width: 'auto',
                render: row => {
                    return <div style={{ borderBottom: 'none', display: 'inline-block' }}>{get(row, '$.oem')}</div>;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'name',
                width: 'auto',
                render: row => {
                    return <div style={{ borderBottom: 'none', display: 'inline-block' }}>{get(row, '$.name')}</div>;
                }
            },
            {
                key: 'action',
                width: 'auto',
                render: row => {
                    return (
                        <div style={{ borderBottom: 'none', display: 'inline-block' }}>
                            <Button icon={<CheckOutlined />} onClick={() => this.singleTreeOperation(row.$)} />
                        </div>
                    );
                }
            }
        ];
    }

    putDiagnosticData = async ({
        imgId,
        orderId,
        diagnosticId,
        partAnswer,
        partOECode,
        createItem = false,
        partPhotosHashes,
        partComment
    }) => {
        const ids = await fetchAPI(
            'PUT',
            'order_diagnostic',
            null,
            {
                imgId,
                orderId,
                partsData: [
                    {
                        rowId: diagnosticId,
                        answer: partAnswer,
                        oeCode: partOECode,
                        photoHashes: partPhotosHashes,
                        comment: partComment
                    }
                ],
                createItem
            },
            {
                handleErrorInternally: true
            }
        );
        await this.props.modalProps.fetchDiagnosticData();
        this.setState({ loading: false });

        return get(ids, 'update', []);
    };

    deleteDiagnosticData = async ({ templateId, orderId, imgId, groupId, systemId, diagnosticId }) => {
        await fetchAPI(
            'DELETE',
            'order_diagnostic',
            null,
            {
                orderId,
                imgId,
                templateId,
                groupIds: groupId ? [groupId] : undefined,
                rowIds: diagnosticId ? [diagnosticId] : undefined,
                systemIds: systemId ? [systemId] : undefined
            },
            { handleErrorInternally: true }
        );
        const { relatedParts } = this.props.modalProps;
        this.props.modalProps.relatedParts = relatedParts.filter(({ diagnosticId: dgId }) => dgId !== diagnosticId);
        await this.props.modalProps.fetchDiagnosticData();
        this.setState({ loading: false });
    };

    onImgLoad = ({ target: img }) => {
        if (this.state.imageList.length) {
            this.state.imageList[this.state.imageList.length - 1].height = img.naturalHeight;
            this.state.imageList[this.state.imageList.length - 1].width = img.naturalWidth;
        }
        this.setState({});
    };

    handleStatusChange = (rowStatus, row) => {
        row.partAnswer = row.partAnswer == rowStatus ? 0 : rowStatus;
        this.putDiagnosticData({
            ...row
        });
    };

    handleFiltersChange = filterObj => {
        this.setState(state => ({ ...state, filters: { ...state.filters, ...filterObj } }));
    };

    handleRelatedFiltersChange = filterObj => {
        this.setState(state => ({ ...state, relatedTableFilters: { ...state.relatedTableFilters, ...filterObj } }));
    };

    handleOk = async () => {
        const {
            storeGroupId,
            vin,
            saveSearchHistoryVin,
            modalProps: { setVinDetail, setVinDetails, onClose, existingOECode },
            setSearchQuery,
            fetchVehicles,
            vehicles
        } = this.props;
        const { stateStoreGroupId, checkedCodes, selectedRows, displayType } = this.state;

        const itemsInfo = this.state.itemsInfo.length ? this.state.itemsInfo[this.state.itemsInfo.length - 1] : [];

        if (checkedCodes.length === 1) {
            const selectedItem = itemsInfo.find(
                item => item.key === checkedCodes[0].key && item.codeonimage === checkedCodes[0].code
            );
            setVinDetail && setVinDetail({ ...selectedItem, storeGroupId: stateStoreGroupId || storeGroupId });
        } else if (setVinDetails) {
            if (displayType !== 'tree') {
                setVinDetails(
                    checkedCodes.map(({ code, key }) => {
                        const selectedItem = itemsInfo.find(item => item.key === key && item.codeonimage === code);

                        console.log(selectedItem, 'selectedItem');

                        return selectedItem;
                    })
                );
            } else {
                setVinDetails(selectedRows.map(({ $ }) => $));
            }
        }

        // const vehicle = get(vehicles, 'clientsVehicles.[0]');

        // const vehicle = get(vehicles, '[0]', []);

        // if (vin && saveSearchHistoryVin) {
        //     saveSearchHistoryVin(vin, `${vehicle && vehicle.make} ${vehicle && vehicle.model}`);
        // }

        this.handleCancel();
    };

    singleTreeOperation = async row => {
        const {
            modalProps: { setVinDetail, setVinDetails, onClose, existingOECode }
        } = this.props;
        const { stateStoreGroupId, checkedCodes, selectedRows, displayType } = this.state;

        setVinDetail && setVinDetail({ ...row });

        this.handleCancel();
    };

    handleCancel = () => {
        const {
            resetModal,
            loadModal,
            modalProps: { modalToLoad, onClose }
        } = this.props;
        this.setState({
            displayType: 'list',
            categoryMode: false,
            categories: [],
            loading: false,
            zoomed: false,
            itemsInfo: [],
            blockPositions: [],
            tableHoverCode: undefined,
            imgHoverCode: undefined,
            imgHoverIndex: undefined,
            checkedCodes: [],
            infoModalVisible: false,
            infoItem: undefined,
            imageList: [],
            image: undefined,
            itemsListEmpty: false,
            zoomMultiplier: 0.75,
            allCategories: [],
            listItemPreview: [],
            previewIndex: undefined,
            variantRadioValue: 0,
            stateStoreGroupId: undefined,
            index: 0,
            detailsWithStoreGroup: undefined,
            selectedDiagnosticIndex: undefined,
            responseInfo: [],
            selectedRowKeys: [],
            selectedRows: [],
            searchValue: ''
        });
        if (modalToLoad) {
            loadModal(modalToLoad);
        } else {
            if (onClose) onClose();

            resetModal();
        }
    };

    handleBack = () => {
        const {
            modalProps: { storeGroupId: propsStoreGroupId }
        } = this.props;
        const { stateStoreGroupId } = this.state;
        const storeGroupId = propsStoreGroupId || stateStoreGroupId;
        if (this.state.categoryMode && !storeGroupId) {
            this.setState({
                categories: this.state.allCategories,
                categoryMode: true
            });
        } else if (this.state.itemsInfo.length > 1) {
            const itemsInfo = this.state.itemsInfo.pop();
            const blockPositions = this.state.blockPositions.pop();
            const imageList = this.state.imageList.pop();
            this.setState({
                itemsInfo,
                blockPositions,
                imageList,
                checkedCodes: []
            });
        } else {
            this.setState({
                categoryMode: true,
                checkedCodes: []
            });
        }
    };

    fetchVinGroups = async () => {
        const { vin } = this.props;
        const response = await fetchAPI('GET', '/vin/tree', { vin }, null, {
            handleErrorInternally: true
        });

        this.onFlatten(response);

        this.setState({
            vinTree: response
        });
    };

    fetchVinGroupsInfo = async quickgroupid => {
        const { vin } = this.props;
        const { vinTreeFlatten } = this.state;
        const exactId = vinTreeFlatten.find(({ id }) => id == quickgroupid);

        if (get(exactId, 'link') === 'true') {
            const responseInfo = await fetchAPI(
                'GET',
                '/vin/tree_quick_detail',
                { vin, quickGroupId: Number(quickgroupid) },
                null,
                {
                    handleErrorInternally: true
                }
            );

            this.setState({
                responseInfo
            });
        } else {
            this.setState({
                responseInfo: []
            });
        }
    };

    fetchData = async (showAll = false) => {
        await this.setState({
            loading: false
        });
        const {
            vin: propsVin,
            modalProps: { storeGroupId: propsStoreGroupId, vin: modalVin }
        } = this.props;
        const { stateStoreGroupId } = this.state;
        const storeGroupId = stateStoreGroupId || propsStoreGroupId;
        const vin = propsVin || modalVin;

        if (storeGroupId && !showAll) {
            try {
                const data = await fetchAPI(
                    'GET',
                    'vin/list_quick_detail',
                    {
                        vin,
                        storeGroupIds: storeGroupId ? `[${[storeGroupId].flat().filter(e => e)}]` : ''
                    },
                    null,
                    {
                        handleErrorInternally: true
                    }
                );
                if (data) {
                    const { catalog, ssd, vehicleid } = data.vehicle;
                    const categoriesArray = data.categories;
                    const normalizedCategories = [];

                    if (categoriesArray.length) {
                        categoriesArray.map(elem => {
                            if (elem.units.length == 1) {
                                elem.name = elem.units[0].name;
                                normalizedCategories.push({
                                    catalog,
                                    vehicleId: vehicleid,
                                    ssd,
                                    ...elem,
                                    unit: { ...elem.units[0] }
                                });
                            } else {
                                elem.units.map(unit => {
                                    elem.name = unit.name;
                                    normalizedCategories.push({
                                        catalog,
                                        vehicleId: vehicleid,
                                        ssd,
                                        ...elem,
                                        unit
                                    });
                                });
                            }
                        });
                    }
                    if (normalizedCategories.length == 1) {
                        this.setState({
                            loading: false,
                            categoryMode: false,
                            categories: normalizedCategories,
                            displayType: 'grid'
                        });
                        this.fetchItemsList(
                            normalizedCategories[0].unit.ssd,
                            normalizedCategories[0].unit.unitid,
                            normalizedCategories[0].catalog
                        );
                    } else {
                        for (let i = 0; i < normalizedCategories.length % 3; i++) {
                            normalizedCategories.push({
                                emptyElement: true
                            });
                        }
                        this.setState({
                            loading: false,
                            displayType: 'grid',
                            categoryMode: normalizedCategories.length,
                            categories: normalizedCategories
                        });
                    }
                } else {
                    this.setState({
                        loading: false,
                        itemsListEmpty: true
                    });
                }
            } catch (e) {
                this.setState({
                    loading: false,
                    itemsListEmpty: true
                });
            }
        } else {
            try {
                const response = await fetchAPI('GET', 'vin/categories', { vin }, null, {
                    handleErrorInternally: true
                });
                const data = get(response, 'data');
                if (data) {
                    const { catalog, ssd, vehicleid } = data.response.FindVehicle.response.FindVehicle[0].row[0].$;
                    const categoriesArray = data.response.ListCategories[0].row;
                    const normalizedCategories = [];

                    if (categoriesArray.length) {
                        categoriesArray.map(elem => {
                            normalizedCategories.push({
                                catalog,
                                vehicleId: vehicleid,
                                ...elem.$,
                                unit: { ...elem.$ }
                            });
                        });
                    }
                    if (normalizedCategories.length == 1) {
                        this.setState({
                            loading: false,
                            categoryMode: false,
                            categories: normalizedCategories
                        });
                        this.fetchItemsList(
                            normalizedCategories[0].unit.ssd,
                            normalizedCategories[0].unit.unitid,
                            normalizedCategories[0].catalog
                        );
                    } else {
                        if (this.state.displayType == 'grid') {
                            for (let i = 0; i < normalizedCategories.length % 5; i++) {
                                normalizedCategories.push({
                                    emptyElement: true
                                });
                            }
                        }
                        this.setState({
                            loading: false,
                            categoryMode: normalizedCategories.length,
                            categories: normalizedCategories,
                            allCategories: normalizedCategories
                        });
                    }

                    if (this.state.displayType == 'list' && normalizedCategories.length) {
                        this.fetchCategoryItemsList(
                            normalizedCategories[0].ssd,
                            normalizedCategories[0].catalog,
                            normalizedCategories[0].categoryid,
                            normalizedCategories[0].vehicleId,
                            true,
                            0
                        );
                    }
                } else {
                    this.setState({
                        loading: false,
                        itemsListEmpty: true
                    });
                }
            } catch (e) {
                this.setState({
                    loading: false,
                    itemsListEmpty: true
                });
            }
        }
        this.setState({ tableLoading: false });
    };

    fetchItemsList = async (ssd, unitId, catalog) => {
        await this.setState({
            loading: true
        });
        try {
            const index = {};

            const response = await fetchAPI(
                'GET',
                'vin/list_detail_by_unit_id',
                {
                    ssd,
                    unitId,
                    catalog
                },
                null,
                { handleErrorInternally: true }
            );
            const data = get(response, 'data');
            if (data) {
                const itemsInfo = [];
                const blockPositions = [];
                const image = get(data.response.GetUnitInfo, '[0].row[0].$');
                get(data.response.ListDetailsByUnit, '[0].row', []).map(({ $: tm, attribute }, key) => {
                    const item = { ...tm };

                    if (item.oem) {
                        itemsInfo.push({
                            key,
                            ...item,
                            attributes: attribute && attribute.map(attr => attr.$)
                        });
                    }
                });
                get(data.response.ListImageMapByUnit, '[0].row', []).map(({ $: position }, key) => {
                    blockPositions.push({
                        ...position,
                        key
                    });
                });

                this.setState({
                    index,
                    loading: false,
                    imageList: [image],
                    itemsInfo: [itemsInfo],
                    blockPositions: [blockPositions],
                    categoryMode: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false,
                itemsInfo: [],
                blockPositions: [],
                categoryMode: false
            });
        }
    };

    fetchCategoryItemsList = async (ssd, catalog, categoryId, vehicleid, previewMode = false, index) => {
        if (!previewMode) {
            await this.setState({
                loading: true
            });
        } else {
            await this.setState({
                previewIndex: index
            });
        }
        try {
            const { data } = await fetchAPI('GET', 'vin/list_units', { ssd, vehicleid, catalog, categoryId }, null, {
                handleErrorInternally: true
            });
            if (data) {
                const categoriesArray = data.response.ListUnits[0].row;
                const normalizedCategories = [];

                if (categoriesArray.length) {
                    categoriesArray.map(elem => {
                        normalizedCategories.push({
                            catalog,
                            vehicleId: vehicleid,
                            ...elem.$,
                            unit: { ...elem.$ }
                        });
                    });
                }
                if (previewMode) {
                    for (let i = 0; i < normalizedCategories.length % 3; i++) {
                        normalizedCategories.push({
                            emptyElement: true
                        });
                    }
                    this.setState({
                        loading: false,
                        listItemPreview: normalizedCategories
                    });
                } else if (normalizedCategories.length == 1) {
                    this.setState({
                        loading: false,
                        categoryMode: false,
                        categories: normalizedCategories
                    });
                    this.fetchItemsList(
                        normalizedCategories[0].unit.ssd,
                        normalizedCategories[0].unit.unitid,
                        normalizedCategories[0].catalog
                    );
                } else {
                    for (let i = 0; i < normalizedCategories.length % 5; i++) {
                        normalizedCategories.push({
                            emptyElement: true
                        });
                    }
                    this.setState({
                        loading: false,
                        categoryMode: normalizedCategories.length,
                        categories: normalizedCategories
                    });
                }
            } else {
                this.setState({
                    loading: false,
                    itemsListEmpty: true
                });
            }
        } catch (e) {
            this.setState({
                loading: false,
                itemsInfo: [],
                blockPositions: [],
                categoryMode: true
            });
        }
    };

    searchImage = () => {
        this.setState({
            loading: true
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.modal !== this.props.modal && this.props.modal === MODALS.VIN_MODAL) {
            const { relatedParts } = this.props.modalProps;
            const { setSearchQuery, fetchVehicles, vin } = this.props;

            // if (vin) {
            //     setSearchQuery({
            //         query: _.toLower(vin.replace(/[+()]/g, ''))
            //     });
            //     fetchVehicles();
            // }

            this.fetchVinGroups();

            const uniqueParams = {
                uniquePlaceNames: uniqBy(relatedParts, 'placeName'),
                uniquePartNames: uniqBy(relatedParts, 'partName'),
                uniqueSystemNames: uniqBy(relatedParts, 'systemName'),
                uniqueRelatedNames: uniqBy(this.itemsInfo, 'name')
            };
            const { uniquePlaceNames, uniquePartNames, uniqueSystemNames } = uniqueParams;

            const filterParams = {
                systemNames: uniqueSystemNames.map(({ systemName }) => systemName),
                placeNames: uniquePlaceNames.map(({ placeName }) => placeName),
                partNames: uniquePartNames.map(({ partName }) => partName)
            };

            this.setState({
                loading: true,
                displayType: 'list',
                zoomMultiplier: this.props.isMobile ? 2 : 0.75,
                filterParams
            });
            this.fetchData();
        }
    }

    onFlatten = tree => {
        const flattenTree = [];
        const flattenStoreGroups = data => {
            for (let item = 0; item < _.get(data, 'length'); item++) {
                const node = data[item];

                flattenTree.push({
                    id: node.$.quickgroupid,
                    name: node.$.name,
                    link: node.$.link
                });
                if (node.row) {
                    flattenStoreGroups(node.row);
                }
            }
        };
        flattenStoreGroups(tree);

        this.setState({
            vinTreeFlatten: flattenTree,
            expandedRowKeys: flattenTree.map(({ id }) => id)
        });

        return flattenTree;
    };

    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false
        });
    };

    onSelect = async (keys, info) => {
        await this.fetchVinGroupsInfo(keys);
    };

    // debouncedFilterChange = filter => {
    //     const { vinTreeFlatten } = this.state;
    //     console.log(vinTreeFlatten, 'vinTreeFlatten');
    //     const foundMap = {};
    //     const found = vinTreeFlatten.filter(
    //         el => filterByPartGeneral(filter, el.name) || filterByPartGeneral(filter, el.id)
    //     );

    //     const findParents = el => {
    //         if (!foundMap[el.id]) {
    //             foundMap[el.id] = el;
    //             const parent = vinTreeFlatten.find(genEntry => genEntry.id === el.parentId);
    //             if (parent) findParents(parent);
    //         }
    //     };

    //     found.forEach(foundEntry => {
    //         findParents(foundEntry);
    //     });

    //     const res = Object.values(foundMap);

    //     if (filter.length > 0) {
    //         const maxLevel = Math.max(...found.map(({ level }) => level));
    //         this.setState({
    //             expandedKeys: res.filter(({ level }) => level < maxLevel).map(({ id }) => id),
    //             foundIds: found.map(({ id }) => id)
    //         });
    //     } else {
    //         this.setState({
    //             foundIds: [],
    //             expandedKeys: []
    //         });
    //     }
    // };

    onChange = e => {
        const { vinTreeFlatten } = this.state;
        const { value } = e.target;

        const searchWords =
            value &&
            value
                .toLowerCase()
                .split(' ')
                .filter(word => word);

        const expandedKeys = vinTreeFlatten
            .map(item => {
                // Check if all search words are present in the item name
                const isMatch =
                    searchWords && searchWords.every(word => String(item.name).toLowerCase().includes(word));
                if (isMatch) {
                    return getParentKey(item.id, this.state.vinTree);
                }
                if (!isMatch) {
                    this.setState({
                        expandedKeys: []
                    });
                }

                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);

        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true
        });
    };

    render() {
        const {
            isMobile,
            modal,
            detailsTreeData,
            modalProps: { storeGroupId: propsStoreGroupId, onClose },
            intl: { formatMessage }
        } = this.props;
        const {
            displayType,
            zoomed,
            tableHoverCode,
            imgHoverCode,
            imgHoverIndex,
            checkedCodes,
            infoItem,
            infoModalVisible,
            loading,
            tableLoading,
            categories,
            categoryMode,
            itemsListEmpty,
            zoomMultiplier,
            allCategories,
            listItemPreview,
            previewIndex,
            tableIsFolded,
            stateStoreGroupId,
            commentaryRow,
            searchValue,
            autoExpandParent,
            expandedKeys,
            responseInfo,
            selectedRowKeys,
            selectedRows
        } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        const loop = data =>
            data.map(item => {
                const searchValues = searchValue.toLowerCase().split(' ');

                const index = searchValues.every(val => String(item.$.name).toLowerCase().includes(val));

                const title =
                    index && searchValue !== '' ? (
                        <span style={{ color: '#f50' }}>{item.$.name}</span>
                    ) : (
                        <span>{item.$.name}</span>
                    );
                if (!_.isEmpty(item.row)) {
                    return (
                        <Leaf
                            key={String(item.$.quickgroupid)}
                            // icon={({ selected }) => (selected ? <CheckOutlined /> : null)}
                            title={title}
                        >
                            {loop(item.row)}
                        </Leaf>
                    );
                }

                return (
                    <Leaf
                        key={String(item.$.quickgroupid)}
                        icon={leaf => (leaf.selected ? <CheckOutlined /> : null)}
                        title={title}
                    />
                );
            });

        const storeGroupId = propsStoreGroupId || stateStoreGroupId;

        const columns = this.columns();

        const itemsInfo = this.state.itemsInfo.length ? this.state.itemsInfo[this.state.itemsInfo.length - 1] : [];
        const blockPositions = this.state.blockPositions.length
            ? this.state.blockPositions[this.state.blockPositions.length - 1]
            : [];
        const image = this.state.imageList.length ? this.state.imageList[this.state.imageList.length - 1] : undefined;

        const zoomActionBlock = (
            <div className={Styles.zoomActionBlock}>
                {zoomed ? (
                    <ZoomOutOutlined
                        onClick={() => {
                            this.setState({
                                zoomed: !zoomed
                            });
                        }}
                        style={{
                            fontSize: 24,
                            zIndex: 9999
                        }}
                    />
                ) : (
                    <ZoomInOutlined
                        onClick={() => {
                            this.setState({
                                zoomed: !zoomed
                            });
                        }}
                        style={{
                            fontSize: 24,
                            zIndex: 9999
                        }}
                    />
                )}
                <MinusOutlined
                    onClick={() => {
                        const res = zoomMultiplier - 0.1;
                        this.setState({ zoomMultiplier: res < 0.2 ? 0.2 : res });
                    }}
                    style={{
                        marginLeft: 15
                    }}
                />
                <Slider
                    dots
                    max={2.5}
                    min={0.2}
                    onChange={value => {
                        this.setState({ zoomMultiplier: value });
                    }}
                    step={0.1}
                    style={{
                        minWidth: 200,
                        margin: '0px 14px'
                    }}
                    value={zoomMultiplier}
                />
                <PlusOutlined
                    onClick={() => {
                        const res = zoomMultiplier + 0.1;
                        this.setState({ zoomMultiplier: res > 2.5 ? 2.5 : res });
                    }}
                />
            </div>
        );

        const disabled = displayType !== 'tree' ? checkedCodes.length === 0 && !onClose : !selectedRowKeys;

        return (
            <Modal
                footer={[
                    <Button
                        key='show_all'
                        data-qa='button_show_all_vin_code_modal'
                        onClick={() => {
                            this.fetchData(true);
                            this.setState({
                                itemsListEmpty: false,
                                stateStoreGroupId: undefined
                            });
                        }}
                    >
                        <FormattedMessage id='show_all' />
                    </Button>,
                    <Button
                        key='submit'
                        disabled={disabled}
                        onClick={() => {
                            this.handleOk();
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='select' />
                    </Button>
                ]}
                maskClosable={false}
                onCancel={() => {
                    this.handleCancel();
                }}
                onOk={this.handleOk}
                open={modal === MODALS.VIN_MODAL}
                style={{
                    maxWidth: '98%',
                    minWidth: displayType === 'list' ? '85%' : '70%'
                }}
                title={<FormattedMessage id='add_order_form.vin' />}
                width='fit-content'
                zIndex={3000}
            >
                {!loading &&
                    !itemsListEmpty &&
                    categoryMode &&
                    !stateStoreGroupId &&
                    allCategories &&
                    allCategories == categories && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '-16px 0 8px 0'
                            }}
                        >
                            <div style={{ fontSize: 18 }}>
                                {displayType === 'list' && categories.length > previewIndex
                                    ? categories[previewIndex].name
                                    : null}
                            </div>
                            {!isMobile && (
                                <React.Fragment>
                                    {displayType !== 'tree' && (
                                        <div>
                                            <TreeSelect
                                                allowClear
                                                data-qa='tree_select_storeGroupId_order_detail_modal'
                                                filterTreeNode={filterTreeNodeByPart}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                onChange={async stateStoreGroupId => {
                                                    await this.setState({ stateStoreGroupId });
                                                    this.fetchData();
                                                }}
                                                placeholder={formatMessage({
                                                    id: 'order_form_table.store_group'
                                                })}
                                                popupMatchSelectWidth={320}
                                                showSearch
                                                style={{
                                                    minWidth: 320
                                                }}
                                                treeData={detailsTreeData}
                                                value={stateStoreGroupId}
                                            />
                                        </div>
                                    )}
                                    <Radio.Group
                                        buttonStyle='solid'
                                        onChange={event => {
                                            this.setState({
                                                displayType: event.target.value
                                            });
                                        }}
                                        value={displayType}
                                    >
                                        <Radio.Button data-qa='button_view_tree_vin_code_modal' value='tree'>
                                            <FormattedMessage id='order_form_table.vin_tree' />
                                        </Radio.Button>
                                        <Radio.Button data-qa='button_view_unordered_list_vin_code_modal' value='list'>
                                            <UnorderedListOutlined
                                                style={{
                                                    fontSize: 18,
                                                    verticalAlign: 'middle'
                                                }}
                                            />
                                        </Radio.Button>
                                        <Radio.Button data-qa='button_view_grid_vin_code_modal' value='grid'>
                                            <GridIcon
                                                style={{
                                                    fontSize: 18,
                                                    verticalAlign: 'middle'
                                                }}
                                            />
                                        </Radio.Button>
                                    </Radio.Group>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                {!loading &&
                    !itemsListEmpty &&
                    (!categoryMode || (!storeGroupId && allCategories && allCategories != categories)) && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: isMobile ? 'space-between' : 'flex-end',
                                margin: '-16px 0 8px 0'
                            }}
                        >
                            {isMobile && (
                                <Button
                                    className={Styles.foldBtn}
                                    icon={
                                        <LegacyIcon
                                            type={tableIsFolded ? 'vertical-align-bottom' : 'vertical-align-top'}
                                        />
                                    }
                                    onClick={() => {
                                        this.setState({
                                            tableIsFolded: !tableIsFolded,
                                            zoomMultiplier: !tableIsFolded ? 2 : zoomMultiplier
                                        });
                                    }}
                                    type={tableIsFolded ? 'primary' : 'default'}
                                />
                            )}

                            <div>
                                <Button key='back' onClick={this.handleBack}>
                                    <FormattedMessage id='step_back' />
                                </Button>
                                <Button
                                    key='submit'
                                    disabled={checkedCodes.length === 0 && !onClose}
                                    onClick={this.handleOk}
                                    style={{ marginLeft: 10 }}
                                    type='primary'
                                >
                                    <FormattedMessage id='select' />
                                </Button>
                            </div>
                        </div>
                    )}
                {loading && !tableLoading ? (
                    <Spin indicator={spinIcon} />
                ) : categoryMode && !itemsListEmpty ? (
                    <div>
                        <div className={displayType !== 'tree' ? `${Styles.categoryList}` : undefined}>
                            {displayType == 'grid' ? (
                                categories.length ? (
                                    categories
                                        // .filter(category => get(category, 'unit.detail.length'))
                                        .map((category, key) => {
                                            return category.emptyElement ? (
                                                <div
                                                    key={`categories-${key}-emptyElement`}
                                                    className={Styles.emptyItem}
                                                    style={{ pointerEvents: 'none' }}
                                                />
                                            ) : (
                                                <div
                                                    key={`categories-${key}-${category.unit.unitid}`}
                                                    className={Styles.categoryItem}
                                                    onClick={() => {
                                                        this.setState({
                                                            detailsWithStoreGroup: _.get(category, 'unit.detail', [])
                                                        });
                                                        if (category.unit.imageurl) {
                                                            this.fetchItemsList(
                                                                category.unit.ssd,
                                                                category.unit.unitid,
                                                                category.catalog
                                                            );
                                                        } else {
                                                            this.fetchCategoryItemsList(
                                                                category.ssd,
                                                                category.catalog,
                                                                category.categoryid,
                                                                category.vehicleId
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {category.unit.imageurl && (
                                                        <img
                                                            alt={category.unit.name}
                                                            src={category.unit.imageurl.replace('%size%', 'source')}
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: '100%'
                                                            }}
                                                            title={category.unit.name}
                                                        />
                                                    )}
                                                    <div className={Styles.categoryName}>{category.name}</div>
                                                </div>
                                            );
                                        })
                                ) : (
                                    <div>Відсутні дані</div>
                                )
                            ) : displayType === 'tree' ? (
                                <div>
                                    <Search
                                        onChange={this.onChange}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'storage.search_for_product_group'
                                        })}
                                        style={{ marginBottom: 8 }}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <StyledTree
                                            autoExpandParent={autoExpandParent}
                                            expandedKeys={expandedKeys}
                                            onExpand={this.onExpand}
                                            onSelect={this.onSelect}
                                            showIcon
                                        >
                                            {/* {loop(gData)} */}
                                            {loop(this.state.vinTree)}
                                        </StyledTree>
                                        <Space direction='vertical'>
                                            {responseInfo.map(elem =>
                                                elem.Unit.map(({ $, Detail }, index) => (
                                                    <Card
                                                        title={
                                                            <p
                                                                onClick={() => {
                                                                    console.log(elem);
                                                                    this.fetchItemsList($.ssd, $.unitid, $.catalog);
                                                                }}
                                                                style={{
                                                                    textDecoration: 'underline',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                {`${index + 1}. ${$.code}: ${$.name}`}
                                                            </p>
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex'
                                                            }}
                                                        >
                                                            <Image
                                                                src={$.imageurl.replace('%size%', 'source')}
                                                                style={{
                                                                    margin: 8,
                                                                    maxWidth: 320
                                                                }}
                                                            />
                                                            <Flex vertical>
                                                                <Table
                                                                    columns={this.detailsColumns()}
                                                                    dataSource={[
                                                                        Detail.find(({ $ }) => $.match === 't') ||
                                                                            Detail[0]
                                                                    ]}
                                                                    pagination={false}
                                                                    rowKey={record => record.$.codeonimage}
                                                                    rowSelection={rowSelection}
                                                                    size='small'
                                                                />

                                                                <Collapse
                                                                    items={[
                                                                        {
                                                                            key: '1',
                                                                            label: (
                                                                                <FormattedMessage id='order_form_table.other' />
                                                                            ),
                                                                            children: (
                                                                                <Table
                                                                                    columns={this.detailsColumns()}
                                                                                    dataSource={(() => {
                                                                                        const firstMatchIndex =
                                                                                            Detail.findIndex(
                                                                                                ({ $ }) =>
                                                                                                    $.match === 't'
                                                                                            );

                                                                                        return Detail.filter(
                                                                                            (_, index) =>
                                                                                                firstMatchIndex !==
                                                                                                index
                                                                                        );
                                                                                    })()}
                                                                                    pagination={false}
                                                                                    rowKey={record =>
                                                                                        record.$.codeonimage
                                                                                    }
                                                                                    rowSelection={rowSelection}
                                                                                    size='small'
                                                                                />
                                                                            )
                                                                        }
                                                                    ]}
                                                                />
                                                            </Flex>
                                                        </div>
                                                    </Card>
                                                )))}
                                        </Space>
                                    </div>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <div className={Styles.allCategoriesList}>
                                        {categories.map((category, key) => {
                                            return category.emptyElement ? (
                                                <div
                                                    key={`categories-${key}-emptyElement`}
                                                    className={Styles.emptyItem}
                                                    style={{ pointerEvents: 'none' }}
                                                />
                                            ) : (
                                                <div
                                                    key={`categories-${key}-${category.categoryid}`}
                                                    className={Styles.categoryListItem}
                                                    onClick={() => {
                                                        this.fetchCategoryItemsList(
                                                            category.ssd,
                                                            category.catalog,
                                                            category.categoryid,
                                                            category.vehicleId,
                                                            true,
                                                            key
                                                        );
                                                    }}
                                                    style={
                                                        previewIndex == key
                                                            ? {
                                                                  backgroundColor: 'var(--db_reserve)'
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    <div className={Styles.categoryListName}>{category.name}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={Styles.previewBLock}>
                                        {listItemPreview.length == 0 ? (
                                            <FormattedMessage id='no_data' />
                                        ) : (
                                            listItemPreview.map((item, key) => {
                                                return item.emptyElement ? (
                                                    <div
                                                        key={`listItemPreview-${key}-emptyElement`}
                                                        className={Styles.emptyItem}
                                                        style={{ pointerEvents: 'none', width: '33%' }}
                                                    />
                                                ) : (
                                                    <div
                                                        key={`listItemPreview-${key}-${item.unit.unitid}`}
                                                        className={Styles.categoryItem}
                                                        onClick={() => {
                                                            this.fetchItemsList(
                                                                item.unit.ssd,
                                                                item.unit.unitid,
                                                                item.catalog
                                                            );
                                                        }}
                                                        style={{
                                                            width: '33%',
                                                            height: 'fit-content'
                                                        }}
                                                    >
                                                        <img
                                                            alt='img'
                                                            src={item.unit.imageurl.replace('%size%', 'source')}
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: '100%'
                                                            }}
                                                            title={item.unit.name}
                                                        />
                                                        <div className={Styles.categoryName}>{item.name}</div>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                ) : !itemsListEmpty ? (
                    <React.Fragment>
                        <div className={Styles.categoryTitle}>{image && image.name}</div>
                        <div className={Styles.vinModal}>
                            <div className={Styles.imgWrap}>
                                {!isMobile && zoomActionBlock}
                                <div
                                    className={Styles.zoomBlock}
                                    style={{
                                        width: `${100 * zoomMultiplier}%`
                                    }}
                                >
                                    {blockPositions.map((item, ind) => {
                                        const { code } = item;
                                        const mainItem = itemsInfo.find(elem => elem.codeonimage === code);
                                        const oem = get(mainItem, 'oem');
                                        const storeGroupId = get(mainItem, 'storeGroupId');
                                        if (!mainItem) {
                                            return null;
                                        }

                                        const key = get(mainItem, 'key');
                                        const title = mainItem ? mainItem.name : '';
                                        const isHovered =
                                            imgHoverCode === code || (imgHoverIndex && imgHoverIndex === key);
                                        const isChecked =
                                            checkedCodes.findIndex(
                                                ({ code: cd, key: k }) => cd === code && k === key
                                            ) >= 0;

                                        return (
                                            <div
                                                key={`blockPositions-${key}-${code}-${ind}`}
                                                ref={isChecked && this.clickedBlockRef}
                                                className={`${Styles.zoomBlockItem} ${
                                                    isHovered && Styles.hoveredItem
                                                } ${isChecked && Styles.checkedItem} ${!key && Styles.noKeyItem}`}
                                                onClick={async event => {
                                                    if (!isChecked) {
                                                        checkedCodes.push({ code, key });
                                                        this.setState({});
                                                    } else {
                                                        this.setState({
                                                            checkedCodes: checkedCodes.filter(
                                                                ({ code: cd }) => cd !== code
                                                            )
                                                        });
                                                    }
                                                }}
                                                onMouseEnter={event => {
                                                    this.setState({
                                                        tableHoverCode: code,
                                                        imgHoverIndex: key
                                                    });
                                                }}
                                                onMouseLeave={event => {
                                                    this.setState({
                                                        tableHoverCode: undefined,
                                                        imgHoverIndex: undefined
                                                    });
                                                }}
                                                style={{
                                                    left: `${(item.x1 / image.width) * 100}%`,
                                                    top: `${(item.y1 / image.height) * 100}%`,
                                                    width: `${((item.x2 - item.x1) / image.width) * 100}%`,
                                                    height: `${((item.y2 - item.y1) / image.height) * 100}%`
                                                }}
                                                title={title}
                                            />
                                        );
                                    })}
                                    <img
                                        alt='vin'
                                        onLoad={this.onImgLoad}
                                        src={`${image && image.imageurl.replace('%size%', 'source')}`}
                                        width='100%'
                                    />
                                    <Modal
                                        footer={[]}
                                        maskClosable={false}
                                        onCancel={() => {
                                            this.setState({
                                                zoomed: false
                                            });
                                        }}
                                        open={zoomed}
                                        title={image && image.name.toUpperCase()}
                                        width='85%'
                                    >
                                        <img
                                            alt='vin'
                                            src={`${image && image.imageurl.replace('%size%', 'source')}`}
                                            width='100%'
                                        />
                                    </Modal>
                                </div>
                            </div>
                            {isMobile && zoomActionBlock}
                            <div className={`${Styles.listWrap} ${tableIsFolded && 'folded'}`}>
                                <Table
                                    bordered
                                    columns={columns}
                                    dataSource={classFilterDataSource(this.state.relatedTableFilters, itemsInfo)}
                                    loading={loading}
                                    onRow={({ codeonimage: code, key }, rowIndex) => {
                                        return {
                                            onClick: event => {
                                                const isChecked =
                                                    checkedCodes.findIndex(
                                                        ({ code: cd, key: k }) => cd === code && k === key
                                                    ) >= 0;
                                                if (!isChecked) {
                                                    checkedCodes.push({ code, key });
                                                    this.setState({});
                                                } else {
                                                    this.setState({
                                                        checkedCodes: checkedCodes.filter(({ code: cd }) => cd !== code)
                                                    });
                                                }
                                            },
                                            onMouseEnter: event => {
                                                this.setState({
                                                    imgHoverCode: code
                                                });
                                            },
                                            onMouseLeave: event => {
                                                this.setState({
                                                    imgHoverCode: undefined
                                                });
                                            }
                                        };
                                    }}
                                    pagination={false}
                                    rowClassName={(record, rowIndex) => {
                                        const code = record.codeonimage;
                                        const isHovered = tableHoverCode === code;
                                        const isChecked =
                                            checkedCodes.findIndex(
                                                ({ code: cd, key: k }) => cd === code && k === record.key
                                            ) >= 0 || Boolean(record.preset);

                                        return `${Styles.listTableRow} ${isHovered && Styles.tableRowHovered} ${
                                            isChecked && Styles.checkedRow
                                        } ${
                                            record.relatedDiagnostic &&
                                            Styles[`tableRowStatus${Number(record.relatedDiagnostic.status)}`]
                                        } ${record.partAnswer === 4 && Styles.diagnosticTableDisabledRow}`;
                                    }}
                                    size='small'
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'none',
                                textAlign: 'end',
                                fontSize: 12,
                                color: 'var(--text2)'
                            }}
                        >
                            <i style={{ color: 'var(--required)' }}>* </i>Ctrl + click to select multiple item
                        </div>
                    </React.Fragment>
                ) : (
                    <FormattedMessage id='no_data' />
                )}
                <Modal
                    footer={[]}
                    maskClosable={false}
                    onCancel={() => {
                        this.setState({
                            infoModalVisible: false
                        });
                    }}
                    open={infoModalVisible}
                    title={infoItem && infoItem.name.toUpperCase()}
                    zIndex={3001}
                >
                    {infoItem && infoItem.body}
                </Modal>
                <CommentaryButton
                    data-qa='btn_comentary_diagnostic_table_order_page'
                    getCurrentDiagnostic={this.props.modalProps.fetchDiagnosticData}
                    handleClose={() => this.setState({ commentaryRow: undefined })}
                    onOk={partComment => {
                        commentaryRow.partComment = partComment;
                        this.putDiagnosticData({
                            ...commentaryRow,
                            partComment
                        });
                        this.setState({});
                    }}
                    rowProp={commentaryRow}
                />
            </Modal>
        );
    }
}

const StyledTree = styled(Tree)`
    &.ant-tree {
        overflow: scroll;

        .ant-tree-treenode-selected .ant-tree-title {
            margin-left: 24px;
        }
    }

    &.ant-tree li.ant-tree-treenode-selected .ant-tree-node-selected {
        display: inline-flex;
        position: relative;
        margin-right: 80px;
        border-radius: 2px;

        & .ant-tree-iconEle.ant-tree-icon__customize {
            position: absolute;
            right: -80px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: rgba(var(--secondaryRGB), 0.2);
        }
    }
`;

const Leaf = styled(TreeNode)`
    /* .ant-tree-node-selected {
        display: inline-flex;
        position: relative;
        margin-right: 80px;
        border-radius: 2px;
    } */

    /* .ant-tree-treenode-selected {
        display: inline-flex;
        position: relative;
        margin-right: 80px;
        border-radius: 2px;
    }

    .ant-tree-treenode-selected .ant-tree-iconEle.ant-tree-icon__customize {
        position: absolute;
        right: -80px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: rgba(var(--secondaryRGB), 0.2);
    }

    .ant-tree-treenode-selected .ant-tree-title {
        margin-left: 24px;
    } */
`;

export default VinCodeModal;
