export const SD_TAGS = {
    "doc": {
        "id": {
            "ua": "ідентифікатор",
            "en": "identifier",
            "cz": "identifikátor",
            "bg": "идентификатор"
        },
        "type": {
            "ua": "тип",
            "en": "type",
            "cz": "typ",
            "bg": "тип"
        },
        "status": {
            "ua": "статус",
            "en": "status",
            "cz": "status",
            "bg": "статус"
        },
        "supplierDocNumber": {
            "ua": "номер документа постачальника",
            "en": "supplier document number",
            "cz": "číslo dodavatelského dokumentu",
            "bg": "номер на документ на доставчика"
        },
        "createdDatetime": {
            "ua": "дата створення",
            "en": "creation date",
            "cz": "datum vytvoření",
            "bg": "дата на създаване"
        },
        "doneDatetime": {
            "ua": "дата завершення",
            "en": "completion date",
            "cz": "datum dokončení",
            "bg": "дата на приключване"
        },
        "recordDatetime": {
            "ua": "дата запису",
            "en": "record date",
            "cz": "datum záznamu",
            "bg": "дата на запис"
        },
        "paidDatetime": {
            "ua": "дата оплати",
            "en": "payment date",
            "cz": "datum platby",
            "bg": "дата на плащане"
        },
        "businessSupplierId": {
            "ua": "id ідентифікатор постачальника",
            "en": "supplier identifier id",
            "cz": "identifikátor dodavatele id",
            "bg": "ид на идентификатор на доставчика"
        },
        "sum": {
            "ua": "сума",
            "en": "amount",
            "cz": "částka",
            "bg": "сума"
        },
        "businessId": {
            "ua": "id ідентифікатор станції",
            "en": "station identifier id",
            "cz": "identifikátor stanice id",
            "bg": "ид на идентификатор на станцията"
        },
        "managerId": {
            "ua": "id ідентифікатор менеджера",
            "en": "manager identifier id",
            "cz": "identifikátor manažera id",
            "bg": "ид на идентификатор на мениджъра"
        },
        "warehouseId": {
            "ua": "id ідентифікатор складу",
            "en": "warehouse identifier id",
            "cz": "identifikátor skladu id",
            "bg": "ид на идентификатор на склада"
        },
        "documentType": {
            "ua": "тип документа",
            "en": "document type",
            "cz": "typ dokumentu",
            "bg": "тип на документа"
        },
        "counterpartBusinessSupplierId": {
            "ua": "id ідентифікатор контрагента постачальника",
            "en": "supplier business counterpart id",
            "cz": "identifikátor dodavatele obchodního partnera id",
            "bg": "ид на идентификатор на контрагента на доставчика"
        },
        "counterpartClientId": {
            "ua": "id ідентифікатор контрагента клієнта",
            "en": "client counterpart id",
            "cz": "identifikátor klientského obchodního partnera id",
            "bg": "ид на идентификатор на контрагента на клиента"
        },
        "counterpartWarehouseId": {
            "ua": "id ідентифікатор складу контрагента",
            "en": "counterpart warehouse id",
            "cz": "identifikátor skladu obchodního partnera id",
            "bg": "ид на идентификатор на склада на контрагента"
        },
        "counterpartEmployeeId": {
            "ua": "id ідентифікатор співробітника контрагента",
            "en": "employee counterpart id",
            "cz": "identifikátor zaměstnance obchodního partnera id",
            "bg": "ид на идентификатор на служителя на контрагента"
        },
        "operationCode": {
            "ua": "код операції",
            "en": "operation code",
            "cz": "kód operace",
            "bg": "код на операция"
        },
        "context": {
            "ua": "контекст",
            "en": "context",
            "cz": "kontext",
            "bg": "контекст"
        },
        "payUntilDatetime": {
            "ua": "дата оплати до",
            "en": "payment until date",
            "cz": "datum platby do",
            "bg": "дата на плащане до"
        },
        "sellingSum": {
            "ua": "сума продажу",
            "en": "selling amount",
            "cz": "částka prodeje",
            "bg": "сума на продажба"
        },
        "orderId": {
            "ua": "id ідентифікатор наряд-замовлення",
            "en": "order identifier id",
            "cz": "identifikátor objednávkového dokumentu id",
            "bg": "ид на идентификатор на наряд-поръчка"
        },
        "externalId": {
            "ua": "зовнішній id ідентифікатор",
            "en": "external identifier id",
            "cz": "externí identifikátor id",
            "bg": "външен ид на идентификатор"
        },
        "transactionId": {
            "ua": "id ідентифікатор транзакції",
            "en": "transaction identifier id",
            "cz": "identifikátor transakce id",
            "bg": "ид на идентификатор на транзакцията"
        },
        "comment": {
            "ua": "коментар",
            "en": "comment",
            "cz": "komentář",
            "bg": "коментар"
        },
        "businessSupplierRequisiteId": {
            "ua": "id ідентифікатор реквізитів бізнес-постачальника",
            "en": "business supplier requisites identifier id",
            "cz": "identifikátor příslušenství obchodního dodavatele id",
            "bg": "ид на идентификатор на реквизитите на бизнес-доставчика"
        },
        "clientRequisiteId": {
            "ua": "id ідентифікатор реквізитів клієнта",
            "en": "client requisites identifier id",
            "cz": "identifikátor příslušenství klienta id",
            "bg": "ид на идентификатор на реквизитите на клиента"
        },
        "businessRequisiteId": {
            "ua": "id ідентифікатор реквізитів бізнесу",
            "en": "business requisites identifier id",
            "cz": "identifikátor příslušenství podnikání id",
            "bg": "ид на идентификатор на реквизитите на бизнеса"
        },
        "externalApiOrderStatus": {
            "ua": "статус замовлення зовнішнього API",
            "en": "external API order status",
            "cz": "status objednávky externího API",
            "bg": "статус на поръчката на външния API"
        },
        "externalApiDocId": {
            "ua": "id ідентифікатор документа зовнішнього API",
            "en": "external API document id",
            "cz": "identifikátor dokumentu externího API id",
            "bg": "ид на идентификатор на документа на външния API"
        },
        "externalApiExpenseDocId": {
            "ua": "id ідентифікатор витратного документа зовнішнього API",
            "en": "external API expense document id",
            "cz": "identifikátor dokumentu výdajů externího API id",
            "bg": "ид на идентификатор на разходния документ на външния API"
        },
        "counterpartBusinessSupplierName": {
            "ua": "назва контрагента постачальника",
            "en": "supplier counterpart name",
            "cz": "název dodavatele obchodního partnera",
            "bg": "име на контрагента на доставчика"
        },
        "counterpartClientName": {
            "ua": "назва контрагента клієнта",
            "en": "client counterpart name",
            "cz": "název klienta obchodního partnera",
            "bg": "име на контрагента на клиента"
        },
        "counterpartClientPhones": {
            "ua": "телефони контрагента клієнта",
            "en": "client counterpart phones",
            "cz": "telefonní čísla klienta obchodního partnera",
            "bg": "телефони на контрагента на клиента"
        },
        "counterpartWarehouseName": {
            "ua": "назва складу контрагента",
            "en": "counterpart warehouse name",
            "cz": "název skladu obchodního partnera",
            "bg": "име на склада на контрагента"
        },
        "counterpartEmployeeName": {
            "ua": "ім'я співробітника контрагента",
            "en": "employee counterpart name",
            "cz": "jméno zaměstnance obchodního partnera",
            "bg": "име на служителя на контрагента"
        },
        "counterpartEmployeePhone": {
            "ua": "телефон співробітника контрагента",
            "en": "employee counterpart phone",
            "cz": "telefon zaměstnance obchodního partnera",
            "bg": "телефон на служителя на контрагента"
        },
        "documentNumber": {
            "ua": "номер документа",
            "en": "document number",
            "cz": "číslo dokumentu",
            "bg": "номер на документа"
        },
        "barcode": {
            "ua": "штрихкод",
            "en": "barcode",
            "cz": "čárový kód",
            "bg": "баркод"
        },
        "remainSum": {
            "ua": "залишкова сума",
            "en": "remaining amount",
            "cz": "zbývající částka",
            "bg": "остатъчна сума"
        },
        "sumTax": {
            "ua": "сума податку",
            "en": "tax amount",
            "cz": "částka daně",
            "bg": "сумата на данъка"
        },
        "showTax": {
            "ua": "показати податок",
            "en": "show tax",
            "cz": "ukázat daň",
            "bg": "показване на данъка"
        },
        "sellingSumTax": {
            "ua": "сума податку на продаж",
            "en": "sales tax amount",
            "cz": "částka daně z prodeje",
            "bg": "сумата на данъка на продажба"
        },
        "wrhAttribute": {
            "ua": "атрибут складу",
            "en": "warehouse attribute",
            "cz": "atribut skladu",
            "bg": "атрибут на склада"
        },
        "counterpartWhsAttribute": {
            "ua": "атрибут складу контрагента",
            "en": "counterpart warehouse attribute",
            "cz": "atribut skladu obchodního partnera",
            "bg": "атрибут на склада на контрагента"
        },
        "incomeOnlyWithCell": {
            "ua": "прихід лише з коміркою",
            "en": "incoming only with cell",
            "cz": "příjem pouze s buněk",
            "bg": "приход само с клетка"
        },
        "ordNum": {
            "ua": "номер замовлення",
            "en": "order number",
            "cz": "číslo objednávky",
            "bg": "номер на поръчка"
        },
        "businessSupplier": {
            "id": {
                "ua": "id ідентифікатор",
                "en": "identifier id",
                "cz": "identifikátor id",
                "bg": "ид на идентификатор"
            },
            "name": {
                "ua": "назва",
                "en": "name",
                "cz": "název",
                "bg": "име"
            },
            "businessId": {
                "ua": "ідентифікатор бізнесу",
                "en": "business identifier",
                "cz": "identifikátor podnikání",
                "bg": "идентификатор на бизнеса"
            },
            "contactName": {
                "ua": "ім'я контакту",
                "en": "contact name",
                "cz": "jméno kontaktu",
                "bg": "име на контакт"
            },
            "contactMiddlename": {
                "ua": "по батькові контакту",
                "en": "contact middle name",
                "cz": "střední jméno kontaktu",
                "bg": "презиме на контакт"
            },
            "contactSurname": {
                "ua": "прізвище контакту",
                "en": "contact surname",
                "cz": "příjmení kontaktu",
                "bg": "фамилия на контакт"
            },
            "phones": {
                "ua": "телефони",
                "en": "phones",
                "cz": "telefony",
                "bg": "телефони"
            },
            "emails": {
                "ua": "електронні адреси",
                "en": "emails",
                "cz": "e-mailové adresy",
                "bg": "електронни адреси"
            },
            "paymentRespite": {
                "ua": "відстрочка платежу",
                "en": "payment respite",
                "cz": "odklad platby",
                "bg": "отсрочка на плащането"
            },
            "comment": {
                "ua": "коментар",
                "en": "comment",
                "cz": "komentář",
                "bg": "коментар"
            },
            "type": {
                "ua": "тип",
                "en": "type",
                "cz": "typ",
                "bg": "тип"
            },
            "accountId": {
                "ua": "ідентифікатор облікового запису",
                "en": "account id",
                "cz": "identifikátor účtu id",
                "bg": "ид на идентификатор на акаунта"
            }
        },
        "manager": {
            "id": {
                "ua": "ідентифікатор",
                "en": "identifier",
                "cz": "identifikátor",
                "bg": "идентификатор"
            },
            "avatar": {
                "ua": "аватар",
                "en": "avatar",
                "cz": "avatar",
                "bg": "аватар"
            },
            "businessId": {
                "ua": "ідентифікатор бізнесу",
                "en": "business identifier",
                "cz": "identifikátor podnikání",
                "bg": "идентификатор на бизнеса"
            },
            "businessesAccess": {
                "ua": "доступ до бізнесів",
                "en": "business access",
                "cz": "přístup k podnikání",
                "bg": "достъп до бизнеси"
            },
            "email": {
                "ua": "електронна адреса",
                "en": "email",
                "cz": "e-mail",
                "bg": "електронна адреса"
            },
            "enabled": {
                "ua": "активовано",
                "en": "enabled",
                "cz": "aktivován",
                "bg": "активирано"
            },
            "isAdmin": {
                "ua": "є адміністратором",
                "en": "is admin",
                "cz": "je administrátorem",
                "bg": "е администратор"
            },
            "language": {
                "ua": "мова",
                "en": "language",
                "cz": "jazyk",
                "bg": "език"
            },
            "name": {
                "ua": "ім'я",
                "en": "name",
                "cz": "jméno",
                "bg": "име"
            },
            "phone": {
                "ua": "телефон",
                "en": "phone",
                "cz": "telefon",
                "bg": "телефон"
            },
            "partnerId": {
                "ua": "id ідентифікатор партнера",
                "en": "partner identifier id",
                "cz": "identifikátor partnera id",
                "bg": "ид на идентификатор на партньора"
            },
            "roleId": {
                "ua": "id ідентифікатор ролі",
                "en": "role identifier id",
                "cz": "identifikátor role id",
                "bg": "ид на идентификатор на ролята"
            },
            "surname": {
                "ua": "прізвище",
                "en": "surname",
                "cz": "příjmení",
                "bg": "фамилия"
            },
            "employeeId": {
                "ua": "id ідентифікатор співробітника",
                "en": "employee identifier id",
                "cz": "identifikátor zaměstnance id",
                "bg": "ид на идентификатор на служителя"
            },
            "isMechanic": {
                "ua": "є механіком",
                "en": "is mechanic",
                "cz": "je mechanik",
                "bg": "е механик"
            },
            "isCashier": {
                "ua": "є касиром",
                "en": "is cashier",
                "cz": "je pokladní",
                "bg": "е касиер"
            },
            "taxService": {
                "ua": "РРО",
                "en": "cash register",
                "cz": "Daňový úřad",
                "bg": "ДДС"
            },
            "phoneCode": {
                "code": {
                    "ua": "код",
                    "en": "code",
                    "cz": "kód",
                    "bg": "код"
                },
                "country": {
                    "ua": "країна",
                    "en": "country",
                    "cz": "země",
                    "bg": "страна"
                }
            },
            "medocUrl": {
                "ua": "url посилання",
                "en": "Medoc URL",
                "cz": "url odkaz",
                "bg": "url адрес"
            },
            "medocEdrpou": {
                "ua": "Код ЄДРПОУ",
                "en": "EDRPOU code",
                "cz": "IČO",
                "bg": "Код ЕДРПОУ"
            },
            "medocLogin": {
                "ua": "medoc Логін",
                "en": "medoc login",
                "cz": "medoc Přihlášení",
                "bg": "medoc Логин"
            },
            "medocDept": {
                "ua": "номер філії",
                "en": "branch number",
                "cz": "číslo pobočky",
                "bg": "номер на филиал"
            }
        },
        "docProducts": {
            "id": {
                "ua": "id ідентифікатор",
                "en": "identifier id",
                "cz": "identifikátor",
                "bg": "ид на идентификатор"
            },
            "orderId": {
                "ua": "ідентифікатор замовлення",
                "en": "order identifier",
                "cz": "identifikátor objednávky",
                "bg": "идентификатор на поръчката"
            },
            "orderNum": {
                "ua": "номер замовлення",
                "en": "order number",
                "cz": "číslo objednávky",
                "bg": "номер на поръчка"
            },
            "quantity": {
                "ua": "кількість",
                "en": "quantity",
                "cz": "množství",
                "bg": "количество"
            },
            "purchasePrice": {
                "ua": "ціна закупівлі",
                "en": "purchase price",
                "cz": "nákupní cena",
                "bg": "цена на закупуване"
            },
            "purchaseSum": {
                "ua": "сума закупівлі",
                "en": "purchase amount",
                "cz": "nákupní částka",
                "bg": "сума на покупка"
            },
            "sellingPrice": {
                "ua": "ціна продажу",
                "en": "selling price",
                "cz": "prodejní cena",
                "bg": "цена на продажба"
            },
            "sellingSum": {
                "ua": "сума продажу",
                "en": "selling amount",
                "cz": "částka prodeje",
                "bg": "сума на продажба"
            },
            "expenseFromId": {
                "ua": "ідентифікатор витратного рахунку",
                "en": "expense account identifier",
                "cz": "identifikátor výdajového účtu",
                "bg": "идентификатор на разходната сметка"
            },
            "productId": {
                "ua": "ідентифікатор продукту",
                "en": "product identifier",
                "cz": "identifikátor produktu",
                "bg": "идентификатор на продукта"
            },
            "docId": {
                "ua": "ідентифікатор документа",
                "en": "document identifier",
                "cz": "identifikátor dokumentu",
                "bg": "идентификатор на документа"
            },
            "measureUnit": {
                "ua": "одиниця виміру",
                "en": "measure unit",
                "cz": "měrná jednotka",
                "bg": "единица за измерване"
            },
            "returnQuantity": {
                "ua": "кількість повернення",
                "en": "return quantity",
                "cz": "množství vrácení",
                "bg": "количество на връщане"
            },
            "stockPrice": {
                "ua": "ціна складу",
                "en": "stock price",
                "cz": "cena skladu",
                "bg": "цена на склада"
            },
            "calculatedFrom": {
                "ua": "розраховано з",
                "en": "calculated from",
                "cz": "vypočítáno z",
                "bg": "изчислено от"
            },
            "ordersAppurtenancies": {
                "ua": "доповнення до замовлень",
                "en": "order supplemental",
                "cz": "doplnění objednávek",
                "bg": "допълнения към поръчките"
            },
            "supplierPartNumber": {
                "ua": "номер частини постачальника",
                "en": "supplier part number",
                "cz": "číslo dílu dodavatele",
                "bg": "номер на част от доставчика"
            },
            "addToAddress": {
                "ua": "додати до адреси",
                "en": "add to address",
                "cz": "přidat k adresám",
                "bg": "добави към адреса"
            },
            "getFromAddress": {
                "ua": "отримати з адреси",
                "en": "get from address",
                "cz": "získat z adres",
                "bg": "вземи от адреса"
            },
            "order": {
                "ua": "замовлення",
                "en": "order",
                "cz": "objednávka",
                "bg": "поръчка"
            },
            "uktz": {
                "ua": "Код УКТЗЕД",
                "en": "UKTZED code",
                "cz": "Kód UKTZED",
                "bg": "Код УКТЗЕД"
            },
            "comment": {
                "ua": "коментар",
                "en": "comment",
                "cz": "komentář",
                "bg": "коментар"
            },
            "docProductUnitId": {
                "ua": "id ідентифікатор документа одиниці продукції",
                "en": "product unit document identifier",
                "cz": "identifikátor dokumentu jednotky produktu",
                "bg": "идентификатор на документа на единицата на продукта"
            },
            "product": {
                "id": {
                    "ua": "id",
                    "en": "id",
                    "cz": "id",
                    "bg": "ид"
                },
                "groupId": {
                    "ua": "id групи товару",
                    "en": "product group identifier",
                    "cz": "identifikátor skupiny zboží",
                    "bg": "ид на групата на стоките"
                },
                "name": {
                    "ua": "назва",
                    "en": "name",
                    "cz": "název",
                    "bg": "име"
                },
                "code": {
                    "ua": "код з/ч",
                    "en": "part number",
                    "cz": "kód náhradního dílu",
                    "bg": "код на резервни части"
                },
                "brandId": {
                    "ua": "id бренда",
                    "en": "brand identifier id",
                    "cz": "identifikátor značky id",
                    "bg": "ид на марка"
                },
                "brandName": {
                    "ua": "Назва бренда",
                    "en": "Brand name",
                    "cz": "Název značky",
                    "bg": "Име на марката"
                },
                "unit": {
                    "en": {
                        "ua": "en",
                        "en": "en",
                        "cz": "en",
                        "bg": "en"
                    },
                    "ua": {
                        "ua": "ua",
                        "en": "ua",
                        "cz": "ua",
                        "bg": "ua"
                    }
                },
                "measureUnit": {
                    "ua": "одиниця виміру",
                    "en": "measure unit",
                    "cz": "měrná jednotka",
                    "bg": "единица за измерване"
                },
                "tradeCode": {
                    "ua": "Код УКТЗЕД",
                    "en": "UKTZED code",
                    "cz": "Kód UKTZED",
                    "bg": "Код УКТЗЕД"
                },
                "certificate": {
                    "ua": "сертифікат",
                    "en": "certificate",
                    "cz": "certifikát",
                    "bg": "сертификат"
                },
                "businessId": {
                    "ua": "id ідентифікатор бізнесу",
                    "en": "business identifier id",
                    "cz": "identifikátor podnikání id",
                    "bg": "идентификатор на бизнеса"
                },
                "defaultBusinessSupplierId": {
                    "ua": "id ідентифікатор стандартного постачальника бізнесу",
                    "en": "default business supplier identifier id",
                    "cz": "identifikátor standardního dodavatele podnikání id",
                    "bg": "идентификатор на стандартния доставчик на бизнеса"
                },
                "saveOnStock": {
                    "ua": "зберігати на складі",
                    "en": "save on stock",
                    "cz": "uchovávejte na skladech",
                    "bg": "съхранявайте на склада"
                },
                "min": {
                    "ua": "мінімум",
                    "en": "minimum",
                    "cz": "minimum",
                    "bg": "минимум"
                },
                "max": {
                    "ua": "максимум",
                    "en": "maximum",
                    "cz": "maximum",
                    "bg": "максимум"
                },
                "defaultWarehouseId": {
                    "ua": "id ідентифікатор стандартного складу",
                    "en": "default warehouse identifier id",
                    "cz": "identifikátor standardního skladu id",
                    "bg": "идентификатор на стандартния склад"
                },
                "multiplicity": {
                    "ua": "кратність",
                    "en": "multiplicity",
                    "cz": "násobnost",
                    "bg": "кратност"
                },
                "externalId": {
                    "ua": "зовнішній id ідентифікатор",
                    "en": "external identifier id",
                    "cz": "externí identifikátor id",
                    "bg": "външен ид идентификатор"
                },
                "height": {
                    "ua": "висота",
                    "en": "height",
                    "cz": "výška",
                    "bg": "височина"
                },
                "width": {
                    "ua": "ширина",
                    "en": "width",
                    "cz": "šířka",
                    "bg": "ширина"
                },
                "depth": {
                    "ua": "глибина",
                    "en": "depth",
                    "cz": "hloubka",
                    "bg": "дълбочина"
                },
                "volume": {
                    "ua": "об'єм",
                    "en": "volume",
                    "cz": "objem",
                    "bg": "обем"
                },
                "weight": {
                    "ua": "вага",
                    "en": "weight",
                    "cz": "hmotnost",
                    "bg": "тегло"
                },
                "length": {
                    "ua": "довжина",
                    "en": "length",
                    "cz": "délka",
                    "bg": "дължина"
                },
                "internalDiameter": {
                    "ua": "внутрішній діаметр",
                    "en": "internal diameter",
                    "cz": "vnitřní průměr",
                    "bg": "вътрешен диаметър"
                },
                "externalDiameter": {
                    "ua": "зовнішній діаметр",
                    "en": "external diameter",
                    "cz": "vnější průměr",
                    "bg": "външен диаметър"
                },
                "colorId": {
                    "ua": "id ідентифікатор кольору",
                    "en": "color identifier id",
                    "cz": "identifikátor barvy id",
                    "bg": "ид на идентификатора на цвета"
                },
                "material": {
                    "ua": "матеріал",
                    "en": "material",
                    "cz": "materiál",
                    "bg": "материал"
                },
                "comment": {
                    "ua": "коментар",
                    "en": "comment",
                    "cz": "komentář",
                    "bg": "коментар"
                },
                "condition": {
                    "ua": "стан",
                    "en": "condition",
                    "cz": "stav",
                    "bg": "състояние"
                },
                "usedInVehicles": {
                    "ua": "використовується в транспортних засобах",
                    "en": "used in vehicles",
                    "cz": "používá se v dopravních prostředcích",
                    "bg": "използва се в превозни средства"
                },
                "right": {
                    "ua": "право",
                    "en": "right",
                    "cz": "pravé",
                    "bg": "дясно"
                },
                "left": {
                    "ua": "ліво",
                    "en": "left",
                    "cz": "levé",
                    "bg": "ляво"
                },
                "front": {
                    "ua": "перед",
                    "en": "front",
                    "cz": "přední",
                    "bg": "преден"
                },
                "rear": {
                    "ua": "зад",
                    "en": "rear",
                    "cz": "zadní",
                    "bg": "зад"
                },
                "top": {
                    "ua": "верх",
                    "en": "top",
                    "cz": "vrchní",
                    "bg": "горен"
                },
                "bottom": {
                    "ua": "низ",
                    "en": "bottom",
                    "cz": "spodní",
                    "bg": "долен"
                },
                "external": {
                    "ua": "зовнішній",
                    "en": "external",
                    "cz": "vnější",
                    "bg": "външен"
                },
                "internal": {
                    "ua": "внутрішній",
                    "en": "internal",
                    "cz": "vnitřní",
                    "bg": "вътрешен"
                },
                "central": {
                    "ua": "центральний",
                    "en": "central",
                    "cz": "centrální",
                    "bg": "централен"
                },
                "cellAddresses": {
                    "ua": "адреси комірок",
                    "en": "cell addresses",
                    "cz": "adresy buněk",
                    "bg": "адреси на клетки"
                },
                "priceGroupNumber": {
                    "ua": "номер групи цін",
                    "en": "price group number",
                    "cz": "číslo cenové skupiny",
                    "bg": "номер на групата на цените"
                },
                "fixedSellingPrice": {
                    "ua": "фіксована ціна продажу",
                    "en": "fixed selling price",
                    "cz": "pevná prodejní cena",
                    "bg": "фиксирана цена на продажба"
                },
                "minSellingPrice": {
                    "ua": "мінімальна ціна продажу",
                    "en": "minimum selling price",
                    "cz": "minimální prodejní cena",
                    "bg": "минимална цена на продажба"
                },
                "fixedPurchasePrice": {
                    "ua": "фіксована ціна закупівлі",
                    "en": "fixed purchase price",
                    "cz": "pevná nákupní cena",
                    "bg": "фиксирана цена на покупка"
                },
                "getSellingPriceFrom": {
                    "ua": "отримати ціну продажу з",
                    "en": "get selling price from",
                    "cz": "získat cenu prodeje z",
                    "bg": "вземете цената на продажба от"
                },
                "getPurchasePriceFrom": {
                    "ua": "отримати ціну закупівлі з",
                    "en": "get purchase price from",
                    "cz": "získat cenu nákupu z",
                    "bg": "вземете цената на покупка от"
                },
                "isRound": {
                    "ua": "округлений",
                    "en": "is round",
                    "cz": "zaokrouhlený",
                    "bg": "закръглен"
                },
                "decimalPlaces": {
                    "ua": "десяткові знаки",
                    "en": "decimal places",
                    "cz": "desetinná místa",
                    "bg": "десетични знаци"
                },
                "productUnitId": {
                    "ua": "ідентифікатор одиниці продукту",
                    "en": "product unit identifier",
                    "cz": "identifikátor jednotky produktu",
                    "bg": "идентификатор на единицата на продукта"
                },
                "enabled": {
                    "ua": "активний",
                    "en": "active",
                    "cz": "aktivní",
                    "bg": "активен"
                },
                "markupGroupId": {
                    "ua": "ідентифікатор групи націнок",
                    "en": "markup group identifier",
                    "cz": "identifikátor skupiny příplatků",
                    "bg": "идентификатор на групата на надбавките"
                },
                "source": {
                    "ua": "джерело",
                    "en": "source",
                    "cz": "zdroj",
                    "bg": "източник"
                },
                "purchaseCurrencyId": {
                    "ua": "ідентифікатор валюти закупівлі",
                    "en": "purchase currency identifier",
                    "cz": "identifikátor měny nákupu",
                    "bg": "идентификатор на валутата на закупуването"
                },
                "sellingCurrencyId": {
                    "ua": "ідентифікатор валюти продажу",
                    "en": "selling currency identifier",
                    "cz": "identifikátor měny prodeje",
                    "bg": "идентификатор на валутата на продажба"
                },
                "purchasePriceInCurrency": {
                    "ua": "ціна закупівлі у валюті",
                    "en": "purchase price in currency",
                    "cz": "cena nákupu v měně",
                    "bg": "цена на покупка в валута"
                },
                "sellingPriceInCurrency": {
                    "ua": "ціна продажу у валюті",
                    "en": "selling price in currency",
                    "cz": "cena prodeje v měně",
                    "bg": "цена на продажба в валута"
                },
                "storageHashes": {
                    "ua": "хеші зберігання",
                    "en": "storage hashes",
                    "cz": "hash pro skladování",
                    "bg": "хешове за съхранение"
                },
                "brand": {
                    "id": {
                        "ua": "id",
                        "en": "id",
                        "cz": "id",
                        "bg": "ид"
                    },
                    "$name": {
                        "ua": "Назва бренду",
                        "en": "Brand name",
                        "cz": "Název značky",
                        "bg": "Име на марката"
                    },
                    "$supplierId": {
                        "ua": "id постачальника",
                        "en": "supplier id",
                        "cz": "id dodavatele",
                        "bg": "ид на доставчика"
                    }
                }
            },
            "$status": {
                "ua": "статус",
                "en": "status",
                "cz": "stát",
                "bg": "статус"
            },
            "$stockSum": {
                "ua": "сума на складі",
                "en": "stock amount",
                "cz": "částka na skladě",
                "bg": "сума на склада"
            },
            "$displayCode": {
                "ua": "код відображення",
                "en": "display code",
                "cz": "kód zobrazení",
                "bg": "код на показване"
            }
        },
        "docServices": {
            "$storeDocServiceId": {
                "ua": "id ідентифікатор сервісу документа зберігання",
                "en": "storage document service identifier id",
                "cz": "id identifikátor služby dokumentu pro skladování",
                "bg": "идентификатор на услугата на документа за съхранение"
            },
            "$name": {
                "ua": "назва",
                "en": "name",
                "cz": "název",
                "bg": "име"
            },
            "$analyticsId": {
                "ua": "id ідентифікатор аналітики",
                "en": "analytics identifier id",
                "cz": "id identifikátor analýzy",
                "bg": "ид идентификатор на аналитика"
            },
            "$storeDocId": {
                "ua": "id ідентифікатор документа зберігання",
                "en": "storage document identifier",
                "cz": "id identifikátor dokumentu pro skladování",
                "bg": "идентификатор на документа за съхранение"
            },
            "$orderId": {
                "ua": "id ідентифікатор замовлення",
                "en": "order identifier id",
                "cz": "id identifikátor objednávky",
                "bg": "идентификатор на поръчката"
            },
            "$docServiceUnitId": {
                "ua": "id ідентифікатор одиниці сервісного документа",
                "en": "service document unit identifier id",
                "cz": "id identifikátor jednotky servisního dokumentu",
                "bg": "идентификатор на единицата на сервизния документ"
            },
            "$measureUnit": {
                "ua": "одиниця виміру",
                "en": "measure unit",
                "cz": "jednotka měření",
                "bg": "единица за измерване"
            },
            "$price": {
                "ua": "ціна",
                "en": "price",
                "cz": "cena",
                "bg": "цена"
            },
            "$count": {
                "ua": "кількість",
                "en": "quantity",
                "cz": "množství",
                "bg": "количество"
            },
            "$sum": {
                "ua": "сума",
                "en": "amount",
                "cz": "částka",
                "bg": "сума"
            },
            "$analyticsName": {
                "ua": "назва аналітики",
                "en": "analytics name",
                "cz": "název analýzy",
                "bg": "име на аналитика"
            }
        },
        "tax": {
            "ua": "податок ПДВ",
            "en": "VAT tax",
            "cz": "daň DPH",
            "bg": "данък ДДС"
        },
        "sellingTax": {
            "ua": "податок ПДВ на продаж",
            "en": "sales VAT",
            "cz": "daň DPH z prodeje",
            "bg": "данък ДДС на продажба"
        },
        "store": {
            "ua": "Зберегти",
            "en": "Save",
            "cz": "Uložit",
            "bg": "Запази"
        },
        "restore": {
            "ua": "Повернути",
            "en": "Return",
            "cz": "Vrátit",
            "bg": "Върни"
        },
        "tags": {
            "$code": {
                "ua": "код з/ч",
                "en": "part number",
                "cz": "kód z/č",
                "bg": "код на резервни части"
            },
            "$name": {
                "ua": "назва",
                "en": "name",
                "cz": "název",
                "bg": "име"
            },
            "$brandName": {
                "ua": "назва бренду",
                "en": "brand name",
                "cz": "název značky",
                "bg": "име на марката"
            },
            "$comment": {
                "ua": "коментар",
                "en": "comment",
                "cz": "komentář",
                "bg": "коментар"
            },
            "$vehicle": {
                "ua": "транспортний засіб",
                "en": "vehicle",
                "cz": "dopravní prostředek",
                "bg": "превозно средство"
            },
            "$cell": {
                "ua": "комірка",
                "en": "cell",
                "cz": "polička",
                "bg": "клетка"
            },
            "$barcode": {
                "ua": "штрих-код",
                "en": "barcode",
                "cz": "čárový kód",
                "bg": "баркод"
            },
            "$price": {
                "ua": "ціна",
                "en": "price",
                "cz": "cena",
                "bg": "цена"
            }
        },
        "showClientNumber": {
            "ua": "показати номер клієнта",
            "en": "show client number",
            "cz": "zobrazit číslo klienta",
            "bg": "показване на номера на клиента"
        },
        "showRequisites": {
            "ua": "показати реквізити",
            "en": "show requisites",
            "cz": "zobrazit údaje",
            "bg": "показване на реквизитите"
        },
        "showSupplierNumber": {
            "ua": "показати номер постачальника",
            "en": "show supplier number",
            "cz": "zobrazit číslo dodavatele",
            "bg": "показване на номера на доставчика"
        }
    },
    "business": {
        "name": {
            "ua": "назва",
            "en": "name",
            "cz": "název",
            "bg": "име"
        },
        "address": {
            "ua": "адреса",
            "en": "address",
            "cz": "adresa",
            "bg": "адрес"
        },
        "phones": {
            "ua": "номер телефону",
            "en": "phone number",
            "cz": "telefonní číslo",
            "bg": "телефонен номер"
        },
        "logo": {
            "original": {
                "path": {
                    "ua": "посилання",
                    "en": "link",
                    "cz": "odkaz",
                    "bg": "връзка"
                },
                "width": {
                    "ua": "ширина",
                    "en": "width",
                    "cz": "šířka",
                    "bg": "ширина"
                },
                "height": {
                    "ua": "висота",
                    "en": "height",
                    "cz": "výška",
                    "bg": "височина"
                }
            }
        },
        "primaryLogoUrl": {
            "ua": "URL посилання основного логотипу",
            "en": "Primary logo URL",
            "cz": "URL odkazu hlavního loga",
            "bg": "URL връзка за основен логотип"
        },
        "secondaryLogoUrl": {
            "ua": "URL посилання додаткового логотипу",
            "en": "Secondary logo URL",
            "cz": "URL odkazu dalšího loga",
            "bg": "URL връзка за допълнителен логотип"
        },
        "domain": {
            "ua": "домен",
            "en": "domain",
            "cz": "doména",
            "bg": "домейн"
        },
        "normHourPrice": {
            "ua": "ціна нормо-години",
            "en": "norm hour price",
            "cz": "cena normohodin",
            "bg": "цена за нормо-час"
        },
        "phoneCode": {
            "$$code": {
                "ua": "код",
                "en": "code",
                "cz": "kód",
                "bg": "код"
            },
            "$$number": {
                "ua": "номер",
                "en": "number",
                "cz": "číslo",
                "bg": "номер"
            },
            "$$country": {
                "ua": "країна",
                "en": "country",
                "cz": "země",
                "bg": "страна"
            }
        },
        "packageStatus": {
            "ua": "статус пакету",
            "en": "package status",
            "cz": "status balíčku",
            "bg": "статус на пакета"
        },
        "base64PrimaryLogo": {
            "ua": "base64 основного логотипу",
            "en": "base64 primary logo",
            "cz": "base64 hlavního loga",
            "bg": "base64 на основния лого"
        },
        "currency": {
            "ua": "currency",
            "en": "currency",
            "cz": "měna",
            "bg": "валута"
        }
    },
    "businessRequisite": {
        "id": {
            "ua": "ідентифікатор",
            "en": "identifier",
            "cz": "identifikátor",
            "bg": "идентификатор"
        },
        "enabled": {
            "ua": "активний",
            "en": "active",
            "cz": "aktivní",
            "bg": "активен"
        },
        "name": {
            "ua": "назва",
            "en": "name",
            "cz": "název",
            "bg": "име"
        },
        "address": {
            "ua": "адреса",
            "en": "address",
            "cz": "adresa",
            "bg": "адрес"
        },
        "bank": {
            "ua": "банк",
            "en": "bank",
            "cz": "banka",
            "bg": "банка"
        },
        "ifi": {
            "ua": "Код банку",
            "en": "Bank code",
            "cz": "Kód banky",
            "bg": "Код на банката"
        },
        "ca": {
            "ua": "рахунок IBAN",
            "en": "IBAN account",
            "cz": "účet IBAN",
            "bg": "IBAN сметка"
        },
        "itn": {
            "ua": "Код ЄДРПОУ",
            "en": "EDRPOU code",
            "cz": "IČO",
            "bg": "Код ЄДРПОУ"
        },
        "formType": {
            "ua": "тип форми",
            "en": "form type",
            "cz": "typ formuláře",
            "bg": "тип на формата"
        },
        "formName": {
            "ua": "назва форми",
            "en": "form name",
            "cz": "název formuláře",
            "bg": "име на формата"
        },
        "isTaxPayer": {
            "ua": "платник податків",
            "en": "taxpayer",
            "cz": "plátce daně",
            "bg": "данъкоплатец"
        },
        "calculationMethod": {
            "ua": "метод розрахунку",
            "en": "calculation method",
            "cz": "metoda výpočtu",
            "bg": "метод на изчисление"
        },
        "taxRate": {
            "ua": "ставка податку",
            "en": "tax rate",
            "cz": "daňová sazba",
            "bg": "данъчна ставка"
        },
        "ipn": {
            "ua": "ІПН",
            "en": "IPN",
            "cz": "TIN",
            "bg": "ИНН"
        },
        "certificateNumber": {
            "ua": "номер сертифіката",
            "en": "certificate number",
            "cz": "číslo certifikátu",
            "bg": "номер на сертификат"
        },
        "contractNumber": {
            "ua": "номер контракту",
            "en": "contract number",
            "cz": "číslo smlouvy",
            "bg": "номер на договора"
        },
        "contractDate": {
            "ua": "дата контракту",
            "en": "contract date",
            "cz": "datum smlouvy",
            "bg": "дата на договора"
        },
        "director": {
            "ua": "директор",
            "en": "director",
            "cz": "ředitel",
            "bg": "директор"
        },
        "used": {
            "ua": "використовується",
            "en": "used",
            "cz": "používán",
            "bg": "използва се"
        },
        "disabled": {
            "ua": "неактивний",
            "en": "inactive",
            "cz": "neaktivní",
            "bg": "неактивен"
        }
    },
    "clientRequisite": {
        "id": {
            "ua": "ідентифікатор",
            "en": "identifier",
            "cz": "identifikátor",
            "bg": "идентификатор"
        },
        "enabled": {
            "ua": "активний",
            "en": "active",
            "cz": "aktivní",
            "bg": "активен"
        },
        "name": {
            "ua": "назва",
            "en": "name",
            "cz": "název",
            "bg": "име"
        },
        "address": {
            "ua": "адреса",
            "en": "address",
            "cz": "adresa",
            "bg": "адрес"
        },
        "bank": {
            "ua": "банк",
            "en": "bank",
            "cz": "banka",
            "bg": "банка"
        },
        "ifi": {
            "ua": "Код банку",
            "en": "Bank code",
            "cz": "Kód banky",
            "bg": "Код на банката"
        },
        "ca": {
            "ua": "рахунок IBAN",
            "en": "IBAN account",
            "cz": "účet IBAN",
            "bg": "IBAN сметка"
        },
        "itn": {
            "ua": "Код ЄДРПОУ",
            "en": "EDRPOU code",
            "cz": "IČO",
            "bg": "Код ЄДРПОУ"
        },
        "clientId": {
            "ua": "ідентифікатор клієнта",
            "en": "client identifier",
            "cz": "identifikátor klienta",
            "bg": "идентификатор на клиента"
        },
        "formType": {
            "ua": "тип форми",
            "en": "form type",
            "cz": "typ formuláře",
            "bg": "тип на формата"
        },
        "formName": {
            "ua": "назва форми",
            "en": "form name",
            "cz": "název formuláře",
            "bg": "име на формата"
        },
        "isTaxPayer": {
            "ua": "є платником податків",
            "en": "is taxpayer",
            "cz": "je plátcem daně",
            "bg": "е данъкоплатец"
        },
        "calculationMethod": {
            "ua": "метод розрахунку",
            "en": "calculation method",
            "cz": "metoda výpočtu",
            "bg": "метод на изчисление"
        },
        "taxRate": {
            "ua": "Податкова ставка",
            "en": "Tax rate",
            "cz": "Daňová sazba",
            "bg": "Данъчна ставка"
        },
        "ipn": {
            "ua": "ІПН",
            "en": "Tax ID",
            "cz": "TIN",
            "bg": "ИНН"
        },
        "certificateNumber": {
            "ua": "номер сертифіката",
            "en": "certificate number",
            "cz": "číslo certifikátu",
            "bg": "номер на сертификат"
        },
        "contractNumber": {
            "ua": "номер контракту",
            "en": "contract number",
            "cz": "číslo smlouvy",
            "bg": "номер на договора"
        },
        "director": {
            "ua": "директор",
            "en": "director",
            "cz": "ředitel",
            "bg": "директор"
        },
        "accountId": {
            "ua": "id ідентифікатор рахунку",
            "en": "Account ID",
            "cz": "id identifikátor účtu",
            "bg": "идентификатор на сметка"
        },
        "disabled": {
            "ua": "відключений",
            "en": "disabled",
            "cz": "vypnutý",
            "bg": "изключен"
        },
        "contractDate": {
            "ua": "дата контракту",
            "en": "contract date",
            "cz": "datum smlouvy",
            "bg": "дата на договора"
        }
    },
    "client": {
        "clientId": {
            "ua": "ідентифікатор клієнта",
            "en": "Client ID",
            "cz": "identifikátor klienta",
            "bg": "идентификатор на клиента"
        },
        "middleName": {
            "ua": "по батькові",
            "en": "middle name",
            "cz": "střední jméno",
            "bg": "средно име"
        },
        "status": {
            "ua": "статус",
            "en": "status",
            "cz": "stát",
            "bg": "статус"
        },
        "birthday": {
            "ua": "дата народження",
            "en": "date of birth",
            "cz": "datum narození",
            "bg": "дата на раждане"
        },
        "sex": {
            "ua": "стать",
            "en": "gender",
            "cz": "pohlaví",
            "bg": "пол"
        },
        "name": {
            "ua": "ім'я",
            "en": "first name",
            "cz": "jméno",
            "bg": "име"
        },
        "disableSms": {
            "ua": "відключити SMS",
            "en": "disable SMS",
            "cz": "zakázat SMS",
            "bg": "изключи SMS"
        },
        "disableAutoSms": {
            "ua": "відключити автоматичні SMS",
            "en": "disable automatic SMS",
            "cz": "zakázat automatické SMS",
            "bg": "изключи автоматични SMS"
        },
        "surname": {
            "ua": "прізвище",
            "en": "surname",
            "cz": "příjmení",
            "bg": "фамилия"
        },
        "phones": {
            "ua": "телефони",
            "en": "phones",
            "cz": "telefony",
            "bg": "телефони"
        },
        "emails": {
            "ua": "електронні пошти",
            "en": "emails",
            "cz": "e-maily",
            "bg": "електронни пощи"
        },
        "paymentRespite": {
            "ua": "строк відстрочки платежу",
            "en": "payment respite",
            "cz": "lhůta pro placení",
            "bg": "срок на отсрочка на плащането"
        },
        "sourceId": {
            "ua": "id ідентифікатор джерела",
            "en": "Source ID",
            "cz": "id identifikátor zdroje",
            "bg": "идентификатор на източника"
        },
        "discount": {
            "ua": "знижка",
            "en": "discount",
            "cz": "sleva",
            "bg": "отстъпка"
        },
        "laborsDiscount": {
            "ua": "знижка на роботи",
            "en": "labor discount",
            "cz": "sleva na práce",
            "bg": "отстъпка за работа"
        },
        "detailsDiscount": {
            "ua": "знижка на деталі",
            "en": "details discount",
            "cz": "sleva na díly",
            "bg": "отстъпка за детайли"
        },
        "type": {
            "ua": "тип",
            "en": "type",
            "cz": "typ",
            "bg": "тип"
        },
        "comment": {
            "ua": "коментар",
            "en": "comment",
            "cz": "komentář",
            "bg": "коментар"
        },
        "markupGroupId": {
            "ua": "ідентифікатор групи націнки",
            "en": "Markup Group ID",
            "cz": "identifikátor skupiny příplatků",
            "bg": "идентификатор на групата на надбавките"
        },
        "phoneCode": {
            "$code": {
                "ua": "код",
                "en": "code",
                "cz": "kód",
                "bg": "код"
            },
            "$country": {
                "ua": "країна",
                "en": "country",
                "cz": "země",
                "bg": "страна"
            }
        },
        "accountId": {
            "ua": "accountId",
            "en": "account Id",
            "cz": "ID účtu",
            "bg": "идентификатор на сметката"
        },
        "requisites": {
            "$ca": {
                "ua": "IBAN",
                "en": "IBAN",
                "cz": "IBAN",
                "bg": "IBAN"
            },
            "$id": {
                "ua": "id",
                "en": "ID",
                "cz": "id",
                "bg": "ид"
            },
            "$ifi": {
                "ua": "Код Банку",
                "en": "Bank Code",
                "cz": "Kód banky",
                "bg": "Код на банката"
            },
            "$ipn": {
                "ua": "ІПН",
                "en": "Tax ID",
                "cz": "TIN",
                "bg": "ИНН"
            },
            "$itn": {
                "ua": "Код ЄДРПОУ",
                "en": "EDRPOU Code",
                "cz": "IČO",
                "bg": "Код ЄДРПОУ"
            },
            "$bank": {
                "ua": "bank",
                "en": "Bank",
                "cz": "banka",
                "bg": "банка"
            },
            "$name": {
                "ua": "name",
                "en": "Name",
                "cz": "název",
                "bg": "име"
            },
            "address": {
                "ua": "адреса",
                "en": "address",
                "cz": "adresa",
                "bg": "адрес"
            },
            "enabled": {
                "ua": "активний",
                "en": "active",
                "cz": "aktivní",
                "bg": "активен"
            },
            "taxRate": {
                "ua": "ставка податку",
                "en": "tax rate",
                "cz": "daňová sazba",
                "bg": "данъчна ставка"
            },
            "director": {
                "ua": "директор",
                "en": "director",
                "cz": "ředitel",
                "bg": "директор"
            },
            "disabled": {
                "ua": "неактивний",
                "en": "inactive",
                "cz": "neaktivní",
                "bg": "неактивен"
            },
            "formName": {
                "ua": "назва форми",
                "en": "form name",
                "cz": "název formuláře",
                "bg": "име на формата"
            },
            "formType": {
                "ua": "тип форми",
                "en": "form type",
                "cz": "typ formuláře",
                "bg": "тип на формата"
            },
            "accountId": {
                "ua": "ідентифікатор рахунку",
                "en": "Account ID",
                "cz": "identifikátor účtu",
                "bg": "идентификатор на сметката"
            },
            "isTaxPayer": {
                "ua": "платник податків",
                "en": "taxpayer",
                "cz": "plátce daně",
                "bg": "данъкоплатец"
            },
            "accountName": {
                "ua": "назва рахунку",
                "en": "account name",
                "cz": "název účtu",
                "bg": "име на сметката"
            },
            "contractDate": {
                "ua": "дата контракту",
                "en": "contract date",
                "cz": "datum smlouvy",
                "bg": "дата на договора"
            },
            "contractNumber": {
                "ua": "номер контракту",
                "en": "contract number",
                "cz": "číslo smlouvy",
                "bg": "номер на договора"
            },
            "calculationMethod": {
                "ua": "метод розрахунку",
                "en": "calculation method",
                "cz": "metoda výpočtu",
                "bg": "метод на изчисление"
            },
            "certificateNumber": {
                "ua": "номер сертифіката",
                "en": "certificate number",
                "cz": "číslo certifikátu",
                "bg": "номер на сертификат"
            }
        },
        "disabled": {
            "ua": "неактивний",
            "en": "inactive",
            "cz": "neaktivní",
            "bg": "неактивен"
        },
        "source": {
            "ua": "джерело",
            "en": "source",
            "cz": "zdroj",
            "bg": "източник"
        },
        "accountName": {
            "ua": "ім'я рахунку",
            "en": "account name",
            "cz": "název účtu",
            "bg": "име на сметката"
        },
        "notifications": {
            "ua": "сповіщення",
            "en": "notifications",
            "cz": "notifikace",
            "bg": "известия"
        },
        "reviews": {
            "ua": "відгуки",
            "en": "reviews",
            "cz": "recenze",
            "bg": "отзиви"
        }
    },
    "clientVehicle": {
        "enabled": {
            "ua": "активний",
            "en": "active",
            "cz": "aktivní",
            "bg": "активен"
        },
        "id": {
            "ua": "id",
            "en": "ID",
            "cz": "id",
            "bg": "ид"
        },
        "vin": {
            "ua": "VIN",
            "en": "VIN",
            "cz": "VIN",
            "bg": "VIN"
        },
        "number": {
            "ua": "номер",
            "en": "number",
            "cz": "číslo",
            "bg": "номер"
        },
        "registrationName": {
            "ua": "назва реєстрації",
            "en": "registration name",
            "cz": "název registrace",
            "bg": "име на регистрацията"
        },
        "vehicleMakeName": {
            "ua": "назва марки транспортного засобу",
            "en": "vehicle make name",
            "cz": "název značky dopravního prostředku",
            "bg": "име на марката на превозното средство"
        },
        "vehicleModelName": {
            "ua": "назва моделі транспортного засобу",
            "en": "vehicle model name",
            "cz": "název modelu dopravního prostředku",
            "bg": "име на модела на превозното средство"
        },
        "vehicleModificationName": {
            "ua": "назва модифікації транспортного засобу",
            "en": "vehicle modification name",
            "cz": "název modifikace dopravního prostředku",
            "bg": "име на модификацията на превозното средство"
        },
        "clientVehicleYear": {
            "ua": "рік випуску транспортного засобу",
            "en": "vehicle year of manufacture",
            "cz": "rok výroby dopravního prostředku",
            "bg": "година на производство на превозното средство"
        },
        "vehiclegroupid": {
            "ua": "id групи транспортних засобів",
            "en": "Vehicle Group ID",
            "cz": "id skupiny dopravních prostředků",
            "bg": "идентификатор на групата на превозните средства"
        },
        "clientId": {
            "ua": "id клієнта",
            "en": "Client ID",
            "cz": "id klienta",
            "bg": "идентификатор на клиента"
        },
        "vehicleMakeId": {
            "ua": "id марки транспортного засобу",
            "en": "Vehicle Make ID",
            "cz": "id značky dopravního prostředku",
            "bg": "идентификатор на марката на превозното средство"
        }
    },
    "order": {
        "detailsDiscount": {
            "ua": "знижка на деталі",
            "en": "details discount",
            "cz": "sleva na díly",
            "bg": "отстъпка за детайли"
        },
        "beginDatetime": {
            "ua": "початок дати та часу",
            "en": "start date and time",
            "cz": "začátek data a času",
            "bg": "начало на датата и времето"
        },
        "businessComment": {
            "ua": "коментар до бізнесу",
            "en": "business comment",
            "cz": "komentář k podnikání",
            "bg": "коментар към бизнес"
        },
        "clientEmail": {
            "ua": "електронна пошта клієнта",
            "en": "client email",
            "cz": "e-mail klienta",
            "bg": "електронна поща на клиента"
        },
        "clientPhone": {
            "ua": "телефон клієнта",
            "en": "client phone",
            "cz": "telefon klienta",
            "bg": "телефон на клиента"
        },
        "clientId": {
            "ua": "id клієнта",
            "en": "Client ID",
            "cz": "id klienta",
            "bg": "идентификатор на клиента"
        },
        "comment": {
            "ua": "коментар",
            "en": "comment",
            "cz": "komentář",
            "bg": "коментар"
        },
        "courtesyCar": {
            "ua": "автомобіль на безкоштовне користування",
            "en": "courtesy car",
            "cz": "automobil k bezplatnému používání",
            "bg": "автомобил за безплатно ползване"
        },
        "clientRequisiteId": {
            "ua": "id реквізиту клієнта",
            "en": "Client Requisite ID",
            "cz": "id rekvizitu klienta",
            "bg": "идентификатор на реквизит на клиента"
        },
        "clientVehicleId": {
            "ua": "id транспортного засобу клієнта",
            "en": "Client Vehicle ID",
            "cz": "id dopravního prostředku klienta",
            "bg": "идентификатор на превозното средство на клиента"
        },
        "clientAggregateId": {
            "ua": "id агрегату клієнта",
            "en": "Client Aggregate ID",
            "cz": "id agregátu klienta",
            "bg": "идентификатор на агрегата на клиента"
        },
        "diskMaterial": {
            "ua": "матеріал диска",
            "en": "disk material",
            "cz": "materiál disku",
            "bg": "материал на диска"
        },
        "diskRadius": {
            "ua": "радіус диска",
            "en": "disk radius",
            "cz": "poloměr disku",
            "bg": "радиус на диска"
        },
        "duration": {
            "ua": "тривалість",
            "en": "duration",
            "cz": "doba",
            "bg": "продължителност"
        },
        "employeeId": {
            "ua": "id співробітника",
            "en": "Employee ID",
            "cz": "ID zaměstnance",
            "bg": "идентификатор на служителя"
        },
        "managerId": {
            "ua": "id менеджера",
            "en": "Manager ID",
            "cz": "ID manažera",
            "bg": "идентификатор на мениджъра"
        },
        "appurtenanciesResponsibleId": {
            "ua": "id відповідального за обладнання",
            "en": "Appurtenances Responsible ID",
            "cz": "ID odpovědného za vybavení",
            "bg": "идентификатор на отговорника за оборудването"
        },
        "odometerValue": {
            "ua": "показник одометра",
            "en": "odometer value",
            "cz": "měřidlo kilometrů",
            "bg": "показател на одометъра"
        },
        "paymentMethod": {
            "ua": "метод оплати",
            "en": "payment method",
            "cz": "metoda platby",
            "bg": "метод на плащане"
        },
        "recommendation": {
            "ua": "рекомендація",
            "en": "recommendation",
            "cz": "doporučení",
            "bg": "препоръка"
        },
        "businessRequisiteId": {
            "ua": "id реквізиту бізнесу",
            "en": "Business Requisite ID",
            "cz": "ID obchodního údaje",
            "bg": "идентификатор на реквизита на бизнеса"
        },
        "servicesDiscount": {
            "ua": "знижка на послуги",
            "en": "services discount",
            "cz": "sleva na služby",
            "bg": "отстъпка за услуги"
        },
        "stationNum": {
            "ua": "номер станції",
            "en": "station number",
            "cz": "číslo stanice",
            "bg": "номер на станцията"
        },
        "status": {
            "ua": "статус",
            "en": "status",
            "cz": "stav",
            "bg": "статус"
        },
        "vehicleCondition": {
            "ua": "стан транспортного засобу",
            "en": "vehicle condition",
            "cz": "stav vozidla",
            "bg": "състояние на превозното средство"
        },
        "id": {
            "ua": "id",
            "en": "ID",
            "cz": "ID",
            "bg": "ид"
        },
        "num": {
            "ua": "номер",
            "en": "number",
            "cz": "číslo",
            "bg": "номер"
        },
        "datetime": {
            "ua": "дата та час",
            "en": "date and time",
            "cz": "datum a čas",
            "bg": "дата и време"
        },
        "servicesSum": {
            "ua": "сума за послуги",
            "en": "services sum",
            "cz": "částka za služby",
            "bg": "сума за услуги"
        },
        "detailsSum": {
            "ua": "сума за деталі",
            "en": "details sum",
            "cz": "částka za díly",
            "bg": "сума за детайли"
        },
        "businessId": {
            "ua": "id бізнесу",
            "en": "Business ID",
            "cz": "ID obchodu",
            "bg": "идентификатор на бизнеса"
        },
        "viewDatetime": {
            "ua": "дата та час перегляду",
            "en": "view date and time",
            "cz": "datum a čas prohlídky",
            "bg": "дата и време на преглед"
        },
        "deliveryDatetime": {
            "ua": "дата та час доставки",
            "en": "delivery date and time",
            "cz": "datum a čas dodání",
            "bg": "дата и време на доставка"
        },
        "successDatetime": {
            "ua": "дата та час завершення",
            "en": "success date and time",
            "cz": "datum a čas ukončení",
            "bg": "дата и време на завършване"
        },
        "invoiceReportPrinted": {
            "ua": "звіт про рахунок-фактуру надруковано",
            "en": "invoice report printed",
            "cz": "zpráva o faktuře vytištěna",
            "bg": "отчетът за фактурата е отпечатан"
        },
        "actOfAcceptanceReportPrinted": {
            "ua": "акт приймання надруковано",
            "en": "act of acceptance report printed",
            "cz": "akt o převzetí vytištěn",
            "bg": "актът на приемане е отпечатан"
        },
        "completedWorkReportPrinted": {
            "ua": "звіт про виконані роботи надруковано",
            "en": "completed work report printed",
            "cz": "zpráva o vykonaných pracích vytištěna",
            "bg": "отчетът за извършената работа е отпечатан"
        },
        "replacementType": {
            "ua": "тип заміни",
            "en": "replacement type",
            "cz": "typ výměny",
            "bg": "тип на замяната"
        },
        "discount": {
            "ua": "знижка",
            "en": "discount",
            "cz": "sleva",
            "bg": "отстъпка"
        },
        "orderBloke": {
            "ua": "блок замовлень",
            "en": "order block",
            "cz": "blok objednávek",
            "bg": "блок на поръчките"
        },
        "systemComment": {
            "ua": "системний коментар",
            "en": "system comment",
            "cz": "systémový komentář",
            "bg": "системен коментар"
        },
        "agreementComment": {
            "ua": "коментар до угоди",
            "en": "agreement comment",
            "cz": "komentář ke smlouvě",
            "bg": "коментар към споразумението"
        },
        "storageHashes": {
            "ua": "хеші зберігання",
            "en": "storage hashes",
            "cz": "úložiště hashe",
            "bg": "хешове за съхранение"
        },
        "detailsTotalSum": {
            "ua": "загальна сума за деталі",
            "en": "total sum for details",
            "cz": "celková částka za díly",
            "bg": "обща сума за детайли"
        },
        "servicesTotalSum": {
            "ua": "загальна сума за послуги",
            "en": "total sum for services",
            "cz": "celková částka za služby",
            "bg": "обща сума за услуги"
        },
        "servicesSumDiscount": {
            "ua": "знижка на суму послуг",
            "en": "services sum discount",
            "cz": "sleva na částku služeb",
            "bg": "отстъпка за сумата на услугите"
        },
        "detailsSumDiscount": {
            "ua": "знижка на суму деталей",
            "en": "details sum discount",
            "cz": "sleva na částku dílů",
            "bg": "отстъпка за сумата на детайлите"
        },
        "servicesPriceSum": {
            "ua": "сума ціни послуг",
            "en": "services price sum",
            "cz": "částka ceny služeb",
            "bg": "сума на цената на услугите"
        },
        "appurtenanciesPriceSum": {
            "ua": "сума ціни обладнання",
            "en": "appurtenances price sum",
            "cz": "částka ceny vybavení",
            "bg": "сума на цената на оборудването"
        },
        "totalSum": {
            "ua": "загальна сума",
            "en": "total sum",
            "cz": "celková částka",
            "bg": "обща сума"
        },
        "totalSumDiscount": {
            "ua": "знижка на загальну суму",
            "en": "total sum discount",
            "cz": "sleva na celkovou částku",
            "bg": "отстъпка за общата сума"
        },
        "onlyAppurtenanciesTotalSum": {
            "ua": "загальна сума тільки за обладнання",
            "en": "total sum only for appurtenances",
            "cz": "celková částka pouze za vybavení",
            "bg": "обща сума само за оборудването"
        },
        "onlyAppurtenanciesTotalSumDiscount": {
            "ua": "знижка на загальну суму тільки за обладнання",
            "en": "total sum discount only for appurtenances",
            "cz": "sleva na celkovou částku pouze za vybavení",
            "bg": "отстъпка за общата сума само за оборудването"
        },
        "printNumber": {
            "ua": "номер друку",
            "en": "print number",
            "cz": "číslo tisku",
            "bg": "номер на печат"
        },
        "agreementCompleted": {
            "ua": "угода виконана",
            "en": "agreement completed",
            "cz": "smlouva splněna",
            "bg": "споразумението е изпълнено"
        },
        "laborTimeMultiplier": {
            "ua": "множник часу на роботу",
            "en": "labor time multiplier",
            "cz": "násobitel času na práci",
            "bg": "множител на времето за работа"
        },
        "totalSumWithTax": {
            "ua": "загальна сума з податком",
            "en": "total sum with tax",
            "cz": "celková částka včetně daně",
            "bg": "обща сума с данък"
        },
        "tax": {
            "ua": "податок",
            "en": "tax",
            "cz": "daň",
            "bg": "данък"
        },
        "isTaxPayer": {
            "ua": "платник податків",
            "en": "taxpayer",
            "cz": "daňový poplatník",
            "bg": "данъкоплатец"
        },
        "clientVehicleVin": {
            "ua": "VIN транспортного засобу клієнта",
            "en": "Client Vehicle VIN",
            "cz": "VIN vozidla klienta",
            "bg": "VIN на превозното средство на клиента"
        },
        "bodyId": {
            "ua": "id кузова",
            "en": "Body ID",
            "cz": "ID karoserie",
            "bg": "ид на шасито"
        },
        "businessLocationId": {
            "ua": "id розташування бізнесу",
            "en": "Business Location ID",
            "cz": "ID umístění firmy",
            "bg": "идентификатор на местоположението на бизнеса"
        },
        "businessLocationName": {
            "ua": "назва розташування бізнесу",
            "en": "business location name",
            "cz": "název umístění firmy",
            "bg": "име на местоположението на бизнеса"
        },
        "remainingSum": {
            "ua": "залишкова сума",
            "en": "remaining sum",
            "cz": "zbývající částka",
            "bg": "остатъчна сума"
        },
        "orderImgId": {
            "ua": "id зображення замовлення",
            "en": "Order Image ID",
            "cz": "ID obrázku objednávky",
            "bg": "идентификатор на изображението на поръчката"
        },
        "orderImgVehicleType": {
            "ua": "тип транспортного засобу за зображенням замовлення",
            "en": "vehicle type from order image",
            "cz": "typ vozidla z obrázku objednávky",
            "bg": "тип превозно средство от изображението на поръчката"
        },
        "subStatus": {
            "ua": "допоміжний статус",
            "en": "sub status",
            "cz": "pomocný status",
            "bg": "помощен статус"
        },
        "subStatusName": {
            "ua": "назва допоміжного статусу",
            "en": "sub status name",
            "cz": "název pomocného statusu",
            "bg": "име на помощния статус"
        },
        "vehicleImageId": {
            "ua": "id зображення транспортного засобу",
            "en": "Vehicle Image ID",
            "cz": "ID obrázku vozidla",
            "bg": "идентификатор на изображението на превозното средство"
        },
        "clientVehicleTypeId": {
            "ua": "id типу транспортного засобу клієнта",
            "en": "Client Vehicle Type ID",
            "cz": "ID typu vozidla klienta",
            "bg": "идентификатор на типа превозно средство на клиента"
        },
        "clientVehicleRadius": {
            "ua": "радіус транспортного засобу клієнта",
            "en": "client vehicle radius",
            "cz": "poloměr vozidla klienta",
            "bg": "радиус на превозното средство на клиента"
        },
        "vehicleTypeName": {
            "ua": "назва типу транспортного засобу",
            "en": "vehicle type name",
            "cz": "název typu vozidla",
            "bg": "име на типа превозно средство"
        },
        "normHourPrice": {
            "ua": "ціна за нормо-годину",
            "en": "norm hour price",
            "cz": "cena za normohodinu",
            "bg": "цена за нормо-час"
        },
        "aggregateId": {
            "ua": "id агрегату",
            "en": "Aggregate ID",
            "cz": "ID agregátu",
            "bg": "идентификатор на агрегата"
        },
        "aggregateCode": {
            "ua": "код агрегату",
            "en": "aggregate code",
            "cz": "kód agregátu",
            "bg": "код на агрегата"
        },
        "aggregateParentCode": {
            "ua": "код батьківського агрегату",
            "en": "parent aggregate code",
            "cz": "kód rodičovského agregátu",
            "bg": "код на родителския агрегат"
        },
        "aggregateLevel": {
            "ua": "рівень агрегату",
            "en": "aggregate level",
            "cz": "úroveň agregátu",
            "bg": "ниво на агрегата"
        },
        "aggregateName": {
            "ua": "назва агрегату",
            "en": "aggregate name",
            "cz": "název agregátu",
            "bg": "име на агрегата"
        },
        "aggregateBrandId": {
            "ua": "id бренду агрегату",
            "en": "Aggregate Brand ID",
            "cz": "ID značky agregátu",
            "bg": "идентификатор на марката на агрегата"
        },
        "aggregateBrandName": {
            "ua": "назва бренду агрегату",
            "en": "aggregate brand name",
            "cz": "název značky agregátu",
            "bg": "име на марката на агрегата"
        },
        "aggregateNumber": {
            "ua": "номер агрегату",
            "en": "aggregate number",
            "cz": "číslo agregátu",
            "bg": "номер на агрегата"
        },
        "aggregateCount": {
            "ua": "кількість агрегатів",
            "en": "number of aggregates",
            "cz": "počet agregátů",
            "bg": "броя на агрегатите"
        },
        "aggregateComment": {
            "ua": "коментар до агрегату",
            "en": "aggregate comment",
            "cz": "komentář k agregátu",
            "bg": "коментар към агрегата"
        },
        "aggregateVin": {
            "ua": "VIN агрегату",
            "en": "Aggregate VIN",
            "cz": "VIN agregátu",
            "bg": "VIN на агрегата"
        },
        "barcode": {
            "ua": "штрих-код",
            "en": "barcode",
            "cz": "čárový kód",
            "bg": "баркод"
        }
    },
    "businessSupplier": {
        "id": {
            "ua": "id",
            "en": "ID",
            "cz": "ID",
            "bg": "ид"
        },
        "name": {
            "ua": "назва",
            "en": "name",
            "cz": "název",
            "bg": "име"
        },
        "businessId": {
            "ua": "id бізнесу",
            "en": "Business ID",
            "cz": "ID podnikání",
            "bg": "идентификатор на бизнеса"
        },
        "contactName": {
            "ua": "ім'я контакту",
            "en": "contact name",
            "cz": "kontaktní jméno",
            "bg": "име на контакт"
        },
        "contactMiddlename": {
            "ua": "по-батькові контакту",
            "en": "contact middle name",
            "cz": "kontaktní příjmení",
            "bg": "средно име на контакта"
        },
        "contactSurname": {
            "ua": "прізвище контакту",
            "en": "contact surname",
            "cz": "kontaktní příjmení",
            "bg": "фамилия на контакта"
        },
        "phones": {
            "ua": "телефони",
            "en": "phones",
            "cz": "telefony",
            "bg": "телефони"
        },
        "emails": {
            "ua": "електронні пошти",
            "en": "emails",
            "cz": "e-maily",
            "bg": "електронни пощи"
        },
        "paymentRespite": {
            "ua": "термін оплати",
            "en": "payment respite",
            "cz": "termín platby",
            "bg": "срок на плащане"
        },
        "comment": {
            "ua": "коментар",
            "en": "comment",
            "cz": "komentář",
            "bg": "коментар"
        },
        "type": {
            "ua": "тип",
            "en": "type",
            "cz": "typ",
            "bg": "тип"
        },
        "accountId": {
            "ua": "id рахунку",
            "en": "Account ID",
            "cz": "ID účtu",
            "bg": "ид на сметката"
        },
        "incomeOrderDocsCount": {
            "ua": "кількість документів доходу",
            "en": "income order documents count",
            "cz": "počet dokladů o příjmu",
            "bg": "брой документи за доход"
        },
        "expenseOrderDocsCount": {
            "ua": "кількість документів витрат",
            "en": "expense order documents count",
            "cz": "počet dokladů o výdajích",
            "bg": "брой документи за разход"
        },
        "accountName": {
            "ua": "назва рахунку",
            "en": "account name",
            "cz": "název účtu",
            "bg": "име на сметката"
        },
        "hide": {
            "ua": "сховати",
            "en": "hide",
            "cz": "skrýt",
            "bg": "скрий"
        },
        "requisites": {
            "$id": {
                "ua": "id",
                "en": "ID",
                "cz": "ID",
                "bg": "ид"
            },
            "$businessSupplierId": {
                "ua": "id постачальника бізнесу",
                "en": "Business Supplier ID",
                "cz": "ID dodavatele firmy",
                "bg": "ид на доставчика на бизнеса"
            },
            "$enabled": {
                "ua": "активований",
                "en": "enabled",
                "cz": "aktivovaný",
                "bg": "активиран"
            },
            "$name": {
                "ua": "назва",
                "en": "name",
                "cz": "název",
                "bg": "име"
            },
            "$address": {
                "ua": "адреса",
                "en": "address",
                "cz": "adresa",
                "bg": "адрес"
            },
            "$bank": {
                "ua": "банк",
                "en": "bank",
                "cz": "banka",
                "bg": "банка"
            },
            "$ifi": {
                "ua": "Код Банку",
                "en": "Bank Code",
                "cz": "Kód banky",
                "bg": "Код на банката"
            },
            "$ca": {
                "ua": "IBAN",
                "en": "IBAN",
                "cz": "IBAN",
                "bg": "IBAN"
            },
            "$itn": {
                "ua": "Код ЄДРПОУ",
                "en": "EDRPOU Code",
                "cz": "IČO",
                "bg": "Код ЄДРПОУ"
            },
            "$formType": {
                "ua": "тип форми",
                "en": "form type",
                "cz": "typ formuláře",
                "bg": "тип форма"
            },
            "$formName": {
                "ua": "назва форми",
                "en": "form name",
                "cz": "název formuláře",
                "bg": "име на формата"
            },
            "$isTaxPayer": {
                "ua": "платник податків",
                "en": "taxpayer",
                "cz": "daňový poplatník",
                "bg": "платец на данъци"
            },
            "$calculationMethod": {
                "ua": "метод розрахунку",
                "en": "calculation method",
                "cz": "metoda výpočtu",
                "bg": "метод на изчисление"
            },
            "$taxRate": {
                "ua": "ставка податку",
                "en": "tax rate",
                "cz": "sazba daně",
                "bg": "ставка на данък"
            },
            "$ipn": {
                "ua": "ІПН",
                "en": "Tax ID",
                "cz": "IČ",
                "bg": "ИД на данъчния номер"
            },
            "$certificateNumber": {
                "ua": "номер сертифікату",
                "en": "certificate number",
                "cz": "číslo osvědčení",
                "bg": "номер на сертификата"
            },
            "$contractNumber": {
                "ua": "номер контракту",
                "en": "contract number",
                "cz": "číslo smlouvy",
                "bg": "номер на договора"
            },
            "$contractDate": {
                "ua": "дата контракту",
                "en": "contract date",
                "cz": "datum smlouvy",
                "bg": "дата на договора"
            },
            "$director": {
                "ua": "директор",
                "en": "director",
                "cz": "ředitel",
                "bg": "директор"
            },
            "$accountID": {
                "ua": "ID рахунку",
                "en": "Account ID",
                "cz": "ID účtu",
                "bg": "ИД на сметката"
            },
            "$disabled": {
                "ua": "неактивований",
                "en": "disabled",
                "cz": "neaktivovaný",
                "bg": "неактивиран"
            },
            "$accountName": {
                "ua": "назва рахунку",
                "en": "account name",
                "cz": "název účtu",
                "bg": "име на сметката"
            }
        }
    },
    "servicesSum": {
        "ua": "servicesSum",
        "en": "Services Sum",
        "cz": "součet služeb",
        "bg": "сумата на услугите"
    }
};