import { Input, Select, Tooltip } from 'antd';
import { CASH_ORDER_TYPES, MODES } from 'modals/RefactoredCashOrderModal/constants';
import { selectCashboxes } from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import {
    selectCashOrderType,
    selectMode,
    selectReceiptNo,
    selectSelectedCashbox,
    selectSelectedCashboxId,
    setReceiptNo
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const { INCOME, EXPENSE, ADJUSTMENT_INCOME } = CASH_ORDER_TYPES;

const mapStateToProps = state => ({
    cashOrderType: selectCashOrderType(state),
    selectedCashbox: selectSelectedCashbox(state),
    selectReceiptNo: selectReceiptNo(state),
    mode: selectMode(state),
    cashboxes: selectCashboxes(state),
    selectedCashboxId: selectSelectedCashboxId(state)
});

const mapDispatchToProps = {
    setReceiptNo
};

/**
 * This component serves selecting cash order type
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ReceiptNoInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {}

    render() {
        const {
            cashOrderType,
            setRequisites,
            selectRequisiteId,
            mode,
            editable,
            cashboxes,
            selectedCashboxId,
            selectReceiptNo,
            setReceiptNo,
            intl: { formatMessage }
        } = this.props;

        return (
            <Tooltip placement='top' title={<FormattedMessage id='receipt_no' />} zIndex={2001}>
                <Input
                    allowClear
                    disabled={mode != MODES.ADD}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={e => {
                        const { value } = e.target;
                        if (!value) {
                            setReceiptNo(null);
                        } else {
                            setReceiptNo(value);
                        }
                    }}
                    placeholder={formatMessage({
                        id: 'receipt_no'
                    })}
                    value={selectReceiptNo}
                />
            </Tooltip>
        );
    }
}
