import {
    CheckOutlined,
    FileImageOutlined,
    FileSearchOutlined,
    FilterFilled,
    FilterOutlined,
    FolderOpenOutlined,
    SearchOutlined,
    VideoCameraAddOutlined
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Dropdown,
    Input,
    Menu,
    Modal,
    Radio,
    Select,
    Space,
    Table,
    Tooltip,
    notification
} from 'antd';
import { AvailabilityIndicator } from 'components';
import { MODALS, loadModal, resetModal, saveModal, setModal } from 'core/modals/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import _, { get } from 'lodash';
import {
    AddLaborOrDetailToOrderModal,
    AddProductToStoreDocModal,
    DetailSupplierModal,
    DetailWarehousesCountModal,
    PreviewMediaFilesModal,
    SearchDetailModal,
    VinCodeModal
} from 'modals';
import { BrandSelectModal, PhotoModal, SourceSelectModal, SupplierSelectModal } from 'modals/OrderDetailModal/modals';
import { STORAGE, SUPPLIER } from 'pages/Storage/constants';
import { fetchVehicles, selectVehicles, setSearchQuery } from 'pages/VehiclesPage/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { BookIcon, DeliveryTruckIcon, StockIcon, VinIcon } from 'theme';
import { buildStoreGroupsTree, fetchAPI, fetchTecdocAPI, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import './styles.less';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    isMobile: state.ui.views.isMobile,
    suppliers: state.suppliers.suppliers,
    warehouses: state.warehouses.warehouses,
    modal: state.modals.modal,
    vehicles: selectVehicles(state)
});

const mapDispatchToProps = {
    setModal,
    saveModal,
    loadModal,
    resetModal,
    setSearchQuery,
    fetchVehicles,
    fetchWarehouses
};

const MODIFICATION = 'MODIFICATION';
const OILS = 'OILS';
const CODES = 'CODES';
const CROSSES = 'CROSSES';

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class OrderDetailBody extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            page: 1,
            pageSize: 15,
            total: 0,
            visibleBrandSelectModal: false,
            searchDetailOpen: false,
            comparation: 'EQUAL',
            mode: 0,
            isAvailable: true,
            lastIncome: false,
            listBrands: [],
            selectedRowKeys: [],
            selectedRows: [],
            loading: false,
            loadingBrands: false,
            originalCode: undefined,
            selectedSearchMode: STORAGE,
            mediaFilesRow: undefined,
            details: []
        };

        this.input = React.createRef();

        this.handleStorageSearch = _.debounce(() => {
            this.fetchStorage();
        }, 500);

        this.handleBrandsSearch = _.debounce(value => {
            this.fetchStorageBrands(value);
        }, 1000);

        this.columns = () => [
            {
                title: () => (
                    <Space.Compact>
                        <Button
                            disabled={!this.state.selectedRows.length}
                            onClick={() => {
                                this.props.setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                    details: this.state.selectedRows.map(detail => ({
                                        storeGroupId: detail.groupId,
                                        name: detail.name,
                                        productId: detail.id,
                                        productCode: detail.code || null,
                                        purchasePrice: detail.purchasePrice || 0,
                                        count: 1,
                                        price: detail.sellingPrice || 0,
                                        supplierBrandId: detail.brandId,
                                        partUnitId: detail.productUnitId,
                                        warehouseId: detail.defaultWarehouseId
                                    })),
                                    mode: 'ADD_DETAIL'
                                });
                            }}
                        >
                            <FormattedMessage id='order_shr' />
                        </Button>
                        <Button
                            disabled={!(this.state.selectedRows.length && this.state.selectedSearchMode === STORAGE)}
                            onClick={() => {
                                this.props.setModal(MODALS.ADD_PRODUCT_TO_STORE_DOC, {
                                    products: this.state.selectedRows.map(elem => ({
                                        productId: elem.productId,
                                        quantity: 1,
                                        stockPrice: elem.stockPrice || 0,
                                        sellingPrice: elem.sellingPrice,
                                        addToAddress: null,
                                        getFromAddress: null,
                                        uktz: elem.tradeCode,
                                        docProductUnitId: elem.productUnitId || 1
                                    }))
                                });
                            }}
                        >
                            <FormattedMessage id='store_srt' />
                        </Button>
                        <Button
                            disabled={!this.state.selectedRows.length}
                            onClick={async () => {
                                const res = await fetchAPI('POST', '/retails', undefined, undefined, {
                                    handleErrorInternally: true
                                });

                                if (res && res.id) {
                                    const payload = {
                                        insertMode: true,
                                        details: this.state.selectedRows.map(detail => ({
                                            storeGroupId: detail.groupId,
                                            name: detail.name,
                                            productId: detail.id,
                                            productCode: detail.code || null,
                                            purchasePrice: detail.purchasePrice || 0,
                                            count: 1,
                                            price: detail.sellingPrice || 0,
                                            supplierBrandId: detail.brandId,
                                            partUnitId: detail.productUnitId,
                                            warehouseId: detail.defaultWarehouseId
                                        })),
                                        services: []
                                    };

                                    await fetchAPI('PUT', `retail/${res.id}`, null, payload, {
                                        handleErrorInternally: true
                                    });
                                    window.open(`${book.rtl}/${res.id}`, '_blank');
                                }
                            }}
                        >
                            RTL+
                        </Button>
                    </Space.Compact>
                ),
                align: 'center',
                key: 'actions',
                render: row => {
                    const code = row.code || row.partNumber;

                    return (
                        !row.emptyRow && (
                            <div className='actions'>
                                <Tooltip title={<FormattedMessage id='settings_page.media' />} zIndex={2001}>
                                    <Button
                                        icon={<VideoCameraAddOutlined />}
                                        onClick={() => {
                                            this.setState({
                                                mediaFilesRow: row
                                            });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={<FormattedMessage id='order_form_table.open_photo_tooltip' />}
                                    zIndex={2001}
                                >
                                    <Button
                                        data-qa='button_open_photo_modal_order_detail_modal'
                                        // disabled={!get(row, 'hasAttributes') && !get(row, 'hasImages')}
                                        icon={<FileImageOutlined />}
                                        onClick={() => {
                                            this.setState({
                                                photoModalRow: row
                                            });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={<FormattedMessage id='order_form_table.add_to_order_tooltip' />}
                                    zIndex={2001}
                                >
                                    <Button
                                        data-qa='button_handle_ok_select_order_detail_modal'
                                        disabled={this.state.loading}
                                        icon={<CheckOutlined />}
                                        onClick={() => {
                                            this.setState({ loading: true });
                                            this.props.setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                                details: [
                                                    {
                                                        storeGroupId: row.groupId,
                                                        name: row.name,
                                                        productId: row.id,
                                                        productCode: row.code || null,
                                                        purchasePrice: row.purchasePrice || 0,
                                                        count: 1,
                                                        price: row.sellingPrice || 0,
                                                        supplierBrandId: row.brandId,
                                                        partUnitId: row.productUnitId,
                                                        warehouseId: row.defaultWarehouseId
                                                    }
                                                ],
                                                mode: 'ADD_DETAIL'
                                            });

                                            this.setState({ loading: false });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title={<FormattedMessage id='order_form_table.apply_btn' />} zIndex={2001}>
                                    <Button
                                        data-qa='btn_fetch_storage_oils_order_detail_modal'
                                        disabled={!code}
                                        icon={<SearchOutlined />}
                                        onClick={async () => {
                                            await this.setState({
                                                code,
                                                brandId: row.brandId,
                                                page: 1
                                            });
                                            setTimeout(() => this.fetchFinalCrosses(), 0);
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        )
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'code',
                width: '15%',
                ...this.getColumnSearchProps('filterCode'),
                render: row => {
                    const code = row.code || row.partNumber;
                    if (row.emptyRow) {
                        return (
                            <FormattedMessage
                                id={
                                    this.state.mode === 0
                                        ? 'order_form_table.directory_spare_parts'
                                        : this.state.mode === 2
                                        ? 'order_form_table.choose_the_desired_product'
                                        : 'order_form_table.replacements_cross_references'
                                }
                            />
                        );
                    }

                    const name = row.name && get(row.name, 'length') > 55 ? `${row.name.slice(0, 55)}...` : row.name;
                    const description = row.description > 55 ? `${row.description.slice(0, 55)}...` : row.description;

                    return (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700,
                                    textDecoration: code && 'underline',
                                    cursor: 'pointer'
                                }}
                            >
                                {(row.businessSupplierId === 0 || this.state.mode === 0) && row.productId ? (
                                    <Link target='_blank' to={`${book.product}/${row.productId}`}>
                                        {code}
                                    </Link>
                                ) : (
                                    <span
                                        onClick={() => {
                                            if (
                                                isGrantAccessed(
                                                    this.props.user,
                                                    grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD
                                                )
                                            ) {
                                                this.props.setModal(MODALS.STORE_PRODUCT, {
                                                    code,
                                                    brandId: row.brandId,
                                                    brandName: row.brandName,
                                                    name: row.name,
                                                    groupId: row.storeGroupId,
                                                    productUnitId: row.partUnitId || 1,
                                                    onSubmit: async (id, code) => {
                                                        notification.success({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'message.success'
                                                            })
                                                        });
                                                        await this.setState({
                                                            code,
                                                            brandId: row.brandId,
                                                            page: 1
                                                        });
                                                        this.fetchFinalCrosses();
                                                    }
                                                });
                                            }
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {code || <FormattedMessage id='long_dash' />}
                                    </span>
                                )}
                            </div>
                            <Tooltip title={row.name || row.description}>
                                <div style={{ fontSize: 12 }}>{name || description}</div>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brand',
                ...this.getColumnSearchProps('filterBrand'),
                render: row => {
                    const code = row.code || row.partNumber;

                    return (
                        <div>
                            <Tooltip
                                title={<FormattedMessage id='order_form_table.find_in_catalog_tooltip' />}
                                zIndex={2001}
                            >
                                <div
                                    onClick={async () => {
                                        await this.setState({
                                            code,
                                            brandId: row.brandId || null,
                                            page: 1
                                        });
                                        this.fetchFinalCrosses();
                                    }}
                                    style={{
                                        fontWeight: 700,
                                        textDecoration: code && 'underline',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {row.brandName}
                                </div>
                            </Tooltip>
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_form_table.store_supplier' />,
                key: 'businessSupplierName',
                align: 'center',
                filterDropdown: ({ confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Select
                            allowClear
                            onChange={value => {
                                this.setState({
                                    filterWarehouseId: value
                                });
                            }}
                            optionFilterProp='children'
                            // mode='multiple'
                            placeholder={this.props.intl.formatMessage({
                                id: 'search'
                            })}
                            showSearch
                            style={{ marginBottom: 8, display: 'block', width: 180 }}
                            value={this.state.filterWarehouseId}
                        >
                            {this.props.warehouses
                                .filter(({ attribute }) => attribute !== 'RESERVE' && attribute !== 'STORAGE')
                                .map(({ name, id }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                        </Select>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, 'filterWarehouseId')}
                                size='small'
                                type='secondary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => this.handleReset('filterWarehouseId', confirm)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    </div>
                ),
                filterIcon: () => (
                    <FilterFilled
                        style={{
                            fontSize: 14,
                            color: this.state.filterWarehouseId ? 'var(--primary)' : undefined
                        }}
                    />
                ),
                render: (row, _, key) => {
                    if (row.emptyRow) return <React.Fragment></React.Fragment>;

                    return (
                        <div>
                            <Tooltip
                                title={<FormattedMessage id='order_form_table.availability_store' />}
                                zIndex={2001}
                            >
                                <span
                                    onClick={() => {
                                        if (
                                            (row.businessSupplierId === 0 &&
                                                isGrantAccessed(
                                                    this.props.user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                                )) ||
                                            (this.state.mode === 0 &&
                                                isGrantAccessed(
                                                    this.props.user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                                ))
                                        ) {
                                            this.setState({
                                                warehousesModalSelectedRow: row
                                            });
                                        }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {row.supplierId ? (
                                        <FormattedMessage id='long_dash' />
                                    ) : (
                                        row.businessSupplierName || row.defaultWarehouseName
                                    )}
                                </span>
                            </Tooltip>
                            {' / '}
                            {isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS, accesses.ROWO) ? (
                                <DetailSupplierModal
                                    brandId={row.brandId}
                                    clientId={this.props.clientId}
                                    detailCode={row.code || row.partNumber}
                                    detailSearchSelectButtons
                                    disabled={!row.storeGroupId || !row.code || !row.brandId}
                                    hideButton
                                    orderSelectFunc={el => {
                                        this.props.setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                            details: [
                                                {
                                                    storeGroupId: row.groupId,
                                                    name: row.name,
                                                    productId: row.id,
                                                    productCode: row.code || null,
                                                    purchasePrice: el.purchasePrice || row.purchasePrice || 0,
                                                    count: 1,
                                                    price: el.sellingPrice || row.sellingPrice || 0,
                                                    supplierBrandId: row.brandId,
                                                    partUnitId: row.productUnitId,
                                                    warehouseId: row.defaultWarehouseId,
                                                    supplierId: el.businessSupplierId,
                                                    supplierOriginalCode: el.supplierOriginalCode,
                                                    supplierProductNumber: el.supplierProductNumber,
                                                    supplierPartNumber: el.supplierPartNumber
                                                }
                                            ],
                                            mode: 'ADD_DETAIL'
                                        });
                                    }}
                                    rtlCreateFunc={async el => {
                                        const res = await fetchAPI('POST', '/retails', undefined, undefined, {
                                            handleErrorInternally: true
                                        });

                                        if (res && res.id) {
                                            const payload = {
                                                insertMode: true,
                                                details: [
                                                    {
                                                        storeGroupId: row.groupId,
                                                        name: row.name,
                                                        productId: row.id,
                                                        productCode: row.code || null,
                                                        purchasePrice: el.purchasePrice || row.purchasePrice || 0,
                                                        count: 1,
                                                        price: el.sellingPrice || row.sellingPrice || 0,
                                                        supplierBrandId: row.brandId,
                                                        partUnitId: row.productUnitId,
                                                        warehouseId: row.defaultWarehouseId,
                                                        supplierId: el.businessSupplierId,
                                                        supplierOriginalCode: el.supplierOriginalCode,
                                                        supplierProductNumber: el.supplierProductNumber,
                                                        supplierPartNumber: el.supplierPartNumber
                                                    }
                                                ],
                                                services: []
                                            };

                                            await fetchAPI('PUT', `retail/${res.id}`, null, payload, {
                                                handleErrorInternally: true
                                            });
                                            window.open(`${book.rtl}/${res.id}`, '_blank');
                                        }
                                    }}
                                    storeDocSelectFunc={el => {
                                        this.props.setModal(MODALS.ADD_PRODUCT_TO_STORE_DOC, {
                                            products: [
                                                {
                                                    productId: row.productId,
                                                    quantity: 1,
                                                    stockPrice: el.sellingPrice || row.stockPrice || 0,
                                                    sellingPrice: el.sellingPrice || row.sellingPrice,
                                                    addToAddress: null,
                                                    getFromAddress: null,
                                                    uktz: row.tradeCode,
                                                    docProductUnitId: row.productUnitId || 1
                                                }
                                            ]
                                        });
                                    }}
                                    storeGroupId={row.storeGroupId}
                                    tableKey={key}
                                    user={this.props.user}
                                >
                                    {row.supplierId ? row.businessSupplierName : <FormattedMessage id='long_dash' />}
                                </DetailSupplierModal>
                            ) : row.supplierId ? (
                                row.businessSupplierName
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='navigation.availability' />,
                key: 'store',
                align: 'center',
                render: ({ id, store, available, reservedCount, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    return id ? (
                        `${Number(available + reservedCount).toFixed(2)} / ${Number(available).toFixed(2)}`
                    ) : (
                        <AvailabilityIndicator indexArray={store} />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='services_table.units' />
                    </div>
                ),
                key: 'unit',
                align: 'right',
                render: (row, unit) => {
                    if (row.emptyRow) {
                        return <p></p>;
                    }

                    return <span style={{ whiteSpace: 'nowrap' }}>{row.unit || <FormattedMessage id='pc' />}</span>;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                align: 'right',
                render: ({ purchasePrice, stockPrice, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    const price = purchasePrice || stockPrice;
                    const strVal = ((price * 10) / 10).toFixed(2);

                    return price ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'salePrice',
                align: 'right',
                render: ({ salePrice, sellingPrice, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    const price = salePrice || sellingPrice;
                    const strVal = ((price * 10) / 10).toFixed(2);

                    return price ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            }
        ];
    }

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.fetchData();
    };

    saveSearchHistoryParts = async payload => {
        try {
            await fetchAPI(
                'POST',
                '/search_history/products',
                undefined,
                {
                    code: payload.code || payload.productCode || undefined,
                    brandId: payload.brandId || payload.supplierBrandId || undefined,
                    name: payload.name || undefined,
                    oeCode: payload.oeCode || payload.code,
                    businessSupplierId: payload.businessSupplierId || undefined
                },
                { handleErrorInternally: true }
            );
        } catch (err) {
            notification.error({ message: this.props.intl.formatMessage({ id: 'error' }) });
        }
    };

    saveSearchHistoryVin = async (vin, makeModel) => {
        const VinValidation = /^[A-Za-z0-9]+$/;
        if (get(vin, 'length') === 17 && VinValidation.test(vin)) {
            fetchAPI(
                'POST',
                '/search_history/vin',
                undefined,
                {
                    makeModel,
                    vin
                },
                { handleErrorInternally: true }
            );
        }
    };

    getDataSource = products => {
        if (!_.isArray(products)) {
            return [];
        }

        return products.map(item => {
            const elem = { ...item };
            if (elem.businessSupplierId !== 0 || elem.brandId === 0) {
                delete elem.productId;
            }
            elem.attributes = elem.attributes || [];

            if (elem.defaultWarehouseName) {
                elem.productId = elem.id;
            }

            if (elem.price) {
                if (elem.price.businessSupplierId === 0) {
                    elem.productId = elem.price.id;
                }
                elem.store = elem.price.store;
                elem.purchasePrice = elem.price.purchasePrice;
                elem.businessSupplierId = elem.price.businessSupplierId;
                elem.businessSupplierName = elem.price.businessSupplierName;
                elem.salePrice =
                    elem.sellingPrice || elem.price.purchasePrice * (elem.price.markup ? elem.price.markup : 1.4);
                elem.supplierOriginalCode = elem.price.supplierOriginalCode;
                elem.supplierPartNumber = elem.price.supplierPartNumber;
            }

            return elem;
        });
    };

    handleReset = (dataIndex, confirm) => {
        this.setState({ [dataIndex]: null });
        this.handleSearch(confirm);
    };

    fetchStorageBrands = async queryBrands => {
        await this.setState({ loadingBrands: true });
        try {
            const { code, page, pageSize, isAvailable, filterCode, filterBrand, filterWarehouseId } = this.state;
            const { result, count } = await fetchAPI(
                'GET',
                '/store_products_brands',
                {
                    query: code,
                    onlyAvailable: isAvailable,
                    filterCode,
                    filterBrand,
                    clientId: this.props.clientId,
                    warehouseId: filterWarehouseId,
                    queryBrands

                    // filterWarehouse: this.getDetailWarehouses()
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            this.setState({
                listBrands: result,
                loadingBrands: false
            });
        } catch (e) {
            this.setState({ loadingBrands: false });
        }
    };

    fetchStorage = async filter => {
        await this.setState({ loading: true, dataSource: [], type: STORAGE });
        try {
            const { code, page, pageSize, isAvailable, filterCode, filterBrand, lastIncome, filterWarehouseId } =
                this.state;
            const {
                list,
                listBrands,
                stats: { count }
            } = await fetchAPI(
                'GET',
                'store_products',
                {
                    page,
                    pageSize: pageSize - 1,
                    query: code,
                    onlyAvailable: isAvailable,
                    lastIncome,
                    filterCode,
                    warehouseId: filterWarehouseId,
                    filterBrand,
                    clientId: this.props.clientId,
                    ...(filter || {})
                    // filterWarehouse: this.getDetailWarehouses()
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            const dataSource = this.getDataSource(list);
            this.setState({
                dataSource: [{ emptyRow: true }, ...dataSource],
                total: count,
                loading: false,
                mode: 0
            });
        } catch (e) {
            this.setState({ loading: false });
        }
    };

    fetchBrands = async () => {
        const { code, comparation } = this.state;
        if (code.length < 3) {
            return;
        }

        this.setState({
            loading: true,
            oeCode: undefined,
            storeGroupId: undefined,
            dataSource: []
        });

        const brands = await fetchTecdocAPI(
            'GET',
            '/products/search/v2',
            {
                code,
                include: this.getIncludesSources(),
                sortField: 'BRAND',
                comparation
            },
            null,
            { handleErrorInternally: true }
        );

        await this.setState({
            page: 1,
            total: 0,
            visibleBrandSelectModal: brands.length > 1,
            brands,
            loading: false,
            brandId: brands.length === 1 ? brands[0].brandId : undefined,
            filterCode: undefined,
            filterBrand: undefined
        });
        if (brands.length <= 1) {
            if (comparation === 'EQUAL') {
                this.fetchFinalCrosses();
            } else {
                this.fetchCodes();
            }
        }
        if (brands.length === 0) {
            this.noResultsNotification();
        }
    };

    fetchCodes = async () => {
        try {
            this.setState({ loading: true, dataSource: [] });
            const { code, brandId, comparation, page, pageSize, isAvailable, filterCode, filterBrand } = this.state;
            const { list, count: total } = await fetchTecdocAPI(
                'GET',
                '/products/search/v2/m2',
                {
                    code,
                    include: this.getIncludesSources(),
                    brandId,
                    comparation,
                    page,
                    pageSize: pageSize - 1,
                    isAvailable,
                    filterCode,
                    filterBrand,
                    filterSupplier: this.getDetailSuppliers(),
                    clientId: this.props.clientId
                    // filterWarehouse: this.getDetailWarehouses()
                },
                null,
                { handleErrorInternally: true }
            );

            let productPriceIndex = 0; // Use to retrieve correct selling price
            list.forEach(product => {
                product.productPriceIndex = productPriceIndex++;
            });

            const sellingPrices = await fetchAPI(
                'POST',
                '/store_products/selling_prices',
                null,
                {
                    clientId: this.props.clientId || null,
                    products: list.map(product => ({
                        index: product.productPriceIndex,
                        productId: product.storeProductId || null,
                        code: product.code,
                        storeGroupId: product.storeGroupId || null,
                        businessSupplierId: product.businessSupplierId || null,
                        purchasePrice: product.purchasePrice || null
                    }))
                },
                { handleErrorInternally: true }
            );

            list.forEach(product => {
                product.sellingPrice = sellingPrices[product.productPriceIndex].sellingPrice;
            });

            const dataSource = this.getDataSource(list);

            await this.setState({
                dataSource: [{ emptyRow: true }, ...dataSource],
                mode: 2,
                type: CODES,
                loading: false,
                total
            });
            if (list.length === 1) {
                await this.setState({
                    code: list[0].code
                });
                this.fetchFinalCrosses();
            }
        } catch (e) {
            this.setState({ loading: false });
        }
    };

    fetchFinalCrosses = async () => {
        try {
            this.setState({ loading: true, dataSource: [] });
            const { code, brandId, page, pageSize, isAvailable, filterCode, filterBrand, filterWarehouseId } =
                this.state;
            if (!code) {
                this.setState({ loading: false });

                return;
            }
            // DRY
            const {
                listParts,
                list: listCrosses,
                stats: { count: total },
                listBrands
            } = await fetchTecdocAPI(
                'GET',
                '/tecdoc/crosses',
                {
                    code,
                    brandId: brandId === 8000 ? undefined : brandId,
                    page,
                    pageSize: pageSize - 2,
                    isAvailable,
                    filterCode,
                    filterBrand,
                    warehouseId: filterWarehouseId,
                    include: this.getIncludesSources(),
                    filterSupplier: this.getDetailSuppliers(),
                    clientId: this.props.clientId
                    // filterWarehouse: this.getDetailWarehouses()
                },
                null,
                { handleErrorInternally: true }
            );

            const updatedListParts = [listParts].map(part => {
                if (part.brandName === 'Not Defined') {
                    part.brandName = 'OES';
                }

                return part;
            });

            let productPriceIndex = 0; // Use to retrieve correct selling price
            [...updatedListParts, ...listCrosses].forEach(product => {
                product.productPriceIndex = productPriceIndex++;
            });

            // Assign selling prices
            const sellingPrices = await fetchAPI(
                'POST',
                '/store_products/selling_prices',
                null,
                {
                    clientId: this.props.clientId || null,
                    products: [...updatedListParts, ...listCrosses].map(product => ({
                        index: product.productPriceIndex,
                        productId: product.storeProductId || null,
                        code: product.code,
                        storeGroupId: product.storeGroupId || null,
                        businessSupplierId: product.businessSupplierId || null,
                        purchasePrice: product.purchasePrice || null
                    }))
                },
                { handleErrorInternally: true }
            );

            updatedListParts.forEach(product => {
                product.sellingPrice = sellingPrices[product.productPriceIndex].sellingPrice;
            });

            listCrosses.forEach(product => {
                product.sellingPrice = sellingPrices[product.productPriceIndex].sellingPrice;
            });

            const listP = this.getDataSource(updatedListParts);
            const listC = this.getDataSource(listCrosses);

            await this.setState({
                dataSource: [...listP, { emptyRow: true }, ...listC],
                mode: 3,
                type: CROSSES,
                loading: false,
                total,
                listBrands
            });
            this.forceUpdate();
        } catch (e) {
            this.setState({ loading: false });
        }
    };

    fetchData = () => {
        const { type } = this.state;
        switch (type) {
            case CROSSES:
                this.fetchFinalCrosses();
                break;
            case CODES:
                this.fetchCodes();
                break;
            // case MODIFICATION:
            //     this.fetchByModificationId();
            //     break;
            case STORAGE:
                this.fetchStorage();
                break;
            // case OILS:
            //     this.fetchOils();
            //     break;
            default:
                this.fetchStorage();
                break;
        }
    };

    getIncludesSources = () => {
        const sources = localStorage.getItem('_my.carbook.detail_sources');
        if (sources) {
            const result = JSON.parse(sources);
            if (result.length) {
                return result;
            }
        }

        return undefined;
        // return ['BUSINESS_PRODUCTS', 'TECDOC', 'PRICELIST', 'BUSINESS_CROSSES'];
    };

    getDetailSuppliers = () => {
        const suppliers = localStorage.getItem('_my.carbook.detail_suppliers');
        if (suppliers) {
            const result = JSON.parse(suppliers);
            if (result.length) {
                return result;
            }
        }

        return undefined;
    };

    getDetailWarehouses = () => {
        const warehouses = localStorage.getItem('_my.carbook.detail_warehouses');
        if (warehouses) {
            const result = JSON.parse(warehouses);
            if (result.length) {
                return result;
            }
        }

        return undefined;
    };

    fetchLaborsAndDetails = async () => {
        const details = await fetchAPI('GET', '/store_groups', undefined, undefined, {
            handleErrorInternally: true
        });
        this.setState({ details });
    };

    componentDidMount() {
        this.fetchStorage();
        if (get(this.props, 'location') && get(this.props, 'location.code')) {
            this.setState({
                code: this.props.location.code
            });
        }

        if (get(this.props, 'location') && get(this.props, 'location.vin')) {
            this.setState({
                vin: this.props.location.vin
            });
        }
        this.fetchLaborsAndDetails();
    }

    getColumnSearchProps = dataIndex => {
        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {dataIndex !== 'filterBrand' ? (
                        <Input
                            ref={node => {
                                this.searchInput = node;
                            }}
                            onChange={e => {
                                this.setState({
                                    [dataIndex]: e.target.value
                                });
                            }}
                            onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                            placeholder={this.props.intl.formatMessage({
                                id: 'search'
                            })}
                            style={{ marginBottom: 8, display: 'block', width: 180 }}
                            value={this.state[dataIndex]}
                        />
                    ) : (
                        <Select
                            allowClear
                            onChange={value => {
                                if (!value) {
                                    this.setState({
                                        filterBrand: undefined
                                    });

                                    if (this.state.type === 'STORAGE') {
                                        this.handleBrandsSearch();
                                    }
                                }
                                this.setState({
                                    filterBrand: value
                                });
                            }}
                            onFocus={() => {
                                if (!this.state.listBrands?.length && this.state.type === 'STORAGE') {
                                    this.fetchStorageBrands();
                                }
                            }}
                            onSearch={input => {
                                if (this.state.type === 'STORAGE') {
                                    this.handleBrandsSearch(input);
                                }
                            }}
                            optionFilterProp='children'
                            // mode='multiple'
                            placeholder={this.props.intl.formatMessage({
                                id: 'search'
                            })}
                            showSearch
                            style={{ marginBottom: 8, display: 'block', width: 180 }}
                            value={this.state.filterBrand}
                        >
                            {this.state.loadingBrands ? (
                                <Option>
                                    <FormattedMessage id='client-mrds-table.data_missing' />
                                </Option>
                            ) : (
                                this.state.listBrands &&
                                this.state.listBrands.map(({ brandId, brandName }) => (
                                    <Option key={brandId} value={brandId}>
                                        {brandName}
                                    </Option>
                                ))
                            )}
                        </Select>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <Button
                            icon={<SearchOutlined style={{ marginRight: 4 }} />}
                            onClick={() => this.handleSearch(confirm, dataIndex)}
                            size='small'
                            type='secondary'
                        >
                            <FormattedMessage id='search' />
                        </Button>
                        <Button onClick={() => this.handleReset(dataIndex, confirm)} size='small'>
                            <FormattedMessage id='reset' />
                        </Button>
                    </div>
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    dataIndex !== 'filterBrand' && setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    componentDidUpdate(prevProps, prevState) {
        const {
            vehicles,
            catalogueData: { vin, code, brandId }
        } = this.props;
        const { details } = this.state;
        // Compare previous state with current state
        const vehicle = get(this.props.vehicles, '[0]', []);
        if (prevProps.vehicles !== vehicles && get(this.state.vin, 'length') === 17) {
            this.saveSearchHistoryVin(
                this.state.vin,
                `${(vehicle && vehicle.make) || ''} ${(vehicle && vehicle.model) || ''}`
            );
        }

        if (prevProps.catalogueData.vin !== vin) {
            this.setState({ vin });
        }

        if (prevProps.catalogueData.code !== code) {
            this.setState({ code, brandId, page: 1 });
            setTimeout(() => this.fetchFinalCrosses(), 0);
        }

        if (!this.state.detailsTreeData && this.state.details && get(this.state.details, 'length')) {
            const detailsTreeData = buildStoreGroupsTree(details);

            this.setState({ detailsTreeData });
        }
    }

    vinModalAction = async ({ oem, storeGroupId }) => {
        await this.setState({ code: oem, oeCode: oem, storeGroupId, brandId: undefined, originalCode: oem });
        this.fetchFinalCrosses();
    };

    handleSearchDetailOk = data => {
        console.log('Data', data);
    };

    handleSearchDetailOpen = () => {
        this.setState({ searchDetailOpen: true });
    };

    handleSearchDetailClose = () => {
        this.setState({ searchDetailOpen: false });
    };

    handleSearchMode = e => {
        this.setState({ selectedSearchMode: e.key });
    };

    fetchVehicles = async vin => {
        const { setSearchQuery, fetchVehicles } = this.props;
        setSearchQuery({
            query: _.toLower(vin.replace(/[+()]/g, ''))
        });
        await fetchVehicles();
    };

    render() {
        const {
            modal,
            setModal,
            resetModal,
            // treeData,
            suppliers,
            showOilModal,
            // orderId,
            // warehouses,
            modificationId,
            // updateDataSource,
            user,
            isMobile,
            intl: { formatMessage }
            // existingOECode
        } = this.props;

        const {
            dataSource,
            // reserveModalVisible,
            page,
            pageSize,
            total,
            code,
            // storeGroupId,
            loading,
            visibleBrandSelectModal,
            comparation,
            brands,
            type,
            visibleSourceSelectModal,
            photoModalRow,
            mode,
            isAvailable,
            visibleSupplierSelectModal,
            // visibleStoreGroupSelectModal,
            warehousesModalSelectedRow,
            // visibleStorageSelectModal,
            filterName,
            selectedRowKeys,
            // selectedRows,
            lastIncome,
            searchDetailOpen,
            vin,
            selectedSearchMode,
            // oeCode,
            mediaFilesRow,
            detailsTreeData
        } = this.state;

        const pagination = {
            pageSize,
            total,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchData();
            }
        };

        const columns = !isMobile
            ? this.columns()
            : this.columns().filter(
                  ({ key }) => key === 'actions' || key === 'code' || key === 'brand' || key === 'salePrice'
              );

        const include = this.getIncludesSources();
        const supplier = this.getDetailSuppliers();
        const warehouse = this.getDetailWarehouses();

        const searchBlock = (
            <div className='headerModal'>
                <div className='headerModal_col'>
                    <Space.Compact className='styleInpButton'>
                        <Input
                            ref={this.input}
                            allowClear
                            data-qa='table_detail_code_input_order_detail_modal'
                            onChange={async event => {
                                const { value: code } = event.target;
                                await this.setState({ code });
                                if (type === STORAGE && !mode) {
                                    this.handleStorageSearch();
                                }
                                if (!code) {
                                    await this.setState({
                                        oeCode: undefined,
                                        storeGroupId: undefined
                                    });
                                    this.fetchStorage();
                                }
                            }}
                            onPressEnter={this.fetchBrands}
                            placeholder={formatMessage({ id: 'order_form_table.detail_search' })}
                            value={code}
                        />
                        <Tooltip title={<FormattedMessage id='filters' />} zIndex={2001}>
                            <Button
                                disabled={selectedSearchMode !== STORAGE}
                                icon={<FilterOutlined />}
                                onClick={this.handleSearchDetailOpen}
                            />
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id='order_form_table.apply_btn' />} zIndex={2001}>
                            <Button disabled={!code} icon={<SearchOutlined />} onClick={this.fetchBrands} />
                        </Tooltip>
                    </Space.Compact>
                </div>
                <div className='btnInAction' style={{ textAlign: isMobile && 'center' }}>
                    <Tooltip
                        title={
                            <Input
                                disabled={!isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_VIN)}
                                max={17}
                                onChange={async e => {
                                    this.setState({ vin: e.target.value });
                                    if (get(e.target.value, 'length') === 17) {
                                        this.fetchVehicles(e.target.value);
                                    }
                                }}
                                placeholder={formatMessage({ id: 'add_order_form.vin' })}
                                value={vin}
                            />
                        }
                        zIndex={2001}
                    >
                        <Button
                            data-qa='btn_add_form_vin_order_detail_modal'
                            disabled={!vin || !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_VIN)}
                            icon={<VinIcon style={{ fontSize: 20 }} />}
                            onClick={() => {
                                setModal(MODALS.VIN_MODAL, {
                                    vin,
                                    setVinDetail: product => this.vinModalAction(product)
                                });
                            }}
                            style={{ marginRight: 8 }}
                            // type={vin ? 'primary' : 'default'}
                        />
                    </Tooltip>
                    <Dropdown
                        overlay={
                            <Menu onClick={this.handleSearchMode} selectedKeys={[selectedSearchMode]}>
                                {/* <Menu.Item
                                    key='search_by_car'
                                    disabled={!modificationId}
                                    onClick={() => {
                                        if (!this.props.storeGroupId) {
                                            this.setState({
                                                visibleStoreGroupSelectModal: true
                                            });
                                        } else {
                                            this.fetchByModificationId();
                                        }
                                    }}
                                >
                                    <FormattedMessage id='order_form_table_search_by_car' />
                                </Menu.Item> */}
                                {isGrantAccessed(
                                    user,
                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                ) && (
                                    <Menu.Item key={STORAGE} onClick={() => this.fetchStorage()}>
                                        <FormattedMessage id='navigation.storage' />
                                    </Menu.Item>
                                )}
                                {/* <Menu.Item key='search_oil' disabled={!showOilModal} onClick={showOilModal}>
                                    <FormattedMessage id='order_from_table_search_oil' />
                                </Menu.Item> */}
                                <Menu.Item
                                    key={SUPPLIER}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'order_from_table_search_supplier'
                                            }),
                                            content: (
                                                <Input
                                                    onChange={e => {
                                                        this.setState({
                                                            filterName: e.target.value
                                                        });
                                                    }}
                                                    value={filterName}
                                                />
                                            ),
                                            onOk: () => {
                                                this.fetchByModificationId();
                                            },
                                            onCancel: () => {
                                                this.setState({ filterName: undefined, selectedSearchMode: STORAGE });
                                            }
                                        });
                                    }}
                                >
                                    <FormattedMessage id='order_from_table_search_supplier' />
                                </Menu.Item>
                            </Menu>
                        }
                        overlayStyle={{
                            zIndex: 9999
                        }}
                        trigger={['click']}
                    >
                        <Tooltip title={<FormattedMessage id='order_form_table.catalog' />} zIndex={2001}>
                            <Button
                                data-qa='btn_selection_by_product_code_order_detail_modal'
                                icon={<BookIcon style={{ fontSize: 20 }} />}
                            />
                        </Tooltip>
                    </Dropdown>
                </div>
                <div className='headerModal_col'>
                    <div className='headerModal_bottomRow'>
                        <Radio.Group
                            onChange={({ target: { value: comparation } }) => this.setState({ comparation })}
                            value={comparation}
                        >
                            <Radio style={{ display: isMobile && 'block' }} value='EQUAL'>
                                <FormattedMessage id='order_form_table.exact_match' />
                            </Radio>
                            <Radio style={{ display: isMobile && 'block' }} value='STARTS_WITH'>
                                <FormattedMessage id='order_form_table.starts_with' />
                            </Radio>
                            <Radio style={{ display: isMobile && 'block' }} value='INCLUDES'>
                                <FormattedMessage id='order_form_table.includes' />
                            </Radio>
                        </Radio.Group>

                        <div style={{ marginTop: isMobile && 14 }}>
                            <Checkbox
                                checked={isAvailable}
                                onChange={async () => {
                                    await this.setState({
                                        isAvailable: !isAvailable,
                                        page: 1
                                    });
                                    this.fetchData();
                                }}
                                style={{
                                    paddingLeft: 8
                                }}
                            >
                                <FormattedMessage id='order_form_table.in_stock' />
                            </Checkbox>
                        </div>
                        <div style={{ marginTop: isMobile && 14 }}>
                            <Checkbox
                                checked={lastIncome}
                                onChange={async () => {
                                    await this.setState({
                                        lastIncome: !lastIncome,
                                        page: 1
                                    });
                                    this.fetchData();
                                }}
                                style={{
                                    paddingLeft: 8
                                }}
                            >
                                <FormattedMessage id='order_form_table.lastIncome' />
                            </Checkbox>
                        </div>
                    </div>
                </div>
                <Space style={{ textAlign: isMobile && 'center' }}>
                    <Tooltip title={<FormattedMessage id='orders.source' />} zIndex={2001}>
                        <Button
                            icon={<FolderOpenOutlined style={{ fontSize: 18 }} />}
                            onClick={() => {
                                this.setState({ visibleSourceSelectModal: true });
                            }}
                            style={{
                                borderColor: include && 'var(--primary)'
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='storage_document.supplier' />} zIndex={2001}>
                        <Button
                            disabled={!isAvailable}
                            icon={<DeliveryTruckIcon style={{ fontSize: 20 }} />}
                            onClick={() => {
                                this.setState({ visibleSupplierSelectModal: true });
                            }}
                            style={{
                                borderColor: isAvailable && supplier && 'var(--primary)'
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='storage_document.warehouses' />} zIndex={2001}>
                        <Button
                            disabled
                            icon={<StockIcon style={{ fontSize: 18 }} />}
                            onClick={() => {
                                this.setState({ visibleStorageSelectModal: true });
                            }}
                            style={{
                                borderColor: warehouse && 'var(--primary)'
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='search_history' />} zIndex={2001}>
                        <Button
                            icon={<FileSearchOutlined style={{ fontSize: 18 }} />}
                            onClick={() => goTo(book.historyTrackingPage)}
                        />
                    </Tooltip>
                </Space>
            </div>
        );

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            },
            getCheckboxProps: record => ({
                disabled: !record.code,
                style: !record.code ? { display: 'none' } : {}
            })
        };

        return (
            <React.Fragment>
                <Table
                    bordered
                    className='ordDetailModalTable'
                    columns={columns}
                    dataSource={dataSource}
                    getPopupContainer={trigger => trigger.parentNode}
                    loading={loading}
                    // onRow={record => ({
                    //     onDoubleClick: () => {
                    //         if (record.code) {
                    //             this.handleOk(record);
                    //         }
                    //     }
                    // })}
                    pagination={pagination}
                    rowClassName={row => {
                        if (row.emptyRow) return 'emptyRow';
                    }}
                    rowKey={row => `${row.code || row.partNumber}-${row.description || row.name}-${row.brandName}`}
                    rowSelection={rowSelection}
                    size='small'
                    title={() => searchBlock}
                />
                <BrandSelectModal
                    brands={brands}
                    hideModal={() => this.setState({ visibleBrandSelectModal: false })}
                    onSelect={async ({ brandId, count }) => {
                        await this.setState({ brandId });
                        if (comparation !== 'EQUAL') {
                            this.fetchCodes();
                        } else {
                            this.fetchFinalCrosses();
                        }
                    }}
                    visibleBrandSelectModal={visibleBrandSelectModal}
                />
                <SourceSelectModal
                    hideModal={async () => {
                        await this.setState({ visibleSourceSelectModal: false });
                        this.fetchData();
                    }}
                    visible={visibleSourceSelectModal}
                />
                <SupplierSelectModal
                    hideModal={async () => {
                        await this.setState({ visibleSupplierSelectModal: false });
                        this.fetchData();
                    }}
                    suppliers={suppliers}
                    visible={visibleSupplierSelectModal}
                />
                <SearchDetailModal
                    onClose={this.handleSearchDetailClose}
                    onOk={filter => this.fetchStorage(filter)}
                    open={searchDetailOpen}
                />
                <PhotoModal
                    data={photoModalRow}
                    hideModal={() => this.setState({ photoModalRow: false })}
                    visible={Boolean(photoModalRow)}
                />
                <DetailWarehousesCountModal
                    count={get(warehousesModalSelectedRow, 'count')}
                    detailSearchSelectButtons
                    hideButton
                    hideModal={() => {
                        this.setState({
                            warehousesModalSelectedRow: undefined
                        });
                    }}
                    orderSelectFunc={el => {
                        const row = warehousesModalSelectedRow
                        this.props.setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                            details: [
                                {
                                    storeGroupId: row.groupId,
                                    name: row.name,
                                    productId: row.id,
                                    productCode: row.code || null,
                                    purchasePrice: el.purchasePrice || row.purchasePrice || 0,
                                    count: 1,
                                    price: el.price || row.sellingPrice || 0,
                                    supplierBrandId: row.brandId,
                                    partUnitId: row.productUnitId,
                                    warehouseId: el.warehouseId,
                                    cellAddress: el.cellAddress, 
                                    supplierId: 0
                                }
                            ],
                            mode: 'ADD_DETAIL'
                        });
                    }}
                    productId={_.get(warehousesModalSelectedRow, 'productId')}
                    storeDocSelectFunc={el => {
                        const row = warehousesModalSelectedRow
                        this.props.setModal(MODALS.ADD_PRODUCT_TO_STORE_DOC, {
                            products: [
                                {
                                    productId: row.productId,
                                    quantity: 1,
                                    stockPrice: el.purchasePrice || row.stockPrice || 0,
                                    sellingPrice: el.price || row.sellingPrice,
                                    addToAddress: el.cellAddress,
                                    getFromAddress: null,
                                    uktz: row.tradeCode,
                                    docProductUnitId: row.productUnitId || 1,
                                    supplierId: 0
                                }
                            ]
                        });
                    }}
                    // onSelect={(address, warehouseId) => {
                    //     if (this.state.activeKey === 'storageOrders') {
                    //         updateSparePartsWorkplaceData([
                    //             {
                    //                 id: warehousesModalSelectedRow.id,
                    //                 cellAddress: address,
                    //                 warehouseId,
                    //                 supplierId: 0
                    //             }
                    //         ]);
                    //     } else {
                    //         this.updateOrdersSurpluses([
                    //             {
                    //                 id: warehousesModalSelectedRow.id,
                    //                 cellAddress: address,
                    //                 warehouseId,
                    //                 supplierId: 0
                    //             }
                    //         ]);
                    //     }
                    // }}
                    productUnit={_.get(warehousesModalSelectedRow, 'unit')}
                    rtlCreateFunc={async el => {
                        const row = warehousesModalSelectedRow
                        const res = await fetchAPI('POST', '/retails', undefined, undefined, {
                            handleErrorInternally: true
                        });

                        if (res && res.id) {
                            const payload = {
                                insertMode: true,
                                details: [
                                    {
                                        storeGroupId: row.groupId,
                                        name: row.name,
                                        productId: row.id,
                                        productCode: row.code || null,
                                        purchasePrice: el.purchasePrice || row.purchasePrice || 0,
                                        count: 1,
                                        price: el.price || row.sellingPrice || 0,
                                        supplierBrandId: row.brandId,
                                        partUnitId: row.productUnitId,
                                        warehouseId: el.warehouseId,
                                        cellAddress: el.cellAddress,
                                        supplierId: 0
                                    }
                                ],
                                services: []
                            };

                            await fetchAPI('PUT', `retail/${res.id}`, null, payload, {
                                handleErrorInternally: true
                            });
                            window.open(`${book.rtl}/${res.id}`, '_blank');
                        }
                    }}
                    visible={Boolean(warehousesModalSelectedRow)}
                />
                <VinCodeModal
                    detailsTreeData={detailsTreeData}
                    disabled={false}
                    modal={modal}
                    resetModal={resetModal}
                    saveSearchHistoryVin={this.saveSearchHistoryVin}
                    vin={vin}
                />
                <PreviewMediaFilesModal
                    hideModal={() =>
                        this.setState({
                            mediaFilesRow: false
                        })
                    }
                    open={Boolean(mediaFilesRow)}
                    prdId={mediaFilesRow ? mediaFilesRow.id || mediaFilesRow.storeProductId : mediaFilesRow}
                />
                <AddLaborOrDetailToOrderModal code={code} saveSearchHistoryParts={this.saveSearchHistoryParts} />
                <AddProductToStoreDocModal />
            </React.Fragment>
        );
    }
}

export default OrderDetailBody;
