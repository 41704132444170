import { Input, Radio, Select, Slider } from 'antd';
import { Catcher } from 'commons';
import {
    clearUniversalFilters,
    fetchUniversalFiltersForm
} from 'core/forms/universalFiltersForm/duck';
import {
    fetchOrders,
    setOrdersCancelReasonFilter,
    setOrdersNPSFilter,
    setOrdersSearchFilter,
    setOrdersStatusFilter
} from 'core/orders/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

const { Search } = Input;
const { Option } = Select;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const mapStateToProps = state => ({
    stats: state.orders.stats,
    filter: state.orders.filter,
    orderComments: state.forms.universalFiltersForm.filters.orderComments,
    currentStatus: _.get(state, 'router.location.state.status')
});

const mapDispatchToProps = {
    fetchOrders,
    setOrdersStatusFilter,
    setOrdersSearchFilter,
    setOrdersNPSFilter,
    fetchUniversalFiltersForm,
    setOrdersCancelReasonFilter,
    clearUniversalFilters
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrdersFilterContainer extends Component {
    constructor(props) {
        super(props);
        this.handleOrdersSearch = _.debounce(value => {
            const { setOrdersSearchFilter, fetchOrders, filter } = this.props;
            setOrdersSearchFilter(value);
            fetchOrders({ page: 1, ...filter });
        }, 1000);

        this.handleReviewSlider = _.debounce(value => {
            const { setOrdersNPSFilter, fetchOrders, filter } = this.props;

            setOrdersNPSFilter({ minNps: value[0], maxNps: value[1] });
            fetchOrders({ page: 1, ...filter });
        }, 1000);
    }

    componentDidMount() {
        const status = this.props.match.params.ordersStatuses;
        const { fetchUniversalFiltersForm } = this.props;

        if (status === 'cancel') {
            fetchUniversalFiltersForm();
        }

        if (this.props.location.state && this.props.location.state.searchValue) {
            if (status !== 'appointments') this.props.setOrdersStatusFilter(status);
            this.props.setOrdersSearchFilter(this.props.location.state.searchValue);
            this.props.setQuery(this.props.location.state.searchValue);
            this.props.fetchOrders({ page: 1, ...this.props.filter });
        }
    }

    componentDidUpdate(prevProps) {
        const status = this.props.match.params.ordersStatuses;
        const { orderComments, fetchUniversalFiltersForm, currentStatus } = this.props;
        // TODO check []
        if (prevProps.currentStatus !== this.props.currentStatus) {
            this.props.setOrdersStatusFilter(currentStatus);
            this.props.setOrdersSearchFilter(this.props.query);
            this.props.fetchOrders({ page: 1, ...this.props.filter });
        }
        // if (prevProps.filter.status !== this.props.filter.status) {
        //     this.props.setOrdersStatusFilter(currentStatus);
        // }
        if (status === 'cancel') {
            if (!orderComments) {
                fetchUniversalFiltersForm();
            }
        }
    }

    handleCancelReasonSelect = value => {
        const { setOrdersCancelReasonFilter, fetchOrders, filter } = this.props;

        setOrdersCancelReasonFilter(value);
        fetchOrders({ page: 1, ...filter });
    };

    _setFilterStatus = status => {
        this.props.setOrdersStatusFilter(status);
        this.props.fetchOrders();
    };

    render() {
        const { status, stats, intl, filter, orderComments, onlySearch, query, setQuery } =
            this.props;
        const filterStatus = filter.status;

        const marks = {
            0: {
                style: {
                    color: 'rgb(255, 126, 126)'
                },
                label: <strong>0</strong>
            },
            1: {
                style: {
                    color: 'rgb(255, 126, 126)'
                },
                label: 1
            },
            2: {
                style: {
                    color: 'rgb(255, 126, 126)'
                },
                label: 2
            },
            3: {
                style: {
                    color: 'rgb(255, 126, 126)'
                },
                label: 3
            },
            4: {
                style: {
                    color: 'rgb(255, 126, 126)'
                },
                label: 4
            },
            5: {
                style: {
                    color: 'rgb(255, 126, 126)'
                },
                label: 5
            },
            6: {
                style: {
                    color: 'rgb(255, 126, 126)'
                },
                label: 6
            },
            7: {
                style: {
                    color: 'rgb(251, 158, 62)'
                },
                label: 7
            },
            8: {
                style: {
                    color: 'rgb(251, 158, 62)'
                },
                label: 8
            },
            9: {
                style: {
                    color: 'rgb(76, 201, 105)'
                },
                label: 9
            },
            10: {
                style: {
                    color: 'rgb(76, 201, 105)'
                },
                label: <strong>10</strong>
            }
        };

        return (
            <Catcher>
                {onlySearch ? (
                    <div>
                        <Search
                            className={Styles.search}
                            onChange={({ target: { value } }) => {
                                setQuery(value);
                                this.handleOrdersSearch(value);
                            }}
                            placeholder={intl.formatMessage({
                                id: 'orders-filter.search_placeholder'
                            })}
                            value={query}
                            maxLength={33}
                        />
                    </div>
                ) : (
                    <div className={Styles.filter}>
                        <Search
                            className={Styles.search}
                            // defaultValue={location.state && location.state.searchValue}
                            onChange={({ target: { value } }) => {
                                setQuery(value);
                                this.handleOrdersSearch(value);
                            }}
                            placeholder={intl.formatMessage({
                                id: 'orders-filter.search_placeholder'
                            })}
                            value={query}
                            maxLength={33}
                        />
                        {status === 'appointments' && (
                            <RadioGroup className={Styles.buttonGroup} value={filterStatus}>
                                <RadioButton
                                    onClick={() =>
                                        this._setFilterStatus('not_complete,required,call,reserve')
                                    }
                                    value='not_complete,required,call,reserve'
                                >
                                    <FormattedMessage id='all' /> (
                                    {stats.not_complete +
                                        stats.required +
                                        stats.call +
                                        stats.reserve}
                                    )
                                </RadioButton>
                                <RadioButton
                                    onClick={() => this._setFilterStatus('not_complete')}
                                    value='not_complete'
                                >
                                    <FormattedMessage id='not_complete' /> ({stats.not_complete})
                                </RadioButton>
                                <RadioButton
                                    onClick={() => this._setFilterStatus('required')}
                                    value='required'
                                >
                                    <FormattedMessage id='required' /> ({stats.required})
                                </RadioButton>
                                <RadioButton
                                    onClick={() => this._setFilterStatus('reserve')}
                                    value='reserve'
                                >
                                    <FormattedMessage id='reserve' /> ({stats.reserve})
                                </RadioButton>
                                <RadioButton
                                    onClick={() => this._setFilterStatus('call')}
                                    value='call'
                                >
                                    <FormattedMessage id='call' /> ({stats.call})
                                </RadioButton>
                            </RadioGroup>
                        )}
                        {status === 'reviews' && (
                            <div className={Styles.review}>
                                {/* <Icon style={ { color: preColor } } type='frown-o' /> */}
                                {/* <div>NPS:</div> */}
                                <Slider
                                    defaultValue={[0, 10]}
                                    marks={marks}
                                    max={10}
                                    min={0}
                                    // value={ filter.nps }
                                    onChange={value => this.handleReviewSlider(value)}
                                    range
                                />
                                {/* <Icon style={ { color: nextColor } } type='smile-o' /> */}
                            </div>
                        )}
                        {status === 'cancel' && orderComments && (
                            <Select
                                className={Styles.cancelReasonSelect}
                                getPopupContainer={trigger => trigger.parentNode}
                                // mode='multiple'
                                onChange={value => this.handleCancelReasonSelect(value)}
                                placeholder={
                                    <FormattedMessage id='orders-filter.filter_by_refusal_reason' />
                                }
                            >
                                {orderComments
                                    .map(({ status, id, comment }) =>
                                        status === 'cancel' ? (
                                            <Option key={v4()} value={id}>
                                                {comment}
                                            </Option>
                                        ) : (
                                            false
                                        ))
                                    .filter(Boolean)}
                            </Select>
                        )}
                    </div>
                )}
            </Catcher>
        );
    }
}
