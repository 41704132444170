import { Button, notification } from 'antd';
import { DateRangePicker, WarehouseSelect } from 'components';
import _ from 'lodash';
import { selectStoreDocsOrdersFilters, setStoreDocsOrdersFilters } from 'pages/ProductPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import OrdersTable from './OrdersTable';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    filters: selectStoreDocsOrdersFilters(state),
    user: state.auth
});

const mapDispatchToProps = {
    setStoreDocsOrdersFilters
};

/// / brands, warehouses, datetime filter

/**
 * Product by suppliers tab
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrderTab extends Component {
    setDaterange = daterange => {
        const [startDate, endDate] = daterange;

        this.props.setStoreDocsOrdersFilters({ startDate, endDate });
    };

    render() {
        return (
            <div>
                <div className={Styles.orderFilters}>
                    <Button
                        disabled={!this.props.isAccessed}
                        onClick={async () => {
                            const response = await fetchAPI(
                                'POST',
                                'v2/store_orders/reset_quantity',
                                null,
                                { productId: this.props.productId },
                                { handleErrorInternally: true }
                            );
                            const checkStatus = async (index = 0) => {
                                if (index >= 20) {
                                    notification.error({ message: 'Invalid' });

                                    return;
                                }
                                const resp = await fetchAPI('GET', 'mq_processes', {
                                    productId: this.props.productId,
                                    pcode: 'RESET_ORDER'
                                });
                                const status = _.get(resp, 'list[0].state');

                                if (status && status !== 'OK') {
                                    setTimeout(() => checkStatus(index + 1), 1000);

                                    return;
                                }
                                if (status === 'OK') {
                                    notification.success({
                                        message: this.props.intl.formatMessage({
                                            id: 'message.success'
                                        })
                                    });
                                }
                                this.props.fetchProductMovement();
                            };

                            checkStatus(0);
                            this.props.fetchProductMovement();
                        }}
                        style={{ margin: '0 8px 0 0' }}
                    >
                        <FormattedMessage id='product.reset_orders' />
                    </Button>

                    <WarehouseSelect onChange={warehouseId => this.props.setStoreDocsOrdersFilters({ warehouseId })} />
                    <DateRangePicker
                        allowClear
                        dateRange={[this.props.filters.startDate, this.props.filters.endDate]}
                        minimize
                        onDateChange={this.setDaterange}
                        style={{ margin: '0 8px 0 0' }}
                    />
                </div>
                <OrdersTable productId={this.props.productId} />
            </div>
        );
    }
}
