import { DiffOutlined, ExportOutlined, FilterFilled, RedoOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Input, Menu, Modal, Select, Space, Table, Tag } from 'antd';
import { Layout, Numeral } from 'commons';
import { DateRangePicker, HamburgerMenu } from 'components';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

export default function JournalVATPage() {
    const intl = useIntl();
    const user = useSelector(state => state.auth);

    // State
    const [vat, setVat] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [count, setCount] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    // For storing text-based filter values keyed by column dataIndex
    const [searchValues, setSearchValues] = useState({});

    const searchInputRef = useRef(null);

    const debounceSetSearchValues = useMemo(() => {
        const loadOptions = value => {
            setSearchValues(value);
        };

        return _.debounce(loadOptions, 500);
    }, []);

    // Fetch VAT info
    const getVATInfo = useCallback(async () => {
        try {
            const data = await fetchAPI(
                'GET',
                '/business/vat/journal',
                { page, pageSize, startDate, endDate, ...searchValues },
                null,
                {
                    handleErrorInternally: true
                }
            );
            setVat(data.vatJournal || []);
            setCount(data.count || 0);
        } catch (err) {
            console.error(err);
        }
    }, [page, pageSize, startDate, endDate, searchValues]);

    // componentDidMount + watch for page, pageSize, startDate, endDate changes
    useEffect(() => {
        getVATInfo();
    }, [getVATInfo]);

    const handleSearch = useCallback((confirm, dataIndex) => {
        confirm();
        setPage(1);
    }, []);

    const handleReset = useCallback((confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        if (dataIndex === 'startDate') {
            setStartDate(undefined);
            setEndDate(undefined);
        } else {
            setSearchValues(prev => ({ ...prev, [dataIndex]: undefined }));
        }
        setPage(1);
    }, []);

    // Filter for columns
    const getColumnSearchProps = useCallback(
        dataIndex => {
            let filterComponent = (confirm, clearFilters) => (
                <Input
                    ref={searchInputRef}
                    allowClear
                    onChange={e =>
                        debounceSetSearchValues(prev => ({
                            ...searchValues,
                            [dataIndex]: e.target.value
                        }))
                    }
                    onPressEnter={() => handleSearch(confirm, dataIndex)}
                    placeholder={intl.formatMessage({ id: 'search' })}
                    style={{ marginBottom: 8, width: 180 }}
                    // value={searchValues[dataIndex]}
                />
            );

            // Special case for date range filter
            if (dataIndex === 'startDate') {
                filterComponent = confirm => (
                    <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                        <DateRangePicker
                            allowClear
                            dateRange={[startDate ? dayjs(startDate) : undefined, endDate ? dayjs(endDate) : undefined]}
                            format={dateFormat}
                            getPopupContainer={trigger => trigger.parentNode}
                            onDateChange={async dateRange => {
                                setStartDate(dateRange[0] ? dayjs(dateRange[0]).format(dateFormat) : undefined);
                                setEndDate(dateRange[1] ? dayjs(dateRange[1]).format(dateFormat) : undefined);
                                handleSearch(confirm, dataIndex);
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                );
            }

            if (dataIndex === 'direction') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={async value => {
                            setSearchValues(prev => ({
                                ...prev,
                                [dataIndex]: value
                            }));
                        }}
                        optionFilterProp='children'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={searchValues[dataIndex]}
                    >
                        <Option key='out' value='out'>
                            <FormattedMessage id='vat_page.out' />
                        </Option>
                        <Option key='in' value='in'>
                            <FormattedMessage id='vat_page.in' />
                        </Option>
                    </Select>
                );
            }

            if (dataIndex === 'status') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={async value => {
                            setSearchValues(prev => ({
                                ...prev,
                                [dataIndex]: value
                            }));
                        }}
                        optionFilterProp='children'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={searchValues[dataIndex]}
                    >
                        <Option key={0} value={0}>
                            <FormattedMessage id='no' />
                        </Option>
                        <Option key={1} value={1}>
                            <FormattedMessage id='yes' />
                        </Option>
                    </Select>
                );
            }

            return {
                filterDropdown: ({ confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        {filterComponent(confirm, clearFilters)}
                        {dataIndex !== 'dateRange' && dataIndex !== 'startDate' && (
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                {/* <Button
                                    icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                    onClick={() => handleSearch(confirm, dataIndex)}
                                    size='small'
                                    type='primary'
                                >
                                    <FormattedMessage id='search' />
                                </Button> */}
                                <Button onClick={() => handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                    </div>
                ),
                filterIcon: () => (
                    <FilterFilled
                        style={{
                            fontSize: 14,
                            color:
                                searchValues[dataIndex] || (dataIndex === 'startDate' && (startDate || endDate))
                                    ? 'var(--primary)'
                                    : undefined
                        }}
                    />
                ),
                onFilterDropdownOpenChange: visible => {
                    if (visible) {
                        setTimeout(() => {
                            searchInputRef.current?.select();
                        }, 100);
                    }
                }
            };
        },
        [intl, searchValues, debounceSetSearchValues, handleSearch, startDate, endDate, handleReset]
    );

    const exportZip = useCallback(
        async (withoutDetailing, rowId) => {
            const medoc = get(user, 'medoc', {});

            const vatIds = selectedRows.map(row => row?.objects[0].rowId);

            const payload = {
                vatIds: rowId ? [rowId] : vatIds,
                withoutDetailing: withoutDetailing || false
            };

            const response = await fetchAPI('POST', '/report/xml/cash_flow', null, payload, {
                rawResponse: true,
                handleErrorInternally: true,
                url: medoc.medocUrl
            });

            const reportFile = await response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            // const fileName = contentDispositionHeader?.match(/^attachment; filename="(.*)"/)[1] || 'xml_documents.zip';
            await saveAs(reportFile, 'xml_documents.zip');

            await setTimeout(() => getVATInfo(), 600);
        },
        [getVATInfo, selectedRows, user]
    );

    const confirmContent = useMemo(
        () => (
            <div className={Styles.dflex}>
                <div>
                    <Button
                        onClick={async () => {
                            await exportZip(false);
                            Modal.destroyAll();
                        }}
                        type='primary'
                    >
                        {intl.formatMessage({
                            id: 'salary-report-form_detailing_labors.with_detailing'
                        })}
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={async () => {
                            await exportZip(true);
                            Modal.destroyAll();
                        }}
                    >
                        {intl.formatMessage({
                            id: 'salary-report-form_detailing_labors.without_detaling'
                        })}
                    </Button>
                </div>
            </div>
        ),
        [exportZip, intl]
    );

    // Define table columns
    const columns = useMemo(() => {
        return [
            {
                title: '№',
                key: 'index',
                width: '2%',
                render: (data, row, index) => <div>{index + 1}.</div>
            },
            {
                title: <FormattedMessage id='storage_document.counterparty' />,
                key: 'counterparty',
                width: '10%',
                render: (data, row) => <div>{row.counterpartName}</div>
            },
            {
                title: <FormattedMessage id='vat_page.primary_doc' />,
                key: 'subject',
                width: '10%',
                ...getColumnSearchProps('initialDocument'),
                render: (data, row) => {
                    if (
                        _.get(row, 'objects[0].subjectType') === 'MRD' ||
                        _.get(row, 'objects[0].subjectType') === 'RD'
                    ) {
                        return (
                            <div>
                                <a
                                    href={`${book.order}/${row.id}`}
                                    style={{ color: 'var(--link)', fontWeight: 'bold' }}
                                >
                                    {row.subject}
                                </a>
                            </div>
                        );
                    }
                    if (_.get(row, 'objects[0].subjectType') === 'RTL') {
                        return (
                            <div>
                                <a href={`${book.rtl}/${row.id}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                                    {row.subject}
                                </a>
                            </div>
                        );
                    }
                    if (_.get(row, 'objects[0].subjectType') === 'RTN') {
                        return (
                            <div>
                                <a href={`${book.rtn}/${row.id}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                                    {row.subject}
                                </a>
                            </div>
                        );
                    }
                    if (isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT)) {
                        return (
                            <div>
                                <a
                                    href={`${book.storageDocument}/${row.id}`}
                                    style={{ color: 'var(--link)', fontWeight: 'bold' }}
                                >
                                    {row.subject}
                                </a>
                            </div>
                        );
                    }

                    return <div>{row.subject}</div>;
                }
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'date',
                width: '5%',
                ...getColumnSearchProps('startDate'),
                render: (data, row) => {
                    const dateVal = _.get(row, 'objects[0].date');

                    return <div>{dateVal ? dayjs(dateVal).format('DD.MM.YYYY') : '-'}</div>;
                }
            },
            {
                title: <FormattedMessage id='vat_page.vat_type' />,
                key: 'vat_type',
                width: '5%',
                align: 'center',
                children: [
                    {
                        title: <FormattedMessage id='vat_page.out' />,
                        key: 'out',
                        align: 'center',
                        width: '5%',
                        render: (data, row) => {
                            const subjectType = _.get(row, 'objects[0].subjectType', '');
                            const validTypesSet = new Set(['MRD', 'RD', 'RTL', 'OUT', 'RTN', 'CRT']);
                            const vatSum = _.get(row, 'objects[0].vatSum', 0);

                            return (
                                validTypesSet.has(subjectType) && (
                                    <Tag
                                        color={
                                            subjectType === 'RTN' || subjectType === 'CRT'
                                                ? 'var(--warning)'
                                                : 'var(--green)'
                                        }
                                    >
                                        {subjectType === 'RTN' || subjectType === 'CRT' ? (
                                            <FormattedMessage id='vat_page.VAC' />
                                        ) : (
                                            <FormattedMessage id='vat_page.VAR' />
                                        )}
                                    </Tag>
                                )
                            );
                        }
                    },
                    {
                        title: <FormattedMessage id='vat_page.in' />,
                        key: 'in',
                        width: '5%',
                        align: 'center',
                        render: (data, row) => {
                            const subjectType = _.get(row, 'objects[0].subjectType', '');
                            const validTypesSet = new Set(['INC', 'SRV', 'SRT', 'VRT']);
                            const vatSum = _.get(row, 'objects[0].vatSum', 0);

                            return (
                                validTypesSet.has(subjectType) && (
                                    <Tag color={subjectType === 'SRT' ? 'var(--warning)' : 'var(--green)'}>
                                        {subjectType === 'SRT' ? (
                                            <FormattedMessage id='vat_page.VAC' />
                                        ) : (
                                            <FormattedMessage id='vat_page.VAR' />
                                        )}
                                    </Tag>
                                )
                            );
                        }
                    }
                ]
            },
            {
                title: <FormattedMessage id='vat_page.vat_direction' />,
                key: 'dir',
                width: '5%',
                align: 'center',
                ...getColumnSearchProps('direction'),
                render: (data, row) => (
                    <div>
                        <FormattedMessage id={`vat_page.${_.get(row, 'objects[0].direction')}`} />
                    </div>
                )
            },
            {
                title: <FormattedMessage id='client_container.document_type' />,
                key: 'doc_type',
                width: '5%',
                align: 'center',
                render: (data, row) => <div>{_.get(row, 'objects[0].subjectType')}</div>
            },
            {
                title: <FormattedMessage id='vat_page.doc_sum' />,
                key: 'doc_sum',
                width: '6%',
                align: 'right',
                render: (data, row) => {
                    const sum = _.get(row, 'objects[0].subjectSum', 0);

                    return (
                        <div>
                            <Numeral mask='0,0.00'>{Number(sum)}</Numeral> ₴
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='vat_page.vat_sum' />,
                key: 'vat_sum',
                width: '6%',
                align: 'right',
                render: (data, row) => {
                    const vatSum = _.get(row, 'objects[0].vatSum', 0);

                    return (
                        <div>
                            <Numeral mask='0,0.00'>{Number(vatSum)}</Numeral> ₴
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='vat_page.registered' />,
                key: 'registered',
                width: '5%',
                align: 'center',
                ...getColumnSearchProps('status'),
                render: (data, row) => {
                    const status = _.get(row, 'objects[0].status');

                    return <Space>{!status ? <FormattedMessage id='no' /> : <FormattedMessage id='yes' />}</Space>;
                }
            },
            {
                title: <FormattedMessage id='tax_invoice' />,
                key: 'invoice',
                width: '6%',
                ...getColumnSearchProps('createdDocument'),
                render: (data, row) => {
                    const document = _.get(row, 'objects[0].document');

                    return (
                        <Space>
                            <span
                                className={Styles.link}
                                onClick={async () => {
                                    if (document) {
                                        Modal.confirm({
                                            title: intl.formatMessage({
                                                id: 'receipt_document_modal.export'
                                            }),
                                            content: (
                                                <div className={Styles.dflex}>
                                                    <div>
                                                        <Button
                                                            onClick={async () => {
                                                                await exportZip(false, row?.objects[0].rowId);
                                                                Modal.destroyAll();
                                                            }}
                                                            type='primary'
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'salary-report-form_detailing_labors.with_detailing'
                                                            })}
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            onClick={async () => {
                                                                await exportZip(true, row?.objects[0].rowId);
                                                                Modal.destroyAll();
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'salary-report-form_detailing_labors.without_detaling'
                                                            })}
                                                        </Button>
                                                    </div>
                                                </div>
                                            ),
                                            footer: null,
                                            maskClosable: true
                                        });
                                    }
                                }}
                            >
                                {document}
                            </span>
                        </Space>
                    );
                }
            },
            {
                title: <FormattedMessage id='error' />,
                key: 'error',
                width: '40%',
                render: (data, row) => {
                    const error = _.get(row, 'objects[0].error');

                    return (
                        <span
                            style={{
                                color: 'var(--warning)'
                            }}
                        >
                            {error || undefined}
                        </span>
                    );
                }
            }
        ];
    }, [getColumnSearchProps, user, intl, exportZip]);

    // Actions
    const handleRegisterVAT = useCallback(() => {
        const medoc = get(user, 'medoc', {});
        selectedRows.forEach(async ({ subject, status }, index) => {
            if (!status) return;

            const resp = await fetchAPI(
                'POST',
                'api/Document/MakeDoc',
                {
                    userLogin: medoc.login,
                    edrpou: medoc.edrpou,
                    dept: medoc.dept,
                    shablonType: 1, // 1 - Звітний документ; 2 - Первинний документ
                    charCode: subject
                },
                null,
                { handleErrorInternally: true, url: medoc.medocUrl }
            );
            selectedRows[index].cardCode = get(resp, 'cardCode');
        });
    }, [selectedRows, user]);

    const handleUpdateStatus = useCallback(() => {
        const medoc = get(user, 'medoc', {});
        selectedRows.forEach(async ({ cardCode }, index) => {
            if (!cardCode) return;
            const resp = await fetchAPI('GET', 'api/Info/GetDocStatus', { cardCode }, null, {
                handleErrorInternally: true,
                url: medoc.medocUrl
            });
            selectedRows[index].status = get(resp, 'status');
        });
    }, [selectedRows, user]);

    const handleUnlockDocs = useCallback(async () => {
        const medoc = get(user, 'medoc', {});

        const rowIds = selectedRows.map(({ objects }) => objects[0]?.rowId);
        await fetchAPI(
            'PUT',
            '/business/vat/journal',
            null,
            { rowIds },
            {
                handleErrorInternally: true,
                url: medoc.medocUrl
            }
        );

        await setTimeout(() => getVATInfo(), 500);
    }, [getVATInfo, selectedRows, user]);

    const isButtonDisabledPN = !selectedRows.every(row =>
        ['MRD', 'RD', 'RTL', 'OUT'].includes(_.get(row, 'objects[0].subjectType', '')));
    const isButtonDisabledPK = !selectedRows.every(row =>
        ['RTN', 'CRT'].includes(_.get(row, 'objects[0].subjectType', '')));

    // Row selection with menu
    const actionsMenu = useMemo(
        () => (
            <Menu>
                <Menu.Item
                    key='create'
                    disabled={!selectedRowKeys.length || !get(user, 'medoc.edrpou')}
                    onClick={handleRegisterVAT}
                >
                    <Space>
                        <DiffOutlined />
                        <FormattedMessage id='new-document-page.item.journal-vat_declaration' />
                    </Space>
                </Menu.Item>
                <Menu.Item
                    key='update'
                    disabled={!selectedRowKeys.length || !get(user, 'medoc.edrpou')}
                    onClick={handleUpdateStatus}
                >
                    <Space>
                        <RedoOutlined />
                        <FormattedMessage id='orders.update_stage' />
                    </Space>
                </Menu.Item>
                <Menu.Item
                    key='exportPN'
                    disabled={
                        !selectedRowKeys.length ||
                        isButtonDisabledPN ||
                        selectedRows.find(({ direction }) => direction === 'in')
                    }
                    onClick={() => {
                        // eslint-disable-next-line no-alert
                        Modal.confirm({
                            title: intl.formatMessage({
                                id: 'receipt_document_modal.export'
                            }),
                            content: confirmContent,
                            footer: null,
                            maskClosable: true
                        });
                    }}
                >
                    <Space>
                        <ExportOutlined />
                        <FormattedMessage id='new-document-page.item.journal-vat.export_pn' />
                    </Space>
                </Menu.Item>
                <Menu.Item
                    key='exportPK'
                    // disabled={
                    //     !selectedRowKeys.length ||
                    //     isButtonDisabledPK ||
                    //     selectedRows.find(({ direction }) => direction === 'in')
                    // }
                    disabled
                    onClick={() => {
                        Modal.confirm({
                            title: intl.formatMessage({
                                id: 'receipt_document_modal.export'
                            }),
                            content: confirmContent,
                            footer: null,
                            maskClosable: true
                        });
                    }}
                >
                    <Space>
                        <ExportOutlined />
                        <FormattedMessage id='new-document-page.item.journal-vat.export_pk' />
                    </Space>
                </Menu.Item>

                <Menu.Item
                    key='unlock'
                    disabled={!selectedRowKeys.length || selectedRows.find(({ objects }) => !objects[0]?.status)}
                    onClick={handleUnlockDocs}
                >
                    <Space>
                        <UnlockOutlined />
                        <FormattedMessage id='new-document-page.item.journal-vat.unlock_doc' />
                    </Space>
                </Menu.Item>
            </Menu>
        ),
        [
            selectedRowKeys.length,
            user,
            handleRegisterVAT,
            handleUpdateStatus,
            isButtonDisabledPN,
            isButtonDisabledPK,
            selectedRows,
            handleUnlockDocs,
            intl,
            confirmContent
        ]
    );

    const rowSelection = useMemo(
        () => ({
            selectedRowKeys,
            onChange: (keys, rows) => {
                setSelectedRowKeys(keys);
                setSelectedRows(rows);
            },
            columnTitle: <HamburgerMenu actionsMenu={actionsMenu} destroyPopupOnHide />
        }),
        [actionsMenu, selectedRowKeys]
    );

    // Table pagination
    const pagination = useMemo(
        () => ({
            pageSize,
            total: Math.ceil(count / pageSize) * pageSize,
            hideOnSinglePage: false,
            showSizeChanger: true,
            current: page,
            onChange: async (newPage, newPageSize) => {
                setPage(newPage);
                setPageSize(newPageSize);
            }
        }),
        [page, pageSize, count]
    );

    return (
        <Layout controls={<Space />} paper title={<FormattedMessage id='navigation.journal_vat' />}>
            <Table
                bordered
                columns={columns}
                dataSource={vat}
                pagination={pagination}
                rowKey='id'
                rowSelection={rowSelection}
                size='small'
            />
        </Layout>
    );
}
