import { DatePicker, Input, Modal, Select, TreeSelect } from 'antd';
import { DateRangePicker } from 'components';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { fetchEmployees } from 'core/employees/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { Component, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildStoreGroupsTree, fetchAPI, normalizeText, orderTypes } from 'utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    clients: state.clients.clients,
    user: state.auth
});

const mapDispatchToProps = {
    resetModal,
    fetchClients,
    fetchEmployees,
    setClientsSearchFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class UniversalOrderReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month'),
            fromDeliveryDate: dayjs().startOf('month'),
            toDeliveryDate: dayjs().startOf('month'),
            stations: [],
            employees: []
        };

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    componentDidUpdate(prevProps) {
        const { modalProps } = this.props;
        const { type } = modalProps;
        if (!prevProps.visible && this.props.visible) {
            this.fetchRequisites();
            if (type !== 'BY_PRODUCTS' && type !== 'BY_LABORS') {
                this.getAllEmployees();
                this.getStations();

                this.props.fetchClients();
            }

            this.setState({
                statusName: 'success',
                dateType: 'DONE'
            });
        }
    }

    handleSearchEmployees = _.debounce(value => {
        this.getAllEmployees(value);
    }, 1000);

    getAllEmployees = async query => {
        const employees = await fetchAPI('GET', 'employees', { query }, null, { handleErrorInternally: true });
        this.setState({
            employees
        });
    };

    handleOk = async () => {
        const { modalProps } = this.props;
        const { type } = modalProps;

        const {
            vehicleId,
            fromDate,
            toDate,
            fromDeliveryDate,
            toDeliveryDate,
            clientId,
            businessRequisiteId,
            masterLaborId,
            storeGroupId,
            laborCode,
            brandId,
            detailCode,
            managerId,
            employeeId,
            appurtenanciesResponsibleId,
            statusName,
            stationNum,
            orderType,
            dateType
        } = this.state;

        const getResponse = () => {
            if (type === 'BY_PRODUCTS') {
                return fetchAPI(
                    'GET',
                    'report/excel/orders/universal/byProducts',
                    {
                        fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                        toDate: dayjs(toDate).format('YYYY-MM-DD'),
                        clientId,
                        vehicleId,
                        businessRequisiteId,
                        storeGroupId,
                        brandId,
                        detailCode
                    },
                    null,
                    { rawResponse: true, handleErrorInternally: true }
                );
            }
            if (type === 'BY_LABORS') {
                return fetchAPI(
                    'GET',
                    'report/excel/orders/universal/byLabors',
                    {
                        fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                        toDate: dayjs(toDate).format('YYYY-MM-DD'),
                        clientId,
                        vehicleId,
                        businessRequisiteId,
                        masterLaborId,
                        storeGroupId,
                        laborCode
                    },
                    null,
                    { rawResponse: true, handleErrorInternally: true }
                );
            }

            return fetchAPI(
                'GET',
                'report/excel/orders/universal',
                {
                    fromDate: dateType == 'DONE' ? dayjs(fromDate).format('YYYY-MM-DD') : undefined,
                    toDate: dateType == 'DONE' ? dayjs(toDate).format('YYYY-MM-DD') : undefined,
                    fromDeliveryDate: dateType == 'DELIVERY' ? dayjs(fromDeliveryDate).format('YYYY-MM-DD') : undefined,
                    toDeliveryDate: dateType == 'DELIVERY' ? dayjs(toDeliveryDate).format('YYYY-MM-DD') : undefined,
                    clientId,
                    vehicleId,
                    businessRequisiteId,
                    managerId: managerId || null,
                    employeeId: employeeId || null,
                    appurtenanciesResponsibleId: appurtenanciesResponsibleId || null,
                    status: statusName || null,
                    stationNum: stationNum || null,
                    orderType: orderType || undefined
                },
                null,
                { rawResponse: true, handleErrorInternally: true }
            );
        };
        const response = await getResponse();
        const reportFile = await response.blob();
        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        saveAs(reportFile, fileName);
        this.handleCancel();
    };

    handleCancel = () => {
        this.props.resetModal();
        this.setState({
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month'),
            fromDeliveryDate: dayjs().startOf('month'),
            toDeliveryDate: dayjs().startOf('month'),
            dateType: 'DONE',
            vehicleId: undefined,
            clientId: undefined,
            businessRequisiteId: undefined,
            masterLaborId: undefined,
            storeGroupId: undefined,
            laborCode: undefined,
            brandId: undefined,
            detailCode: undefined,
            managerId: undefined,
            appurtenanciesResponsibleId: undefined,
            employeeId: undefined
        });
    };

    fetchRequisites = async () => {
        const requisites = await fetchAPI('GET', 'businesses/requisites', null, null, {
            handleErrorInternally: true
        });
        this.setState({
            requisites
        });
    };

    getStations = async () => {
        const stations = await fetchAPI('GET', '/businesses/stations', null, null, { handleErrorInternally: true });

        this.setState({ stations });
    };

    render() {
        const {
            modalProps,
            resetModal,
            visible,
            clients,
            intl: { formatMessage }
        } = this.props;

        const {
            vehicleId,
            fromDate,
            toDate,
            fromDeliveryDate,
            toDeliveryDate,
            clientId,
            vehicles,
            requisites,
            businessRequisiteId,
            managerId,
            employeeId,
            appurtenanciesResponsibleId,
            statusName,
            stations,
            stationNum,
            dateType,
            employees,
            orderType
        } = this.state;

        const { type } = modalProps;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    maskClosable={false}
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={
                        type === 'BY_PRODUCTS' ? (
                            <FormattedMessage id='reports_page.universal_by_details' />
                        ) : type === 'BY_LABORS' ? (
                            <FormattedMessage id='reports_page.universal_by_labors' />
                        ) : (
                            <FormattedMessage id='reports_page.universal_by_orders' />
                        )
                    }
                    visible={visible === MODALS.UNIVERSAL_ORDER_REPORT_MODAL}
                    width='fit-content'
                >
                    {type === 'BY_PRODUCTS' && (
                        <ByProductFields
                            formatMessage={formatMessage}
                            setState={field => this.setState(field)}
                            {...this.state}
                        />
                    )}
                    {type === 'BY_LABORS' && (
                        <ByLaborsFields
                            formatMessage={formatMessage}
                            setState={field => this.setState(field)}
                            {...this.state}
                        />
                    )}
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={businessRequisiteId => {
                            this.setState({
                                businessRequisiteId
                            });
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({ id: 'navigation.requisites' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={businessRequisiteId}
                    >
                        {(requisites || []).map(({ id, name, ifi }) => (
                            <Option key={id} value={id}>
                                {name} {ifi && ` (${ifi})`}
                            </Option>
                        ))}
                    </Select>
                    {type !== 'BY_PRODUCTS' && type !== 'BY_LABORS' && (
                        <div>
                            <Select
                                allowClear
                                filterOption={false}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        managerId: value
                                    });
                                }}
                                onSearch={input => this.handleSearchEmployees(input)}
                                placeholder={formatMessage({ id: 'orders.responsible' })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={managerId}
                            >
                                {employees
                                    ? employees
                                          .filter(
                                              ({ posts, disabled }) =>
                                                  !disabled &&
                                                  posts.find(({ postName }) => postName == 'MASTER_RECEIVER')
                                          )
                                          .map(emp => (
                                              <Option key={emp.managerId} value={emp.managerId}>
                                                  {`${emp.name || ''} ${emp.surname}  `}
                                              </Option>
                                          ))
                                    : undefined}
                            </Select>
                            <Select
                                allowClear
                                filterOption={false}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        employeeId: value
                                    });
                                }}
                                onSearch={input => this.handleSearchEmployees(input)}
                                placeholder={formatMessage({ id: 'employee.is_mechanic' })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={employeeId}
                            >
                                {employees
                                    ? employees
                                          .filter(
                                              ({ posts, disabled }) =>
                                                  !disabled && posts.find(({ postName }) => postName == 'MECHANIC')
                                          )
                                          .map(emp => (
                                              <Option key={emp.id} value={emp.id}>
                                                  {`${emp.name || ''} ${emp.surname}  `}
                                              </Option>
                                          ))
                                    : undefined}
                            </Select>
                            <Select
                                allowClear
                                filterOption={false}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        appurtenanciesResponsibleId: value
                                    });
                                }}
                                onSearch={input => this.handleSearchEmployees(input)}
                                placeholder={formatMessage({ id: 'employee_salary_rules.SPARE_PARTS_SPECIALIST' })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={appurtenanciesResponsibleId}
                            >
                                {employees
                                    ? employees
                                          .filter(
                                              ({ posts, disabled }) =>
                                                  !disabled &&
                                                  posts.find(({ postName }) => postName == 'SPARE_PARTS_SPECIALIST')
                                          )
                                          .map(emp => (
                                              <Option key={emp.id} value={emp.id}>
                                                  {`${emp.name || ''} ${emp.surname}  `}
                                              </Option>
                                          ))
                                    : undefined}
                            </Select>
                            <Select
                                allowClear
                                filterOption={false}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        statusName: value
                                    });
                                }}
                                onSearch={this.handleSearchClients}
                                placeholder={formatMessage({ id: 'status' })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={statusName}
                            >
                                <Option key='call' value='call'>
                                    <FormattedMessage id='order_statuses_mapper.call' />
                                </Option>
                                <Option key='not_complete' value='not_complete'>
                                    <FormattedMessage id='order_statuses_mapper.not_complete' />
                                </Option>
                                <Option key='progress' value='progress'>
                                    <FormattedMessage id='order_statuses_mapper.progress' />
                                </Option>
                                <Option key='reserve' value='reserve'>
                                    <FormattedMessage id='order_statuses_mapper.reserve' />
                                </Option>
                                <Option key='required' value='required'>
                                    <FormattedMessage id='order_statuses_mapper.required' />
                                </Option>
                                <Option key='success' value='success'>
                                    <FormattedMessage id='order_statuses_mapper.success' />
                                </Option>
                            </Select>
                            <Select
                                allowClear
                                filterOption={false}
                                getPopupContainer={trigger => trigger.parentNode}
                                mode='multiple'
                                onChange={value => {
                                    this.setState({
                                        orderType: value
                                    });
                                }}
                                onSearch={input => this.handleSearchEmployees(input)}
                                placeholder={formatMessage({ id: 'storage_funds.order_type' })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={orderType}
                            >
                                {orderTypes.map(type => (
                                    <Option key={`${type}-aggr`} value={type}>
                                        <FormattedMessage id={`aggregate.${type}`} />
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                allowClear
                                filterOption={false}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        stationNum: value
                                    });
                                }}
                                onSearch={this.handleSearchClients}
                                placeholder={formatMessage({ id: 'orders.post' })}
                                showSearch
                                style={{
                                    marginBottom: 8
                                }}
                                value={stationNum}
                            >
                                {stations
                                    ? stations.map(station => (
                                          <Option key={station.num} value={station.num}>
                                              {`${station.name || ''}`}
                                          </Option>
                                      ))
                                    : undefined}
                            </Select>
                        </div>
                    )}
                    <Select
                        allowClear
                        filterOption={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={(clientId, option) => {
                            if (option) {
                                this.setState({
                                    clientId,
                                    vehicles: option.props.vehicles
                                });
                            } else {
                                this.setState({
                                    clientId: undefined,
                                    vehicles: undefined
                                });
                            }
                        }}
                        onSearch={this.handleSearchClients}
                        placeholder={formatMessage({ id: 'add_order_form.client' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={clientId}
                    >
                        {clients.map(client => (
                            <Option
                                key={client.clientId}
                                phone={_.get(client, 'phones[0]')}
                                value={client.clientId}
                                vehicles={_.get(client, 'vehicles')}
                            >
                                {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                    client,
                                    'phones[0]',
                                    ''
                                )}`}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        disabled={!vehicles}
                        filterOption={(input, option) => {
                            // Normalize input and vehicle name
                            const normalizedInput = normalizeText(input);
                            const normalizedVehicle = normalizeText(option.children);

                            return normalizedVehicle.includes(normalizedInput);
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={vehicleId => {
                            this.setState({ vehicleId });
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({ id: 'locations.vehicle' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={vehicleId}
                    >
                        {(vehicles || []).map(({ id, make, model, number }) => (
                            <Option key={id} value={id}>
                                {make} {model} {number}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onSelect={async value => {
                            await this.setState({
                                dateType: value
                            });
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({ id: 'reports_page.universal.date_type' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={dateType}
                    >
                        <Option key='DONE' value='DONE'>
                            <FormattedMessage id='reports_page.universal.done_date' />
                        </Option>
                        <Option key='DELIVERY' value='DELIVERY'>
                            <FormattedMessage id='reports_page.universal.delivery_date' />
                        </Option>
                    </Select>
                    <DateRangePicker
                        dateRange={dateType === 'DONE' ? [fromDate, toDate] : [fromDeliveryDate, toDeliveryDate]}
                        onDateChange={arr => {
                            if (dateType === 'DONE') {
                                this.setState({
                                    fromDate: arr[0],
                                    toDate: arr[1]
                                });
                            } else if (dateType === 'DELIVERY') {
                                this.setState({
                                    fromDeliveryDate: arr[0],
                                    toDeliveryDate: arr[1]
                                });
                            }
                        }}
                    />
                </Modal>
            </div>
        );
    }
}

const ByProductFields = props => {
    const [brands, setBrands] = useState([]);
    const [storeGroups, setStoreGroups] = useState([]);

    async function fetchBrands() {
        const brands = await fetchAPI('GET', 'brands');
        setBrands(brands);
    }

    async function fetchstoreGroups() {
        const storeGroups = await fetchAPI('GET', 'store_groups');
        setStoreGroups(buildStoreGroupsTree(storeGroups));
    }

    useEffect(() => {
        fetchBrands();
        fetchstoreGroups();
    }, []);

    return (
        <React.Fragment>
            <TreeSelect
                getPopupContainer={trigger => trigger.parentNode}
                onSelect={storeGroupId => {
                    props.setState({ storeGroupId });
                }}
                optionFilterProp='children'
                placeholder={props.formatMessage({ id: 'order_form_table.detail_code' })}
                showSearch
                style={{
                    marginBottom: 8
                }}
                treeData={storeGroups}
                value={props.storeGroupId}
            />
            <Select
                allowClear
                getPopupContainer={trigger => trigger.parentNode}
                onChange={brandId => {
                    props.setState({ brandId });
                }}
                optionFilterProp='children'
                placeholder={props.formatMessage({ id: 'order_form_table.brand' })}
                showSearch
                style={{
                    marginBottom: 8
                }}
                value={props.brandId}
            >
                {_.uniqBy(brands, 'brandName').map(({ brandId, brandName }) => (
                    <Option key={brandId} value={brandId}>
                        {brandName}
                    </Option>
                ))}
            </Select>
            <Input
                onChange={({ target: { value } }) => {
                    props.setState({
                        detailCode: value
                    });
                }}
                placeholder={props.formatMessage({ id: 'order_form_table.detail_code' })}
                style={{
                    marginBottom: 8
                }}
                value={props.detailCode}
            />
        </React.Fragment>
    );
};

const ByLaborsFields = props => {
    const [labors, setLabors] = useState([]);
    const [storeGroups, setStoreGroups] = useState([]);

    const fetchLabors = useCallback(async search => {
        const { labors } = await fetchAPI(
            'GET',
            'labors',
            { page: 1, pageSize: 25, search: search ? search.replace(/-/g, '') : undefined },
            null,
            {
                handleErrorInternally: true
            }
        );

        setLabors(labors);
    }, []);

    const handleSearchLabors = useMemo(() => {
        const loadOptions = value => {
            fetchLabors(value);
        };

        return _.debounce(loadOptions, 500);
    }, [fetchLabors]);

    async function fetchstoreGroups() {
        const storeGroups = await fetchAPI('GET', 'store_groups');
        setStoreGroups(buildStoreGroupsTree(storeGroups));
    }

    useEffect(() => {
        fetchLabors();
        fetchstoreGroups();
    }, [fetchLabors]);

    return (
        <React.Fragment>
            <Select
                allowClear
                filterOption={false}
                getPopupContainer={trigger => trigger.parentNode}
                onChange={masterLaborId => {
                    props.setState({ masterLaborId });
                }}
                onSearch={input => handleSearchLabors(input)}
                optionFilterProp='children'
                placeholder={props.formatMessage({ id: 'order_form_table.service_type' })}
                showSearch
                style={{
                    marginBottom: 8
                }}
                value={props.masterLaborId}
            >
                {_.uniqBy(labors, 'masterLaborId').map(({ masterLaborId, masterLaborName }) => (
                    <Option key={masterLaborId} value={masterLaborId}>
                        {masterLaborName} (#{masterLaborId})
                    </Option>
                ))}
            </Select>
            <TreeSelect
                getPopupContainer={trigger => trigger.parentNode}
                onSelect={storeGroupId => {
                    props.setState({ storeGroupId });
                }}
                optionFilterProp='children'
                placeholder={props.formatMessage({ id: 'order_form_table.detail_code' })}
                showSearch
                style={{
                    marginBottom: 8
                }}
                treeData={storeGroups}
                value={props.storeGroupId}
            />
            <Input
                onChange={({ target: { value } }) => {
                    props.setState({
                        laborCode: value
                    });
                }}
                placeholder={props.formatMessage({ id: 'order_form_table.labors_code' })}
                style={{
                    marginBottom: 8
                }}
                value={
                    props.laborCode
                    // ? props.laborCode
                    // : props.masterLaborId || props.storeGroupId
                    //     ? `${props.masterLaborId || '0000'}-${props.storeGroupId || '0000000'}`
                    //     : undefined
                }
            />
        </React.Fragment>
    );
};
