import { Form } from '@ant-design/compatible';
import { Button, Flex, Modal, Select, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { CashboxRSTIcon } from 'commons';
import { fetchCashboxes } from 'core/cash/duck';
import {
    createCashOrder,
    fetchAnalytics,
    fetchCashOrderNextId,
    selectAnalytics,
    selectCashOrderNextId
} from 'core/forms/cashOrderForm/duck';
import { onChangeToSuccessForm } from 'core/forms/toSuccessForm/duck';
import dayjs from 'dayjs';
import { cashlessPaymentTypes, paymentTypes } from 'forms/CashCreationForm/config';
import { cashOrderTypes } from 'forms/CashOrderForm/config';
import { DecoratedInput, DecoratedInputNumber, DecoratedSelect, DecoratedSwitch } from 'forms/DecoratedFields';
import _, { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    fetchAPI,
    isForbidden,
    moveDigitsToFront,
    numeralFormatter2,
    numeralParser,
    permissions,
    withReduxForm2
} from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const cx = classNames.bind(Styles);
const { Option } = Select;

@injectIntl
@withReduxForm2({
    name: 'toSuccessForm',
    actions: {
        change: onChangeToSuccessForm,
        createCashOrder,
        fetchCashboxes,
        fetchCashOrderNextId,
        fetchAnalytics
    },
    mapStateToProps: state => ({
        businessName: state.auth.businessName,
        user: state.auth,
        cashboxesAll: state.cash.cashboxes,
        cashOrderNextId: selectCashOrderNextId(state),
        analytics: selectAnalytics(state)
    })
})
export class ToSuccessForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        };
    }

    componentDidMount() {
        const { fetchAnalytics } = this.props;

        fetchAnalytics();
        this.fetchNotification();
    }

    componentDidUpdate(nextProps) {
        const { form } = this.props;

        const cashlessPaymentType = form.getFieldValue('cashlessPaymentType');
        const cardMask = form.getFieldValue('cardMask');

        if (nextProps.fields.withPayment !== this.props.fields.withPayment) {
            if (_.isEmpty(this.props.cashboxes)) {
                this.props.fetchCashboxes();
            }
            const withPayment = this.props.form.getFieldValue('withPayment');
            if (this.props.form.getFieldValue('withPayment')) {
                this.props.fetchCashOrderNextId();
            }
        }

        if (nextProps.cashboxesAll !== this.props.cashboxesAll && !this.state.cashboxType) {
            const cashboxes = this.props.cashboxesAll.filter(
                cashbox =>
                    !this.props.businessRequisiteId ||
                    !cashbox.businessRequisiteId ||
                    cashbox.businessRequisiteId == this.props.businessRequisiteId
            );
            this.setState({
                cashboxRst: get(cashboxes, '[0].rst'),
                cashboxType: get(cashboxes, '[0].type')
            });
        }
        // if (cashlessPaymentType === 'POS_TERMINAL' && !cardMask) {
        //     form.setFieldsValue({ cardMask: '**** **** **** ****' });
        // }
    }

    componentWillUnmount() {
        this.props.form.resetFields();
    }

    fetchNotification = async () => {
        const notificationSettings = await fetchAPI('GET', 'notification_settings');
        const alphaName = await fetchAPI('GET', 'business', undefined, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            notificationSettings,
            alphaName: alphaName ? alphaName.alphaName : undefined
        });
    };

    _submit = () => {
        const {
            form,
            onStatusChange,
            createCashOrder,
            resetModal,
            orderId,
            clientId,
            storeDocId,
            onSubmit,
            analytics,
            reload,
            cashboxesAll
        } = this.props;

        const { formatMessage } = this.props.intl;

        const smsMessage = form.getFieldValue('smsMessage');
        const createRecommendations = form.getFieldValue('createRecommendations');

        const paymentType = form.getFieldValue('paymentMethod');
        const cardMask = `${form.getFieldValue('cardMaskStart') || '****'} **** **** ${
            form.getFieldValue('cardMaskEnd') || '****'
        }`;
        const cashboxType = cashboxesAll.find(({ id }) => id === form.getFieldValue('cashBoxId'));

        const regex = /^([0-9*]{4} ){3}[0-9*]{4}$/;

        const okFunc = () => {
            form.validateFields((err, values) => {
                if (!err) {
                    if (onStatusChange) {
                        onStatusChange('success', void 0, {
                            smsMessage,
                            createRecommendations
                        });
                    } else if (onSubmit) {
                        onSubmit();
                    }

                    if (values.withPayment && Boolean(values.paymentSum)) {
                        const cardMask = `${values.cardMaskStart || '****'} **** **** ${values.cardMaskEnd || '****'}`;
                        createCashOrder({
                            cashBoxId: values.cashBoxId,
                            clientId,
                            datetime: dayjs(),
                            id: this.props.cashOrderNextId,
                            increase: values.paymentSum,
                            orderId,
                            type: cashOrderTypes.INCOME,
                            storeDocId,
                            analyticsUniqueId: analytics && analytics[0].analyticsUniqueId,
                            paymentMethod: values.paymentMethod,
                            cashlessPaymentType: values.paymentMethod === 'CASH' ? null : values.cashlessPaymentType,
                            cardMask: cardMask === '**** **** **** ****' ? null : cardMask,
                            authCode: values.authCode,
                            paymentSystem: values.paymentSystem,
                            rrn: values.rrn,
                            value: values.cash,
                            cashlessPaymentTypeMeans: values.cashlessPaymentTypeMeans,
                            receiptNo: values.receiptNo
                            // cashCollected: values.cash,
                            // cashRemaining: values.remaining
                        });
                    }

                    resetModal();
                    form.resetFields();
                }
            });

            // reload && setTimeout(() => window.location.reload(), 500);
            reload && setTimeout(() => reload(), 500);
        };

        if (
            cashboxType &&
            cashboxType.type !== paymentType &&
            (cashboxType.type === 'CAHS' || paymentType === 'CASH')
        ) {
            Modal.confirm({
                title: formatMessage({ id: 'cash_and_payment_types_warning' }),
                onOk: okFunc
            });
        } else if (cardMask && !regex.test(cardMask)) {
            Modal.error({
                title: formatMessage({ id: 'card_mask_incorrect' }),
                content: '**** **** **** ****'
            });
        } else {
            okFunc();
        }
    };

    _setPayment = event => {
        this.props.form.resetFields(['partialPayment']);
        this.props.form.setFieldsValue({ paymentSum: this.props.remainPrice });
    };

    setPaymentCashFirst = event => {
        const { getFieldValue } = this.props.form;
        const cash = getFieldValue('cash');

        if (cash > 0) {
            this.props.form.setFieldsValue({
                paymentSum: event,
                remaining: cash > event ? Number(Math.abs(cash - event).toFixed(2)) : 0
            });
        }
    };

    setPaymentCashSecond = event => {
        const { getFieldValue } = this.props.form;
        const paymentSum = getFieldValue('paymentSum');
        if (paymentSum > 0) {
            this.props.form.setFieldsValue({
                cash: event,
                remaining: event > paymentSum ? Number(Math.abs(event - paymentSum).toFixed(2)) : 0
            });
        }
    };

    _setSms = value => {
        this.props.form.setFieldsValue({ smsMessage: value });
    };

    _setPartialPayment = event => {
        this.props.form.setFieldsValue({ paymentSum: this.props.remainPrice });
    };

    _hiddenFormItemStyles = type =>
        cx({
            hiddenFormItem: !type,
            styledFormItem: true
        });

    render() {
        const {
            handleToSuccessModalSubmit,
            resetModal,
            businessName,
            remainPrice,
            cashboxesAll,
            storeDocId,
            user,
            businessRequisiteId,
            precision,
            isRTN
        } = this.props;
        const cashboxes = cashboxesAll.filter(
            cashbox =>
                !businessRequisiteId ||
                !cashbox.businessRequisiteId ||
                cashbox.businessRequisiteId == businessRequisiteId
        );

        const { disabled, cashboxRst, cashboxType } = this.state;

        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;

        const cashboxId = getFieldValue('cashBoxId');
        const cashbox = cashboxes.find(({ id }) => id === cashboxId);

        const withPayment = getFieldValue('withPayment');

        const smsMessage = getFieldValue('smsMessage');

        const cardMaskStart = getFieldValue('cardMaskStart');

        const paymentType = getFieldValue('paymentMethod');
        const cashlessPaymentType = getFieldValue('cashlessPaymentType');

        const { formatMessage } = this.props.intl;

        const remain = Number(Math.abs(remainPrice).toFixed(2));

        return (
            <Form>
                <div className={Styles.title}>
                    {!storeDocId ? (
                        <FormattedMessage id='to_success.title' />
                    ) : (
                        <FormattedMessage id='to_success_store_doc.title' />
                    )}
                </div>
                <div className={Styles.submit}>
                    <Button
                        className={Styles.submitButton}
                        data-qa='button_submit_yes_to_success_form'
                        disabled={disabled}
                        onClick={() => this._submit()}
                        type='primary'
                    >
                        <FormattedMessage id='yes' />
                    </Button>
                    <Button className={Styles.submitButton} onClick={() => resetModal()}>
                        <FormattedMessage id='no' />
                    </Button>
                </div>
                <React.Fragment>
                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_PAYMENT) && (
                        <div className={Styles.checkboxStyle}>
                            <span>
                                <FormattedMessage id='to_success.pay' />
                            </span>
                            <div>
                                <DecoratedSwitch
                                    field='withPayment'
                                    formItem
                                    getFieldDecorator={getFieldDecorator}
                                    initialValue={false}
                                    onChange={event => this._setPayment(event)}
                                />
                            </div>
                        </div>
                    )}
                    {withPayment && (
                        <div className={Styles.blockFilling}>
                            <DecoratedSelect
                                className={this._hiddenFormItemStyles(getFieldValue('withPayment'))}
                                field='cashBoxId'
                                formItem
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                initialValue={_.get(cashboxes, '[0].id')}
                                label={formatMessage({
                                    id: 'cash-order-form.cashbox'
                                })}
                                onSelect={async (value, option) => {
                                    const cashbox = cashboxes.find(({ id }) => value === id);
                                    await setFieldsValue({
                                        paymentMethod: cashbox?.type === 'CASH' ? 'CASH' : 'CASHLESS'
                                    });
                                    setFieldsValue({
                                        cashlessPaymentType: cashbox?.type === 'CASH' ? null : 'POS_TERMINAL'
                                    });

                                    this.setState({
                                        disabled: option.props.rst && option.props.onlineCashdeskState === 'CLOSED',
                                        cashboxRst: cashbox?.rst,
                                        cashboxType: cashbox?.type
                                    });
                                }}
                                placeholder={formatMessage({
                                    id: 'cash-order-form.cashbox.placeholder'
                                })}
                                rules={[
                                    {
                                        required: Boolean(getFieldValue('withPayment')),
                                        message: formatMessage({
                                            id: 'required_field'
                                        })
                                    }
                                ]}
                                showSearch
                            >
                                {cashboxes
                                    ? cashboxes
                                          .filter(({ active }) => active)
                                          .map(obj => {
                                              const { id, name, rst, onlineCashdeskState, isShiftOpen } = obj;

                                              return (
                                                  <Option
                                                      key={id}
                                                      disabled={
                                                          (rst && isForbidden(user, permissions.ACCESS_SALE_RST)) ||
                                                          (rst && !isShiftOpen)
                                                      }
                                                      onlineCashdeskState={onlineCashdeskState}
                                                      rst={rst}
                                                      value={id}
                                                  >
                                                      <span>{name}</span>
                                                      <span style={{ float: 'right' }}>
                                                          {rst ? (
                                                              <CashboxRSTIcon
                                                                  isShiftOpen={isShiftOpen}
                                                                  onlineCashdeskState={onlineCashdeskState}
                                                              />
                                                          ) : null}
                                                      </span>
                                                  </Option>
                                              );
                                          })
                                    : undefined}
                            </DecoratedSelect>

                            <div className={Styles.rightAlignedInput}>
                                <DecoratedInputNumber
                                    className={this._hiddenFormItemStyles(getFieldValue('partialPayment'))}
                                    colon={false}
                                    defaultGetValueProps
                                    formatter={numeralFormatter2}
                                    getFieldDecorator={getFieldDecorator}
                                    initialValue={remain}
                                    label={formatMessage({ id: 'sum' })}
                                    min={0}
                                    onChange={value => this.setPaymentCashFirst(Number(value))}
                                    parser={numeralParser}
                                    precision={2}
                                    rules={[
                                        {
                                            required: Boolean(getFieldValue('withPayment')),
                                            type: 'number',
                                            message: formatMessage({
                                                id: 'required_field'
                                            })
                                        }
                                    ]}
                                    field='paymentSum'
                                    // formatter={val => numeralFormatter4Digits(val, precision)}
                                    formItem
                                />
                            </div>
                            {cashboxRst && (
                                <div className={Styles.rightAlignedInput}>
                                    <Tooltip
                                        placement='top'
                                        title={<FormattedMessage id='payment_type' />}
                                        zIndex={2001}
                                    >
                                        <DecoratedSelect
                                            field='paymentMethod'
                                            formItem
                                            getFieldDecorator={getFieldDecorator}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            initialValue={_.get(cashbox, 'type') === 'CASH' ? 'CASH' : 'CASHLESS'}
                                            label={formatMessage({
                                                id: 'payment_type'
                                            })}
                                            onSelect={async (value, option) => {
                                                await setFieldsValue({});
                                                setFieldsValue({
                                                    cashlessPaymentType: value === 'CASH' ? null : 'POS_TERMINAL'
                                                });
                                                this.setState({});
                                            }}
                                            placeholder={formatMessage({
                                                id: 'payment_type'
                                            })}
                                            showSearch
                                        >
                                            {Object.values(paymentTypes).map(item => (
                                                <Option key={item} value={item}>
                                                    {formatMessage({ id: `cash-creation-form.type-${item}` })}
                                                </Option>
                                            ))}
                                        </DecoratedSelect>
                                    </Tooltip>
                                </div>
                            )}
                            {paymentType === 'CASHLESS' && cashboxRst && (
                                <div className={Styles.rightAlignedInput}>
                                    <Tooltip
                                        placement='top'
                                        title={<FormattedMessage id='cashless_payment_type' />}
                                        zIndex={2001}
                                    >
                                        <DecoratedSelect
                                            field='cashlessPaymentType'
                                            formItem
                                            getFieldDecorator={getFieldDecorator}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            label={formatMessage({
                                                id: 'cashless_payment_type'
                                            })}
                                            onSelect={(value, option) => {}}
                                            placeholder={formatMessage({
                                                id: 'cashless_payment_type'
                                            })}
                                            showSearch
                                        >
                                            {Object.values(cashlessPaymentTypes).map(item => (
                                                <Option key={item} value={item}>
                                                    {formatMessage({ id: `cash-creation-form.type-${item}` })}
                                                </Option>
                                            ))}
                                        </DecoratedSelect>
                                    </Tooltip>
                                </div>
                            )}
                            {paymentType === 'CASHLESS' &&
                                cashboxRst &&
                                ['ACQUIRING', 'SERVICE'].includes(cashlessPaymentType) && (
                                    <div className={Styles.rightAlignedInput}>
                                        <Tooltip
                                            placement='top'
                                            title={<FormattedMessage id='cashles_payment_type_means.tooltip' />}
                                            zIndex={2001}
                                        >
                                            <DecoratedInput
                                                field='cashlessPaymentTypeMeans'
                                                formItem // Disable to prevent prod from coccupting(temp)
                                                getFieldDecorator={getFieldDecorator}
                                                label={formatMessage({ id: 'cashles_payment_type_means' })}
                                                placeholder={formatMessage({
                                                    id: 'cashles_payment_type_means.tooltip'
                                                })}
                                            />
                                        </Tooltip>
                                    </div>
                                )}
                            {paymentType === 'CASHLESS' && cashboxRst && cashlessPaymentType === 'POS_TERMINAL' && (
                                <React.Fragment>
                                    <div className={Styles.rightAlignedInput}>
                                        <Tooltip
                                            placement='top'
                                            title={<FormattedMessage id='receipt_no' />}
                                            zIndex={2001}
                                        >
                                            <DecoratedInput
                                                field='receiptNo'
                                                formItem // Disable to prevent prod from coccupting(temp)
                                                getFieldDecorator={getFieldDecorator}
                                                label={formatMessage({ id: 'receipt_no' })}
                                                onChange={e => {
                                                    setFieldsValue({ receiptNo: e.target.value });
                                                }}
                                                placeholder={formatMessage({ id: 'receipt_no' })}
                                            />
                                        </Tooltip>
                                    </div>
                                    <div
                                        className={Styles.rightAlignedInput}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <p style={{ padding: '8px 0' }}>{formatMessage({ id: 'card_mask' })}</p>
                                        <Flex>
                                            <Tooltip
                                                placement='top'
                                                title={<FormattedMessage id='card_mask_start' />}
                                                zIndex={2001}
                                            >
                                                <DecoratedInput
                                                    field='cardMaskStart'
                                                    formItem // Disable to prevent prod from coccupting(temp)
                                                    getFieldDecorator={getFieldDecorator}
                                                    initialValue='****'
                                                    onChange={async e => {
                                                        await setFieldsValue({});
                                                        const value = moveDigitsToFront(e.target.value);
                                                        if (value.length >= 4) {
                                                            setFieldsValue({
                                                                cardMaskStart: value.substring(0, 4)
                                                            });
                                                        } else {
                                                            setFieldsValue({ cardMaskStart: value.padEnd(4, '*') });
                                                        }
                                                        this.setState({});
                                                    }}
                                                    placeholder={formatMessage({ id: 'card_mask_start' })}
                                                />
                                            </Tooltip>

                                            <Tooltip
                                                placement='top'
                                                title={<FormattedMessage id='card_mask_end' />}
                                                zIndex={2001}
                                            >
                                                <DecoratedInput
                                                    field='cardMaskEnd'
                                                    formItem // Disable to prevent prod from coccupting(temp)
                                                    getFieldDecorator={getFieldDecorator}
                                                    initialValue='****'
                                                    onChange={async e => {
                                                        await setFieldsValue({});
                                                        const { value } = e.target;
                                                        if (value.length >= 4) {
                                                            setFieldsValue({
                                                                cardMaskEnd: value.substring(value.length - 4)
                                                            });
                                                        } else {
                                                            setFieldsValue({ cardMaskEnd: value.padEnd(4, '*') });
                                                        }
                                                        this.setState({});
                                                    }}
                                                    placeholder={formatMessage({ id: 'card_mask_end' })}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </div>

                                    <div className={Styles.rightAlignedInput}>
                                        <Tooltip
                                            placement='top'
                                            title={<FormattedMessage id='card_type' />}
                                            zIndex={2001}
                                        >
                                            <DecoratedSelect
                                                field='paymentSystem'
                                                formItem
                                                getFieldDecorator={getFieldDecorator}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                initialValue='VISA'
                                                label={formatMessage({
                                                    id: 'card_type'
                                                })}
                                                onSelect={(value, option) => {}}
                                                placeholder={formatMessage({
                                                    id: 'card_type'
                                                })}
                                                showSearch
                                            >
                                                {['VISA', 'MASTERCARD'].map(item => (
                                                    <Option key={item} value={item}>
                                                        {item}
                                                    </Option>
                                                ))}
                                            </DecoratedSelect>
                                        </Tooltip>
                                    </div>
                                    <div className={Styles.rightAlignedInput}>
                                        <Tooltip
                                            placement='top'
                                            title={<FormattedMessage id='authorization_code.tooltip' />}
                                            zIndex={2001}
                                        >
                                            <DecoratedInput
                                                field='authCode'
                                                formItem // Disable to prevent prod from coccupting(temp)
                                                getFieldDecorator={getFieldDecorator}
                                                label={formatMessage({ id: 'authorization_code' })}
                                            />
                                        </Tooltip>
                                    </div>
                                    <div className={Styles.rightAlignedInput}>
                                        <Tooltip
                                            placement='top'
                                            title={<FormattedMessage id='rrn.tooltip' />}
                                            zIndex={2001}
                                        >
                                            <DecoratedInput
                                                field='rrn'
                                                formItem // Disable to prevent prod from coccupting(temp)
                                                getFieldDecorator={getFieldDecorator}
                                                label={formatMessage({ id: 'rrn' })}
                                            />
                                        </Tooltip>
                                    </div>
                                </React.Fragment>
                            )}
                            {(paymentType === 'CASH' || (cashboxType === 'CASH' && !cashboxRst)) && (
                                <React.Fragment>
                                    <div className={Styles.rightAlignedInput}>
                                        <DecoratedInputNumber
                                            className={this._hiddenFormItemStyles(getFieldValue('partialPayment'))}
                                                colon={false}
                                                defaultGetValueProps
                                                formatter={numeralFormatter2}
                                                getFieldDecorator={getFieldDecorator}
                                                ignoreVoid0
                                                initialValue={0}
                                                label={formatMessage({ id: 'cash-order-form.cash_collected' })}
                                                min={0}
                                                onChange={value => this.setPaymentCashSecond(Number(value))}
                                                parser={numeralParser}
                                                precision={2}
                                                rules={[
                                                    {
                                                        type: 'number'
                                                    }
                                                ]}
                                                field='cash'
                                                // formatter={val => numeralFormatter4Digits(val, precision)}
                                                formItem
                                        />
                                    </div>
                                    <div className={Styles.rightAlignedInput}>
                                        <DecoratedInputNumber
                                            className={this._hiddenFormItemStyles(getFieldValue('partialPayment'))}
                                            colon={false}
                                            defaultGetValueProps
                                            field='remaining'
                                            formatter={numeralFormatter2}
                                            formItem
                                            getFieldDecorator={getFieldDecorator}
                                            ignoreVoid0
                                            initialValue={0}
                                            label={formatMessage({ id: 'cash-order-form.remaining' })}
                                            min={0}
                                            parser={numeralParser}
                                            readOnly
                                            rules={[
                                                {
                                                    type: 'number'
                                                }
                                            ]}
                                            precision={2}
                                            // formatter={val => numeralFormatter4Digits(val, precision)}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </React.Fragment>

                {!storeDocId && !isRTN && (
                    <React.Fragment>
                        <div className={Styles.checkboxStyle}>
                            <span>
                                <FormattedMessage id='сreate_recommendations' />
                            </span>
                            <div>
                                <DecoratedSwitch
                                    field='createRecommendations'
                                    formItem
                                    getFieldDecorator={getFieldDecorator}
                                    initialValue
                                    onChange={checked => setFieldsValue({ createRecommendations: checked })}
                                />
                            </div>
                        </div>
                        <div className={Styles.checkboxStyle}>
                            <span>
                                <FormattedMessage id='to_success.send_message' />
                            </span>
                            <div>
                                <DecoratedSwitch
                                    field='smsMessage'
                                    formItem
                                    getFieldDecorator={getFieldDecorator}
                                    initialValue={false}
                                    onChange={() => this._setSms(!smsMessage)}
                                />
                            </div>
                        </div>

                        {smsMessage && businessName && (
                            <div className={Styles.blockFilling}>
                                <FormattedMessage id='to_success.sms1' />
                                {businessName}
                                <FormattedMessage id='to_success.sms2' />
                                {remainPrice.toFixed(2)}
                                <FormattedMessage id='to_success.eur' />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </Form>
        );
    }
}
