import { Input, Modal, Select, Tooltip, notification } from 'antd';
import { createStoreGroup } from 'core/storage/storeGroups';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getStorageToken } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    createStoreGroup
};

const EditPhotoFileModal = ({ open, hideModal, edit, fetchFileList, intl: { formatMessage } }) => {
    const [newName, setName] = useState();
    const [orders, setOrders] = useState([]);
    const [orderId, setOrderId] = useState();
    const [orderTxt, setOrderTxt] = useState();
    const [storageProducts, setStorageProducts] = useState([]);
    const [detailId, setDetailId] = useState();
    const [detTxt, setDetTxt] = useState();

    const fetchOrders = useMemo(
        () => async query => {
            const { orders } = await fetchAPI('GET', 'orders', { query, pageSize: 50 }, null, {
                handleErrorInternally: true
            });

            setOrders(orders);
        },
        []
    );

    const getStorageProductsByQuery = useMemo(
        () => async query => {
            const { list: storageProducts } = await fetchAPI(
                'GET',
                'store_products',
                { query, pageSize: 25, withoutPhoto: true },
                null,
                {
                    handleErrorInternally: true
                }
            );
            setStorageProducts(storageProducts);
        },
        []
    );

    const handleSearchOrders = useMemo(() => {
        const loadOptions = value => {
            fetchOrders(value);
        };

        return _.debounce(loadOptions, 500);
    }, [fetchOrders]);

    const debouncedQuerySearch = useMemo(() => {
        const loadOptions = value => {
            getStorageProductsByQuery(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [getStorageProductsByQuery]);

    useEffect(() => {
        if (edit) {
            setName(edit.name);
            setOrderId(edit && edit.order && Number(edit.ord));
            setDetailId(edit && edit.prd && Number(edit.prd));
        }
    }, [edit]);

    useEffect(() => {
        if (open && edit) {
            fetchOrders(edit && edit.order);
            getStorageProductsByQuery(edit && edit.prdTxt);
        }
    }, [edit, fetchOrders, getStorageProductsByQuery, open]);

    const onClose = () => {
        setName(undefined);
        setOrderId(undefined);
        setDetailId(undefined);
        hideModal();
    };

    const editPhoto = useMemo(
        () => async () => {
            await fetchAPI(
                'PUT',
                '/api/file',
                null,
                {
                    hash: edit && edit.hash,
                    name: newName,
                    ord: Number(orderId) || undefined,
                    ordTxt: orderTxt,
                    prd: detailId,
                    prdTxt: detTxt
                },
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();

            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        },
        [detTxt, detailId, edit, fetchFileList, formatMessage, newName, orderId, orderTxt]
    );

    const onFinish = async () => {
        editPhoto();
        await onClose();
    };

    return (
        <Modal
            maskClosable={false}
            onCancel={onClose}
            onOk={async () => {
                onFinish();
            }}
            open={open}
            title={<FormattedMessage id='gallary.edit_name' />}
        >
            <div className={Styles.divGroup}>
                <Tooltip title={<FormattedMessage id='diagnostic-page.name' />}>
                    <Input
                        onChange={event => setName(event.target.value)}
                        placeholder={formatMessage({
                            id: 'diagnostic-page.name'
                        })}
                        style={{
                            width: '100%'
                        }}
                        value={newName}
                    />
                </Tooltip>
            </div>
            <div className={Styles.divGroup}>
                <Tooltip title={<FormattedMessage id='order_form_table.detail_code' />}>
                    <Select
                        allowClear
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                            zIndex: '9999',
                            minWidth: 220
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={(value, option) => {
                            setDetailId(Number(value));
                            setDetTxt(option.code);
                        }}
                        onSearch={value => {
                            if (value.length >= 3) {
                                debouncedQuerySearch(value);
                            }
                        }}
                        optionFilterProp='children'
                        placeholder={
                            <div>
                                <FormattedMessage id='order_form_table.detail_code' />
                            </div>
                        }
                        showSearch
                        value={detailId}
                    >
                        {storageProducts.map(elem => {
                            return (
                                <Option key={elem.id} code={elem.code} value={elem.id}>
                                    {elem.code}
                                </Option>
                            );
                        })}
                    </Select>
                </Tooltip>
            </div>
            <div className={Styles.divGroup}>
                <Tooltip title={<FormattedMessage id='reports_page.orders' />}>
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={(value, option) => {
                            setOrderId(Number(value));
                            setOrderTxt(option.num);
                        }}
                        onSearch={input => handleSearchOrders(input)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'reports_page.orders'
                        })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={orderId}
                    >
                        {orders.map(({ id, num }) => (
                            <Option key={id} num={num} value={id}>
                                {num}
                            </Option>
                        ))}
                    </Select>
                </Tooltip>
            </div>
        </Modal>
    );
};

export default injectIntl(connect(mapStateToProps)(EditPhotoFileModal));
