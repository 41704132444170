import { CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Input, InputNumber, List, Select, Spin } from 'antd';
import { MODALS, setModal } from 'core/modals/duck';
import {
    fetchProductAnalogues,
    selectProductAnaloguesLoading,
    selectStoreProduct,
    selectStoreProductAnalogues
} from 'core/storage/products';
import { fetchStoreGroups, selectStoreGroups } from 'core/storage/storeGroups';
import _, { get } from 'lodash';
import { VehicleModal } from 'modals';
import { fetchVehicleColors, selectColors } from 'modals/VehicleModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    product: selectStoreProduct(state),
    productAnalogues: selectStoreProductAnalogues(state),
    productAnaloguesLoading: selectProductAnaloguesLoading(state),
    colors: selectColors(state),
    storeGroups: selectStoreGroups(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setModal,
    fetchProductAnalogues,
    fetchVehicleColors,
    fetchStoreGroups
};

/**
 * Tech info tab contains product attributes
 */
const buttonFields = ['front', 'top', 'external', 'rear', 'bottom', 'internal', 'left', 'central', 'right'];

const conditionOptions = [
    { label: 'detail_order.new', value: 'NEW' },
    { label: 'detail_order.used', value: 'USED' }
];

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class TechInfoTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeGroupsTree: [],
            colorId: undefined,
            buttonState: {},
            usedInVehicles: '',
            edit: undefined
        };
    }

    componentDidMount() {
        const { product } = this.props;
        // this.props.fetchProductAnalogues();
        this.props.fetchVehicleColors();

        this.listDataSource(product.usedInVehicles);

        if (product) {
            this.setState({
                name: product.name,
                colorId: product.colorId,
                condition: product.condition,
                height: product.height,
                width: product.width,
                weight: product.weight,
                length: product.length,
                internalDiameter: product.internalDiameter,
                externalDiameter: product.externalDiameter,
                comment: product.comment,
                usedInVehicles: product.usedInVehicles,
                buttonState: _.pick(product, [
                    'right',
                    'left',
                    'front',
                    'rear',
                    'top',
                    'bottom',
                    'external',
                    'internal',
                    'central'
                ])
            });
        }
    }

    listDataSource = inputString => {
        // Step 1: Split the input string by ';'
        const substrings = inputString
            ? inputString.split(';').filter(substring => substring.trim() !== '')
            : undefined;

        // Step 2: Initialize an empty array to hold the objects
        const objectsArray = [];

        // Step 3: Iterate through each substring
        substrings &&
            substrings.forEach((substring, index) => {
                // Split the substring by ',' to get parts (assuming each part is separated by ',')
                const parts = substring.split(',').map(part => part.trim());

                // Create an object with the relevant parts
                const obj = {
                    id: index,
                    title: `${parts[0]}, ${parts[1]}, ${parts[2]}, ${parts.slice(3).join(', ')}`

                    // make: parts[1],
                    // model: parts[2],
                    // modification: parts.slice(3).join(', ')
                };

                // Push the object into the array
                objectsArray.push(obj);
            });

        this.setState({
            dataSource: objectsArray
        });

        return objectsArray;
    };

    toggleButton = propName => () => {
        this.setState(prevState => ({
            buttonState: {
                ...prevState.buttonState,
                [propName]: !prevState.buttonState[propName]
            }
        }));
    };

    handleOk = async () => {
        const { id } = this.props;
        const {
            colorId,
            name,
            groupId,
            condition,
            height,
            width,
            weight,
            length,
            internalDiameter,
            externalDiameter,
            comment,
            buttonState,
            usedInVehicles,
            dataSource
        } = this.state;

        const dataSourceTitles =
            dataSource && get(dataSource, 'length') ? dataSource.map(item => `${item.title}; `).join('; ') : null;

        await fetchAPI(
            'PUT',
            `/store_products/${id}`,

            null,
            {
                name,
                colorId,
                groupId,
                condition,
                height,
                width,
                weight,
                length,
                internalDiameter,
                externalDiameter,
                comment: comment == '' ? null : comment,
                usedInVehicles: dataSourceTitles,
                ...buttonState
            },
            {
                handleErrorInternally: true
            }
        );

        this.setState({
            usedInVehicles: dataSourceTitles
        });
    };

    componentDidUpdate(prevProps, prevState) {
        // Compare previous state with current state
        if (prevState.dataSource !== this.state.dataSource) {
            this.handleOk();
        }
    }

    onAddVehicle = () => {
        this.props.setModal(MODALS.VEHICLE, { mode: 'ADD' });
    };

    setVehicleData = async val => {
        const { usedInVehicles } = this.state;

        await this.listDataSource(usedInVehicles !== null ? usedInVehicles + val : val);
        await this.handleOk();
    };

    handleDelete = async index => {
        const { dataSource } = this.state;
        const newDataSource = dataSource.filter(item => item.id !== index);
        this.setState({
            dataSource: newDataSource.length > 0 ? newDataSource : []
        });
    };

    handleEdit = async index => {
        const { dataSource } = this.state;

        this.setState({
            edit: index
        });
    };

    handleEditSave = async index => {
        const { dataSource } = this.state;

        // this.listDataSource(dataSource);

        this.setState({
            edit: undefined
        });

        await this.handleOk();
    };

    changeDataSourceField = (fields, additionalState = {}) => {
        this.setState(({ dataSource: ds }) => {
            const dataSource = ds;
            Object.entries(fields).forEach(([key, value]) => {
                if (dataSource && dataSource[0]) {
                    dataSource[0][key] = value;
                }
            });

            return {
                dataSource,
                ...additionalState
            };
        });
    };

    render() {
        const {
            productAnalogues,
            productAnaloguesLoading,
            images,
            attributes,
            intl: { formatMessage },
            colors,
            isMobile
        } = this.props;
        const {
            colorId,
            name,
            condition,
            height,
            width,
            weight,
            length,
            internalDiameter,
            externalDiameter,
            buttonState,
            comment,
            usedInVehicles,

            dataSource,
            edit
        } = this.state;

        const src =
            images && images.length
                ? `${__TECDOC_IMAGES_URL__}/${images[0].pictureName}`
                : `${__TECDOC_IMAGES_URL__}/not_found.png`;

        return productAnaloguesLoading ? (
            <Spin />
        ) : (
            <div className={!isMobile ? Styles.techInfoWrapper : null}>
                <div>
                    <div>
                        <div className={Styles.margin}>
                            <div>
                                <FormattedMessage id='detail_order.name' />
                            </div>
                            <Input
                                onChange={event =>
                                    this.setState({
                                        name: event.target.value
                                    })
                                }
                                placeholder={formatMessage({
                                    id: 'detail_order.name'
                                })}
                                value={name}
                            />
                        </div>
                        <div className={Styles.margin}>
                            <div>
                                <FormattedMessage id='detail_order.colorId' />
                            </div>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={(value, option) => {
                                    this.setState({
                                        colorId: option.value
                                    });
                                }}
                                optionFilterProp='label'
                                options={colors.map(({ colorId, name, colorCode }) => ({
                                    label: name,
                                    value: colorId,
                                    color: colorCode
                                }))}
                                placeholder={formatMessage({
                                    id: 'add_client_form.color_placeholder'
                                })}
                                showSearch
                                value={colorId}
                            />
                        </div>
                        <div className={Styles.margin}>
                            <div>
                                <FormattedMessage id='detail_order.condition' />
                            </div>
                            <Select
                                onSelect={(value, option) =>
                                    this.setState({
                                        condition: option.value
                                    })
                                }
                                options={conditionOptions.map(({ label, value }) => ({
                                    label: formatMessage({ id: label }),
                                    value
                                }))}
                                value={condition}
                            />
                        </div>
                        <div className={Styles.inputNumberBlock}>
                            <div className={Styles.margin}>
                                <div>
                                    <FormattedMessage id='detail_order.length' />
                                </div>
                                <InputNumber
                                    onChange={value =>
                                        this.setState({
                                            length: value
                                        })
                                    }
                                    value={length}
                                />
                            </div>
                            <div className={Styles.margin}>
                                <div>
                                    <FormattedMessage id='detail_order.width' />
                                </div>
                                <InputNumber
                                    onChange={value =>
                                        this.setState({
                                            width: value
                                        })
                                    }
                                    value={width}
                                />
                            </div>
                            <div className={Styles.margin}>
                                <div>
                                    <FormattedMessage id='detail_order.height' />
                                </div>
                                <InputNumber
                                    onChange={value =>
                                        this.setState({
                                            height: value
                                        })
                                    }
                                    value={height}
                                />
                            </div>
                            <div className={Styles.margin}>
                                <div>
                                    <FormattedMessage id='detail_order.weight' />
                                </div>
                                <InputNumber
                                    onChange={value =>
                                        this.setState({
                                            weight: value
                                        })
                                    }
                                    value={weight}
                                />
                            </div>
                            <div className={Styles.margin}>
                                <div>
                                    <FormattedMessage id='detail_order.external_diameter' />
                                </div>
                                <InputNumber
                                    onChange={value =>
                                        this.setState({
                                            externalDiameter: value
                                        })
                                    }
                                    value={externalDiameter}
                                />
                            </div>
                            <div className={Styles.margin}>
                                <div>
                                    <FormattedMessage id='detail_order.internal_diameter' />
                                </div>
                                <InputNumber
                                    onChange={value =>
                                        this.setState({
                                            internalDiameter: value
                                        })
                                    }
                                    value={internalDiameter}
                                />
                            </div>
                        </div>

                        <div className={Styles.margin}>
                            <div>
                                <FormattedMessage id='comment' />
                            </div>
                            <Input.TextArea
                                onChange={event =>
                                    this.setState({
                                        comment: event.target.value
                                    })
                                }
                                value={comment}
                            />
                        </div>
                        <div className={Styles.buttonWrap}>
                            {buttonFields.map(propName => (
                                <Button
                                    key={propName}
                                    onClick={this.toggleButton(propName)}
                                    style={{ width: '30%' }}
                                    type={buttonState[propName] ? 'primary' : 'default'}
                                >
                                    {formatMessage({ id: `detail_order.${propName}` })}
                                </Button>
                            ))}
                        </div>
                        {/* <div className={Styles.margin}>
                            <div>
                                <FormattedMessage id='detail_order.use_in_vehicle' />
                            </div>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <Input readOnly value={usedInVehicles} />
                                <Button icon={<PlusOutlined />} onClick={this.onAddVehicle} />
                            </div>
                        </div> */}
                        <div className={Styles.margin}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: 18
                                    }}
                                >
                                    <FormattedMessage id='detail_order.use_in_vehicle' />
                                </span>
                                <Button icon={<PlusOutlined />} onClick={this.onAddVehicle} />
                            </div>
                            <div>
                                <List
                                    dataSource={dataSource}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <Card
                                                style={{
                                                    width: '100%'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    {edit === index ? (
                                                        <Input
                                                            onChange={event =>
                                                                this.changeDataSourceField({
                                                                    title: event.target.value
                                                                })
                                                            }
                                                            style={{
                                                                width: '90%'
                                                            }}
                                                            value={item.title}
                                                        />
                                                    ) : (
                                                        `${index + 1} - ${item.title}`
                                                    )}
                                                    <div>
                                                        <Button
                                                            icon={edit === index ? <CheckOutlined /> : <EditOutlined />}
                                                            onClick={() =>
                                                                edit === index
                                                                    ? this.handleEditSave()
                                                                    : this.handleEdit(index)
                                                            }
                                                            style={{
                                                                marginLeft: 8
                                                            }}
                                                        />
                                                        <Button
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => this.handleDelete(index)}
                                                            style={{
                                                                marginLeft: 8
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                marginBottom: 8
                            }}
                        >
                            <Button onClick={this.handleOk} type='primary'>
                                <FormattedMessage id='save' />
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={Styles.techInfoImageWrapper}>
                    <img
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                        }}
                        src={src}
                        style={{ cursor: 'pointer' }}
                        width='100%'
                    />
                </div>
                <div className={Styles.techInfoItemWrapper}>
                    {attributes &&
                        attributes.map((attribute, i) => (
                            <div key={i} className={Styles.attributeItem}>
                                {attribute.description}: {attribute.value}
                            </div>
                        ))}
                </div>
                <VehicleModal setVehicleData={this.setVehicleData} />
            </div>
        );
    }
}
