import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popconfirm, Select, Table, Tooltip, notification } from 'antd';
import { Numeral } from 'commons';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import AddAccRowModal from '../AddAccRowModal';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

const AccTemplateRowsTable = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },

        loadingRows,
        user,
        docData,
        setTemplateRows,
        fetchAccTemplates,
        templates,
        updateList,
        saveRows
    } = props;

    const [rowData, setRowData] = useState();
    const [rowVisible, setRowVisible] = useState(false);
    const [template, setTemplate] = useState();
    const [rows, setRows] = useState();
    const [edit, setEdit] = useState(undefined);
    const [openModal, openAddModal] = useState(false);

    const fetchTemplate = useCallback(
        async rows => {
            const data = templates && templates.find(({ id }) => id === get(docData, 'id', undefined));

            await setRows(rows || (data && data.rows));
        },
        [docData, templates]
    );

    useEffect(() => {
        if (open) {
            fetchTemplate();
        }
    }, [fetchTemplate, open]);

    const deleteRows = async value => {
        await setRows(value);
        try {
            await fetchAPI(
                'PUT',
                '/general_ledger/acc_document_template',
                undefined,
                {
                    ..._.omit(docData, [
                        'value',
                        'businessId',
                        'managerId',
                        'warehouseName',
                        'managerName',
                        'cashBoxName',
                        'warehouseAccountId',
                        'cashBoxAccountId',
                        'counterpartAccountId',
                        'businessRequisiteName'
                    ]),
                    amount: docData.amount || 0,
                    rows: value
                },
                { handleErrorInternally: true }
            );

            notification.success({ message: formatMessage({ id: 'message.success' }) });
        } catch (e) {
            notification.error({ message: formatMessage({ id: 'error' }) });
        }

        await fetchAccTemplates();
    };

    // const setData = row => {
    //     setAccountId(row.accountId || undefined);
    //     setAnalyticsId(row.analyticsId || undefined);
    //     setAmountSignature(row.amountSignature || undefined);
    //     setFormula(row.amountFormula || undefined);
    //     setOperationType(row.operationType || undefined);
    //     setComment(row.comment || undefined);
    //     setId(row.id || undefined);

    //     setEdit(undefined);
    // };

    const columns = useMemo(() => {
        return [
            {
                title: () => (
                    <div className={Styles.headerActions}>
                        <Tooltip placement='top' title={<FormattedMessage id='add' />}>
                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    setRowVisible(true);
                                }}
                            />
                        </Tooltip>
                    </div>
                ),
                key: 'buttonGroup',
                align: 'center',
                width: '10%',
                render: (row, _, index) => {
                    const pairedRows = rows.filter(({ operationId }) => row.operationId === operationId);

                    const filteredRows = rows.filter((item, idx) => item.operationId !== row.operationId);

                    return (
                        <div className={Styles.rowActions}>
                            <Tooltip placement='top' title={<FormattedMessage id='labors_table.add_edit_button' />}>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        setRowData(pairedRows);
                                        setRowVisible(true);
                                    }}
                                />
                            </Tooltip>
                            <Popconfirm
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT,
                                        accesses.ROWO
                                    )
                                }
                                onConfirm={async () => {
                                    await deleteRows(filteredRows);
                                }}
                                title={<FormattedMessage id='add_order_form.delete_confirm' />}
                            >
                                <Button
                                    data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                    disabled={
                                        !isGrantAccessed(
                                            user,
                                            grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT,
                                            accesses.ROWO
                                        )
                                    }
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='entry_table.account_id' />,
                key: 'accountName',
                width: '25%',
                render: row => <div>{`(#${row.accountId}) ${row.accountName}`}</div>
            },
            {
                title: <FormattedMessage id='entry_table.operation_type' />,
                key: 'type',
                width: '20%',
                render: row => (
                    <div>
                        <FormattedMessage id={`entries_tab.${row.type}`} />
                    </div>
                )
            },
            {
                title: <FormattedMessage id='storage.counterparty' />,
                key: 'counterpart',

                width: '15%',
                render: row =>
                    row.counterpartType === docData.counterpartType ? <div>{docData.counterpartName}</div> : undefined
            },
            {
                title: <FormattedMessage id='sum' />,
                key: 'sum',
                align: 'right',
                width: '10%',
                render: row => (
                    <div>
                        <Numeral mask='0,0.00'>{Number(row.amount)}</Numeral>
                    </div>
                )
            }
        ];
    }, [rows, user]);

    const onClose = () => {
        hideModal();
    };

    return (
        <div>
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                footer={null}
                maskClosable={false}
                onCancel={() => onClose()}
                open={open}
                title={<FormattedMessage id='entries_tab.edit_rows' />}
                width='90%'
            >
                <Table
                    columns={columns}
                    dataSource={rows}
                    loading={loadingRows}
                    pagination={false}
                    scroll={{ x: 'auto' }}
                    size='small'
                />
            </Modal>
            <AddAccRowModal
                closeModal={() => {
                    setRowData(undefined);
                    setRowVisible(false);
                }}
                docData={docData}
                fetchAccTemplates={fetchAccTemplates}
                fetchTemplate={fetchTemplate}
                maskClosable={false}
                open={rowVisible}
                rowData={rowData}
                rows={rows}
                setTemplateRows={setTemplateRows}
                templates={templates}
                updateList={updateList}
            />
        </div>
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccTemplateRowsTable));
