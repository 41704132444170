import {
    MailOutlined,
    MenuOutlined,
    MessageOutlined,
    MobileOutlined,
    PhoneOutlined
} from '@ant-design/icons';
import { Button, Menu, Space } from 'antd';
import { HamburgerMenu } from 'components';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

/** Width for each col, it must be 100% of width in total or less than 100% with auto keywords somewhere */
const defWidth = {
    vehicle: '20%',
    vehicleNumber: '10%',
    vehicleVin: '20%',
    client: 'auto'
};

export function columnsConfig() {
    const groupActionsCol = {
        title: () => {
            const actionsMenu = (
                <Menu>
                    <Menu.Item>
                        <Space>
                            <PhoneOutlined />
                            <FormattedMessage id='phone' />
                        </Space>
                    </Menu.Item>
                    <Menu.Item>
                        <Space>
                            <MessageOutlined />
                            <FormattedMessage id='navigation.SMS' />
                        </Space>
                    </Menu.Item>
                    <Menu.Item>
                        <Space>
                            <MobileOutlined />
                            <FormattedMessage id='message' />
                        </Space>
                    </Menu.Item>
                    <Menu.Item>
                        <Space>
                            <MailOutlined />
                            <FormattedMessage id='email' />
                        </Space>
                    </Menu.Item>
                </Menu>
            );

            return <HamburgerMenu actionsMenu={actionsMenu} />;
        },
        key: 'actions',
        align: 'center',
        render: detail => {
            const actionsMenu = () => (
                <Menu>
                    <Menu>
                        <Menu.Item>
                            <Space>
                                <PhoneOutlined />
                                <FormattedMessage id='phone' />
                            </Space>
                        </Menu.Item>
                        <Menu.Item>
                            <Space>
                                <MessageOutlined />
                                <FormattedMessage id='navigation.SMS' />
                            </Space>
                        </Menu.Item>
                        <Menu.Item>
                            <Space>
                                <MobileOutlined />
                                <FormattedMessage id='message' />
                            </Space>
                        </Menu.Item>
                        <Menu.Item>
                            <Space>
                                <MailOutlined />
                                <FormattedMessage id='email' />
                            </Space>
                        </Menu.Item>
                    </Menu>
                </Menu>
            );

            return (
                <HamburgerMenu actionsMenu={actionsMenu}>
                    <Button data-qa='btn_show_hamburger_menu_modal_services_table_order_page' icon={<MenuOutlined />} />
                </HamburgerMenu>
            );
        }
    };

    const vehicleCol = {
        title: <FormattedMessage id='vehicle' />,
        width: defWidth.vehicle,
        key: 'vehicle',
        render: (val, vehicle) => {
            return (
                <div>
                    <Link to={`${book.vehicle}/${vehicle.clientVehicleId}`}>
                        {`${_.get(vehicle, 'make')} ${_.get(vehicle, 'model')} ${_.get(vehicle, 'year')}`}
                    </Link>
                </div>
            );
        }
    };

    const vehicleNumberCol = {
        title: <FormattedMessage id='add_client_form.number' />,
        width: defWidth.vehicleNumber,
        align: 'center',
        dataIndex: 'number',
        key: 'number',
        render: number => {
            return <div>{number}</div>;
        }
    };

    const vehicleVINCol = {
        title: <FormattedMessage id='add_order_form.vin' />,
        width: defWidth.vehicleVin,
        dataIndex: 'vin',
        key: 'vin',
        render: vin => {
            return <div>{vin}</div>;
        }
    };

    const clientCol = {
        title: <FormattedMessage id='name' />,
        width: defWidth.client,
        dataIndex: 'client',
        key: 'client',
        render: client => {
            return (
                <div>
                    <div>
                        <Link to={`${book.client}/${_.get(client, 'clientId')}`}>
                            {`${_.get(client, 'name')} ${_.get(client, 'surname')}`}
                        </Link>
                    </div>

                    <div>
                        {client.phones.map(phone => (
                            <a key={v4()} className={Styles.clientPhone} href={`tel:${phone}`}>
                                {phone}
                            </a>
                        ))}
                    </div>
                </div>
            );
        }
    };

    return [groupActionsCol, vehicleCol, vehicleNumberCol, vehicleVINCol, clientCol];
}
