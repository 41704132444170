import { Flex, Input, Select, Tooltip } from 'antd';
import { CASH_ORDER_TYPES, MODES } from 'modals/RefactoredCashOrderModal/constants';
import { selectCashboxes } from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import {
    selectAuthCode,
    selectCardMask,
    selectCashOrderType,
    selectMode,
    selectSelectedCashbox,
    selectSelectedCashboxId,
    setCardMask
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { moveDigitsToFront } from 'utils';

const { Option } = Select;

const { INCOME, EXPENSE, ADJUSTMENT_INCOME } = CASH_ORDER_TYPES;

const mapStateToProps = state => ({
    cashOrderType: selectCashOrderType(state),
    selectedCashbox: selectSelectedCashbox(state),
    selectAuthCode: selectAuthCode(state),
    selectCardMask: selectCardMask(state),
    mode: selectMode(state),
    cashboxes: selectCashboxes(state),
    selectedCashboxId: selectSelectedCashboxId(state)
});

const mapDispatchToProps = {
    setCardMask
};

/**
 * This component serves selecting cash order type
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CardMaskInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cardMaskStart: '****',
            cardMaskEnd: '****'
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        const { selectCardMask } = this.props;
        if (prevProps.selectCardMask !== selectCardMask) {
            this.setState({
                cardMaskStart: `${selectCardMask}`.substring(0, 4),
                cardMaskEnd: `${selectCardMask}`.substring(`${selectCardMask}`.length - 4)
            });
        }
    }

    handleChangeCardMask = () => {
        const { setCardMask } = this.props;

        const { cardMaskStart, cardMaskEnd } = this.state;

        if (!cardMaskStart && !cardMaskEnd) {
            setCardMask('**** **** **** ****');
        } else {
            setCardMask(`${cardMaskStart} **** **** ${cardMaskEnd}`);
        }
    };

    render() {
        const {
            cashOrderType,
            setRequisites,
            selectRequisiteId,
            mode,
            editable,
            cashboxes,
            selectedCashboxId,
            selectCardMask,
            setCardMask,
            intl: { formatMessage }
        } = this.props;

        const { cardMaskStart, cardMaskEnd } = this.state;

        return (
            <Flex>
                <Tooltip placement='top' title={<FormattedMessage id='card_mask_start' />} zIndex={2001}>
                    <Input
                        allowClear
                        disabled={mode != MODES.ADD}
                        getPopupContainer={trigger => trigger.parentNode}
                        onBlur={this.handleChangeCardMask}
                        onChange={e => {
                            const value = moveDigitsToFront(e.target.value);
                            if (value.length >= 4) {
                                this.setState({ cardMaskStart: value.substring(0, 4) });
                            } else {
                                this.setState({ cardMaskStart: value.padEnd(4, '*') });
                            }
                        }}
                        placeholder={formatMessage({
                            id: 'card_mask_start'
                        })}
                        value={cardMaskStart}
                    />
                </Tooltip>
                <Tooltip placement='top' title={<FormattedMessage id='card_mask_end' />} zIndex={2001}>
                    <Input
                        allowClear
                        disabled={mode != MODES.ADD}
                        getPopupContainer={trigger => trigger.parentNode}
                        onBlur={this.handleChangeCardMask}
                        onChange={e => {
                            const { value } = e.target;
                            if (value.length >= 4) {
                                this.setState({ cardMaskEnd: value.substring(value.length - 4) });
                            } else {
                                this.setState({ cardMaskEnd: value.padStart(4, '*') });
                            }
                        }}
                        placeholder={formatMessage({
                            id: 'card_mask_start'
                        })}
                        value={cardMaskEnd}
                    />
                </Tooltip>
            </Flex>
        );
    }
}
