import { SaveOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { DecoratedInputNumber, DecoratedSelect } from 'forms/DecoratedFields';
import React from 'react';
import Styles from './styles.m.css';

const { Option } = Select;

/* eslint-disable complexity */
export function columnsConfig(props) {
    const _getDefaultValue = (key, fieldName) => {
        const services = (props.services || [])[key];
        if (!services) {
            return;
        }

        const fields = {
            serviceId: services.serviceId,
            detailId: services.detailId,
            quantity: services.duration
        };

        return fields[fieldName];
    };
    const serviceCol = {
        title: 'Работа',
        key: 'service',
        width: '15%',
        render: (text, { key }) => (
            <DecoratedSelect
                cnStyles={Styles.select}
                dropdownStyle={{ width: '50%' }}
                field={`service[${key}][serviceId]`}
                getFieldDecorator={props.form.getFieldDecorator}
                initialValue={_getDefaultValue(key, 'serviceId')}
                popupMatchSelectWidth={false}
            >
                {props.services.services.map(({ serviceId, serviceName }) => (
                    <Option key={serviceId} value={serviceId}>
                        {serviceName}
                    </Option>
                ))}
            </DecoratedSelect>
        )
    };

    const detailsCol = {
        title: 'Запчасти',
        key: 'details',
        width: '15%',
        render: (text, { key }) => (
            <DecoratedSelect
                cnStyles={Styles.select}
                dropdownStyle={{ width: '50%' }}
                field={`service[${key}][detailId]`}
                getFieldDecorator={props.form.getFieldDecorator}
                initialValue={_getDefaultValue(key, 'detailId')}
                popupMatchSelectWidth={false}
                rules={[
                    {
                        required: true,
                        message: 'Type is required'
                    }
                ]}
            >
                {props.services.details.map(({ detailId, detailName }) => (
                    <Option key={detailId} value={detailId}>
                        {detailName}
                    </Option>
                ))}
            </DecoratedSelect>
        )
    };

    const quantityCol = {
        title: 'Кол-во',
        key: 'quantity',
        width: '10%',
        render: (text, { key }) => (
            <DecoratedInputNumber
                field={`service[${key}][quantity]`}
                fields={{}}
                getFieldDecorator={props.form.getFieldDecorator}
                initialValue={_getDefaultValue(key, 'quantity')}
            />
        )
    };

    const actionsCol = {
        title: '',
        key: 'actions',
        width: 'auto',
        render: (text, { key }) => (
            <div>
                <SaveOutlined
                    className={Styles.saveIcon}
                    onClick={() => {
                        props.createService({
                            ...props.form.getFieldValue(`service[${key}]`)
                        });
                        props.resetFields();
                    }}
                />
            </div>
        )
    };

    return [serviceCol, detailsCol, quantityCol, actionsCol];
}
