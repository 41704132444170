import { Tag } from 'antd';
import { Numeral } from 'commons';
import { DatetimeFormatter } from 'commons/_uikit';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { getCurrency } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

const getMinus = (docType, value) => {
    if (docType) {
        return '';
    }
    if (!docType && value === 0) {
        return '';
    }

    return '-';
};

// Get translations based on type table orders, reserves
const getDocumentType = (props, type) => {
    if (props.type == 'reserves' || props.tab == 'RESERVE') {
        return type === 'INCOME' ? `storage.${type}` : 'storage.reserve';
    }
    if (props.type == 'orders') {
        return type === 'INCOME' ? 'storage.order' : 'storage.order_income';
    }

    return `storage.${type || 'EXPENSE'}`;
};

export default props => {
    const code = {
        title: props.intl.formatMessage({
            id: 'storage.product_code'
        }),
        key: 'code',
        width: '20vh',
        dataIndex: 'code',
        render: (code, { product }) =>
            isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD, accesses.ROWO) ? (
                <Link to={`${book.product}/${product.id}`}>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--link)'
                        }}
                    >
                        {product.code}
                    </div>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--text3)',
                            fontSize: 12
                        }}
                    >
                        {product.name}
                    </div>
                </Link>
            ) : (
                <div>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--link)'
                        }}
                    >
                        {product.code}
                    </div>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--text3)',
                            fontSize: 12
                        }}
                    >
                        {product.name}
                    </div>
                </div>
            )
    };

    const type = {
        title: props.intl.formatMessage({
            id: 'storage.operation_type'
        }),
        key: 'type',
        width: '25vh',
        render: (key, data) => {
            const type = _.get(data, 'doc.type');

            return (
                <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                    {props.intl.formatMessage({
                        id: getDocumentType(props, type)
                    })}
                </Tag>
            );
        }
    };

    const docNum = {
        title: props.intl.formatMessage({
            id: 'storage.document'
        }),
        key: 'docNum',
        width: '20vh',
        render: (key, data) => {
            const docId = _.get(data, 'doc.id');
            const documentNumber = _.get(data, 'doc.documentNumber');

            return data.orderId ? (
                <a href={`${book.order}/${data.orderId}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                    {data.orderId}
                </a>
            ) : docId && isGrantAccessed(props.user, grants.WAREHOUSE_DOCUMENT, accesses.ROWO) ? (
                <a href={`${book.storageDocument}/${docId}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                    {documentNumber}
                </a>
            ) : (
                documentNumber
            );
        }
    };

    const orderNum = {
        title: props.intl.formatMessage({
            id: 'storage.order'
        }),
        key: 'order',
        width: '20vh',
        render: data => {
            const orderId = _.get(data, 'order.id');
            const documentNumber = _.get(data, 'order.ordNum');
            const operationCode = _.get(data, 'order.operationCode');

            return orderId ? (
                <div>
                    <a
                        href={`${operationCode === 'RTL' ? book.rtl : book.order}/${orderId}`}
                        style={{ color: 'var(--link)', fontWeight: 'bold' }}
                    >
                        {documentNumber}
                    </a>
                </div>
            ) : (
                documentNumber
            );
        }
    };

    const docNumAndType = {
        title: props.intl.formatMessage({
            id: 'storage.document'
        }),
        key: 'docNumAndType',
        width: '20vh',
        render: (key, data) => {
            const docId = _.get(data, 'doc.id');
            const documentNumber = _.get(data, 'doc.documentNumber');
            const type = _.get(data, 'doc.type');

            if (docId && isGrantAccessed(props.user, grants.WAREHOUSE_DOCUMENT, accesses.ROWO)) {
                return (
                    <div>
                        <a
                            href={`${book.storageDocument}/${docId}`}
                            style={{ color: 'var(--link)', fontWeight: 'bold' }}
                        >
                            {documentNumber}
                        </a>
                        <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                            {props.intl.formatMessage({
                                id: `storage.${
                                    type || (props.type == 'reserves' || props.tab == 'RESERVE' ? 'reserve' : 'EXPENSE')
                                }`
                            })}
                        </Tag>
                    </div>
                );
            }

            return data.orderId ? (
                <div>
                    <a href={`${book.order}/${data.orderId}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                        {data.orderId}
                    </a>
                    <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                        {props.intl.formatMessage({
                            id: `storage.${
                                type || (props.type == 'reserves' || props.tab == 'RESERVE' ? 'reserve' : 'EXPENSE')
                            }`
                        })}
                    </Tag>
                </div>
            ) : (
                <div>
                    <span>{documentNumber}</span>
                    <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                        {props.intl.formatMessage({
                            id: `storage.${
                                type || (props.type == 'reserves' || props.tab == 'RESERVE' ? 'reserve' : 'EXPENSE')
                            }`
                        })}
                    </Tag>
                </div>
            );
        }
    };

    const warehouse = {
        title: props.intl.formatMessage({
            id: 'navigation.storage'
        }),
        key: 'warehouse',
        width: '20vh',
        render: row => {
            return <div>{row.doc ? row.doc.warehouseName : ''}</div>;
        }
    };

    const createdDatetime = {
        title: props.intl.formatMessage({
            id: 'storage.date'
        }),
        dataIndex: 'datetime',
        width: '15vh',
        sorter: (a, b) => (dayjs(a.datetime).isAfter(b.datetime) ? 1 : dayjs(b.datetime).isAfter(a.datetime) ? -1 : 0),
        defaultSortOrder: 'descend',
        render: datetime => <DatetimeFormatter datetime={datetime} />
    };

    const counterparty = {
        title: props.intl.formatMessage({
            id: 'storage.counterparty'
        }),
        dataIndex: 'doc',
        width: '20vh',
        render: (doc, data) => {
            const income = Boolean(doc);

            const isCounterpartyExists =
                _.get(data, 'order.client.id') ||
                _.get(data, 'doc.businessSupplier.name') ||
                _.get(data, 'doc.counterpartClientName', '');

            return isCounterpartyExists ? (
                <Link
                    to={
                        income
                            ? `${book.supplier}/${_.get(data, 'doc.businessSupplier.id')}`
                            : `${book.client}/${_.get(data, 'order.client.id')}`
                    }
                >
                    {income
                        ? `${_.get(data, 'doc.businessSupplier.name', '')}` ||
                          `${_.get(data, 'doc.counterpartClientName', '')}`
                        : `${_.get(data, 'order.client.name', '')} ${_.get(data, 'order.client.surname', '')}`}
                </Link>
            ) : null;
        }
    };

    const responsible = {
        title: props.intl.formatMessage({
            id: 'storage.responsible'
        }),
        dataIndex: 'order',
        width: '20vh',
        render: (order, data) => {
            const expense = Boolean(order);
            const isManagerExists = _.get(data, 'order.manager.employeeId') || _.get(data, 'doc.manager.employeeId');

            return isManagerExists && isGrantAccessed(props.user, grants.DIRECTORIES_EMPLOYEES_LIST, accesses.ROWO) ? (
                <Link
                    to={`${book.employeesPage}/${
                        expense ? _.get(data, 'order.manager.employeeId') : _.get(data, 'doc.manager.employeeId')
                    }`}
                >
                    {expense
                        ? `${_.get(data, 'order.manager.name', '')} ${_.get(data, 'order.manager.surname', '')}`
                        : `${_.get(data, 'doc.manager.name', '')} ${_.get(data, 'doc.manager.surname', '')}`}
                </Link>
            ) : (
                <div>
                    {expense
                        ? `${_.get(data, 'order.manager.name', '')} ${_.get(data, 'order.manager.surname', '')}`
                        : `${_.get(data, 'doc.manager.name', '')} ${_.get(data, 'doc.manager.surname', '')}`}
                </div>
            );
        }
    };

    const units = {
        title: props.intl.formatMessage({
            id: 'services_table.units'
        }),
        width: '5vh',
        align: 'center',
        render: (unit, data) => {
            const units = _.get(data, 'product.unit');

            return <React.Fragment>{units || undefined}</React.Fragment>;
        }
    };

    const quantity = {
        title: props.intl.formatMessage({
            id: 'storage.quantity'
        }),
        dataIndex: 'quantity',
        width: '7vh',
        render: (quantity, data) => {
            return (
                <React.Fragment>
                    {getMinus(_.get(data, 'doc.type'), Number.parseInt(quantity, 0))}
                    {Number(quantity).toFixed(2)}
                </React.Fragment>
            );
        }
    };

    const purchasePrice = {
        title: props.intl.formatMessage({
            id: 'storage.purchase_price'
        }),
        dataIndex: 'purchasePrice',
        width: '10vh',
        render: purchasePrice => (
            <Numeral currency={getCurrency()} mask='0,0.00'>
                {Number(purchasePrice)}
            </Numeral>
        )
    };

    const purchaseSum = {
        title: props.intl.formatMessage({
            id: 'storage.purchase_sum'
        }),
        dataIndex: 'purchaseSum',
        width: '10vh',
        render: (purchaseSum, data) => {
            return (
                <React.Fragment>
                    {getMinus(_.get(data, 'doc.type'), purchaseSum)}
                    <Numeral currency={getCurrency()} mask='0,0.00'>
                        {Number(purchaseSum)}
                    </Numeral>
                </React.Fragment>
            );
        }
    };

    const sellingPrice = {
        title: props.intl.formatMessage({
            id: 'storage.selling_price'
        }),
        width: '10vh',
        dataIndex: 'sellingPrice',
        render: sellingPrice => (
            <Numeral currency={getCurrency()} mask='0,0.00'>
                {Number(sellingPrice)}
            </Numeral>
        )
    };

    const sellingSum = {
        title: props.intl.formatMessage({
            id: 'storage.selling_sum'
        }),
        width: '10vh',
        dataIndex: 'sellingSum',
        render: (sellingSum, data) => {
            return (
                <React.Fragment>
                    {getMinus(_.get(data, 'doc.type'), sellingSum)}
                    <Numeral currency={getCurrency()} mask='0,0.00'>
                        {Number(sellingSum)}
                    </Numeral>
                </React.Fragment>
            );
        }
    };

    const addToAddress = {
        title: props.intl.formatMessage({
            id: 'wms.in_cell'
        }),
        width: '15vh',
        dataIndex: 'addToAddress',
        render: (addToAddress, data) => {
            return (
                <React.Fragment>
                    <div>{addToAddress || undefined}</div>
                </React.Fragment>
            );
        }
    };

    const getFromAddress = {
        title: props.intl.formatMessage({
            id: 'wms.from_cell'
        }),
        width: '15vh',
        dataIndex: 'getFromAddress',
        render: (getFromAddress, data) => {
            return (
                <React.Fragment>
                    <div>{getFromAddress || undefined}</div>
                </React.Fragment>
            );
        }
    };

    const columns = [
        code,
        warehouse,
        getFromAddress,
        addToAddress,
        type,
        docNum,
        docNumAndType,
        orderNum,
        createdDatetime,
        counterparty,
        responsible,
        quantity,
        units,
        purchasePrice,
        purchaseSum,
        sellingPrice,
        sellingSum
    ];

    if (props.rawData) {
        _.remove(columns, ({ key }) => key == 'type' || key == 'docNum');
    } else {
        _.remove(columns, ({ key }) => key == 'docNumAndType');
    }
    if (props.hideCode) {
        _.remove(columns, ({ key }) => key == 'code');
    }

    if (props.tab == 'RESERVE') {
        return [
            warehouse,
            addToAddress,
            getFromAddress,
            docNumAndType,
            orderNum,
            createdDatetime,
            counterparty,
            responsible,
            quantity
        ];
    }

    return columns;
};
