import { Collapse, DatePicker, Input, Modal, Select, notification } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const dateFormat = 'YYYY-MM-DD';

const EditOdometrModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        edit,
        fetchVehicle
    } = props;

    const [odometrNumber, setOdometrNumber] = useState();
    const [odometrDate, setOdometrDate] = useState(dayjs());

    useEffect(() => {
        if (edit) {
            setOdometrNumber(edit.mileage);
            setOdometrDate(dayjs(edit.date));
        }
    }, [edit]);

    const editOdometr = async () => {
        try {
            if (edit) {
                await fetchAPI(
                    'PUT',
                    '/mileage/history',
                    undefined,
                    {
                        id: edit.id,
                        mileage: odometrNumber,
                        date: dayjs(odometrDate).format(dateFormat),
                        custom: true
                    },
                    { handleErrorInternally: true }
                );

                notification.success({
                    message: formatMessage({ id: 'message.success' })
                });

                await fetchVehicle();
            }
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const onClose = () => {
        setOdometrNumber(undefined);
        setOdometrDate(undefined);
        hideModal();
    };

    const onFinish = async () => {
        editOdometr();

        await onClose();
    };

    const disabled = !odometrDate || !odometrNumber;

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={<FormattedMessage id='vehicle_page.edit_mileage' />}
            width='30%'
        >
            <div className={Styles.blockStyle}>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='vehicle_page.current_mileage' />
                    </div>
                    <Input
                        onChange={event => setOdometrNumber(event.target.value)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'packages.role_name'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={odometrNumber}
                    />
                </div>

                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='date' />
                    </div>
                    <div>
                        <DatePicker
                            allowClear={false}
                            format={dateFormat}
                            onChange={odometrDate => setOdometrDate(odometrDate)}
                            style={{
                                marginRight: 8
                            }}
                            value={odometrDate}
                            width={100}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(EditOdometrModal);
