import { PrinterOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Image } from 'antd';
import { Layout } from 'commons';
import { CashOrdersTable } from 'components';
import {
    fetchCashOrders,
    printCashOrder,
    selectCashOrdersFilters,
    setCashOrdersFilters,
    setCashOrdersPage
} from 'core/cash/duck';
import { clearCashOrderForm } from 'core/forms/cashOrderForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { CashOrdersFiltersForm } from 'forms';
import _ from 'lodash';
import { CashOrderModal, RefactoredCashOrderModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    collapsed: state.ui.collapsed,
    cashOrders: state.cash.cashOrders,
    stats: state.cash.stats,
    user: state.auth,
    modal: state.modals.modal,
    modalProps: state.modals.modalProps,
    filters: selectCashOrdersFilters(state),
    cashFlowFilters: _.get(state, 'router.location.state.cashFlowFilters'),
    isFetching: state.ui.cashOrdersFetching,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setModal,
    resetModal,
    setCashOrdersPage,
    fetchCashOrders,
    clearCashOrderForm,
    printCashOrder,
    setCashOrdersFilters
};

@connect(mapStateToProps, mapDispatchToProps)
export default class CashFlowPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    state = {
        cashOrderModalMounted: false
    };

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.cashboxId) {
            this.props.setCashOrdersFilters({
                cashBoxId: this.props.location.state.cashboxId
            });
        }
        this.props.fetchCashOrders(this.props.cashFlowFilters);

        if (this.props.location.state && this.props.location.state.showForm) {
            this._onOpenCashOrderModal();
        }

        if (this.props.location.state && this.props.location.state.downloadReport) {
            // this.props.setModal(this.props.location.state.showModal)
            this.props.printCashOrder();
        }
        if (this.props.location.state && this.props.location.state.cashOrderId) {
            this._onOpenEditCashOrderModal({ id: this.props.location.state.cashOrderId });
        }
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'accounting_cash_flow' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    _onOpenCashOrderModal = () => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            initValues: {
                cashBoxId: this.props.filters.cashBoxId
            },
            onCashOrderCreatedCallback: () => this.props.fetchCashOrders(this.props.cashFlowFilters)
        });
        this.setState({ cashOrderModalMounted: true });
    };

    _onCloseCashOrderModal = () => {
        this.props.resetModal();
        this.props.clearCashOrderForm();
        this.setState({ cashOrderModalMounted: false });
    };

    _onOpenPrintCashOrderModal = cashOrderEntity => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            mode: 'PRINT',
            initValues: {
                cashOrderId: cashOrderEntity.id
            }
        });
        this.setState({ cashOrderModalMounted: true });
    };

    _onOpenEditCashOrderModal = cashOrderEntity => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            mode: 'EDIT',
            initValues: {
                cashOrderId: cashOrderEntity.id,
                cashBoxId: cashOrderEntity.cashBoxId,
                isRegisteredWithRst: cashOrderEntity.isRegisteredWithRst
            },
            onCashOrderUpdatedCallback: () => this.props.fetchCashOrders(this.props.cashFlowFilters)
        });
        this.setState({ cashOrderModalMounted: true });
    };

    render() {
        const {
            isFetching,
            collapsed,
            stats,
            modal,
            modalProps,
            setCashOrdersPage,
            clearCashOrderForm,
            cashOrders,
            filters,
            user,
            fetchCashOrders,
            printCashOrder,
            isMobile,
            withoutLayout
        } = this.props;

        const { helperDrawerOpen, allLinks } = this.state;

        const canEditCashOrders = isGrantAccessed(user, grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER, accesses.ROWO);
        const canPrintCashOrders = isGrantAccessed(user, grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_PRINT);

        const controls = (
            <div className={Styles.buttonGroup}>
                <Button disabled={!canPrintCashOrders} icon={<PrinterOutlined />} onClick={() => printCashOrder()} />
                <Button onClick={() => this._onOpenCashOrderModal()} type='primary'>
                    <FormattedMessage id='add' />
                </Button>
                <Button
                    icon={<QuestionCircleOutlined />}
                    onClick={async () => {
                        this.setState({
                            helperDrawerOpen: true
                        });
                        await this.fetchHelperLinks();
                    }}
                    style={{
                        fontSize: 22,
                        marginLeft: 8,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    type='text'
                />
            </div>
        );

        const body = (
            <React.Fragment>
                {withoutLayout && (
                    <Flex justify='flex-end' style={{ marginBottom: 14 }}>
                        {controls}
                    </Flex>
                )}
                <CashOrdersFiltersForm filters={filters} stats={_.omit(stats, 'totalCount')} />
                <CashOrdersTable
                    cashOrders={cashOrders}
                    cashOrdersFetching={isFetching}
                    fetchCashOrders={() => this.props.fetchCashOrders(this.props.cashFlowFilters)}
                    filters={filters}
                    isMobile={isMobile}
                    openEdit={canEditCashOrders ? this._onOpenEditCashOrderModal : null}
                    openPrint={this._onOpenPrintCashOrderModal}
                    setCashOrdersPage={setCashOrdersPage}
                    // eslint-disable-next-line no-empty-function
                    totalCount={stats.totalCount}
                />
                {this.state.cashOrderModalMounted ? (
                    <CashOrderModal
                        clearCashOrderForm={clearCashOrderForm}
                        modalProps={modalProps}
                        resetModal={this._onCloseCashOrderModal}
                        visible={modal}
                    />
                ) : null}

                <RefactoredCashOrderModal />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </React.Fragment>
        );

        if (withoutLayout) return body;

        return (
            <Layout controls={controls} description=' ' title={<FormattedMessage id='navigation.flow_of_money' />}>
                {body}
            </Layout>
        );
    }
}
