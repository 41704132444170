/* eslint-disable max-classes-per-file */
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Drawer,
    Image,
    Input,
    InputNumber,
    Modal,
    Select,
    Switch,
    Table,
    Tabs,
    TreeSelect,
    notification
} from 'antd';
import { Layout } from 'commons';
import { fetchWarehouses } from 'core/warehouses/duck';
import _, { get } from 'lodash';
import { filterTreeByActive } from 'pages/AccountPlanPage/utils/filterTreeByActive';
import { getAccountsList } from 'pages/AccountPlanPage/utils/getAccountsList';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, filterTreeNodeByPart } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;
const { TabPane } = Tabs;
const MAIN = 'MAIN';
const RESERVE = 'RESERVE';
const TOOL = 'TOOL';
const REPAIR_AREA = 'REPAIR_AREA';
const STORAGE = 'STORAGE';
const PRODUCTION = 'PRODUCTION';

const mapStateToProps = state => {
    return {
        user: state.auth,
        warehouses: state.warehouses.warehouses
    };
};

const mapDispatchToProps = {
    fetchWarehouses
};
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class WarehousesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warehouses: [],
            isMain: false,
            isReserve: false,
            isTool: false,
            isRepairArea: false,
            modalVisible: false,
            allLinks: [],
            helperDrawerOpen: false,
            activeKey: undefined,
            classesABC: [],
            classesXYZ: []
        };

        this.getWarehouses = this.getWarehouses.bind(this);

        this.columns = [
            {
                title: '№',
                key: 'key',
                dataIndex: 'key',
                width: '5%',
                render: (data, elem) => {
                    return data + 1;
                }
            },
            {
                title: <FormattedMessage id='storage' />,
                key: 'name',
                dataIndex: 'name',
                width: 'auto',
                render: (data, elem) => {
                    return data;
                }
            },
            {
                title: <FormattedMessage id='storage.attribute' />,
                key: 'attribute',
                dataIndex: 'attribute',
                width: 'auto',
                render: (data, elem) => {
                    return data || <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='directory_page.requisites' />,
                key: 'businessRequisiteName',
                dataIndex: 'businessRequisiteName',
                width: 'auto',
                render: data => {
                    return data;
                }
            },
            {
                title: <FormattedMessage id='storage.consider_quantity' />,
                key: 'considerQuantity',
                dataIndex: 'considerQuantity',
                // sorter:    (a, b) => a.considerQuantity ? 1 : b.considerQuantity ? -1 : 0,
                width: 'auto',
                render: (data, elem) => {
                    return data ? (
                        <CheckOutlined style={{ color: 'var(--green)' }} />
                    ) : (
                        <CloseOutlined style={{ color: 'var(--warning)' }} />
                    );
                }
            },
            {
                title: <FormattedMessage id='storage.direct_sales' />,
                key: 'directSales',
                dataIndex: 'directSales',
                // sorter:    (a, b) => a.considerQuantity ? 1 : b.considerQuantity ? -1 : 0,
                width: 'auto',
                render: (data, elem) => {
                    return data ? (
                        <CheckOutlined style={{ color: 'var(--green)' }} />
                    ) : (
                        <CloseOutlined style={{ color: 'var(--warning)' }} />
                    );
                }
            },

            {
                title: 'InterCoRate',
                key: 'interCoRate',
                width: 'auto',
                render: row => Number(row.interCoRate).toFixed(2)
            },
            {
                title: <FormattedMessage id='warehouses.show_not_show' />,
                key: 'toggleShow',
                width: '10%',
                render: row => (
                    <Switch
                        checked={row.disabled}
                        disabled={
                            !isGrantAccessed(this.props.user, grants.SETTINGS_STORES_HIDDEN_WAREHOUSE, accesses.ROWO)
                        }
                        onChange={async () => {
                            await fetchAPI(
                                'PUT',
                                `warehouses/${row.id}`,
                                undefined,
                                {
                                    disabled: !row.disabled,
                                    ..._.omit(row, [
                                        'id',
                                        'attribute',
                                        'businessId',
                                        'businessRequisiteName',
                                        'accountName',
                                        'key',
                                        'disabled'
                                    ])
                                },
                                { handleErrorInternally: true }
                            );
                            await setTimeout(() => this.getWarehouses(), 100);
                        }}
                    />
                )
            },
            {
                key: 'edit',
                width: '5%',
                render: elem => {
                    return (
                        <EditOutlined
                            onClick={() => {
                                if (isGrantAccessed(this.props.user, grants.SETTINGS_STORES, accesses.ROWO)) {
                                    this.setState({
                                        editMode: true,
                                        warehouse: elem
                                    });
                                }
                            }}
                            style={
                                !isGrantAccessed(this.props.user, grants.SETTINGS_STORES, accesses.ROWO)
                                    ? {
                                          fontSize: 18,
                                          color: 'var(--text2)',
                                          pointerEvents: 'none'
                                      }
                                    : {
                                          fontSize: 18
                                      }
                            }
                        />
                    );
                }
            },
            {
                title: () => {
                    const {
                        warehouses,
                        isMain,
                        isReserve,
                        isTool,
                        isRepairArea,
                        editMode,
                        warehouse,
                        modalVisible,
                        helperDrawerOpen,
                        allLinks
                    } = this.state;

                    return (
                        <AddWarehousesModal
                            disabled={!isGrantAccessed(this.props.user, grants.SETTINGS_STORES, accesses.ROWO)}
                            editMode={editMode}
                            finishEdit={() => {
                                this.setState({
                                    editMode: false,
                                    warehouse: undefined
                                });
                            }}
                            getWarehouses={this.getWarehouses}
                            isMain={isMain}
                            isRepairArea={isRepairArea}
                            isReserve={isReserve}
                            isTool={isTool}
                            modalVisible={modalVisible}
                            unsetVisible={() => {
                                this.setState({
                                    modalVisible: false
                                });
                            }}
                            user={this.props.user}
                            warehouse={warehouse}
                        />
                    );
                },
                key: 'delete',
                width: '5%',
                render: elem => {
                    return (
                        <DeleteOutlined
                            onClick={() => {
                                if (isGrantAccessed(this.props.user, grants.SETTINGS_STORES, accesses.ROWO)) {
                                    this.deleteWarehouse(elem.id);
                                }
                            }}
                            style={
                                !isGrantAccessed(this.props.user, grants.SETTINGS_STORES, accesses.ROWO)
                                    ? {
                                          fontSize: 18,
                                          color: 'var(--text2)',
                                          pointerEvents: 'none'
                                      }
                                    : {
                                          fontSize: 18
                                      }
                            }
                        />
                    );
                }
            }
        ];

        this.classesColumns = [
            {
                title: '№',
                key: 'number',
                width: '5%',
                render: (data, elem, index) => {
                    return index + 1;
                }
            },
            {
                title: <FormattedMessage id='services_table.short_specification' />,
                key: 'key',
                dataIndex: 'key',
                width: 'auto',
                render: (data, elem) => {
                    return data;
                }
            },
            {
                title: <FormattedMessage id='info_modal.value' />,
                key: 'value',
                dataIndex: 'value',
                width: 'auto',
                render: (data, elem) => {
                    return `${data && data.toFixed(2)}%` || <FormattedMessage id='long_dash' />;
                }
            },

            {
                key: 'edit',
                width: '5%',
                render: row => {
                    return (
                        row.key !== 'E' && (
                            <EditOutlined
                                onClick={() => {
                                    if (isGrantAccessed(this.props.user, grants.SETTINGS_STORES, accesses.ROWO)) {
                                        this.setState({
                                            editModalOpen: true,
                                            rowClasses: row,
                                            classValue: row.value
                                        });
                                    }
                                }}
                                style={
                                    !isGrantAccessed(this.props.user, grants.SETTINGS_STORES, accesses.ROWO)
                                        ? {
                                              fontSize: 18,
                                              color: 'var(--text2)',
                                              pointerEvents: 'none'
                                          }
                                        : {
                                              fontSize: 18
                                          }
                                }
                            />
                        )
                    );
                }
            }
        ];
    }

    getWarehouses = async () => {
        const data = await fetchAPI(
            'GET',
            'warehouses',
            {
                disabled: isGrantAccessed(this.props.user, grants.SETTINGS_STORES_HIDDEN_WAREHOUSE, accesses.ROWO)
                    ? true
                    : undefined
            },
            null
        );
        this.setState({
            dataSource: data
        });

        let isMain = false;
        let isReserve = false;
        let isTool = false;
        let isRepairArea = false;
        data.map((warehouse, i) => {
            warehouse.key = i;
            if (warehouse.attribute == MAIN) {
                isMain = true;
            }
            if (warehouse.attribute == RESERVE) {
                isReserve = true;
            }
            if (warehouse.attribute == TOOL) {
                isTool = true;
            }
            if (warehouse.attribute == REPAIR_AREA) {
                isRepairArea = true;
            }
        });
        this.setState({
            warehouses: data,
            isMain,
            isReserve,
            isTool,
            isRepairArea
        });
    };

    deleteWarehouse = async id => {
        try {
            await fetchAPI('DELETE', `warehouses/${id}`, undefined, undefined, {
                handleErrorInternally: true
            });
            await this.getWarehouses();
        } catch (e) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'storage.cannot_delete' })
            });
        }
    };

    editClass = async (id, value) => {
        try {
            await fetchAPI(
                'PUT',
                'classes',
                undefined,
                { id, value },
                {
                    handleErrorInternally: true
                }
            );
            this.setState({
                editModalOpen: false,
                rowClasses: undefined
            });

            if (this.state.activeKey === 'ABC') {
                this.getClassesABC();
            }
            if (this.state.activeKey === 'XYZ') {
                this.getClassesXYZ();
            }
            notification.success({
                message: this.props.intl.formatMessage({ id: 'message.success' })
            });
        } catch (e) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'error' })
            });
        }
    };

    getClassesABC = async () => {
        const data = await fetchAPI('GET', 'classes', { type: 'ABC' }, null, {
            handleErrorInternally: true
        });
        this.setState({
            classesABC: data
        });
    };

    getClassesXYZ = async () => {
        const data = await fetchAPI('GET', 'classes', { type: 'XYZ' }, null, {
            handleErrorInternally: true
        });
        this.setState({
            classesXYZ: data
        });
    };

    resetClasses = async type => {
        try {
            await fetchAPI(
                'PUT',
                '/reset_classes',
                null,
                { type },
                {
                    handleErrorInternally: true
                }
            );
            if (type === 'ABC') {
                this.getClassesABC();
            }
            if (type === 'XYZ') {
                this.getClassesXYZ();
            }
            notification.success({
                message: this.props.intl.formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'error' })
            });
        }
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_warehouses' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    createForInterCo = async () => {
        try {
            await fetchAPI('POST', '/inter_co_business_counterparts', null, undefined, {
                handleErrorInternally: true
            });
            notification.success({
                message: this.props.intl.formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'error' })
            });
        }
    };

    componentDidMount() {
        // this.props.fetchWarehouses();
        this.getWarehouses();

        if (this.props.location.state && this.props.location.state.showForm) {
            this.setState({
                modalVisible: true
            });
        }
    }

    componentDidUpdate() {
        if (this.state.activeKey === 'ABC' && !get(this.state.classesABC, 'length')) {
            this.getClassesABC();
        }
        if (this.state.activeKey === 'XYZ' && !get(this.state.classesXYZ, 'length')) {
            this.getClassesXYZ();
        }
    }

    render() {
        const {
            warehouses,
            isMain,
            isReserve,
            isTool,
            isRepairArea,
            editMode,
            warehouse,
            modalVisible,
            helperDrawerOpen,
            allLinks,
            classesABC,
            classesXYZ,
            editModalOpen,
            rowClasses,
            classValue
        } = this.state;

        return (
            <Layout
                controls={
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <div
                            style={{
                                marginLeft: 8,
                                display: 'flex'
                            }}
                        >
                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    this.setState({
                                        helperDrawerOpen: true
                                    });
                                    await this.fetchHelperLinks();
                                }}
                                style={{
                                    fontSize: 22,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </div>
                    </div>
                }
                title={<FormattedMessage id='navigation.warehouses' />}
            >
                <Tabs
                    onTabClick={key => {
                        this.setState({
                            activeKey: key
                        });
                    }}
                >
                    <TabPane
                        key='1'
                        tab={this.props.intl.formatMessage({
                            id: 'navigation.warehouses'
                        })}
                    >
                        <div className={Styles.btnInterCo}>
                            <Button onClick={() => this.createForInterCo()} type='primary'>
                                <FormattedMessage id='warehouses.create_counterpart_interCo' />
                            </Button>
                        </div>
                        <Table
                            bordered
                            columns={this.columns}
                            dataSource={warehouses}
                            pagination={false}
                            size='small'
                        />
                    </TabPane>
                    <TabPane
                        key='ABC'
                        tab={this.props.intl.formatMessage({
                            id: 'classes.ABC'
                        })}
                    >
                        <div className={Styles.btnResetClasses}>
                            <Button onClick={() => this.resetClasses('ABC')} type='primary'>
                                <FormattedMessage id='classes.reset_classes' />
                            </Button>
                        </div>
                        <Table
                            bordered
                            columns={this.classesColumns}
                            dataSource={classesABC}
                            pagination={false}
                            size='small'
                        />
                    </TabPane>
                    <TabPane
                        key='XYZ'
                        tab={this.props.intl.formatMessage({
                            id: 'classes.XYZ'
                        })}
                    >
                        <div className={Styles.btnResetClasses}>
                            <Button onClick={() => this.resetClasses('XYZ')} type='primary'>
                                <FormattedMessage id='classes.reset_classes' />
                            </Button>
                        </div>
                        <Table
                            bordered
                            columns={this.classesColumns}
                            dataSource={classesXYZ}
                            pagination={false}
                            size='small'
                        />
                    </TabPane>
                </Tabs>
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
                <Modal
                    maskClosable={false}
                    onCancel={() => {
                        this.setState({
                            editModalOpen: false,
                            rowClasses: undefined
                        });
                    }}
                    onOk={() => this.editClass(rowClasses.id, classValue)}
                    open={editModalOpen}
                    title={<FormattedMessage id='classes.edit_class' />}
                    width='fit-content'
                >
                    <div
                        style={{
                            marginBottom: 8
                        }}
                    >
                        <FormattedMessage id='info_modal.value' />:
                    </div>
                    <InputNumber
                        formatter={value => `${value}%`}
                        max={100}
                        min={-100}
                        onChange={value => {
                            this.setState({
                                classValue: value
                            });
                        }}
                        parser={value => value.replace('%', '')}
                        style={{
                            width: '100%'
                        }}
                        value={classValue}
                    />
                </Modal>
            </Layout>
        );
    }
}

export default WarehousesPage;

@injectIntl
class AddWarehousesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            attribute: null,
            considerQuantity: true,
            directSales: true,
            accountId: props.user.warehouseAccountId,
            accounts: [],
            tree: []
        };
    }

    postWarehouse = async () => {
        await fetchAPI(
            'POST',
            'warehouses',
            undefined,
            {
                name: this.state.name,
                attribute: this.state.attribute || null,
                considerQuantity: this.state.considerQuantity,
                directSales: this.state.directSales,
                accountId: String(this.state.accountId),
                interCoRate: this.state.interCoRate || 0,
                businessRequisiteId: this.state.businessRequisiteId || null
            },
            { handleErrorInternally: true }
        );
        await this.props.getWarehouses();
    };

    editWarehouse = async () => {
        const editData = {
            name: this.state.name,
            attribute: this.state.attribute,
            considerQuantity: this.state.considerQuantity,
            directSales: this.state.directSales,
            accountId: String(this.state.accountId),
            interCoRate: this.state.interCoRate || 0,
            businessRequisiteId: this.state.businessRequisiteId || null
        };

        if (!this.state.attribute) {
            delete editData.attribute;
        }

        await fetchAPI('PUT', `warehouses/${this.props.warehouse.id}`, undefined, editData, {
            handleErrorInternally: true
        });
        await this.props.getWarehouses();
    };

    handleOk = () => {
        if (this.props.editMode) {
            this.editWarehouse();
        } else {
            this.postWarehouse();
        }
        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            name: undefined,
            visible: false,
            attribute: null,
            considerQuantity: true,
            directSales: true,
            businessRequisiteId: undefined,
            interCoRate: 0
        });
        this.props.finishEdit();
    };

    fetchAccounts = async query => {
        const accounts = await getAccountsList(query);
        this.filterTree(accounts);
    };

    filterTree = nodes => {
        const filteredNodes = filterTreeByActive(nodes);

        this.setState({
            tree: filteredNodes
        });
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.editMode && this.props.editMode) {
            const { warehouse } = this.props;
            this.setState({
                visible: true,
                name: warehouse.name,
                attribute: warehouse.attribute,
                accountId: warehouse.accountId,
                considerQuantity: warehouse.considerQuantity,
                directSales: warehouse.directSales,
                businessRequisiteId: warehouse.businessRequisiteId,
                interCoRate: warehouse.interCoRate || 0
            });
        }
        if (this.props.modalVisible && !this.state.visible) {
            this.setState({
                visible: true
            });
            this.props.unsetVisible();
        }
    }

    getRequisites = async () => {
        const response = await fetchAPI('GET', 'businesses/requisites', undefined, undefined, {
            handleErrorInternally: true
        });
        this.setState({ requisites: response });
    };

    componentDidMount() {
        this.getRequisites();
        this.fetchAccounts();
    }

    render() {
        const {
            isMain,
            isReserve,
            isTool,
            isRepairArea,
            editMode,
            intl: { formatMessage },
            disabled
        } = this.props;

        return (
            <React.Fragment>
                <Button
                    disabled={disabled}
                    onClick={() => {
                        this.setState({
                            visible: true
                        });
                    }}
                    type='primary'
                >
                    <FormattedMessage id='add' />
                </Button>
                <Modal
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={this.state.visible}
                    title={`${formatMessage({ id: editMode ? 'edit' : 'add' })} ${formatMessage({
                        id: 'storage'
                    }).toLowerCase()}`}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <FormattedMessage id='storage.name' />:
                        <Input
                            onChange={event => {
                                this.setState({
                                    name: event.target.value
                                });
                            }}
                            style={{ marginLeft: 8 }}
                            value={this.state.name}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 16
                        }}
                    >
                        <FormattedMessage id='directory_page.requisites' />:
                        <Select
                            allowClear
                            onChange={businessRequisiteId => {
                                this.setState({
                                    businessRequisiteId
                                });
                            }}
                            placeholder={formatMessage({ id: 'directory_page.requisites' })}
                            style={{
                                marginLeft: 8,
                                display: 'block',
                                width: '100%'
                            }}
                            value={this.state.businessRequisiteId}
                        >
                            {this.state.requisites &&
                                this.state.requisites
                                    .filter(({ enabled }) => enabled)
                                    .map(({ id, name }) => (
                                        <Option key={id} value={id}>
                                            {name}
                                        </Option>
                                    ))}
                        </Select>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 16
                        }}
                    >
                        <FormattedMessage id='storage.attribute' />:
                        <Select
                            defaultActiveFirstOption
                            disabled={
                                (this.state.attribute == MAIN && isMain) ||
                                (this.state.attribute == RESERVE && isReserve) ||
                                (this.state.attribute == TOOL && isTool) ||
                                (this.state.attribute == REPAIR_AREA && isRepairArea)
                            }
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            onChange={attribute => {
                                if (attribute === STORAGE) {
                                    this.setState({
                                        directSales: false
                                    });
                                }
                                this.setState({
                                    attribute
                                });
                            }}
                            style={{
                                marginLeft: 8,
                                display: 'block',
                                width: '100%'
                            }}
                            value={this.state.attribute}
                        >
                            <Option value={null}>
                                <FormattedMessage id='long_dash' />
                            </Option>
                            <Option disabled={isMain && this.state.attribute != MAIN} value={MAIN}>
                                {MAIN}
                            </Option>
                            <Option disabled={isReserve && this.state.attribute != RESERVE} value={RESERVE}>
                                {RESERVE}
                            </Option>
                            <Option disabled={isTool && this.state.attribute != TOOL} value={TOOL}>
                                {TOOL}
                            </Option>
                            <Option disabled={isRepairArea && this.state.attribute != REPAIR_AREA} value={REPAIR_AREA}>
                                {REPAIR_AREA}
                            </Option>
                            <Option value={STORAGE}>{STORAGE}</Option>
                            <Option value={PRODUCTION}>{PRODUCTION}</Option>
                        </Select>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 16
                        }}
                    >
                        <FormattedMessage id='storage.consider_quantity' />:
                        <Checkbox
                            checked={this.state.considerQuantity}
                            onChange={() => {
                                this.setState({
                                    considerQuantity: !this.state.considerQuantity
                                });
                            }}
                            style={{ marginLeft: 8 }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 16
                        }}
                    >
                        <FormattedMessage id='storage.direct_sales' />:
                        <Checkbox
                            checked={this.state.directSales}
                            onChange={() => {
                                this.setState({
                                    directSales: !this.state.directSales
                                });
                            }}
                            style={{ marginLeft: 8 }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 16
                        }}
                    >
                        <FormattedMessage id='new-document-page.item.account' />:
                        <TreeSelect
                            filterTreeNode={filterTreeNodeByPart}
                            onChange={accountId => {
                                this.setState({
                                    accountId
                                });
                            }}
                            placeholder={formatMessage({ id: 'directory_page.requisites' })}
                            showSearch
                            style={{
                                marginLeft: 8,
                                display: 'block',
                                width: '100%'
                            }}
                            treeData={this.state.tree}
                            value={this.state.accountId}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 16
                        }}
                    >
                        InterCoRate:
                        <InputNumber
                            onChange={value =>
                                this.setState({
                                    interCoRate: value
                                })
                            }
                            placeholder='InterCoRate'
                            precision={2}
                            style={{
                                marginLeft: 8,
                                display: 'block',
                                width: '100%'
                            }}
                            value={this.state.interCoRate}
                        />
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
