import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import toObject from 'dayjs/plugin/toObject';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { intl } from 'store/intl';
import { getLocale } from './sideEffects';

import 'dayjs/locale/bg';
import 'dayjs/locale/cs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';
import 'dayjs/locale/pl';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(toObject);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(quarterOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(updateLocale);
dayjs.extend(isBetween);
dayjs.extend(timezone);

dayjs.updateLocale('en-gb', {
    weekStart: 1
});

// dayjs.tz.setDefault('Etc/UTC');

const language = getLocale();
dayjs.updateLocale(language, {
    weekStart: 1
});
dayjs.locale(language || 'uk');

const defaultSelector = state => state.intl;

const mapStateToProps = (state, { intlSelector = defaultSelector }) => {
    if (intlSelector(state)) {
        return intlSelector(state);
    }

    return {
        locale: intl.locale,
        messages: intl.messages
    };
};

export const ConnectedIntlProvider = connect(mapStateToProps, null, null, {
    pure: false
})(IntlProvider);
