import { Input, InputNumber, Modal, notification, Select, Switch, TreeSelect } from 'antd';
import _, { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildStoreGroupsTree, fetchAPI } from 'utils';
import Styles from '../styles.m.css';

const { Option } = Select;

const mapStateToProps = state => {
    return {
        user: state.auth,
        isMobile: state.ui.views.isMobile
    };
};

@injectIntl
@connect(mapStateToProps, void 0)
export class AddLaborModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            masterLabors: [],
            labors: [],
            units: []
        };
    }

    componentDidMount() {
        this.fetchData();
        this.fetchLabors();
    }

    componentDidUpdate(prevProps) {
        const { visible, unitDefault } = this.props;
        if (!prevProps.visible && visible) {
            const defaultUnit = unitDefault.map(({ measurementUnits }) => measurementUnits).flat();
            const defaultUse = defaultUnit.filter(({ defaultUse }) => defaultUse);

            this.setState({
                laborUnitId: _.get(defaultUse, '[0].id')
            });
        }
    }

    handleCancel = () => {
        const { hideModal } = this.props;
        this.setState({
            crossId: undefined,
            fixed: undefined,
            masterLaborId: undefined,
            name: undefined,
            normHours: undefined,
            price: undefined,
            storeGroupId: undefined,
            laborUnitId: undefined,
            foundLabors: undefined
        });
        hideModal();
    };

    handleOk = () => {
        this.saveAddLabor();
        this.handleCancel();
        this.props.fetchLabors();
    };

    fetchLabors = async () => {
        const response = await fetchAPI('GET', 'labors', { all: true });
        response.labors.sort((a, b) =>
            a.masterLaborId < b.masterLaborId ? -1 : a.masterLaborId > b.masterLaborId ? 1 : 0
        );
        response.labors.map((elem, index) => {
            elem.key = index;
            elem.laborCode = `${elem.masterLaborId}-${elem.storeGroupId}`;
            elem.price = elem.laborPrice.price;
            elem.fixed = elem.laborPrice.fixed;
            elem.normHours = elem.laborPrice.normHours;
            elem.customName = elem.customName || elem.name;
        });
        this.setState({
            labors: response.labors
        });

        if (this.props.location.state && this.props.location.state.showForm) {
            this.nameInput.focus();
        }
    };

    saveAddLabor = async () => {
        const { crossId, fixed, masterLaborId, name, normHours, price, storeGroupId, disabled, barcode, laborUnitId } =
            this.state;
        await fetchAPI(
            'PUT',
            'labors',
            null,
            [
                {
                    id: `${masterLaborId}${storeGroupId}`,
                    masterLaborId,
                    storeGroupId: storeGroupId || null,
                    crossId: crossId || null,
                    fixed: Boolean(fixed) || false,
                    name: name || null,
                    price: price || 1,
                    normHours: normHours || 1,
                    disabled: Boolean(disabled),
                    barcode,
                    laborUnitId
                }
            ],
            {
                handleErrorInternally: true
            }
        );
    };

    fetchData = async () => {
        const masterLabors = await fetchAPI('GET', 'labors/master');
        const units = await fetchAPI('GET', 'business/measurement/units');
        this.setState({
            masterLabors: masterLabors.masterLabors,
            units
        });
        const storeGroups = await fetchAPI('GET', 'store_groups');

        const tree = buildStoreGroupsTree(storeGroups);
        this.setState({
            storeGroups,
            treeData: tree
        });
    };

    render() {
        const { visible, findExistingLabors, loadingModal, isMobile } = this.props;
        const {
            crossId,
            fixed,
            name,
            normHours,
            price,
            labors,
            storeGroupId,
            masterLaborId,
            laborUnitId,
            foundLabors,
            treeData
        } = this.state;

        return (
            <Modal
                maskClosable={false}
                okButtonProps={{
                    disabled: get(foundLabors, 'length') || !masterLaborId || !storeGroupId || !name,
                    loading: loadingModal
                }}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                title={<FormattedMessage id='navigation.add_labor' />}
                visible={visible}
                width='75%'
            >
                <div className={isMobile ? Styles.blockStyleMobile : Styles.blockStyle}>
                    <div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='order_form_table.store_group' />
                            <TreeSelect
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                filterTreeNode={(input, node) =>
                                    node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={async value => {
                                    this.setState({ storeGroupId: value });

                                    if (value && masterLaborId) {
                                        const laborExist = await findExistingLabors(value, masterLaborId);

                                        this.setState({
                                            foundLabors: laborExist
                                        });

                                        console.log(laborExist, 'laborExist');

                                        if (get(laborExist, 'length')) {
                                            notification.warning({
                                                message: this.props.intl.formatMessage({
                                                    id: 'labor_form.notification'
                                                })
                                            });
                                        }
                                    }
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_code'
                                })}
                                showSearch
                                style={{ minWidth: '83%' }}
                                treeData={treeData}
                                value={storeGroupId}
                            />
                        </div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='order_form_table.detail_name' />
                            <Input
                                onChange={event => {
                                    this.setState({ name: event.target.value });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_name'
                                })}
                                style={{ color: 'var(--text)', maxWidth: isMobile ? 'auto' : '83%' }}
                                value={name}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='order_form_table.service_type' />
                            <Select
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.props.value).indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={async (value, option) => {
                                    this.setState({ masterLaborId: value });

                                    if (storeGroupId && value) {
                                        const laborExist = await findExistingLabors(storeGroupId, value);

                                        this.setState({
                                            foundLabors: laborExist
                                        });

                                        if (get(laborExist, 'length')) {
                                            notification.warning({
                                                message: this.props.intl.formatMessage({
                                                    id: 'labor_form.notification'
                                                })
                                            });
                                        }
                                    }
                                }}
                                placeholder='ID'
                                showSearch
                                style={{ minWidth: '100px', maxWidth: isMobile ? 'auto' : '83%' }}
                                value={masterLaborId}
                            >
                                {this.state.masterLabors.map((elem, index) => (
                                    <Option key={elem.masterLaborId} value={elem.masterLaborId}>
                                        {elem.defaultMasterLaborName}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className={Styles.rowStyles}>
                            <div>
                                <FormattedMessage id='order_form_table.external_id' />
                            </div>
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({ crossId: event.target.value });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.external_id'
                                })}
                                style={{
                                    maxWidth: isMobile ? 'auto' : '83%'
                                }}
                                value={crossId}
                            />
                        </div>
                        {/* <div className={Styles.rowStyles}>
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        laborName: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.service_type'
                                })}
                                style={{
                                    maxWidth: '83%'
                                }}
                                value={laborName}
                            />
                        </div> */}
                    </div>

                    <div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='services_table.units' />
                            <Select
                                allowClear
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => {
                                    this.setState({
                                        laborUnitId: value
                                    });
                                }}
                                optionFilterProp='children'
                                placeholder={this.props.intl.formatMessage({
                                    id: 'services_table.units_placeholder'
                                })}
                                popupMatchSelectWidth={100}
                                showSearch
                                style={{ minWidth: '100px', maxWidth: isMobile ? 'auto' : '83%' }}
                                value={laborUnitId}
                            >
                                {(this.state.units || []).map((elem, index) => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.shortcut}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div
                            style={{
                                marginTop: 32
                            }}
                        >
                            <span
                                style={{
                                    marginRight: 12
                                }}
                            >
                                <FormattedMessage id='order_form_table.fixed' />
                            </span>
                            <Switch
                                checked={fixed}
                                onChange={async () => {
                                    await this.setState({ fixed: !fixed });
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={Styles.rowStyles}>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='hours' />
                            </span>
                            <InputNumber
                                decimalSeparator=','
                                defaultValue={1}
                                disabled={fixed}
                                min={0.1}
                                onChange={normHours => this.setState({ normHours })}
                                step={0.2}
                                style={{ color: 'var(--text)', marginTop: 22 }}
                                value={normHours}
                            />
                        </div>
                        <div className={Styles.rowStyles}>
                            <span
                                style={{
                                    marginRight: 22
                                }}
                            >
                                <FormattedMessage id='order_form_table.price' />
                            </span>
                            <InputNumber
                                decimalSeparator=','
                                defaultValue={1}
                                disabled={!fixed}
                                min={1}
                                onChange={price => this.setState({ price })}
                                style={{ color: 'var(--text)' }}
                                value={price}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
