import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Modal, Select, notification } from 'antd';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const AddCrewModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        edit,
        crews,
        fetchCrews,
        setEdit
    } = props;

    const [crewName, setCrewName] = useState();
    const [crewStatus, setCrewStatus] = useState('ACTIVE');
    const [crewMembers, setCrewMembers] = useState([{ crewId: edit && edit.id, employeeId: undefined }]);
    const [phones, setPhones] = useState([]);
    const [address, setAddress] = useState();
    const [allEmployees, setEmployees] = useState([]);

    const getAllEmployees = useCallback(async query => {
        const response = await fetchAPI('GET', 'employees', { query }, null, {
            handleErrorInternally: true
        });

        setEmployees(
            response && response.filter(({ posts, postref }) => posts.find(({ postName }) => postName === 'MECHANIC'))
        );
    }, []);

    const handleSearchEmployees = useMemo(() => {
        return _.debounce(getAllEmployees, 800);
    }, [getAllEmployees]);

    const handleEmployeeChange = (index, employeeId, percent, id) => {
        const newCrewMemeber = [...crewMembers];
        newCrewMemeber[index].crewId = edit && edit.id;
        newCrewMemeber[index].employeeId = employeeId;
        newCrewMemeber[index].percent = percent;

        setCrewMembers(newCrewMemeber);
    };

    const handleAddEmployee = () => {
        setCrewMembers([...crewMembers, { employeeId: undefined }]);
    };

    const handleDeleteObject = index => {
        const updatedArray = crewMembers.filter((item, idx) => idx !== index);
        setCrewMembers([...updatedArray]);
    };

    const deleteCrewMember = useCallback(
        async (row, index) => {
            await fetchAPI(
                'DELETE',
                '/crew/members',
                null,
                {
                    id: row.id
                },
                {
                    handleErrorInternally: true
                }
            );

            await fetchCrews();

            handleDeleteObject(index);
        },
        [fetchCrews, handleDeleteObject]
    );

    useEffect(() => {
        if (edit) {
            setCrewName(edit.crewName);
            setCrewStatus(edit.crewStatus);
            if (get(edit.crewMembers, 'length')) {
                setCrewMembers(edit.crewMembers);
            }
        }
    }, [edit]);

    useEffect(() => {
        if (open && !get(allEmployees, 'length')) {
            getAllEmployees();
        }
    }, [allEmployees, getAllEmployees, open]);

    const onClose = () => {
        setCrewName(undefined);
        setCrewStatus('ACTIVE');
        setCrewMembers([{ crewId: edit && edit.id, employeeId: undefined }]);

        hideModal();
    };

    const postNewCrewMembers = async crewId => {
        const updatedData = crewMembers
            .filter(({ id }) => !id)
            .map(item => ({
                ...item,
                crewId
            }));

        try {
            if (get(updatedData, 'length')) {
                await fetchAPI('POST', '/crew/members', undefined, [...updatedData], { handleErrorInternally: true });

                // notification.success({
                //     message: formatMessage({ id: 'message.success' })
                // });
            }
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const addCrew = async () => {
        try {
            const res = await fetchAPI(
                'POST',
                '/crew',
                undefined,
                {
                    crewName,
                    crewStatus
                },
                { handleErrorInternally: true }
            );

            await postNewCrewMembers(res && res[0].insertedId);
            await fetchCrews();
            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const postCrewMembers = async payload => {
        try {
            await fetchAPI(
                'POST',
                '/crew/members',
                undefined,
                [{ crewId: payload.crewId, employeeId: payload.employeeId, percent: payload.percent }],
                { handleErrorInternally: true }
            );

            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const handleDebouncePostPercent = useMemo(() => {
        const loadOptions = value => {
            postCrewMembers(value);
        };

        return _.debounce(loadOptions, 1000);
    }, []);

    const putCrewMember = async crewId => {
        const updatedData = crewMembers
            .filter(({ id }) => id)
            .map(({ employee, ...item }) => ({
                ...item,
                crewId
            }));

        try {
            await fetchAPI('PUT', '/crew/members', undefined, [...updatedData], { handleErrorInternally: true });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const handleDebounceSavePercent = useMemo(() => {
        const loadOptions = value => {
            putCrewMember(value);
        };

        return _.debounce(loadOptions, 1000);
    }, []);

    const editCrew = async () => {
        try {
            await fetchAPI(
                'PUT',
                '/crews',
                undefined,
                {
                    crewName,
                    crewStatus,
                    filters: {
                        id: edit.id
                    }
                },
                { handleErrorInternally: true }
            );

            await postNewCrewMembers(edit && edit.id);
            await putCrewMember(edit && edit.id);
            await fetchCrews();
            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    // useEffect(() => {
    //     if (open) {
    //         setPhones([]);
    //         setPhones([{ code: '', number: '', country: 'ua' }]);
    //     }
    // }, [open]);

    const onFinish = async () => {
        if (!edit) {
            addCrew();
            await onClose();
        } else {
            editCrew();
            await onClose();
        }
    };

    const totalPercentage = crewMembers.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.percent;
    }, 0);

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: !crewName || !crewStatus || totalPercentage !== 100
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={!edit ? <FormattedMessage id='crews.create_crew' /> : <FormattedMessage id='crews.edit_crew' />}
            width='50%'
        >
            <div>
                <div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.name' /> <span className={Styles.red}>*</span>
                        </div>
                        <Input
                            onChange={event => setCrewName(event.target.value)}
                            placeholder={formatMessage({
                                id: 'description'
                            })}
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={crewName}
                        />
                    </div>
                    <div className={Styles.marginStatus}>
                        <div>
                            <FormattedMessage id='status' /> <span className={Styles.red}>*</span>
                        </div>
                        <Select
                            onChange={value => {
                                setCrewStatus(value);
                            }}
                            placeholder={formatMessage({
                                id: 'status'
                            })}
                            style={{ color: 'var(--text)', width: '100%', marginBottom: 8 }}
                            value={crewStatus}
                        >
                            <Option key='ACTIVE' value='ACTIVE'>
                                <FormattedMessage id='crews.status.ACTIVE' />
                            </Option>
                            <Option key='CANCELED' value='CANCELED'>
                                <FormattedMessage id='crews.status.CANCELED' />
                            </Option>
                        </Select>
                    </div>

                    <div className={Styles.margin}>
                        {crewMembers && open
                            ? crewMembers.map((elem, index) => {
                                  return (
                                      <div>
                                          <div>
                                              {index + 1}. <FormattedMessage id='employee' />{' '}
                                              {/* <span className={Styles.red}>*</span> */}
                                          </div>

                                          <div
                                              style={{
                                                  marginBottom: 8,
                                                  display: 'flex',
                                                  justifyContent: 'space-between'
                                              }}
                                          >
                                              <Select
                                                  onChange={value => handleEmployeeChange(index, value, elem.percent)}
                                                  onSearch={input => handleSearchEmployees(input)}
                                                  //   onSelect={() => {
                                                  //       if (elem.employeeId && elem.percent && elem.id && edit) {
                                                  //           putCrewMember(elem);
                                                  //       }
                                                  //       //   if (elem.employeeId && elem.percent && !elem.id && !edit) {
                                                  //       //       postCrewMembers(elem);
                                                  //       //   }
                                                  //   }}
                                                  optionFilterProp='children'
                                                  placeholder={formatMessage({ id: 'employee' })}
                                                  showSearch
                                                  style={{
                                                      width: '70%',
                                                      marginRight: 6
                                                  }}
                                                  value={elem.employeeId}

                                                  // formatter={value => phoneNumberFormatter(value, user.country)}
                                              >
                                                  {allEmployees
                                                      .filter(({ disabled }) => !disabled)
                                                      .map(({ id, name, surname }) => (
                                                          <Option key={id} value={id}>
                                                              {`${name} ${surname}`}
                                                          </Option>
                                                      ))}
                                              </Select>
                                              <InputNumber
                                                  formatter={value => `${value}%`}
                                                  max={100}
                                                  min={1}
                                                  onChange={async value => {
                                                      await handleEmployeeChange(index, elem.employeeId, value);

                                                      //   if (elem.employeeId && elem.percent && elem.id && edit) {
                                                      //       handleDebounceSavePercent(elem);
                                                      //   }
                                                      //   if (elem.employeeId && elem.percent && !elem.id && edit) {
                                                      //       handleDebouncePostPercent(elem);
                                                      //   }
                                                  }}
                                                  parser={value => value.replace('%', '')}
                                                  style={{
                                                      width: '30%'
                                                  }}
                                                  value={elem.percent}
                                              />
                                              <Button
                                                  icon={<DeleteOutlined />}
                                                  onClick={() =>
                                                      elem.id
                                                          ? deleteCrewMember(elem, index)
                                                          : handleDeleteObject(index)
                                                  }
                                                  type='text'
                                              />
                                          </div>
                                      </div>
                                  );
                              })
                            : undefined}

                        <div className={Styles.marginAddBtn}>
                            <Button
                                icon={
                                    <PlusOutlined
                                        style={{
                                            marginRight: 8
                                        }}
                                    />
                                }
                                onClick={handleAddEmployee}
                                style={{
                                    marginTop: 8
                                }}
                            >
                                <FormattedMessage id='regulations.add_row' />
                            </Button>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end'
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='crews.total_percentage' />{' '}
                        </span>
                        : {isNaN(totalPercentage) ? <FormattedMessage id='crews.wrong_data' /> : `${totalPercentage} %`}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(AddCrewModal);
