import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, InputNumber, notification, Row, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, getCurrency } from 'utils';

const { Option } = Select;

@injectIntl
export class SalarySettingTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            conditions: [],
            allRules: []
        };
    }

    componentDidMount() {
        this.fetchRules();
        this.fetchConditions();
    }

    fetchConditions = async () => {
        const { employeeId } = this.props;
        const conditions = await fetchAPI('GET', 'salary/settings', { employeeId });
        this.setState({
            conditions: conditions.sort((a, b) => Number(a.condition) - Number(b.condition))
        });
    };

    fetchRules = async () => {
        const { positions } = this.props;
        const allRules = await fetchAPI('GET', 'salary/rules');
        this.setState({
            allRules: positions
                ? allRules.filter(({ postName }) => positions.find(ps => ps.postName === postName))
                : allRules
        });
    };

    updateCondition = async (payload, values) => {
        const { employeeId } = this.props;
        await fetchAPI('DELETE', 'salary/settings', null, { employeeId, ...payload });
        this.fetchConditions();
        await fetchAPI('POST', 'salary/settings', null, { employeeId, ...payload, ...values });
        this.fetchConditions();
    };

    saveChanges = async () => {
        const { conditions } = this.state;
        const { employeeId } = this.props;

        const payload = [];

        conditions.forEach(({ rules, bet, period, condition, onlyRate }) => {
            payload.push({
                bet,
                period,
                condition,
                employeeId,
                onlyRate,
                ruleIds: rules.map(({ ruleId }) => ruleId)
            });
        });

        await fetchAPI('POST', 'salary/settings', null, payload);
        notification.success({
            message: this.props.intl.formatMessage({ id: 'message.success' })
        });

        this.fetchConditions();
    };

    render() {
        const { employeeId } = this.props;
        const { conditions, allRules } = this.state;

        return (
            <React.Fragment>
                {conditions.map(({ condition, bet, period, rules, added, onlyRate }, ind) => (
                    <Card
                        style={{ width: '80%', margin: '0 auto' }}
                        title={
                            condition === null ? (
                                <FormattedMessage id='employee_salary_rules.basic_condition' />
                            ) : (
                                <React.Fragment>
                                    <FormattedMessage id='employee_salary_rules.condition' /> {ind + 1}
                                </React.Fragment>
                            )
                        }
                    >
                        <Row>
                            <Col span={6}>
                                <FormattedMessage id='export_import_pages.data_base' />
                            </Col>
                            <Col span={8}>
                                <Select
                                    onChange={val => {
                                        conditions[ind].period = val;
                                        this.setState({});
                                    }}
                                    value={period}
                                >
                                    {['DAY', 'WEEK', 'MONTH', 'HOURS'].map(period => (
                                        <Option key={period} value={period}>
                                            <FormattedMessage id={period} />
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={4}>
                                <Button
                                    disabled={ind === 0 && conditions.length > 1}
                                    icon={<DeleteOutlined style={{ fontSize: 22 }} />}
                                    onClick={async () => {
                                        if (added) {
                                            this.setState({
                                                conditions: conditions.filter((el, index) => index !== ind)
                                            });
                                        } else {
                                            await fetchAPI('DELETE', 'salary/settings', null, {
                                                employeeId,
                                                ruleId: rules[0].ruleId,
                                                bet,
                                                period,
                                                condition,
                                                onlyRate
                                            });
                                            notification.success({
                                                message: this.props.intl.formatMessage({
                                                    id: 'message.success'
                                                })
                                            });
                                            this.fetchConditions();
                                        }
                                    }}
                                    type='text'
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 8, marginBottom: 12 }}>
                            <Col span={6}>
                                <FormattedMessage id='employee_salary_rules.salary' />
                            </Col>
                            <Col span={8}>
                                <InputNumber
                                    addonAfter={getCurrency()}
                                    onChange={val => {
                                        conditions[ind].bet = val;
                                        this.setState({});
                                    }}
                                    style={{ width: '100%' }}
                                    value={bet}
                                />
                            </Col>
                            <Col span={4}>
                                <span
                                    style={{
                                        marginRight: 8,
                                        marginLeft: 8
                                    }}
                                >
                                    <FormattedMessage id='employee_salary_rules.only_rate' />
                                </span>
                                <Checkbox
                                    checked={onlyRate}
                                    onChange={() => {
                                        conditions[ind].onlyRate = !onlyRate;
                                        this.setState({});
                                    }}
                                />
                            </Col>
                        </Row>

                        {!onlyRate &&
                            rules.map(({ ruleId }, priority) => (
                                <Row style={{ marginTop: 8 }}>
                                    <Col span={6}>
                                        <FormattedMessage id='employee_salary_rules.rule_one' /> {priority + 1}
                                    </Col>
                                    <Col span={8}>
                                        <Select
                                            onChange={(val, option) => {
                                                conditions[ind].rules[priority].ruleId = val;
                                                conditions[ind].rules[priority].presetId = option.presetId;
                                                conditions[ind].rules[priority].postName = option.postName;
                                                this.setState({});
                                            }}
                                            value={ruleId}
                                        >
                                            {allRules
                                                .filter(({ presetId, postName }) =>
                                                    rules.length === 1
                                                        ? true
                                                        : presetId === rules[0].presetId &&
                                                          postName === rules[0].postName)
                                                .map(({ ruleId, ruleName, presetId, postName }) => (
                                                    <Option
                                                        key={ruleId}
                                                        postName={postName}
                                                        presetId={presetId}
                                                        value={ruleId}
                                                    >
                                                        {ruleName}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Col>
                                    <Col span={2}>
                                        <Button
                                            disabled={priority === 0}
                                            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                                            onClick={async () => {
                                                await fetchAPI('DELETE', 'salary/settings', null, {
                                                    employeeId,
                                                    ruleId,
                                                    bet,
                                                    period,
                                                    condition,
                                                    onlyRate
                                                });
                                                notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'message.success'
                                                    })
                                                });
                                                this.fetchConditions();
                                            }}
                                            type='text'
                                        />
                                    </Col>
                                </Row>
                            ))}
                        <Button
                            disabled={
                                allRules.filter(
                                    ({ presetId, postName, ruleId }) =>
                                        presetId === rules[0].presetId &&
                                        postName === rules[0].postName &&
                                        rules.findIndex(rl => rl.ruleId === ruleId) === -1
                                ).length === 0 || onlyRate
                            }
                            onClick={() => {
                                conditions[ind].rules.push({
                                    ruleId: allRules.filter(
                                        ({ presetId, postName, ruleId }) =>
                                            presetId === rules[0].presetId &&
                                            postName === rules[0].postName &&
                                            rules.findIndex(rl => rl.ruleId === ruleId) === -1
                                    )[0].ruleId
                                });
                                this.setState({});
                            }}
                            style={{ marginTop: 14 }}
                            type='primary'
                        >
                            <FormattedMessage id='employee_salary_rules.add_rule' />
                        </Button>
                    </Card>
                ))}
                <div
                    style={{
                        width: '80%',
                        margin: '14px auto 0',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        disabled={!allRules.length}
                        onClick={() => {
                            conditions.push({
                                rules: [
                                    {
                                        ruleId: allRules[0].ruleId,
                                        presetId: allRules[0].presetId,
                                        postName: allRules[0].postName
                                    }
                                ],
                                bet: 0,
                                period: 'WEEK',
                                condition: !conditions.length ? null : conditions.length,
                                added: true
                            });
                            this.setState({});
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='employee_salary_rules.add_setting' />
                    </Button>
                    <Button disabled={!allRules.length} onClick={this.saveChanges} type='primary'>
                        <FormattedMessage id='save' />
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}
