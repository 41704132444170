import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Modal, Tooltip } from 'antd';
import { ClientMRDsTable } from 'components';
import {
    fetchClientMRDs,
    setCashOrderEntityIsFetching,
    setCashOrderModalMounted,
    setClientMRDsPage,
    setFilterDate,
    setFilters
} from 'core/clientMRDs/duck';
import { clearCashOrderForm } from 'core/forms/cashOrderForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import { AddRowModal } from 'pages/Storage/StorageJournalPage/modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const DEF_DATE_FORMAT = 'DD/MM/YYYY';

const mapStateToProps = state => ({
    isFetching: state.ui.clientMRDsFetching,
    user: state.auth,
    modal: state.modals.modal,
    modalProps: state.modals.modalProps,
    datetime: state.clientMRDs.filter.datetime,
    cashOrderEntity: state.clientMRDs.cashOrderEntity,
    clientMRDsPage: state.clientMRDs.filter.page,
    pageSize: state.clientMRDs.filter.pageSize,
    mrds: state.clientMRDs.mrds,
    stats: state.clientMRDs.stats,
    cashOrderModalMounted: state.clientMRDs.cashOrderModalMounted,
    cashOrderEntityIsFetching: state.clientMRDs.cashOrderEntityIsFetching,
    filterSign: state.clientMRDs.filter.filterSign,
    filterDocumentType: state.clientMRDs.filter.filterDocumentType,
    datetimeFrom: state.clientMRDs.filter.datetimeFrom,
    datetimeTo: state.clientMRDs.filter.datetimeTo,
    nonZeroRows: state.clientMRDs.filter.nonZeroRows,
    filterBusinessRequisiteId: state.clientMRDs.filter.filterBusinessRequisiteId,
    filterCounterpartRequisiteId: state.clientMRDs.filter.filterCounterpartRequisiteId,
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {
    setModal,
    resetModal,
    fetchClientMRDs,
    fetchAPI,
    setClientMRDsPage,
    setFilterDate,
    setCashOrderModalMounted,
    setCashOrderEntityIsFetching,
    clearCashOrderForm,
    setFilters,
    fetchWarehouses
};

const { confirm } = Modal;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientMRDsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labors: []
        };
        this.onDatePicker = this.onDatePicker.bind(this);
    }

    componentDidMount() {
        const { clientId } = this.props;

        this.props.setFilterDate(dayjs());
        this.props.fetchClientMRDs({ clientId });
        // this.fetchLabors();
        this.props.fetchWarehouses();
    }

    // Event: date was changed in datapicker
    onDatePicker(date) {
        const { clientId } = this.props;

        this.props.setFilterDate(date);
        this.props.fetchClientMRDs({ clientId });
    }

    fetchLabors = async () => {
        const data = await fetchAPI('GET', '/labors', null, null, {
            handleErrorInternally: true
        });

        this.setState({ labors: data.labors });
    };

    getStoreDocProducts = async () => {
        const {
            dateRange,
            docTime,
            page,
            pageSize,
            filterDocumentNumber,
            filterDocumentType,
            filterSign,
            filterCounterpartId,
            filterCounterpartType,
            filterCounterpartName,
            filterRelatedDoc,
            filterBusinessRequisiteId,
            filterCounterpartRequisiteId
        } = this.state;
        const data = await fetchAPI(
            'GET',
            '/journal_calculations',
            {
                page,
                pageSize,
                // startDate: _.get(dateRange, '[0]') ? dateRange[0].format('YYYY-MM-DD') : undefined,
                // endDate: _.get(dateRange, '[1]') ? dateRange[1].format('YYYY-MM-DD') : undefined,
                filterDocumentNumber,
                filterDocumentType,
                filterSign,
                filterBusinessRequisiteId,
                filterCounterpartRequisiteId,
                filterCounterpartId,
                filterCounterpartType,
                filterStartDocDate: _.get(docTime, '[0]') ? docTime[0].format('YYYY-MM-DD') : undefined,
                filterEndDocDate: _.get(docTime, '[1]') ? docTime[1].format('YYYY-MM-DD') : undefined,
                filterCounterpartName,
                filterRelatedDoc,
                filterStartDate: _.get(dateRange, '[0]') ? dateRange[0].format('YYYY-MM-DD') : undefined,
                filterEndDate: _.get(dateRange, '[1]') ? dateRange[1].format('YYYY-MM-DD') : undefined
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            dataSource: data.res,
            stats: data.stats,
            sum: data.sum
        });
    };

    _showCashOrderModal = row => {
        const { clientId, client, setModal } = this.props;
        const { remaining, orderId, documentType, storeDocId, sign } = row;

        if (remaining > 0) {
            setModal(MODALS.REFACTORED_CASH_ORDER, {
                initValues: {
                    type: sign === '-' ? CASH_ORDER_TYPES.EXPENSE : CASH_ORDER_TYPES.INCOME,
                    counterpartyType: COUNTERPARTY_TYPES.CLIENT,
                    clientId,
                    orderId: storeDocId ? undefined : orderId,
                    sum: remaining,
                    signType: sign,
                    clientStoreDocId: storeDocId
                },
                onCashOrderCreatedCallback: () => this.props.fetchClientMRDs({ clientId })
            });
        }
    };

    render() {
        const {
            fetchClientMRDs,
            setClientMRDsPage,
            isFetching,
            mrds,
            stats,
            clientMRDsPage,
            clientId,
            nonZeroRows,
            modal,
            modalProps,
            pageSize,
            setFilters,
            filterSign,
            filterDocumentType,
            datetimeFrom,
            datetimeTo,
            user,
            filterBusinessRequisiteId,
            filterCounterpartRequisiteId,
            intl: { formatMessage },
            client,
            disabled
        } = this.props;

        const { visibleAddRowModal, labors } = this.state;

        // if (isFetching) {
        //     return <Loader loading={isFetching} />;
        // }

        return (
            <React.Fragment>
                <div className={Styles.headerContainer}>
                    <Tooltip title={<FormattedMessage id='add' />}>
                        <Button
                            disabled={
                                disabled ||
                                !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS_CORRECTION)
                            }
                            icon={<PlusOutlined />}
                            onClick={() => {
                                this.setState({ visibleAddRowModal: true });
                            }}
                            style={{
                                marginRight: 8
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='storage.reset_all_filters' />}>
                        <Button
                            disabled={disabled}
                            icon={<RedoOutlined />}
                            onClick={async () => {
                                await setFilters({
                                    dateRange: undefined,
                                    filterSign: undefined,
                                    page: 1,
                                    datetimeFrom: undefined,
                                    filterDocumentType: undefined,
                                    filterBusinessRequisiteId: undefined,
                                    filterCounterpartRequisiteId: undefined
                                });
                                fetchClientMRDs({ clientId });
                            }}
                            style={{
                                marginRight: 8
                            }}
                        />
                    </Tooltip>
                    <DatePicker
                        allowClear={false}
                        defaultValue={dayjs(this.props.datetime)}
                        disabled={disabled}
                        format={DEF_DATE_FORMAT}
                        onChange={date => this.onDatePicker(date)}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS, accesses.ROWO) && (
                        <div className={Styles.headerContainer}>
                            <Button
                                disabled={_.get(stats.oweAmount, 'debt') === _.get(stats.oweAmount, 'sum')}
                                onClick={() => {
                                    confirm({
                                        title: formatMessage({
                                            id: 'order_stats_info_modal.sure_to_recalculate'
                                        }),
                                        content: (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}
                                            >
                                                <div>
                                                    <Button
                                                        onClick={() => {
                                                            Modal.destroyAll();
                                                        }}
                                                    >
                                                        {this.props.intl.formatMessage({
                                                            id: 'cancel'
                                                        })}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'POST',
                                                                'recalculate/debts',
                                                                null,
                                                                {
                                                                    counterpartyId: clientId,
                                                                    counterpartyType: 'client'
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            fetchClientMRDs({ clientId });
                                                            Modal.destroyAll();
                                                        }}
                                                        type='primary'
                                                    >
                                                        {this.props.intl.formatMessage({
                                                            id: 'general_settings.submit'
                                                        })}
                                                    </Button>
                                                </div>
                                            </div>
                                        ),
                                        footer: null
                                    });
                                }}
                            >
                                <FormattedMessage id='order_stats_info_modal.recalculate' />
                            </Button>
                        </div>
                    )}
                    <div className={Styles.headerContainer}>
                        <span
                            style={{
                                marginRight: 12
                            }}
                        >
                            {' '}
                            <FormattedMessage id='supplier_debts.non_zero_rows' />
                        </span>
                        <Checkbox
                            checked={nonZeroRows}
                            onChange={() => {
                                setFilters({
                                    nonZeroRows: !nonZeroRows
                                });
                                fetchClientMRDs({ clientId });
                            }}
                        />
                    </div>
                </div>

                <ClientMRDsTable
                    clientId={clientId}
                    clientMRDsPage={clientMRDsPage}
                    datetimeFrom={datetimeFrom}
                    datetimeTo={datetimeTo}
                    fetchMRDs={fetchClientMRDs}
                    filterBusinessRequisiteId={filterBusinessRequisiteId}
                    filterCounterpartRequisiteId={filterCounterpartRequisiteId}
                    filterDocumentType={filterDocumentType}
                    filterSign={filterSign}
                    isFetching={isFetching}
                    mrds={mrds}
                    pageSize={pageSize}
                    setFilters={setFilters}
                    setMRDsPage={setClientMRDsPage}
                    showCashOrderModal={this._showCashOrderModal}
                    stats={stats}
                    updateData={() => fetchClientMRDs({ clientId })}
                />

                <RefactoredCashOrderModal />
                <AddRowModal
                    client={client}
                    clientId={clientId}
                    hideModal={() => {
                        this.setState({ visibleAddRowModal: false });
                    }}
                    labors={labors}
                    updateData={this.getStoreDocProducts}
                    visible={visibleAddRowModal}
                    warehouses={this.props.warehouses}
                />
            </React.Fragment>
        );
    }
}
