import { Input, Table } from 'antd';
import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    fetchVehicles,
    selectExpandedVehicleId,
    selectFetchingVehicles,
    selectSort,
    selectVehicles,
    selectVehiclesStats,
    setExpandedVehicleId,
    setPage,
    setSearchQuery
} from '../../redux/duck';
import VehicleOrdersTable from '../VehicleOrdersTable';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    vehicles: selectVehicles(state),
    stats: selectVehiclesStats(state),
    sort: selectSort(state),
    expandedVehicleId: selectExpandedVehicleId(state),
    fetchingVehicles: selectFetchingVehicles(state)
});

const mapDispatchToProps = {
    fetchVehicles,
    setPage,
    setSearchQuery,
    setExpandedVehicleId
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@withRouter
export default class VehiclesTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRowKeys: [],
            selectedRows: []
        };

        this.handleSearch = _.debounce(value => {
            this.props.setSearchQuery({
                query: _.toLower(value.replace(/[+()]/g, ''))
            });
        }, 1000).bind(this);

        this.urlParams = new URLSearchParams(location.search);
        this.initialSearchQuery = this.urlParams.get('initial_search_query');
        if (this.props.location.state && this.props.location.state.searchValue) {
            this.initialSearchQuery = this.props.location.state.searchValue;
        }
        if (this.initialSearchQuery) {
            this.props.setSearchQuery({ query: this.initialSearchQuery.replace(/[+()]/g, '') });
        }
    }

    componentDidMount() {
        this.props.fetchVehicles();
    }

    componentDidUpdate(prevProps) {}

    onSearch = async e => {
        const { value } = e.target;
        await this.handleSearch(value);
    };

    render() {
        const {
            stats,
            setPage,
            sort,
            expandedVehicleId,
            user,
            vehicles,
            fetchingVehicles,
            setExpandedVehicleId,
            hasFilters,
            intl: { formatMessage }
        } = this.props;
        const { selectedRowKeys, selectedRows } = this.state;

        const pagination = {
            pageSizeOptions: [10, 25, 50],
            pageSize: sort.pageSize,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / sort.pageSize) * sort.pageSize,
            current: sort.page,
            onChange: (page, pageSize) => setPage({ page, pageSize })
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys, selectedRows });
            }
        };

        return (
            <React.Fragment>
                <div className={Styles.filtersCont}>
                    <div className={Styles.inputCont}>
                        <Input.Search
                            allowClear
                            defaultValue={this.initialSearchQuery}
                            onChange={this.onSearch}
                            placeholder={formatMessage({ id: 'vehicles_page.search' })}
                        />
                    </div>
                </div>

                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig({ user, selectedRowKeys, selectedRows })}
                    dataSource={!hasFilters ? vehicles : vehicles.filter((_, ind) => ind % 2 !== 0)}
                    expandable={{
                        expandedRowKeys: [expandedVehicleId],
                        expandedRowRender: () => <VehicleOrdersTable />,
                        onExpand: (expanded, vehicle) => {
                            setExpandedVehicleId({
                                vehicleId: expanded ? vehicle.clientVehicleId : undefined
                            });
                        }
                    }}
                    loading={fetchingVehicles}
                    pagination={pagination}
                    rowKey={vehicle => vehicle.clientVehicleId}
                    rowSelection={rowSelection}
                    size='small'
                    width='100%'
                />
            </React.Fragment>
        );
    }
}
