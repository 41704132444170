import {
    CarFilled,
    EditOutlined,
    MailOutlined,
    MenuOutlined,
    MessageOutlined,
    PhoneOutlined,
    RedoOutlined
} from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { Numeral } from 'commons';
import { OrderStatusIcon, RepairMapIndicator } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { getCurrency, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    index_col: '4%',
    order_col: '15%',

    datetime_col: '10%',
    begin_datetime_col: '10%',
    delivery_datetime_col: '10%',
    success_datetime_col: '10%',
    create_datetime_col: '10%',

    client_col: '22%',
    post_col: '8%',
    sum_col: '8%',
    remaining_sum_col: '8%',

    responsible_col: '10%',
    source_col: '8%',
    tasks_col: '8%',
    review_col: '10%',
    invitation_col: '10%',

    action_col: '8%',
    edit_col: '10%'
};

/* eslint-disable complexity */
export function columnsConfig(
    openOrderDrawer,
    invited,
    action,
    isOrderInvitable,
    isAlreadyInvited,
    activeRoute,
    sort,
    user,
    formatMessage,
    isMobile,
    businessRequisites,
    requisiteId,
    onRequisiteSelect,
    responsibleId,
    onResponsibleSelect,
    managers
) {
    const sortOptions = {
        asc: 'ascend',
        desc: 'descend'
    };

    const indexCol = {
        title: '№',
        dataIndex: 'index',
        key: 'index'
    };

    const orderCol = {
        title: <FormattedMessage id='orders.order' />,
        dataIndex: 'num',
        key: 'num',
        render: (_, order) => (
            <React.Fragment>
                <Link className={Styles.ordernLink} to={`${book.order}/${order.id}`}>
                    {order.num}
                </Link>
                <OrderStatusIcon status={order.status} />

                <Button icon={<MenuOutlined />} onClick={() => openOrderDrawer(order.id)} type='text' />

                {order.serviceNames && (
                    <div className={Styles.serviceNames}>{[...new Set(order.serviceNames)].join(', ')}</div>
                )}
                {order.recommendation && <div className={Styles.recommendation}>{order.recommendation}</div>}
                {(order.cancelReason || order.cancelStatusReason || order.cancelStatusOwnReason) && (
                    <div className={Styles.cancelReason}>
                        {/* <div>{ order.cancelReason }</div> */}
                        <div>{order.cancelStatusReason}</div>
                        <div>{order.cancelStatusOwnReason}</div>
                    </div>
                )}
                <RepairMapIndicator data={order.subStatusIndications} />
            </React.Fragment>
        )
    };

    const datetimeCol = {
        title: !isMobile ? <FormattedMessage id='orders.creation_date' /> : <FormattedMessage id='date' />,
        dataIndex: 'datetime',
        key: 'datetime',
        sorter: !isMobile,
        sortOrder: sort.field === 'datetime' && !isMobile ? sortOptions[sort.order] : false,
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.datetime ? dayjs(order.datetime).format('DD.MM.YYYY HH:mm') : '-'}
            </div>
        )
    };

    const beginDatetimeCol = {
        title: <FormattedMessage id='orders.begin_date' />,
        dataIndex: 'beginDatetime',
        key: 'beginDatetime',
        sortOrder: sort.field === 'beginDatetime' ? sortOptions[sort.order] : false,
        sorter: true,
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.beginDatetime ? dayjs(order.beginDatetime).format('DD.MM.YYYY HH:mm') : '-'}
            </div>
        )
    };

    const deliveryDatetimeCol = {
        title: <FormattedMessage id='orders.delivery_date' />,
        dataIndex: 'deliveryDatetime',
        key: 'deliveryDatetime',
        sortOrder: sort.field === 'deliveryDatetime' ? sortOptions[sort.order] : false,
        sorter: true,
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.deliveryDatetime ? dayjs(order.deliveryDatetime).format('DD.MM.YYYY HH:mm') : '-'}
            </div>
        )
    };

    const successDatetimeCol = {
        title: <FormattedMessage id='orders.success_date' />,
        dataIndex: 'successDatetime',
        key: 'successDatetime',
        sortOrder: sort.field === 'successDatetime' ? sortOptions[sort.order] : false,
        sorter: true,
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.successDatetime ? dayjs(order.successDatetime).format('DD.MM.YYYY HH:mm') : '-'}
            </div>
        )
    };

    const createDatetimeCol = {
        title: <FormattedMessage id='orders.creation_date' />,
        dataIndex: 'datetime',
        key: 'datetime',
        sorter: true,
        sortOrder: sort.field === 'datetime' ? sortOptions[sort.order] : false,
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.datetime ? dayjs(order.datetime).format('DD.MM.YYYY HH:mm') : '-'}
            </div>
        )
    };

    const clientCol = {
        title: <FormattedMessage id='orders.client' />,
        dataIndex: 'clientFullName',
        key: 'clientFullName',
        render: (_, order) => (
            <div className={Styles.client}>
                <Link className={Styles.clientFullname} to={`${book.client}/${order.clientId}`}>
                    {`${order.clientSurname || ''} ${order.clientName || '-'}`}
                </Link>
                {order.vehicleId ? (
                    <span className={Styles.clientVehicle}>
                        {`${order.vehicleMakeName || '-'} ${order.vehicleModelName || order.vehicleYear || '-'} ${
                            order.vehicleNumber || ''
                        }`}
                        <CarFilled
                            style={{
                                display: order.colorCode === '' || order.colorCode === null ? 'none' : undefined,
                                color: order.colorCode,
                                backgroundColor: '#e3e3e3',
                                fontSize: 16,
                                borderRadius: 16,
                                padding: 3,
                                marginLeft: 4
                            }}
                        />
                    </span>
                ) : (
                    <span className={Styles.clientVehicle}>
                        {` ${order.aggregateNumber || '-'} ${order.aggregateName || '-'} ${
                            order.aggregateComment || '-'
                        }`}
                    </span>
                )}
                <a className={Styles.clientPhone} href={`tel:${order.clientPhone}`}>
                    {order.clientPhone || '-'}
                </a>
            </div>
        )
    };

    const postCol = {
        title: <FormattedMessage id='orders.post' />,
        width: defWidth.post_col,
        dataIndex: 'stationNum',
        key: 'stationNum',
        sorter: true,
        sortOrder: sort.field === 'stationNum' ? sortOptions[sort.order] : false,
        render: (_, order) => order.stationName
    };

    const orderAndClientCol = {
        title: (
            <div>
                <p>
                    <FormattedMessage id='orders.order' />
                </p>
                <p>
                    <FormattedMessage id='orders.client' />
                </p>
            </div>
        ),
        width: 'auto',
        dataIndex: 'num',
        key: 'orderAndClient',
        // fixed:     'left',
        render: (_, order) => (
            <React.Fragment>
                <Link className={Styles.ordernLink} to={`${book.order}/${order.id}`}>
                    {order.num}
                </Link>
                <OrderStatusIcon status={order.status} />
                <div className={Styles.client}>
                    <span className={Styles.clientFullname}>
                        {`${order.clientSurname || ''} ${order.clientName || '-'}`}
                    </span>
                    {order.vehicleId ? (
                        <span className={Styles.clientVehicle}>
                            {`${order.vehicleMakeName || '-'} ${order.vehicleModelName || order.vehicleYear || '-'}`}
                        </span>
                    ) : order.aggregateId ? (
                        <span className={Styles.clientVehicle}>
                            {`${order.aggregateNumber || '-'} ${order.aggregateComment || '-'}`}
                        </span>
                    ) : undefined}
                    <a className={Styles.clientPhone} href={`tel:${order.clientPhone}`}>
                        {order.clientPhone || '-'}
                    </a>
                </div>
            </React.Fragment>
        )
    };

    const sumCol = {
        title: !isMobile ? <FormattedMessage id='orders.sum_without_VAT' /> : <FormattedMessage id='orders.sum' />,
        dataIndex: 'totalSum',
        key: 'totalSum',
        /* sorter:    !isMobile, */
        /* sortOrder: sort.field === 'totalSum' && !isMobile ? sortOptions[ sort.order ] : false, */
        render: totalSum => (
            <div style={{ whiteSpace: 'nowrap' }}>
                <Numeral
                    // TODO
                    className={Styles.sumRow}
                    currency={getCurrency()}
                    mask='0,0.00'
                    nullText='0'
                >
                    {totalSum}
                </Numeral>
            </div>
        )
    };

    const sumWithTaxCol = {
        title: <FormattedMessage id='sum_tax' />,
        dataIndex: 'totalSumWithTax',
        key: 'totalSumWithTax',
        /* sorter:    !isMobile, */
        /* sortOrder: sort.field === 'totalSum' && !isMobile ? sortOptions[ sort.order ] : false, */
        render: totalSumWithTax => (
            <div style={{ whiteSpace: 'nowrap' }}>
                <Numeral
                    // TODO
                    className={Styles.sumRow}
                    currency={getCurrency()}
                    mask='0,0.00'
                    nullText='0'
                >
                    {totalSumWithTax}
                </Numeral>
            </div>
        )
    };

    const remainingSumCol = {
        title: <FormattedMessage id='orders.remaining_sum' />,
        key: 'remainingSum',
        render: ({ remainingSum, tax }) => (
            <Numeral currency={getCurrency()} nullText='0'>
                {Math.round(remainingSum * 100) / 100 + Math.round(tax * 100) / 100}
            </Numeral>
        )
    };

    const responsibleCol = {
        title: <FormattedMessage id='orders.responsible' />,
        dataIndex: 'managerName',
        key: 'managerName',
        filtered: responsibleId,
        filterDropdown: () => (
            <div
                onKeyDown={e => e.stopPropagation()}
                style={{
                    padding: 8
                }}
            >
                <Select
                    allowClear
                    dropdownStyle={{ zIndex: '9999' }}
                    onChange={onResponsibleSelect}
                    optionFilterProp='children'
                    placeholder={<FormattedMessage id='orders.responsible' />}
                    showSearch
                    style={{ width: 280 }}
                    value={responsibleId}
                >
                    {managers.map(elem => (
                        <Select.Option key={elem.id} value={elem.id}>
                            {elem.managerSurname} {elem.managerName}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        ),
        render: (_, order) => {
            if (order.managerName) {
                return (
                    <div>
                        {order.managerSurname} {order.managerName}
                    </div>
                );
            }

            return (
                <div>
                    <FormattedMessage id='orders.not_assigned' />
                </div>
            );
        }
    };

    const sourceCol = {
        title: <FormattedMessage id='orders.source' />,
        dataIndex: 'changeReason',
        key: 'changeReason',
        render: (_, order) =>
            order.changeReason ? (
                <FormattedMessage id={`${order.changeReason}`} />
            ) : (
                <FormattedMessage id='orders.not_provided' />
            )
    };

    const tasksCol = {
        title: <FormattedMessage id='orders.tasks' />,
        dataIndex: 'activeTasks',
        key: 'activeTasks',
        render: (_, order) => {
            if (order.activeTasks) {
                return <Link to={`${book.order}/${order.id}`}>{order.activeTasks}</Link>;
            }

            return <FormattedMessage id='orders.no_tasks' />;
        }
    };

    const reviewCol = {
        title: <FormattedMessage id='orders.review' />,
        dataIndex: 'review',
        key: 'review',
        render: (data, order) => {
            if (_.isNumber(order.nps)) {
                return (
                    <a href={`${book.feedback}/${order.reviewIds[0]}`}>
                        <div
                            className={classNames(Styles.nps, {
                                [Styles.npsMid]: order.nps === 7 || order.nps === 8,
                                [Styles.npsLow]: order.nps <= 6
                            })}
                        >
                            {Math.round(order.nps)}
                        </div>
                    </a>
                );
            }

            return (
                <Button>
                    <FormattedMessage id='orders.add_feedback' />
                </Button>
            );
        }
    };

    const isInviteButtonDisabled = order => {
        const missingRequiredField = !isOrderInvitable(order);
        const alreadyInvited = isAlreadyInvited(order);
        const forbidden =
            isForbidden(user, permissions.CREATE_ORDER) || isForbidden(user, permissions.CREATE_INVITE_ORDER);

        return missingRequiredField || alreadyInvited || forbidden;
    };

    const invitationCol = {
        title: <FormattedMessage id='orders.invitation' />,
        dataIndex: 'invite',
        key: 'invite',
        render: (_void, order) => {
            if (!order.vehicleInviteExists) {
                return (
                    <Button disabled={isInviteButtonDisabled(order)} onClick={() => action([order])} type='primary'>
                        <FormattedMessage id='orders.invite' />
                    </Button>
                );
            }

            return (
                <Link className={Styles.inviteLink} to={`${book.order}/${order.vehicleInviteExists}`}>
                    {order.vehicleInviteExists}
                </Link>
            );
        }
    };

    const actionCol = {
        title: <FormattedMessage id='orders.actions' />,
        dataIndex: 'actions',
        key: 'actions',
        render: () => (
            <div className={Styles.inviteActions}>
                <Tooltip placement='bottom' title={<FormattedMessage id='orders.send_sms' />}>
                    <MessageOutlined style={{ fontSize: 24, color: '#08c' }} />
                </Tooltip>
                <Tooltip placement='bottom' title={<FormattedMessage id='orders.call' />}>
                    <PhoneOutlined style={{ fontSize: 24, color: '#08c' }} />
                </Tooltip>
                <Tooltip placement='bottom' title={<FormattedMessage id='orders.send_email' />}>
                    <MailOutlined style={{ fontSize: 24, color: '#08c' }} />
                </Tooltip>
            </div>
        )
    };

    const editCol = {
        title: (
            <Tooltip placement='right' title={<FormattedMessage id='orders.update_stage' />}>
                <Button
                    onClick={() => {
                        const token = localStorage.getItem('_my.carbook.pro_token');
                        const url = `${__API_URL__}/orders/repair_map?update=true`;
                        fetch(url, {
                            method: 'PUT',
                            headers: {
                                Authorization: token
                            }
                        })
                            .then(function (response) {
                                if (response.status !== 200) {
                                    return Promise.reject(new Error(response.statusText));
                                }

                                return Promise.resolve(response);
                            })
                            .then(function (response) {
                                return response.json();
                            })
                            .then(function (data) {
                                window.location.reload();
                            })
                            .catch(function (error) {
                                console.log('error', error);
                            });
                    }}
                    style={{
                        padding: '0px 8px',
                        fontSize: 18
                    }}
                >
                    <RedoOutlined />
                </Button>
            </Tooltip>
        ),
        key: 'editAction',
        render: (_, order) => (
            <Link to={`${book.order}/${order.id}`}>
                <Tooltip placement='right' title={<FormattedMessage id='orders.editAction' />}>
                    <Button
                        style={{
                            padding: '0px 8px',
                            fontSize: 18
                        }}
                    >
                        <EditOutlined />
                    </Button>
                </Tooltip>
            </Link>
        )
    };

    const requisiteCol = {
        title: <FormattedMessage id='navigation.requisites' />,
        dataIndex: 'requisiteName',
        key: 'requisiteName',
        filtered: requisiteId,
        filterDropdown: () => (
            <div
                onKeyDown={e => e.stopPropagation()}
                style={{
                    padding: 8
                }}
            >
                <Select
                    allowClear
                    dropdownStyle={{ zIndex: '9999' }}
                    onChange={onRequisiteSelect}
                    optionFilterProp='children'
                    placeholder={<FormattedMessage id='storage.business_requisites' />}
                    showSearch
                    style={{ width: 280 }}
                    value={requisiteId}
                >
                    {businessRequisites.map(elem => (
                        <Select.Option key={elem.id} value={elem.id}>
                            {elem.name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        )
    };

    if (isMobile) {
        return [datetimeCol, orderAndClientCol, sumCol];
    }

    switch (activeRoute) {
        case '/orders/appointments':
            return [
                indexCol,
                orderCol,
                datetimeCol,
                beginDatetimeCol,
                deliveryDatetimeCol,
                successDatetimeCol,
                clientCol,
                postCol,
                sumCol,
                responsibleCol,
                sourceCol,
                requisiteCol
            ];

        case '/orders/approve':
            return [
                indexCol,
                orderCol,
                datetimeCol,
                beginDatetimeCol,
                deliveryDatetimeCol,
                successDatetimeCol,
                clientCol,
                sumCol,
                responsibleCol,
                sourceCol,
                requisiteCol
            ];
        case '/orders/progress':
            return [
                indexCol,
                orderCol,
                datetimeCol,
                beginDatetimeCol,
                deliveryDatetimeCol,
                successDatetimeCol,
                clientCol,
                sumCol,
                sumWithTaxCol,
                responsibleCol,
                sourceCol,
                requisiteCol
            ];

        case '/orders/success':
            return [
                indexCol,
                orderCol,
                datetimeCol,
                beginDatetimeCol,
                deliveryDatetimeCol,
                successDatetimeCol,
                clientCol,
                sumCol,
                sumWithTaxCol,
                remainingSumCol,
                responsibleCol,
                sourceCol,
                // reviewCol,
                // invitationCol,
                requisiteCol
            ];

        case '/orders/cancel':
            return [
                indexCol,
                orderCol,
                datetimeCol,
                beginDatetimeCol,
                deliveryDatetimeCol,
                successDatetimeCol,
                clientCol,
                sumCol,
                responsibleCol,
                sourceCol,
                invitationCol,
                requisiteCol
            ];

        case '/orders/reviews':
            return [
                indexCol,
                orderCol,
                reviewCol,
                datetimeCol,
                beginDatetimeCol,
                deliveryDatetimeCol,
                successDatetimeCol,
                clientCol,
                sumCol,
                responsibleCol,
                sourceCol,
                requisiteCol
            ];

        case '/orders/invitations':
            return [
                indexCol,
                orderCol,
                datetimeCol,
                beginDatetimeCol,
                deliveryDatetimeCol,
                successDatetimeCol,
                clientCol,
                actionCol,
                responsibleCol,
                requisiteCol
            ];

        default:
            return [
                indexCol,
                orderCol,
                datetimeCol,
                beginDatetimeCol,
                deliveryDatetimeCol,
                successDatetimeCol,
                clientCol,
                sumCol,
                responsibleCol,
                sourceCol,
                requisiteCol
            ];
    }
}

export function rowsConfig(activeRoute, selectedRowKeys, onChange, getCheckboxProps) {
    if (activeRoute === '/orders/success' || activeRoute === '/orders/cancel') {
        return {
            selectedRowKeys,
            onChange,
            getCheckboxProps
        };
    }

    return null;
}

export function scrollConfig(activeRoute) {
    switch (activeRoute) {
        case '/orders/appointments':
            return { x: 1500, y: '70vh' }; // 1600 - 80 -
        case '/orders/approve':
            return { x: 1340, y: '70vh' };
        case '/orders/progress':
            return { x: 1340, y: '70vh' }; // 1440 - 80 - 20
        case '/orders/success':
            return { x: 1860, y: '70vh' }; // 1820
        case '/orders/reviews':
            return { x: 1520, y: '70vh' }; // 1620
        case '/orders/invitations':
            return { x: 1260, y: '70vh' }; // 1400
        case 'orders/cancel':
            return { x: 1400, y: '70vh' }; // 1640 // -160 second date
        default:
            return { x: 1540, y: '70vh' }; // 1640
    }
}
