import { DatePicker, InputNumber, Modal, Select, notification } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const AddSuggestionsGroupModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        edit,
        fetchBillingGroups,
        fetchGroupsSuggestions,
        billingGroups,
        businessId
    } = props;

    const [packageName, setPackageName] = useState(undefined);
    const [billingGroupId, setBillingGroupId] = useState(undefined);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [payment, setPayment] = useState(0);
    const [suggestionsStatus, setStatus] = useState('not_paid');

    useEffect(() => {
        if (edit) {
            setBillingGroupId(edit.group_id);
            setStartDate(dayjs(edit.begin_date));
            setEndDate(dayjs(edit.end_date));
            setPayment(edit.sum);
            setStatus(edit.status);
        }
    }, [edit]);

    const addSuggestionsGroup = async () => {
        try {
            await fetchAPI(
                'POST',
                '/billing/suggestion_groups_businesses_ref',
                undefined,
                {
                    group_id: billingGroupId,
                    begin_date: startDate ? dayjs(startDate) : undefined,
                    end_date: endDate ? dayjs(endDate) : undefined,
                    sum: payment || 0,
                    status: suggestionsStatus,
                    business_id: businessId
                },
                { handleErrorInternally: true }
            );
            await fetchGroupsSuggestions();
            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const editAdditionalPackage = async () => {
        try {
            if (edit) {
                await fetchAPI(
                    'PUT',
                    `/billing/suggestion_groups_businesses_ref/${edit.id}`,
                    undefined,
                    {
                        group_id: billingGroupId,
                        begin_date: startDate ? dayjs(startDate) : undefined,
                        end_date: endDate ? dayjs(endDate) : undefined,
                        sum: payment || 0,
                        status: suggestionsStatus
                    },
                    { handleErrorInternally: true }
                );

                notification.success({
                    message: formatMessage({ id: 'message.success' })
                });

                await fetchGroupsSuggestions();
            }
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const onClose = () => {
        setBillingGroupId(undefined);
        setStartDate(dayjs());
        setEndDate(dayjs());
        setPayment(undefined);
        setStatus(undefined);
        hideModal();
    };

    useEffect(() => {
        if (open && !get(billingGroups, 'length')) {
            fetchBillingGroups();
        }
    }, [open, fetchBillingGroups, billingGroups]);

    const onFinish = async () => {
        if (!edit) {
            addSuggestionsGroup();
        } else {
            editAdditionalPackage();
        }

        await onClose();
    };

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: !billingGroupId || !startDate || !suggestionsStatus
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={
                !edit ? (
                    <FormattedMessage id='monetization.create_group' />
                ) : (
                    <FormattedMessage id='monetization.edit_group' />
                )
            }
            width='30%'
        >
            <div>
                <div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.issue_group' /> <span className={Styles.red}>*</span>
                        </div>
                        <Select
                            onSelect={value => setBillingGroupId(value)}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'monetization.issue_group'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={billingGroupId}
                        >
                            {billingGroups
                                ? billingGroups.map(({ id, name }) => (
                                      <Option key={id} value={id}>
                                          {name}
                                      </Option>
                                  ))
                                : undefined}
                        </Select>
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.start_date' /> <span className={Styles.red}>*</span>
                        </div>
                        <DatePicker
                            allowClear={false}
                            className={Styles.datePicker}
                            format='DD-MM-YYYY'
                            onChange={value => setStartDate(dayjs(value))}
                            placeholder={formatMessage({
                                id: 'select_date'
                            })}
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={startDate}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.end_date' />
                        </div>
                        <DatePicker
                            allowClear={false}
                            className={Styles.datePicker}
                            format='DD-MM-YYYY'
                            onChange={value => setEndDate(dayjs(value))}
                            placeholder={formatMessage({
                                id: 'select_date'
                            })}
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={endDate}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.payment' />
                        </div>
                        <InputNumber
                            min={0}
                            onChange={value => setPayment(value)}
                            placeholder={formatMessage({
                                id: 'monetization.payment'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={payment}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.status' /> <span className={Styles.red}>*</span>
                        </div>
                        <Select
                            onSelect={value => setStatus(value)}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'monetization.status'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={suggestionsStatus}
                        >
                            <Option key='paid' value='paid'>
                                <FormattedMessage id='monetization.status.paid' />
                            </Option>
                            <Option key='not_paid' value='not_paid'>
                                <FormattedMessage id='monetization.status.not_paid' />
                            </Option>
                        </Select>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(AddSuggestionsGroupModal);
