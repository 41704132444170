import { Select, Tooltip } from 'antd';
import { CASH_ORDER_TYPES, MODES } from 'modals/RefactoredCashOrderModal/constants';
import { selectCashboxes } from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import {
    selectCashOrderType,
    selectMode,
    selectPaymentSystem,
    selectPaymentType,
    selectRequisiteId,
    selectSelectedCashbox,
    selectSelectedCashboxId,
    setPaymentSystem
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const { INCOME, EXPENSE, ADJUSTMENT_INCOME } = CASH_ORDER_TYPES;

const mapStateToProps = state => ({
    cashOrderType: selectCashOrderType(state),
    selectedCashbox: selectSelectedCashbox(state),
    selectRequisiteId: selectRequisiteId(state),
    selectPaymentType: selectPaymentType(state),
    selectPaymentSystem: selectPaymentSystem(state),
    mode: selectMode(state),
    cashboxes: selectCashboxes(state),
    selectedCashboxId: selectSelectedCashboxId(state)
});

const mapDispatchToProps = {
    setPaymentSystem
};

/**
 * This component serves selecting cash order type
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class PaymentSystemSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {}

    render() {
        const {
            cashOrderType,
            setRequisites,
            selectRequisiteId,
            mode,
            editable,
            cashboxes,
            selectedCashboxId,
            selectPaymentSystem,
            setPaymentSystem,
            intl: { formatMessage }
        } = this.props;

        return (
            <Tooltip placement='top' title={<FormattedMessage id='card_type' />} zIndex={2001}>
                <Select
                    allowClear
                    disabled={mode != MODES.ADD}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={value => {
                        if (!value) {
                            setPaymentSystem(null);
                        } else {
                            setPaymentSystem(value);
                        }
                    }}
                    placeholder={formatMessage({
                        id: 'card_type'
                    })}
                    value={selectPaymentSystem}
                >
                    {['VISA', 'MASTERCARD'].map(item => (
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Tooltip>
        );
    }
}
