import {
    AuditOutlined,
    CloseOutlined,
    FolderAddOutlined,
    InfoOutlined,
    MenuOutlined,
    PrinterOutlined,
    SaveOutlined,
    SnippetsOutlined,
    SwapOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Form, Modal, notification, Space, Tabs, Tooltip } from 'antd';
import { Layout } from 'commons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { AccountingForm } from 'forms';
import _ from 'lodash';
import { AddClientModal, CreateAccTemplateModal } from 'modals';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { LogsIcon } from 'theme';
import { fetchAPI, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import AccountingRowsTable from './components/AccountingRowsTable';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    user: state.auth,
    modal: state.modals.modal,
    businessRequisites: selectBusinessRequisites(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setModal,
    resetModal,
    fetchBusinessRequisites
};

const AccPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ({
        intl: { formatMessage },
        setModal,
        resetModal,
        modal,
        user,
        businessRequisites,
        fetchBusinessRequisites,
        isMobile
    }) => {
        const { id } = useParams();
        const [form] = Form.useForm();
        const [docData, setDocData] = useState({
            counterpartType: 'CLIENT'
        });
        const [previousData, setPreviousData] = useState({});
        const [addClientVisible, setAddClientVisible] = useState(false);
        const [tableData, setTableData] = useState([]);
        const [employees, setEmployees] = useState([]);
        const [clients, setClients] = useState([]);
        const [sources, setSources] = useState([]);
        const [types, setTypes] = useState([]);
        const [categories, setCategories] = useState([]);
        const [suppliers, setSuppliers] = useState([]);
        const [statuses, setStatuses] = useState([]);
        const [cashboxes, setCashboxes] = useState([]);
        const [warehouses, setWarehouses] = useState([]);
        const [openTemplate, setOpenTemplate] = useState(false);
        const [templateName, setTemplateName] = useState();
        const [activeTab, setActiveTab] = useState();

        const openAddClient = () => {
            setModal(MODALS.ADD_CLIENT, { initialPhoneNumber: docData.counterpartySearch });
        };
        const onClientAdded = async value => {
            const { clients } = await fetchAPI('GET', '/clients', null, null, {
                handleErrorInternally: true
            });

            setClients(clients);

            const client = clients.find(({ clientId }) => clientId == value) || {};
            form.setFieldsValue({
                counterpartyId: value,
                sourceId: client.sourceId
            });
            setDocData({
                ...docData,
                client: `${client.name} ${client.surname || ''} ${_.get(client.phones, '[0]', ' ')}`,
                counterpartyId: value,
                sourceId: client.sourceId
            });
        };

        const fetchDocData = useCallback(async () => {
            if (id) {
                const response = await fetchAPI('GET', '/general_ledger/acc_document_rows/', { id }, null, {
                    handleErrorInternally: true
                });

                setDocData(response[0]);
                setPreviousData(response[0]);
                setTableData(response[0].rows);
            }
        }, [id]);

        const fetchEmployees = useCallback(async query => {
            const responseEmps = await fetchAPI('GET', 'employees', { query }, null, {
                handleErrorInternally: true
            });

            setEmployees(responseEmps);

            return responseEmps
                .filter(({ disabled }) => !disabled)
                .map(({ id, name, surname }) => ({ label: `${name} ${surname}`, value: id }));
        }, []);

        const fetchClients = useCallback(async query => {
            const responseClients = await fetchAPI('GET', '/clients', { filters: { query } }, null, {
                handleErrorInternally: true
            });

            setClients(responseClients.clients);

            return responseClients.clients.map(({ clientId, name, surname, phones }) => ({
                label: `${name} ${surname || ''} ${_.get(phones, '[0]', ' ')}`,
                value: clientId
            }));
        }, []);

        const fetchSuppliers = useCallback(async query => {
            const responseSuppliers = await fetchAPI('GET', '/business_suppliers_dept', { filters: { query } }, null, {
                handleErrorInternally: true
            });

            setSuppliers(responseSuppliers.businessSuppliers);

            return responseSuppliers.businessSuppliers.map(({ businesssupplierid, businessSupplierName }) => ({
                label: businessSupplierName,
                value: businesssupplierid
            }));
        }, []);

        const handleSearchCounterparty = useMemo(() => {
            const loadOptions = (type, query) => {
                if (type === 'SUPPLIERS') {
                    fetchSuppliers(query);
                }
                if (type === 'CLIENTS') {
                    fetchClients(query);
                }
                if (type === 'EMPLOYEES') {
                    fetchEmployees(query);
                }
            };

            return _.debounce(loadOptions, 1000);
        }, [fetchClients, fetchEmployees, fetchSuppliers]);

        const fetchCashboxes = useCallback(async query => {
            const response = await fetchAPI(
                'GET',
                'cash_boxes',
                {
                    query,
                    disabled: isGrantAccessed(user, grants.SETTINGS_CASH_REGISTERS_HIDDEN_CASH_BOX, accesses.ROWO)
                        ? true
                        : undefined
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setCashboxes(response);
        }, []);

        const fetchWarehouses = useCallback(async query => {
            const response = await fetchAPI(
                'GET',
                'warehouses',
                {
                    query,
                    disabled: isGrantAccessed(user, grants.SETTINGS_STORES_HIDDEN_WAREHOUSE, accesses.ROWO)
                        ? true
                        : undefined
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setWarehouses(response);
        }, []);

        const debouncedFilter = (query, type) => {
            const funcMap = {
                CLIENTS: fetchClients,
                EMPLOYEES: fetchEmployees,
                SUPPLIERS: fetchSuppliers
            };

            return funcMap[type](query);
        };

        const handleSave = useCallback(
            async st => {
                const {
                    counterpartId,
                    comment,
                    documentDatetime,
                    status,
                    responsibleId,
                    rows,
                    documentNumber,
                    counterpartType,
                    warehouseId,
                    cashBoxId,
                    operationType,
                    businessRequisiteId
                } = docData;

                const submitValues = {
                    id: Number(id),
                    status: st || status,
                    counterpartId,
                    counterpartType,
                    comment: comment || ' ',
                    documentDatetime: documentDatetime ? dayjs(documentDatetime).format('YYYY-MM-DD') : undefined,
                    responsibleId,
                    documentNumber: String(documentNumber),
                    warehouseId: warehouseId || null,
                    cashBoxId: cashBoxId || null,
                    operationType,
                    businessRequisiteId: businessRequisiteId || null,
                    rows: rows
                        ? rows.map(el =>
                              _.omit(el, [
                                  'managerId',
                                  'managerName',
                                  'businessId',
                                  'accountName',
                                  'accDocumentId',
                                  'counterpartName',
                                  'operationId',
                                  'businessRequisiteName'
                              ]))
                        : [] || []
                };
                const validation = await form.validateFields();
                if (!validation.errorFields) {
                    try {
                        await fetchAPI('PUT', '/general_ledger/acc_document', null, submitValues, {
                            handleErrorInternally: true
                        });
                        notification.success({ message: formatMessage({ id: 'message.success' }) });

                        window.location.reload();
                    } catch (e) {
                        notification.error({ message: formatMessage({ id: 'error' }) });
                    }
                }
            },
            [docData, form, formatMessage, id]
        );

        const changeStatus = status => {
            handleSave(status);
        };

        const [showControls, setShowControls] = useState(false);

        const toggleControls = () => setShowControls(!showControls);

        useEffect(() => {
            fetchDocData();
            fetchBusinessRequisites();
            // fetchEmployees();
            // fetchClients();
            // fetchSuppliers();
            fetchCashboxes();
            fetchWarehouses();
            setActiveTab(isMobile ? 'info' : 'rows');
        }, [id, isMobile]);

        // useEffect(() => {
        //     if(docData.counterpartId) {

        //     handleSearchCounterparty(docData.counterpartType, )
        //     }
        // }, [id]);

        const items = [
            {
                key: 'NEW',
                disabled: _.get(docData, 'status') === 'NEW',
                label: (
                    <div
                        onClick={() => {
                            if (_.get(docData, 'status') !== 'NEW') {
                                changeStatus('NEW');
                            }
                        }}
                    >
                        <FormattedMessage id='employee_salary_rules.status.NEW' />
                    </div>
                )
            },
            {
                key: 'DONE',
                disabled:
                    !_.get(docData.rows, 'length') ||
                    _.get(docData, 'status') === 'CANCEL' ||
                    _.get(docData, 'status') === 'DONE',
                label: (
                    <div
                        onClick={() => {
                            if (
                                _.get(docData.rows, 'length') &&
                                _.get(docData, 'status') !== 'CANCEL' &&
                                _.get(docData, 'status') !== 'DONE'
                            ) {
                                changeStatus('DONE');
                            }
                        }}
                    >
                        <FormattedMessage id='employee_salary_rules.status.DONE' />
                    </div>
                )
            },

            {
                key: 'CANCEL',
                disabled: _.get(docData, 'status') === 'DONE' || _.get(docData, 'status') === 'CANCEL',
                label: (
                    <div
                        onClick={() => {
                            if (_.get(docData, 'status') !== 'DONE' && _.get(docData, 'status') !== 'CANCEL') {
                                changeStatus('CANCEL');
                            }
                        }}
                    >
                        <FormattedMessage id='employee_salary_rules.status.CANCEL' />
                    </div>
                )
            }
        ];

        return (
            <Layout
                className='addInteractionsPage'
                controls={
                    <Space size='small'>
                        {isMobile && (
                            <Button
                                className={Styles.fixedMenuButton}
                                icon={<MenuOutlined style={{ fontSize: 20 }} />}
                                onClick={() => toggleControls()}
                                size='large'
                                type='text'
                            />
                        )}
                        <div
                            className={
                                isMobile && !showControls
                                    ? Styles.blockStyleMobileNone
                                    : isMobile
                                    ? Styles.blockStyleMobile
                                    : Styles.blockStyle
                            }
                        >
                            {isGrantAccessed(
                                user,
                                grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT_ACC_UNPOSTING
                            ) && (
                                <div className={Styles.headerActionBtns}>
                                    <Dropdown menu={{ items }}>
                                        <Button
                                            icon={
                                                <SwapOutlined
                                                    style={{
                                                        fontSize: !isMobile ? 24 : 20
                                                    }}
                                                />
                                            }
                                            type='text'
                                        >
                                            {!isMobile && (
                                                <span style={{ verticalAlign: 'text-bottom' }}>
                                                    <FormattedMessage id='change_status_dropdown.change_status' />
                                                </span>
                                            )}
                                        </Button>
                                    </Dropdown>
                                </div>
                            )}
                            <div className={Styles.headerActionBtns}>
                                <Tooltip title={<FormattedMessage id='complexes.create_template' />}>
                                    <Button
                                        icon={
                                            <FolderAddOutlined
                                                style={{
                                                    fontSize: !isMobile ? 24 : 20
                                                }}
                                            />
                                        }
                                        onClick={() => {
                                            setOpenTemplate(true);
                                        }}
                                        type='text'
                                    />
                                </Tooltip>
                            </div>
                            <div className={Styles.headerActionBtns}>
                                <Button
                                    disabled
                                    icon={
                                        <PrinterOutlined
                                            style={{
                                                fontSize: !isMobile ? 24 : 20
                                            }}
                                        />
                                    }
                                    onClick={() => {}}
                                    type='text'
                                />
                            </div>
                            {isGrantAccessed(
                                user,
                                grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT_ACC_UNPOSTING
                            ) && (
                                <div className={Styles.headerActionBtns}>
                                    <Tooltip title={<FormattedMessage id='navigation.entries' />}>
                                        <Link
                                            to={{
                                                pathname: book.journalEntries,
                                                state: {
                                                    operationId: String(docData.operationId)
                                                }
                                            }}
                                        >
                                            <Button
                                                icon={
                                                    <SnippetsOutlined
                                                        style={{
                                                            fontSize: !isMobile ? 24 : 20
                                                        }}
                                                    />
                                                }
                                                type='text'
                                            />
                                        </Link>
                                    </Tooltip>
                                </div>
                            )}
                            <div className={Styles.headerActionBtns}>
                                <Button
                                    icon={
                                        <SaveOutlined
                                            style={{
                                                fontSize: !isMobile ? 24 : 20
                                            }}
                                        />
                                    }
                                    onClick={() => {
                                        if (previousData.counterpartId !== docData.counterpartId) {
                                            Modal.confirm({
                                                title: formatMessage({
                                                    id: 'acc_page.acc_doc_save_notific'
                                                }),
                                                onOk() {
                                                    handleSave();
                                                },
                                                okType: 'default',
                                                onCancel() {}
                                            });
                                        } else {
                                            handleSave();
                                        }
                                    }}
                                    type='text'
                                />
                            </div>
                            <div className={Styles.headerActionBtns}>
                                <Button
                                    icon={
                                        <CloseOutlined
                                            style={{
                                                fontSize: !isMobile ? 24 : 20
                                            }}
                                        />
                                    }
                                    onClick={async () => {
                                        goTo(book.accDocs);
                                    }}
                                    type='text'
                                />
                            </div>
                        </div>
                    </Space>
                }
                title={
                    <div className={isMobile ? Styles.titleHeader : {}}>
                        <FormattedMessage id={`employee_salary_rules.status.${docData.status}`} />{' '}
                        <FormattedMessage id='acc_page.acc_doc' /> {isMobile ? <br /> : null}
                        {docData.accNum}
                    </div>
                }
            >
                {!isMobile && (
                    <AccountingForm
                        businessRequisites={businessRequisites}
                        cashboxes={cashboxes}
                        categories={categories}
                        clients={clients}
                        debouncedFilter={debouncedFilter}
                        docData={docData}
                        employees={employees}
                        fetchClients={fetchClients}
                        fetchEmployees={fetchEmployees}
                        fetchSuppliers={fetchSuppliers}
                        form={form}
                        handleSearchCounterparty={handleSearchCounterparty}
                        id={id}
                        openAddClient={openAddClient}
                        previousData={previousData}
                        setData={setDocData}
                        sources={sources}
                        suppliers={suppliers}
                        types={types}
                        warehouses={warehouses}
                    />
                )}

                <Tabs
                    activeKey={activeTab}
                    className={Styles.orderFormsTabs}
                    onChange={setActiveTab}
                    size='small'
                    type='card'
                >
                    {isMobile && (
                        <TabPane
                            key='info'
                            tab={
                                <div className={Styles.tabStyle}>
                                    <InfoOutlined className={Styles.iconsStyle} />
                                    <FormattedMessage id='add_order_form.general' />
                                </div>
                            }
                        >
                            <AccountingForm
                                businessRequisites={businessRequisites}
                                cashboxes={cashboxes}
                                categories={categories}
                                clients={clients}
                                debouncedFilter={debouncedFilter}
                                docData={docData}
                                employees={employees}
                                fetchClients={fetchClients}
                                fetchEmployees={fetchEmployees}
                                fetchSuppliers={fetchSuppliers}
                                form={form}
                                handleSearchCounterparty={handleSearchCounterparty}
                                id={id}
                                openAddClient={openAddClient}
                                previousData={previousData}
                                setData={setDocData}
                                sources={sources}
                                suppliers={suppliers}
                                types={types}
                                warehouses={warehouses}
                            />
                        </TabPane>
                    )}
                    {id && (
                        <TabPane
                            key='rows'
                            tab={
                                <div className={Styles.tabStyle}>
                                    <AuditOutlined className={Styles.iconsStyle} />
                                    <FormattedMessage id='accounts_tab.tab' />
                                </div>
                            }
                        >
                            <AccountingRowsTable
                                disabled={docData.status === 'DONE'}
                                docData={docData}
                                fetchDocData={fetchDocData}
                                id={id}
                                tableData={tableData}
                            />
                        </TabPane>
                    )}
                    {id && (
                        <TabPane
                            key='logs'
                            disabled
                            tab={
                                <div className={Styles.tabStyle}>
                                    <LogsIcon className={Styles.iconsStyle} />
                                    <FormattedMessage id='storage_logs' />
                                </div>
                            }
                        ></TabPane>
                    )}
                </Tabs>
                <AddClientModal func={onClientAdded} resetModal={resetModal} visible={modal} />
                <CreateAccTemplateModal
                    onClose={() => {
                        setOpenTemplate(false);
                    }}
                    open={openTemplate}
                    rowId={id}
                />
            </Layout>
        );
    }
);

export default injectIntl(AccPage);
