import { Checkbox, InputNumber, Modal, notification, Radio } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PriceTagIcon } from 'theme';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

@injectIntl
export default class PriceRecalculationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    handleOk = async () => {
        const { ordersAppurtenanciesIds, updateDataSource } = this.props;
        const { purchase, sell, minMargin, minMarkup } = this.state;
        await fetchAPI(
            'PUT',
            'orders/details/prices',
            undefined,
            {
                ordersAppurtenanciesIds: [...ordersAppurtenanciesIds],
                purchase,
                sell,
                minMargin,
                minMarkup
            },
            { handleErrorInternally: true }
        );
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'message.success' })
        });
        this.handleCancel();
        updateDataSource();
    };

    handleCancel = () => {
        const { hideModal } = this.props;
        if (hideModal) hideModal();
        this.setState({
            visible: false,
            isPurchase: false,
            isSell: false,
            markup: false
        });
    };

    componentDidUpdate(_, prevState) {}

    render() {
        const { hideButton, visible, selectedRows } = this.props;
        const { markup, isPurchase, isSell } = this.state;
        const radioStyle = {
            display: 'block'
        };

        return (
            <React.Fragment>
                {!hideButton && (
                    <PriceTagIcon
                        className={Styles.icon}
                        onClick={() => {
                            this.setState({
                                visible: true
                            });
                        }}
                        title={this.props.intl.formatMessage({
                            id: 'order_form_table.update_order_prices'
                        })}
                    />
                )}
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='order_form_table.update_order_prices' />}
                    visible={this.state.visible || visible}
                    width={320}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontWeight: 500
                        }}
                    >
                        <FormattedMessage id='order_form_table.purchase' />
                        <Checkbox
                            data-qa='checkbox_purchase_price_recalculation_modal'
                            onChange={({ target }) => {
                                this.setState({
                                    isPurchase: target.checked,
                                    purchase: undefined
                                });
                            }}
                        />
                    </div>
                    {isPurchase && (
                        <div style={{ padding: '4px 0px 0px 24px' }}>
                            <Radio.Group
                                data-qa='radio_group_purchase_price_recalculation_modal'
                                onChange={({ target }) => {
                                    this.setState({
                                        purchase: target.value
                                    });
                                }}
                            >
                                <Radio style={radioStyle} value='FIFO'>
                                    <FormattedMessage id='order_form_table.purchase.from_stock_FIFO' />
                                </Radio>
                                <Radio style={radioStyle} value='STORE_PRODUCT'>
                                    <FormattedMessage id='order_form_table.purchase.from_stock_last_price' />
                                </Radio>
                                <Radio style={radioStyle} value='API'>
                                    <FormattedMessage id='order_form_table.purchase.API' />
                                </Radio>
                                <Radio style={radioStyle} value='PRICELIST'>
                                    <FormattedMessage id='order_form_table.purchase.pricelist' />
                                </Radio>
                            </Radio.Group>
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontWeight: 500
                        }}
                    >
                        <FormattedMessage id='order_form_table.sell' />
                        <Checkbox
                            data-qa='checkbox_sell_price_recalculation_modal'
                            onChange={({ target }) => {
                                this.setState({
                                    isSell: target.checked,
                                    sell: undefined
                                });
                            }}
                        />
                    </div>
                    {isSell && (
                        <div style={{ padding: '4px 0px 8px 24px' }}>
                            <Radio.Group
                                data-qa='radio_group_sell_price_recalculation_modal'
                                onChange={({ target }) => {
                                    this.setState({
                                        sell: target.value
                                    });
                                }}
                            >
                                <Radio style={radioStyle} value='STORE_PRODUCT'>
                                    <FormattedMessage id='order_form_table.sell.from_stock' />
                                </Radio>
                                <Radio style={radioStyle} value='API'>
                                    <FormattedMessage id='order_form_table.sell.API' />
                                </Radio>
                                <Radio style={radioStyle} value='PRICELIST'>
                                    <FormattedMessage id='order_form_table.sell.pricelist' />
                                </Radio>
                            </Radio.Group>
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontWeight: 500
                        }}
                    >
                        <FormattedMessage id='order_form_table.check_markup' />
                        <Checkbox
                            data-qa='checkbox_markup_price_recalculation_modal'
                            disabled={selectedRows ? selectedRows.find(({ agreement }) => agreement == 'AGREED') : null}
                            onChange={({ target }) => {
                                this.setState({
                                    markup: target.checked,
                                    minMargin: undefined,
                                    minMarkup: undefined
                                });
                            }}
                        />
                    </div>
                    {markup && (
                        <div style={{ padding: '4px 0px 0px 24px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <FormattedMessage id='order_form_table.min_marge' />
                                <InputNumber
                                    data-qa='input_number_minMargin_price_recalculation_modal'
                                    decimalSeparator=','
                                    formatter={value => `${value}%`}
                                    min={0}
                                    onChange={value => {
                                        this.setState({
                                            minMargin: value
                                        });
                                    }}
                                    parser={value => value.replace('%', '')}
                                    step={0.5}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <FormattedMessage id='order_form_table.min_markup' />
                                <InputNumber
                                    data-qa='input_number_minMarkup_price_recalculation_modal'
                                    decimalSeparator=','
                                    formatter={value => `${value}%`}
                                    min={0}
                                    onChange={value => {
                                        this.setState({
                                            minMarkup: value
                                        });
                                    }}
                                    parser={value => value.replace('%', '')}
                                    step={0.5}
                                />
                            </div>
                        </div>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}
