import { DatePicker, Flex, Input, InputNumber, Modal, Select, notification } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

import Styles from './styles.m.css';

const { TextArea } = Input;
const { Option } = Select;

const statusNames = {
    NEW: 'interactions.status.NEW',
    DONE: 'interactions.status.DONE',
    DELETED: 'interactions.status.DELETED'
};

const inputWidth = 180;

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

const AddInteractionRowModal = ({ open = false, closeModal, intl, documentId, fetchDocData, rowData, isMobile }) => {
    const [date, setDate] = useState(dayjs());
    const [nextDate, setNextDate] = useState(date.startOf('day').hour(9));
    const [oldStatus, setOldStatus] = useState();
    const [subStatus, setSubStatus] = useState();
    const [type, setType] = useState();
    const [sum, setSum] = useState(0);
    const [comment, setComment] = useState('');

    const [statuses, setStatuses] = useState([]);
    const [types, setTypes] = useState([]);

    const handleDateChange = date => setDate(date);
    const handleNextDateChange = date => setNextDate(date.startOf('day').hour(9));

    const onClose = async () => {
        setSum(0);
        setNextDate(date.startOf('day').hour(9));
        setDate(dayjs());
        setComment('');
        setSubStatus(undefined);
        setOldStatus(undefined);
        setType(undefined);
        await closeModal();
    };

    const handleFinish = async values => {
        const submitValuesAdd = {
            nextDate,
            comment,
            sum,
            documentId,
            subStatusId: subStatus,
            interactionTypeId: type
        };
        const submitValuesEdit = {
            documentId,
            rows: [
                {
                    id: rowData ? rowData.id : undefined,
                    nextDate,
                    comment,
                    sum,
                    subStatusId: subStatus,
                    interactionTypeId: type
                }
            ]
        };

        try {
            if (!rowData) {
                const response = await fetchAPI('POST', '/crm/interactions_doc/rows', null, submitValuesAdd, {
                    handleErrorInternally: true
                });
            } else {
                const response = await fetchAPI('PUT', '/crm/interactions_doc/rows', null, submitValuesEdit, {
                    handleErrorInternally: true
                });
            }
            notification.success({ message: intl.formatMessage({ id: 'message.success' }) });
        } catch (e) {
            notification.error({ message: intl.formatMessage({ id: 'error' }) });
        }

        await onClose();
        await fetchDocData();
    };

    const setRowData = () => {
        if (rowData) {
            setSum(rowData ? rowData.sum : 0);
            setNextDate(dayjs(rowData.nextDate));
            setDate(dayjs(rowData.creationDate));
            setComment(rowData.comment);
            setSubStatus(rowData.subStatusId);
            setType(rowData.interactionTypeId);
        }
    };

    useEffect(() => {
        const fetchStatuses = async () => {
            const response = await fetchAPI('GET', '/crm/substatus', null, null, { handleErrorInternally: true });
            setStatuses(response);
        };
        const fetchTypes = async () => {
            const responseTypes = await fetchAPI('GET', '/crm/interations_row_type', null, null, {
                handleErrorInternally: true
            });
            setTypes(responseTypes);
        };
        fetchStatuses();
        fetchTypes();
        setRowData();
    }, [rowData]);

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{
                disabled: !comment || !type || !subStatus
            }}
            onCancel={onClose}
            onOk={handleFinish}
            open={open}
            style={{ minWidth: isMobile ? '95%' : 1024 }}
        >
            {/* <div className={Styles.formWrapper}> */}
            <Flex className={isMobile ? Styles.formWrapperMobile : Styles.formRow} horizontal justify='space-between'>
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.date' />:
                    </div>
                    <DatePicker
                        disabled
                        onChange={handleDateChange}
                        placeholder={intl.formatMessage({
                            id: 'cash-table.date'
                        })}
                        style={{ width: '100%' }}
                        value={date}
                    />
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='interactions.interaction_type' />:
                    </div>
                    <Select
                        onChange={value => setType(value)}
                        placeholder={intl.formatMessage({
                            id: 'interactions.interaction_type'
                        })}
                        style={{ width: '100%' }}
                        value={type}
                    >
                        {types.map(({ name, id }) => (
                            <Option key={id} value={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='orders.status' />:
                    </div>
                    <Select
                        onChange={value => setSubStatus(value)}
                        placeholder={intl.formatMessage({
                            id: 'orders.status'
                        })}
                        style={{ width: '100%' }}
                        value={subStatus}
                    >
                        {statuses
                            .filter(({ enabled }) => enabled)
                            .map(({ name, id }) => (
                                <Option key={id} value={id}>
                                    {name}
                                </Option>
                            ))}
                    </Select>
                </div>
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='interactions.next_date' />:
                    </div>
                    <DatePicker
                        onChange={handleNextDateChange}
                        placeholder={intl.formatMessage({
                            id: 'interactions.next_date'
                        })}
                        style={{ width: '100%' }}
                        value={nextDate}
                    />
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.sum' />:
                    </div>
                    <InputNumber
                        onChange={value => setSum(value)}
                        placeholder={intl.formatMessage({
                            id: 'cash-table.sum'
                        })}
                        style={{ width: '100%' }}
                        type='number'
                        value={sum}
                    />
                </div>
            </Flex>

            <TextArea
                onChange={e => setComment(e.target.value)}
                placeholder={intl.formatMessage({
                    id: 'comment'
                })}
                style={{ marginTop: 14, minHeight: 300 }}
                value={comment}
            />

            {/* </div> */}
        </Modal>
    );
};

export default connect(mapStateToProps)(injectIntl(AddInteractionRowModal));
