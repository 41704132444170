import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Menu, Modal, Popconfirm, Switch, notification } from 'antd';
import { Layout } from 'commons';
import { DraggableTable } from 'components';
import HamburgerMenu from 'components/HamburgerMenu';
import _, { pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import CreateComplexModal from './components/CreateComplexModal';

const forbiddenUpdateComplexFields = [
    'laborComplexId',
    'laborBusinessId',
    'productComplexId',
    'productBusinessId',
    'laborShortNames'
];

const mapStateToProps = state => ({
    user: state.auth
});

const cleanForEdit = data =>
    data.map(el =>
        pick(
            el,
            Object.keys(el).filter(key => el[key] !== null && !forbiddenUpdateComplexFields.includes(key))
        ));

const ComplexesPage = ({ intl: { formatMessage }, user }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [createComplexOpen, setCreateComplexOpen] = useState(false);
    const [editObj, setEditObj] = useState(null);
    const [newName, setNewName] = useState('');
    const [labors, setLabors] = useState([]);
    const [details, setDetails] = useState([]);
    const [brands, setBrands] = useState([]);
    const [complexes, setComplexes] = useState([]);

    const openComplexModal = (row = true) => setCreateComplexOpen(row);
    const hideComplexModal = () => setCreateComplexOpen(false);

    const fetchComplexes = async () => {
        const complexes = await fetchAPI(
            'GET',
            '/business/complexes',
            { types: ['PRODUCTION', 'OWN', 'DISASSEMBLY'] },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        setComplexes(complexes);
    };

    const fetchLabors = useCallback(async search => {
        const { labors } = await fetchAPI(
            'GET',
            'labors',
            { page: 1, pageSize: 25, search: search ? search.replace(/-/g, '') : undefined },
            null,
            {
                handleErrorInternally: true
            }
        );

        setLabors(labors);
    }, []);

    const handleSearchLabors = useMemo(() => {
        const loadOptions = value => {
            fetchLabors(value);
        };

        return _.debounce(loadOptions, 500);
    }, [fetchLabors]);

    const fetchData = async () => {
        const [details, brands] = await Promise.all([
            fetchAPI('GET', '/store_groups', undefined, undefined, {
                handleErrorInternally: true
            }),
            fetchAPI('GET', 'brands', undefined, undefined, {
                handleErrorInternally: true
            })
        ]);
        fetchComplexes();
        fetchLabors();
        setDetails(details);
        setBrands(brands);
    };

    const editComplexes = async complexes => {
        const response = await fetchAPI(
            'PUT',
            '/business/complexes',
            null,
            { complexesData: complexes },
            { handleErrorInternally: true }
        );

        return response;
    };
    const handleEditOpen = row => {
        setEditObj(row);
        setNewName(row.name);
    };
    const handleEditClose = () => {
        setEditObj(null);
        setNewName('');
    };
    const handleEdit = useMemo(
        () => async () => {
            console.log(editObj, [
                {
                    name: newName,
                    id: editObj.id,
                    complexLaborsData: editObj.complexLaborsData,
                    complexProductsData: editObj.complexProductsData
                }
            ]);
            await editComplexes([
                {
                    name: newName,
                    id: editObj.id,
                    complexLaborsData: cleanForEdit(editObj.labors),
                    complexProductsData: cleanForEdit(editObj.products)
                }
            ]);
            handleEditClose();
            await fetchComplexes();
        },
        [newName, editObj]
    );

    const handleStatusChange = useCallback(
        async status => {
            await editComplexes(
                selectedRows.map(({ id, labors, products }) => ({
                    id,
                    enabled: status,
                    complexLaborsData: cleanForEdit(labors),
                    complexProductsData: cleanForEdit(products)
                }))
            );
            await fetchComplexes();
            setSelectedRows([]);
            setSelectedRowKeys([]);
        },
        [selectedRows]
    );

    const handleCopy = useCallback(async complex => {
        const response = await fetchAPI('PUT', '/business/complexes/copy', null, {
            id: complex.id
        });
        await fetchComplexes();

        return response;
    }, []);

    const deleteComplexes = useCallback(async complexes => {
        const complexIds = complexes.map(({ id }) => id);
        try {
            await fetchAPI(
                'DELETE',
                '/business/complexes',
                null,
                { complexIds },
                {
                    handleErrorInternally: true
                }
            );
            await fetchComplexes();
            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'complexes.delete_error_message' })
            });
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const columns = useMemo(
        () => [
            {
                title: () => {
                    const actionMenu = () => (
                        <Menu>
                            <Menu.Item
                                key='complex.group_enable'
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_COMPLEXES, accesses.ROWO)}
                                onClick={() => handleStatusChange(true)}
                            >
                                {formatMessage({ id: 'complexes.enable_complex' })}
                            </Menu.Item>
                            <Menu.Item
                                key='complex.group_disable'
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_COMPLEXES, accesses.ROWO)}
                                onClick={() => handleStatusChange(false)}
                            >
                                {formatMessage({ id: 'complexes.disable_complex' })}
                            </Menu.Item>
                            <Menu.Item
                                key='complex.group_delete'
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_COMPLEXES, accesses.ROWO)}
                            >
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: formatMessage({ id: 'add_order_form.delete_confirm' }),
                                            onOk: () => deleteComplexes(selectedRows)
                                        });
                                    }}
                                >
                                    {formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionMenu} disabled={!selectedRows.length} />;
                },
                render: row => {
                    const singleMenu = () => (
                        <Menu>
                            <Menu.Item
                                key='complex.single_copy'
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_COMPLEXES, accesses.ROWO)}
                                onClick={() => handleCopy(row)}
                            >
                                {formatMessage({ id: 'copy' })}
                            </Menu.Item>
                            <Menu.Item
                                key='complex.single_delete'
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_COMPLEXES, accesses.ROWO)}
                            >
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: formatMessage({ id: 'add_order_form.delete_confirm' }),
                                            onOk: () => deleteComplexes([row])
                                        });
                                    }}
                                >
                                    {formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={singleMenu} />;
                },
                width: '48px',
                align: 'center'
            },
            {
                title: <Button disabled icon={<EditOutlined />} />,
                width: '48px',
                align: 'center',
                render: row => (
                    <Button
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_COMPLEXES, accesses.ROWO)}
                        icon={<EditOutlined />}
                        onClick={() => openComplexModal(row)}
                    />
                )
            },
            {
                title: '№',
                key: 'index',
                width: '48px',
                render: (r, r2, index) => index + 1,
                align: 'right'
            },
            {
                title: <FormattedMessage id='services_table.complex' />,
                render: row => (
                    <div>
                        {row.name}
                        <Popconfirm
                            description={
                                <Input
                                    onChange={e => setNewName(e.target.value)}
                                    placeholder={formatMessage({ id: 'complexes.complex_name' })}
                                    value={newName}
                                ></Input>
                            }
                            onCancel={handleEditClose}
                            onConfirm={handleEdit}
                            open={editObj && editObj.id === row.id}
                            title={formatMessage({ id: 'complexes.change_name' })}
                        >
                            <Button icon={<EditOutlined />} onClick={() => handleEditOpen(row)} type='text' />
                        </Popconfirm>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='complexes.type' />,
                render: row => (
                    <div>
                        <FormattedMessage id={`complexes.type.${row.type}`} />
                    </div>
                )
            },
            {
                title: <FormattedMessage id='complexes.jobs' />,
                align: 'right',
                render: ({ labors }) => labors.length
            },
            {
                title: <FormattedMessage id='complexes.parts' />,
                align: 'right',
                render: ({ products }) => products.length
            },
            {
                title: formatMessage({ id: 'product.visibility' }),
                render: row => (
                    <Switch
                        checked={row.enabled}
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_COMPLEXES, accesses.ROWO)}
                        onChange={async enabled => {
                            await editComplexes([
                                {
                                    enabled,
                                    id: row.id,
                                    complexLaborsData: cleanForEdit(row.labors),
                                    complexProductsData: cleanForEdit(row.products)
                                }
                            ]);
                            await fetchComplexes();
                        }}
                    />
                )
            }
        ],
        [deleteComplexes, editObj, formatMessage, handleCopy, handleEdit, handleStatusChange, newName, selectedRows]
    );

    const rowSelection = useMemo(() => {
        return {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRows);
                setSelectedRowKeys(selectedRowKeys);
            }
        };
        // }, [actionsMenu, selectedRowKeys, selectedRows]);
    }, [selectedRowKeys, selectedRows]);

    const accessAddButton = isGrantAccessed(user, grants.DIRECTORIES_COMPLEXES, accesses.ROWO);

    return (
        <Layout
            controls={
                <Button disabled={!accessAddButton} onClick={() => openComplexModal()} type='primary'>
                    {formatMessage({ id: 'create' })}
                </Button>
            }
            title={<FormattedMessage id='navigation.complexes' />}
        >
            <DraggableTable
                addDragColumn
                bordered
                columns={columns}
                dataSource={complexes}
                onDragEnd={async (fromIndex, toIndex) => {
                    await fetchAPI(
                        'PUT',
                        'business/complexes/swap',
                        {
                            complex1: complexes[fromIndex].order,
                            complex2: complexes[toIndex].order
                        },
                        undefined,
                        { handleErrorInternally: true }
                    );
                    fetchComplexes();
                }}
                rowKey='id'
                rowSelection={rowSelection}
                size='small'
            />
            <CreateComplexModal
                brands={brands}
                details={details}
                fetchComplexes={fetchComplexes}
                handleSearchLabors={handleSearchLabors}
                hideModal={hideComplexModal}
                labors={labors}
                open={createComplexOpen}
            />
        </Layout>
    );
};

export default injectIntl(connect(mapStateToProps)(ComplexesPage));
