import { Button, Checkbox, Flex, InputNumber, Modal, Radio, Space, notification } from 'antd';
import { get, omit } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, getCurrency } from 'utils';
import Styles from './styles.m.css';

const initialData = {
    purchaseCoefficient: 1,
    purchaseFixed: 1,
    sellCoefficient: 1,
    sellFixed: 1,
    sellFixedDiscount: 0,
    sellFixedMarkup: 50,
    sellMinMarkup: 25,
    decimalPlaces: 2
};

const UpdatePriceModal = ({
    open,
    ordersAppurtenanciesIds,
    handleOk,
    handleClose,
    hideSellingPrice,
    isRTN,
    intl: { formatMessage },
    selectedRows
}) => {
    const [inputs, setInputs] = useState(initialData);
    const [isRound, setIsRound] = useState(true);
    const [recalculateSalesWhenChangingPurchase, updCheckbox] = useState(true);
    const [loading, setLoading] = useState(false);
    const [purchase, setPurchase] = useState('BY_COEFFICIENT');
    const [sell, setSell] = useState('BY_COEFFICIENT');
    const inputRef = useRef(null);

    const onChangePurchase = e => {
        setPurchase(e.target.value);
    };

    const onChangeSell = e => {
        setSell(e.target.value);
    };

    const handleInputsChange = key => value => {
        setInputs({ ...inputs, [key]: value });
    };

    const getProduct = async product => {
        const prices = await fetchAPI(
            'GET',
            '/prices_by_product',
            { productId: get(product, 'productId'), clientId: null, supplierId: null },
            null,
            {
                handleErrorInternally: true
            }
        );
        setInputs(prev => ({ ...prev, ...prices }));
    };

    useEffect(() => {
        if (open) {
            const timeoutId = setTimeout(() => {
                inputRef.current.focus();
            }, 100);

            return () => clearTimeout(timeoutId);
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            setInputs(initialData);
            if (open.length === 1) {
                getProduct(open[0]);
            }
        } else {
            setLoading(false);
        }
    }, [open]);

    const handleFifo = async () => {
        await fetchAPI(
            'PUT',
            '/orders/details/prices',
            null,
            { ordersAppurtenanciesIds, purchase: 'FIFO' },
            {
                handleErrorInternally: true
            }
        );
        handleClose();
        handleOk();
    };

    const handleApi = async () => {
        await fetchAPI(
            'PUT',
            '/orders/details/prices',
            null,
            { ordersAppurtenanciesIds, purchase: 'API' },
            {
                handleErrorInternally: true
            }
        );
        handleClose();
        handleOk();
    };

    const onOk = async () => {
        setLoading(true);
        await fetchAPI(
            'PUT',
            '/orders/details/prices',
            null,
            {
                ordersAppurtenanciesIds,
                ...omit(inputs, [
                    'purchasePriceFromProductCard',
                    'sellingPriceFromProductCard',
                    'purchasePriceLast',
                    'purchasePriceFromSuppliersPrice',
                    'sellingPriceFromSuppliersPrice',
                    'purchasePriceDefaultMarkup',
                    'sellingPriceStandartMarkup'
                ]),
                sell,
                purchase,
                recalculateSalesWhenChangingPurchase,
                isRound
            },
            { handleErrorInternally: true }
        );

        handleClose();
        const isRejected = (selectedRows || []).find(
            ({ agreement }) => agreement === 'AGREED' || agreement === 'REJECTED'
        );

        if (isRejected && sell === 'FIXED_DISCOUNT') {
            notification.warning({
                message: formatMessage({
                    id: 'labor.rejected_discount_message'
                })
            });
        }

        handleOk();
    };

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{
                loading
            }}
            onCancel={async () => {
                await handleClose();
                setIsRound(true);
            }}
            onOk={onOk}
            open={open}
            title={formatMessage({ id: 'update_price' })}
            width='fit-content'
        >
            <Flex justify='flex-end'>
                <Space>
                    <Button disabled={isRTN} onClick={handleFifo}>
                        FIFO
                    </Button>
                    <Button disabled={isRTN} onClick={handleApi}>
                        API
                    </Button>
                </Space>
            </Flex>
            <Flex justify='space-between'>
                <Space direction='vertical'>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>
                        <FormattedMessage id='order_form_table.purchase_prices' />
                    </p>
                    <Radio.Group disabled={isRTN} onChange={onChangePurchase} value={purchase}>
                        <Space direction='vertical'>
                            <Radio disabled={isRTN} value='BY_COEFFICIENT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.by_coefficient' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            decimalSeparator=','
                                            disabled={isRTN}
                                            min={0}
                                            onChange={handleInputsChange('purchaseCoefficient')}
                                            onClick={() => setPurchase('BY_COEFFICIENT')}
                                            precision={4}
                                            step={0.002}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchaseCoefficient}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio disabled={isRTN} value='FIXED'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.fixed_price' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled={isRTN}
                                            min={0}
                                            onChange={handleInputsChange('purchaseFixed')}
                                            onClick={() => setPurchase('FIXED')}
                                            precision={2}
                                            step={5}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchaseFixed}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio disabled={isRTN} value='STORE_PRODUCT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.product_prices' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchasePriceFromProductCard}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio disabled={isRTN} value='FIFO'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.fifo_prices' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio disabled={isRTN} value='LAST'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.storage_last_prices' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchasePriceLast}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio disabled={isRTN} value='PRICELIST'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.purchase.pricelist' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.purchasePriceFromSuppliersPrice}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio disabled={isRTN} value='API'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.purchase.API' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            disabled
                                            style={{ width: 160, marginLeft: 10 }}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Checkbox
                                checked={recalculateSalesWhenChangingPurchase}
                                onChange={() => updCheckbox(!recalculateSalesWhenChangingPurchase)}
                            >
                                <FormattedMessage id='order_form_table.recalculation_prices' />
                            </Checkbox>
                        </Space>
                    </Radio.Group>
                </Space>
                {!hideSellingPrice && (
                    <Space direction='vertical'>
                        <p style={{ fontSize: 16, fontWeight: 700 }}>
                            <FormattedMessage id='order_form_table.sell_price' />
                        </p>
                        <Radio.Group onChange={onChangeSell} value={sell}>
                            <Space direction='vertical'>
                                <Radio disabled={isRTN} value='BY_COEFFICIENT'>
                                    <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                        <FormattedMessage id='order_form_table.by_coefficient' />{' '}
                                        <div className={Styles.detailNumberInput}>
                                            <InputNumber
                                                ref={inputRef}
                                                decimalSeparator=','
                                                disabled={isRTN}
                                                min={0}
                                                onChange={handleInputsChange('sellCoefficient')}
                                                onClick={() => setSell('BY_COEFFICIENT')}
                                                precision={4}
                                                step={0.002}
                                                style={{ width: 160, marginLeft: 10 }}
                                                value={inputs.sellCoefficient}
                                            />
                                        </div>
                                    </Flex>
                                </Radio>
                                <Radio disabled={isRTN} value='FIXED'>
                                    <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                        <FormattedMessage id='order_form_table.fixed_price' />{' '}
                                        <div className={Styles.detailNumberInput}>
                                            <InputNumber
                                                addonAfter={getCurrency()}
                                                decimalSeparator=','
                                                disabled={isRTN}
                                                min={0}
                                                onChange={handleInputsChange('sellFixed')}
                                                onClick={() => setSell('FIXED')}
                                                precision={2}
                                                step={5}
                                                style={{ width: 160, marginLeft: 10 }}
                                                value={inputs.sellFixed}
                                            />
                                        </div>
                                    </Flex>
                                </Radio>
                                <Radio value='FIXED_DISCOUNT'>
                                    <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                        <FormattedMessage id='order_form_table.fixed_discount' />{' '}
                                        <div className={Styles.detailNumberInput}>
                                            <InputNumber
                                                addonAfter='%'
                                                decimalSeparator=','
                                                onChange={handleInputsChange('sellFixedDiscount')}
                                                onClick={() => setSell('FIXED_DISCOUNT')}
                                                style={{ width: 160, marginLeft: 10 }}
                                                value={inputs.sellFixedDiscount}
                                            />
                                        </div>
                                    </Flex>
                                </Radio>
                                <Radio disabled={isRTN} value='STORE_PRODUCT'>
                                    <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                        <FormattedMessage id='order_form_table.product_prices' />{' '}
                                        <div className={Styles.detailNumberInput}>
                                            <InputNumber
                                                addonAfter={getCurrency()}
                                                decimalSeparator=','
                                                disabled
                                                style={{ width: 160, marginLeft: 10 }}
                                                value={inputs.sellingPriceFromProductCard}
                                            />
                                        </div>
                                    </Flex>
                                </Radio>
                                <Radio disabled={isRTN} value='STANDART_MARKUP'>
                                    <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                        <FormattedMessage id='order_form_table.standart_markup' />{' '}
                                        <div className={Styles.detailNumberInput}>
                                            <InputNumber
                                                addonAfter='%'
                                                decimalSeparator=','
                                                disabled
                                                // onChange={handleInputsChange('sellingPriceStandartMarkup')}
                                                style={{ width: 160, marginLeft: 10 }}
                                                value={inputs.sellingPriceStandartMarkup}
                                            />
                                        </div>
                                    </Flex>
                                </Radio>
                                <Radio value='FIXED_MARKUP'>
                                    <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                        <FormattedMessage id='order_form_table.fixed_markup' />{' '}
                                        <div className={Styles.detailNumberInput}>
                                            <InputNumber
                                                addonAfter='%'
                                                decimalSeparator=','
                                                min={0}
                                                onChange={handleInputsChange('sellFixedMarkup')}
                                                onClick={() => setSell('FIXED_MARKUP')}
                                                style={{ width: 160, marginLeft: 10 }}
                                                value={inputs.sellFixedMarkup}
                                            />
                                        </div>
                                    </Flex>
                                </Radio>
                                <Radio disabled={isRTN} value='PRICELIST'>
                                    <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                        <FormattedMessage id='order_form_table.purchase.pricelist' />{' '}
                                        <div className={Styles.detailNumberInput}>
                                            <InputNumber
                                                addonAfter={getCurrency()}
                                                decimalSeparator=','
                                                disabled
                                                style={{ width: 160, marginLeft: 10 }}
                                                value={inputs.sellingPriceFromSuppliersPrice}
                                            />
                                        </div>
                                    </Flex>
                                </Radio>
                                <Radio disabled value='API'>
                                    <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                        <FormattedMessage id='order_form_table.api_prices' />{' '}
                                        <div className={Styles.detailNumberInput}>
                                            <InputNumber
                                                addonAfter={getCurrency()}
                                                decimalSeparator=','
                                                disabled
                                                style={{ width: 160, marginLeft: 10 }}
                                            />
                                        </div>
                                    </Flex>
                                </Radio>
                                <Radio disabled={isRTN} value='MIN_MARKUP'>
                                    <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                        <FormattedMessage id='order_form_table.minimal_markup' />{' '}
                                        <div className={Styles.detailNumberInput}>
                                            <InputNumber
                                                addonAfter='%'
                                                decimalSeparator=','
                                                disabled={isRTN}
                                                min={0}
                                                onChange={handleInputsChange('sellMinMarkup')}
                                                onClick={() => setSell('MIN_MARKUP')}
                                                style={{ width: 160, marginLeft: 10 }}
                                                value={inputs.sellMinMarkup}
                                            />
                                        </div>
                                    </Flex>
                                </Radio>
                            </Space>
                        </Radio.Group>
                        <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                            <Checkbox checked={isRound} onChange={() => setIsRound(!isRound)} />
                            <FormattedMessage id='storage.round' />{' '}
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    addonAfter={getCurrency()}
                                    decimalSeparator=','
                                    max={4}
                                    min={0}
                                    onChange={handleInputsChange('decimalPlaces')}
                                    style={{ width: 160, marginLeft: 94, marginRight: -24 }}
                                    value={inputs.decimalPlaces}
                                />
                            </div>
                        </Flex>
                    </Space>
                )}
            </Flex>
        </Modal>
    );
};

export default injectIntl(UpdatePriceModal);
