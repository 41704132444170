/* eslint-disable no-underscore-dangle */
import {
    CarOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    MenuOutlined,
    PhoneTwoTone,
    PlusOutlined,
    ReadOutlined,
    SwapOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Form, Input, Modal, Popconfirm, Popover, Select, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { Numeral } from 'commons';
import { Barcode } from 'components';
import _, { get } from 'lodash';
import { VehicleLocationModal } from 'modals';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, getCurrency, permissions, toCyrillicTransliterate } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { v4 } from 'uuid';
import { ClientsSearchTable } from '../OrderFormTables';
import { formCommentLayout, fromExpandedCommentLayout } from '../layouts';
import Styles from './styles.m.css';

const { Option } = Select;

const cx = classNames.bind(Styles);

// TODO: @yan
// specific label name formating
function formatVehicleLabel(vehicle, formatMessage) {
    const modelPart = vehicle.model
        ? `${vehicle.make} ${vehicle.model}`
        : formatMessage({ id: 'add_order_form.no_model' });
    const horsePowerLabel = !vehicle.horsePower
        ? null
        : `(${vehicle.horsePower} ${formatMessage({
              id: 'horse_power'
          })})`;
    const modificationPart = [vehicle.modification, horsePowerLabel].filter(Boolean).join(' ');
    const parts = [modelPart, vehicle.year, modificationPart, vehicle.number, vehicle.vin];

    return parts.filter(Boolean).map(String).map(_.trimEnd).join(', ');
}

function formatAggregateLabel(aggregate, formatMessage) {
    const modelPart = aggregate.name
        ? ` ${aggregate.name} ${aggregate.brandName} (#${aggregate.aggregateNumber}) `
        : formatMessage({ id: 'add_order_form.no_model' });

    const parts = [modelPart];

    return parts.filter(Boolean).map(String).map(_.trimEnd).join(', ');
}

@injectIntl
export default class OrderFormBody extends Component {
    constructor(props) {
        super(props);
        this.state = { clientsAggregates: [], debt: undefined };

        // Constant rules, styles, props
        this.requiredFieldRules = [
            {
                required: true,
                message: this.props.intl.formatMessage({
                    id: 'required_field'
                })
            }
        ];
        this.requiredNumberFieldRules = [
            {
                type: 'number',
                message: this.props.intl.formatMessage({
                    id: 'required_field'
                })
            }
        ];
        this.recommendationRules = [
            {
                max: 2000,
                message: this.props.intl.formatMessage({
                    id: 'field_should_be_below_2000_chars'
                })
            }
        ];
        this._prevRecommendationAutoSize = { minRows: 2, maxRows: 6 };
        this._recommendationAutoSize = { minRows: 2, maxRows: 6 };
        this._clientPhoneBorderStyle = { borderRadius: 0 };

        // In order to reduce <FormatMessage> invocation
        this._localizationMap = {};

        // Default select options
        const clientPhonesOptions = this._getClientPhonesOptions();
        const clientEmailsOptions = this._getClientEmailsOptions();
        const clientVehiclesOptions = this._getClientVehiclesOptions();
        const clientAggregatesOptions = this._getClientAggregateOptions();

        // ClientEmail required copy button, so we need to regenerate the value
        const clientEmailLabel = this._getClientEmailLabel();
        const businessLocationsLabel = this._getBusinessLocationsLabel();
        const recommendationStyles = this._getRecommendationStyles();

        // Configure initial state
        this.state = {
            clientPhonesOptions,
            clientEmailsOptions,
            clientEmailLabel,
            businessLocationsLabel,
            clientVehiclesOptions,
            recommendationStyles,
            clientAggregatesOptions
        };

        this.clientRef = React.createRef();
        this.milageRef = React.createRef();
        this.locationRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedClient !== this.props.selectedClient || prevProps.vehicles !== this.props.vehicles) {
            const clientPhonesOptions = this._getClientPhonesOptions();
            const clientEmailsOptions = this._getClientEmailsOptions();
            const clientEmailLabel = this._getClientEmailLabel();

            const clientVehiclesOptions = this._getClientVehiclesOptions();

            const clientAggregatesOptions = this._getClientAggregateOptions();
            const clientDebt = this.fetchClientDebt();

            this.setState({
                clientPhonesOptions,
                clientEmailsOptions,
                clientEmailLabel,
                clientVehiclesOptions,
                clientAggregatesOptions,
                clientDebt
            });
        }

        if (prevProps.clientEmail !== this.props.clientEmail) {
            const clientEmailLabel = this._getClientEmailLabel();
            this.setState({ clientEmailLabel });
        }

        // if (
        //     prevProps.orderId !== this.props.orderId ||
        //     prevProps.orderHistory !== this.props.orderHistory
        // ) {
        //     const recommendationStyles = this._getRecommendationStyles();
        //     this.setState({ recommendationStyles });
        // }

        if (prevProps.focusedRef !== this.props.focusedRef) {
            if (this.props.focusedRef === 'HEADER_CLIENT_SEARCH' && this.clientRef.current) {
                this.clientRef.current.focus();
                this.props.focusOnRef();
            }
            if (this.props.focusedRef === 'HEADER_MILEAGE') {
                this.milageRef.current.focus();
                this.props.focusOnRef();
            }
            if (this.props.focusedRef === 'HEADER_LOCATION_ACTION') {
                const businessLocationsLabel = this._getBusinessLocationsLabel();
                this.setState({ businessLocationsLabel });
                // this.locationRef.current.focus();
                this.props.focusOnRef();
            }
        }

        if (prevProps.fetchedOrder !== this.props.fetchedOrder) {
            const businessLocationsLabel = this._getBusinessLocationsLabel();
            this.setState({ businessLocationsLabel });
        }
    }

    _renderVehicleColumn = () => {
        const {
            selectedClient,
            fetchedOrder,
            clientVehicle,
            createOrder,
            clientAggregateId,
            createStatus,
            fields,
            isClosedStatus,
            orderBloke,
            vehicles,
            isRTN
        } = this.props;
        const { formatMessage } = this.props.intl;
        const selectedVehicleId = clientVehicle || get(fields, 'clientVehicle ');
        const selectedAggregateId = _.get(fields, 'clientAggregateId');

        const selectedVehicle = selectedClient && selectedVehicleId && _.find(vehicles, { id: selectedVehicleId });

        const selectedAggregate =
            selectedClient &&
            selectedAggregateId &&
            _.find(selectedClient.aggregates, {
                clientAggregateId: selectedAggregateId
            });

        return (
            <div className={Styles.bodyColumn}>
                <div className={Styles.bodyColumnContent}>
                    <div className={Styles.contentWrapper}>
                        <div className={Styles.comboFieldWrapper}>
                            <span className={Styles.clientFieldTitle}>
                                <CarOutlined />
                                {orderBloke === 'UNIT_REPAIR' || orderBloke === 'WARRANTY_UNIT_REPAIR' ? (
                                    <FormattedMessage id='order_form.aggregate' />
                                ) : (
                                    <FormattedMessage id='add_order_form.car' />
                                )}
                            </span>
                            {orderBloke !== 'UNIT_REPAIR' && orderBloke !== 'WARRANTY_UNIT_REPAIR' ? (
                                <div className={Styles.comboField__vehicle}>
                                    {_.get(selectedVehicle, 'number') && (
                                        <span>
                                            <FormattedMessage id='add_client_form.car_number' />
                                            {': '}
                                            {_.get(selectedVehicle, 'number')}
                                        </span>
                                    )}
                                    {_.get(selectedVehicle, 'vin') ? ',' : null}
                                    {_.get(selectedVehicle, 'vin') && (
                                        <span style={{ paddingLeft: 8 }}>
                                            <FormattedMessage id='add_client_form.vin' />
                                            {': '}
                                            {_.get(selectedVehicle, 'vin')}
                                        </span>
                                    )}
                                    {_.get(selectedVehicle, 'managerName') ? ',' : null}
                                    {_.get(selectedVehicle, 'managerName') && (
                                        <span style={{ paddingLeft: 8 }}>
                                            <FormattedMessage id='add_client_form.responsible' />
                                            {': '}
                                            {_.get(selectedVehicle, 'managerName')}
                                        </span>
                                    )}
                                    {_.get(selectedVehicle, 'managerPhone') ? ',' : null}
                                    {_.get(selectedVehicle, 'managerPhone') && (
                                        <span style={{ paddingLeft: 8 }}>
                                            <FormattedMessage id='add_client_form.phone_number_responsible_short' />
                                            {': '}
                                            {_.get(selectedVehicle, 'managerPhone')}
                                        </span>
                                    )}{' '}
                                    {selectedVehicle && (
                                        <Popover
                                            content={
                                                <div className={Styles.actionIconsWrap}>
                                                    {createOrder ? (
                                                        <Popconfirm
                                                            onConfirm={() => {
                                                                createOrder(
                                                                    createStatus,
                                                                    `${book.client}/${selectedClient.clientId}`
                                                                );
                                                            }}
                                                            title={<FormattedMessage id='save_order_changes' />}
                                                        >
                                                            <Link to={`${book.client}/${selectedClient.clientId}`}>
                                                                <EditOutlined className={Styles.icon} />
                                                            </Link>
                                                        </Popconfirm>
                                                    ) : (
                                                        <Link
                                                            to={`${book.vehicle}/${_.get(
                                                                fetchedOrder,
                                                                'order.clientVehicleId'
                                                            )}`}
                                                        >
                                                            <Tooltip
                                                                placement='bottom'
                                                                title={<FormattedMessage id='edit' />}
                                                            >
                                                                <EditOutlined className={Styles.icon} />
                                                            </Tooltip>
                                                        </Link>
                                                    )}
                                                    <CopyToClipboard text={`${selectedVehicle.vin}`}>
                                                        <Tooltip
                                                            placement='bottom'
                                                            title={<FormattedMessage id='order_form.copy_vin' />}
                                                        >
                                                            <CopyOutlined className={Styles.icon} />
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                    <Popconfirm>
                                                        <Tooltip
                                                            title={
                                                                <FormattedMessage id='add_order_form.delete_confirm' />
                                                            }
                                                        >
                                                            <DeleteOutlined
                                                                className={Styles.icon}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        vehicle: undefined
                                                                    });
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Popconfirm>
                                                </div>
                                            }
                                            placement='right'
                                            trigger='click'
                                        >
                                            <Tooltip
                                                placement='top'
                                                title={<FormattedMessage id='order_form_table.action' />}
                                            >
                                                <MenuOutlined className={Styles.actionIconsPopover} />
                                            </Tooltip>
                                        </Popover>
                                    )}
                                </div>
                            ) : (
                                <div className={Styles.comboField__vehicle}>
                                    {_.get(selectedAggregate, 'name') && (
                                        <span>
                                            <FormattedMessage id='storage.type' />
                                            {': '}
                                            {_.get(selectedAggregate, 'name')}
                                        </span>
                                    )}
                                    {/* {_.get(selectedAggregate, 'brandName') ? ',' : null}
                                    {_.get(selectedAggregate, 'brandName') && (
                                        <span style={{ paddingLeft: 8 }}>
                                            <FormattedMessage id='brand' />
                                            {': '}
                                            {_.get(selectedAggregate, 'brandName')}
                                        </span>
                                    )}
                                    {_.get(selectedAggregate, 'aggregateNumber') ? ',' : null}
                                    {_.get(selectedAggregate, 'aggregateNumber') && (
                                        <span style={{ paddingLeft: 8 }}>
                                            <FormattedMessage id='nRegNew' />
                                            {': '}
                                            {_.get(selectedAggregate, 'aggregateNumber')}
                                        </span>
                                    )} */}
                                    {_.get(selectedAggregate, 'count') ? ',' : null}
                                    {_.get(selectedAggregate, 'count') && (
                                        <span style={{ paddingLeft: 8 }}>
                                            <FormattedMessage id='storage_journal.amount' />
                                            {': '}
                                            {_.get(selectedAggregate, 'count')}
                                        </span>
                                    )}
                                    {_.get(selectedAggregate, 'comment') ? ',' : null}
                                    {_.get(selectedAggregate, 'comment') && (
                                        <span style={{ paddingLeft: 8 }}>
                                            <FormattedMessage id='comment' />
                                            {': '}"{_.get(selectedAggregate, 'comment')}"
                                        </span>
                                    )}
                                    {_.get(selectedAggregate, 'vin') ? ',' : null}
                                    {_.get(selectedAggregate, 'vin') && (
                                        <span style={{ paddingLeft: 8 }}>
                                            <FormattedMessage id='VIN' />
                                            {': '}
                                            {_.get(selectedAggregate, 'vin')}
                                        </span>
                                    )}
                                    {selectedAggregate && (
                                        <Popover
                                            content={
                                                <div className={Styles.actionIconsWrap}>
                                                    {createOrder ? (
                                                        <Popconfirm
                                                            onConfirm={() => {
                                                                createOrder(
                                                                    createStatus,
                                                                    `${book.client}/${selectedClient.clientId}`
                                                                );
                                                            }}
                                                            title={<FormattedMessage id='save_order_changes' />}
                                                        >
                                                            <Link to={`${book.client}/${selectedClient.clientId}`}>
                                                                <EditOutlined className={Styles.icon} />
                                                            </Link>
                                                        </Popconfirm>
                                                    ) : (
                                                        <Link
                                                            to={`${book.aggregate}/${_.get(
                                                                selectedAggregate,
                                                                'clientAggregateId'
                                                            )}`}
                                                        >
                                                            <Tooltip
                                                                placement='bottom'
                                                                title={<FormattedMessage id='edit' />}
                                                            >
                                                                <EditOutlined className={Styles.icon} />
                                                            </Tooltip>
                                                        </Link>
                                                    )}

                                                    <CopyToClipboard text={`${selectedAggregate.vin}`}>
                                                        <Tooltip
                                                            placement='bottom'
                                                            title={<FormattedMessage id='order_form.copy_vin' />}
                                                        >
                                                            <CopyOutlined className={Styles.icon} />
                                                        </Tooltip>
                                                    </CopyToClipboard>

                                                    <Popconfirm>
                                                        <Tooltip
                                                            title={
                                                                <FormattedMessage id='add_order_form.delete_confirm' />
                                                            }
                                                        >
                                                            <DeleteOutlined
                                                                className={Styles.icon}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        aggregate: undefined
                                                                    });
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Popconfirm>
                                                </div>
                                            }
                                            placement='right'
                                            trigger='click'
                                        >
                                            <Tooltip
                                                placement='top'
                                                title={<FormattedMessage id='order_form_table.action' />}
                                            >
                                                <MenuOutlined className={Styles.actionIconsPopover} />
                                            </Tooltip>
                                        </Popover>
                                    )}
                                </div>
                            )}
                        </div>
                        {orderBloke !== 'UNIT_REPAIR' && orderBloke !== 'WARRANTY_UNIT_REPAIR' ? (
                            <Form.Item initialValue={_.get(fetchedOrder, 'order.clientVehicleId')} name='clientVehicle'>
                                <Select
                                    allowClear
                                    className={Styles.comboFieldSelect__vehicle}
                                    data-qa='select_client_vehicle_id_order_page'
                                    disabled={this.bodyUpdateIsForbidden() || isClosedStatus || isRTN}
                                    filterOption={(inputValue, option) => {
                                        const optionText = option.children.toLowerCase();
                                        const inputText = inputValue.toLowerCase();

                                        return (
                                            optionText.includes(toCyrillicTransliterate(inputText)) ||
                                            optionText.includes(inputText)
                                        );
                                    }}
                                    onSearch={input => this.props.handleSearchClientVehicles(input)}
                                    onSelect={value => this.props.fetchVehicles(value)}
                                    optionFilterProp='children'
                                    showSearch
                                    title={`
                                            ${
                                                _.get(selectedVehicle, 'make')
                                                    ? `${_.get(selectedVehicle, 'make')} `
                                                    : ''
                                            }
                                            ${
                                                _.get(selectedVehicle, 'model')
                                                    ? `${_.get(selectedVehicle, 'model')} `
                                                    : ''
                                            }
                                            ${
                                                _.get(selectedVehicle, 'year')
                                                    ? `, ${_.get(selectedVehicle, 'year')}`
                                                    : ''
                                            }
                                            ${
                                                _.get(selectedVehicle, 'modification')
                                                    ? `, ${_.get(selectedVehicle, 'modification')}`
                                                    : ''
                                            }
                                            ${
                                                _.get(selectedVehicle, 'engineCode')
                                                    ? `, ${_.get(selectedVehicle, 'engineCode')}`
                                                    : ''
                                            }
                                            ${
                                                _.get(selectedVehicle, 'power')
                                                    ? `(${_.get(selectedVehicle, 'power')})`
                                                    : ''
                                            }
                                            ${
                                                _.get(selectedVehicle, 'number')
                                                    ? `, ${_.get(selectedVehicle, 'number')}`
                                                    : ''
                                            }
                                            ${_.get(selectedVehicle, 'vin') ? `, ${_.get(selectedVehicle, 'vin')}` : ''}
                                            ${
                                                _.get(selectedVehicle, 'comment')
                                                    ? `, ${_.get(selectedVehicle, 'comment')}`
                                                    : ''
                                            }
                                        `
                                        .replace(/\s+/g, ' ')
                                        .trim()}
                                >
                                    {this.state.clientVehiclesOptions}
                                </Select>
                            </Form.Item>
                        ) : (
                            <Form.Item
                                initialValue={_.get(fetchedOrder, 'order.clientAggregateId')}
                                name='clientAggregateId'
                            >
                                <Select
                                    allowClear
                                    className={Styles.comboFieldSelect__vehicle}
                                    data-qa='select_client_vehicle_id_order_page'
                                    disabled={this.bodyUpdateIsForbidden() || isClosedStatus}
                                    optionFilterProp='children'
                                    showSearch
                                >
                                    {this.state.clientAggregatesOptions}
                                </Select>
                            </Form.Item>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    _renderCommentsBlock = () => {
        const { fetchedOrder, user, isClosedStatus, orderStatus, onStatusChange } = this.props;
        const { ACCESS_ORDER_COMMENTS } = permissions;

        return (
            <div className={Styles.commentsBlock}>
                <div className={Styles.commentExtended}>
                    <Form.Item
                        initialValue={_.get(fetchedOrder, 'order.comment')}
                        // label={this._getLocalization('add_order_form.client_comments')}
                        name='comment'
                        rules={this.recommendationRules}
                        {...(this.state.recommendationStyles.prevRecommendation
                            ? formCommentLayout
                            : fromExpandedCommentLayout)}
                        onBlur={() => {
                            if (orderStatus) {
                                onStatusChange(orderStatus);
                            }
                        }}
                    >
                        <Input.TextArea
                            autoSize={this._recommendationAutoSize}
                            className={this.state.recommendationStyles.value}
                            data-qa='comment_client_order_page'
                            placeholder={this._getLocalization('add_order_form.client_comments')}
                        />
                    </Form.Item>
                </div>
                <div className={Styles.systemCommentExtended}>
                    <Form.Item
                        initialValue={_.get(fetchedOrder, 'order.systemComment')}
                        // label={this._getLocalization('add_order_form.system_comments')}
                        name='systemComment'
                        rules={this.recommendationRules}
                        {...(this.state.recommendationStyles.prevRecommendation
                            ? formCommentLayout
                            : fromExpandedCommentLayout)}
                        onBlur={() => {
                            if (orderStatus) {
                                onStatusChange(orderStatus);
                            }
                        }}
                    >
                        <Input.TextArea
                            autoSize={this._recommendationAutoSize}
                            className={this.state.recommendationStyles.value}
                            data-qa='comment_client_order_page'
                            placeholder={this._getLocalization('add_order_form.system_comments')}
                        />
                    </Form.Item>
                </div>
                {this.state.recommendationStyles.prevRecommendation && (
                    <Form.Item
                        initialValue={this.state.recommendationStyles.prevRecommendation}
                        label={this._getLocalization('add_order_form.prev_order_recommendations')}
                        name='prevRecommendation'
                        rules={this.recommendationRules}
                        {...fromExpandedCommentLayout}
                    >
                        <Input.TextArea
                            autoSize={this._prevRecommendationAutoSize}
                            className={Styles.comment}
                            data-qa='comment_client_recommendations_order_page'
                            disabled
                            placeholder={this._getLocalization('add_order_form.client_comments')}
                        />
                    </Form.Item>
                )}
            </div>
        );
    };

    _renderClientColumn = () => {
        const {
            selectedClient,
            fetchedOrder,
            fields,
            clientPhone,
            createOrder,
            createStatus,
            setModal,
            modals,
            isClosedStatus,
            isRTN
        } = this.props;
        const { formatMessage } = this.props.intl;
        const hasClient = clientPhone;

        return (
            <div className={Styles.bodyColumn}>
                <div className={Styles.bodyColumnContent}>
                    <div className={Styles.contentWrapper}>
                        <div className={Styles.comboFieldWrapper}>
                            <span className={Styles.clientFieldTitle}>
                                <UserOutlined />
                                <FormattedMessage id='add_order_form.client' />
                            </span>
                            <div className={Styles.comboField}>
                                {selectedClient.name || selectedClient.surname
                                    ? `${selectedClient.surname ? `${selectedClient.surname} ` : ''}${
                                          selectedClient.name
                                      }`
                                    : void 0}

                                {hasClient && (
                                    <React.Fragment>
                                        {Boolean(this.state.debt) && (
                                            <span style={{ color: this.state.debt > 0 ? 'lightcoral' : 'green' }}>
                                                {' ('}
                                                <FormattedMessage id='account_plan.balance' />
                                                {`: ${this.state.debt > 0 ? '-' : ''}`}
                                                <Numeral mask='0,0.00'>
                                                    {Math.abs(Number(this.state.debt))}
                                                </Numeral>{' '}
                                                {getCurrency()}
                                                {') '}
                                            </span>
                                        )}

                                        <Popover
                                            content={
                                                <div className={Styles.actionIconsWrap}>
                                                    {createOrder ? (
                                                        <Popconfirm
                                                            onConfirm={() => {
                                                                createOrder(
                                                                    createStatus,
                                                                    `${book.client}/${selectedClient.clientId}`
                                                                );
                                                            }}
                                                            title={<FormattedMessage id='save_order_changes' />}
                                                        >
                                                            {selectedClient.clientId && (
                                                                <Link to={`${book.client}/${selectedClient.clientId}`}>
                                                                    <EditOutlined className={Styles.icon} />
                                                                </Link>
                                                            )}
                                                        </Popconfirm>
                                                    ) : selectedClient.clientId ? (
                                                        <Link to={`${book.client}/${selectedClient.clientId}`}>
                                                            <Tooltip
                                                                placement='bottom'
                                                                title={<FormattedMessage id='edit' />}
                                                            >
                                                                <EditOutlined className={Styles.icon} />
                                                            </Tooltip>
                                                        </Link>
                                                    ) : undefined}
                                                    <CopyToClipboard text={hasClient}>
                                                        <Tooltip
                                                            placement='bottom'
                                                            title={<FormattedMessage id='copy' />}
                                                        >
                                                            <CopyOutlined className={Styles.icon} />
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                    <a
                                                        className={Styles.callLink}
                                                        href={`tel:${_.get(fields, 'clientPhone')}`}
                                                    >
                                                        <Tooltip
                                                            placement='bottom'
                                                            title={<FormattedMessage id='call' />}
                                                        >
                                                            <PhoneTwoTone className={Styles.icon} />
                                                        </Tooltip>
                                                    </a>

                                                    {!isClosedStatus && (
                                                        <Tooltip
                                                            placement='bottom'
                                                            title={
                                                                <FormattedMessage id='order_form_table.change_customer' />
                                                            }
                                                        >
                                                            <SwapOutlined
                                                                className={Styles.icon}
                                                                onClick={() => {
                                                                    const remainingSum = _.get(
                                                                        fetchedOrder,
                                                                        'order.remainingSum'
                                                                    );
                                                                    const totalSum = _.get(
                                                                        fetchedOrder,
                                                                        'order.totalSum'
                                                                    );
                                                                    const totalSumWithTax = _.get(
                                                                        fetchedOrder,
                                                                        'order.totalSumWithTax'
                                                                    );
                                                                    const newSum =
                                                                        totalSumWithTax - (totalSumWithTax - totalSum);

                                                                    if (remainingSum < newSum) {
                                                                        Modal.error({
                                                                            title: formatMessage({
                                                                                id: 'error'
                                                                            }),
                                                                            content: formatMessage({
                                                                                id: 'order_form_table.change_customer_error'
                                                                            })
                                                                        });
                                                                    } else {
                                                                        setModal(modals.ORDER_CLIENT_CHANGE);
                                                                    }
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    <Link
                                                        to={{
                                                            pathname: `${book.client}/${selectedClient.clientId}`,
                                                            state: {
                                                                specificTab: 'orders'
                                                            }
                                                        }}
                                                    >
                                                        <Tooltip
                                                            placement='bottom'
                                                            title={<FormattedMessage id='calls-table.order' />}
                                                        >
                                                            <ReadOutlined className={Styles.icon} />
                                                        </Tooltip>
                                                    </Link>
                                                </div>
                                            }
                                            placement='right'
                                            trigger='click'
                                        >
                                            <Tooltip
                                                placement='top'
                                                title={<FormattedMessage id='order_form_table.action' />}
                                            >
                                                <MenuOutlined className={Styles.actionIconsPopover} />
                                            </Tooltip>
                                        </Popover>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <Form.Item
                            initialValue={
                                _.get(fetchedOrder, 'order.clientPhone') ||
                                (this.bodyUpdateIsForbidden() ? void 0 : _.get(selectedClient, 'phones[0]'))
                            }
                            name='clientPhone'
                        >
                            <Select
                                className={`${Styles.clientCol} ${Styles.comboFieldSelect}`}
                                data-qa='select_search_client_order_page'
                                disabled={this.bodyUpdateIsForbidden() || isClosedStatus || isRTN}
                                dropdownStyle={this._clientPhoneBorderStyle}
                                placeholder={this._getLocalization('add_order_form.search_client.placeholder')}
                            >
                                {this.state.clientPhonesOptions}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            </div>
        );
    };

    _renderClientSearch = () => {
        const { user, isClosedStatus, fetchedOrder } = this.props;
        const { setFieldsValue } = this.props.form;
        const { CREATE_EDIT_DELETE_CLIENTS } = permissions;

        const disabledClientSearch =
            (!_.get(this.props, 'order.status') || _.get(this.props, 'order.status') !== 'reserve') &&
            _.get(this.props, 'order.clientId');

        return !disabledClientSearch ? (
            <div className={Styles.client}>
                <Form.Item name='searchClientQuery'>
                    <Input
                        ref={this.clientRef}
                        className={Styles.clientSearchField}
                        data-qa='input_search_client_query_order_page'
                        disabled={Boolean(disabledClientSearch) || this.bodyUpdateIsForbidden() || isClosedStatus}
                        maxLength={50}
                        placeholder={this._getLocalization('add_order_form.search_client.placeholder')}
                    />
                </Form.Item>

                <React.Fragment>
                    <PlusOutlined
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST, accesses.ROWO)}
                        onClick={() => this.props.setAddClientModal()}
                        style={{ fontSize: 22, margin: 4 }}
                    />

                    <Barcode
                        data-qa='barcode_order_page'
                        iconStyle={{
                            fontSize: 24
                        }}
                        onConfirm={async (code, prefix, fullCode) => {
                            const barcodeData = await fetchAPI('GET', 'barcodes', {
                                barcode: fullCode
                            });
                            if (barcodeData.length) {
                                const data = await fetchAPI('GET', `clients/vehicles/${barcodeData[0].referenceId}`);
                                setFieldsValue({
                                    searchClientQuery: data.vehicleVin || data.vehicleNumber
                                });
                            } else {
                                setFieldsValue({ searchClientQuery: fullCode });
                            }
                        }}
                        prefix='CVH'
                    />
                </React.Fragment>
            </div>
        ) : null;
    };

    _renderClientSearchTable = () => {
        const {
            searchClientsResult: { searching: clientsSearching, clients },
            setClientSelection,
            searchClientQuery,
            form,
            orderStatus,
            onStatusChange,
            orderBloke
        } = this.props;

        return (
            <ClientsSearchTable
                clients={clients}
                clientsSearching={clientsSearching}
                isSearchAggregates={orderBloke === 'UNIT_REPAIR' || orderBloke === 'WARRANTY_UNIT_REPAIR'}
                setClientSelection={async cl => {
                    await form.setFieldsValue({ searchClientQuery: undefined });
                    await setClientSelection(cl);
                    if (orderStatus) {
                        onStatusChange(orderStatus);
                    }
                }}
                visible={searchClientQuery && searchClientQuery.length > 2}
            />
        );
    };

    _getRecommendationStyles() {
        const { orderId: id, orderHistory } = this.props;
        const orders = _.get(orderHistory, 'orders');
        const orderIndexInHistory = _.findIndex(orders, { id });
        const prevRecommendation =
            orderIndexInHistory !== -1
                ? _.get(orderHistory, ['orders', orderIndexInHistory + 1, 'recommendation'])
                : null;

        const value = cx({
            comment: true,
            commentExtended: !prevRecommendation,
            systemCommentExtended: !prevRecommendation
        });

        return { value, prevRecommendation };
    }

    _getClientPhonesOptions() {
        return _.get(this.props, 'selectedClient.phones', [])
            .filter(Boolean)
            .map(phone => (
                <Option key={v4()} value={phone}>
                    {phone}
                </Option>
            ));
    }

    _getClientEmailLabel() {
        const { clientEmail: clipboardClientEmail } = this.props;

        return (
            <div>
                <FormattedMessage id='add_order_form.email' />
                {clipboardClientEmail && (
                    <CopyToClipboard text={clipboardClientEmail}>
                        <CopyOutlined className={Styles.copyIcon} style={{ marginLeft: 4 }} />
                    </CopyToClipboard>
                )}
            </div>
        );
    }

    fetchClientDebt = async () => {
        if (_.get(this.props, 'selectedClient.clientId')) {
            const debtAmount = await fetchAPI(
                'GET',
                '/report/debts',
                {
                    counterpartId: _.get(this.props, 'selectedClient.clientId'),
                    counterpartyType: 'client'
                },
                null,
                { handleErrorInternally: true }
            );
            const debt = _.get(debtAmount, 'stats.oweAmount.debt', 0);
            this.setState({
                debt
            });
        }
    };

    _getBusinessLocationsLabel() {
        const { fields, selectedClient, fetchedOrder, orderId, updateOrderField, user, isClosedStatus } = this.props;
        const businessLocationId =
            _.get(fields, 'businessLocationId') || _.get(fetchedOrder, 'order.businessLocationId');
        const orderSuccess = _.get(this.props, 'order.status') === 'success';

        return (
            <div>
                <FormattedMessage id='location' />
                {orderId && fetchedOrder && (
                    <VehicleLocationModal
                        clientId={_.get(selectedClient, 'clientId')}
                        currentLocation={businessLocationId}
                        disabled={(!businessLocationId && orderSuccess) || isClosedStatus}
                        hideModal={() => {
                            const businessLocationsLabel = this._getBusinessLocationsLabel();
                            this.setState({ businessLocationsLabel });
                        }}
                        onConfirm={businessLocationId =>
                            updateOrderField({ businessLocationId: businessLocationId || null })
                        }
                        orderId={orderId}
                        receiveMode={!businessLocationId}
                        returnMode={businessLocationId && orderSuccess}
                        showIcon
                        showModal={this.props.focusedRef === 'HEADER_LOCATION_ACTION'}
                        style={{ marginLeft: 4 }}
                        transferMode={businessLocationId && !orderSuccess}
                        vehicleId={_.get(fetchedOrder, 'order.clientVehicleId')}
                    />
                )}
            </div>
        );
    }

    _getClientEmailsOptions() {
        const emails = Object.values(_.get(this.props, 'selectedClient.emails', {})) || [];

        return emails.filter(Boolean).map(email => (
            <Option key={`email-${email}`} value={email}>
                {email}
            </Option>
        ));
    }

    _getClientVehiclesOptions() {
        return _.get(this.props, 'vehicles', []).map(vehicle => (
            <Option key={vehicle.id} disabled={vehicle.disabled} value={vehicle.id}>
                {formatVehicleLabel(vehicle, this.props.intl.formatMessage)}
            </Option>
        ));
    }

    _getClientAggregateOptions() {
        return _.get(this.props, 'selectedClient.aggregates', []).map(aggregate => (
            <Option
                key={aggregate.clientAggregateId}
                aggregateNumber={aggregate.aggregateNumber}
                brandName={aggregate.brandName}
                name={aggregate.name}
                value={aggregate.clientAggregateId}
            >
                {formatAggregateLabel(aggregate, this.props.intl.formatMessage)}
            </Option>
        ));
    }

    _getLocalization(key) {
        if (!this._localizationMap[key]) {
            this._localizationMap[key] = this.props.intl.formatMessage({
                id: key
            });
        }

        return this._localizationMap[key];
    }

    bodyUpdateIsForbidden() {
        return (
            !isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER, accesses.RAWO) ||
            this.props.invoiceCreated
        );
    }

    render() {
        const clientSearch = this._renderClientSearch();
        const clientColumn = this._renderClientColumn();
        const vehicleColumn = this._renderVehicleColumn();
        const comments = this._renderCommentsBlock();
        const clientsSearchTable = this._renderClientSearchTable();

        return (
            <div className={Styles.clientBlock}>
                {clientSearch}
                {clientsSearchTable}
                <div className={Styles.clientData}>
                    {clientColumn}
                    {vehicleColumn}
                </div>
                {!this.props.isRetailOrder && comments}
            </div>
        );
    }
}
