import { Catcher, Numeral } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

export default class StatsCountsPanel extends Component {
    render() {
        const { stats, extendedCounts } = this.props;

        return stats ? (
            <Catcher>
                <div className={extendedCounts ? Styles.extendedStats : Styles.stats}>
                    {Object.keys(stats).map(key => (
                        <div key={v4()} className={extendedCounts ? Styles.extendedCounts : Styles.count}>
                            <FormattedMessage id={`stats_counts_panel.${key}`} />
                            <Numeral>{stats[key]}</Numeral>
                        </div>
                    ))}
                </div>
            </Catcher>
        ) : null;
    }
}
