import { Select, Tooltip } from 'antd';
import { CASH_ORDER_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import { selectCashboxes } from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import {
    selectCashOrderType,
    selectMode,
    selectRequisiteId,
    selectSelectedCashbox,
    selectSelectedCashboxId,
    setCashOrderType,
    setRequisites
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const { Option } = Select;

const { INCOME, EXPENSE, ADJUSTMENT_INCOME } = CASH_ORDER_TYPES;

const mapStateToProps = state => ({
    cashOrderType: selectCashOrderType(state),
    selectedCashbox: selectSelectedCashbox(state),
    selectRequisiteId: selectRequisiteId(state),
    mode: selectMode(state),
    cashboxes: selectCashboxes(state),
    selectedCashboxId: selectSelectedCashboxId(state)
});

const mapDispatchToProps = {
    setCashOrderType,
    setRequisites
};

/**
 * This component serves selecting cash order type
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class RequisitesSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = { requisites: [] };
    }

    componentDidMount() {
        this.fetchBusinessesRequisites();
    }

    fetchBusinessesRequisites = async () => {
        const response = await fetchAPI('GET', '/businesses/requisites', null, null, {
            handleErrorInternally: true
        });

        this.setState({
            requisites: response
        });
    };

    render() {
        const {
            cashOrderType,
            setRequisites,
            selectRequisiteId,
            mode,
            editable,
            cashboxes,
            selectedCashboxId,
            intl: { formatMessage }
        } = this.props;

        const { requisites } = this.state;

        return (
            <Tooltip placement='top' title={<FormattedMessage id='navigation.requisites' />} zIndex={2001}>
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={value => {
                        if (!value) {
                            setRequisites(null);
                        } else {
                            setRequisites(value);
                        }
                    }}
                    placeholder={formatMessage({
                        id: 'navigation.requisites'
                    })}
                    value={selectRequisiteId}
                >
                    {requisites
                        .filter(({ enabled }) => enabled)
                        .map((elem, i) => {
                            return (
                                <Option key={i} value={elem.id}>
                                    {elem.name}
                                    {elem.isTaxPayer && (
                                        <span
                                            style={{
                                                marginLeft: 8,
                                                color: 'var(--text2)'
                                            }}
                                        >
                                            (<FormattedMessage id='with_VAT' />)
                                        </span>
                                    )}
                                </Option>
                            );
                        })}
                </Select>
            </Tooltip>
        );
    }
}
