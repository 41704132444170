import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Select } from 'antd';
import { onChangeProfileForm, submitProfileFormOther } from 'core/forms/profileForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getBusinessType } from 'utils';
import Styles from './styles.less';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    colon: false
};

const mapDispatchToProps = {
    onChangeProfileForm,
    submitProfileFormOther
};

const mapStateToProps = state => {
    return {
        user: state.auth,
        profileUpdating: state.ui.profileUpdating
    };
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class OtherTabSettings extends Component {
    componentDidMount() {
        this.fetchAdditionalData();
    }

    _submitProfile = values => {
        const { submitProfileFormOther, onChangeProfileForm } = this.props;
        submitProfileFormOther(values);
        // onChangeProfileForm();
    };

    fetchAdditionalData = async () => {
        // await fetchAPI('GET', 'businesses');
    };

    render() {
        const {
            user,
            isDisabledCRUD,
            intl: { formatMessage }
        } = this.props;

        const businessType = getBusinessType();

        return (
            <Form
                className={Styles.profileForm}
                disabled={isDisabledCRUD}
                initialValues={{
                    businessTypes: businessType || 'DEFAULT',
                    packageStatuses: _.get(user, 'packageListDetailStatuses[0]'),
                    incomeOnlyWithCell: user.incomeOnlyWithCell,
                    orderStatusNewVersion: user.orderStatusNewVersion,
                    callOrderAutoCreate: user.callOrderAutoCreate,
                    autoReserve: user.autoReserve
                }}
                labelCol={formItemLayout.labelCol}
                name='profileForm'
                onFinish={this._submitProfile}
                wrapperCol={formItemLayout.wrapperCol}
            >
                <Form.Item
                    label={<FormattedMessage id='profile-form.business_types' />}
                    name='businessTypes'
                    tooltip={{
                        title: formatMessage({
                            id: 'profile-form.relogin_required'
                        }),
                        icon: <InfoCircleOutlined />
                    }}
                >
                    <Select
                        getPopupContainer={trigger => trigger.parentNode}
                        placeholder={formatMessage({
                            id: 'profile-form.business_types'
                        })}
                    >
                        <Option value='DEFAULT'>СТО</Option>
                        <Option value='TIRE_STATION'>Шиномонтаж</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label={<FormattedMessage id='order_form_table.income_is_only_by_cell' />}
                    name='incomeOnlyWithCell'
                    tooltip={{
                        title: formatMessage({
                            id: 'profile-form.relogin_required'
                        }),
                        icon: <InfoCircleOutlined />
                    }}
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='auto_reserve' />}
                    name='autoReserve'
                    tooltip={{
                        title: formatMessage({
                            id: 'auto_reserve_message'
                        }),
                        icon: <InfoCircleOutlined />
                    }}
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='use_new_statuses' />}
                    name='orderStatusNewVersion'
                    tooltip={{
                        title: formatMessage({
                            id: 'profile-form.relogin_required'
                        }),
                        icon: <InfoCircleOutlined />
                    }}
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='order_form_table.create_order_by_call' />}
                    name='callOrderAutoCreate'
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    className={Styles.itemBtn}
                    wrapperCol={{
                        offset: 11,
                        span: 12
                    }}
                >
                    <Button className={Styles.saveBtn} htmlType='submit' type='primary'>
                        <FormattedMessage id='save' />
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
