import { MenuOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { Catcher } from 'commons';
import React, { Component } from 'react';
import ReactDragListView from 'react-drag-listview';

export default class DraggableTable extends Component {
    addDragColumn = columns => [
        ...columns,
        {
            key: 'drag',
            align: 'center',
            width: 36,
            render: () => <Button icon={<MenuOutlined />} size='small' type='text' />
        }
    ];

    render() {
        const { onDragEnd, addDragColumn, columns, ...props } = this.props;

        return (
            <Catcher>
                <ReactDragListView
                    // handleSelector={addDragColumn ? 'q' : null}
                    onDragEnd={onDragEnd}
                >
                    <Table
                        bordered
                        columns={addDragColumn ? this.addDragColumn(columns) : columns}
                        size='small'
                        {...props}
                    />
                </ReactDragListView>
            </Catcher>
        );
    }
}
