/* eslint-disable default-case */
import { Col, Row } from 'antd';
import {
    AnalyticsSelector,
    AuthCodeInput,
    CardMaskInput,
    CashInput,
    CashOrderTypeSelector,
    CashboxSelector,
    CashlessPaymentTypeMeansInput,
    CashlessPaymentTypeSelect,
    ClientCounterpartyContainer,
    CommentInput,
    CounterPartySelector,
    DateSelector,
    EmployeeCounterpartyContainer,
    OtherCounterpartyContainer,
    PaymentSystemSelect,
    PaymentTypeSelector,
    ReceiptNoInput,
    RemainingCashInput,
    RequisitesSelector,
    RrnInput,
    SumInput,
    SupplierCounterpartyContainer
} from 'modals/RefactoredCashOrderModal/components';
import { COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectCashboxes,
    selectCashlessPaymentType,
    selectPaymentType,
    selectSelectedCashboxId,
    selectSelectedCounterpartyType
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    selectedCounterpartyType: selectSelectedCounterpartyType(state),
    selectPaymentType: selectPaymentType(state),
    selectCashlessPaymentType: selectCashlessPaymentType(state),

    selectedCashboxId: selectSelectedCashboxId(state),

    cashboxes: selectCashboxes(state)
});

const mapDispatchToProps = {};

/**
 * This form is used to create ordinary cash order.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class DefaultForm extends Component {
    render() {
        const {
            selectedCounterpartyType,
            isMobile,
            editable,
            analyticsUniqueIdForEmployee,
            selectPaymentType,
            selectCashlessPaymentType,
            cashboxes,
            selectedCashboxId
        } = this.props;
        const span = isMobile ? 24 : 12;

        const cashbox = cashboxes.find(({ id }) => id === selectedCashboxId) || {};
        const { rst, type } = cashbox;

        // Select which counterparty has to be rendered depending on a value(selectedCounterparty) from redux
        let currentCounterpartyContainer = null;
        switch (selectedCounterpartyType) {
            case COUNTERPARTY_TYPES.CLIENT:
                currentCounterpartyContainer = <ClientCounterpartyContainer editable={editable} isMobile={isMobile} />;
                break;
            case COUNTERPARTY_TYPES.EMPLOYEE:
                currentCounterpartyContainer = <EmployeeCounterpartyContainer editable={editable} />;
                break;
            case COUNTERPARTY_TYPES.BUSINESS_SUPPLIER:
                currentCounterpartyContainer = <SupplierCounterpartyContainer editable={editable} />;
                break;
            case COUNTERPARTY_TYPES.OTHER:
                currentCounterpartyContainer = <OtherCounterpartyContainer editable={editable} />;
                break;
        }

        return (
            <React.Fragment>
                <div>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={span}>
                            <CashOrderTypeSelector editable={editable} />
                        </Col>
                        <Col className={Styles.col} span={span}>
                            <CashboxSelector editable={editable} />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={span}>
                            <DateSelector editable={editable} />
                        </Col>
                        <Col className={Styles.col} span={span}>
                            <AnalyticsSelector
                                analyticsUniqueIdForEmployee={analyticsUniqueIdForEmployee}
                                editable={editable}
                            />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={span}>
                            <RequisitesSelector editable={editable} />
                        </Col>
                        <Col className={Styles.col} span={span}>
                            <SumInput editable={editable} />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={span}>
                            {rst && <PaymentTypeSelector editable={editable} />}
                        </Col>

                        {(selectPaymentType === 'CASH' ||
                            (type === 'CASH' && !rst && this.props.cashOrderType === 'INCOME')) && (
                            <Col className={Styles.col} span={span}>
                                <CashInput editable={editable} />
                            </Col>
                        )}
                        <Col className={Styles.col} span={span}>
                            {rst && selectPaymentType === 'CASHLESS' && (
                                <CashlessPaymentTypeSelect editable={editable} />
                            )}
                        </Col>
                    </Row>

                    {rst && selectPaymentType === 'CASHLESS' && selectCashlessPaymentType === 'POS_TERMINAL' && (
                        <Row className={Styles.row}>
                            <Col className={Styles.col} span={4}>
                                <ReceiptNoInput editable={editable} />
                            </Col>
                            <Col className={Styles.col} span={5}>
                                <PaymentSystemSelect editable={editable} />
                            </Col>
                            <Col className={Styles.col} span={5}>
                                <CardMaskInput editable={editable} />
                            </Col>
                            <Col className={Styles.col} span={5}>
                                <AuthCodeInput editable={editable} />
                            </Col>
                            <Col className={Styles.col} span={5}>
                                <RrnInput editable={editable} />
                            </Col>
                        </Row>
                    )}

                    {rst &&
                        selectPaymentType === 'CASHLESS' &&
                        ['ACQUIRING', 'SERVICE'].includes(selectCashlessPaymentType) && (
                            <Row className={Styles.row}>
                                <Col className={Styles.col} span={span}></Col>
                                <Col className={Styles.col} span={span}>
                                    <CashlessPaymentTypeMeansInput editable={editable} />
                                </Col>
                            </Row>
                        )}

                    {(selectPaymentType === 'CASH' ||
                        (type === 'CASH' && !rst && this.props.cashOrderType === 'INCOME')) && (
                        <Row className={Styles.row}>
                            {/* <Col className={Styles.col} span={span}>
                                <CashInput editable={editable} />
                            </Col> */}
                            <Col className={Styles.col} span={span}></Col>
                            <Col className={Styles.col} span={span}>
                                <RemainingCashInput editable={editable} />
                            </Col>
                        </Row>
                    )}

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <CounterPartySelector editable={editable} />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            {currentCounterpartyContainer}
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <CommentInput editable={editable} />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}
