import { InputNumber, Modal, Select, notification } from 'antd';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const EditLaborPercent = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        edit,

        fetchCrews,
        crewStats,
        crewStatsSingle,
        updateDataSource
    } = props;

    const [crewName, setCrewName] = useState();
    const [crewStatus, setCrewStatus] = useState('ACTIVE');
    const [crewMembers, setCrewMembers] = useState();
    const [allEmployees, setEmployees] = useState([]);

    const getAllEmployees = useCallback(async query => {
        const response = await fetchAPI('GET', 'employees', { query }, null, {
            handleErrorInternally: true
        });

        setEmployees(
            response && response.filter(({ posts, postref }) => posts.find(({ postName }) => postName === 'MECHANIC'))
        );
    }, []);

    const handleSearchEmployees = useMemo(() => {
        return _.debounce(getAllEmployees, 800);
    }, [getAllEmployees]);

    const handleEmployeeChange = (index, employeeId, percent, id) => {
        const newCrewMemeber = [...crewMembers];
        newCrewMemeber[index].employeeId = employeeId;
        newCrewMemeber[index].percent = percent;

        setCrewMembers(newCrewMemeber);
    };

    useEffect(() => {
        if (crewStats && get(crewStats, 'length')) {
            setCrewMembers(crewStats[0]);
        }

        if (crewStatsSingle && get(crewStatsSingle, 'length')) {
            setCrewMembers(crewStatsSingle);
        }
    }, [crewStats, crewStatsSingle]);

    useEffect(() => {
        if (open && !get(allEmployees, 'length')) {
            getAllEmployees();
        }
    }, [allEmployees, getAllEmployees, open]);

    const onClose = () => {
        setCrewMembers([]);

        hideModal();
    };

    const handleCrewPercent = async () => {
        const updateData = crewMembers.map(({ employee, ...rest }) => rest);
        const restCrewMembers = crewStats && crewStats.filter((elem, index) => index !== 0).flat();

        const updatedDataRest =
            restCrewMembers &&
            restCrewMembers.map(({ employee, ...item }) => {
                const findPercent = updateData.find(({ crewMemberId }) => crewMemberId === item.crewMemberId);

                return {
                    ...item,
                    percent: findPercent && findPercent.percent
                };
            });

        try {
            await fetchAPI(
                'PUT',
                '/crew/labors',
                undefined,

                [...updateData, ...updatedDataRest],
                { handleErrorInternally: true }
            );

            updateDataSource();
            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const handleCrewPercentSingle = async () => {
        const updateData = crewMembers.map(({ employee, ...rest }) => rest);

        try {
            await fetchAPI(
                'PUT',
                '/crew/labors',
                undefined,

                [...updateData],
                { handleErrorInternally: true }
            );

            updateDataSource();
            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const putCrewMember = async payload => {
        try {
            await fetchAPI(
                'PUT',
                '/crew/members',
                undefined,
                [{ crewId: payload.crewId, employeeId: payload.employeeId, percent: payload.percent, id: payload.id }],
                { handleErrorInternally: true }
            );

            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const handleDebounceSavePercent = useMemo(() => {
        const loadOptions = value => {
            putCrewMember(value);
        };

        return _.debounce(loadOptions, 1000);
    }, []);

    // useEffect(() => {
    //     if (open) {
    //         setPhones([]);
    //         setPhones([{ code: '', number: '', country: 'ua' }]);
    //     }
    // }, [open]);

    const onFinish = async () => {
        if (get(crewStatsSingle, 'length')) {
            handleCrewPercentSingle();
        } else {
            handleCrewPercent();
        }

        await onClose();
    };

    const totalPercentage = crewMembers
        ? crewMembers.reduce((accumulator, currentObject) => {
              return accumulator + currentObject.percent;
          }, 0)
        : 0;

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: totalPercentage !== 100
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={<FormattedMessage id='crews.edit_crew' />}
            width='50%'
        >
            <div>
                <div className={Styles.margin}>
                    {crewMembers && open
                        ? crewMembers.map((elem, index) => {
                              return (
                                  <div>
                                      <div>
                                          {index + 1}. <FormattedMessage id='employee' />{' '}
                                          {/* <span className={Styles.red}>*</span> */}
                                      </div>

                                      <div
                                          style={{
                                              marginBottom: 8,
                                              display: 'flex',
                                              justifyContent: 'space-between'
                                          }}
                                      >
                                          <Select
                                              disabled
                                              onChange={value => handleEmployeeChange(index, value, elem.percent)}
                                              onSearch={input => handleSearchEmployees(input)}
                                              onSelect={() => {
                                                  if (elem.employeeId && elem.percent && elem.id && edit) {
                                                      putCrewMember(elem);
                                                  }
                                                  //   if (elem.employeeId && elem.percent && !elem.id && !edit) {
                                                  //       postCrewMembers(elem);
                                                  //   }
                                              }}
                                              optionFilterProp='children'
                                              placeholder={formatMessage({ id: 'employee' })}
                                              showSearch
                                              style={{
                                                  width: '70%',
                                                  marginRight: 6
                                              }}
                                              value={elem.employeeId}

                                              // formatter={value => phoneNumberFormatter(value, user.country)}
                                          >
                                              {allEmployees
                                                  .filter(({ disabled }) => !disabled)
                                                  .map(({ id, name, surname }) => (
                                                      <Option key={id} value={id}>
                                                          {`${name} ${surname}`}
                                                      </Option>
                                                  ))}
                                          </Select>
                                          <InputNumber
                                              formatter={value => `${value}%`}
                                              max={100}
                                              min={1}
                                              onChange={async value => {
                                                  await handleEmployeeChange(index, elem.employeeId, value);

                                                  if (elem.employeeId && elem.percent && elem.id && edit) {
                                                      handleDebounceSavePercent(elem);
                                                  }
                                                  //   if (elem.employeeId && elem.percent && !elem.id && edit) {
                                                  //       handleDebouncePostPercent(elem);
                                                  //   }
                                              }}
                                              parser={value => value.replace('%', '')}
                                              style={{
                                                  width: '30%'
                                              }}
                                              value={elem.percent}
                                          />
                                      </div>
                                  </div>
                              );
                          })
                        : undefined}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end'
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='crews.total_percentage' />
                        </span>
                        : {totalPercentage} %
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(EditLaborPercent);
