import { IdcardOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { DebounceSelect } from 'components/DebouncedSelect';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { DeliveryTruckIcon } from 'theme';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const EMPLOYEE = 'EMPLOYEE';
const SUPPLIER = 'SUPPLIER';
const CREW = 'CREW';

const EmployeeSupplierSelector = ({
    value,
    onChange = () => {},
    intl: { formatMessage },
    initialState,
    servicesStyle,
    changeOptions,
    employeesOptions,
    suppliersOptions,
    crews,
    disabled,
    requestModal,
    ...props
}) => {
    const initialType = get(initialState, 'type', EMPLOYEE);
    const [type, setType] = useState(initialType);

    useEffect(() => {
        setType(initialType);
    }, [initialType]);

    const typeMap = useMemo(
        () => ({
            [EMPLOYEE]: {
                icon: <IdcardOutlined />,
                request: async query => {
                    const employees = await fetchAPI('GET', 'employees', { query }, null, {
                        handleErrorInternally: true
                    });

                    return employees
                        .filter(
                            ({ posts, disabled }) =>
                                !disabled && posts.map(({ postName }) => postName).includes('MECHANIC')
                        )
                        .map(({ name, surname, id }) => ({
                            value: id,
                            label: `${surname || ''} ${name || ''}`
                        }));
                },
                placeholder: formatMessage({ id: 'employee' })
            },

            [SUPPLIER]: {
                icon: <DeliveryTruckIcon />,
                request: async query => {
                    const suppliers = await fetchAPI(
                        'GET',
                        'business_suppliers',
                        {
                            all: true,
                            cut: true,
                            query
                        },
                        null,
                        { handleErrorInternally: true }
                    );

                    return suppliers.map(({ name, id }) => ({
                        value: id,
                        label: name
                    }));
                },
                placeholder: formatMessage({ id: 'supplier' })
            },
            [CREW]: {
                icon: <TeamOutlined />,
                request: async query => {
                    const res = await fetchAPI(
                        'GET',
                        'crews',
                        {
                            filters: {
                                crewName: query || undefined
                            }
                        },
                        null,
                        { handleErrorInternally: true }
                    );

                    return (
                        res &&
                        res.crews
                            .filter(({ crewMembers }) => get(crewMembers, 'length'))
                            .map(({ crewName, id }) => ({
                                value: id,
                                label: crewName
                            }))
                    );
                },
                placeholder: formatMessage({ id: 'crews.crew' })
            }
        }),
        [formatMessage]
    );

    const handleSearchByType = query => typeMap[type || EMPLOYEE].request(query);

    const swapType = useCallback(() => {
        let tp = EMPLOYEE;
        if (type === EMPLOYEE) {
            setType(SUPPLIER);
            tp = SUPPLIER;
        } else if (type === 'SUPPLIER') {
            setType(!requestModal ? CREW : EMPLOYEE);
            tp = !requestModal ? CREW : EMPLOYEE;
        } else if (type === 'CREW') {
            setType(EMPLOYEE);
            tp = EMPLOYEE;
        }
        if (value) onChange(undefined, tp || EMPLOYEE);
    }, [type, value]);

    const handleChange = (value, option) => {
        onChange(value, type || EMPLOYEE, option);
    };

    return (
        <DebounceSelect
            allowClear
            className={Styles.employeeSelector}
            crews={crews}
            disabled={disabled}
            employeesOptions={employeesOptions}
            fetchOptions={handleSearchByType}
            filterOption={false}
            initialState={initialState}
            labelRender={({ label }) => {
                return (
                    <Space title={label}>
                        <Button
                            disabled={disabled}
                            icon={get(typeMap, `${[type || EMPLOYEE]}.icon`, null)}
                            onClick={swapType}
                            style={{ padding: 0, height: 'auto', width: 'auto' }}
                            title={get(typeMap, `${[type || EMPLOYEE]}.placeholder`, 'No data')}
                            type='text'
                        />
                        {label}
                    </Space>
                );
            }}
            onChange={handleChange}
            placeholder={get(typeMap, `${[type || EMPLOYEE]}.placeholder`, 'No data')}
            popupMatchSelectWidth={false}
            servicesStyle={servicesStyle}
            showSearch
            suffixIcon={
                !value && (
                    <Space>
                        <Button
                            disabled={disabled}
                            icon={get(typeMap, `${[type || EMPLOYEE]}.icon`, null)}
                            onClick={swapType}
                            style={{ padding: 0, height: 'auto', width: 'auto' }}
                            title={get(typeMap, `${[type || EMPLOYEE]}.placeholder`, 'No data')}
                            type='text'
                        />
                    </Space>
                )
            }
            suppliersOptions={suppliersOptions}
            type={type}
            value={value}
        />
    );
};

export default injectIntl(EmployeeSupplierSelector);
