/* eslint-disable complexity */
import { CarFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Catcher } from 'commons';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import withDnDropContext from 'utils/withDnDContext.js';
import { DashboardAddOrderCell, DashboardAddOrderLink } from './DashboardAddOrderLink';
import DashboardEmptyCell from './DashboardEmptyCell';
import DashboardOrder from './DashboardOrder';
import DashboardTimeline from './DashboardTimeline';
import getBeginDatetime from './dashboardCore/getBeginDatetime';
import mapOrders from './dashboardCore/mapOrders';
import ordersPuzzle from './dashboardCore/ordersPuzzle';
import {
    Dashboard,
    DashboardAddOrderColumn,
    DashboardBody,
    DashboardColumn,
    DashboardContentBox,
    DashboardContentColumn,
    DashboardGrid,
    DashboardHead,
    DashboardLoad,
    DashboardTimeCell,
    DashboardTitle
} from './styled.js';

class DashboardContainer extends Component {
    constructor(props) {
        super(props);
        this._dashboardRef = React.createRef();

        this.state = {
            currentDay: dayjs().format('YYYY-MM-DD'),
            // dashboardRef: this._dashboardRef,
            hideSourceOnDrag: true
            // mode:             props.mode === 'calendar',
        };

        this.currentDayRef = null;

        this.currentDayRefCallback = element => {
            this.currentDayRef = element;
            if (element) {
                element.scrollIntoView({ block: 'end', inline: 'end' });
                const x = window.scrollX;
                window.scroll(x, 0);
                // window.scrollTo(0, element.offsetTop);
                // element.scrollTo({ top: 100, left: 100, behavior: 'smooth'})
            }
        };
    }

    _hideSourceOnDrag = () => this.setState({ hideSourceOnDrag: !this.state.hideSourceOnDrag });

    render() {
        const { dashboard, schedule, mode } = this.props;
        const timeColumn = this._renderTimeColumn();
        const dashboardColumns = this._renderDashboardColumns();
        const dashboardGhostColumns = this._renderDashboardGhostColumns();

        return (
            <Catcher>
                <Dashboard ref={this._dashboardRef} className='withScroll'>
                    <DashboardTimeline schedule={schedule} />
                    {timeColumn}
                    <DashboardGrid columns={dashboard.columns < 7 ? 7 : dashboard.columns}>
                        {dashboardColumns}
                        {(mode === 'stations' || mode === 'employees') &&
                            dashboard.columns < 7 &&
                            window.innerWidth >= 1024 &&
                            dashboardGhostColumns}
                    </DashboardGrid>
                </Dashboard>
            </Catcher>
        );
    }

    _linkToStations = day => this.props.linkToDashboardStations(day);

    _linkToEmployees = day => this.props.linkToDashboardEmployees(day);

    _renderTimeColumn = () => {
        const { dashboard, time } = this.props;

        return (
            <DashboardColumn className='timeColumn' column={1} dashboard={dashboard} time>
                <DashboardHead />
                {time.map(time => (
                    <React.Fragment key={time}>
                        <DashboardTimeCell>{time}</DashboardTimeCell>
                        <DashboardTimeCell />
                    </React.Fragment>
                ))}
            </DashboardColumn>
        );
    };

    _renderDashboardColumns = () => {
        const { dashboard, days, stations, employees, load, mode, user } = this.props;
        const { currentDay } = this.state;

        const columnsArr = [
            ...(mode === 'stations' && dashboard.columns && user.zeroDashboardsPost ? [-1] : []),
            ...Array(dashboard.columns).keys()
        ];

        return columnsArr.map((index, _) => {
            if (index >= 0) {
                const day = mode === 'calendar' ? days[index] : null;
                const selectedDay = this.props.date.format('YYYY-MM-DD');
                let employeeLoadCoefficient = 0;
                if (mode === 'employees') {
                    const employeeLoad = load.find(elem => elem.employeeId == employees[index].id);
                    employeeLoadCoefficient = employeeLoad ? employeeLoad.loadCoefficient : 0;
                }

                return (
                    <DashboardColumn
                        data-qa='dashboard_column'
                        key={index}
                        ref={currentDay && currentDay === day ? this.currentDayRefCallback : null}
                        column={1}
                        currentDay={selectedDay}
                        dashboard={dashboard}
                        day={day}
                    >
                        <DashboardHead column={1} dashboard={dashboard}>
                            {load.length && (
                                <React.Fragment>
                                    <DashboardTitle>
                                        {mode === 'calendar' ? (
                                            <FormattedMessage id={load[index].dayName} />
                                        ) : mode === 'employees' ? (
                                            employees[index].employeeNum
                                        ) : (
                                            load[index].stationNum
                                        )}
                                    </DashboardTitle>
                                    <DashboardLoad
                                        link={mode === 'calendar'}
                                        loadCoefficient={
                                            mode === 'employees' ? employeeLoadCoefficient : load[index].loadCoefficient
                                        }
                                        onClick={() => this._linkToStations(days[index])}
                                    >
                                        {mode === 'calendar'
                                            ? `${dayjs(load[index].beginDate).format('DD MMM')} -`
                                            : mode == 'employees'
                                            ? `${employees[index].name} ${employees[index].surname} - `
                                            : stations[index].name && `${stations[index].name} - `}
                                        {mode == 'employees'
                                            ? Math.round(employeeLoadCoefficient)
                                            : Math.round(load[index].loadCoefficient)}
                                        %
                                    </DashboardLoad>
                                </React.Fragment>
                            )}
                        </DashboardHead>
                        <DashboardBody>
                            {this._renderDashboardContentColumn(index)}
                            {this._renderDashboardAddOrderColumn(index, day)}
                        </DashboardBody>
                    </DashboardColumn>
                );
            }
            if (index === -1) {
                const day = null;
                const selectedDay = this.props.date.format('YYYY-MM-DD');

                return (
                    <DashboardColumn
                        key={index}
                        ref={currentDay && currentDay === day ? this.currentDayRefCallback : null}
                        column={1}
                        currentDay={selectedDay}
                        dashboard={dashboard}
                        day={day}
                    >
                        <DashboardHead column={1} dashboard={dashboard}>
                            {load.length && (
                                <React.Fragment>
                                    <DashboardTitle>0</DashboardTitle>
                                    <DashboardLoad loadCoefficient={0}>
                                        <FormattedMessage id='dashboard-container.title' />
                                    </DashboardLoad>
                                </React.Fragment>
                            )}
                        </DashboardHead>
                        <DashboardBody>
                            {this._renderDashboardContentColumn(index)}
                            {this._renderDashboardAddOrderColumn(index, day)}
                        </DashboardBody>
                    </DashboardColumn>
                );
            }
        });
    };

    _renderDashboardContentColumn = column => {
        const {
            dashboard,
            days,
            stations,
            employees,
            orders,
            schedule,
            updateDashboardOrder,
            updateDashboardService,
            date,
            mode,
            user,
            daysWithConflicts,
            stationsWithConflicts,
            employeesWithConflicts,
            selectedOrderId
        } = this.props;
        // const { hideSourceOnDrag } = this.state;
        if (column === -1) {
            const dashboardData = (orders || []).filter(
                ({ stationNum, status }) => !stationNum && status !== 'required'
            );

            const mappedOrders = mapOrders(schedule.beginHour, dashboard.rows, dashboardData);

            const puzzle = ordersPuzzle(mappedOrders, dashboard.rows);

            return (
                <DashboardContentColumn dashboard={dashboard}>
                    {puzzle.map(({ data: { result, maxRows, maxBlocks } }, index) => (
                        <DashboardContentBox key={index} columns={maxBlocks} dashboard={dashboard} rows={maxRows}>
                            {result.map((order, index) =>
                                order.empty ? (
                                    <DashboardEmptyCell
                                        key={index}
                                        day={date.format('YYYY-MM-DD')}
                                        daysWithConflicts={daysWithConflicts}
                                        employeeId={_.get(employees, `[${column}].id`)}
                                        employeesWithConflicts={employeesWithConflicts}
                                        mode={mode}
                                        stationNum={column + 1}
                                        stationsWithConflicts={stationsWithConflicts}
                                        {...order}
                                    />
                                ) : (
                                    <DashboardOrder
                                        key={index}
                                        dashboardRef={this._dashboardRef}
                                        day={mode === 'calendar' ? days[column] : date.format('YYYY-MM-DD')}
                                        dropOrder={mode === 'employees' ? updateDashboardService : updateDashboardOrder}
                                        employeeId={result[index].options.employeeId}
                                        id={result[index].options.stationLoadId}
                                        label={{
                                            vehicleMakeName: result[index].options.vehicleMakeName,
                                            vehicleModelName: result[index].options.vehicleModelName,
                                            vehicleNumber: result[index].options.vehicleNumber,
                                            clientName: result[index].options.clientName,
                                            aggregateName: result[index].options.aggregateName,
                                            aggregateNumber: result[index].options.aggregateNumber,
                                            clientSurname: result[index].options.clientSurname,
                                            stationName: result[index].options.stationName,
                                            colorIcon:
                                                result[index].options.colorCode !== '' || result[index].options.colorCode !== null ?
                                                    <CarFilled
                                                        style={{
                                                            display: result[index].options.colorCode === '' || result[index].options.colorCode === null ? 'none' : undefined,
                                                            color: result[index].options.colorCode,
                                                            backgroundColor: '#e3e3e3',
                                                            fontSize: 10,
                                                            borderRadius: 16
                                                        }}
                                                    />
                                                : undefined,
                                            drawerIcon: <InfoCircleOutlined onClick={() =>
                                                this.setState({
                                                    drawerOpenId: true
                                                        })
                                                    }
                                                />,
                                            
                                            laborName: result[index].options.laborName
                                        }}
                                        orders={orders}
                                        stationNum={_.get(stations, `[${column}].num`)}
                                        status={
                                            !selectedOrderId
                                                ? result[index].options.status
                                                : selectedOrderId == result[index].options.orderId
                                                ? 'selected'
                                                : result[index].options.status
                                        }
                                        subStatusIndications={result[index].options.subStatusIndications}
                                        user={user}
                                        mode={mode}
                                        // hideSourceOnDrag={ hideSourceOnDrag }
                                        schedule={schedule}
                                        {...order}
                                    />
                                )
                            )}
                        </DashboardContentBox>
                    ))}
                </DashboardContentColumn>
            );
        }

        const dashboardMode = mode === 'calendar';

        const columnsData = mode === 'calendar' ? days : mode === 'employees' ? employees : stations;

        const columnId = dashboardMode
            ? columnsData
                ? columnsData[column]
                : null
            : mode === 'employees'
            ? columnsData
                ? columnsData[column].id
                : null
            : columnsData
            ? columnsData[column].num
            : null;

        const dashboardData = dashboardMode
            ? (orders || []).filter(
                  ({ beginDatetime, status }) =>
                      dayjs(beginDatetime).format('YYYY-MM-DD') === columnId && status != 'required'
              )
            : mode === 'employees'
            ? (orders || []).filter(({ employeeId, status }) => employeeId === columnId && status != 'required')
            : (orders || []).filter(({ stationNum, status }) => stationNum === columnId && status != 'required');

        const mappedOrders = mapOrders(schedule.beginHour, dashboard.rows, dashboardData);

        const puzzle = ordersPuzzle(mappedOrders, dashboard.rows);

        return (
            <DashboardContentColumn dashboard={dashboard}>
                {puzzle.map(({ data: { result, maxRows, maxBlocks } }, index) => (
                    <DashboardContentBox key={index} columns={maxBlocks} dashboard={dashboard} rows={maxRows}>
                        {result.map((order, index) =>
                            order.empty ? (
                                <DashboardEmptyCell
                                    key={index}
                                    day={dashboardMode ? days[column] : date.format('YYYY-MM-DD')}
                                    daysWithConflicts={daysWithConflicts}
                                    employeeId={_.get(employees, `[${column}].id`)}
                                    employeesWithConflicts={employeesWithConflicts}
                                    mode={mode}
                                    stationNum={!dashboardMode ? _.get(stations, `[${column}].num`) : column + 1}
                                    stationsWithConflicts={stationsWithConflicts}
                                    {...order}
                                />
                            ) : (
                                <DashboardOrder
                                    key={index}
                                    dashboardRef={this._dashboardRef}
                                    day={mode === 'calendar' ? days[column] : date.format('YYYY-MM-DD')}
                                    dropOrder={mode === 'employees' ? updateDashboardService : updateDashboardOrder}
                                    employeeId={result[index].options.employeeId}
                                    id={result[index].options.stationLoadId}
                                    label={{
                                        
                                        vehicleMakeName: result[index].options.vehicleMakeName,
                                        vehicleModelName: result[index].options.vehicleModelName,
                                        vehicleNumber: result[index].options.vehicleNumber,
                                        clientName: result[index].options.clientName,
                                        clientSurname: result[index].options.clientSurname,
                                        stationName: result[index].options.stationName,
                                        aggregateName: result[index].options.aggregateName,
                                        aggregateNumber: result[index].options.aggregateNumber,
                                        colorIcon:
                                                result[index].options.colorCode !== '' ||
                                                result[index].options.colorCode !== null ?
                                                    <CarFilled
                                                        style={{
                                                            display: result[index].options.colorCode === '' || result[index].options.colorCode === null ? 'none' : undefined,
                                                            color: result[index].options.colorCode,
                                                            backgroundColor: '#e3e3e3',
                                                            fontSize: 10,
                                                            borderRadius: 16
                                                        }}
                                                    />
                                                : undefined,

                                                drawerIcon: <InfoCircleOutlined onClick={() =>
                                                    this.setState({
                                                        drawerOpenId: true
                                                            })
                                                        }
                                                    />,
                                                
                                            laborName: result[index].options.laborName,
                                        
                                    }}
                                    orders={orders}
                                    stationNum={
                                        mode == 'calendar'
                                            ? result[index].options.stationNum
                                            : _.get(stations, `[${column}].num`)
                                    }
                                    status={
                                        !selectedOrderId
                                            ? result[index].options.status
                                            : selectedOrderId == result[index].options.orderId
                                            ? 'selected'
                                            : result[index].options.status
                                    }
                                    subStatusIndications={result[index].options.subStatusIndications}
                                    user={user}
                                    mode={mode}
                                    // hideSourceOnDrag={ hideSourceOnDrag }
                                    schedule={schedule}
                                    {...order}
                                />
                            )
                        )}
                    </DashboardContentBox>
                ))}
            </DashboardContentColumn>
        );
    };

    _renderDashboardAddOrderColumn = (column, day) => {
        const {
            dashboard,
            days,
            date,
            stations,
            employees,
            schedule,
            mode,
            user,
            daysWithConflicts,
            stationsWithConflicts,
            employeesWithConflicts,
            onStationSelect,
            alternativeAction
        } = this.props;

        if (column === -1) {
            const setBeginDateitme = index => getBeginDatetime(date.format('YYYY-MM-DD'), index, schedule.beginHour);

            const showLink = isGrantAccessed(user, grants.OPERATIONS_PLANNER_1D_AND_7D_PLANNER, accesses.ROWO);

            return (
                <DashboardAddOrderColumn
                    dashboard={dashboard}
                    day={day}
                    daysWithConflicts={daysWithConflicts}
                    employeesWithConflicts={employeesWithConflicts}
                    mode={mode}
                    stationNum={0}
                    stationsWithConflicts={stationsWithConflicts}
                >
                    {[...Array(dashboard.rows).keys()].map((_, index) => (
                        <DashboardAddOrderCell key={index}>
                            {showLink ? (
                                <DashboardAddOrderLink
                                    alternativeAction={alternativeAction}
                                    day={day}
                                    employeeId={mode == 'employees' && employees[column].id}
                                    onStationSelect={mode == 'stations' && onStationSelect}
                                    stationNum={0}
                                    time={setBeginDateitme(index)}
                                />
                            ) : null}
                        </DashboardAddOrderCell>
                    ))}
                </DashboardAddOrderColumn>
            );
        }

        const dashboardMode = mode === 'calendar';

        const setBeginDateitme = index => {
            if (mode !== 'calendar') {
                return getBeginDatetime(date.format('YYYY-MM-DD'), index, schedule.beginHour);
            }

            return getBeginDatetime(days[column], index, schedule.beginHour);
        };

        const showLink = isGrantAccessed(user, grants.OPERATIONS_PLANNER_1D_AND_7D_PLANNER, accesses.ROWO);

        return (
            <DashboardAddOrderColumn
                data-qa='dashboard_btn_plus'
                dashboard={dashboard}
                day={day}
                daysWithConflicts={daysWithConflicts}
                employeeId={mode == 'employees' && employees[column].id}
                employeesWithConflicts={employeesWithConflicts}
                mode={mode}
                stationNum={!dashboardMode && _.get(stations, `[${column}].num`)}
                stationsWithConflicts={stationsWithConflicts}
            >
                {[...Array(dashboard.rows).keys()].map((_, index) => (
                    <DashboardAddOrderCell key={index}>
                        {showLink ? (
                            <DashboardAddOrderLink
                                alternativeAction={alternativeAction}
                                day={day}
                                employeeId={mode == 'employees' && employees[column].id}
                                onStationSelect={mode == 'stations' && onStationSelect}
                                stationNum={mode == 'stations' && stations[column].num}
                                time={setBeginDateitme(index)}
                            />
                        ) : null}
                    </DashboardAddOrderCell>
                ))}
            </DashboardAddOrderColumn>
        );
    };

    _renderDashboardGhostColumns = () => {
        const { dashboard } = this.props;

        if (dashboard.columns < 7) {
            return [...Array(7 - dashboard.columns).keys()].map((_, index) => (
                <DashboardColumn key={index} column={1} dashboard={dashboard} />
            ));
        }

        return null;
    };
}

export default withDnDropContext(DashboardContainer);
