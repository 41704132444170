import { Form } from '@ant-design/compatible';
import {
    CaretDownOutlined,
    CaretUpOutlined,
    CloseOutlined,
    PlusCircleOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import { Button, Col, Radio, Row, Select } from 'antd';
import classNames from 'classnames/bind';
import { Loader } from 'commons';
import { CashSelectedClientOrdersTable } from 'components';
import { fetchCashboxes } from 'core/cash/duck';
import {
    createCashOrder,
    fetchAnalytics,
    fetchCashOrderForm,
    fetchCashOrderNextId,
    fetchStoreDocsBySupplier,
    onChangeCashOrderForm,
    onClientFieldsReset,
    onClientReset,
    onClientSelect,
    onOrderReset,
    onOrderSelect,
    onStoreDocReset,
    onStoreDocSelect,
    printCashOrder,
    resetStoreDocBySupplier,
    selectAnalytics,
    selectClient,
    selectCounterpartyList,
    selectOrder,
    selectStoreDocsBySupplierSelected,
    setStoreDocBySupplier,
    setStoreDocsBySupplierFilters
} from 'core/forms/cashOrderForm/duck';
import dayjs from 'dayjs';
import { StoreDocsBySupplierTable } from 'forms/CashOrderForm/components/StoreDocsBySupplierTable';
import {
    DecoratedDatePicker,
    DecoratedInput,
    DecoratedInputNumber,
    DecoratedRadio,
    DecoratedSelect,
    DecoratedTextArea
} from 'forms/DecoratedFields';
import { ClientsSearchTable } from 'forms/OrderForm/OrderFormTables';
import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { fetchAPI, isForbidden, numeralFormatter, numeralParser, permissions, withReduxForm2 } from 'utils';
import { adjustmentSumTypes, cashOrderCounterpartyTypes, cashOrderTypes } from './config.js';
import Styles from './styles.m.css';

const cx = classNames.bind(Styles);
const { Option } = Select;
const RadioGroup = Radio.Group;

// Layout describes how Form Item will pe divided into parts Example: (______label_______: ______Input________)
const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 15 }
};

const reverseFromItemLayout = {
    labelCol: { span: 15 },
    wrapperCol: { span: 7 }
};

const getActiveFieldsMap = activeCashOrder => {
    return _.pickBy(
        _.pick(activeCashOrder, [
            'id',
            'type',
            'businessSupplierId',
            'cashBoxId',
            'clientId',
            'orderId',
            'storeDocId',
            'description',
            'employeeId',
            'increase',
            'decrease',
            'otherCounterparty',
            'datetime',
            'analyticsUniqueId'
        ]),
        value => !_.isNil(value)
    );
};

/**
 * This is an very **** modal, no more words.
 *
 * @callback onCashOrderModalClose - Callback, this is called after cash order modal is closed
 * @callback onCashOrderCreated - Callback, this is called after cash order was successfully created
 * @property { Boolean } fromStoreDocIncome - fetches supplier data for this type
 * @property { Boolean } fromStoreDocExpense - fetches supplier data for this type
 */
@withReduxForm2({
    name: 'cashOrderForm',
    actions: {
        change: onChangeCashOrderForm,
        fetchCashboxes,
        fetchCashOrderNextId,
        fetchCashOrderForm,
        fetchAnalytics,
        createCashOrder,
        onClientSelect,
        onOrderSelect,
        onStoreDocSelect,
        onClientReset,
        onClientFieldsReset,
        onOrderReset,
        onStoreDocReset,
        printCashOrder,
        fetchStoreDocsBySupplier,
        setStoreDocsBySupplierFilters,
        setStoreDocBySupplier,
        resetStoreDocBySupplier
    },
    mapStateToProps: state => {
        return {
            activeCashOrder: _.get(state, 'modals.modalProps.cashOrderEntity'),
            cashboxes: state.cash.cashboxes,
            client: selectClient(state),
            clientFetching: state.ui.clientFetching,
            clientOrdersFetching: state.ui.clientOrdersFetching,
            analyticsFetchingState: state.forms.cashOrderForm.analyticsFetchingState,
            counterpartyList: selectCounterpartyList(state),
            analytics: selectAnalytics(state),
            nextId: _.get(state, 'forms.cashOrderForm.fields.nextId'),
            order: selectOrder(state),
            modalProps: state.modals.modalProps,
            user: state.auth,
            selectedStoreDocument: selectStoreDocsBySupplierSelected(state)
        };
    }
})
@injectIntl
export class CashOrderForm extends Component {
    state = {
        sumType: adjustmentSumTypes.INCREASE, // This is used to define which input field is selected(default value)
        isVisibleSumTypeRadio: null, // Defines if radio is vissible for selecting input type
        clientSearchType: 'client',
        editing: false,
        errorValidationPanel: false,
        timerId: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.editMode && !state.editing) {
            return {
                sumType: !_.isNil(_.get(getActiveFieldsMap(props.activeCashOrder), 'increase'))
                    ? 'increase'
                    : 'decrease',
                editing: false
            };
        }

        return null;
    }

    componentDidMount() {
        const {
            editMode,
            printMode,
            fromOrder,
            fromClient,
            fromStoreDoc,
            fromStoreDocIncome,
            fromStoreDocExpense,
            activeCashOrder,
            fetchCashOrderNextId,
            fetchCashboxes,
            fetchAnalytics,
            fetchStoreDocsBySupplier,
            modalProps,
            onStoreDocSelect,
            setStoreDocBySupplier,
            form: { setFieldsValue }
        } = this.props;

        const {
            initialStoreDocId,
            businessSupplierId, // : 242
            counterpartyType, // : "BUSINESS_SUPPLIER"
            decrease, // : "100"
            increase,
            sumType, // : "decrease"
            type // : "EXPENSE"
        } = activeCashOrder;

        if (fromStoreDoc || (_.get(activeCashOrder, 'clientId') && _.get(activeCashOrder, 'storeDocId'))) {
            this.setState({
                clientSearchType: 'storeDoc'
            });
        }

        fetchAnalytics();
        fetchCashboxes();

        if (editMode || printMode) {
            this._setFormFields(activeCashOrder);
            this._selectOrderType(_.get(activeCashOrder, 'type'));
        } else {
            fetchCashOrderNextId();

            if (fromOrder || fromStoreDoc || fromClient) {
                this._setFormFields(activeCashOrder);
            }

            if (counterpartyType == cashOrderCounterpartyTypes.BUSINESS_SUPPLIER) {
                if (type == cashOrderTypes.EXPENSE && decrease) {
                    this.setState({
                        sumType: 'decrease',
                        decrease,
                        businessSupplierId,
                        clientSearchType: 'storeDoc'
                    });
                    setFieldsValue({ decrease, sumType: 'decrease', businessSupplierId });
                } else if (type == cashOrderTypes.INCOME && increase) {
                    this.setState({
                        sumType: 'increase',
                        decrease: increase,
                        businessSupplierId,
                        clientSearchType: 'storeDoc'
                    });
                    setFieldsValue({ increase, sumType: 'increase', businessSupplierId });
                }
                if (initialStoreDocId) {
                    fetchAPI('GET', `store_docs/${initialStoreDocId}`, null, null, {
                        handleErrorInternally: true
                    })
                        .then(initialStoreDoc => {
                            setStoreDocBySupplier(initialStoreDoc);
                            setFieldsValue({ storeDocumentId: initialStoreDocId });
                        })
                        .catch(error => console.error(error));
                }
            }
        }

        // Report analytics modal after closing passes some saved values which are set here
        if (modalProps && modalProps.sumTypeStateVal) {
            // If we reopened this modal and value from previous state has to be set
            this.setState(prevState => {
                setFieldsValue({ [prevState.sumType]: null });

                return {
                    sumType: modalProps.sumTypeStateVal,
                    isVisibleSumTypeRadio: modalProps.sumTypeRadioStateVal
                };
            });
        }

        // Fetch suppliers
        if (fromStoreDocIncome) {
            this.setState({
                sumType: 'decrease'
            });
            this._fetchCounterpartyFormData(_.get(activeCashOrder, 'counterpartyType'));
        } else if (fromStoreDocExpense) {
            this.setState({
                sumType: 'increase'
            });
            this._fetchCounterpartyFormData(_.get(activeCashOrder, 'counterpartyType'));
        }
    }

    componentDidUpdate(prevProps) {
        const {
            editMode,
            printMode,
            fromOrder,
            fromStoreDoc,
            fromClient,
            cashboxes,
            fields,
            activeCashOrder,
            analytics,
            form: { getFieldValue, setFieldsValue }
        } = this.props;

        // If order type or sum type was changed we have to update default analytics field value
        if (
            _.get(prevProps, 'fields.type.value') !== _.get(this.props, 'fields.type.value') ||
            _.get(prevProps, 'fields.sumType.value') !== _.get(this.props, 'fields.sumType.value')
        ) {
            this.updateAnalyticsField();
        }

        if (_.get(prevProps, 'fields.counterpartyType.value') !== _.get(fields, 'counterpartyType.value')) {
            const counterparty = getFieldValue('counterpartyType');
            this._fetchCounterpartyFormData(counterparty);

            if (!editMode) {
                this._setNullToFieldsValue();
                this.props.onClientFieldsReset();
            }

            if (editMode) {
                const activeCounterparty = _.get(this._getActiveCounterpartyType(), 'counterpartyType');

                if (counterparty !== activeCounterparty) {
                    this._setNullToFieldsValue();
                }
            }
        }

        if (
            editMode &&
            !_.isNil(_.get(prevProps, 'fields.type.value')) &&
            _.get(prevProps, 'fields.type.value') !== _.get(fields, 'type.value') &&
            _.get(fields, 'type.value') === cashOrderTypes.ADJUSTMENT
        ) {
            this.setState(prevState => {
                setFieldsValue({ [prevState.sumType]: null });

                return {
                    sumType: getFieldValue('sumType'),
                    isVisibleSumTypeRadio: true
                };
            });
        }

        if (!this.state.editing) this.setFieldsValueWhileError();
    }

    handleSearchStoreDocsBySupplier = _.debounce(filters => {
        this.props.setStoreDocsBySupplierFilters(filters);
    }, 1000);

    setFieldsValueWhileError() {
        const {
            editMode,
            printMode,
            fromOrder,
            fromStoreDoc,
            cashboxes,
            fields,
            activeCashOrder,
            analytics,
            form: { getFieldDecorator, setFieldsValue }
        } = this.props;

        const { sumType } = this.state;

        const orderType = _.get(fields, 'type.value') || cashOrderTypes.INCOME;
        // Get all filtered analytics
        let filteredAnalytics = this._getFilteredAnalytics(orderType).filter(
            ana => ana.analyticsDefaultOrderType == orderType
        );

        // If no analytics found set at least first item else we will receive an error
        filteredAnalytics =
            filteredAnalytics.length > 0
                ? filteredAnalytics
                : analytics.filter(ana => ana.analyticsDefaultOrderType == orderType);
        const analyticsUniqueId = _.get(filteredAnalytics, '[0].analyticsUniqueId');

        // getFieldDecorator('cashBoxId', { initialValue: _.get(activeCashOrder, "cashBoxId") || _.get(cashboxes, "[0].id") });
        // getFieldDecorator('analyticsUniqueId', { initialValue: _.get(activeCashOrder, "analyticsUniqueId") || _.get(cashboxes, "[0].id") ||
        // _.get(analytics.filter(ana => ana.analyticsDefaultOrderType == orderType), '[0].analyticsUniqueId') });
        // getFieldDecorator('clientId', { initialValue: _.get(activeCashOrder, "clientId")});
        // getFieldDecorator('orderId', { initialValue: _.get(activeCashOrder, "orderId")});
        // getFieldDecorator('storeDocId', { initialValue: _.get(activeCashOrder, "storeDocId")});
        // getFieldDecorator('increase', { initialValue: _.get(activeCashOrder, "increase")});
        // getFieldDecorator('decrease', { initialValue: _.get(activeCashOrder, "decrease")});

        if (
            (_.get(activeCashOrder, 'cashBoxId') || _.get(cashboxes, '[0].id')) &&
            _.get(fields, 'cashBoxId') &&
            !_.get(fields, 'cashBoxId.value')
        ) {
            setFieldsValue({
                cashBoxId: _.get(activeCashOrder, 'cashBoxId') || _.get(cashboxes, '[0].id')
            });
        }
        if (
            analytics &&
            analytics.length > 0 &&
            (_.get(activeCashOrder, 'analyticsUniqueId') || analyticsUniqueId) &&
            !_.get(fields, 'analyticsUniqueId.value') &&
            !printMode
        ) {
            setFieldsValue({
                analyticsUniqueId: _.get(activeCashOrder, 'analyticsUniqueId') || analyticsUniqueId
            });
        }

        if (_.get(activeCashOrder, 'clientId') && !printMode && !_.get(fields, 'clientId.value') && !printMode) {
            setFieldsValue({ clientId: _.get(activeCashOrder, 'clientId') });
        }
        if (
            _.get(activeCashOrder, 'orderId') &&
            this.state.clientSearchType != 'storeDoc' &&
            !_.get(fields, 'orderId.value') &&
            !printMode
        ) {
            setFieldsValue({ orderId: _.get(activeCashOrder, 'orderId') });
        }
        if (
            _.get(activeCashOrder, 'storeDocId') &&
            this.state.clientSearchType == 'storeDoc' &&
            !_.get(fields, 'storeDocId.value') &&
            !printMode
        ) {
            setFieldsValue({ storeDocId: _.get(activeCashOrder, 'storeDocId') });
        }
        if (
            _.get(activeCashOrder, 'increase') &&
            _.get(fields, 'increase.name') &&
            !_.get(fields, 'increase.value') &&
            sumType == 'increase'
        ) {
            setFieldsValue({ increase: _.get(activeCashOrder, 'increase') });
        }
        if (
            _.get(activeCashOrder, 'decrease') &&
            _.get(fields, 'decrease.name') &&
            !_.get(fields, 'decrease.value') &&
            sumType == 'decrease'
        ) {
            setFieldsValue({ decrease: _.get(activeCashOrder, 'decrease') });
        }
    }

    _setNullToFieldsValue = () => {
        this.props.form.setFieldsValue({
            clientId: null,
            orderId: null,
            storeDocId: null,
            employeeId: null,
            businessSupplierId: null,
            otherCounterparty: null
        });
    };

    _fetchCounterpartyFormData = counterparty => {
        switch (counterparty) {
            case cashOrderCounterpartyTypes.EMPLOYEE:
                return this.props.fetchCashOrderForm('employees');

            case cashOrderCounterpartyTypes.BUSINESS_SUPPLIER:
                return this.props.fetchCashOrderForm('business_suppliers?all=true');

            default:
                break;
        }
    };

    _setFormFields = activeCashOrder => {
        const cashbox = this._getCurrentlySelectedCashbox();

        const { form } = this.props;
        const fieldsMap = getActiveFieldsMap(activeCashOrder);
        const counterparty = this._getActiveCounterpartyType();
        const normalizedDatetime = dayjs(fieldsMap.datetime);
        const sumType =
            !_.isNil(fieldsMap.increase) || (cashbox && cashbox.rst)
                ? adjustmentSumTypes.DECREASE
                : adjustmentSumTypes.INCREASE;
        const isVisibleSumTypeRadio = fieldsMap.type === cashOrderTypes.ADJUSTMENT;
        const normalizedFieldsMap = {
            ...fieldsMap,
            sumType,
            isVisibleSumTypeRadio,
            ...counterparty,
            datetime: normalizedDatetime
        };

        this.setState({ isVisibleSumTypeRadio });
        form.setFieldsValue(normalizedFieldsMap);
    };

    _getActiveCounterpartyType = () => {
        const fieldsMap = getActiveFieldsMap(this.props.activeCashOrder);

        let counterparty = {};
        if (fieldsMap.clientId) {
            counterparty = {
                counterpartyType: cashOrderCounterpartyTypes.CLIENT,
                clientId: fieldsMap.clientId,
                orderId: fieldsMap.orderId,
                storeDocId: fieldsMap.storeDocId
            };
        }
        if (fieldsMap.employeeId) {
            counterparty = {
                counterpartyType: cashOrderCounterpartyTypes.EMPLOYEE,
                employeeId: fieldsMap.employeeId
            };
        }

        if (fieldsMap.businessSupplierId) {
            counterparty = {
                counterpartyType: cashOrderCounterpartyTypes.BUSINESS_SUPPLIER,
                businessSupplierId: fieldsMap.businessSupplierId
            };
        }

        if (fieldsMap.otherCounterparty) {
            counterparty = {
                counterpartyType: cashOrderCounterpartyTypes.OTHER,
                otherCounterparty: fieldsMap.otherCounterparty
            };
        }

        return counterparty;
    };

    _submit = event => {
        event.preventDefault();
        const {
            cashboxes,
            activeCashOrder,
            form,
            createCashOrder,
            onCloseModal,
            editMode,
            fromOrder,
            fromStoreDoc,
            fetchOrder,
            fetchStoreDoc,
            fromClient,
            onCashOrderModalClose,
            onCashOrderCreated
        } = this.props;
        form.validateFields(async (err, values) => {
            if (_.has(err, 'clientId') || _.has(err, 'orderId') || _.has(err, 'storeDocId')) {
                this._handleErrorValidationPanel();
            }

            // Get currently used cashBox
            const currentCashBox = values.cashBoxId
                ? _.get(
                      _.filter(cashboxes, o => o.id == values.cashBoxId),
                      '[0]'
                  )
                : undefined;

            const storeDocument = _.get(values, 'storeDocumentId') ? values.storeDocumentId : null;

            if (!err) {
                const cashOrder = {
                    clientId: values.hasOwnProperty('clientId') ? values.clientId : null,
                    orderId: values.hasOwnProperty('orderId') ? values.orderId : null,
                    storeDocId: values.hasOwnProperty('storeDocId') ? values.storeDocId : storeDocument,

                    cashBox: currentCashBox,
                    editMode,
                    ...values
                };

                await createCashOrder(
                    _.omit({ onCashOrderCreated, ...cashOrder }, ['storeDocumentId', 'storeDocsBySupplierQuery'])
                );
                form.resetFields();
                onCloseModal();

                if (fromOrder) await fetchOrder();
                if (fromStoreDoc) await fetchStoreDoc();
                if (fromClient) await window.location.reload();
                if (onCashOrderModalClose) onCashOrderModalClose();
            }
        });
    };

    /**
     * Gets filtered analytics depending on a cash order type currently selected and adjustment parameters like  increase or decrease
     */
    _getFilteredAnalytics() {
        let {
            analytics,
            form: { getFieldValue }
        } = this.props;

        const cashOrderType = getFieldValue('type'); // Get cash order type

        // Remove disabled analytics from list
        analytics = analytics.filter(ans => !ans.analyticsDisabled);

        let filteredAnlytics;

        if (cashOrderType == cashOrderTypes.INCOME || cashOrderType == cashOrderTypes.EXPENSE) {
            filteredAnlytics = analytics.filter(ana => ana.analyticsOrderType == cashOrderType);
        } else if (cashOrderType == cashOrderTypes.ADJUSTMENT) {
            // There are two cases for this type of cash order, and we have to return swapped values
            const sumType = this.state.sumType || adjustmentSumTypes.INCREASE; // Get field or its init value

            if (sumType == adjustmentSumTypes.INCREASE) {
                // We have to retrun expense analytics only in this case
                filteredAnlytics = analytics.filter(ana => ana.analyticsOrderType == cashOrderTypes.EXPENSE);
            } else if (sumType == adjustmentSumTypes.DECREASE) {
                // Return "income" analytics
                filteredAnlytics = analytics.filter(ana => ana.analyticsOrderType == cashOrderTypes.INCOME);
            }
        }

        return filteredAnlytics || [];
    }

    /**
     * Updates analytics field depending on a selected cash order type and other parameters
     */
    updateAnalyticsField() {
        const {
            form: { setFieldsValue }
        } = this.props;

        const filteredAnalytics = this._getFilteredAnalytics(); // Get analytic for selected type

        // Get default analytics from filtered
        const ans = _.get(
            filteredAnalytics.filter(ana => ana.analyticsDefaultOrderType != null),
            '[0]'
        );

        if (!ans) {
            // Reset if no default analytics found
            setFieldsValue({ analyticsUniqueId: null });
        } else {
            setFieldsValue({ analyticsUniqueId: ans.analyticsUniqueId });
        }
    }

    /**
     * Takes currently selected cashbox and retruns it
     * @param {*} cashboxId Id of a cashboxthat is currenly selected
     * @returns cashbox or undefined
     */
    _getCurrentlySelectedCashbox(cashboxId) {
        const {
            cashboxes
            // form: { getFieldValue }
        } = this.props;

        // const cashboxId = getFieldValue('cashBoxId');

        // Get currently selected cashbox to know if we have to block some fields(for specific cashboxes)
        const currentlySelectedCashbox = cashboxId
            ? _.get(
                  _.filter(cashboxes, o => o.id == cashboxId),
                  '[0]'
              )
            : undefined;

        return currentlySelectedCashbox;
    }

    /** This method is used to set default sumtype based on currently selected cashbox. If cashbox contains rst, then we can't use INCREASE type */
    _setDefaultSumType(cashboxId) {
        const {
            form: { setFieldsValue }
        } = this.props;

        const cashbox = this._getCurrentlySelectedCashbox(cashboxId);

        return this.setState(prevState => {
            setFieldsValue({ [prevState.sumType]: null });

            return {
                sumType: cashbox && cashbox.rst ? adjustmentSumTypes.DECREASE : adjustmentSumTypes.INCREASE,
                isVisibleSumTypeRadio: true
            };
        });
    }

    /**
     * This is called when cash order type is changed.
     * This method is used to update some field value when new order type was selected.
     * "tag" field is out of date, analyticsUniqueId is used instead as it is separate module
     * @param {*} value Selected cash order type
     */
    _selectOrderType = (value, options) => {
        const {
            form: { setFieldsValue }
        } = this.props;

        const cashboxId = _.get(options, 'props.cashboxId');

        switch (value) {
            case cashOrderTypes.INCOME:
                return this.setState(prevState => {
                    setFieldsValue({ [prevState.sumType]: null });

                    return {
                        sumType: adjustmentSumTypes.INCREASE,
                        isVisibleSumTypeRadio: false
                    };
                });

            case cashOrderTypes.EXPENSE:
                return this.setState(prevState => {
                    setFieldsValue({ [prevState.sumType]: null });

                    return {
                        sumType: adjustmentSumTypes.DECREASE,
                        isVisibleSumTypeRadio: false
                    };
                });

            case cashOrderTypes.ADJUSTMENT:
                if (!this.props.editMode) {
                    this._setDefaultSumType(cashboxId);
                }
                break;

            default:
                break;
        }
    };

    /**
     * This method is triggered when cash box is selected.
     * If some fields have to be changed if this field is trigerred we can do it from here,
     * for example we must set other fields(valid) if new cash box contains rst
     */
    _onSelectCashbox = (cashboxId, options) => {
        const {
            form: { setFieldsValue, getFieldValue }
        } = this.props;

        const cashbox = this._getCurrentlySelectedCashbox(cashboxId);

        const cashOrderType = getFieldValue('type');
        const sumType = getFieldValue('sumType');

        // If cashbox contains rst we have to change to some default fileds
        if (cashbox && cashbox.rst) {
            this.setState({ sumTupe: adjustmentSumTypes.DECREASE });
            if (cashOrderType === cashOrderTypes.EXPENSE) {
                setFieldsValue({ type: cashOrderTypes.INCOME });
                this._setSumType(adjustmentSumTypes.INCREASE);
            }
            cashOrderType === cashOrderTypes.ADJUSTMENT &&
                sumType === adjustmentSumTypes.INCREASE &&
                this._setSumType(adjustmentSumTypes.DECREASE);
        }

        this.forceUpdate();
    };

    _setSumType = sumType => {
        this.setState(prevState => {
            this.props.form.setFieldsValue({
                sumType,
                [prevState.sumType]: null
            });

            return { sumType };
        });
    };

    _setClientSearchType = e => {
        this._resetClientCounterparty();
        this.setState({ clientSearchType: e.target.value });
    };

    _handleClientSelection = client => {
        this.props.form.setFieldsValue({ clientId: client.clientId });
        this.props.onClientSelect(client);
    };

    _handleOrderSelection = order => {
        this.props.form.setFieldsValue({
            orderId: order.id,
            clientId: order.clientId,
            increase: order.remainingSum
        });
        this.props.onOrderSelect(order);
    };

    _handleStoreDocSelection = storeDoc => {
        this.props.form.setFieldsValue({
            storeDocId: storeDoc.id,
            clientId: storeDoc.counterpartClientId,
            increase: Math.abs(storeDoc.remainingSum || 10)
        });
        this.props.onStoreDocSelect(storeDoc);
    };

    _handleStoreDocBySupplierSelection = storeDoc => {
        this.props.form.setFieldsValue({
            storeDocumentId: storeDoc.id
            // clientId: storeDoc.counterpartClientId,
            // increase: Math.abs(storeDoc.remainingSum || 10),
        });

        this.props.setStoreDocBySupplier(storeDoc);
    };

    _setCounterpartyType = type => {
        if (type === cashOrderCounterpartyTypes.CLIENT) {
            this._resetClientCounterparty();
        }
    };

    _getClientId = () => {
        const searchEntity = this.props[this.state.clientSearchType];

        const clientId =
            _.get(searchEntity, 'clientId') || this.state.editing
                ? _.get(searchEntity, 'clientId') || this.props.form.getFieldValue('clientId')
                : _.get(this.props.activeCashOrder, 'clientId');

        return clientId;
    };

    _resetClient = editMode => {
        this.props.form.setFieldsValue({
            clientId: null,
            orderId: null,
            storeDocId: null
        });
        this.props.onClientReset();

        if (editMode) {
            this.setState({ editing: true });
        }
    };

    _resetOrder = editMode => {
        this.props.form.setFieldsValue({ orderId: null });
        this.props.onOrderReset();

        if (editMode) {
            this.setState({ editing: true });
        }
    };

    _resetStoreDoc = editMode => {
        this.props.form.setFieldsValue({ storeDocId: null, clientId: null });
        this.props.onStoreDocReset();

        if (editMode) {
            this.setState({ editing: true });
        }
    };

    _resetStoreDocBySupplier = () => {
        this.props.form.setFieldsValue({ storeDocumentId: null });

        this.props.resetStoreDocBySupplier();
    };

    _resetClientCounterparty = () => {
        this.props.onClientReset();
        this.props.onClientFieldsReset();
    };

    _handleErrorValidationPanel = () => {
        const setTimer = () => {
            const timerId = setTimeout(() => {
                this.setState(state => ({
                    errorValidationPanel: !state.errorValidationPanel,
                    timerId: null
                }));
            }, 3000);
            this.setState(() => ({ timerId }));
        };

        if (this.state.timerId) {
            clearTimeout(this.state.timerId);
            setTimer();

            return;
        }

        this.setState(state => ({
            errorValidationPanel: !state.errorValidationPanel
        }));
        setTimer();
    };

    _hiddenFormItemStyles = type =>
        cx({
            hiddenFormItem: !type,
            styledFormItem: true
        });

    _hiddenResetStyles = prop =>
        cx({
            hiddenIcon: !prop,
            clientOrderField: true
        });

    /** This method retruns cashOrder types which can be selected for current type of cashbox */
    _getAvailableCashOrderTypes(cashboxId) {
        const cashbox = this._getCurrentlySelectedCashbox(cashboxId); // Current cashbox

        const availableTypes =
            cashbox && cashbox.rst ? _.omit(cashOrderTypes, [cashOrderTypes.EXPENSE]) : cashOrderTypes;

        return availableTypes;
    }

    render() {
        const {
            cashboxes,
            nextId,
            printMode,
            editMode,
            intl: { formatMessage },
            form: { getFieldDecorator, getFieldValue },

            analyticsFetchingState,
            activeCashOrder,
            onOpenAnalyticsModal,
            fromOrder,
            fromClient,
            fromStoreDoc,
            fromStoreDocIncome,
            user
        } = this.props;

        const cashOrderId = getFieldValue('id');
        const cashbox = _.get(
            cashboxes.filter(obj => obj.id == getFieldValue('cashBoxId')),
            '[0]'
        );

        // https://github.com/ant-design/ant-design/issues/8880#issuecomment-402590493
        // getFieldDecorator("clientId", { initialValue: void 0 });
        return (
            <Form onSubmit={this._submit}>
                <div className={Styles.cashOrderId}>
                    <DecoratedInput
                        className={Styles.styledFormItem}
                        disabled
                        field='id'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={nextId || cashOrderId}
                        label={formatMessage({
                            id: 'cash-order-form.cash_order_num'
                        })}
                    />
                </div>
                <div className={Styles.step}>
                    <DecoratedSelect
                        className={Styles.styledFormItem}
                        // initialValue={cashOrderTypes.INCOME}
                        disabled={printMode || fromStoreDocIncome}
                        field='type'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        initialValue={_.get(activeCashOrder, 'type') || cashOrderTypes.INCOME}
                        label={formatMessage({
                            id: 'cash-order-form.order_type'
                        })}
                        onSelect={this._selectOrderType}
                        placeholder={formatMessage({
                            id: 'cash-order-form.order_type.placeholder'
                        })}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    >
                        {Object.values(this._getAvailableCashOrderTypes(cashbox && cashbox.id)).map(type => (
                            <Option key={type} cashboxId={cashbox && cashbox.id} value={type}>
                                {formatMessage({
                                    id: `cash-order-form.type.${type}`
                                })}
                            </Option>
                        ))}
                    </DecoratedSelect>
                    <DecoratedSelect
                        className={Styles.styledFormItem}
                        disabled={printMode}
                        field='cashBoxId'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        initialValue={_.get(activeCashOrder, 'cashBoxId') || _.get(cashboxes, '[0].id')}
                        label={formatMessage({ id: 'cash-order-form.cashbox' })}
                        onSelect={(cashboxId, options) => this._onSelectCashbox(cashboxId, options)}
                        placeholder={formatMessage({
                            id: 'cash-order-form.cashbox.placeholder'
                        })}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    >
                        {cashboxes.map(obj => {
                            const { id, name, rst } = obj;

                            return (
                                <Option
                                    key={id}
                                    disabled={rst && isForbidden(user, permissions.ACCESS_SALE_RST)}
                                    rst={Boolean(rst)}
                                    value={id}
                                >
                                    {name}
                                </Option>
                            );
                        })}
                    </DecoratedSelect>
                    <DecoratedDatePicker
                        className={Styles.styledFormItem}
                        cnStyles={Styles.expandedInput}
                        disabled={printMode}
                        field='datetime'
                        format='YYYY-MM-DD'
                        formatMessage={formatMessage}
                        formItem
                        formItemLayout={formItemLayout}
                        getCalendarContainer={trigger => trigger.parentNode}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={dayjs()}
                        label={formatMessage({ id: 'cash-order-form.date' })}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    />
                </div>
                <div className={Styles.step}>
                    <DecoratedSelect
                        className={Styles.styledFormItem}
                        disabled={printMode || fromOrder || fromClient || fromStoreDoc || fromStoreDocIncome}
                        field='counterpartyType'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        // initialValue={cashOrderCounterpartyTypes.CLIENT}
                        getPopupContainer={trigger => trigger.parentNode}
                        initialValue={_.get(activeCashOrder, 'counterpartyType') || cashOrderCounterpartyTypes.CLIENT}
                        label={formatMessage({
                            id: 'cash-order-form.counterparty_type'
                        })}
                        onSelect={type => this._setCounterpartyType(type)}
                        placeholder={formatMessage({
                            id: 'cash-order-form.counterparty_type.placeholder'
                        })}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    >
                        {Object.values(cashOrderCounterpartyTypes).map(type => (
                            <Option key={type} value={type}>
                                {formatMessage({
                                    id: `cash-order-form.counterparty.${type}`
                                })}
                            </Option>
                        ))}
                    </DecoratedSelect>
                    {this._renderClientBlock()}
                    {this._renderEmployeeBlock()}
                    {this._renderSupplierBlock()}
                    {this._renderOtherBlock()}
                </div>
                <div className={Styles.step}>
                    <DecoratedRadio
                        className={this._hiddenFormItemStyles(this.state.isVisibleSumTypeRadio)}
                        disabled={printMode}
                        field='sumType'
                        fieldValue={this.state.sumType}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={this.state.sumType}
                        onChange={e => this._setSumType(e.target.value)}
                    >
                        <Radio disabled={cashbox && cashbox.rst} value='increase'>
                            {formatMessage({
                                id: 'cash-order-form.increase'
                            })}
                        </Radio>
                        <Radio value='decrease'>
                            {formatMessage({
                                id: 'cash-order-form.decrease'
                            })}
                        </Radio>
                    </DecoratedRadio>
                    <DecoratedInputNumber
                        className={this._hiddenFormItemStyles(this.state.sumType === 'increase')}
                        cnStyles={Styles.expandedInput}
                        disabled={printMode}
                        field='increase'
                        fields={{}}
                        formatter={numeralFormatter}
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        label={
                            <div>
                                {formatMessage({
                                    id: 'cash-order-form.sum'
                                })}{' '}
                                <CaretUpOutlined style={{ color: 'var(--enabled)' }} />
                            </div>
                        }
                        min={0}
                        parser={numeralParser}
                        placeholder={formatMessage({
                            id: 'cash-order-form.sum.placeholder'
                        })}
                        rules={[
                            {
                                required: this.state.sumType === 'increase',
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    />
                    <DecoratedInputNumber
                        className={this._hiddenFormItemStyles(this.state.sumType === 'decrease')}
                        cnStyles={Styles.expandedInput}
                        disabled={printMode}
                        field='decrease'
                        fields={{}}
                        formatter={numeralFormatter}
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        label={
                            <div>
                                {formatMessage({
                                    id: 'cash-order-form.sum'
                                })}{' '}
                                <CaretDownOutlined style={{ color: 'var(--disabled)' }} />
                            </div>
                        }
                        min={0}
                        parser={numeralParser}
                        placeholder={formatMessage({
                            id: 'cash-order-form.sum.placeholder'
                        })}
                        rules={[
                            {
                                required: this.state.sumType === 'decrease',
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    />

                    <div className={Styles.analyticsCont}>
                        <DecoratedSelect
                            className={Styles.styledFormItem}
                            disabled={
                                printMode ||
                                analyticsFetchingState ||
                                isForbidden(user, permissions.ACCESS_CATALOGUE_ANALYTICS_CRUD)
                            }
                            field='analyticsUniqueId'
                            formItem
                            formItemLayout={formItemLayout}
                            // Initial value depends on a specific analytics field so we leave only those which has that field
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={
                                editMode && activeCashOrder && activeCashOrder.analyticsUniqueId
                                    ? activeCashOrder.analyticsUniqueId
                                    : printMode
                                    ? void 0
                                    : _.get(this._getFilteredAnalytics(), '[0].analyticsUniqueId')
                            }
                            label={formatMessage({
                                id: 'cash-table.analytics'
                            })}
                            loading={analyticsFetchingState}
                            optionLabel='analyticsName' // Will be sent as var
                            options={this._getFilteredAnalytics()}
                            optionValue='analyticsUniqueId'
                            rules={[{ required: true, message: 'Analytics must be selected!!!' }]}
                            showSearch
                        />

                        <Row>
                            <Col span={8}></Col>
                            <Col span={10}>
                                <div className={Styles.createAnalyticsButton}>
                                    <Button
                                        disabled={
                                            printMode || isForbidden(user, permissions.ACCESS_CATALOGUE_ANALYTICS_CRUD)
                                        }
                                        onClick={() => {
                                            onOpenAnalyticsModal({
                                                editMode,
                                                printMode,
                                                fromOrder,
                                                cashOrderEntity: activeCashOrder,
                                                sumTypeStateVal: this.state.sumType,
                                                sumTypeRadioStateVal: this.state.isVisibleSumTypeRadio
                                            });
                                        }}
                                        type='primary'
                                    >
                                        <PlusCircleOutlined />
                                        {formatMessage({
                                            id: 'report_analytics_modal.create_analytics'
                                        })}
                                    </Button>
                                </div>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </div>

                    <DecoratedTextArea
                        className={Styles.styledFormItem}
                        disabled={printMode}
                        field='description'
                        fields={{}}
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        label={formatMessage({ id: 'cash-order-form.comment' })}
                    />
                </div>
                {this.state.errorValidationPanel && (
                    <div className={Styles.error}>
                        {formatMessage({
                            id: 'cash-order-form.client_and_order_are_required'
                        })}
                    </div>
                )}
                <div className={Styles.buttonGroup}>
                    <Button
                        className={printMode ? Styles.printButton : ''}
                        disabled={!cashOrderId || (!printMode && !editMode)}
                        icon={<PrinterOutlined />}
                        onClick={() => this.props.printCashOrder(cashOrderId)}
                        type={printMode ? 'primary' : 'default'}
                    >
                        {formatMessage({ id: 'cash-order-form.print' })}
                    </Button>
                    {printMode || editMode ? null : (
                        <Button htmlType='submit' type='primary'>
                            {formatMessage({ id: 'add' })}
                        </Button>
                    )}
                    {editMode && (
                        <Button htmlType='submit' type='primary'>
                            {formatMessage({ id: 'edit' })}
                        </Button>
                    )}
                </div>
            </Form>
        );
    }

    /* eslint-disable complexity */
    _renderClientBlock = () => {
        const {
            printMode,
            client,
            activeCashOrder,
            order,
            selectedStoreDoc,
            form: { getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const orderId =
            _.get(order, 'id') || this.state.editing
                ? _.get(order, 'id') || getFieldValue('orderId')
                : _.get(activeCashOrder, 'orderId');
        const clientId =
            _.get(client, 'clientId') || this.state.editing
                ? _.get(client, 'clientId') || getFieldValue('clientId')
                : _.get(activeCashOrder, 'clientId');
        const storeDocId =
            _.get(selectedStoreDoc, 'id') || this.state.editing
                ? _.get(selectedStoreDoc, 'storeDocId') || getFieldValue('storeDocId')
                : _.get(activeCashOrder, 'storeDocId');

        const clientSearch = this._renderClientSearch();
        const clientSearchTable = this._renderClientSearchTable();
        const clientField = this._renderClientField();

        const orderSearchField = this._renderOrderSearch();
        const orderField = this._renderOrderField();

        const storeDocFieldSearch = this._renderStoreDocSearch();
        const storeDocField = this._renderStoreDocField();

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.CLIENT;

        return (
            <React.Fragment>
                {!printMode &&
                    (isActive ? (
                        <React.Fragment>
                            <Form.Item
                                className={Styles.switcher}
                                {...reverseFromItemLayout}
                                label={formatMessage({
                                    id: 'cash-order-form.search_by_client_or_document'
                                })}
                            >
                                <RadioGroup
                                    disabled={printMode}
                                    onChange={e => this._setClientSearchType(e)}
                                    value={this.state.clientSearchType}
                                >
                                    <Radio value='client'>
                                        {formatMessage({
                                            id: 'cash-order-form.switch_by_client'
                                        })}
                                    </Radio>
                                    <Radio value='order'>
                                        {formatMessage({
                                            id: 'cash-order-form.switch_by_order'
                                        })}
                                    </Radio>
                                    <Radio value='storeDoc'>
                                        {formatMessage({
                                            id: 'cash-order-form.switch_by_document'
                                        })}
                                    </Radio>
                                </RadioGroup>
                            </Form.Item>
                            {this.state.clientSearchType === 'client' && (
                                <React.Fragment>
                                    {Boolean(!clientId) && clientSearch}
                                    {Boolean(!clientId) && clientSearchTable}
                                    {clientField}
                                    {Boolean(clientId) &&
                                        Boolean(!orderId) &&
                                        (this.props.clientOrdersFetching ? (
                                            <Loader loading={this.props.clientOrdersFetching} />
                                        ) : (
                                            <CashSelectedClientOrdersTable
                                                selectedClient={client}
                                                selectOrder={this._handleOrderSelection}
                                                type='client'
                                            />
                                        ))}
                                    {orderField}
                                </React.Fragment>
                            )}
                            {this.state.clientSearchType === 'order' && (
                                <React.Fragment>
                                    {Boolean(!orderId) && orderSearchField}
                                    {Boolean(!orderId) &&
                                        (this.props.form.getFieldValue('searchOrderQuery') ? (
                                            <CashSelectedClientOrdersTable
                                                searching={this.props.clientOrdersFetching}
                                                searchOrderQuery={this.props.form.getFieldValue('searchOrderQuery')}
                                                searchOrdersResult={this.props.searchOrdersResult}
                                                selectOrder={this._handleOrderSelection}
                                                type='order'
                                            />
                                        ) : null)}
                                    {orderField}
                                </React.Fragment>
                            )}
                            {this.state.clientSearchType === 'storeDoc' && (
                                <React.Fragment>
                                    {Boolean(!storeDocId) && storeDocFieldSearch}
                                    {Boolean(!storeDocId) &&
                                        (this.props.form.getFieldValue('searchStoreDocQuery') ? (
                                            <CashSelectedClientOrdersTable
                                                searching={this.props.clientOrdersFetching}
                                                searchStoreDocQuery={this.props.form.getFieldValue(
                                                    'searchStoreDocQuery'
                                                )}
                                                searchStoreDocsResult={this.props.searchStoreDocsResult}
                                                selectStoreDoc={this._handleStoreDocSelection}
                                                type='storeDoc'
                                            />
                                        ) : null)}
                                    {storeDocField}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : null)}
            </React.Fragment>
        );
    };

    _renderClientSearchTable = () => {
        const {
            searchClientsResult: { searching: clientsSearching, clients },
            form
        } = this.props;
        const formFieldsValues = form.getFieldsValue();
        const searchClientQuery = _.get(formFieldsValues, 'searchClientQuery');

        return (
            <ClientsSearchTable
                clients={clients}
                clientsSearching={clientsSearching}
                setClientSelection={this._handleClientSelection}
                visible={searchClientQuery}
            />
        );
    };

    _renderClientSearch = () => {
        const { getFieldDecorator } = this.props.form;
        const {
            fields,
            errors,
            intl: { formatMessage },
            form: { getFieldValue }
        } = this.props;

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.CLIENT;

        return (
            <div className={Styles.client}>
                <DecoratedInput
                    className={this._hiddenFormItemStyles(isActive)}
                    defaultGetValueProps
                    errors={errors}
                    field='searchClientQuery'
                    fieldValue={_.get(fields, 'searchClientQuery')}
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    label={formatMessage({
                        id: 'cash-order-form.search'
                    })}
                    placeholder={formatMessage({
                        id: 'add_order_form.search_client.placeholder'
                    })}
                    rules={[
                        {
                            required: isActive,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
            </div>
        );
    };

    _renderClientField = () => {
        const {
            printMode,
            editMode,
            client,
            activeCashOrder,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.CLIENT;
        const clientId = this._getClientId();
        const name = _.get(client, 'name') || _.get(activeCashOrder, 'clientName');
        const surname = _.get(client, 'surname') || _.get(activeCashOrder, 'clientSurname');

        return (
            <div className={Styles.clientField}>
                <DecoratedInput
                    cnStyles={Styles.hiddenField}
                    // initialValue={editMode ? clientId : void 0}
                    disabled
                    field='clientId'
                    getFieldDecorator={getFieldDecorator}
                    rules={[
                        {
                            required: isActive,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                {isActive && (
                    <div className={this._hiddenResetStyles(clientId)}>
                        <span>{`${name || ''} ${surname || ''}`}</span>
                        {!printMode && (
                            <CloseOutlined className={Styles.resetIcon} onClick={() => this._resetClient(editMode)} />
                        )}
                    </div>
                )}
            </div>
        );
    };

    _renderStoreDocSearch = () => {
        const {
            fields,
            errors,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const isActive =
            getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.CLIENT &&
            this.state.clientSearchType === 'storeDoc';

        return (
            <DecoratedInput
                className={this._hiddenFormItemStyles(isActive)}
                defaultGetValueProps
                errors={errors}
                field='searchStoreDocQuery'
                fieldValue={_.get(fields, 'searchStoreDocQuery')}
                formItem
                formItemLayout={formItemLayout}
                getFieldDecorator={getFieldDecorator}
                label={formatMessage({
                    id: 'cash-order-form.search'
                })}
                placeholder={formatMessage({
                    id: 'cash-order-form.search_by_document'
                })}
                rules={[
                    {
                        required: isActive,
                        message: formatMessage({
                            id: 'required_field'
                        })
                    }
                ]}
            />
        );
    };

    _renderOrderSearch = () => {
        const {
            fields,
            errors,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const isActive =
            getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.CLIENT &&
            this.state.clientSearchType === 'order';

        return (
            <DecoratedInput
                className={this._hiddenFormItemStyles(isActive)}
                defaultGetValueProps
                errors={errors}
                field='searchOrderQuery'
                fieldValue={_.get(fields, 'searchOrderQuery')}
                formItem
                formItemLayout={formItemLayout}
                getFieldDecorator={getFieldDecorator}
                label={formatMessage({
                    id: 'cash-order-form.search'
                })}
                placeholder={formatMessage({
                    id: 'cash-order-form.search_by_order'
                })}
                rules={[
                    {
                        required: isActive,
                        message: formatMessage({
                            id: 'required_field'
                        })
                    }
                ]}
            />
        );
    };

    _renderOrderField = () => {
        const {
            printMode,
            editMode,
            order,
            activeCashOrder,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const orderId =
            _.get(order, 'id') || this.state.editing
                ? _.get(order, 'id') || getFieldValue('orderId')
                : _.get(activeCashOrder, 'orderId');
        const clientId = this._getClientId();

        const num = _.get(order, 'num') || _.get(activeCashOrder, 'orderNum');
        const clientName = _.get(order, 'clientName') || _.get(activeCashOrder, 'clientName');
        const clientSurname = _.get(order, 'clientSurname') || _.get(activeCashOrder, 'clientSurname');

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.CLIENT;

        return (
            <React.Fragment>
                <div className={Styles.clientField}>
                    <DecoratedInput
                        cnStyles={Styles.hiddenField}
                        // initialValue={editMode ? orderId : void 0}
                        disabled
                        field='orderId'
                        getFieldDecorator={getFieldDecorator}
                        rules={[
                            {
                                required: isActive,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    />
                    {isActive && (
                        <div className={this._hiddenResetStyles(orderId)}>
                            <span>{num}</span>
                            {!printMode && (
                                <CloseOutlined
                                    className={Styles.resetIcon}
                                    onClick={() => this._resetOrder(editMode)}
                                />
                            )}
                        </div>
                    )}
                </div>

                <DecoratedInput
                    cnStyles={Styles.hiddenField}
                    // initialValue={editMode ? clientId : void 0}
                    disabled
                    field='clientId'
                    getFieldDecorator={getFieldDecorator}
                    rules={[
                        {
                            required: isActive,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                {this.state.clientSearchType !== 'client' && (
                    <div className={this._hiddenResetStyles(clientId)}>
                        <span>
                            {clientName}
                            {clientSurname}
                        </span>
                    </div>
                )}
            </React.Fragment>
        );
    };

    _renderStoreDocField = () => {
        const {
            printMode,
            editMode,
            selectedStoreDoc,
            activeCashOrder,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const storeDocId =
            _.get(selectedStoreDoc, 'id') || this.state.editing
                ? _.get(selectedStoreDoc, 'storeDocId') || getFieldValue('storeDocId')
                : _.get(activeCashOrder, 'storeDocId');
        const clientId =
            _.get(selectedStoreDoc, 'counterpartClientId') || this.state.editing
                ? _.get(selectedStoreDoc, 'counterpartClientId') || getFieldValue('clientId')
                : _.get(activeCashOrder, 'clientId');

        // const storeDocId = _.get(selectedStoreDoc, "id") || _.get(activeCashOrder, "storeDocId") || getFieldValue("storeDocId");
        // const clientId = _.get(selectedStoreDoc, "counterpartClientId") || getFieldValue("clientId") || _.get(activeCashOrder, "clientId");

        const documentNumber =
            _.get(selectedStoreDoc, 'documentNumber') || this.state.editing
                ? _.get(selectedStoreDoc, 'documentNumber') || getFieldValue('documentNumber')
                : _.get(activeCashOrder, 'documentNumber');
        const clientName = _.get(selectedStoreDoc, 'counterpartClientName') || _.get(activeCashOrder, 'clientName');
        const clientSurname = _.get(selectedStoreDoc, 'clientSurname') || _.get(activeCashOrder, 'clientSurname');

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.CLIENT;

        return (
            <React.Fragment>
                <div className={Styles.clientField}>
                    <DecoratedInput
                        cnStyles={Styles.hiddenField}
                        // initialValue={editMode ? orderId : void 0}
                        disabled
                        field='storeDocId'
                        getFieldDecorator={getFieldDecorator}
                        rules={[
                            {
                                required: isActive,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    />
                    {isActive && (
                        <div className={this._hiddenResetStyles(storeDocId)}>
                            <span>{documentNumber}</span>
                            {!printMode && (
                                <CloseOutlined
                                    className={Styles.resetIcon}
                                    onClick={() => this._resetStoreDoc(editMode)}
                                />
                            )}
                        </div>
                    )}
                </div>

                <DecoratedInput
                    cnStyles={Styles.hiddenField}
                    // initialValue={editMode ? clientId : void 0}
                    disabled
                    field='clientId'
                    getFieldDecorator={getFieldDecorator}
                    rules={[
                        {
                            required: isActive,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                {this.state.clientSearchType !== 'client' && (
                    <div className={this._hiddenResetStyles(clientId)}>
                        <span>
                            {clientName}
                            {clientSurname}
                        </span>
                    </div>
                )}
            </React.Fragment>
        );
    };

    _renderEmployeeBlock = () => {
        const {
            printMode,
            counterpartyList,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.EMPLOYEE;

        return (
            <DecoratedSelect
                className={this._hiddenFormItemStyles(isActive)}
                disabled={printMode}
                field='employeeId'
                filterOption={(input, option) => {
                    return (
                        option.props.children &&
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                }}
                formItem
                formItemLayout={formItemLayout}
                getFieldDecorator={getFieldDecorator}
                getPopupContainer={trigger => trigger.parentNode}
                label={formatMessage({
                    id: 'cash-order-form.counterparty.EMPLOYEE'
                })}
                placeholder={formatMessage({
                    id: 'cash-order-form.select_employee'
                })}
                rules={[
                    {
                        required: isActive,
                        message: formatMessage({
                            id: 'required_field'
                        })
                    }
                ]}
                showSearch
            >
                {!_.isEmpty(counterpartyList)
                    ? counterpartyList.map(({ id, name, surname, disabled }) => {
                          if (!disabled) {
                              return (
                                  <Option key={id} disabled={disabled} value={id}>
                                      {name} {surname}
                                  </Option>
                              );
                          }
                      })
                    : []}
            </DecoratedSelect>
        );
    };

    _renderSupplierBlock = () => {
        const {
            printMode,
            counterpartyList,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.BUSINESS_SUPPLIER;

        const storeDocumentId = _.get(this.props.selectedStoreDocument, 'id');

        const businessSupplierId = this.props.form.getFieldValue('businessSupplierId');

        const storeDocFieldSearch = this._renderSupplierStoreDocSearch();
        const storeDocField = this._renderSupplierStoreDocField();

        return (
            <React.Fragment>
                <DecoratedSelect
                    className={this._hiddenFormItemStyles(isActive)}
                    disabled={printMode}
                    field='businessSupplierId'
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    label={formatMessage({
                        id: 'cash-order-form.counterparty.BUSINESS_SUPPLIER'
                    })}
                    placeholder={formatMessage({
                        id: 'cash-order-form.select_supplier'
                    })}
                    rules={[
                        {
                            required: isActive,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                    showSearch
                >
                    {!_.isEmpty(counterpartyList)
                        ? counterpartyList.map(({ id, name }) => (
                              <Option key={id} value={id}>
                                  {name}
                              </Option>
                          ))
                        : []}
                </DecoratedSelect>
                <React.Fragment>
                    {Boolean(isActive && businessSupplierId && !storeDocumentId) && storeDocFieldSearch}
                    {Boolean(isActive && businessSupplierId && !storeDocumentId) &&
                        (this.props.form.getFieldValue('storeDocsBySupplierQuery') ? (
                            <StoreDocsBySupplierTable selectStoreDoc={this._handleStoreDocBySupplierSelection} />
                        ) : null)}

                    {Boolean(isActive) && storeDocField}
                </React.Fragment>
            </React.Fragment>
        );
    };

    _renderSupplierStoreDocSearch = () => {
        const {
            fields,
            errors,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.BUSINESS_SUPPLIER;

        return (
            <DecoratedInput
                className={this._hiddenFormItemStyles(isActive)}
                defaultGetValueProps
                errors={errors}
                field='storeDocsBySupplierQuery'
                formItem
                formItemLayout={formItemLayout}
                getFieldDecorator={getFieldDecorator}
                label={formatMessage({
                    id: 'cash-order-form.search'
                })}
                onChange={e => {
                    const type = this.props.form.getFieldValue('type');
                    const supplierId = this.props.form.getFieldValue('businessSupplierId');

                    const filters = {
                        type: type === cashOrderTypes.INCOME ? cashOrderTypes.EXPENSE : cashOrderTypes.INCOME,
                        documentType: 'SUPPLIER',
                        status: 'DONE',
                        query: _.toLower(_.get(e, 'target.value').replace(/[+()]/g, '')),
                        counterpartBusinessSupplierId: supplierId
                    };
                    this.handleSearchStoreDocsBySupplier(filters);
                }}
                placeholder={formatMessage({
                    id: 'cash-order-form.search_by_document'
                })}
            />
        );
    };

    _renderSupplierStoreDocField = () => {
        const {
            printMode,
            editMode,
            selectedStoreDocument,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const storeDocId = _.get(selectedStoreDocument, 'id');

        const documentNumber = _.get(selectedStoreDocument, 'documentNumber');

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.BUSINESS_SUPPLIER;

        return (
            <React.Fragment>
                <div className={Styles.clientField}>
                    <DecoratedInput
                        cnStyles={Styles.hiddenField}
                        disabled
                        field='storeDocumentId'
                        getFieldDecorator={getFieldDecorator}
                    />
                    {isActive && (
                        <div className={this._hiddenResetStyles(storeDocId)}>
                            <span>{documentNumber}</span>
                            {!printMode && (
                                <CloseOutlined
                                    className={Styles.resetIcon}
                                    onClick={() => this._resetStoreDocBySupplier()}
                                />
                            )}
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    };

    _renderOtherBlock = () => {
        const {
            printMode,
            form: { getFieldDecorator, getFieldValue },
            intl: { formatMessage }
        } = this.props;

        const isActive = getFieldValue('counterpartyType') === cashOrderCounterpartyTypes.OTHER;

        return (
            <DecoratedInput
                className={this._hiddenFormItemStyles(isActive)}
                disabled={printMode}
                field='otherCounterparty'
                formItem
                formItemLayout={formItemLayout}
                getFieldDecorator={getFieldDecorator}
                label={formatMessage({
                    id: 'cash-order-form.counterparty.OTHER'
                })}
                placeholder={formatMessage({
                    id: 'cash-order-form.other_counterparty.placeholder'
                })}
                rules={[
                    {
                        required: false
                    }
                ]}
            />
        );
    };
}
