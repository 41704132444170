import { Form } from '@ant-design/compatible';
import { Button, Select, notification } from 'antd';
import { onChangeCancelReasonForm } from 'core/forms/cancelReasonForm/duck';
import { DecoratedTextArea } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, withReduxForm } from 'utils';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
@withReduxForm({
    name: 'cancelReasonForm',
    actions: {
        change: onChangeCancelReasonForm
    }
})
export class CancelReasonForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commentId: undefined,
            allDetails: []
        };
    }

    checkIsReserved = async callback => {
        const { orderId } = this.props;

        const res = await fetchAPI('GET', `orders/${orderId}`, null, null);

        const isReserved = res && res.orderDetails.find(({ reservedCount }) => reservedCount > 0);

        return isReserved;
    };

    render() {
        const {
            orderComments,
            handleCancelReasonModalSubmit,
            resetModal,
            transferOrder,
            id,
            handleCardClickTransfer,
            cancelForTrancfer,
            handleCancel,
            handleCancelReasonsModal
        } = this.props;
        const { commentId, allDetails } = this.state;
        const { getFieldDecorator, getFieldsValue, setFieldsValue } = this.props.form;
        const { formatMessage } = this.props.intl;

        const values = getFieldsValue();

        return (
            <Form layout='vertical'>
                <div className={Styles.title}>
                    <FormattedMessage id='cancel_reason.title' />
                </div>
                <div className={Styles.submit}>
                    <Button
                        className={Styles.submitButton}
                        onClick={async () => {
                            const values = getFieldsValue();
                            const orderStatusCommentId = commentId || 9;
                            const orderStatusAdditionalComment = values.cancelComment;

                            const options = {
                                orderStatusAdditionalComment,
                                orderStatusCommentId
                            };
                            if (handleCancelReasonModalSubmit) {
                                const isReserved = await this.checkIsReserved();

                                if (isReserved) {
                                    notification.error({
                                        message: formatMessage({
                                            id: 'order-page.reserved_error'
                                        })
                                    });
                                } else {
                                    handleCancelReasonModalSubmit(
                                        'cancel',
                                        void 0,
                                        _.mapValues(options, value => (value === '' ? null : value))
                                    );
                                }
                            } else {
                                handleCardClickTransfer(
                                    id,
                                    'cancel',
                                    void 0,
                                    _.mapValues(options, value => (value === '' ? null : value))
                                );
                            }
                            if (handleCancel) handleCancel();
                        }}
                    >
                        <FormattedMessage id='yes' />
                    </Button>
                    <Button className={Styles.submitButton} onClick={() => resetModal()}>
                        <FormattedMessage id='no' />
                    </Button>
                </div>
                {orderComments && (
                    <Select
                        allowClear
                        onChange={value => {
                            this.setState({
                                commentId: value
                            });
                        }}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={this.props.intl.formatMessage({
                            id: 'cancel_reason.select_cancel_reason'
                        })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block' }}
                        value={handleCardClickTransfer ? commentId || 9 : commentId}
                    >
                        {orderComments
                            .map(({ status, id, comment }) =>
                                status === 'cancel' ? (
                                    <Option key={v4()} value={id}>
                                        {comment}
                                    </Option>
                                ) : (
                                    false
                                )
                            )
                            .filter(Boolean)}
                    </Select>
                )}
                <DecoratedTextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    field='cancelComment'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    rows={4}
                    rules={[
                        {
                            max: 2000,
                            message: formatMessage({
                                id: 'field_should_be_below_2000_chars'
                            })
                        }
                    ]}
                />
            </Form>
        );
    }
}
