import { CheckOutlined } from '@ant-design/icons';
import { Table, notification } from 'antd';
import { Layout } from 'commons';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const mapStateToProps = state => ({
    user: state.auth
});

@injectIntl
@connect(mapStateToProps, void 0)
export default class WaybillsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waybills: [],
            startDate: dayjs().add(-30, 'day'),
            endDate: dayjs(),
            loading: false
        };

        this.columns = [
            { title: <FormattedMessage id='order' />, dataIndex: 'num', key: 'num' },
            { title: <FormattedMessage id='locations.name' />, dataIndex: 'name', key: 'name' },
            {
                title: <FormattedMessage id='date' />,
                dataIndex: 'date',
                key: 'date',
                render: date => dayjs(date).format('DD-MM-YYYY')
            },
            { title: <FormattedMessage id='order_form_table.sum' />, dataIndex: 'sum', key: 'sum' },
            {
                title: <FormattedMessage id='storage_document.pay_until' />,
                dataIndex: 'plannedDate',
                key: 'plannedDate',
                render: date => dayjs(date).format('DD-MM-YYYY')
            },
            {
                key: 'operation',
                render: row => (
                    <CheckOutlined
                        onClick={async () => {
                            await fetchAPI(
                                'POST',
                                'store_docs/receive_external_api_order',
                                undefined,
                                {
                                    externalApiDocId: row.externalApiDocId
                                },
                                { handleErrorInternally: true }
                            );
                            await notification.success({
                                message: this.props.intl.formatMessage({ id: 'message.success' })
                            });
                        }}
                    />
                )
            }
        ];

        this.expandedRowRender = record => {
            const columns = [
                {
                    title: <FormattedMessage id='order_form_table.detail_name' />,
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: <FormattedMessage id='order_form_table.detail_code' />,
                    dataIndex: 'supplierCode',
                    key: 'supplierCode'
                },
                {
                    title: <FormattedMessage id='order_form_table.brand' />,
                    dataIndex: 'brand',
                    key: 'brand'
                },
                {
                    title: <FormattedMessage id='order_form_table.price' />,
                    dataIndex: 'price',
                    key: 'price'
                },
                {
                    title: <FormattedMessage id='order_form_table.count' />,
                    dataIndex: 'count',
                    key: 'count'
                }
            ];

            return (
                <Table
                    bordered
                    columns={columns}
                    dataSource={record.products}
                    pagination={false}
                    rowKey='carbookProductId'
                />
            );
        };
    }

    fetchData = async () => {
        const { endDate, startDate } = this.state;
        this.setState({ loading: true });

        const waybills = await fetchAPI(
            'POST',
            'external_api/get_orders',
            undefined,
            {
                supplier: 'Омега S',
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                index: 0
            },
            { handleErrorInternally: true }
        );
        this.setState({
            waybills,
            loading: false
        });
    };

    render() {
        const { user } = this.props;
        const { waybills, startDate, endDate, loading } = this.state;

        return (
            <Layout
                controls={[
                    <div>
                        <DateRangePicker
                            dateRange={[startDate, endDate]}
                            minimize
                            onDateChange={async ([startDate, endDate]) => {
                                await this.setState({
                                    startDate,
                                    endDate
                                });
                            }} // prevent default space
                            style={{ margin: '0 0 0 8px' }}
                        />
                    </div>
                ]}
                title={<FormattedMessage id='navigation.waybills_list' />}
            >
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={waybills}
                    expandedRowRender={this.expandedRowRender}
                    loading={loading}
                    rowKey='Id'
                    size='small'
                />
            </Layout>
        );
    }
}
