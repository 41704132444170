import bg from './bg';
import cz from './cz';
import en from './en';
import fr from './fr';
import pl from './pl';
import uk from './uk';

const messages = {
    en,
    uk,
    cz,
    bg,
    fr,
    pl
};

export default messages;
