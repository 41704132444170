import { BoxPlotOutlined, CarOutlined, ToolOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, notification, Tooltip } from 'antd';
import { Layout, Spinner } from 'commons';
import { ClientContainer } from 'containers';
import { createOrderForClient, fetchClient, selectClientFetching } from 'core/client/duck';
import { MODALS, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { AddAggregateModal, VehicleModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    clientEntity: state.client.clientEntity,
    clientFetching: selectClientFetching(state)
});

const mapDispatchToProps = {
    fetchClient,
    createOrderForClient,
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleAddAggregateModal: false,
            clientsAggregates: []
        };
    }

    componentDidMount() {
        this.props.fetchClient(this.props.match.params.id);
    }

    getClientsAggregates = async () => {
        const { clientEntity } = this.props;

        await fetchAPI('GET', '/client/aggregates', { clientId: clientEntity.clientId }, null, {
            handleErrorInternally: true
        });
    };

    /**
     * When we want to create a new order for this client
     */
    onCreateOrderForClient = () => {
        const { createOrderForClient, clientEntity, user } = this.props;

        createOrderForClient({
            clientId: clientEntity.clientId,
            managerId: user.id,
            businessRequisiteId: get(user, 'businessRequisitesId')
        });
    };

    /**
     * Open modal to add a new vehicle for current client
     */
    onAddVehicle = () => {
        const { clientEntity } = this.props;

        this.props.setModal(MODALS.VEHICLE, {
            mode: 'ADD',
            clientId: clientEntity.clientId,
            autoRedirect: false,
            onSubmit: () => window.location.reload()
        });
    };

    onCreateEditInteractionForClient = async () => {
        const { clientEntity, user, sourceId, history } = this.props;

        try {
            const existingInteractions = await fetchAPI('GET', '/crm/interactions_docs', null, {
                clientId: clientEntity.clientId,
                counterpartyType: 'CLIENT'
            });

            const isValidDocs = existingInteractions && Array.isArray(existingInteractions.docs);
            if (!isValidDocs) {
                throw new Error('Invalid response structure: docs is missing or not an array');
            }

            const clientInteractions = existingInteractions.docs.filter(
                interaction => interaction.counterpartyId === clientEntity.clientId
            );

            if (clientInteractions.length > 0) {
                const lastInteractionId = _.get(clientInteractions, '[0].id');
                history.push(`/interactions/${lastInteractionId}`);
            } else {
                const submitValues = {
                    docType: 'IAC',
                    statusId: 1,
                    sourceId: clientEntity.sourceId || undefined,
                    counterpartyId: clientEntity.clientId,
                    counterpartyType: 'CLIENT',
                    interactionTypeId: 1,
                    nextDate: dayjs().format('YYYY-MM-DD')
                };

                const response = await fetchAPI('POST', '/crm/interactions_doc', null, submitValues, {
                    handleErrorInternally: true
                });

                notification.success({
                    message: this.props.intl.formatMessage({
                        id: 'message.success'
                    })
                });
                this.props.history.push(`/interactions/${_.get(response, '[0].iac_id_pk')}`);
            }
        } catch (error) {
            notification.error({
                message: this.props.intl.formatMessage({
                    id: 'error'
                })
            });
        }
    };

    render() {
        const { clientFetching, clientEntity, match, location, fetchClient, user, isMobile } = this.props;

        const { visibleAddAggregateModal } = this.state;

        const specificTab = location && location.state ? location.state.specificTab : undefined;

        return clientFetching ? (
            <Spinner spin={clientFetching} />
        ) : (
            <Layout
                controls={
                    <div>
                        <span className={Styles.controlButton}>
                            <Tooltip
                                placement='bottomLeft'
                                title={<FormattedMessage id='client_page.hint_create_order_with_client' />}
                            >
                                <Button
                                    disabled={
                                        clientFetching ||
                                        !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT, accesses.ROWO)
                                    }
                                    icon={<ToolOutlined />}
                                    onClick={this.onCreateOrderForClient}
                                    style={{ color: 'var(--primary)' }}
                                ></Button>
                            </Tooltip>
                        </span>
                        {!isMobile && (
                            <span className={Styles.controlButton}>
                                <Tooltip
                                    placement='bottomLeft'
                                    title={<FormattedMessage id='vehicle_page.hint_add_vehicle_modal' />}
                                >
                                    <Button
                                        disabled={
                                            clientFetching ||
                                            !isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_CUSTOMERS_LIST_CARS,
                                                accesses.ROWO
                                            )
                                        }
                                        icon={<CarOutlined />}
                                        onClick={this.onAddVehicle}
                                    ></Button>
                                </Tooltip>
                            </span>
                        )}
                        <span className={Styles.controlButton}>
                            <Tooltip
                                placement='bottomLeft'
                                title={<FormattedMessage id='clients-page.add_aggregate' />}
                            >
                                <Button
                                    disabled={
                                        clientFetching ||
                                        !isGrantAccessed(
                                            user,
                                            grants.DIRECTORIES_CUSTOMERS_LIST_AGGREGATES,
                                            accesses.ROWO
                                        )
                                    }
                                    icon={<BoxPlotOutlined />}
                                    onClick={() => {
                                        this.setState({
                                            visibleAddAggregateModal: true
                                        });
                                    }}
                                ></Button>
                            </Tooltip>
                        </span>
                        <span className={Styles.controlButton}>
                            <Tooltip placement='bottomLeft' title={<FormattedMessage id='add_edit_interaction' />}>
                                <Button
                                    disabled={
                                        clientFetching ||
                                        !isGrantAccessed(
                                            user,
                                            grants.DIRECTORIES_CUSTOMERS_LIST_INTERACTIONS,
                                            accesses.ROWO,
                                            accesses.ROWO
                                        )
                                    }
                                    icon={<UsergroupAddOutlined />}
                                    onClick={this.onCreateEditInteractionForClient}
                                ></Button>
                            </Tooltip>
                        </span>
                    </div>
                }
                title={<FormattedMessage id='client_page.title' />}
            >
                <ClientContainer
                    clientEntity={clientEntity}
                    clientId={match.params.id}
                    fetchClient={fetchClient}
                    onAddVehicle={this.onAddVehicle}
                    specificTab={specificTab}
                />

                <AddAggregateModal
                    clientId={clientEntity.clientId}
                    getClientsAggregates={this.getClientsAggregates}
                    hideModal={() => {
                        this.setState({
                            visibleAddAggregateModal: false
                        });
                    }}
                    visible={visibleAddAggregateModal}
                />
                <VehicleModal />
            </Layout>
        );
    }
}
