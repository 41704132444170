import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import { CommentaryButton } from 'modals';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PistonIcon, WrenchIcon } from 'theme';

export const VisualAddColumns = ({ setToOrderData, setVericalLayout, verticalLayout }) => {
    const columns = useMemo(
        () => [
            {
                title: () => {
                    return (
                        <Button
                            icon={!verticalLayout ? <CaretDownOutlined /> : <CaretUpOutlined />}
                            onClick={() => setVericalLayout(!verticalLayout)}
                        />
                    );
                },
                key: 'icon',
                align: 'center',
                width: 24,
                render: record => (record.laborId ? <WrenchIcon /> : <PistonIcon />)
            },
            {
                title: <FormattedMessage id='storage_journal.name' />,
                key: 'name',
                render: record => (
                    <Flex vertical>
                        <div>{record.storeGroupName || record.laborName}</div>
                    </Flex>
                )
            },
            {
                key: 'comment',
                align: 'center',
                width: 24,
                render: record => {
                    let name = record.storeGroupName || record.laborName;
                    if (name && name.indexOf(' - ') > -1) {
                        name = name.slice(0, name.indexOf(' - '));
                    }

                    return (
                        <CommentaryButton
                            commentary={
                                record.comment || {
                                    comment: undefined,
                                    positions: [],
                                    problems: []
                                }
                            }
                            detail={name}
                            setComment={(comment, positions, problems) => {
                                if (record.laborId) {
                                    setToOrderData(prev => ({
                                        labors: prev.labors.map(lbr =>
                                            record.laborId === lbr.laborId
                                                ? {
                                                      ...lbr,
                                                      laborName: comment || record.laborName,
                                                      comment: {
                                                          comment,
                                                          positions,
                                                          problems
                                                      }
                                                  }
                                                : lbr
                                        ),
                                        storeGroups: prev.storeGroups
                                    }));
                                } else {
                                    setToOrderData(prev => ({
                                        labors: prev.labors,
                                        storeGroups: prev.storeGroups.map(grp =>
                                            record.storeGroupId === grp.storeGroupId
                                                ? {
                                                      ...grp,
                                                      storeGroupName: comment || record.storeGroupName,
                                                      comment: {
                                                          comment,
                                                          positions,
                                                          problems
                                                      }
                                                  }
                                                : grp
                                        )
                                    }));
                                }
                            }}
                        />
                    );
                }
            },
            {
                key: 'delete',
                align: 'center',
                width: 24,
                render: record => (
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            if (record.laborId) {
                                setToOrderData(prev => ({
                                    labors: prev.labors.filter(({ laborId }) => laborId !== record.laborId),
                                    storeGroups: prev.storeGroups
                                }));
                            } else {
                                setToOrderData(prev => ({
                                    labors: prev.labors,
                                    storeGroups: prev.storeGroups.filter(
                                        ({ storeGroupId }) => storeGroupId !== record.storeGroupId
                                    )
                                }));
                            }
                        }}
                    />
                )
            }
        ],
        [setToOrderData, verticalLayout]
    );

    return columns;
};
