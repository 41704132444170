import { Col, Row, Select } from 'antd';
import _ from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import { selectMode } from 'modals/RefactoredCashOrderModal/redux/duck';
import {
    fetchEmployees,
    selectEmployees,
    selectFetchingEmployees,
    selectSelectedEmployeeId,
    setSelectedEmployeeId
} from 'modals/RefactoredCashOrderModal/redux/employees/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    employees: selectEmployees(state),
    selectedEmployeeId: selectSelectedEmployeeId(state),
    fetchingEmployees: selectFetchingEmployees(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    fetchEmployees,
    setSelectedEmployeeId
};

/**
 * This module is used to select an employees to make cash order for him.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class EmployeeCounterpartyContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: []
        };
    }

    componentDidMount() {
        this.getAllEmployees();
    }

    handleSearchEmployees = _.debounce(value => {
        this.getAllEmployees(value);
    }, 1000);

    getAllEmployees = async query => {
        const employees = await fetchAPI('GET', 'employees', { query }, null, { handleErrorInternally: true });
        this.setState({
            employees
        });
    };

    render() {
        const {
            mode,

            selectedEmployeeId,
            fetchingEmployees,
            setSelectedEmployeeId,
            editable,

            intl: { formatMessage }
        } = this.props;

        const { employees } = this.state;

        const disabled = Boolean(mode != MODES.ADD);

        return (
            <div className={Styles.mainContainer}>
                <div title='Select your Supplier'>
                    <Select
                        allowClear
                        className={Styles.select}
                        disabled={!editable && (disabled || fetchingEmployees)}
                        dropdownStyle={{ position: 'fixed', zIndex: '9999' }}
                        filterOption={(input, option) => {
                            return (
                                String(_.get(option, 'props.label', ''))
                                    .toLowerCase()
                                    .indexOf(String(input || '').toLowerCase()) >= 0
                            );
                        }}
                        loading={fetchingEmployees}
                        onChange={employeeId => setSelectedEmployeeId(employeeId)}
                        onSearch={input => this.handleSearchEmployees(input)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'refactored_cash_order_modal.placeholder_employee'
                        })}
                        popupMatchSelectWidth
                        showSearch
                        value={selectedEmployeeId}
                    >
                        {_.map(
                            employees.filter(({ disabled }) => !disabled),
                            employee => {
                                return (
                                    <Option
                                        key={employee.id}
                                        label={`${employee.name || ''} ${employee.surname || ''}`}
                                        value={employee.id}
                                    >
                                        {' '}
                                        {/* Label is required for search */}
                                        <Row>
                                            <Col span={12}>{`${employee.name || ''} ${employee.surname || ''}`}</Col>
                                            <Col className={Styles.jobTitle} span={12}>{`${
                                                employee.jobTitle || ''
                                            }`}</Col>
                                        </Row>
                                    </Option>
                                );
                            }
                        )}
                    </Select>
                </div>
            </div>
        );
    }
}
