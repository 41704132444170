import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { Numeral } from 'commons';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import PaddedWrapper from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/PaddedWrapper';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { getCurrency } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from '../styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const operationSideOptions = [
    { value: 'CREDIT', label: 'CREDIT' },
    { value: 'DEBET', label: 'DEBET' }
];
const operationTypeOptions = [
    { value: 'DIRECT', label: 'DIRECT' },
    { value: 'STORNO', label: 'STORNO' }
];

const EntriesTable = ({
    entries,
    user,
    handleRelatedModalOpen,
    getFilterInput,
    getFilterSelect,
    filters,
    handleFiltersChange,
    requisites,
    totalEntries,
    handlePaginationChange,
    pagination
}) => {
    const columns = useMemo(
        () => [
            {
                key: 'modal',
                render: record => (
                    <Button icon={<InfoCircleOutlined />} onClick={() => handleRelatedModalOpen(record)} type='text' />
                ),
                align: 'center'
            },
            {
                title: <FormattedMessage id='storage_journal.row_id' />,
                dataIndex: 'operationId',
                key: 'operationId',
                ...getFilterInput('operationId', 'entry_table.operation_id'),
                filtered: filters.operationId
            },
            {
                title: <FormattedMessage id='debt_move_page.document_date' />,
                dataIndex: 'date',
                key: 'date',
                render: data => (data ? dayjs(data).format('DD.MM.YYYY') : <FormattedMessage id='long_dash' />),
                filterDropdown: () => {
                    return (
                        <PaddedWrapper wrapperProps={{ className: Styles.wrapper }}>
                            <DateRangePicker
                                allowClear
                                dateRange={[dayjs(filters.documentDateFrom), dayjs(filters.documentDateTo)]}
                                onDateChange={([startDate, endDate]) =>
                                    handleFiltersChange({
                                        documentDateFrom: startDate.toISOString(),
                                        documentDateTo: endDate.toISOString()
                                    })
                                }
                            />
                        </PaddedWrapper>
                    );
                },
                filtered: filters.documentDateFrom
            },
            {
                title: <FormattedMessage id='storage.created_datetime' />,
                dataIndex: 'closingDate',
                key: 'closingDate',
                filterDropdown: () => {
                    return (
                        <PaddedWrapper wrapperProps={{ className: Styles.wrapper }}>
                            <DateRangePicker
                                allowClear
                                dateRange={[dayjs(filters.dateFrom), dayjs(filters.dateTo)]}
                                onDateChange={([startDate, endDate]) =>
                                    handleFiltersChange({
                                        dateFrom: startDate.toISOString(),
                                        dateTo: endDate.toISOString()
                                    })
                                }
                            />
                        </PaddedWrapper>
                    );
                },
                filtered: filters.dateFrom,
                render: data => dayjs(data).format('DD.MM.YYYY, HH:mm')
            },
            {
                title: <FormattedMessage id='entry_table.requisite_id' />,
                dataIndex: 'requisiteName',
                key: 'requisiteName',
                ...getFilterSelect('requisiteId', 'entry_table.requisite_id', {
                    options: requisites,
                    allowSearch: true
                }) // pass requisiteId to backend
            },
            {
                title: <FormattedMessage id='entry_table.account_id' />,
                dataIndex: 'accountId',
                key: 'accountId',
                ...getFilterInput('accountId', 'entry_table.account_id')
            },
            {
                title: <FormattedMessage id='entry_table.analytics_id' />,
                dataIndex: 'analyticsId',
                key: 'analyticsId',
                ...getFilterInput('analyticsId', 'entry_table.analytics_id')
            },
            {
                title: <FormattedMessage id='entry_table.line_operation_id' />,
                dataIndex: 'lineOperationId',
                key: 'lineOperationId'
                // ...getFilterInput('lineOperationId', 'entry_table.analytics_id')
            },
            {
                title: <FormattedMessage id='entry_table.operation_side' />,
                dataIndex: 'operationSide',
                key: 'operationSide',
                ...getFilterSelect('operationSide', 'entry_table.operation_side', {
                    options: operationSideOptions
                })
            },
            {
                title: <FormattedMessage id='entry_table.operation_type' />,
                dataIndex: 'operationType',
                key: 'operationType',
                ...getFilterSelect('operationType', 'entry_table.operation_type', {
                    options: operationTypeOptions
                })
            },
            {
                title: <FormattedMessage id='entry_table.amount' />,
                dataIndex: 'amount',
                key: 'amount',
                align: 'right',
                render: amount => (
                    <Numeral currency={getCurrency()} mask='0,0.00'>
                        {amount || 0}
                    </Numeral>
                )
            },
            {
                title: <FormattedMessage id='entry_table.doc_number' />,
                key: 'docId',
                render: ({ docId, docType }) => {
                    switch (docType) {
                        case 'MRD':
                            return (
                                <Link to={`${book.order}/${docId}`}>{`${docType}-${user.businessId}-${docId}`}</Link>
                            );
                        case 'RD':
                            return (
                                <Link to={`${book.order}/${docId}`}>{`${docType}-${user.businessId}-${docId}`}</Link>
                            );
                        case 'SAL':
                            return (
                                <Link
                                    to={`${book.salariesPage}/${docId}`}
                                >{`${docType}-${user.businessId}-${docId}`}</Link>
                            );
                        case 'CSH':
                            return (
                                <Link
                                    to={{
                                        pathname: book.cashFlowPage,
                                        state: { cashOrderId: docId }
                                    }}
                                >{`${docType}-${user.businessId}-${docId}`}</Link>
                            );
                        case 'ACC':
                            return isGrantAccessed(user, grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST) ? (
                                <Link to={`${book.accDocs}/${docId}`}>{`${docType}-${user.businessId}-${docId}`}</Link>
                            ) : (
                                `${docType}-${user.businessId}-${docId}`
                            );
                        default:
                            return isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT) ? (
                                <Link
                                    to={`${book.storageDocument}/${docId}`}
                                >{`${docType}-${user.businessId}-${docId}`}</Link>
                            ) : (
                                <span>{`${docType}-${user.businessId}-${docId}`}</span>
                            );
                    }
                },
                ...getFilterInput('docId', 'entry_table.doc_number')
            },
            {
                title: <FormattedMessage id='debt_move_page.user' />,
                render: ({ responsibleId, responsibleName }) => responsibleName || responsibleId,
                ...getFilterInput('responsibleName', 'debt_move_page.user'),
                key: 'responsible'
            },
            {
                title: <FormattedMessage id='entry_table.comment' />,
                dataIndex: 'comment',
                key: 'comment',
                width: 300
            }
        ],
        [
            getFilterInput,
            getFilterSelect,
            requisites,
            handleRelatedModalOpen,
            filters.documentDateFrom,
            filters.documentDateTo,
            filters.dateFrom,
            filters.dateTo,
            handleFiltersChange,
            user.businessId
        ]
    );

    return (
        <Table
            bordered
            columns={columns}
            dataSource={entries}
            pagination={{
                pageSizeOptions: [10, 50, 100],
                showSizeChanger: true,
                onChange: (page, pageSize) => handlePaginationChange({ pageSize, page }),
                pageSize: pagination.pageSize,
                current: pagination.page,
                total: totalEntries
            }}
            rowKey='id'
            scroll={{ x: '100vw' }}
            size='small'
        />
    );
};

export default connect(mapStateToProps)(EntriesTable);
