import { DeleteOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, notification, Popconfirm, Select, Space, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const { Option } = Select;
const RSTSettingsTab = ({ employee, ...props }) => {
    const intl = useIntl();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const cashboxes = await fetchAPI('GET', '/cash_boxes');
            const refs = await fetchAPI('GET', '/manager_cash_boxes_ref', { managerIds: [employee.managerId] });
            setData(
                cashboxes
                    .filter(({ rst }) => rst)
                    .map(cashbox => {
                        const ref = refs?.find(({ cashBoxId }) => cashBoxId === cashbox.id);

                        return {
                            ...cashbox,
                            ...pick(ref, ['taxService', 'cashierApiToken', 'managerId', 'updatedAt', 'updatedByName']),
                            refId: ref?.id
                        };
                    })
            );
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const saveRecord = async record => {
        try {
            if (record.refId) {
                await fetchAPI(
                    'PUT',
                    `/manager_cash_boxes_ref/${record.refId}`,
                    null,
                    {
                        cashierApiToken: record.cashierApiToken,
                        taxService: record.taxService
                    },
                    { handleErrorInternally: true }
                );
            } else {
                await fetchAPI(
                    'POST',
                    '/manager_cash_boxes_ref',
                    null,
                    {
                        managerId: employee.managerId,
                        cashBoxId: record.id,
                        taxService: record.taxService,
                        cashierApiToken: record.cashierApiToken,
                        name: record.name
                    },
                    { handleErrorInternally: true }
                );
            }

            notification.success({
                message: intl.formatMessage({ id: 'message.success' })
            });

            fetchData();
        } catch (error) {
            console.error('Failed to update cashier API token:', error);
        }
    };

    const deleteRecord = async record => {
        try {
            await fetchAPI(
                'DELETE',
                '/manager_cash_boxes_ref',
                null,
                {
                    ids: [record.refId]
                },
                { handleErrorInternally: true }
            );

            fetchData();
        } catch (error) {
            console.error('Failed to update cashier API token:', error);
        }
    };

    const columns = [
        {
            key: 'actions',
            align: 'center',
            width: '4%',
            render: record => (
                <Space>
                    <Button icon={<SaveOutlined />} onClick={() => saveRecord(record)} />
                    <Popconfirm
                        onConfirm={() => deleteRecord(record)}
                        title={
                            <React.Fragment>
                                <FormattedMessage id='delete' />?
                            </React.Fragment>
                        }
                    >
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            )
        },
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            align: 'right'
        },
        {
            title: <FormattedMessage id='cash-table.name' />,
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: <FormattedMessage id='cash-table.type' />,
            dataIndex: 'type',
            key: 'type',
            render: type => <FormattedMessage id={`cash-creation-form.type-${type}`} />
        },
        {
            title: <FormattedMessage id='reports_page.by_rst' />,
            key: 'taxService',
            render: (text, record) => (
                <Select
                    onChange={taxService =>
                        setData(prevData =>
                            prevData.map(item => (item.id === record.id ? { ...item, taxService } : item))
                        )
                    }
                    status={!record.taxService && 'error'}
                    value={record.taxService}
                >
                    <Option value='CASHDESK'>Casdesk</Option>
                    <Option value='CHECKBOX'>Checkbox</Option>
                </Select>
            )
        },
        {
            title: (
                <React.Fragment>
                    PIN / <FormattedMessage id='employee.cashier_api_token' />
                </React.Fragment>
            ),
            key: 'cashierApiToken',
            render: record => (
                <Tooltip
                    open={!record.taxService ? false : void 0}
                    title={
                        record.taxService === 'CHECKBOX' ? 'PIN' : <FormattedMessage id='employee.cashier_api_token' />
                    }
                >
                    <Input.Password
                        onChange={e => {
                            setData(prevData =>
                                prevData.map(item =>
                                    item.id === record.id ? { ...item, cashierApiToken: e.target.value } : item
                                )
                            );
                        }}
                        placeholder='*****************************'
                        status={!record.cashierApiToken && 'error'}
                        value={record.cashierApiToken}
                    />
                </Tooltip>
            )
        },
        {
            key: 'info',
            width: '2%',
            render: record => (
                <Tooltip
                    open={!record.updatedByName ? false : void 0}
                    title={`${record.updatedByName} ${dayjs(record.updatedAt).format('hh:mm DD/MM/YY')}`}
                >
                    <Button disabled={!record.updatedByName} icon={<InfoCircleOutlined />} type='text' />
                </Tooltip>
            )
        }
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={false}
            rowKey='id'
            size='small'
        />
    );
};

export default RSTSettingsTab;
