import { setErrorMessage } from 'core/errorMessage/duck';
import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    CREATE_STORE_DOC_SERVICE,
    FETCH_ANALYTICS,
    UPDATE_STORE_DOC_SERVICE,
    createStoreDocServiceSuccess,
    fetchAnalyticsSuccess,
    selectFiltersAnalytics,
    selectStoreDocServiceFields,
    updateStoreDocServiceSuccess
} from './duck';

export function* fetchAnalyticsSaga() {
    while (true) {
        try {
            yield take(FETCH_ANALYTICS);

            const filters = yield select(selectFiltersAnalytics);

            const response = yield call(fetchAPI, 'GET', '/report/analytics', { filters }, null, {
                handleErrorInternally: true
            });

            yield put(fetchAnalyticsSuccess(response));
        } catch (error) {
            yield put(emitError(error));
        } finally {
        }
    }
}

export function* createStoreDocServiceSaga() {
    while (true) {
        try {
            yield take(CREATE_STORE_DOC_SERVICE);

            const fields = yield select(selectStoreDocServiceFields);

            const response = yield call(fetchAPI, 'POST', 'store_doc_service', {}, fields, {
                handleErrorInternally: true
            });

            yield put(createStoreDocServiceSuccess());
        } catch (error) {
            yield put(setErrorMessage(error));
        }
    }
}

export function* updateStoreDocServiceSaga() {
    while (true) {
        try {
            yield take(UPDATE_STORE_DOC_SERVICE);

            const fieldsPayload = yield select(selectStoreDocServiceFields);

            // storeDocServiceId used as params not payload, analyticsName not required
            const fields = _.omit(fieldsPayload, ['analyticsName', 'id']);

            const storeDocServiceId = _.get(fieldsPayload, 'id');

            const response = yield call(fetchAPI, 'PUT', `store_doc_services/${storeDocServiceId}`, {}, fields, {
                handleErrorInternally: true
            });

            yield put(updateStoreDocServiceSuccess());
        } catch (error) {
            yield put(setErrorMessage(error));
        }
    }
}

export function* saga() {
    yield all([call(fetchAnalyticsSaga), call(createStoreDocServiceSaga), call(updateStoreDocServiceSaga)]);
}
