import {
    CopyOutlined,
    DeleteOutlined,
    MenuOutlined,
    PrinterOutlined,
    RollbackOutlined,
    SaveOutlined
} from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Collapse, Drawer, Flex, Select, Space, notification } from 'antd';
import { Layout } from 'commons';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { setPrint } from 'core/ui/duck';
import dayjs from 'dayjs';
import { get, isArray } from 'lodash';
import { AddLaborOrDetailToOrderModal, AddProductToStoreDocModal } from 'modals';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import { fetchAPI, getLocale, goTo } from 'utils';
import { compressTemplate, decompressTemplate } from '../../../shared/utils/utils';
import { ALL_CSH } from './data/ALL_CSH data';
import { CSH } from './data/CSH_data';
import { ORD } from './data/ORD_data';
import { PRD } from './data/PRD_data';
import { SD_TAGS } from './data/SD_TAGS_data';
import { SD } from './data/SD_data';

const ReportTemplateEditor = ({ setPrint, setModal, location, intl: { formatMessage } }) => {
    const [content, setContent] = useState('');
    const [headContent, setHeadContent] = useState('');
    const [templates, setTemplates] = useState([]);
    const [defaultLocales, setDefaultLocales] = useState({});
    const [templateId, setTemplateId] = useState();
    const [template, setTemplate] = useState({});
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const buildTree = (obj, path = 'data') => {
        return Object.entries(obj).map(([key, val]) => {
            const tmpPath = `${path}.${key}`;
            let lang = getLocale();
            if (lang === 'uk') lang = 'ua';
            console.log(val, val[lang], isArray(val[lang]));

            if (typeof val === 'object' && val[lang] && !isArray(val[lang])) {
                return (
                    <p>
                        <Button
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    key[0] === '$' ? `{{${key.slice(1)}}}` : `{{${tmpPath}}}`
                                );
                                notification.success({ message: formatMessage({ id: 'copied_to_clipboard' }) });
                            }}
                            type='link'
                        >
                            {key[0] === '$' ? `{{${key.slice(1)}}}` : `{{${tmpPath}}}`}: {val[lang]}
                        </Button>
                    </p>
                );
            }

            if (typeof val === 'string') {
                return (
                    <p>
                        <Button
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    key[0] === '$' ? `{{${key.slice(1)}}}` : `{{${tmpPath}}}`
                                );
                                notification.success({ message: formatMessage({ id: 'copied_to_clipboard' }) });
                            }}
                            type='link'
                        >
                            {key[0] === '$' ? `{{${key.slice(1)}}}` : `{{${tmpPath}}}`}: {val}
                        </Button>
                    </p>
                );
            }

            return (
                <Collapse
                    items={[
                        {
                            key,
                            label: key,
                            children: buildTree(val, tmpPath)
                        }
                    ]}
                />
            );
        });
    };

    const items = (locales, type = 'ORDER') => [
        ...(type === 'ORDER'
            ? [
                  {
                      key: 'ORD',
                      label: <FormattedMessage id='new-document-page.item.order' />,
                      children: buildTree(ORD)
                  }
              ]
            : []),
        ...(type === 'DOCUMENT'
            ? [
                  {
                      key: 'SD',
                      label: <FormattedMessage id='task_page.store_doc' />,
                      children: buildTree(SD)
                  },
                  {
                      key: 'SD_TAGS',
                      label: <FormattedMessage id='storage_document.tags' />,
                      children: buildTree(SD_TAGS)
                  }
              ]
            : []),
        ...(type === 'CASH_ORDER'
            ? [
                  {
                      key: 'CSH',
                      label: <FormattedMessage id='task_page.cash_order' />,
                      children: buildTree(CSH)
                  },
                  {
                      key: 'ALL_CSH',
                      label: <FormattedMessage id='navigation.reports' />,
                      children: buildTree(ALL_CSH)
                  }
              ]
            : []),

        {
            key: 'PRD',
            label: <FormattedMessage id='navigation.product' />,
            children: buildTree(PRD)
        },
        {
            key: 'LNG',
            label: <FormattedMessage id='translations' />,
            children: (
                <p>
                    {Object.entries(locales).map(([key, value]) => (
                        <p>
                            <Button
                                onClick={() => {
                                    navigator.clipboard.writeText(`{{lng.${key}}}`);
                                    notification.success({ message: formatMessage({ id: 'copied_to_clipboard' }) });
                                }}
                                type='link'
                            >
                                {`{{lng.${key}}}: ${value}`}
                            </Button>
                        </p>
                    ))}
                </p>
            )
        }
    ];

    const fetchTemplates = useCallback(async () => {
        const { templates, defaultLocales } = await fetchAPI('GET', '/reports_templates', null, null, {
            handleErrorInternally: true
        });
        setTemplates(templates.map(template => ({ ...template, printKey: template.key, key: template.templateId })));
        setDefaultLocales(defaultLocales);
    }, []);

    const putTemplate = useCallback(
        async template => {
            const { templateId, name, globallocale } = template;
            // Combine headContent and bodyContent before saving
            const combinedContent = `<!DOCTYPE html><html>${headContent}${content}</html>`;
            const contentCompressed = await compressTemplate(combinedContent);
            await fetchAPI(
                'PUT',
                '/report_template',
                null,
                { templateId, name, content: contentCompressed, locale: JSON.stringify(globallocale) },
                { handleErrorInternally: true }
            );
            notification.success({ message: formatMessage({ id: 'message.success' }) });
            fetchTemplates();
        },
        [content, headContent]
    );

    const postTemplate = useCallback(
        async template => {
            const { printKey: key, name, type, content } = template;
            // Combine headContent and bodyContent before saving
            // const combinedContent = `<!DOCTYPE html><html>${headContent}${content}</html>`;
            // const contentCompressed = await compressTemplate(combinedContent);
            const { templateId } = await fetchAPI(
                'POST',
                '/report_template',
                null,
                {
                    name: `${name} (${formatMessage({ id: 'copy_1' })})`,
                    content,
                    key,
                    type,
                    locale: JSON.stringify(template.globallocale)
                },
                { handleErrorInternally: true }
            );
            notification.success({ message: formatMessage({ id: 'message.success' }) });
            fetchTemplates();
            setTemplateId(templateId);
        },
        [content, headContent]
    );

    const handleSave = useCallback(() => {
        const template = templates.find(tmp => tmp.templateId === templateId);
        !template.businessId ? postTemplate(template) : putTemplate(template);
    }, [postTemplate, putTemplate, templateId, templates]);

    useEffect(() => {
        if (!templateId && templates.length) {
            setTemplateId(get(location, 'state.templateId', templates[0].templateId));
        }
    }, [location, templateId, templates]);

    useEffect(() => {
        const updContent = async () => {
            const template = templates.find(tmp => tmp.templateId === templateId);
            setTemplate(template);
            if (template) {
                const content = Buffer.isBuffer(template.content)
                    ? template.content
                    : Buffer.from(template.content.data);
                const decompressed = await decompressTemplate(content);
                // Extract the head content and set it
                const headMatch = decompressed.match(/<head[^>]*>[\s\S]*<\/head>/i);
                if (headMatch) {
                    setHeadContent(headMatch[0]);
                }
                // Remove the head content from the decompressed content and set the body content
                const bodyContent = decompressed
                    .replace(/<head[^>]*>[\s\S]*<\/head>/i, '')
                    .replace('<!DOCTYPE html>', '')
                    .replace('<html>', '')
                    .replace('</html>', '');
                setContent(bodyContent);
            }
        };
        updContent();
    }, [templateId, templates]);

    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates]);

    const handleEditorChange = content => {
        setContent(content);
    };

    const handlePrint = async () => {
        if (get(template, 'type') === 'ORDER') {
            setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                mode: 'ORDER_SELECT',
                onConfirm: async orderId => {
                    const response = await fetchAPI(
                        'GET',
                        `/orders/reports/${template.printKey}/${orderId}`,
                        { previewTemplateId: templateId },
                        null,
                        {
                            rawResponse: true,
                            handleErrorInternally: true
                        }
                    );

                    const arrayBuffer = await response.arrayBuffer();
                    const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
                    const reportFile = await decompressTemplate(content);
                    setPrint(reportFile);
                }
            });
        } else if (get(template, 'type') === 'DOCUMENT') {
            setModal(MODALS.ADD_PRODUCT_TO_STORE_DOC, {
                mode: 'DOCUMENT_SELECT',
                onConfirm: async id => {
                    const response = await fetchAPI(
                        'GET',
                        `/orders/reports/${id}`,
                        { previewTemplateId: templateId, type: 'documentReport' },
                        null,
                        {
                            rawResponse: true,
                            handleErrorInternally: true
                        }
                    );

                    const arrayBuffer = await response.arrayBuffer();
                    const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
                    const reportFile = await decompressTemplate(content);
                    setPrint(reportFile);
                }
            });
        } else if (get(template, 'type') === 'CASH_ORDER') {
            const { list: cashOrders } = await fetchAPI('GET', '/cash_orders', {
                startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
                endDate: dayjs().format('YYYY-MM-DD'),
                sortField: 'id',
                sortOrder: 'desc',
                page: 1,
                pageSize: 1
            });
            const cashOrderId = get(cashOrders, '[0].id');
            if (!cashOrderId) return;
            const response = await fetchAPI(
                'GET',
                `/cash_orders/${cashOrderId}/pdf`,
                { previewTemplateId: templateId, reportType: 'incomeCashOrder' },
                null,
                {
                    rawResponse: true,
                    handleErrorInternally: true
                }
            );

            const arrayBuffer = await response.arrayBuffer();
            const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
            const reportFile = await decompressTemplate(content);
            setPrint(reportFile);
        } else {
            // window.print();
            setPrint(content);
        }
    };

    return (
        <Layout
            controls={
                <Space>
                    <Button icon={<RollbackOutlined />} onClick={() => goTo(book.referenceBookPage)} type='text' />
                </Space>
            }
            title={<FormattedMessage id='navigation.report_template_editor' />}
        >
            <Flex justify='space-between'>
                <Flex gap={8} style={{ marginBottom: 8 }}>
                    <Select
                        fieldNames={{ label: 'name', value: 'templateId' }}
                        onChange={setTemplateId}
                        optionFilterProp='name'
                        options={templates}
                        showSearch
                        style={{ minWidth: 333 }}
                        value={templateId}
                    />
                    <Button icon={<PrinterOutlined />} onClick={handlePrint} />
                    <Button icon={<MenuOutlined />} onClick={showDrawer} />
                </Flex>

                <Flex gap={8}>
                    <Button
                        disabled={!get(template, 'businessId')}
                        icon={<DeleteOutlined />}
                        onClick={async () => {
                            await fetchAPI(
                                'DELETE',
                                '/report_template',
                                null,
                                { templateId },
                                {
                                    handleErrorInternally: true
                                }
                            );
                            notification.success({
                                message: formatMessage({ id: 'message.success' })
                            });
                            setTemplateId(get(templates, '[0].templateId'));
                            fetchTemplates();
                        }}
                    />
                    <Button
                        icon={get(template, 'businessId') ? <SaveOutlined /> : <CopyOutlined />}
                        onClick={handleSave}
                    />
                </Flex>
            </Flex>
            <Editor
                apiKey='k58ptx45bkkaxevgjiep2d20wzs8q41rpsqy4yyk146b5nsk' // Get your API key from TinyMCE
                init={{
                    height: '66vh',
                    fullpage_hide_in_source_view: false,
                    image_caption: true,
                    toolbar_mode: 'sliding',
                    contextmenu: 'link image table',
                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                    entity_encoding: 'raw',
                    verify_html: false,
                    cleanup: false,
                    invalid_elements: 'script',
                    protect: [
                        /// \<\/?(p)\>/g,
                        /\<\/?(img)\>/g,
                        /\<\/?(body)\>/g,
                        /<img class="image" src="data:image\/jpg;base64,{{data\.order\.imageAsBase64}}">/g,
                        /<script\s+type="text\/javascript">\s*function\s+setPositions\(\)\s*{[^}]+}[^<]+<\/script>/g,
                        /<script\s+type="text\/javascript">\s*function\s+setPositions\(imageWidth,\s*imageHeight\)\s*{[^}]+}[^<]+<\/script>/g
                        // /\{\{[^{}]+\}\}/g,
                        // /\{\{statusColor\}\}/g
                    ],
                    plugins:
                        'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion fullpage',
                    menubar: 'file edit view insert format tools table help',
                    toolbar:
                        'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl | fullpage'
                }}
                onEditorChange={handleEditorChange}
                value={content}
            />
            {/* <div className={Styles.printable} dangerouslySetInnerHTML={{ __html: content }} /> */}
            <Drawer onClose={onClose} open={open} title={<FormattedMessage id='add_order_form.data' />} width='40%'>
                <Collapse items={items({ ...defaultLocales, ...get(template, 'locale', {}) }, get(template, 'type'))} />
            </Drawer>
            <AddLaborOrDetailToOrderModal />
            <AddProductToStoreDocModal />
        </Layout>
    );
};

const mapStateToProps = state => ({
    print: state.ui.print,
    user: state.auth
});

const mapDispatchToProps = {
    setPrint,
    setModal,
    resetModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReportTemplateEditor)));
