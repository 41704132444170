// actionsMenu.js
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    BlockOutlined,
    CheckOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ImportOutlined,
    RedoOutlined,
    ShoppingOutlined,
    ShrinkOutlined,
    StarFilled,
    TransactionOutlined,
    UndoOutlined
} from '@ant-design/icons';
import { Menu, Modal, notification } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { fetchAPI, isDisabledByDetailStatuses } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

/**
 * Generates the bulk actions menu (the hamburger menu at the top of the table).
 * Handles group operations on selected rows.
 *
 * @param {Object} params
 * @param {Object} params.props - Props from the DetailsTable component.
 * @param {Object} params.state - State from the DetailsTable component.
 * @param {Function} params.updateDataSource - Function to refresh the data.
 */
export function getMainActionsMenu({ props, state, updateDataSource }) {
    const { user, intl, orderId, warehouses, isRTN, isRetailOrder } = props;

    const { selectedRowKeys, selectedRows } = state;

    // Helper: Check if order is closed
    const checkOrderClosed = async () => {
        const { status: st } = await fetchAPI('GET', 'orders/status', { orderId }, null);
        if (st === 'success') {
            notification.error({
                message: intl.formatMessage({ id: 'order_form_table.closed_order_notific' })
            });

            return true;
        }

        return false;
    };

    const handleDelete = async () => {
        if (!selectedRowKeys.length) return;
        Modal.confirm({
            title: intl.formatMessage({ id: 'add_order_form.delete_confirm' }),
            onOk: async () => {
                if (await checkOrderClosed()) return;
                try {
                    const deletableRows = selectedRows.filter(
                        row => !isDisabledByDetailStatuses('delete', row, Boolean(Number(row.reservedCount)))
                    );
                    if (!deletableRows.length) {
                        notification.warning({
                            message: intl.formatMessage({ id: 'details_table.delete_failed' })
                        });

                        return;
                    }

                    await fetchAPI('DELETE', 'orders/details', undefined, {
                        ids: deletableRows.map(({ id }) => id)
                    });
                    notification.success({
                        message: intl.formatMessage({ id: 'details_table.deleted' })
                    });
                    updateDataSource();
                } catch (e) {
                    notification.warning({
                        message: intl.formatMessage({ id: 'details_table.delete_failed' })
                    });
                }
            }
        });
    };

    const handleCopy = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;
        // Implement bulk copy logic:
        // E.g. create duplicates of selectedRows
        const detailsPayload = selectedRows
            .filter(row => !isDisabledByDetailStatuses('copy', row))
            .map(row => ({
                storeGroupId: row.storeGroupId,
                name: row.detailName,
                productCode: row.detailCode,
                supplierId: row.supplierId,
                supplierBrandId: row.supplierBrandId || row.brandId,
                purchasePrice: row.purchasePrice,
                count: row.count,
                price: row.price,
                status: row.status,
                partUnitId: row.partUnitId || 1,
                partRowDiscount: row.partRowDiscount || 0,
                oeCode: row.oeCode || null
            }));

        if (!detailsPayload.length) {
            notification.warning({
                message: intl.formatMessage({ id: 'not_found' })
            });

            return;
        }

        await fetchAPI('PUT', `orders/${orderId}`, null, {
            insertMode: true,
            details: detailsPayload
        });
        updateDataSource();
    };

    const handleUpdatePrice = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;
        // Show update price modal logic or call API if needed
        // The original code sets setPriceModal: selectedRows in main component
        // You can do something like:
        // props.setPriceModal(selectedRows);
    };

    const handleChangeStatus = async status => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;

        const detailsToUpdate = selectedRows.map(({ id, storeGroupId }) => ({
            id,
            storeGroupId,
            status
        }));
        const payload = { updateMode: true, details: detailsToUpdate };
        await fetchAPI('PUT', `orders/${orderId}`, undefined, payload);
        updateDataSource();
    };

    const handleChangeResponsible = async responsibleId => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;

        const detailsToUpdate = selectedRows.map(({ id, storeGroupId }) => ({
            id,
            storeGroupId,
            responsibleId
        }));
        const payload = { updateMode: true, details: detailsToUpdate };
        await fetchAPI('PUT', `orders/${orderId}`, undefined, payload);
        updateDataSource();
    };

    const handleUpdateStage = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;

        const detailsPayload = selectedRows.map(({ id }) => ({ id }));
        const response = await fetchAPI(
            'PUT',
            'orders/details',
            undefined,
            {
                details: detailsPayload
            },
            { handleErrorInternally: true }
        );

        // Show notification based on response
        if (response && response.success) {
            notification.success({
                message: intl.formatMessage({ id: 'message.success' })
            });
        } else {
            notification.warning({
                message: intl.formatMessage({ id: 'error' })
            });
        }
        updateDataSource();
    };

    const handleGroup = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;
        // Group logic: assign a new groupName to all selected rows
        const groupName = Math.random().toString(36).substr(2, 9);
        const detailsToUpdate = selectedRows.map(({ id, agreement }) => ({
            id,
            groupName,
            agreement: agreement || 'UNDEFINED'
        }));
        await fetchAPI('PUT', `orders/${orderId}`, undefined, { updateMode: true, details: detailsToUpdate });
        updateDataSource();
    };

    const handleOrderAllPossible = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;

        const ordersAppurtenanciesIds = selectedRows
            .filter(row => !isDisabledByDetailStatuses('order', row) && row.detailCode)
            .map(({ id }) => id);

        if (!ordersAppurtenanciesIds.length) {
            notification.warning({
                message: intl.formatMessage({ id: 'not_found' })
            });

            return;
        }

        await fetchAPI('POST', 'store_docs/order_all_possible', undefined, { ordersAppurtenanciesIds });
        // Check status or show notification if needed
        updateDataSource();
    };

    const handleAcceptAllPossible = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;
        // This would normally open a SuppliersIncomeModal in main code.
        // Implement if needed.
    };

    const handleReturnAllPossible = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;

        const ordersAppurtenanciesIds = selectedRows
            .filter(row => !isDisabledByDetailStatuses('ret_2', row))
            .map(({ id }) => id);
        if (!ordersAppurtenanciesIds.length) return;

        await fetchAPI('POST', 'store_docs/return_to_supplier_all_possible', undefined, { ordersAppurtenanciesIds });
        updateDataSource();
    };

    const handleGiveAllPossible = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;
        const repairAreaWarehouse = warehouses.find(({ attribute }) => attribute === 'REPAIR_AREA');
        if (!repairAreaWarehouse) return;

        const ordersAppurtenanciesIds = selectedRows
            .filter(row => !isDisabledByDetailStatuses('give', row))
            .map(({ id }) => id);
        if (!ordersAppurtenanciesIds.length) return;

        await fetchAPI('POST', 'v2/store_docs/transfer_reserved_all_possible', undefined, {
            ordersAppurtenanciesIds,
            toWarehouseId: repairAreaWarehouse.id
        });
        updateDataSource();
    };

    const handleToStockAllPossible = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;
        const mainWarehouse = warehouses.find(({ attribute }) => attribute === 'MAIN');
        if (!mainWarehouse) return;

        const ordersAppurtenanciesIds = selectedRows
            .filter(row => !isDisabledByDetailStatuses('ret_1', row))
            .map(({ id }) => id);
        if (!ordersAppurtenanciesIds.length) return;

        await fetchAPI('POST', 'v2/store_docs/transfer_reserved_all_possible', undefined, {
            ordersAppurtenanciesIds,
            toWarehouseId: mainWarehouse.id
        });
        updateDataSource();
    };

    const handleImportDoc = async () => {
        if (await checkOrderClosed()) return;
        // Show import document modal if needed
        // props.setModal(MODALS.IMPORT_RECEIPT_DOCUMENT_MODAL, { visibleParts: true });
    };

    const handleMountAllPossible = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;

        const detailsToUpdate = selectedRows
            .filter(row => !isDisabledByDetailStatuses('mount', row))
            .map(({ id }) => ({ id, status: 'MOUNTED' }));
        if (!detailsToUpdate.length) return;

        await fetchAPI('PUT', `orders/${orderId}`, undefined, { updateMode: true, details: detailsToUpdate });
        updateDataSource();
    };

    const handleFrequentAllPossible = async () => {
        if (!selectedRowKeys.length) return;
        if (await checkOrderClosed()) return;
        // Add all selected rows to favorites if they are not already favorite
        const data = [];
        selectedRows
            .filter(({ frequentDetailId }) => !frequentDetailId)
            .forEach(row =>
                data.push({
                    storeGroupId: row.storeGroupId,
                    detailCode: row.detailCode,
                    detailName: row.detailName,
                    productBrandId: row.productBrandId || row.supplierBrandId,
                    supplierId: row.supplierId
                }));

        if (data.length) {
            await fetchAPI('POST', 'orders/frequent/details', null, data, {
                handleErrorInternally: true
            });
            notification.success({
                message: intl.formatMessage({ id: 'details_table.added' })
            });
            updateDataSource();
        }
    };

    if (isRTN) {
        // For RTN case, show fewer actions (as in original code)
        return (
            <Menu>
                {isGrantAccessed(
                    user,
                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
                ) && (
                    <Menu.Item
                        key='groupDetailsMenu.updatePrice'
                        disabled={!selectedRowKeys.length}
                        onClick={handleUpdatePrice}
                    >
                        <TransactionOutlined style={{ fontSize: 18 }} />
                        {intl.formatMessage({ id: 'update_price' })}
                    </Menu.Item>
                )}

                <Menu.Item
                    key='groupDetailsMenu.delete'
                    disabled={
                        !selectedRowKeys.length || selectedRows.find(({ reservedCount }) => reservedCount >= 0.01)
                    }
                    onClick={handleDelete}
                >
                    <DeleteOutlined />
                    {intl.formatMessage({ id: 'delete' })}
                </Menu.Item>
            </Menu>
        );
    }

    // Not RTN: full actions
    return (
        <Menu>
            <Menu.Item
                key='groupDetailsMenu.changeStatus'
                disabled={!selectedRowKeys.length || selectedRows.some(({ reservedCount }) => reservedCount > 0)}
            >
                <EditOutlined style={{ fontSize: 18 }} />
                {intl.formatMessage({ id: 'profile.spare_parts_workplace.change_status' })}
                {/* You would open a submenu or modal to pick a status, then call handleChangeStatus */}
            </Menu.Item>

            <Menu.Item key='groupDetailsMenu.changeResponsible' disabled={!selectedRowKeys.length}>
                <FormattedMessage id='order_form_table.appurtenancies_responsible' />
                {/* Similarly open a submenu or modal to pick responsible, then call handleChangeResponsible */}
            </Menu.Item>

            {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE) && (
                <Menu.Item
                    key='groupDetailsMenu.updateStage'
                    disabled={!selectedRowKeys.length}
                    onClick={handleUpdateStage}
                >
                    <RedoOutlined style={{ fontSize: 18 }} />
                    {intl.formatMessage({ id: 'orders.update_stage' })}
                </Menu.Item>
            )}

            <Menu.Item key='groupDetailsMenu.changeGroup' disabled={!selectedRowKeys.length} onClick={handleGroup}>
                <BlockOutlined />
                {intl.formatMessage({ id: 'orders.to_group' })}
            </Menu.Item>

            <Menu.Item key='groupDetailsMenu.copy' disabled={!selectedRowKeys.length} onClick={handleCopy}>
                <CopyOutlined />
                {intl.formatMessage({ id: 'profile.spare_parts_workplace.copy' })}
            </Menu.Item>

            {isGrantAccessed(
                user,
                grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
            ) && (
                <Menu.Item
                    key='groupDetailsMenu.updatePrice'
                    disabled={!selectedRowKeys.length}
                    onClick={handleUpdatePrice}
                >
                    <TransactionOutlined style={{ fontSize: 18 }} />
                    {intl.formatMessage({ id: 'update_price' })}
                </Menu.Item>
            )}

            {(isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT, accesses.ROWO) ||
                isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS, accesses.ROWO)) && (
                <Menu.Item
                    key='groupDetailsMenu.order'
                    disabled={!selectedRowKeys.length || selectedRows.every(r => !r.detailCode)}
                    onClick={handleOrderAllPossible}
                >
                    <ShoppingOutlined />
                    {intl.formatMessage({ id: 'profile.spare_parts_workplace.to_order' })}
                </Menu.Item>
            )}

            {isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT, accesses.ROWO) && (
                <React.Fragment>
                    <Menu.Item
                        key='groupDetailsMenu.accept'
                        disabled={!selectedRowKeys.length || selectedRows.every(r => !r.detailCode)}
                        onClick={handleAcceptAllPossible}
                    >
                        <CheckOutlined />
                        {intl.formatMessage({ id: 'profile.spare_parts_workplace.accept' })}
                    </Menu.Item>
                    <Menu.Item
                        key='groupDetailsMenu.give'
                        disabled={!selectedRowKeys.length}
                        onClick={handleGiveAllPossible}
                    >
                        <ArrowRightOutlined />
                        {intl.formatMessage({ id: 'profile.spare_parts_workplace.give' })}
                    </Menu.Item>
                    <Menu.Item
                        key='groupDetailsMenu.toStock'
                        disabled={!selectedRowKeys.length}
                        onClick={handleToStockAllPossible}
                    >
                        <ArrowLeftOutlined />
                        {intl.formatMessage({ id: 'profile.spare_parts_workplace.return_to_stock' })}
                    </Menu.Item>
                    <Menu.Item
                        key='groupDetailsMenu.return'
                        disabled={!selectedRowKeys.length}
                        onClick={handleReturnAllPossible}
                    >
                        <UndoOutlined />
                        {intl.formatMessage({ id: 'profile.spare_parts_workplace.return_to_supplier' })}
                    </Menu.Item>
                </React.Fragment>
            )}

            <Menu.Item
                key='groupDetailsMenu.delete'
                disabled={!selectedRowKeys.length || selectedRows.find(({ reservedCount }) => reservedCount >= 0.01)}
                onClick={handleDelete}
            >
                <DeleteOutlined />
                {intl.formatMessage({ id: 'delete' })}
            </Menu.Item>

            <Menu.Item
                key='groupDetailsMenu.frequent'
                disabled={!selectedRowKeys.length}
                onClick={handleFrequentAllPossible}
            >
                <StarFilled style={{ color: 'gold' }} />
                {intl.formatMessage({ id: 'add_to_favorites' })}
            </Menu.Item>

            <Menu.Item key='groupDetailsMenu.import_doc' onClick={handleImportDoc}>
                <ImportOutlined style={{ fontSize: 18 }} />
                {intl.formatMessage({ id: 'directory_page.import_document' })}
            </Menu.Item>

            {!isRetailOrder && (
                <Menu.Item
                    key='groupDetailsMenu.mount'
                    disabled={!selectedRowKeys.length}
                    onClick={handleMountAllPossible}
                >
                    <ShrinkOutlined />
                    {intl.formatMessage({ id: 'profile.mount' })}
                </Menu.Item>
            )}
        </Menu>
    );
}
