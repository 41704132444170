import { notification } from 'antd';

export const showDetailsActionNotification = (intl, type, all, success) => {
    if (success) {
        notification.success({
            message: intl.formatMessage({ id: `details_action_notification.${type}.success` })
        });
    } else if (success) {
        notification.warning({
            message: intl.formatMessage({ id: `details_action_notification.${type}.part_success` })
        });
    } else {
        notification.error({
            message: intl.formatMessage({ id: `details_action_notification.${type}.error` })
        });
    }
};
