import { Button } from 'antd';
import { Layout } from 'commons';
import { CashboxesTable } from 'components/Tables';
import { MODALS, setModal } from 'core/modals/duck';
import { AddCashboxModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { v4 } from 'uuid';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

/**
 * Cash setting page is used to work with cashboxes, you can delere create and setting them up.
 * Currently there are different types of cash boxes, some of them are carbook internal features,
 * and others are connected to government servers(via cashdesk api service for example)
 */
@connect(mapStateToProps, mapDispatchToProps)
export default class CashSettingsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            cashboxes: [],
            loading: false
        };
    }

    onAddCashboxModal = () => {
        this.props.setModal(MODALS.ADD_CASHBOX);
    };

    setTableRow = value => {
        this.setState({
            rows: value
        });
    };

    fetchCashboxes = async query => {
        this.setState({
            loading: true
        });
        const response = await fetchAPI(
            'GET',
            'cash_boxes',
            {
                query,
                onlyActive: false
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        console.log(response);

        this.setState({
            cashboxes: response,
            loading: false
        });
    };

    render() {
        const { user } = this.props;
        const { rows, cashboxes, loading } = this.state;

        const accessAddButton = isGrantAccessed(user, grants.SETTINGS_CASH_REGISTERS, accesses.ROWO);

        return (
            <Layout
                controls={[
                    <Button
                        key={v4()}
                        disabled={!accessAddButton}
                        onClick={() => this.onAddCashboxModal()}
                        type='primary'
                    >
                        <FormattedMessage id='add' />
                    </Button>
                ]}
                title={<FormattedMessage id='navigation.cash_settings' />}
            >
                <CashboxesTable
                    accessAddButton={accessAddButton}
                    cashboxes={cashboxes}
                    fetchCashboxes={this.fetchCashboxes}
                    loading={loading}
                    openEdit={cashbox => {
                        this.props.setModal(MODALS.ADD_CASHBOX, {
                            cashbox
                        });
                    }}
                    setTableRow={this.setTableRow}
                />
                <AddCashboxModal fetchCashboxes={this.fetchCashboxes} rows={rows} setTableRow={this.setTableRow} />
            </Layout>
        );
    }
}
