/**
 * Convert api error to duck error
 * @param {Object} err api error
 * @param {String} source source of error
 * @returns {{source: *}} error's response status with source
 */
export const normalizeText = text => {
    if (!text) return '';

    // Ensure input is always a string
    text = String(text);

    return text
        .toLowerCase()
        .replace(/а/g, 'a')
        .replace(/в/g, 'b')
        .replace(/е/g, 'e')
        .replace(/к/g, 'k')
        .replace(/м/g, 'm')
        .replace(/н/g, 'h')
        .replace(/о/g, 'o')
        .replace(/р/g, 'p')
        .replace(/с/g, 'c')
        .replace(/т/g, 't')
        .replace(/у/g, 'y')
        .replace(/х/g, 'x')
        .replace(/і/g, 'i');
};
