import { EditOutlined, FilterFilled } from '@ant-design/icons';
import { Button, DatePicker, Input, InputNumber, Modal, Popconfirm, Select, Table, Upload, notification } from 'antd';
import { Layout } from 'commons';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const ScmAbcReportsPage = ({ user, intl: { formatMessage }, fetchWarehouses, warehouses, fetchBrands, brands }) => {
    const [isFetched, setFetched] = useState(true);
    const [modalData, setModalData] = useState({});
    const [max, setMax] = useState();
    const [rowId, setRowId] = useState();
    const [visible, setVisible] = useState(false);
    const [abcReportInfo, setAbc] = useState([]);
    const [reportCount, setReportCount] = useState([]);
    const [filters, setFilters] = useState({
        warehouseId: undefined,
        date: dayjs(),
        averageSellPerMonth: 0,
        deliveryTime: 1,
        calculationPeriod: 12,
        limit: 25,
        frequencyOfOrders: 7,
        insuranceReserve: 1
    });
    const [classesABC, setClassesABC] = useState([]);
    const [classesXYZ, setClassesXYZ] = useState([]);
    const [columnFilters, setColumnFilters] = useState({
        page: 1,
        pageSize: 100
    });
    const [suppliers, setSuppliers] = useState([]);

    const fetchSuppliers = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                all: true,
                cut: true,
                query
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setSuppliers(response);
    }, []);

    const getClassesABC = async () => {
        const data = await fetchAPI('GET', 'classes', { type: 'ABC' }, null, {
            handleErrorInternally: true
        });
        setClassesABC(data);
    };

    const getClassesXYZ = async () => {
        const data = await fetchAPI('GET', 'classes', { type: 'XYZ' }, null, {
            handleErrorInternally: true
        });
        setClassesXYZ(data);
    };

    const handleSearchSuppliers = useMemo(() => {
        const loadOptions = value => {
            fetchSuppliers(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [fetchSuppliers]);

    const getAbcReport = useCallback(async () => {
        try {
            const res = await fetchAPI(
                'GET',
                '/scm_report',
                {
                    ...columnFilters
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            if (get(res.data, 'length')) {
                setAbc(res.data);
                setFilters({
                    ..._.omit(res.data[0].filterParameters, [
                        'businessId',
                        'startDate',
                        'endDate',
                        'formattedEndDate',
                        'formattedStartDate',
                        'language'
                    ]),
                    date: dayjs(res.data[0].filterParameters.date) || dayjs()
                });
                setReportCount(res.count);
            } else {
                setAbc([]);
                setReportCount(0);
            }
            setFetched(false);
        } catch (err) {
            notification.error({
                message: formatMessage({
                    id: 'error'
                })
            });
            setFetched(false);
        }
    }, [columnFilters, formatMessage]);

    const postAbcReport = useCallback(async () => {
        try {
            await fetchAPI(
                'POST',
                '/scm_report',
                null,
                {
                    ...filters,
                    // ...columnFilters,
                    date: filters.date ? dayjs(filters.startDate).format(dateFormat) : undefined
                },
                {
                    handleErrorInternally: true
                }
            );

            await getAbcReport();

            notification.success({
                message: formatMessage({ id: 'message.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({
                    id: 'error'
                })
            });
        }
    }, [filters]);

    const exportReport = async () => {
        const getResponse = () => {
            return fetchAPI('GET', 'export_scm_report', null, null, { rawResponse: true });
        };
        const response = await getResponse();
        const reportFile = await response.blob();
        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        saveAs(reportFile, fileName);
    };

    const importReport = async file => {
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/import_scm_report`;

        const formData = new FormData();

        formData.append('file', file);

        console.log(formData, 'form');

        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: token
            }
        }).then(function (response) {
            if (response.status !== 200) {
                return notification.error({
                    message: `${formatMessage({
                        id: 'error'
                    })}!`
                });
            }
            if (response.status === 200) {
                notification.success({
                    message: formatMessage({ id: 'message.success' })
                });
            }
        });

        await getAbcReport();
    };

    const handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        const tmp = columnFilters;

        delete tmp[dataIndex];

        await setColumnFilters(tmp);
        getAbcReport();
    };

    const debounceFilters = useMemo(() => {
        const loadOptions = (filters, value) => {
            setColumnFilters(filters, value);
        };

        return _.debounce(loadOptions, 1000);
    }, [setColumnFilters]);

    const getColumnSearchProps = useCallback(
        dataIndex => {
            let filterComponent = (confirm, clearFilters) => (
                <Input
                    onChange={e => {
                        if (dataIndex === 'code' || dataIndex === 'searchName') {
                            debounceFilters({
                                ...columnFilters,
                                [dataIndex]: e.target.value
                            });
                        } else if (dataIndex === 'storeGroupId') {
                            debounceFilters({
                                ...columnFilters,
                                [dataIndex]: Number(e.target.value)
                            });
                        }
                    }}
                    placeholder={formatMessage({
                        id: 'search'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                />
            );

            if (dataIndex === 'brandId') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={value => {
                            setColumnFilters({
                                ...columnFilters,
                                [dataIndex]: value
                            });
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={columnFilters && columnFilters.brandId}
                    >
                        {brands.map(({ brandId, brandName }) => (
                            <Option key={brandId} value={brandId}>
                                {brandName}
                            </Option>
                        ))}
                    </Select>
                );
            }

            if (dataIndex === 'supplierId') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={async (value, option) => {
                            await setColumnFilters({
                                ...columnFilters,
                                [dataIndex]: value
                            });
                        }}
                        onSearch={input => handleSearchSuppliers(input)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={columnFilters && columnFilters.supplierId}
                    >
                        {suppliers.map(supplier => (
                            <Option key={supplier.id} value={supplier.id}>
                                {`${supplier.name}`}
                            </Option>
                        ))}
                    </Select>
                );
            }

            if (dataIndex === 'classABCId' || dataIndex === 'classXYZId') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={async (value, option) => {
                            await setColumnFilters({
                                ...columnFilters,
                                [dataIndex]: value
                            });
                        }}
                        onSearch={input => handleSearchSuppliers(input)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={
                            dataIndex === 'classABCId'
                                ? columnFilters && columnFilters.classABCId
                                : columnFilters && columnFilters.classXYZId
                        }
                    >
                        {(dataIndex === 'classABCId' ? classesABC : classesXYZ).map(elem => (
                            <Option key={elem.id} value={elem.id}>
                                {elem.key}
                            </Option>
                        ))}
                    </Select>
                );
            }

            if (dataIndex === 'min' || dataIndex === 'max') {
                filterComponent = (confirm, clearFilters) => (
                    <InputNumber
                        allowClear
                        onChange={async (value, option) => {
                            await setColumnFilters({
                                ...columnFilters,
                                [dataIndex]: value
                            });
                        }}
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                    />
                );
            }

            return {
                filterDropdown: ({ confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        {filterComponent(confirm, clearFilters)}
                        {dataIndex !== '11' && dataIndex !== 'filterCreatedDate' && dataIndex !== 'filtertDoneDate' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <Button onClick={() => handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                    </div>
                ),
                filterIcon: () => {
                    let colorFilter;
                    if (columnFilters && columnFilters[dataIndex]) {
                        colorFilter = 'var(--primary)';
                    }

                    return (
                        <FilterFilled
                            style={{
                                fontSize: 14,
                                color: colorFilter
                            }}
                        />
                    );
                }
                // onFilterDropdownVisibleChange: visible => {
                //     if (visible) {
                //         setTimeout(() => this.searchInput.select(), 100);
                //     }
                // }
            };
        },
        [formatMessage, columnFilters, debounceFilters, brands, suppliers, handleSearchSuppliers, handleReset]
    );

    const editRow = row => {
        setRowId(row.id);
        setModalData({
            max: row.currentMax,
            min: row.currentMin,
            multiplicity: row.multiplicity,
            classABCId: row.classABCId,
            classXYZId: row.classXYZId
        });

        setVisible(true);
    };

    const columns = useMemo(
        () => [
            {
                title: <FormattedMessage id='№' />,
                key: 'num',
                render: (record, _, index) => {
                    const pa = columnFilters.pageSize * (columnFilters.page - 1);

                    return `${index + 1 + pa}.`;
                },
                width: 45,
                fixed: 'left',
                onCell: () => ({ style: { backgroundColor: 'white' } })
            },
            {
                key: 'edit',
                align: 'center',
                width: 60,
                fixed: 'left',
                render: (record, _, index) => {
                    return <Button icon={<EditOutlined />} onClick={() => editRow(record)} />;
                },
                onCell: () => ({ style: { backgroundColor: 'white' } })
            },
            {
                title: <FormattedMessage id='storage.product_code' />,
                key: 'code',
                fixed: 'left',
                ...getColumnSearchProps('code'),
                width: 125,
                render: row => <Link to={`${book.product}/${row.productId}`}>{row.code}</Link>,
                onCell: () => ({ style: { backgroundColor: 'white' } })
            },
            {
                title: <FormattedMessage id='brand' />,
                key: 'brand',
                fixed: 'left',
                ...getColumnSearchProps('brandId'),
                width: 125,
                dataIndex: 'brandName',
                onCell: () => ({ style: { backgroundColor: 'white' } })
            },
            {
                title: <FormattedMessage id='storage.product_group' />,
                key: 'store_group',
                fixed: 'left',
                ...getColumnSearchProps('storeGroupId'),
                width: 125,
                render: row => {
                    return row.storeGroupId;
                },
                onCell: () => ({ style: { backgroundColor: 'white' } })
            },
            {
                title: <FormattedMessage id='supplier' />,
                key: 'supplier',
                ...getColumnSearchProps('supplierId'),
                width: 125,
                render: row => <Link to={`${book.supplier}/${row.supplierId}`}>{row.supplierName}</Link>
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: 145,
                ...getColumnSearchProps('searchName'),
                dataIndex: 'name'
            },
            {
                title: <FormattedMessage id='storage.measure_unit' />,
                key: 'unit',
                width: 60,
                dataIndex: 'measureUnit'
            },
            {
                title: <FormattedMessage id='navigation.availability' />,
                key: 'availability',
                align: 'center',
                width: 125,
                render: row => `${Number(row.countOnWarehouse).toFixed(2)} / ${Number(row.available).toFixed(2)}`
            },
            {
                title: <FormattedMessage id='storage_document.multiplicity' />,
                key: 'multiplicity',
                width: 78,
                dataIndex: 'multiplicity'
            },
            {
                title: () => {
                    return (
                        <div>
                            {' '}
                            <FormattedMessage id='services_table.short_specification' /> ABC
                        </div>
                    );
                },
                width: 75,
                key: 'classAbc',
                ...getColumnSearchProps('classABCId'),
                align: 'center',
                dataIndex: 'classABC'
            },
            {
                title: () => {
                    return (
                        <div>
                            {' '}
                            <FormattedMessage id='services_table.short_specification' /> XYZ
                        </div>
                    );
                },
                key: 'classXYZ',
                width: 75,
                ...getColumnSearchProps('classXYZId'),
                align: 'center',
                dataIndex: 'classXYZ'
            },
            {
                title: 'Min',
                key: 'Min',
                ...getColumnSearchProps('min'),
                align: 'center',
                width: 60,
                render: row => row.min || row.currentMin
            },
            {
                title: 'Max',
                key: 'Max',
                ...getColumnSearchProps('max'),
                align: 'center',
                width: 60,
                render: row => row.max || row.currentMax
            },
            {
                title: <FormattedMessage id='SALES' />,
                key: 'multiplicity',
                children: [
                    {
                        title: 'Ø / міс.',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.avgSellsCount).toFixed(2)
                    },
                    {
                        title: '0',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount0).toFixed(2)
                    },
                    {
                        title: '-1',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount1).toFixed(2)
                    },
                    {
                        title: '-2',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount2).toFixed(2)
                    },
                    {
                        title: '-3',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount3).toFixed(2)
                    },
                    {
                        title: '-4',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount4).toFixed(2)
                    },
                    {
                        title: '-5',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount5).toFixed(2)
                    },
                    {
                        title: '-6',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount6).toFixed(2)
                    },
                    {
                        title: '-7',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount7).toFixed(2)
                    },
                    {
                        title: '-8',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount8).toFixed(2)
                    },
                    {
                        title: '-9',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount9).toFixed(2)
                    },
                    {
                        title: '-10',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount10).toFixed(2)
                    },
                    {
                        title: '-11',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount11).toFixed(2)
                    },
                    {
                        title: '-12',
                        align: 'right',
                        width: 60,
                        render: row => Number(row.sellsCount12).toFixed(2)
                    }
                ]
            }
        ],
        [columnFilters.page, columnFilters.pageSize, getColumnSearchProps]
    );

    useEffect(() => {
        if (!get(classesABC, 'length')) {
            getClassesABC();
            getClassesXYZ();
        }
    }, [classesABC]);

    useEffect(() => {
        setFetched(true);
        // getAbcReport();
        fetchWarehouses();
        fetchBrands();
        fetchSuppliers();
    }, [fetchBrands, fetchSuppliers, fetchWarehouses]);

    // useEffect(() => {
    //     if (isFetched) {
    //         getAbcReport();
    //     }
    // }, [isFetched]);

    // useEffect(() => {
    //     if (get(abcReportInfo, 'length')) {
    //         setFilters({
    //             startDate: dayjs(),
    //     endDate: dayjs(),
    //     averageSellPerMonth: 0,
    //     deliveryTime: 1,
    //     calculationPeriod: 12,
    //     limit: 25,
    //     frequencyOfOrders: 7,
    //     insuranceReserve: 1
    //         })
    //     }
    // }, [filters.warehouseId, getAbcReport, isFetched]);

    useEffect(() => {
        if (columnFilters) {
            getAbcReport();
        }
    }, [columnFilters]);

    const saveMinMax = useMemo(
        () => async () => {
            try {
                await fetchAPI(
                    'PUT',
                    '/min_max',
                    null,

                    {
                        data: abcReportInfo.map(
                            ({
                                productId,
                                min,
                                max,
                                currentMin,
                                currentMax,
                                multiplicity,
                                classABCId,
                                classXYZId
                            }) => ({
                                id: productId,
                                min: min || currentMin || 0,
                                max: max || currentMax || 0,
                                multiplicity: multiplicity || 1,
                                abcId: classABCId,
                                xyzId: classXYZId
                            })
                        )
                    },
                    {
                        handleErrorInternally: true
                    }
                );
                getAbcReport();

                notification.success({
                    message: formatMessage({ id: 'message.success' })
                });
            } catch (err) {
                notification.error({
                    message: formatMessage({
                        id: 'error'
                    })
                });
            }
        },
        [abcReportInfo, formatMessage, getAbcReport]
    );

    const handleCancel = () => {
        setModalData({});
        setRowId(undefined);
        setVisible(false);
    };

    const editMinMax = useMemo(
        () => async () => {
            try {
                await fetchAPI(
                    'PUT',
                    '/scm_report',
                    null,

                    {
                        data: [
                            {
                                ...modalData,
                                min: modalData.min * modalData.multiplicity,
                                max: modalData.max * modalData.multiplicity,
                                id: rowId
                            }
                        ]
                    },
                    {
                        handleErrorInternally: true
                    }
                );
                await getAbcReport();
                handleCancel();
                notification.success({
                    message: formatMessage({ id: 'message.success' })
                });
            } catch (err) {
                notification.error({
                    message: formatMessage({
                        id: 'error'
                    })
                });
            }
        },
        [formatMessage, getAbcReport, modalData, rowId]
    );

    const handleOk = () => {
        editMinMax();
    };

    const pagination = {
        pageSize: columnFilters.pageSize,
        size: 'small',
        total: reportCount,
        hideOnSinglePage: false,
        current: columnFilters.page,
        onChange: (page, pageSize) => {
            setColumnFilters({
                ...columnFilters,
                page,
                pageSize
            });
        }
    };

    return (
        <Layout title={<FormattedMessage id='abc_report' />}>
            <div className={Styles.filterBlockStyle}>
                <div className={Styles.inputsMargin}>
                    <span>
                        <FormattedMessage id='date' />
                    </span>
                    <DatePicker
                        allowClear
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={async date => {
                            if (!date) {
                                await setFilters({
                                    ...filters,
                                    date: undefined
                                });
                            }
                            await setFilters({
                                ...filters,
                                date
                            });
                        }}
                        style={{ width: '100%', marginBottom: 10 }}
                        value={filters.date}
                    />
                    <span>
                        <FormattedMessage id='storage' />
                    </span>
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={value => {
                            setFilters({
                                ...filters,
                                warehouseId: value
                            });
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'employee_settings.warehouse'
                        })}
                        showSearch
                        style={{ marginBottom: 8, width: '100%', minWidth: 140 }}
                        value={filters.warehouseId}
                    >
                        {warehouses.map(elem => (
                            <Option key={elem.id} value={elem.id}>
                                {elem.name}
                            </Option>
                        ))}
                    </Select>
                </div>

                <div className={Styles.filterBlockStyle}>
                    <div className={Styles.inputsMargin}>
                        <span>
                            <FormattedMessage id='average_sales_per_month' />
                        </span>
                        <div>
                            <InputNumber
                                decimalSeparator=','
                                min={0}
                                onChange={value => {
                                    setFilters({
                                        ...filters,
                                        averageSellPerMonth: value
                                    });
                                }}
                                style={{
                                    width: '100%',
                                    marginBottom: 10
                                }}
                                value={filters.averageSellPerMonth}
                            />
                        </div>
                        <span>
                            <FormattedMessage id='calculation_period' />
                        </span>
                        <div>
                            <InputNumber
                                decimalSeparator=','
                                max={12}
                                min={0}
                                onChange={value => {
                                    setFilters({
                                        ...filters,
                                        calculationPeriod: value
                                    });
                                }}
                                style={{
                                    width: '100%'
                                }}
                                value={filters.calculationPeriod}
                            />
                        </div>
                    </div>
                    <div className={Styles.inputsMargin}>
                        <span>
                            <FormattedMessage id='delivery_time' />
                        </span>
                        <div>
                            <InputNumber
                                decimalSeparator=','
                                min={0}
                                onChange={value => {
                                    setFilters({
                                        ...filters,
                                        deliveryTime: value
                                    });
                                }}
                                style={{
                                    width: '100%',
                                    marginBottom: 10
                                }}
                                value={filters.deliveryTime}
                            />
                        </div>
                        <span>
                            <FormattedMessage id='frequency_of_orders' />
                        </span>
                        <div>
                            <InputNumber
                                decimalSeparator=','
                                min={0}
                                onChange={value => {
                                    setFilters({
                                        ...filters,
                                        frequencyOfOrders: value
                                    });
                                }}
                                style={{
                                    width: '100%'
                                }}
                                value={filters.frequencyOfOrders}
                            />
                        </div>
                    </div>
                    <div className={Styles.inputsMargin}>
                        <span>
                            <FormattedMessage id='insurance_reserve' />
                        </span>
                        <div>
                            <InputNumber
                                decimalSeparator=','
                                formatter={value => `${value}%`}
                                max={100}
                                min={0}
                                onChange={value => {
                                    setFilters({
                                        ...filters,
                                        insuranceReserve: value
                                    });
                                }}
                                parser={value => value.replace('%', '')}
                                style={{
                                    width: '100%',
                                    marginBottom: 10
                                }}
                                value={filters.insuranceReserve}
                            />
                        </div>
                        <span>
                            <FormattedMessage id='output_positions' />
                        </span>
                        <div>
                            <InputNumber
                                decimalSeparator=','
                                max={200}
                                min={1}
                                onChange={value => {
                                    setFilters({
                                        ...filters,
                                        limit: value
                                    });
                                }}
                                style={{
                                    width: '100%'
                                }}
                                value={filters.limit}
                            />
                        </div>
                    </div>
                </div>
                <div className={Styles.buttonsMargin}>
                    <Popconfirm
                        onConfirm={() => {
                            postAbcReport();
                        }}
                        title={<FormattedMessage id='calculate_minmax_message' />}
                    >
                        <Button
                            style={{
                                width: '100%'
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='calculate_minmax' />
                        </Button>
                    </Popconfirm>
                    <div
                        style={{
                            marginTop: 24
                        }}
                    >
                        <Button
                            onClick={() => saveMinMax()}
                            style={{
                                width: '100%'
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='save_minmax' />
                        </Button>
                    </div>
                </div>

                <div className={Styles.buttonsMargin}>
                    <Upload
                        showUploadList={false}
                        action={importReport}
                        // beforeUpload={file => {
                        //     const isPNG = file.type === 'image/png';
                        //     const isJPG = file.type === 'image/jpeg';
                        //     if (!isPNG && !isJPG) {
                        //         notification.error({
                        //             message: `${file.name} ${formatMessage({
                        //                 id: 'photo_modal.is_not_an_image'
                        //             })}!`
                        //         });
                        //     }

                        //     return isPNG || isJPG;
                        // }}
                        style={{
                            width: '100%'
                        }}
                    >
                        <Button
                            style={{
                                width: '100%'
                            }}
                        >
                            <FormattedMessage id='import_xlsx' />
                        </Button>
                    </Upload>

                    <div
                        style={{
                            marginTop: 24
                        }}
                    >
                        <Button
                            onClick={() => exportReport()}
                            style={{
                                width: '93%'
                            }}
                        >
                            <FormattedMessage id='export_xlsx' />
                        </Button>
                    </div>
                </div>
            </div>

            <div>
                <Table
                    bordered
                    columns={columns}
                    dataSource={abcReportInfo}
                    loading={isFetched}
                    pagination={filters.limit < 100 ? false : pagination}
                    scroll={{ x: 1920, y: 1000 }}
                    size='small'
                />
            </div>
            <Modal
                maskClosable={false}
                okButtonProps={{
                    disabled:
                        !modalData &&
                        !modalData.multiplicity &&
                        !modalData &&
                        !modalData.max &&
                        !modalData &&
                        !modalData.min
                }}
                onCancel={handleCancel}
                onOk={handleOk}
                open={visible}
                title={`${formatMessage({ id: 'edit_minmax' })}`}
                width='fit-content'
            >
                <div>
                    <FormattedMessage id='storage_document.multiplicity' />
                    <div>
                        <InputNumber
                            decimalSeparator=','
                            min={0}
                            onChange={value => {
                                setModalData({
                                    ...modalData,
                                    multiplicity: value
                                });
                            }}
                            step={modalData.multiplicity}
                            style={{
                                width: '100%'
                            }}
                            value={modalData && modalData.multiplicity}
                        />
                    </div>
                </div>
                <div>
                    Min:
                    <div>
                        <InputNumber
                            decimalSeparator=','
                            min={0}
                            onChange={value => {
                                setModalData({
                                    ...modalData,
                                    min: value
                                });
                            }}
                            step={modalData.multiplicity}
                            style={{
                                width: '100%'
                            }}
                            value={modalData && modalData.min * modalData.multiplicity}
                        />
                    </div>
                </div>
                <div>
                    Max:
                    <div>
                        <InputNumber
                            decimalSeparator=','
                            min={0}
                            onChange={value => {
                                setModalData({
                                    ...modalData,
                                    max: value
                                });
                            }}
                            style={{
                                width: '100%'
                            }}
                            value={modalData && modalData.max * modalData.multiplicity}
                        />
                    </div>
                </div>

                <div>
                    <div>
                        {' '}
                        <FormattedMessage id='services_table.short_specification' /> ABC
                    </div>
                    <div>
                        <Select
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={value => {
                                setModalData({
                                    ...modalData,
                                    classABCId: value
                                });
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'services_table.short_specification'
                            })}
                            showSearch
                            style={{ marginBottom: 8, display: 'block', width: 350 }}
                            value={modalData && modalData.classABCId}
                        >
                            {classesABC.map(elem => (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.key}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div>
                    <div>
                        {' '}
                        <FormattedMessage id='services_table.short_specification' /> XYZ
                    </div>
                    <div>
                        <Select
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={value => {
                                setModalData({
                                    ...modalData,
                                    classXYZId: value
                                });
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'services_table.short_specification'
                            })}
                            showSearch
                            style={{ marginBottom: 8, display: 'block', width: 350 }}
                            value={modalData && modalData.classXYZId}
                        >
                            {classesXYZ.map(elem => (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.key}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </Modal>
        </Layout>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    brands: selectBrands(state)
});

const mapDispatchToProps = {
    fetchWarehouses,
    fetchBrands
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ScmAbcReportsPage));
