import { Form } from '@ant-design/compatible';
import { Checkbox, Col, Input, InputNumber, Modal, Row, Select, TreeSelect } from 'antd';
import { DateRangePicker } from 'components';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { fetchSuppliers } from 'core/suppliers/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, filterTreeNodeByPart } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    brands: selectBrands(state),
    warehouses: state.warehouses.warehouses,
    suppliers: state.suppliers.suppliers
});

const mapDispatchToProps = {
    fetchBrands,
    fetchWarehouses,
    fetchSuppliers
};

const { Option } = Select;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AddRowsReturnSurplusesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            directSale: true,
            startDateIncome: undefined,
            endDateIncome: undefined
        };
    }

    componentDidMount() {
        this.props.fetchBrands();
        this.props.fetchWarehouses();
        this.props.fetchSuppliers();
    }

    onOk = async () => {
        await this.addRows();

        this.onCancel();

        this.props.getOrdersForSurpluses();
    };

    addRows = async () => {
        const {
            brandId,
            storeGroupId,
            warehouseId,
            supplierId,
            partNumber,
            warehouseBalanceMoreDay,
            minNumberOfDaysInStock,
            startDateIncome,
            endDateIncome,
            directSale
        } = this.state;

        await fetchAPI(
            'POST',
            'orders_ord_supplier_expense',
            null,
            {
                brandId,
                storeGroupId,
                warehouseId,
                supplierId,
                partNumber,
                warehouseBalanceMoreDay,
                minNumberOfDaysInStock,
                startDateIncome: !minNumberOfDaysInStock ? dayjs(startDateIncome).format('YYYY-MM-DD') : undefined,
                endDateIncome: !minNumberOfDaysInStock ? dayjs(endDateIncome).format('YYYY-MM-DD') : undefined,
                directSale
            },
            {
                handleErrorInternally: true
            }
        );
    };

    onCancel = () => {
        const { hideModal } = this.props;

        this.setState({
            brandId: undefined,
            storeGroupId: undefined,
            warehouseId: undefined,
            supplierId: undefined,
            partNumber: undefined,
            warehouseBalanceMoreDay: undefined,
            minNumberOfDaysInStock: undefined,
            startDateIncome: undefined,
            endDateIncome: undefined,
            directSale: undefined
        });

        hideModal();
    };

    render() {
        const {
            visible,

            intl: { formatMessage },
            brands,
            warehouses,
            suppliers,
            detailsTreeData
        } = this.props;

        const {
            brandId,
            warehouseId,
            supplierId,
            partNumber,
            warehouseBalanceMoreDay,
            minNumberOfDaysInStock,
            startDateIncome,
            endDateIncome,
            directSale,
            storeGroupId
        } = this.state;

        return (
            <div>
                <Modal
                    maskClosable={false}
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    title={
                        <React.Fragment>
                            <FormattedMessage id='spare_parts_workplace.hint_storage_surplus' />
                        </React.Fragment>
                    }
                    visible={visible}
                    width='50%'
                >
                    <Form>
                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_settings.warehouse' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(value, option) => {
                                        this.setState({
                                            warehouseId: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 220 }}
                                    value={warehouseId}
                                >
                                    {warehouses.map(elem => (
                                        <Option key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='storage.business_supplier' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(value, option) => {
                                        this.setState({
                                            supplierId: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 220 }}
                                    value={supplierId}
                                >
                                    {suppliers.map(supplier => (
                                        <Option key={supplier.id} value={supplier.id}>
                                            {supplier.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='storage.product_group' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <TreeSelect
                                    allowClear
                                    data-qa='tree_select_storeGroupId_order_detail_modal'
                                    filterTreeNode={filterTreeNodeByPart}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={async value => {
                                        await this.setState({ storeGroupId: value });
                                    }}
                                    placeholder={formatMessage({
                                        id: 'order_form_table.store_group'
                                    })}
                                    popupMatchSelectWidth={320}
                                    showSearch
                                    style={{
                                        minWidth: 220
                                    }}
                                    treeData={detailsTreeData}
                                    value={storeGroupId}
                                />
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='brand' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(value, option) => {
                                        this.setState({
                                            brandId: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 220 }}
                                    value={brandId}
                                >
                                    {brands.map(({ brandId, brandName }) => (
                                        <Option key={brandId} value={brandId}>
                                            {brandName}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='storage.product_code' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input
                                    onChange={event => {
                                        this.setState({
                                            partNumber: event.target.value
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'storage.product_code'
                                    })}
                                    style={{ marginBottom: 8, display: 'block', width: 220 }}
                                    value={partNumber}
                                />
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='spare_parts_workplace.warehouse_balance_is_more' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    decimalSeparator=','
                                    min={0}
                                    onChange={warehouseBalanceMoreDay => {
                                        this.setState({
                                            warehouseBalanceMoreDay
                                        });
                                    }}
                                    precision={2}
                                    value={warehouseBalanceMoreDay}
                                />
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='spare_parts_workplace.min_warehouse_days' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={startDateIncome || endDateIncome}
                                    min={0}
                                    onChange={minNumberOfDaysInStock => {
                                        this.setState({
                                            minNumberOfDaysInStock
                                        });
                                    }}
                                    value={minNumberOfDaysInStock}
                                />
                            </Col>
                        </Row>

                        {!minNumberOfDaysInStock && (
                            <Row className={Styles.row}>
                                <Col span={10}>
                                    <div className={Styles.colText}>
                                        <FormattedMessage id='storage_document.date_done' />
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <DateRangePicker
                                        allowClear
                                        dateRange={[dayjs(startDateIncome), dayjs(endDateIncome)]}
                                        onDateChange={([startDate, endDate]) => {
                                            this.setState({
                                                startDateIncome: startDate,
                                                endDateIncome: endDate
                                            });
                                        }}
                                        overlayStyle={{ zIndex: '5000' }}
                                        style={{
                                            marginTop: 6
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='spare_parts_workplace.only_by_products' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={directSale}
                                    onChange={() => {
                                        this.setState({
                                            directSale: !directSale
                                        });
                                    }}
                                    style={{
                                        marginTop: 6
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}
