import { Input, Modal, Select, notification } from 'antd';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildSupplierOptions, fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const operationTypes = {
    DIRECT: 'entries_tab.DIRECT',
    STORNO: 'entries_tab.STORNO'
};

const mapStateToProps = state => ({
    user: state.auth,
    clients: state.clients.clients,
    businessRequisites: selectBusinessRequisites(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchClients,
    setClientsSearchFilter,
    fetchBusinessRequisites
};

const AddAccTemplateModal = ({
    open = false,
    hideModal,
    intl: { formatMessage },
    fetchAccTemplates,
    user,
    businessRequisites,
    fetchBusinessRequisites,
    onlyTemplates,
    isMobile,
    edit,
    ...props
}) => {
    const [name, setTemplateName] = useState();
    const [businessRequisiteId, setBusinessRequisiteId] = useState();
    const [counterpartType, setCounterpartyType] = useState();
    const [counterpartId, setCounterpartId] = useState();
    const [warehouseId, setWarehouseId] = useState();
    const [cashBoxId, setCashboxId] = useState();
    const [comment, setComment] = useState(' ');
    const [operationType, setOperationType] = useState('DIRECT');
    const [documentDatetime, setDocumentDatetime] = useState(dayjs());
    const [cashboxes, setCashboxes] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [templateData, setTemplateData] = useState();

    const fetchCashboxes = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'cash_boxes',
            {
                query,
                disabled: isGrantAccessed(user, grants.SETTINGS_CASH_REGISTERS_HIDDEN_CASH_BOX, accesses.ROWO)
                    ? true
                    : undefined
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setCashboxes(response);
    }, []);

    const fetchWarehouses = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'warehouses',
            {
                query,
                disabled: isGrantAccessed(user, grants.SETTINGS_STORES_HIDDEN_WAREHOUSE, accesses.ROWO)
                    ? true
                    : undefined
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setWarehouses(response);
    }, []);

    const fetchSuppliers = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                all: true,
                cut: true,
                query
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setSuppliers(response);
    }, []);

    const fetchEmployees = useCallback(async query => {
        const response = await fetchAPI('GET', '/employees', { query, disabled: false }, null, {
            handleErrorInternally: true
        });

        setEmployees(response);
    }, []);

    const addTemplate = async () => {
        await fetchAPI(
            'POST',
            '/general_ledger/acc_document_template',
            undefined,
            {
                name,

                counterpartId,
                counterpartType: counterpartType || 'DIRECT',
                warehouseId,
                cashBoxId,
                comment,
                operationType,
                businessRequisiteId
            },
            { handleErrorInternally: true }
        );
        await notification.success({
            message: formatMessage({ id: 'message.success' })
        });
    };

    const editTemplate = async () => {
        await fetchAPI(
            'PUT',
            '/general_ledger/acc_document_template',
            undefined,
            {
                id: edit && edit.id,
                name,
                counterpartId,

                counterpartType: counterpartType || 'DIRECT',
                warehouseId,
                cashBoxId,
                comment,
                operationType,
                businessRequisiteId
            },
            { handleErrorInternally: true }
        );
        await notification.success({
            message: formatMessage({ id: 'message.success' })
        });
    };

    const onClose = () => {
        setTemplateName(undefined);
        setCounterpartId(undefined);
        setCounterpartyType(undefined);
        setCashboxId(undefined);
        setWarehouseId(undefined);
        setComment(undefined);
        setTemplateData(undefined);

        hideModal();
        if (fetchAccTemplates) {
            setTimeout(() => fetchAccTemplates(), 100);
        }
    };

    useEffect(() => {
        fetchCashboxes();
        fetchWarehouses();
        fetchBusinessRequisites();
    }, [fetchCashboxes, fetchWarehouses]);

    const handleSearchSuppliers = useMemo(() => {
        const loadOptions = value => {
            fetchSuppliers(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [fetchSuppliers]);

    const handleSearchEmployees = useMemo(() => {
        const loadOptions = value => {
            fetchEmployees(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [fetchEmployees]);

    const handleSearchClients = useMemo(() => {
        const loadOptions = value => {
            props.setClientsSearchFilter(value);
            props.fetchClients();
        };

        return _.debounce(loadOptions, 1000);
    }, [props]);

    useEffect(() => {
        if (edit) {
            const name = edit.counterpartName && edit.counterpartName.split(' ');
            setTemplateName(edit.name);
            setCounterpartId(edit.counterpartId);
            setCounterpartyType(edit.counterpartType);
            setCashboxId(edit.cashBoxId);
            setWarehouseId(edit.warehouseId);
            setComment(edit.comment);

            if (edit.counterpartType === 'SUPPLIERS') {
                fetchSuppliers(name[0]);
            }
            if (edit.counterpartType === 'EMPLOYEES') {
                fetchEmployees(name[0]);
            }
            if (edit.counterpartType === 'CLIENTS') {
                props.setClientsSearchFilter(name[0]);
                props.fetchClients();
            }
        }
    }, [edit]);

    const onFinish = async () => {
        if (edit) {
            editTemplate();
        } else {
            addTemplate();
        }

        await onClose();
    };

    const disabled = !name || !documentDatetime || !operationType;

    return (
        <Modal
            okButtonProps={{
                disabled
            }}
            onCancel={hideModal}
            onOk={() => onFinish()}
            open={open}
            title={
                edit ? (
                    <FormattedMessage id='complexes.edit_template' />
                ) : (
                    <FormattedMessage id='complexes.create_template' />
                )
            }
            width={onlyTemplates && isMobile ? '25%' : isMobile ? '95%' : '50% '}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 6
                }}
            >
                <React.Fragment>
                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='storage.name' />
                            <span className={Styles.rules}>*</span>
                        </div>

                        <Input
                            min={0}
                            onChange={async event => {
                                await setTemplateName(event.target.value);
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'storage.name'
                            })}
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={name}
                        />
                    </div>

                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='interactions.counterparty_type' />
                        </div>
                        <Select
                            onChange={value => {
                                setCounterpartyType(value);
                                setCounterpartId(undefined);
                                if (value === 'SUPPLIERS' && !_.get(suppliers, 'length')) {
                                    fetchSuppliers();
                                }
                                if (value === 'EMPLOYEES' && !_.get(employees, 'length')) {
                                    fetchEmployees();
                                }
                                if (value === 'CLIENTS' && !_.get(props.clients, 'length')) {
                                    props.fetchClients();
                                }
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'interactions.counterparty_type'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={counterpartType}
                        >
                            <Option key='SUPPLIERS' value='SUPPLIERS'>
                                <FormattedMessage id='entries_tab.SUPPLIER' />
                            </Option>
                            <Option key='EMPLOYEES' value='EMPLOYEES'>
                                <FormattedMessage id='entries_tab.EMPLOYEE' />
                            </Option>
                            <Option key='CLIENTS' value='CLIENTS'>
                                <FormattedMessage id='entries_tab.CLIENT' />
                            </Option>
                        </Select>
                    </div>
                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='entries_tab.CONTRAGENT' />
                        </div>
                        <Select
                            disabled={!counterpartType}
                            onChange={value => {
                                setCounterpartId(value);
                            }}
                            onSearch={input => {
                                if (counterpartType === 'SUPPLIERS') {
                                    handleSearchSuppliers(input);
                                }
                                if (counterpartType === 'EMPLOYEES') {
                                    handleSearchEmployees(input);
                                }
                                if (counterpartType === 'CLIENTS') {
                                    handleSearchClients(input);
                                }
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'entries_tab.CONTRAGENT'
                            })}
                            showSearch
                            style={{ width: '100%' }}
                            value={counterpartId}
                        >
                            {counterpartType === 'SUPPLIERS'
                                ? buildSupplierOptions(suppliers, {
                                      id: counterpartId,
                                      name: edit && edit.counterpartName
                                  }).map(supplier => (
                                      <Option key={supplier.id} value={supplier.id}>
                                          {`${supplier.name}`}
                                      </Option>
                                  ))
                                : counterpartType === 'EMPLOYEES'
                                ? buildSupplierOptions(employees, {
                                      id: counterpartId,
                                      name: edit && edit.counterpartName
                                  }).map(employee => (
                                      <Option key={employee.id} value={employee.id}>
                                          {`${employee.name} ${employee.surname}`}
                                      </Option>
                                  ))
                                : counterpartType === 'CLIENTS'
                                ? buildSupplierOptions(props.clients, {
                                      id: counterpartId,
                                      name: edit && edit.counterpartName
                                  }).map(client => (
                                      <Option
                                          key={client.clientId}
                                          phone={_.get(client, 'phones[0]')}
                                          value={client.clientId}
                                          vehicles={_.get(client, 'vehicles')}
                                      >
                                          {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                              client,
                                              'phones[0]',
                                              ''
                                          )}`}
                                      </Option>
                                  ))
                                : []}
                        </Select>
                    </div>
                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='entry_table.operation_type' />
                            <span className={Styles.rules}>*</span>
                        </div>
                        <Select
                            onChange={value => {
                                setOperationType(value);
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'interactions.counterparty_type'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={operationType}
                        >
                            {Object.entries(operationTypes).map(([key, value]) => (
                                <Option key={key} value={key}>
                                    <FormattedMessage id={value} />
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='navigation.storage' />
                        </div>
                        <Select
                            onChange={value => {
                                setWarehouseId(value);
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'navigation.storage'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={warehouseId}
                        >
                            {warehouses.map(elem => (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='supplier.cashbox_id' />
                        </div>
                        <Select
                            onChange={value => {
                                setCashboxId(value);
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'supplier.cashbox_id'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={cashBoxId}
                        >
                            {cashboxes.map(({ name, id }) => (
                                <Option key={id} value={id}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='storage.business_requisites' />
                        </div>
                        <Select
                            onChange={value => {
                                setBusinessRequisiteId(value);
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'storage.business_requisites'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={businessRequisiteId}
                        >
                            {businessRequisites
                                .filter(({ used }) => used)
                                .map(elem => (
                                    <Select.Option key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='comment' />
                        </div>
                        <Input.TextArea
                            autoFocus
                            onChange={event => {
                                setComment(event.target.value);
                            }}
                            style={{ width: '100%', minHeight: '150px' }}
                            value={comment}
                        />
                    </div>
                </React.Fragment>
            </div>
        </Modal>
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddAccTemplateModal));
