/* eslint-disable max-classes-per-file */
import {
    DeleteOutlined,
    EditOutlined,
    MenuOutlined,
    PlusOutlined,
    PlusSquareOutlined,
    QuestionCircleOutlined,
    ThunderboltOutlined,
    ToolOutlined
} from '@ant-design/icons';
import { Button, Drawer, Dropdown, Image, Menu, Modal, Tooltip, notification } from 'antd';
import { Catcher } from 'commons';
import { DraggableTable, EmployeeSupplierSelector, HamburgerMenu } from 'components';
import _, { get, pick } from 'lodash';
import { AddQuickRequestsModal, ComplexesModal, OrderRequestModal, RequestCommentaryButton } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { PencilIcon } from 'theme';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { SubMenu } = Menu;

const statuses = [
    {
        status: 'INACTIVE',
        statusColor: '#f44336'
    },
    {
        status: 'IN_PROGRESS',
        statusColor: '#ff9800'
    },
    {
        status: 'STOPPED',
        statusColor: '#4caf50'
    },
    {
        status: 'DONE',
        statusColor: '#2196f3'
    },
    {
        status: 'CANCELED',
        statusColor: '#673ab7'
    }
];

@injectIntl
class RequestsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            selectedRowKeys: [],
            selectedRows: [],

            counterpartyTreeData: [],
            helperDrawerOpen: false,
            allLinks: [],
            alsoProducts: false
        };

        this.columns = () => [
            {
                title: () => {
                    const {
                        orderId,
                        intl: { formatMessage },
                        selectedClient
                    } = this.props;
                    const { selectedRows, selectedRowKeys } = this.state;

                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <SubMenu
                                key='groupDetailsMenu.changeResponsible'
                                data-qa='sub_menu_responsible_details_menu_details_table_order_page'
                                disabled={!selectedRows.length}
                                title={
                                    <React.Fragment>
                                        <ToolOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                        {this.props.intl.formatMessage({
                                            id: 'order_form_table_requests.appoint_mechanic'
                                        })}
                                    </React.Fragment>
                                }
                            >
                                {this.props.employees
                                    .filter(({ posts }) => posts.find(({ postName }) => postName === 'MECHANIC'))
                                    .map(({ id: employeeId, surname, name }) => (
                                        <Menu.Item
                                            key={`groupDetailsMenu.changeResponsible.${employeeId}`}
                                            disabled={!selectedRowKeys.length}
                                            onClick={async () => {
                                                const payload = {
                                                    updateMode: true,
                                                    laborRequests: [
                                                        ...this.state.selectedRows.map(request => ({
                                                            ...this.getRequestFields(request),
                                                            counterparty: 'EMPLOYEE',
                                                            businessSupplierId: null,
                                                            employeeId
                                                        }))
                                                    ],
                                                    details: [],
                                                    services: []
                                                };
                                                await fetchAPI(
                                                    'PUT',
                                                    `orders/${this.props.orderId}`,
                                                    undefined,
                                                    payload
                                                );
                                                await this.updateDataSource();
                                            }}
                                        >
                                            {surname} {name}
                                        </Menu.Item>
                                    ))}
                            </SubMenu>
                            <SubMenu
                                key='groupDetailsMenu.changeStatus'
                                data-qa='sub_menu_group_details_menu_details_table_order_page'
                                disabled={!selectedRowKeys.length}
                                title={
                                    <React.Fragment>
                                        <EditOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                        {this.props.intl.formatMessage({
                                            id: 'profile.spare_parts_workplace.change_status'
                                        })}
                                    </React.Fragment>
                                }
                            >
                                {statuses.map(({ status }) => (
                                    <Menu.Item
                                        key={`groupDetailsMenu.changeStatus.${status}`}
                                        onClick={async () => {
                                            const payload = {
                                                updateMode: true,
                                                services: [],
                                                details: [],
                                                laborRequests: selectedRows.map(request => ({
                                                    ...this.getRequestFields(request),
                                                    stage: status
                                                }))
                                            };
                                            await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                            await this.updateDataSource();
                                        }}
                                    >
                                        {this.props.intl.formatMessage({ id: `status_requests.${status}` })}
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                            <Menu.Item key='groupRecommendationsMenu.deleted' disabled={!selectedRowKeys.length}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                await fetchAPI(
                                                    'DELETE',
                                                    `orders/${orderId}/labors_request`,
                                                    {
                                                        ids: `[${this.state.selectedRows
                                                            .filter(
                                                                ({ stage }) => stage !== 'DONE' && stage !== 'CANCELED'
                                                            )
                                                            .map(({ id }) => id)}]`
                                                    },
                                                    undefined,
                                                    { handleErrorInternally: true }
                                                );
                                                await notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.deleted'
                                                    })
                                                });
                                                this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                key='groupRecommendationsMenu.addToOrder'
                                disabled={
                                    !selectedRowKeys.length ||
                                    selectedRows.find(({ complexId, customComplexId }) => complexId || customComplexId)
                                }
                            >
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'order_form_table.diagnostic.create_order'
                                            }),
                                            onOk: async () => {
                                                const { status } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (status === 'success') {
                                                    window.location.reload();

                                                    return;
                                                }
                                                const arr = this.state.selectedRows.filter(
                                                    ({ stage, serviceId }) =>
                                                        stage !== 'DONE' && stage !== 'CANCELED' && serviceId
                                                );
                                                const defaultUse = _.get(
                                                    this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                                                    '[0].id'
                                                );

                                                const payload = {
                                                    insertMode: true,
                                                    services: [
                                                        ...arr.map(row => ({
                                                            serviceId: row.serviceId,
                                                            serviceName: `${row.serviceName} ${
                                                                row.laborRequestName.comment || ''
                                                            }`,
                                                            comment: row.employeeComment || {
                                                                comment: null,
                                                                positions: [],
                                                                problems: []
                                                            },
                                                            employeeId: row.employeeId || this.props.mechanicId || null,
                                                            counterparty: row.counterparty,
                                                            businessSupplierId: row.businessSupplierId || null,
                                                            count: row.count || 1,
                                                            serviceHours: 0,
                                                            servicePrice: Number(row.servicePrice),
                                                            laborUnitId: row.laborUnitId || defaultUse,
                                                            serviceRowDiscount:
                                                                selectedClient.laborsDiscount || selectedClient.discount
                                                        }))
                                                    ],
                                                    details: [
                                                        ...arr.map(row => ({
                                                            storeGroupId: row.storeGroupId,
                                                            name: row.storeGroupName,
                                                            comment: row.employeeComment || {
                                                                comment: null,
                                                                positions: [],
                                                                problems: []
                                                            },
                                                            partRowDiscount:
                                                                selectedClient.detailsDiscount ||
                                                                selectedClient.discount
                                                        }))
                                                    ]
                                                };

                                                if (this.props.tecdocId) {
                                                    payload.modificationId = this.props.tecdocId;
                                                }
                                                const updatePayload = {
                                                    updateMode: true,
                                                    services: [],
                                                    details: [],
                                                    laborRequests: arr.map(request => ({
                                                        ...this.getRequestFields(request),
                                                        stage: 'DONE'
                                                    }))
                                                };
                                                await fetchAPI(
                                                    'PUT',
                                                    `orders/${this.props.orderId}`,
                                                    undefined,
                                                    payload,
                                                    {
                                                        handleErrorInternally: true
                                                    }
                                                );
                                                await fetchAPI(
                                                    'PUT',
                                                    `orders/${this.props.orderId}`,
                                                    undefined,
                                                    updatePayload,
                                                    {
                                                        handleErrorInternally: true
                                                    }
                                                );
                                                this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'order_form_table.diagnostic.create_order' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} />;
                },
                key: 'actions',
                align: 'center',
                render: row => {
                    const currentStage = get(row, 'stage');

                    const { selectedClient } = this.props;
                    const { alsoProducts } = this.state;

                    const isDetails = JSON.parse(localStorage.getItem('_my.carbook.relatedDetailCheckbox')) || false;

                    const actionsMenu = () => (
                        <Menu>
                            <Menu.Item
                                key='recommendationActionMenu.delete'
                                disabled={currentStage === 'DONE' || currentStage === 'CANCELED'}
                            >
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                await fetchAPI(
                                                    'DELETE',
                                                    `orders/${this.props.orderId}/labors_request`,
                                                    { ids: `[${row.id}]` },
                                                    undefined,
                                                    { handleErrorInternally: true }
                                                );
                                                await notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.deleted'
                                                    })
                                                });
                                                this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item disabled={currentStage === 'DONE' || currentStage === 'CANCELED'}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'order_form_table.diagnostic.create_order'
                                            }),
                                            onOk: async () => {
                                                const { status } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (status === 'success') {
                                                    window.location.reload();

                                                    return;
                                                }
                                                const defaultUse = _.get(
                                                    this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                                                    '[0].id'
                                                );
                                                if (row.complexId) {
                                                    await this.fetchCurrentComplex(row.complexId);
                                                } else if (row.customComplexId) {
                                                    await this.fetchCurrentComplex(undefined, row.customComplexId);
                                                }

                                                const payload = {
                                                    insertMode: true,
                                                    services: this.state.complex
                                                        ? [
                                                              ...this.state.complex.labors.map(elem => ({
                                                                  serviceId: elem.id || elem.laborLaborId,
                                                                  serviceName: `${elem.name}`,
                                                                  comment: row.employeeComment || {
                                                                      comment: null,
                                                                      positions: [],
                                                                      problems: []
                                                                  },
                                                                  employeeId:
                                                                      row.employeeId || this.props.mechanicId || null,
                                                                  counterparty: row.counterparty,
                                                                  businessSupplierId: null,
                                                                  count: elem.laborCount || 1,
                                                                  serviceHours: 0,
                                                                  laborUnitId: elem.laborUnitId || defaultUse,
                                                                  serviceRowDiscount:
                                                                      selectedClient.laborsDiscount ||
                                                                      selectedClient.discount
                                                              }))
                                                          ]
                                                        : this.state.customComplex
                                                        ? [
                                                              ...this.state.customComplex.labors.map(elem => ({
                                                                  serviceId: elem.laborLaborId,
                                                                  serviceName: `${elem.laborName}`,
                                                                  comment: row.employeeComment || {
                                                                      comment: null,
                                                                      positions: [],
                                                                      problems: []
                                                                  },
                                                                  employeeId:
                                                                      row.employeeId || this.props.mechanicId || null,
                                                                  counterparty: row.counterparty,
                                                                  businessSupplierId: null,
                                                                  count: elem.laborCount || 1,
                                                                  serviceHours: 0,
                                                                  laborUnitId: elem.laborUnitId || defaultUse,
                                                                  serviceRowDiscount:
                                                                      selectedClient.laborsDiscount ||
                                                                      selectedClient.discount
                                                              }))
                                                          ]
                                                        : [
                                                              {
                                                                  serviceId: row.serviceId,
                                                                  serviceName: `${row.serviceName}`,
                                                                  employeeId:
                                                                      row.employeeId || this.props.mechanicId || null,
                                                                  counterparty: row.counterparty,
                                                                  comment: row.employeeComment || {
                                                                      comment: null,
                                                                      positions: [],
                                                                      problems: []
                                                                  },
                                                                  businessSupplierId: row.businessSupplierId || null,
                                                                  count: row.count || 1,
                                                                  serviceHours: 0,
                                                                  laborUnitId: row.laborUnitId || defaultUse,
                                                                  servicePrice: Number(row.servicePrice),
                                                                  serviceRowDiscount:
                                                                      selectedClient.laborsDiscount ||
                                                                      selectedClient.discount
                                                              }
                                                          ],
                                                    details:
                                                        this.state.complex && isDetails
                                                            ? [
                                                                  ...this.state.complex.storeGroups.map(elem => ({
                                                                      storeGroupId: elem.id,
                                                                      name: elem.name,
                                                                      comment: row.employeeComment || {
                                                                          comment: null,
                                                                          positions: [],
                                                                          problems: []
                                                                      },
                                                                      partRowDiscount:
                                                                          selectedClient.detailsDiscount ||
                                                                          selectedClient.discount
                                                                  }))
                                                              ]
                                                            : this.state.customComplex && isDetails
                                                            ? [
                                                                  ...this.state.customComplex.products
                                                                      .filter(
                                                                          ({ productStoreGroupId }) =>
                                                                              productStoreGroupId
                                                                      )
                                                                      .map(elem => ({
                                                                          storeGroupId: elem.productStoreGroupId,
                                                                          name: elem.productName,
                                                                          comment: row.employeeComment || {
                                                                              comment: null,
                                                                              positions: [],
                                                                              problems: []
                                                                          },
                                                                          partRowDiscount:
                                                                              selectedClient.detailsDiscount ||
                                                                              selectedClient.discount
                                                                      }))
                                                              ]
                                                            : isDetails
                                                            ? [
                                                                  {
                                                                      storeGroupId: row.storeGroupId,
                                                                      name: row.storeGroupName,
                                                                      comment: row.employeeComment || {
                                                                          comment: null,
                                                                          positions: [],
                                                                          problems: []
                                                                      },
                                                                      partRowDiscount:
                                                                          selectedClient.detailsDiscount ||
                                                                          selectedClient.discount
                                                                  }
                                                              ]
                                                            : []
                                                };

                                                if (this.props.tecdocId) {
                                                    payload.modificationId = this.props.tecdocId;
                                                }

                                                await fetchAPI(
                                                    'PUT',
                                                    `orders/${this.props.orderId}`,
                                                    undefined,
                                                    payload,
                                                    {
                                                        handleErrorInternally: true
                                                    }
                                                );

                                                this.updateRequest({ ...row, stage: 'DONE' });

                                                this.setState({
                                                    complex: undefined,
                                                    customComplex: undefined
                                                });
                                            }
                                        });
                                    }}
                                >
                                    <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'order_form_table.diagnostic.create_order' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <HamburgerMenu actionsMenu={actionsMenu}>
                            <Button
                                data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                icon={<MenuOutlined />}
                            />
                        </HamburgerMenu>
                    );
                }
            },
            {
                title: () => (
                    <div className={Styles.headerActions}>
                        <Tooltip placement='top' title={<FormattedMessage id='add' />}>
                            <Button
                                data-qa='btn_show_service_product_modal_services_table_order_page'
                                disabled={this.props.disabled}
                                icon={<PlusOutlined />}
                                onClick={() =>
                                    this.setState({
                                        request: {}
                                    })
                                }
                            />
                        </Tooltip>
                        <Tooltip placement='top' title={<FormattedMessage id='navigation.quick_requests' />}>
                            <Button
                                disabled={this.props.disabled}
                                icon={<ThunderboltOutlined />}
                                onClick={() =>
                                    this.setState({
                                        visisibleRequestsModal: true
                                    })
                                }
                            />
                        </Tooltip>
                        <ComplexesModal
                            defaultEmployeeId={this.props.defaultEmployeeId}
                            details={this.props.details}
                            detailsTreeData={this.props.detailsTreeData}
                            disabled
                            handleSearchLabors={this.handleSearchLabors}
                            labors={this.props.labors}
                            laborTimeMultiplier={this.props.laborTimeMultiplier}
                            normHourPrice={this.props.normHourPrice}
                            orderId={this.props.orderId}
                            reloadOrderForm={this.props.reloadOrderForm}
                            tecdocId={this.props.tecdocId}
                        />
                    </div>
                ),
                key: 'buttonGroup',
                align: 'center',
                render: row => {
                    const currentStage = get(row, 'stage');

                    let detail = get(row.employeeComment, 'comment', '');
                    if (detail && detail.indexOf(' - ') > -1) {
                        detail = detail.slice(0, detail.indexOf(' - '));
                    }

                    return (
                        <div className={Styles.rowActions}>
                            <Tooltip
                                placement='top'
                                title={<FormattedMessage id='order_form_table_requests.add_edit_request' />}
                            >
                                <Button
                                    data-qa='btn_add_edit_button_service_product_modal_services_table_order_page'
                                    disabled={currentStage === 'DONE' || currentStage === 'CANCELED'}
                                    icon={<PencilIcon />}
                                    onClick={() =>
                                        this.setState({
                                            request: { ...row }
                                        })
                                    }
                                />
                            </Tooltip>
                            <RequestCommentaryButton
                                commentary={
                                    row.employeeComment || {
                                        comment: undefined,
                                        positions: []
                                    }
                                }
                                data-qa='button_comment_add_service_modal'
                                detail={detail}
                                setComment={(comment, positions) => {
                                    this.updateRequest({
                                        ...row,
                                        employeeComment: {
                                            comment,
                                            positions
                                        }
                                    });
                                }}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table_requests.the_reason_for_the_appeal' />,
                key: 'laborRequestName',
                dataIndex: 'laborRequestName',
                render: laborRequestName => get(laborRequestName, 'comment')
            },
            // {
            //     title: <FormattedMessage id='order_form_table_requests.node' />,
            //     key: 'groupName',
            //     dataIndex: 'groupName'
            // },
            {
                title: <FormattedMessage id='order_form_table_requests.mechanic' />,
                key: 'mechanic',
                render: row => {
                    const employeesOptions = this.props.employees
                        .filter(
                            ({ posts, disabled }) =>
                                !disabled && posts.map(({ postName }) => postName).includes('MECHANIC')
                        )
                        .map(({ name, surname, id }) => ({
                            value: id,
                            label: `${surname || ''} ${name || ''}`
                        }));

                    const suppliersOptions = this.props.suppliers.map(({ name, id }) => ({
                        value: id,
                        label: name
                    }));

                    return (
                        <EmployeeSupplierSelector
                            disabled={this.props.disabled}
                            employeesOptions={employeesOptions}
                            initialState={{
                                type: row.counterparty,
                                options:
                                    row.employeeId || row.businessSupplierId
                                        ? [
                                              {
                                                  value: row.employeeId || row.businessSupplierId,
                                                  label: row.employeeId ? row.employeeName : row.businessSupplierName
                                              }
                                          ]
                                        : undefined
                            }}
                            onChange={async (value, type) => {
                                if (!value) {
                                    await fetchAPI(
                                        'PUT',
                                        `orders/${this.props.orderId}`,
                                        undefined,
                                        {
                                            updateMode: true,
                                            laborRequests: [
                                                {
                                                    ...this.getRequestFields(row),
                                                    serviceId: Number(row.serviceId),
                                                    counterparty: type,
                                                    employeeId: null,
                                                    businessSupplierId: null
                                                }
                                            ],
                                            services: [],
                                            details: []
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    await this.updateDataSource();
                                } else {
                                    await fetchAPI(
                                        'PUT',
                                        `orders/${this.props.orderId}`,
                                        undefined,
                                        {
                                            updateMode: true,
                                            laborRequests: [
                                                {
                                                    ...this.getRequestFields(row),
                                                    serviceId: Number(row.serviceId),
                                                    counterparty: type || 'EMPLOYEE',
                                                    employeeId: type === 'EMPLOYEE' ? value.key : null,
                                                    businessSupplierId: type === 'SUPPLIER' ? value.key : null
                                                }
                                            ],
                                            details: [],
                                            services: []
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    await this.updateDataSource();
                                }
                            }}
                            requestModal
                            suppliersOptions={suppliersOptions}
                            value={row.employeeId || row.businessSupplierId}
                        />
                        // <TreeSelect
                        //     allowClear
                        //     data-qa='tree_select_counterparty_employee_services_table_order_page'
                        //     disabled={currentStage === 'DONE' || currentStage === 'CANCELED'}
                        //     popupMatchSelectWidth={280}
                        //     filterTreeNode={(input, node) => {
                        //         return (
                        //             node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        //             String(node.props.value).indexOf(input.toLowerCase()) >= 0
                        //         );
                        //     }}
                        //     listHeight={440}
                        //     onChange={async value => {
                        //         if (!value) {
                        //             await fetchAPI(
                        //                 'PUT',
                        //                 `orders/${this.props.orderId}`,
                        //                 undefined,
                        //                 {
                        //                     updateMode: true,
                        //                     laborRequests: [
                        //                         {
                        //                             ...this.getRequestFields(row),
                        //                             serviceId: Number(row.serviceId),
                        //                             counterparty: null,
                        //                             employeeId: null,
                        //                             businessSupplierId: null
                        //                         }
                        //                     ]
                        //                 },
                        //                 { handleErrorInternally: true }
                        //             );
                        //             await this.updateDataSource();
                        //         }
                        //     }}
                        //     onSelect={async (valueString, option) => {
                        //         const value = JSON.parse(valueString);
                        //         await fetchAPI(
                        //             'PUT',
                        //             `orders/${this.props.orderId}`,
                        //             undefined,
                        //             {
                        //                 updateMode: true,
                        //                 laborRequests: [
                        //                     {
                        //                         ...this.getRequestFields(row),
                        //                         serviceId: Number(row.serviceId),
                        //                         counterparty: value.counterparty,
                        //                         employeeId: value.counterparty === 'EMPLOYEE' ? value.id : null,
                        //                         businessSupplierId: value.counterparty === 'SUPPLIER' ? value.id : null
                        //                     }
                        //                 ],
                        //                 details: [],
                        //                 services: []
                        //             },
                        //             { handleErrorInternally: true }
                        //         );
                        //         await this.updateDataSource();
                        //     }}
                        //     placeholder={
                        //         <React.Fragment>
                        //             <FormattedMessage id='order_form_table.master' /> /{' '}
                        //             <FormattedMessage id='order_form_table.supplier' />
                        //         </React.Fragment>
                        //     }
                        //     showSearch
                        //     treeData={this.state.counterpartyTreeData}
                        //     treeDefaultExpandedKeys={['EMPLOYEE']}
                        //     value={
                        //         row.employeeId || row.businessSupplierId
                        //             ? JSON.stringify({
                        //                   counterparty: row.counterparty || 'EMPLOYEE',
                        //                   id: row.counterparty === 'SUPPLIER' ? row.businessSupplierId : row.employeeId
                        //               })
                        //             : undefined
                        //     }
                        // />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table_requests.mechanic_comment' />,
                key: 'employeeComment',
                dataIndex: 'employeeComment',
                render: employeeComment => get(employeeComment, 'comment')
            },
            {
                title: <FormattedMessage id='order_form_table_requests.type' />,

                key: 'actionType',
                dataIndex: 'actionType',
                render: action => <FormattedMessage id={`status_requests.${action}`} />
            },

            {
                title: () => (
                    <div>
                        <FormattedMessage id='order_form_table_requests.labor' /> /{' '}
                        <FormattedMessage id='services_table.complex' />
                    </div>
                ),
                key: 'serviceName',
                align: 'center',
                render: (data, row) => {
                    const serviceId = `${String(row.serviceId).substring(0, 4)}-${String(row.serviceId).substring(4)}`;

                    return row.complexId ? (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700
                                }}
                            >
                                <FormattedMessage id='services_table.complex' />:
                            </div>
                            <div style={{ fontSize: 12 }}>{row.complexName}</div>
                        </div>
                    ) : row.customComplexId ? (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700
                                }}
                            >
                                <FormattedMessage id='services_table.complex' />:
                            </div>
                            <div style={{ fontSize: 12 }}>{row.customComplexName}</div>
                        </div>
                    ) : (
                        <div>
                            <div>{row.serviceName || <FormattedMessage id='long_dash' />}</div>

                            {isGrantAccessed(this.props.user, grants.DIRECTORIES_JOBS) ? (
                                <Link
                                    style={{ textDecoration: 'underline' }}
                                    to={{
                                        pathname: book.laborsPage,
                                        state: {
                                            laborId: serviceId.replace('-', '')
                                        }
                                    }}
                                >
                                    <div style={{ fontSize: 12 }}>{serviceId}</div>
                                </Link>
                            ) : (
                                <div style={{ fontSize: 12 }}>{serviceId}</div>
                            )}
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_form_table_requests.status' />,
                key: 'stage',
                dataIndex: 'stage',
                align: 'center',
                render: (data, row) => {
                    const currentStatus = (statuses || []).find(({ status }) => status === data);

                    const menu = (
                        <Menu>
                            {statuses.map(({ status }) => (
                                <Menu.Item
                                    key={status}
                                    data-qa='menu_item_status_details_table'
                                    disabled={row.reservedCount}
                                    onClick={() => {
                                        row.stage = status;
                                        this.updateRequest(row);
                                    }}
                                >
                                    <FormattedMessage id={`status_requests.${status}`} />
                                </Menu.Item>
                            ))}
                        </Menu>
                    );

                    return (
                        <Dropdown
                            data-qa='dropdown_current_details_table'
                            disabled={this.props.disabled}
                            overlay={menu}
                            trigger={['click']}
                        >
                            <Tooltip title={<FormattedMessage id={`status_requests.${data}`} />}>
                                <div
                                    style={{
                                        border: `2px solid ${get(currentStatus, 'statusColor')}`,
                                        padding: '6px 2px',
                                        textAlign: 'center',
                                        fontWeight: 500,
                                        borderRadius: 6
                                    }}
                                >
                                    <FormattedMessage id={`status_requests.${data}`} />
                                </div>
                            </Tooltip>
                        </Dropdown>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.fetchComplexData();

        this.setState({
            dataSource: this.props.orderLaborsRequest,
            fetched: true
        });
    }

    handleSearchLabors = _.debounce(value => {
        this.props.fetchLabors(value);
    }, 500);

    async componentDidUpdate(prevProps) {
        if (
            (prevProps.activeKey != 'request' && this.props.activeKey == 'request') ||
            prevProps.orderLaborsRequest != this.props.orderLaborsRequest
        ) {
            await this.setState({
                dataSource: this.props.orderLaborsRequest
            });
        }
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_request_data' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    fetchCurrentComplex = async (complexId, customComplexId) => {
        if (complexId) {
            const response = await fetchAPI('GET', '/complex', { id: complexId }, undefined, {
                handleErrorInternally: true
            });
            await this.setState({
                complex: response[0]
            });
        } else if (customComplexId) {
            const response = await fetchAPI(
                'GET',
                '/business/complexes',
                { types: ['PRODUCTION', 'OWN', 'DISASSEMBLY'], complexId: customComplexId },
                undefined,
                {
                    handleErrorInternally: true
                }
            );
            await this.setState({
                customComplex: response[0]
            });
        }
    };

    updateDataSource = cb => {
        if (this.state.fetched) {
            this.setState({
                fetched: false
            });
        }
        const callback = async data => {
            await this.setState({
                dataSource: data.orderLaborsRequest,
                selectedRowKeys: [],
                selectedRows: [],
                fetched: true
            });

            if (_.isFunction(cb)) {
                cb();
            }
        };
        setTimeout(() => {
            this.props.reloadOrderForm(callback, 'details');
        }, 500);
    };

    getRequestFields = request =>
        pick(request, [
            'id',
            'actionType',
            'counterparty',
            'employeeId',
            'laborRequestName',
            'stage',
            'storeGroupId',
            'serviceId'
        ]);

    updateRequest = async request => {
        if (this.state.fetched) {
            this.setState({
                fetched: false
            });
        }

        console.log(request.employeeComment, 'request.employeeComment');
        const data = {
            updateMode: true,
            laborRequests: [
                {
                    ...this.getRequestFields(request),
                    employeeComment: request.employeeComment,
                    complexId: request.complexId || null,
                    serviceId: Number(request.serviceId) || null
                }
            ],
            details: [],
            services: []
        };

        const { orderId } = this.props;
        await fetchAPI('PUT', `orders/${orderId}`, null, data, { handleErrorInternally: true });
        this.updateDataSource();
    };

    // buildCounterpartyTree = async () => {
    //     const suppliers = await fetchAPI('GET', 'business_suppliers', {
    //         all: true,
    //         showHidden: true
    //     });
    //     const counterpartyTreeData = [
    //         {
    //             title: this.props.intl.formatMessage({ id: 'order_form_table.master' }),
    //             value: 'EMPLOYEE',
    //             selectable: false,
    //             children: this.props.employees
    //                 .filter(({ disabled, posts, id }) => {
    //                     if (this.props.orderLaborsRequest.findIndex(({ employeeId }) => employeeId === id) !== -1) {
    //                         return true;
    //                     }

    //                     return !disabled && posts.findIndex(({ postName }) => postName === 'MECHANIC') !== -1;
    //                 })
    //                 .map(employee => ({
    //                     title: `${employee.surname} ${employee.name}`,
    //                     value: JSON.stringify({
    //                         counterparty: 'EMPLOYEE',
    //                         id: employee.id
    //                     })
    //                 }))
    //         },
    //         {
    //             title: this.props.intl.formatMessage({ id: 'order_form_table.supplier' }),
    //             value: 'SUPPLIER',
    //             selectable: false,
    //             children: suppliers.map(supplier => ({
    //                 title: `${supplier.name}`,
    //                 value: JSON.stringify({
    //                     counterparty: 'SUPPLIER',
    //                     id: supplier.id
    //                 })
    //             }))
    //         }
    //     ];
    //     this.setState({
    //         counterpartyTreeData
    //     });
    // };

    fetchComplexData = async () => {
        const [data, own] = await Promise.all([
            fetchAPI('GET', 'complexes', { hide: false }, null, { handleErrorInternally: true }),
            fetchAPI('GET', 'business/complexes', { types: ['PRODUCTION', 'OWN', 'DISASSEMBLY'] }, null, {
                handleErrorInternally: true
            })
        ]);

        const dataSourceComplex = [
            {
                name: <FormattedMessage id='own' />,
                id: 'OWN',
                childs: own.filter(({ enabled, type }) => enabled && type === 'OWN')
            },
            {
                name: <FormattedMessage id='complexes.type.PRODUCTION' />,
                id: 'PRODUCTION',
                childs: own.filter(({ enabled, type }) => enabled && type === 'PRODUCTION')
            },
            {
                name: <FormattedMessage id='complexes.type.DISASSEMBLY' />,
                id: 'DISASSEMBLY',
                childs: own.filter(({ enabled, type }) => enabled && type === 'DISASSEMBLY')
            },
            ...data
        ];
        this.buildComplexTree(dataSourceComplex);

        this.setState({
            complexesDefault: data,
            complexesCustom: own
        });
    };

    buildComplexTree(dataSource) {
        const treeData = [];
        for (let i = 0; i < dataSource.length; i++) {
            const parentGroup = dataSource[i];
            treeData.push({
                title: parentGroup.name,
                value: parentGroup.id,
                key: `${i}`,
                selectable: false,
                children: []
            });
            for (let j = 0; j < parentGroup.childs.length; j++) {
                const childGroup = parentGroup.childs[j];
                treeData[i].children.push({
                    title: childGroup.name,
                    value: childGroup.id,
                    key: `${i}-${j}`,
                    labors: childGroup.labors,
                    details: childGroup.storeGroups || childGroup.products,
                    type: childGroup.type || undefined
                });
            }
        }
        this.setState({
            complexTree: treeData
        });
    }

    render() {
        const { user, labors, detailsTreeData, orderId, defaultEmployeeId, setModal, isMobile, employees, suppliers } =
            this.props;
        const {
            dataSource,
            selectedRowKeys,
            selectedRows,
            counterpartyTreeData,
            request,
            fetched,
            visisibleRequestsModal,
            helperDrawerOpen,
            allLinks,
            complexTree,
            complexesCustom,
            complexesDefault
        } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            },
            getCheckboxProps: record => ({
                disabled: !record.id
            })
        };

        const columns = !isMobile
            ? this.columns()
            : this.columns().filter(
                  ({ key }) =>
                      key == 'laborRequestName' ||
                      key == 'employeeComment' ||
                      key == 'actionType' ||
                      key == 'serviceName'
              );

        return (
            <Catcher>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginBottom: 8
                    }}
                >
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            this.setState({
                                helperDrawerOpen: true
                            });
                            await this.fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                </div>
                <DraggableTable
                    addDragColumn
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    loading={!isMobile ? !fetched : undefined}
                    onDragEnd={async (fromIndex, toIndex) => {
                        await fetchAPI(
                            'PUT',
                            'orders/swap_labors_request',
                            {
                                orderId,
                                order1: dataSource[fromIndex].order,
                                order2: dataSource[toIndex].order
                            },
                            undefined,
                            { handleErrorInternally: true }
                        );
                        this.updateDataSource();
                    }}
                    onRow={row => {
                        return {
                            onClick: () => {
                                isMobile &&
                                    this.setState({
                                        request: { ...row }
                                    });
                            }
                        };
                    }}
                    pagination={false}
                    rowKey='id'
                    rowSelection={!isMobile && rowSelection}
                    size='small'
                />
                {isMobile && (
                    <div
                        style={{
                            margin: '12px 0px 8px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <ComplexesModal
                            defaultEmployeeId={this.props.defaultEmployeeId}
                            details={this.props.details}
                            detailsTreeData={this.props.detailsTreeData}
                            disabled
                            handleSearchLabors={this.handleSearchLabors}
                            labors={this.props.labors}
                            laborTimeMultiplier={this.props.laborTimeMultiplier}
                            normHourPrice={this.props.normHourPrice}
                            orderId={this.props.orderId}
                            reloadOrderForm={this.props.reloadOrderForm}
                            tecdocId={this.props.tecdocId}
                        />
                        <Button
                            data-qa='btn_show_service_product_modal_services_table_order_page'
                            disabled={this.props.disabled}
                            onClick={() =>
                                this.setState({
                                    request: {}
                                })
                            }
                            style={{
                                margin: '0px 0px 0px 8px'
                            }}
                        >
                            <FormattedMessage id='add' />
                        </Button>
                    </div>
                )}
                <OrderRequestModal
                    complexesCustom={complexesCustom}
                    complexesDefault={complexesDefault}
                    complexTree={complexTree}
                    counterpartyTreeData={counterpartyTreeData}
                    defaultEmployeeId={defaultEmployeeId}
                    detailsTreeData={detailsTreeData}
                    employees={employees}
                    handleSearchLabors={this.handleSearchLabors}
                    hideModal={() => this.setState({ request: undefined })}
                    isMobile={isMobile}
                    labors={labors}
                    open={Boolean(request)}
                    orderId={orderId}
                    request={request}
                    setModal={setModal}
                    suppliers={suppliers}
                    updateDataSource={this.updateDataSource}
                    updateRequest={this.updateRequest}
                    user={user}
                />
                <AddQuickRequestsModal
                    defaultEmployeeId={defaultEmployeeId}
                    hideModal={() => {
                        this.setState({
                            visisibleRequestsModal: false
                        });
                    }}
                    labors={labors}
                    orderId={orderId}
                    unitDefault={this.props.unitDefault}
                    updateDataSource={this.updateDataSource}
                    visible={visisibleRequestsModal}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}

export default RequestsTable;
