import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Image, Table, Tooltip, notification } from 'antd';
import { get } from 'lodash';
import { CommentaryButton } from 'modals';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PistonIcon, WrenchIcon } from 'theme';
import { fetchAPI } from 'utils';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

const IMAGE_HEIGHT = 668;
const IMAGE_WIDTH = 1064;

const PREVIEW_HEIGHT = 768;
const PREVIEW_WIDTH = 1366;

const Tab2D = ({
    open,
    resetModal,
    orderId,
    reloadOrderForm,
    user,
    setOrderData,
    toOrderData,
    setToOrderData,
    orderData,
    defaultEmployeeId,
    intl: { formatMessage }
}) => {
    const [systems, setSystems] = useState([]);
    const [unitUrl, setUnitUrl] = useState();

    const convertToPercentage = useCallback((x, y, width = PREVIEW_WIDTH, height = PREVIEW_HEIGHT) => {
        const xPercentage = (x / width) * 100;
        const yPercentage = (y / height) * 100;

        return {
            left: `${xPercentage}%`,
            top: `${yPercentage}%`
        };
    }, []);

    useEffect(() => {
        const fetch = async () => {
            const systems = await fetchAPI('GET', '/graphic_systems', null, null, { handleErrorInternally: true });
            setSystems(systems);
        };
        if (open && !systems.length) {
            fetch();
        }
    }, [open, systems]);

    const formatUrl = useCallback(
        str => str.replace('http://localhost:4100', 'https://test-s1.car-book.com').replace('svg', 'png'),
        []
    );

    const columns = useMemo(
        () => [
            {
                key: 'icon',
                render: record => (record.laborId ? <WrenchIcon /> : <PistonIcon />)
            },
            {
                title: <FormattedMessage id='storage_journal.name' />,
                key: 'name',
                render: record => (
                    <Flex vertical>
                        <div>{record.storeGroupName || record.laborName}</div>
                    </Flex>
                )
            },
            {
                key: 'comment',
                render: record => {
                    let name = record.storeGroupName || record.laborName;
                    if (name && name.indexOf(' - ') > -1) {
                        name = name.slice(0, name.indexOf(' - '));
                    }

                    return (
                        <CommentaryButton
                            commentary={
                                record.comment || {
                                    comment: undefined,
                                    positions: [],
                                    problems: []
                                }
                            }
                            detail={name}
                            setComment={(comment, positions, problems) => {
                                if (record.laborId) {
                                    setToOrderData(prev => ({
                                        labors: prev.labors.map(lbr =>
                                            record.laborId === lbr.laborId
                                                ? {
                                                      ...lbr,
                                                      laborName: comment || record.laborName,
                                                      comment: {
                                                          comment,
                                                          positions,
                                                          problems
                                                      }
                                                  }
                                                : lbr),
                                        storeGroups: prev.storeGroups
                                    }));
                                } else {
                                    setToOrderData(prev => ({
                                        labors: prev.labors,
                                        storeGroups: prev.storeGroups.map(grp =>
                                            record.storeGroupId === grp.storeGroupId
                                                ? {
                                                      ...grp,
                                                      storeGroupName: comment || record.storeGroupName,
                                                      comment: {
                                                          comment,
                                                          positions,
                                                          problems
                                                      }
                                                  }
                                                : grp)
                                    }));
                                }
                            }}
                        />
                    );
                }
            },
            {
                key: 'delete',
                render: record => (
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            if (record.laborId) {
                                setToOrderData(prev => ({
                                    labors: prev.labors.filter(({ laborId }) => laborId !== record.laborId),
                                    storeGroups: prev.storeGroups
                                }));
                            } else {
                                setToOrderData(prev => ({
                                    labors: prev.labors,
                                    storeGroups: prev.storeGroups.filter(
                                        ({ storeGroupId }) => storeGroupId !== record.storeGroupId
                                    )
                                }));
                            }
                        }}
                    />
                )
            }
        ],
        []
    );

    const handleCloseToOrderModal = useCallback(() => {
        setOrderData(undefined);
        setUnitUrl(undefined);
    }, []);

    const handleAddToOrder = useCallback(() => {
        const fetch = async () => {
            const payload = {
                insertMode: true,
                services: toOrderData.labors.map(
                    ({ laborId, laborName, laborHour, laborPrice, measureUnitId, comment }) => ({
                        serviceId: laborId,
                        serviceName: laborName,
                        count: laborHour,
                        servicePrice: laborPrice,
                        laborUnitId: measureUnitId,
                        employeeId: defaultEmployeeId,
                        comment
                    })
                ),
                details: toOrderData.storeGroups.map(({ storeGroupId, storeGroupName, measureUnitId, comment }) => ({
                    storeGroupId,
                    name: storeGroupName,
                    partUnitId: measureUnitId,
                    comment
                }))
            };

            if (!payload.services.length) {
                delete payload.services;
            }
            if (!payload.details.length) {
                delete payload.details;
            }

            await fetchAPI('PUT', `orders/${orderId}`, null, payload);
            notification.success({
                message: formatMessage({
                    id: 'message.success'
                })
            });
            resetModal();
            reloadOrderForm();
        };
        fetch();
        setToOrderData({ labors: [], storeGroups: [] });
        handleCloseToOrderModal();
    }, [handleCloseToOrderModal, toOrderData, orderId, formatMessage, resetModal, reloadOrderForm]);

    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        const labors = get(toOrderData, 'labors', []);
        const storeGroups = get(toOrderData, 'storeGroups', []);
        setRowCount(labors.length + storeGroups.length);
    }, [toOrderData]);

    return (
        <React.Fragment>
            <Flex gap={12} wrap='wrap'>
                {systems.map(({ id, name, url, units }) => (
                    <Flex
                        key={id}
                        align='center'
                        gap={16}
                        justify='center'
                        style={{
                            minWidth: 360,
                            maxWidth: systems.length - 1 ? `calc(${100 / Math.min(systems.length, 4)}% - 16px)` : 720
                        }}
                        vertical
                    >
                        <Image
                            alt={`visual_sys_${id}`}
                            preview={{
                                onVisibleChange: visible => {
                                    if (!visible) {
                                        setToOrderData({ labors: [], storeGroups: [] });
                                    }
                                },
                                mask: (
                                    <React.Fragment>
                                        <EyeOutlined style={{ fontSize: 36 }} />
                                    </React.Fragment>
                                ),
                                visible: !open ? false : void 0,
                                imageRender: () => (
                                    <React.Fragment>
                                        <div
                                            style={{
                                                position: 'relative',
                                                height: IMAGE_HEIGHT,
                                                width: IMAGE_WIDTH,
                                                backgroundColor: '#fff',
                                                backgroundImage: `url(${formatUrl(url)})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center'
                                            }}
                                        >
                                            {/* <Image
                                            height={PREVIEW_HEIGHT}
                                            preview={false}
                                            src={formatUrl(url)}
                                            style={{ position: 'absolute', left: 0, top: 0 }}
                                            width={PREVIEW_WIDTH}
                                        /> */}
                                            {unitUrl && (
                                                <div
                                                    className={Styles.bg}
                                                    style={{
                                                        position: 'absolute',
                                                        left: 0,
                                                        top: 0,
                                                        height: '100%',
                                                        width: '100%',
                                                        backgroundColor: '#000000bf',
                                                        backgroundImage: `url(${formatUrl(unitUrl)})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center'
                                                    }}
                                                />
                                            )}
                                            {units.map(
                                                ({ id, plusX, plusY, pictureUrl, name, storeGroups, labors }) => (
                                                    <Tooltip key={id} title={name}>
                                                        <Button
                                                            icon={<PlusOutlined style={{ verticalAlign: 'sub' }} />}
                                                            onClick={() => {
                                                                setUnitUrl(undefined);
                                                                setOrderData({
                                                                    storeGroups,
                                                                    labors
                                                                });
                                                            }}
                                                            onMouseEnter={() => setUnitUrl(pictureUrl)}
                                                            onMouseLeave={() => setUnitUrl(undefined)}
                                                            shape='circle'
                                                            style={{
                                                                position: 'absolute',
                                                                ...convertToPercentage(plusX, plusY),
                                                                opacity: unitUrl || orderData ? 0 : 1,
                                                                background: 'rgb(155 89 182 / 85%)',
                                                                transform: 'translate(-50%, -50%)'
                                                            }}
                                                            type='primary'
                                                        />
                                                    </Tooltip>
                                                )
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                background: 'white',
                                                minWidth: 360,
                                                maxWidth: 660,
                                                height: IMAGE_HEIGHT,
                                                overflowY: 'auto'
                                            }}
                                        >
                                            <Table
                                                bordered
                                                columns={columns}
                                                dataSource={[
                                                    ...get(toOrderData, 'labors', []),
                                                    ...get(toOrderData, 'storeGroups', [])
                                                ]}
                                                footer={() => (
                                                    <Flex justify='end'>
                                                        <Button
                                                            disabled={
                                                                ![
                                                                    ...get(toOrderData, 'labors', []),
                                                                    ...get(toOrderData, 'storeGroups', [])
                                                                ].length
                                                            }
                                                            onClick={handleAddToOrder}
                                                            type='primary'
                                                        >
                                                            <FormattedMessage id='ok' />
                                                        </Button>
                                                    </Flex>
                                                )}
                                                pagination={false}
                                                rowKey={record => {
                                                    return v4();
                                                }}
                                                size='small'
                                                title={() => {
                                                    return rowCount > 11 ? (
                                                        <Flex justify='end'>
                                                            <Button onClick={handleAddToOrder} type='primary'>
                                                                <FormattedMessage id='ok' />
                                                            </Button>
                                                        </Flex>
                                                    ) : null;
                                                }}
                                            />
                                        </div>
                                    </React.Fragment>
                                ),
                                toolbarRender: () => null
                            }}
                            src={formatUrl(url)}
                        />
                        <p style={{ fontWeight: 700, fontSize: 18 }}>{name}</p>
                    </Flex>
                ))}
            </Flex>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default connect(mapStateToProps)(injectIntl(Tab2D));
