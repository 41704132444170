import {
    CameraOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FormOutlined,
    InfoCircleOutlined,
    MenuOutlined,
    MessageOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import { Button, Drawer, Input, Popconfirm, Popover, Select, Space, Tooltip, notification } from 'antd';
import { DraggableTable } from 'components';
import { get, uniqBy } from 'lodash';
import { PreviewMediaFilesModal } from 'modals';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, filterByPart, getStorageToken } from 'utils';
import { CommentaryButton } from '../../..';
import PaddedWrapper from '../../PaddedWrapper';
import DiagnosticAddCustomPartsDrawer from '../DiagnosticAddCustomPartsDrawer';
import Styles from './styles.m.css';
import useFilterDataSource from './useFilterDataSource';

const pageSizeOptions = ['10', '25', '50'];

export const diagnosticFilterStatuses = [
    {
        labelId: 'order_form_table.diagnostic.filters.answer.ok',
        partAnswer: '1'
    },
    {
        labelId: 'order_form_table.diagnostic.filters.answer.attention',
        partAnswer: '2'
    },
    {
        labelId: 'order_form_table.diagnostic.filters.answer.critical',
        partAnswer: '3'
    },
    {
        labelId: 'order_form_table.diagnostic.filters.answer.blocked',
        partAnswer: '4'
    },
    {
        labelId: 'order_form_table.diagnostic.filters.answer.not_selected',
        partAnswer: [null, '0']
    }
];

const { Option } = Select;

export const initDiagnosticFilters = {};

export const DiagnosticTableDrawer = injectIntl(
    ({
        open,
        handleClose,
        disabled,
        fetchDiagnosticData,
        statistics,
        orderDiagnostics,
        filterDiagnosticData,
        setVinModal,
        orderId,
        orderNum,
        intl,
        orderImgVehicleType,
        fetchFileList,
        bussinessStats,
        disabledPhoto
    }) => {
        const [, updateState] = useState();
        const forceUpdate = useCallback(() => updateState({}), []);

        const [loading, setLoading] = useState(false);
        const [addCustomPartsOpen, setAddCustomPartsOpen] = useState(false);

        const [labelId, setLabelId] = useState();
        const [templateName, setTemplateName] = useState('');

        const [selectedRowKeys, setSelectedRowKeys] = useState([]);
        const [selectedRows, setSelectedRows] = useState([]);

        const [commentaryRow, setCommentaryRow] = useState();

        const [filters, setFilters] = useState(initDiagnosticFilters);
        const handleFiltersChange = filterObj => {
            setFilters({ ...filters, ...filterObj });
        };
        const filteredData = useFilterDataSource(orderDiagnostics, filters);
        const [defaultPlaces, setDefaultPlaces] = useState([]);

        const [openMediaModal, setOpenMediaModal] = useState(false);
        const [diagnData, setDiagnData] = useState();

        useEffect(() => {
            window.addEventListener('resize', forceUpdate);

            return () => {
                window.removeEventListener('resize', forceUpdate);
            };
        }, []);

        const uniqueParams = useMemo(() => {
            return {
                uniquePlaceNames: uniqBy(orderDiagnostics, 'placeName'),
                uniquePartNames: uniqBy(orderDiagnostics, 'partName'),
                uniqueSystemNames: uniqBy(orderDiagnostics, 'systemName')
            };
        }, [orderDiagnostics]);

        const filterParams = useMemo(() => {
            const { uniquePlaceNames, uniquePartNames, uniqueSystemNames } = uniqueParams;

            const params = {
                systemNames: uniqueSystemNames.map(({ systemName }) => systemName),
                placeNames: uniquePlaceNames.map(({ placeName }) => placeName),
                partNames: uniquePartNames.map(({ partName }) => partName)
            };

            return params;
        }, [orderDiagnostics, filters]);

        const tableParentRef = useRef(null);

        const addCustomDiagnostic = async () => {
            try {
                setLoading(true);
                await fetchAPI(
                    'POST',
                    'custom_diagnostics',
                    null,
                    {
                        orderId,
                        templateName
                    },
                    {
                        handleErrorInternally: true
                    }
                );
                await fetchDiagnosticData();
                notification.success({ message: intl.formatMessage({ id: 'message.success' }) });
            } catch (e) {
                notification.error({ message: intl.formatMessage({ id: 'error' }) });
            } finally {
                setLoading(false);
            }
        };

        const onClose = () => {
            handleClose();
        };

        const green = '#44982B';
        const red = '#ff3725';
        const yellow = '#ffc801';
        const colorByAnswer = {
            1: '#44982ba6',
            2: '#fdc50070',
            3: '#e72c1c78'
        };

        const putDiagnosticData = async ({
            orderId,
            diagnosticId,
            partAnswer,
            partOECode,
            partPhotosHashes,
            partComment,
            createItem = false
        }) => {
            setLoading(true);
            await fetchAPI(
                'PUT',
                'order_diagnostic',
                null,
                {
                    orderId,
                    createItem,
                    partsData: [
                        {
                            rowId: diagnosticId,
                            answer: partAnswer,
                            oeCode: partOECode,
                            photoHashes: partPhotosHashes,
                            comment: partComment
                        }
                    ]
                },
                {
                    handleErrorInternally: true
                }
            );
            fetchDiagnosticData();
            setLoading(false);
        };

        const putGroupDiagnosticData = async ({ orderId, partsData, createItem = false }) => {
            setLoading(true);
            await fetchAPI(
                'PUT',
                'order_diagnostic',
                null,
                {
                    orderId,
                    createItem,
                    partsData: partsData.map(
                        ({ diagnosticId, partAnswer, partOECode, partPhotosHashes, partComment }) => ({
                            rowId: diagnosticId,
                            answer: partAnswer,
                            oeCode: partOECode,
                            photoHashes: partPhotosHashes,
                            comment: partComment
                        })
                    )
                },
                {
                    handleErrorInternally: true
                }
            );
            fetchDiagnosticData();
            setLoading(false);
            setSelectedRowKeys([]);
            setSelectedRows([]);
        };

        const deleteDiagnosticData = async ({ templateId, orderId, groupId, systemId, diagnosticId }) => {
            await fetchAPI(
                'DELETE',
                'order_diagnostic',
                null,
                {
                    orderId,
                    templateId,
                    groupIds: groupId ? [groupId].flat() : undefined,
                    rowIds: diagnosticId ? [diagnosticId].flat() : undefined,
                    systemIds: systemId ? [systemId].flat() : undefined
                },
                { handleErrorInternally: true }
            );
            fetchDiagnosticData();
            setLoading(false);
            setSelectedRowKeys([]);
            setSelectedRows([]);
        };

        const handleStatusChange = (rowStatus, row) => {
            putDiagnosticData({
                ...row,
                partAnswer: row.partAnswer == rowStatus ? 0 : rowStatus
            });
        };

        const handleGroupStatusChange = (rowStatus, partsData) => {
            const { orderId } = partsData[0];
            putGroupDiagnosticData({
                orderId,
                partsData: partsData.map(e => ({ ...e, partAnswer: rowStatus }))
            });
        };
        const handleSwapRows = async (fromIndex, toIndex) => {
            setLoading(true);
            await fetchAPI(
                'PUT',
                'order_diagnostic/swap',

                {
                    orderId,
                    diagnostic1: filteredData[fromIndex - 1].order,
                    diagnostic2: filteredData[toIndex - 1].order
                },
                undefined,
                { handleErrorInternally: true }
            );
            fetchDiagnosticData();

            setLoading(false);
        };

        const fetchDefaultPlaces = async () => {
            const data = await fetchAPI(
                'GET',
                '/diagnostic_places',
                { type: orderImgVehicleType || 'PC' },
                {},
                { handleErrorInternally: true }
            );

            setDefaultPlaces(data);
        };

        const downloadPhoto = async (file, id, text) => {
            const url = `${__GALLARY_IMAGES_URL__}/api/upload_file`;

            const formData = new FormData();

            formData.append('file', file);
            formData.append('type', 'img');
            formData.append('name', file.name);
            formData.append('dgn', id);
            formData.append('dgnTxt', text);
            formData.append('ord', orderId);
            formData.append('ordTxt', orderNum);
            // formData.append('ord', 1);
            // formData.append('det', 2);
            // formData.append('dgn', 3);

            // formData.append('ordTxt', 'MRD-1174-1');
            // formData.append('detTxt', 'product-code-1 brand-1');
            // formData.append('dgnTxt', 'change left wheel');

            console.log(formData, 'form');

            await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: getStorageToken()
                },
                body: formData
            }).then(function (response) {
                if (response.status !== 200) {
                    return notification.error({
                        message: `${intl.formatMessage({
                            id: 'gallary.not_enought_space'
                        })}!`
                    });
                }
                notification.success({
                    message: intl.formatMessage({ id: 'message.success' })
                });

                return Promise.resolve(response);
            });

            await fetchFileList(), await bussinessStats();
        };

        const dgColumns = () => [
            {
                // permissions
                title: row => {
                    const popoverContent = (
                        <div className={Styles.actionBlock}>
                            <Popconfirm
                                onConfirm={() => {
                                    handleGroupStatusChange(1, selectedRows);
                                }}
                                placement='bottomRight'
                                title={intl.formatMessage({ id: 'agreement.confirm_title' })}
                            >
                                <Button
                                    disabled={!selectedRowKeys.length}
                                    icon={<CheckCircleOutlined style={{ color: green }} />}
                                    type='text'
                                />
                            </Popconfirm>
                            <Popconfirm
                                onConfirm={() => {
                                    handleGroupStatusChange(3, selectedRows);
                                }}
                                placement='bottomRight'
                                title={intl.formatMessage({ id: 'agreement.confirm_title' })}
                            >
                                <Button
                                    disabled={!selectedRowKeys.length}
                                    icon={<CloseCircleOutlined style={{ color: red }} />}
                                    type='text'
                                />
                            </Popconfirm>
                            <Popconfirm
                                onConfirm={() => {
                                    handleGroupStatusChange(2, selectedRows);
                                }}
                                placement='bottomRight'
                                title={intl.formatMessage({ id: 'agreement.confirm_title' })}
                            >
                                <Button
                                    disabled={!selectedRowKeys.length}
                                    icon={<ExclamationCircleOutlined style={{ color: yellow }} />}
                                    type='text'
                                />
                            </Popconfirm>
                            <Popconfirm
                                onConfirm={() => {
                                    handleGroupStatusChange(4, selectedRows);
                                }}
                                placement='bottomRight'
                                title={intl.formatMessage({ id: 'agreement.confirm_title' })}
                            >
                                <Button disabled={!selectedRowKeys.length} icon={<MinusCircleOutlined />} type='text' />
                            </Popconfirm>
                            <Popconfirm
                                onConfirm={() => {
                                    putGroupDiagnosticData({
                                        orderId: get(selectedRows, '[0].orderId'),
                                        partsData: selectedRows,
                                        createItem: true
                                    });
                                }}
                                placement='bottomRight'
                                title={intl.formatMessage({ id: 'agreement.confirm_title' })}
                            >
                                <Tooltip title={<FormattedMessage id='order-page.create_copy' />}>
                                    <Button
                                        disabled={!selectedRowKeys.length}
                                        icon={<PlusCircleOutlined />}
                                        type='text'
                                    />
                                </Tooltip>
                            </Popconfirm>
                            <Popconfirm
                                onConfirm={() => {
                                    deleteDiagnosticData({
                                        orderId: get(selectedRows, '[0].orderId'),
                                        diagnosticId: selectedRowKeys
                                    });
                                }}
                                placement='bottomRight'
                                title={intl.formatMessage({ id: 'agreement.confirm_title' })}
                            >
                                <Tooltip title={<FormattedMessage id='delete' />}>
                                    <Button disabled={!selectedRowKeys.length} icon={<DeleteOutlined />} type='text' />
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    );

                    <Popconfirm
                        onConfirm={() => {
                            handleGroupStatusChange(3, selectedRows);
                        }}
                        placement='bottomRight'
                        title={intl.formatMessage({ id: 'agreement.confirm_title' })}
                    ></Popconfirm>;

                    return (
                        <div className={Styles.actionButtonAlign}>
                            <Popover content={popoverContent} trigger='click'>
                                <Button
                                    data-qa='btn_menu_diagnostic_table_order_page'
                                    disabled={disabled || row.disabled}
                                >
                                    <MenuOutlined />
                                </Button>
                            </Popover>
                        </div>
                    );
                },
                // <FormattedMessage id='order_form_table.action' />,
                key: 'actions',
                render: row => {
                    const disabledComplete = disabled || row.diagnosticComplete;
                    const popoverContent = (
                        <div className={Styles.actionBlock}>
                            {row.partComment ? (
                                <Button
                                    className={Styles.commentaryButton}
                                    disabled={disabledComplete}
                                    onClick={() => setCommentaryRow(row)}
                                    title={intl.formatMessage({
                                        id: 'commentary.edit'
                                    })}
                                    type='primary'
                                >
                                    <FormOutlined className={Styles.commentaryButtonIcon} />
                                </Button>
                            ) : (
                                <Tooltip title={<FormattedMessage id='commentary.add' />} zIndex={3001}>
                                    <Button disabled={disabledComplete} onClick={() => setCommentaryRow(row)}>
                                        <MessageOutlined />
                                    </Button>
                                </Tooltip>
                            )}
                            <Button
                                disabled={disabledPhoto}
                                icon={<CameraOutlined />}
                                onClick={() => {
                                    setDiagnData({
                                        id: row.diagnosticId,
                                        name: row.partName
                                    });
                                    setOpenMediaModal(true);
                                }}
                                type='text'
                            />
                            {/* <Upload
                                action={elem => downloadPhoto(elem, row.diagnosticId, row.partName)}
                                beforeUpload={file => {
                                    const isPNG = file.type === 'image/png';
                                    const isJPG = file.type === 'image/jpeg';
                                    if (!isPNG && !isJPG) {
                                        notification.error({
                                            message: `${file.name} ${intl.formatMessage({
                                                id: 'photo_modal.is_not_an_image'
                                            })}!`
                                        });
                                    }

                                    return isPNG || isJPG;
                                }}
                                disabled={disabledPhoto}
                                showUploadList={false}
                            >
                               
                            </Upload> */}
                            {/* <PhotoButton
                                data-qa='btn_photo_diagnostic_table_order_page'
                                disabled={disabledComplete}
                                getCurrentDiagnostic={fetchDiagnosticData}
                                onOk={partPhotosHashes => {
                                    putDiagnosticData({
                                        ...row,
                                        partPhotosHashes
                                    });
                                }}
                                rowProp={row}
                            /> */}
                            <Tooltip title={<FormattedMessage id='order-page.create_copy' />}>
                                <Button
                                    icon={<PlusCircleOutlined />}
                                    onClick={() => {
                                        putDiagnosticData({
                                            ...row,
                                            createItem: true
                                        });
                                    }}
                                    type='text'
                                />
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id='delete' />}>
                                <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        deleteDiagnosticData({
                                            ...row
                                        });
                                    }}
                                    type='text'
                                />
                            </Tooltip>
                        </div>
                    );

                    return (
                        <div className={Styles.actionButtonAlign}>
                            <Popover content={popoverContent} trigger='click'>
                                <Button
                                    data-qa='btn_menu_diagnostic_table_order_page'
                                    disabled={disabled || row.disabled}
                                >
                                    <MenuOutlined />
                                </Button>
                            </Popover>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.element_title' />,
                key: 'systemName',
                dataIndex: 'systemName',
                filtered: filters.systemName,
                filterDropdown: () => {
                    return (
                        <PaddedWrapper>
                            <Select
                                allowClear
                                onChange={selectValue => handleFiltersChange({ systemName: selectValue })}
                                placeholder={intl.formatMessage({
                                    id: 'order_form_table.diagnostic.filters.element.placeholder'
                                })}
                                style={{ minWidth: 250, maxWidth: 500 }}
                                value={filters.systemName}
                            >
                                {filterParams.systemNames.map(systemName => (
                                    <Option key={systemName} value={systemName}>
                                        {systemName}
                                    </Option>
                                ))}
                            </Select>
                        </PaddedWrapper>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.position_title' />,
                key: 'placeName',
                dataIndex: 'placeName',
                filtered: filters.placeName,
                filterDropdown: () => {
                    return (
                        <PaddedWrapper>
                            <Select
                                allowClear
                                onChange={selectValue => handleFiltersChange({ placeName: selectValue })}
                                placeholder={intl.formatMessage({
                                    id: 'order_form_table.diagnostic.filters.position.placeholder'
                                })}
                                style={{ minWidth: 250, maxWidth: 500 }}
                                value={filters.placeName}
                            >
                                {filterParams.placeNames.map(placeName => (
                                    <Option key={placeName} value={placeName}>
                                        {placeName}
                                    </Option>
                                ))}
                            </Select>
                        </PaddedWrapper>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.node_title' />,
                key: 'partName',
                filtered: filters.partName && filters.partName.length,
                onCell: row => {
                    return {
                        onClick: () => setCommentaryRow(row),
                        style: { cursor: 'pointer' }
                    };
                },
                filterDropdown: () => {
                    return (
                        <PaddedWrapper>
                            <Select
                                allowClear
                                filterOption={filterByPart}
                                mode='multiple'
                                onChange={selectValue => handleFiltersChange({ partName: selectValue })}
                                placeholder={intl.formatMessage({
                                    id: 'order_form_table.diagnostic.filters.node.placeholder'
                                })}
                                style={{ minWidth: 300, maxWidth: 500 }}
                                value={filters.partName}
                            >
                                {filterParams.partNames.map(partName => (
                                    <Option key={partName} value={partName}>
                                        {partName}
                                    </Option>
                                ))}
                            </Select>
                        </PaddedWrapper>
                    );
                },
                width: 250,

                render: row => {
                    const color = colorByAnswer[row.partAnswer];

                    return (
                        <div style={{ borderBottom: color ? `3px solid ${color}` : 'none', display: 'inline-block' }}>
                            {get(row, 'partName')}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.oe_code_title' />,
                key: 'partOECode',
                onCell: row => {
                    return {
                        onClick: () =>
                            setVinModal({
                                storeGroupId: orderDiagnostics.map(({ partStoreGroupId }) => partStoreGroupId),
                                diagnosticMode: true,
                                relatedParts: orderDiagnostics,
                                orderId: row.orderId,
                                fetchDiagnosticData: filterDiagnosticData,
                                onClose: () => filterDiagnosticData()
                            }),
                        style: { cursor: 'pointer' }
                    };
                },
                render: row => {
                    const color = colorByAnswer[row.partAnswer];
                    const partOECode = get(row, 'partOECode');

                    return partOECode ? (
                        <div
                            style={{
                                borderBottom: color ? `3px solid ${color}` : 'none',
                                display: 'inline-block'
                            }}
                        >
                            {partOECode}
                        </div>
                    ) : (
                        <div>-----</div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.status_title' />,
                key: 'partAnswer',
                filtered: filters.partAnswer && filters.partAnswer.length,
                filterDropdown: ({ visible }) => {
                    return (
                        <PaddedWrapper>
                            <Select
                                allowClear
                                dropdownStyle={{ minWidth: 250, maxWidth: 400 }}
                                mode='multiple'
                                onChange={(labelId, option) => {
                                    setLabelId(labelId);
                                    handleFiltersChange({ partAnswer: option.map(({ partAnswer }) => partAnswer) });
                                }}
                                placeholder={intl.formatMessage({
                                    id: 'order_form_table.diagnostic.filters.answer.placeholder'
                                })}
                                showSearch={false}
                                style={{ minWidth: 250, maxWidth: 400 }}
                                value={labelId}
                            >
                                {diagnosticFilterStatuses.map(({ labelId, partAnswer }) => (
                                    <Option key={labelId} partAnswer={partAnswer} value={labelId}>
                                        <FormattedMessage id={labelId} />
                                    </Option>
                                ))}
                            </Select>
                        </PaddedWrapper>
                    );
                },
                render: row => {
                    const disabledComplete = disabled || row.diagnosticComplete;

                    return (
                        <div style={{ display: 'flex' }}>
                            <Button
                                disabled={row.partAnswer == 4 || disabledComplete}
                                icon={<CheckCircleOutlined style={{ color: row.partAnswer == 1 && green }} />}
                                onClick={() => {
                                    handleStatusChange(1, row);
                                }}
                                type='text'
                            />
                            <Button
                                disabled={row.partAnswer == 4 || disabledComplete}
                                icon={<CloseCircleOutlined style={{ color: row.partAnswer == 3 && red }} />}
                                onClick={() => {
                                    handleStatusChange(3, row);
                                }}
                                type='text'
                            />
                            <Button
                                disabled={row.partAnswer == 4 || disabledComplete}
                                icon={<ExclamationCircleOutlined style={{ color: row.partAnswer == 2 && yellow }} />}
                                onClick={() => {
                                    handleStatusChange(2, row);
                                }}
                                type='text'
                            />
                            <Button
                                disabled={disabledComplete}
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                    handleStatusChange(row.partAnswer == 4 ? 0 : 4, row);
                                }}
                                type='text'
                            />
                        </div>
                    );
                }
            }
        ];

        const rowSelection = useMemo(() => {
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRows(selectedRows);
                    setSelectedRowKeys(selectedRowKeys);
                }
            };
        }, [selectedRowKeys, selectedRows]);

        return (
            <Drawer
                extra={
                    <Space>
                        <Popconfirm
                            description={
                                <div>
                                    <Input
                                        onChange={e => setTemplateName(e.target.value)}
                                        placeholder={intl.formatMessage({
                                            id: 'diagnostics_directories.placeholder.enter_name'
                                        })}
                                        suffix={
                                            <Tooltip
                                                title={intl.formatMessage({
                                                    id: 'diagnostics_directories.tooltip.same_name'
                                                })}
                                            >
                                                <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, .45)' }} />
                                            </Tooltip>
                                        }
                                        value={templateName}
                                    />
                                </div>
                            }
                            okButtonProps={{ disabled: !templateName, loading }}
                            onConfirm={addCustomDiagnostic}
                            placement='bottom'
                            title={<FormattedMessage id='diagnostics_directories.title.save_diagnostics' />}
                        >
                            <Button type='dashed'>
                                <FormattedMessage id='diagnostics_directories.title.save_diagnostics' />
                            </Button>
                        </Popconfirm>
                        <Button onClick={() => setAddCustomPartsOpen(true)} type='primary'>
                            <FormattedMessage id='diagnostics_directories.title.add_part' />
                        </Button>
                    </Space>
                }
                onClose={onClose}
                open={open}
                placement='right'
                title={<FormattedMessage id='order_form_table.diagnostic.drawer_title' />}
                width={1000}
            >
                <div ref={tableParentRef} style={{ height: '100%' }}>
                    {statistics()}
                    <DraggableTable
                        addDragColumn
                        bordered
                        columns={dgColumns()}
                        dataSource={filteredData}
                        loading={loading}
                        onDragEnd={handleSwapRows}
                        pagination={{
                            defaultPageSize: 50,
                            showSizeChanger: true,
                            pageSizeOptions
                        }}
                        rowClassName={row =>
                            (row.partAnswer == 4 || row.diagnosticComplete) && Styles.diagnosticTableDisabledRow
                        }
                        rowKey='diagnosticId'
                        rowSelection={rowSelection}
                        scroll={{ y: (tableParentRef.current && tableParentRef.current.clientHeight * 0.85) || 700 }}
                        size='small'
                        // className={Styles.diagnosticTable}
                        sticky
                    />
                    <CommentaryButton
                        data-qa='btn_comentary_diagnostic_table_order_page'
                        getCurrentDiagnostic={fetchDiagnosticData}
                        handleClose={() => setCommentaryRow(null)}
                        onOk={partComment => {
                            putDiagnosticData({
                                ...commentaryRow,
                                partComment
                            });
                        }}
                        rowProp={commentaryRow}
                    />
                    <DiagnosticAddCustomPartsDrawer
                        defaultPlaces={defaultPlaces}
                        fetchDefaultPlaces={fetchDefaultPlaces}
                        fetchDiagnosticData={fetchDiagnosticData}
                        handleClose={() => setAddCustomPartsOpen(false)}
                        open={addCustomPartsOpen}
                        orderId={orderId}
                        templateData={get(orderDiagnostics, '[0]', {})}
                        title={<FormattedMessage id='order_form_table.diagnostic.drawer_title' />}
                        uniquePlaceNames={uniqueParams.uniquePlaceNames}
                    />
                    <PreviewMediaFilesModal
                        diagnData={diagnData}
                        downloadPhoto={downloadPhoto}
                        hideModal={() => {
                            setOpenMediaModal(false);
                            setDiagnData(undefined);
                        }}
                        open={openMediaModal}
                        orderId={orderId}
                        orderNum={orderNum}
                    />
                </div>
            </Drawer>
        );
    }
);
