import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Drawer, Image, Input, notification } from 'antd';
import _, { debounce, get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { fetchAPI, filterStringByPart, getStorageToken } from 'utils';

// Import columns and menus logic
import { Catcher } from 'commons';
import { DraggableTable } from 'components';
import { MODALS } from 'core/modals/duck';
import {
    DetailSupplierModal,
    DetailWarehousesCountModal,
    FavouriteDetailsModal,
    OrderDetailModal,
    SetBarcodeModal
} from 'modals';
import { BookIcon, VinIcon } from 'theme';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { getColumns } from './columns';
import ImportDetailsModal from './modals/ImportDetailsModal';
import SuppliersIncomeModal from './modals/SuppliersIncomeModal';
import UpdatePriceModal from './modals/UpdatePriceModal';

// Import your modals if needed (adjust paths as per your project)
// import OrderDetailModal from 'modals/OrderDetailModal';
// import DetailSupplierModal from 'modals/DetailSupplierModal';
// import UpdatePriceModal from 'modals/UpdatePriceModal';
// import SuppliersIncomeModal from 'modals/SuppliersIncomeModal';
// import FavouriteDetailsModal from 'modals/FavouriteDetailsModal';
// import SetBarcodeModal from 'modals/SetBarcodeModal';
// import DetailWarehousesCountModal from 'modals/DetailWarehousesCountModal';
// import ImportDetailsModal from 'modals/ImportDetailsModal';

import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {
    // fetchWarehouses if needed, or other redux actions
    // fetchWarehouses
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class DetailsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            fetched: false,
            selectedRowKeys: [],
            selectedRows: [],
            search: '',
            productBarcode: undefined,
            helperDrawerOpen: false,
            favouriteModalVisible: false,
            setPriceModal: undefined,
            supplierModalSelectedRow: undefined,
            warehousesModalSelectedRow: undefined,
            visibleSuppliersIncomeModal: false,
            suppliersIncomeModalRow: undefined
        };
    }

    componentDidMount() {
        this.fetchData();

        if (this.props.showOilModal) {
            this.setState({
                detailModalVisibleMode: 1,
                detailModalProduct: {}
            });
        }

        this.setState({
            dataSource: this.buildVariations(this.props.orderDetails)
        });
        this.updateDataSource();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!prevProps.showOilModal && this.props.showOilModal) {
            this.setState({
                detailModalVisibleMode: 1,
                detailModalProduct: {}
            });
        }
        if (
            (prevProps.activeKey != 'details' && this.props.activeKey == 'details') ||
            prevProps.orderDetails != this.props.orderDetails
        ) {
            await this.setState({
                dataSource: this.buildVariations(this.props.orderDetails)
            });
        }
        if (prevProps.orderDetails !== this.props.orderDetails) {
            const details = this.state.dataSource
                .filter(row => Number(row.count) === Number(row.reservedCount) && row.supplierId !== 0)
                .map(elem => this.parseDetailData({ ...elem, supplierId: 0 }));
            if (details.length) {
                const data = {
                    updateMode: true,
                    details
                };
                await fetchAPI('PUT', `orders/${this.props.orderId}`, null, data, {
                    handleErrorInternally: true
                });
                this.updateDataSource();
            }
        }
    }

    fetchData = async () => {
        this.props.fetchWarehouses();
        let statuses = [];
        if (isGrantAccessed(this.props.user, grants.SETTINGS_PART_STATUSES)) {
            statuses = await fetchAPI('GET', 'status_settings');
        }
        this.setState({
            statuses,
            fetched: true
        });
    };

    // Example photo upload logic if needed
    downloadPhoto = async (file, id, text) => {
        const url = `${__GALLARY_IMAGES_URL__}/api/upload_file`;

        const {
            orderId,
            orderNum,
            intl: { formatMessage }
        } = this.props;

        const formData = new FormData();

        formData.append('file', file);
        formData.append('type', 'img');
        formData.append('name', file.name);
        formData.append('det', id);
        formData.append('detTxt', text);
        formData.append('ord', orderId);
        formData.append('ordTxt', orderNum);
        // formData.append('ord', 1);
        // formData.append('det', 2);
        // formData.append('dgn', 3);

        // formData.append('ordTxt', 'MRD-1174-1');
        // formData.append('detTxt', 'product-code-1 brand-1');
        // formData.append('dgnTxt', 'change left wheel');

        await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: getStorageToken()
            },
            body: formData
        }).then(function (response) {
            if (response.status !== 200) {
                return notification.error({
                    message: `${formatMessage({
                        id: 'gallary.not_enought_space'
                    })}!`
                });
            }

            notification.success({
                message: formatMessage({ id: 'message.success' })
            });

            return Promise.resolve(response);
        });

        await this.props.fetchFileList();
        await this.props.bussinessStats();
    };

    // Example method to handle close modals

    updateDataSource = cb => {
        if (this.state.fetched && !this.props.isMobile) {
            this.setState({
                fetched: false
            });
        }
        if (this.props.isRetailOrder) {
            this.setState({
                fetched: false
            });
        }
        const callback = async data => {
            await this.setState({
                dataSource: this.buildVariations(data.orderDetails),
                selectedRowKeys: [],
                selectedRows: [],
                fetched: true
            });

            if (_.isFunction(cb)) {
                cb();
            }
        };
        this.props.reloadOrderForm(callback, 'details');
    };

    updateDetail = async detail => {
        if (this.props.isRetailOrder) {
            if (this.props.orderStatus === 'success') {
                window.location.reload();

                return;
            }
        } else {
            const { status } = await fetchAPI('GET', 'orders/status', { orderId: this.props.orderId }, null);
            if (status === 'success') {
                window.location.reload();

                return;
            }
        }
        if (this.state.fetched && !this.props.isMobile) {
            this.setState({
                fetched: false
            });
        }
        if (this.props.isRetailOrder) {
            this.setState({
                fetched: false
            });
        }
        // const productCode = (detail.detailCode || detail.productCode)
        //     .replace(/[^A-Za-z0-9\u0400-\u04FF]/gm, '')
        //     .toUpperCase();
        const newDetail = this.parseDetailData(detail);
        const data = {
            updateMode: true,
            details: [newDetail]
        };
        if (
            isGrantAccessed(
                this.props.user,
                grants.OPERATIONS_ORDER_DOCUMENT_PARTS_REPAIR_ORDER_STATUS_CHANGE,
                accesses.ROWO
            )
        ) {
            data.details[0].agreement = detail.agreement;
        }
        if (
            isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE, accesses.ROWO)
        ) {
            data.details[0].status = detail.status;
        }

        const { orderId } = this.props;
        if (this.props.isRetailOrder) {
            await fetchAPI('PUT', `retail/${orderId}`, null, data, { handleErrorInternally: true });
        } else {
            await fetchAPI('PUT', `orders/${orderId}`, null, data, { handleErrorInternally: true });
        }
        this.updateDataSource();
    };

    parseDetailData = detail => {
        return detail.productId
            ? {
                  id: detail.id,
                  parentId: detail.parentId || undefined,
                  storeGroupId: detail.storeGroupId,
                  name: detail.name || detail.detailName,
                  productId: detail.storeId || detail.productId,
                  productCode: detail.detailCode || detail.productCode || null,
                  purchasePrice: detail.purchasePrice || 0,
                  count: detail.count ? detail.count : 1,
                  price: detail.price ? detail.price : 0,
                  supplierBrandId: detail.supplierBrandId || detail.brandId,
                  supplierId: detail.supplierId,
                  supplierOriginalCode: detail.supplierOriginalCode,
                  supplierProductNumber: detail.supplierProductNumber,
                  supplierPartNumber: detail.supplierPartNumber,
                  cellAddress: detail.cellAddress || null,
                  warehouseId: detail.warehouseId,
                  partUnitId: detail.partUnitId || detail.productUnitId,
                  oeCode: detail.oeCode || null,
                  partRowDiscount: detail.partRowDiscount || 0,
                  comment: detail.comment || {
                      comment: undefined,
                      positions: []
                  }
              }
            : {
                  id: detail.id,
                  parentId: detail.parentId || undefined,
                  storeGroupId: detail.storeGroupId,
                  name: detail.name || detail.detailName,
                  productCode: detail.detailCode || detail.productCode || null,
                  supplierId: detail.supplierId,
                  supplierBrandId: detail.supplierBrandId || detail.brandId || null,
                  supplierOriginalCode: detail.supplierOriginalCode,
                  supplierProductNumber: detail.supplierProductNumber,
                  supplierPartNumber: detail.supplierPartNumber,
                  purchasePrice: detail.purchasePrice || 0,
                  count: detail.count,
                  price: detail.price ? detail.price : 0,
                  partUnitId: detail.partUnitId || detail.productUnitId,
                  oeCode: detail.oeCode || null,
                  partRowDiscount: detail.partRowDiscount || 0,
                  comment: detail.comment || {
                      comment: undefined,
                      positions: []
                  }
              };
    };

    buildVariations = orderDetails => {
        const tmp = [...orderDetails]
            .map((elem, key) => {
                return { ...elem, variations: [] };
            })
            .filter(({ groupName, agreement, id }) => {
                const res = orderDetails.filter(({ groupName: gpName }) => gpName === groupName);
                if (res.every(({ agreement }) => agreement === 'REJECTED') && id === res[0].id) {
                    return true;
                }

                return (groupName && agreement !== 'REJECTED') || !groupName;
            });

        orderDetails
            .filter(({ id }) => tmp.findIndex(({ id: ID }) => ID === id) === -1)
            .forEach((elem, key) => {
                const index = tmp.findIndex(({ groupName }) => groupName === elem.groupName);
                if (index > -1) {
                    tmp[index].variations.push({
                        ...elem,
                        leadIndex: index,
                        key,
                        brandId: elem.supplierBrandId || undefined,
                        brandName: elem.supplierBrandName
                    });
                }
            });

        return tmp.map((elem, key) => ({
            ...elem,
            key,
            brandId: elem.supplierBrandId || undefined,
            brandName: elem.supplierBrandName
        }));
    };

    handleSearchChange = debounce(e => this.setState({ search: e.target.value }), 300);

    filterDetails = dataSource => {
        const search = this.state.search.toLowerCase();

        return dataSource.filter(
            ({ detailName, detailCode, brandName }) =>
                filterStringByPart(search, String(detailName)) ||
                filterStringByPart(search, String(detailCode)) ||
                filterStringByPart(search, String(brandName))
        );
    };

    handleSearchSuppliers = debounce(value => {
        this.props.fetchSuppliers(value, true);
    }, 1000);

    toggleSelectAll = (keys, data) =>
        this.setState({
            selectedRowKeys: keys.length === data.length ? [] : data.map(r => r.key),
            selectedRows: keys.length === data.length ? [] : data
        });

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_add_s_p_data' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const {
            detailsSuggestionsFetching,
            suggestionsFetching,
            detailsTreeData,
            tecdocId,
            orderId,
            allDetails,
            clientVehicleVin,
            showOilModal,
            oilModalData,
            clearOilData,
            isMobile,
            modal,
            resetModal,

            disabled,
            selectedVehicle,
            defaultResponsibleId,
            units,
            clientId,
            suppliers,
            selectedClient,
            isRTN,
            intl,
            user
        } = this.props;
        const {
            fetched,
            dataSource,
            productBarcode,
            selectedRowKeys,
            warehousesModalSelectedRow,
            selectedRows,
            setPriceModal,
            supplierModalSelectedRow,
            detailModalVisibleMode,
            detailModalProduct,
            visibleSuppliersIncomeModal,
            suppliersIncomeModalRow,
            favouriteModalVisible,
            helperDrawerOpen,
            allLinks,
            search
        } = this.state;

        const { detailsDiscount, discount } = selectedClient || {};

        const tblDataSource = search.length ? this.filterDetails(dataSource) : dataSource;

        const columns = getColumns({
            props: this.props,
            state: this.state,
            updateDataSource: this.updateDataSource,
            updateDetail: this.updateDetail,
            downloadPhoto: this.downloadPhoto
        });

        const pagination = {
            defaultPageSize: 25,
            size: 'small',
            pageSizeOptions: [15, 25, 50, 100],
            hideOnSinglePage: dataSource.length < 25,
            showSizeChanger: true
        };

        const headerCheckbox = (
            <Checkbox
                checked={selectedRowKeys.length}
                indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < tblDataSource.length}
                onChange={() => this.toggleSelectAll(selectedRowKeys, tblDataSource)}
            />
        );

        const rowSelection = {
            selectedRowKeys,
            selectedRows,
            columnTitle: headerCheckbox,
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            },
            // onSelectAll: (selected, selectedRows, changeRows) => {
            //     if (selected) {
            //         const selectedRowKeys = tblDataSource.map(({ id }) => id);

            //         this.setState({
            //             selectedRowKeys,
            //             selectedRows: tblDataSource
            //         });

            //         return;
            //     }

            //     this.setState({
            //         selectedRowKeys: [],
            //         selectedRows: []
            //     });
            // },
            onSelect: (record, selected) => {
                if (selected) {
                    this.setState({
                        selectedRowKeys: [...selectedRows, record].map(({ id }) => id),
                        selectedRows: [...selectedRows, record]
                    });

                    return;
                }

                this.setState({
                    selectedRowKeys: selectedRowKeys.filter(id => id !== record.id),
                    selectedRows: selectedRows.filter(({ id }) => id !== record.id)
                });
            },
            getCheckboxProps: record => ({
                disabled: !record.id
            })
        };

        return (
            <Catcher>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                    <div style={{ display: 'flex', gap: 8, width: '100%' }}>
                        <Input
                            addonBefore={<SearchOutlined />}
                            allowClear
                            onChange={this.handleSearchChange}
                            placeholder={intl.formatMessage({ id: 'order_form_table.details_search' })}
                        />
                    </div>
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            this.setState({ helperDrawerOpen: true });

                            await this.fetchHelperLinks();
                        }}
                        style={{ fontSize: 22 }}
                        type='text'
                    />
                </div>

                <DraggableTable
                    addDragColumn={!isMobile}
                    bordered
                    className={Styles.detailsTable}
                    columns={columns}
                    dataSource={tblDataSource}
                    expandable={{
                        expandedRowRender: record => (
                            <DraggableTable
                                bordered
                                columns={columns}
                                dataSource={record.variations}
                                onDragEnd={async (fromIndex, toIndex) => {
                                    await fetchAPI(
                                        'PUT',
                                        'orders/swap_details',
                                        {
                                            orderId: this.props.orderId,
                                            order1: dataSource[fromIndex].order,
                                            order2: dataSource[toIndex].order
                                        },
                                        undefined,
                                        { handleErrorInternally: true }
                                    );
                                    await this.updateDataSource();
                                }}
                                onRow={product => ({
                                    onClick: () => {
                                        isMobile &&
                                            this.setState({
                                                detailModalVisibleMode: 1,
                                                detailModalProduct: product
                                            });
                                    }
                                })}
                                pagination={false}
                                rowSelection={
                                    !isMobile && {
                                        getCheckboxProps: () => ({
                                            disabled: true
                                        })
                                    }
                                }
                                showHeader={false}
                                size='small'
                            />
                        ),
                        rowExpandable: ({ variations }) => variations && variations.length
                    }}
                    loading={detailsSuggestionsFetching || suggestionsFetching || !fetched}
                    onDragEnd={async (fromIndex, toIndex) => {
                        await fetchAPI(
                            'PUT',
                            'orders/swap_details',
                            {
                                orderId: this.props.orderId,
                                order1: dataSource[fromIndex].order,
                                order2: dataSource[toIndex].order
                            },
                            undefined,
                            { handleErrorInternally: true }
                        );
                        await this.updateDataSource();
                    }}
                    onRow={product => ({
                        onClick: () => {
                            isMobile &&
                                this.setState({
                                    detailModalVisibleMode: 1,
                                    detailModalProduct: product
                                });
                        }
                    })}
                    pagination={pagination}
                    rowSelection={!isMobile && !disabled && rowSelection}
                    size='small'
                />

                {isMobile && (
                    <div
                        style={{
                            margin: '12px 0px 8px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 8
                        }}
                    >
                        <Button
                            data-qa='btn_add_form_vin_order_detail_modal'
                            disabled={!this.props.clientVehicleVin || this.props.disabled}
                            icon={<VinIcon style={{ fontSize: 20 }} />}
                            onClick={() => {
                                this.props.setModal(MODALS.VIN_MODAL, {
                                    setVinDetail: ({ oem, storeGroupId, name }) =>
                                        this.setState({
                                            detailModalVisibleMode: 2,
                                            detailModalProduct: {
                                                detailCode: oem,
                                                detailName: name,
                                                oeCode: oem,
                                                storeGroupId
                                            }
                                        }),
                                    setVinDetails: async codes => {
                                        const data = {
                                            insertMode: true,
                                            details: codes.map(({ oem, name, storeGroupId }) => ({
                                                productCode: oem,
                                                name,
                                                groupId: storeGroupId,
                                                oeCode: oem
                                            }))
                                        };
                                        await fetchAPI('PUT', `orders/${this.props.orderId}`, null, data, {
                                            handleErrorInternally: true
                                        });
                                        this.updateDataSource();
                                    },
                                    modalToLoad: MODALS.ORDER_DETAIL_MODAL
                                });
                            }}
                        />
                        <Button
                            data-qa='btn_header_actions_details_table_order_page'
                            disabled={this.props.disabled}
                            icon={<BookIcon />}
                            onClick={() => {
                                this.setState({
                                    detailModalVisibleMode: 2,
                                    detailModalProduct: {}
                                });
                            }}
                        />

                        <Button
                            data-qa='btn_show_detail_product_modal_details_table_order_page'
                            onClick={() =>
                                this.setState({
                                    detailModalVisibleMode: 1,
                                    detailModalProduct: {}
                                })
                            }
                        >
                            <FormattedMessage id='add' />
                        </Button>
                    </div>
                )}
                <OrderDetailModal
                    allDetails={allDetails.details}
                    brands={allDetails.brands}
                    clearOilData={clearOilData}
                    clientDiscount={discount}
                    clientId={clientId}
                    clientVehicleVin={clientVehicleVin}
                    defaultResponsibleId={defaultResponsibleId}
                    detailsDiscount={detailsDiscount}
                    downloadPhoto={this.downloadPhoto}
                    handleSearchSuppliers={this.handleSearchSuppliers}
                    hideModal={() => {
                        this.setState({
                            detailModalVisibleMode: 0,
                            detailModalProduct: {}
                        });
                    }}
                    isRetailOrder={this.props.isRetailOrder}
                    isRTN={isRTN}
                    modificationId={tecdocId}
                    oilModalData={oilModalData}
                    onFinish={this.updateDetail}
                    orderId={orderId}
                    product={detailModalProduct}
                    resetModal={resetModal}
                    selectedVehicle={selectedVehicle}
                    showOilModal={showOilModal}
                    suppliers={suppliers}
                    treeData={detailsTreeData}
                    units={units}
                    updateDataSource={this.updateDataSource}
                    user={user}
                    visible={detailModalVisibleMode}
                />
                <DetailSupplierModal
                    brandId={_.get(supplierModalSelectedRow, 'brandId')}
                    clientId={clientId}
                    detailCode={_.get(supplierModalSelectedRow, 'detailCode')}
                    hideButton
                    hideModal={() => {
                        this.setState({
                            supplierModalSelectedRow: undefined
                        });
                    }}
                    onSelect={item => {
                        supplierModalSelectedRow.supplierId = item.businessSupplierId;
                        supplierModalSelectedRow.purchasePrice = item.purchasePrice;
                        if (supplierModalSelectedRow.agreement !== 'AGREED') {
                            supplierModalSelectedRow.price = item.price;
                        }
                        supplierModalSelectedRow.supplierOriginalCode = item.supplierOriginalCode;
                        supplierModalSelectedRow.supplierPartNumber = item.supplierPartNumber;
                        this.updateDetail(supplierModalSelectedRow);
                    }}
                    storeGroupId={_.get(supplierModalSelectedRow, 'storeGroupId')}
                    user={user}
                    visible={Boolean(supplierModalSelectedRow)}
                />
                <UpdatePriceModal
                    handleClose={() => this.setState({ setPriceModal: undefined })}
                    handleOk={this.updateDataSource}
                    isRTN={isRTN}
                    open={setPriceModal}
                    ordersAppurtenanciesIds={
                        setPriceModal && setPriceModal.id ? [setPriceModal.id] : selectedRows.map(({ id }) => id)
                    }
                    selectedRows={selectedRows}
                />

                <SetBarcodeModal
                    barcode={productBarcode}
                    confirmAction={async id => {
                        const payload = {
                            insertMode: true,
                            details: [],
                            services: []
                        };
                        const product = await fetchAPI('GET', `store_products/${id}`);
                        payload.details.push({
                            productId: product.id,
                            storeGroupId: product.groupId,
                            name: product.name,
                            productCode: product.code,
                            supplierBrandId: product.brandId,
                            supplierId: 0,
                            count: 1,
                            price: product.sellingPrice || 0,
                            purchasePrice: product.purchasePrice || 0
                        });
                        await fetchAPI('PUT', `orders/${orderId}`, null, payload);
                        await this.updateDataSource();
                    }}
                    hideModal={() => {
                        this.setState({
                            productBarcode: undefined
                        });
                    }}
                    visible={Boolean(productBarcode)}
                />
                <DetailWarehousesCountModal
                    count={get(warehousesModalSelectedRow, 'count')}
                    hideButton
                    hideModal={() => {
                        this.setState({
                            warehousesModalSelectedRow: undefined
                        });
                    }}
                    onSelect={(address, warehouseId) => {
                        warehousesModalSelectedRow.supplierId = 0;
                        warehousesModalSelectedRow.cellAddress = address;
                        warehousesModalSelectedRow.warehouseId = warehouseId;
                        this.updateDetail(warehousesModalSelectedRow);
                    }}
                    orderId={orderId}
                    productId={_.get(warehousesModalSelectedRow, 'productId')}
                    productUnit={_.get(warehousesModalSelectedRow, 'measureUnit')}
                    visible={Boolean(warehousesModalSelectedRow)}
                />
                <ImportDetailsModal
                    modal={modal}
                    orderId={orderId}
                    resetModal={resetModal}
                    suppliers={suppliers}
                    updateDataSource={this.updateDataSource}
                />
                <SuppliersIncomeModal
                    hideModal={() => {
                        this.setState({
                            selectedRowKeys: [],
                            selectedRows: [],
                            visibleSuppliersIncomeModal: false,
                            suppliersIncomeModalRow: undefined
                        });
                    }}
                    orderId={orderId}
                    row={suppliersIncomeModalRow}
                    selectedRows={selectedRows}
                    updateDataSource={this.updateDataSource}
                    visible={visibleSuppliersIncomeModal}
                />
                <FavouriteDetailsModal
                    hideModal={() => this.setState({ favouriteModalVisible: false })}
                    orderId={orderId}
                    updateDataSource={this.updateDataSource}
                    user={user}
                    visible={favouriteModalVisible}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}

export default DetailsTable;
