import { Select, Tooltip } from 'antd';
import { paymentTypes } from 'forms/CashCreationForm/config';
import { CASH_ORDER_TYPES, MODES } from 'modals/RefactoredCashOrderModal/constants';
import { selectCashboxes } from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import {
    selectCashOrderType,
    selectMode,
    selectPaymentType,
    selectRequisiteId,
    selectSelectedCashbox,
    selectSelectedCashboxId,
    setCashlessPaymentType,
    setPaymentType
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const { INCOME, EXPENSE, ADJUSTMENT_INCOME } = CASH_ORDER_TYPES;

const mapStateToProps = state => ({
    cashOrderType: selectCashOrderType(state),
    selectedCashbox: selectSelectedCashbox(state),
    selectRequisiteId: selectRequisiteId(state),
    selectPaymentType: selectPaymentType(state),
    mode: selectMode(state),
    cashboxes: selectCashboxes(state),
    selectedCashboxId: selectSelectedCashboxId(state)
});

const mapDispatchToProps = {
    setPaymentType,
    setCashlessPaymentType
};

/**
 * This component serves selecting cash order type
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class PaymentTypeSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const { selectedCashboxId, selectPaymentType, cashboxes, setPaymentType, setCashlessPaymentType } = this.props;
        if (selectedCashboxId && !selectPaymentType) {
            // console.log(selectedCashboxId, selectPaymentType, prevProps.selectedCashboxId, selectedCashboxId);
            const cashbox = cashboxes.find(({ id }) => id === selectedCashboxId);
            if (cashbox) setPaymentType(cashbox.type === 'CASH' ? 'CASH' : 'CASHLESS');
            if (cashbox && cashbox.type === 'CASHLESS') setCashlessPaymentType('POS_TERMINAL');
        }
    }

    componentDidUpdate(prevProps) {
        const { selectedCashboxId, selectPaymentType, cashboxes, setPaymentType, setCashlessPaymentType } = this.props;
        if ((selectedCashboxId && !selectPaymentType) || prevProps.selectedCashboxId !== selectedCashboxId) {
            // console.log(selectedCashboxId, selectPaymentType, prevProps.selectedCashboxId, selectedCashboxId);
            const cashbox = cashboxes.find(({ id }) => id === selectedCashboxId);
            if (cashbox) setPaymentType(cashbox.type === 'CASH' ? 'CASH' : 'CASHLESS');
            if (cashbox && cashbox.type === 'CASHLESS') setCashlessPaymentType('POS_TERMINAL');
        }
    }

    render() {
        const {
            cashOrderType,
            setRequisites,
            selectRequisiteId,
            mode,
            editable,
            cashboxes,
            selectedCashboxId,
            selectPaymentType,
            setPaymentType,
            setCashlessPaymentType,
            intl: { formatMessage }
        } = this.props;

        return (
            <Tooltip placement='top' title={<FormattedMessage id='payment_type' />} zIndex={2001}>
                <Select
                    disabled={mode != MODES.ADD}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={value => {
                        if (!value) {
                            setCashlessPaymentType(null);
                            setPaymentType(null);
                        } else {
                            if (value !== 'CASH') {
                                setCashlessPaymentType('POS_TERMINAL');
                            } else {
                                setCashlessPaymentType(null);
                            }
                            setPaymentType(value);
                        }
                    }}
                    placeholder={formatMessage({
                        id: 'payment_type'
                    })}
                    value={selectPaymentType}
                >
                    {Object.values(paymentTypes).map(item => (
                        <Option key={item} value={item}>
                            {formatMessage({ id: `cash-creation-form.type-${item}` })}
                        </Option>
                    ))}
                </Select>
            </Tooltip>
        );
    }
}
