import { Drawer, Select, Spin, Table } from 'antd';
import { selectCashSum } from 'core/forms/orderForm/duck';
import { setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const InteractionStatusesContainer = ({
    onClose,
    open,
    intl: { formatMessage },
    filterCreationStartDate,
    filterCreationEndDate,
    filterLastStartDate,
    filterLastEndDate,
    filterNextStartDate,
    filterNextEndDate
}) => {
    const [statuses, setStatusesInfo] = useState([]);

    const [loading, setLoading] = useState();

    const totalCounts = statuses
        .flatMap(manager => manager.statuses)
        .reduce((acc, status) => {
            acc[status.subStatusName] = (acc[status.subStatusName] || 0) + status.count;

            return acc;
        }, {});

    const transformedData = statuses.flatMap(manager =>
        manager.statuses.map(status => ({
            managerId: manager.managerId,
            managerName: manager.managerName.trim(),
            subStatusName: status.subStatusName,
            subStatusId: status.subStatusId,
            count: status.count,
            totalCount: totalCounts[status.subStatusName]
        }))
    );

    const groupedData = Object.values(
        transformedData.reduce((acc, item) => {
            if (!acc[item.subStatusId]) {
                // Initialize new entry grouped by subStatusId
                acc[item.subStatusId] = {
                    subStatusId: item.subStatusId,
                    subStatusName: item.subStatusName,
                    totalCount: item.totalCount,
                    managers: [{ managerId: item.managerId, managerName: item.managerName, count: item.count }]
                };
            } else {
                // If the subStatusId already exists, add manager details
                acc[item.subStatusId].managers.push({
                    managerId: item.managerId,
                    managerName: item.managerName,
                    count: item.count
                });
            }

            return acc;
        }, {})
    );

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='status' />,
                dataIndex: 'subStatusName',
                key: 'name',
                align: 'center'
            },
            ...statuses.map(({ managerName, managerId, count, statuses }, index) => ({
                title: () => {
                    const totalCountByManagers = statuses.reduce((sum, status) => sum + status.count, 0);

                    return (
                        <div>
                            <div>{managerName}</div>
                            <div>
                                <FormattedMessage id='storage_journal.amount' /> {`(${totalCountByManagers})`}
                            </div>
                        </div>
                    );
                },
                key: `manager_${index}`,
                align: 'center',
                render: row => {
                    const specificManager = row.managers.find(elem => elem.managerId === managerId);

                    return specificManager?.count;
                }
            })),
            {
                title: () => {
                    const totalCountByAllRows = groupedData.reduce((sum, elem) => sum + elem.totalCount, 0);

                    return (
                        <div>
                            <div>
                                <FormattedMessage id='sub_total' />,
                            </div>
                            <div>
                                <FormattedMessage id='storage_journal.amount' /> {`(${totalCountByAllRows})`}
                            </div>
                        </div>
                    );
                },
                dataIndex: 'totalCount',
                key: 'totalCount',
                align: 'center'
            }
        ];
    }, [groupedData, statuses]);

    // const columns = useMemo(() => {
    //     return [
    //         {
    //             title: <FormattedMessage id='Статус' />,
    //             key: 'subStatusName',
    //             dataIndex: 'subStatusName',
    //             align: 'center'
    //         },
    //         {
    //             title: <FormattedMessage id='Кількість' />,
    //             key: 'count',
    //             dataIndex: 'count',
    //             align: 'center'
    //         }
    //     ];
    // }, []);

    const fetchStatuses = useCallback(
        async query => {
            setLoading(true);
            const res = await fetchAPI(
                'GET',
                '/crm/interactions_docs/statistics',
                {
                    creationStartDate: filterCreationStartDate || dayjs().startOf('month').format('YYYY-MM-DD'),
                    creationEndDate: filterCreationEndDate || dayjs().endOf('month').format('YYYY-MM-DD'),
                    lastStartDate: filterLastStartDate || undefined,
                    lastEndDate: filterLastEndDate || undefined,
                    nextStartDate: filterNextStartDate || undefined,
                    nextEndDate: filterNextEndDate || undefined
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setStatusesInfo(res);

            setLoading(false);
        },
        [
            filterCreationEndDate,
            filterCreationStartDate,
            filterLastEndDate,
            filterLastStartDate,
            filterNextEndDate,
            filterNextStartDate
        ]
    );

    useEffect(() => {
        if (open) {
            fetchStatuses();
        }
    }, [fetchStatuses, open]);

    const mask = '0,0.00';

    return (
        <React.Fragment>
            <Drawer onClose={onClose} open={open} title={formatMessage({ id: 'employee-page.statistics' })} width='70%'>
                {loading ? (
                    <Spin />
                ) : (
                    <div className={Styles.paper}>
                        <Table
                            bordered
                            className={Styles.documentsTable}
                            columns={columns}
                            dataSource={groupedData}
                            sticky
                            pagination={false}
                            // scroll={{ x: 800 }}
                            style={{
                                marginTop: 8
                            }}
                        />
                    </div>
                )}
            </Drawer>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    cashSum: selectCashSum(state)
});

const mapDispatchToProps = {
    setModal
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InteractionStatusesContainer));
