import { RollbackOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, InputNumber, notification, Select, Tabs, TreeSelect } from 'antd';
import { Catcher, Layout } from 'commons';
import { PhoneInput } from 'components';
import { RequisiteSettingContainer, StorageTable } from 'containers';
import { resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import { deleteSupplierRequisite, postSupplierRequisite, updateSupplierRequisite } from 'core/requisiteSettings/saga';
import _ from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import { filterTreeByActive } from 'pages/AccountPlanPage/utils/filterTreeByActive';
import { getAccountsList } from 'pages/AccountPlanPage/utils/getAccountsList';
import InteractionsTable from 'pages/InteractionsPage/components/InteractionsTable';
import {
    fetchStoreDocs,
    selectCashOrdersFilters,
    selectStoreDocsFilters,
    setCashOrdersFilters,
    setStoreDocsDeliveryFilters,
    setStoreDocsFilters
} from 'pages/SupplierPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI, filterTreeNodeByPart, phoneNumberParser } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { StoreDocsDeliveryTable, StoreDocsTable } from './components/tables';
import Styles from './styles.m.css';

const { TabPane } = Tabs;
const { Search, TextArea } = Input;
const { Option } = Select;
// import Col from "antd/es/descriptions/Col";

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    modal: selectModal(state),
    modalProps: selectModalProps(state),
    storeDocFilters: selectStoreDocsFilters(state),
    cashOrderFilters: selectCashOrdersFilters(state)
});

const mapDispatchToProps = {
    fetchStoreDocs,
    resetModal,
    setStoreDocsFilters,
    setCashOrdersFilters,
    setStoreDocsDeliveryFilters
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SupplierPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requisitesModalVisible: false,
            requisiteData: undefined,
            requisitesDataSource: undefined,
            pageSize: 25,
            page: 1,
            generalInfo: {},
            orders: [],
            supplies: [],
            tree: [],
            accounts: [],
            supplierReq: true
        };

        this.setRequisitesDataSource = this.setRequisitesDataSource.bind(this);
        this.updateRequisitesDataSource = this.updateRequisitesDataSource.bind(this);
        this.hideRequisitesModal = this.hideRequisitesModal.bind(this);
    }

    showRequisitesModal = (requisiteData = undefined) => {
        this.setState({
            requisitesModalVisible: true,
            requisiteData
        });
    };

    hideRequisitesModal(isSubmitted) {
        this.setState({
            requisitesModalVisible: false,
            requisiteData: undefined
        });
        if (isSubmitted) {
            this.fetchData();
        }
    }

    fetchAccounts = async query => {
        const accounts = await getAccountsList(query);
        this.setState({ accounts });
    };

    componentDidMount() {
        this.fetchData();
        this.fetchOrders();
        this.fetchSupplies();
        this.fetchAccounts();
        this.props.id && this.props.setStoreDocsFilters({ supplierId: this.props.id });
        this.props.id && this.props.setCashOrdersFilters({ supplierId: this.props.id });
        this.props.id &&
            this.props.setStoreDocsDeliveryFilters({
                counterpartBusinessSupplierId: this.props.id
            });
    }

    filterTree = nodes => {
        const filteredNodes = filterTreeByActive(nodes);

        // Update the state outside of the reduce function
        this.setState({
            tree: filteredNodes
        });
    };

    componentDidUpdate() {
        const { tree } = this.state;
        if (!_.get(tree, 'length') && _.get(this.state.accounts, 'length')) {
            this.filterTree(this.state.accounts);
        }
    }

    setRequisitesDataSource(data) {
        data.map((elem, i) => {
            elem.key = i;
        });
        this.setState({
            requisitesDataSource: data
        });
    }

    updateRequisitesDataSource = async () => {
        await this.setState({
            loading: true,
            dataSource: undefined,
            modalVisible: false,
            requisiteData: undefined
        });
        this.fetchData();
    };

    fetchOrders(page, pageSize) {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/store_docs?page=${page || this.state.page}&pageSize=${
            pageSize || this.state.pageSize
        }&types=["INCOME"]&documentTypes=["SUPPLIER"]&contexts=["ORDER"]&counterpartBusinessSupplierId=${
            this.props.id
        }`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.list.map((elem, key) => {
                    elem.key = key;
                });
                that.setState({
                    orders: data.list,
                    count: data.stats.count
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    fetchSupplies() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/store_docs?types=["EXPENSE"]&documentTypes=["SUPPLIER"]&contexts=["ORDER"]&counterpartBusinessSupplierId=${this.props.id}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.list.map((elem, key) => {
                    elem.key = key;
                });
                that.setState({
                    supplies: data.list
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    fetchData = async () => {
        const data = await fetchAPI('GET', `business_suppliers/${this.props.id}`, null, null, {
            handleErrorInternally: true
        });
        (data || []).requisites.map((elem, key) => {
            elem.key = key;
        });
        console.log(data);
        this.setState({
            generalInfo: {
                ...data,
                ...(_.get(data, 'accountId')
                    ? {}
                    : {
                          accountId: this.props.user.supplierAccountId
                              ? String(this.props.user.supplierAccountId)
                              : undefined
                      })
            },
            requisitesDataSource: data.requisites,
            requisiteData: undefined,
            loading: false
        });
    };

    updateSupplier = async () => {
        const re = /\S+@\S+\.\S+/;
        const { generalInfo } = this.state;
        const {
            intl: { formatMessage }
        } = this.props;
        const phone = phoneNumberParser(_.get(generalInfo, 'phones'));
        const data = {
            name: _.get(generalInfo, 'name'),
            phones: phone ? [phone] : undefined,
            emails: _.get(generalInfo, 'emails') || [],
            type: _.get(generalInfo, 'type', null),
            comment: _.get(generalInfo, 'comment'),
            paymentRespite: _.get(generalInfo, 'paymentRespite') || 0,
            accountId: _.get(generalInfo, 'accountId') ? String(_.get(generalInfo, 'accountId')) : undefined
        };
        if (generalInfo.contactName) data.contactName = generalInfo.contactName;
        if (generalInfo.emails && generalInfo.emails.length && !re.test(generalInfo.emails[0])) {
            notification.error({
                message: formatMessage({ id: 'supplier.error.email' })
            });

            return;
        }

        await fetchAPI(
            'PUT',
            `business_suppliers/${this.props.id}/settings`,
            null,
            {
                hide: Boolean(generalInfo.hide)
            },
            { handleErrorInternally: true }
        );
        await fetchAPI('PUT', `business_suppliers/${this.props.id}`, null, data, {
            handleErrorInternally: true
        });
        // window.location.reload();
        this.fetchData();
        notification.success({
            message: formatMessage({ id: 'message.success' })
        });
    };

    handleSearchStoreDoc = _.debounce(value => {
        this.props.setStoreDocsFilters({
            query: _.toLower(value.replace(/[+()]/g, ''))
        });
    }, 1000);

    handleSearchCashOrders = _.debounce(value => {
        this.props.setCashOrdersFilters({
            query: _.toLower(value.replace(/[+()]/g, ''))
        });
    }, 1000);

    render() {
        const { user, location, id, isMobile, storeDocFilters, cashOrderFilters, modal, modalProps, resetModal } =
            this.props;
        const {
            generalInfo,
            requisitesModalVisible,
            requisiteData,
            requisitesDataSource,
            orders,
            loading,
            pageSize,
            page,
            count,
            supplierReq
        } = this.state;

        const isDisabledCRUDGeneral = !isGrantAccessed(
            user,
            grants.DIRECTORIES_SUPPLIERS_LIST_GENERAL_INFORMATION_CARD,
            accesses.ROWO
        );

        const isDisabledCRUDAccounts = !isGrantAccessed(
            user,
            grants.DIRECTORIES_SUPPLIERS_LIST_GENERAL_INFORMATION_CARD_ACCOUNTING_BY_ACCOUNT,
            accesses.ROWO
        );

        const pagination = {
            pageSize,
            size: 'small',
            total: Math.ceil(count / pageSize) * pageSize,
            hideOnSinglePage: false,
            current: page,
            onChange: (page, pageSize) => {
                this.setState({ page, pageSize });
                this.fetchOrders(page, pageSize);
            }
        };

        return (
            <Layout
                controls={
                    <div>
                        <Button
                            onClick={() => {
                                history.push({ pathname: `${book.suppliersPage}` });
                            }}
                        >
                            <RollbackOutlined />
                        </Button>
                    </div>
                }
                title={<FormattedMessage id='order_form_table.supplier' />}
            >
                <Catcher>
                    <Tabs defaultActiveKey={(location.state && location.state.tab) || 'general'} tabPosition='top'>
                        {isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST_GENERAL_INFORMATION_CARD) && (
                            <TabPane key='general' tab={<FormattedMessage id='client_container.general_info' />}>
                                <div className={Styles.generalInfo}>
                                    <div className={Styles.generalInfoItem}>
                                        <FormattedMessage id='client_requisites_container.name' />
                                        <Input
                                            disabled={isDisabledCRUDGeneral}
                                            onChange={event => {
                                                const { value } = event.target;
                                                generalInfo.name = value;
                                                this.setState({});
                                            }}
                                            value={generalInfo.name}
                                        />
                                    </div>
                                    <div className={Styles.generalInfoItem}>
                                        <FormattedMessage id='supplier.contact' />
                                        <Input
                                            disabled={isDisabledCRUDGeneral}
                                            onChange={event => {
                                                const { value } = event.target;
                                                generalInfo.contactName = value;
                                                this.setState({});
                                            }}
                                            value={generalInfo.contactName}
                                        />
                                    </div>
                                    <div className={Styles.generalInfoItem}>
                                        <FormattedMessage id='supplier.phone' />
                                        <div style={{ display: 'block', width: '100%' }}>
                                            <PhoneInput
                                                disabled={isDisabledCRUDGeneral}
                                                onChange={value => {
                                                    generalInfo.phones = [String(value)];
                                                    this.setState({});
                                                }}
                                                value={(generalInfo.phones && generalInfo.phones[0]) || null}
                                            />
                                        </div>
                                    </div>
                                    <div className={Styles.generalInfoItem}>
                                        <span>E-mail</span>
                                        <Input
                                            disabled={isDisabledCRUDGeneral}
                                            onChange={event => {
                                                const { value } = event.target;
                                                generalInfo.emails = [value];
                                                this.setState({});
                                            }}
                                            value={generalInfo.emails && generalInfo.emails[0]}
                                        />
                                    </div>
                                    <div className={Styles.generalInfoItem}>
                                        <FormattedMessage id='supplier.type' />
                                        <Select
                                            allowClear
                                            disabled={isDisabledCRUDGeneral}
                                            onChange={value => {
                                                generalInfo.type = value;
                                                this.setState({});
                                            }}
                                            optionFilterProp='children'
                                            showSearch
                                            value={generalInfo.type}
                                        >
                                            <Option value='PHYSICAL_PERSON'>
                                                <FormattedMessage id='add_client_form.PHYSICAL_PERSON' />
                                            </Option>
                                            <Option value='ENTREPRENEUR'>
                                                <FormattedMessage id='add_client_form.ENTREPRENEUR' />
                                            </Option>
                                            <Option value='LIMITED_LIABILITY_COMPANY'>
                                                <FormattedMessage id='add_client_form.LIMITED_LIABILITY_COMPANY' />
                                            </Option>
                                            <Option value='OTHER'>
                                                <FormattedMessage id='add_client_form.OTHER' />
                                            </Option>
                                        </Select>
                                    </div>
                                    <div className={Styles.generalInfoItem}>
                                        <FormattedMessage id='supplier.description' />
                                        <TextArea
                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                            disabled={isDisabledCRUDGeneral}
                                            onChange={event => {
                                                const { value } = event.target;
                                                generalInfo.comment = value;
                                                this.setState({});
                                            }}
                                            value={generalInfo.comment}
                                        />
                                    </div>
                                    <div className={Styles.generalInfoItem}>
                                        <FormattedMessage id='new-document-page.item.account' />
                                        <TreeSelect
                                            disabled={isDisabledCRUDAccounts}
                                            filterTreeNode={filterTreeNodeByPart}
                                            onChange={value => {
                                                generalInfo.accountId = value;
                                                this.setState({});
                                            }}
                                            showSearch
                                            treeData={this.state.tree}
                                            value={generalInfo.accountId}
                                        />
                                    </div>
                                    <div
                                        className={Styles.generalInfoItem}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div>
                                            <FormattedMessage id='supplier.paymentRespite' />
                                            <InputNumber
                                                decimalSeparator=','
                                                disabled={isDisabledCRUDGeneral}
                                                min={0}
                                                onChange={value => {
                                                    generalInfo.paymentRespite = value;
                                                    this.setState({});
                                                }}
                                                style={{
                                                    margin: '0 0 0 8px'
                                                }}
                                                value={generalInfo.paymentRespite || 0}
                                            />{' '}
                                            <FormattedMessage id='universal_filters_form.days' />
                                        </div>
                                        <div>
                                            <FormattedMessage id='supplier.show_supplier' />
                                            <Checkbox
                                                checked={!generalInfo.hide}
                                                disabled={isDisabledCRUDGeneral}
                                                onChange={event => {
                                                    generalInfo.hide = !event.target.checked;
                                                    this.setState({});
                                                }}
                                                style={{
                                                    margin: '0 0 0 8px'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={Styles.submitButton}>
                                        <Button
                                            disabled={isDisabledCRUDGeneral}
                                            onClick={() => {
                                                this.updateSupplier();
                                            }}
                                            type='primary'
                                        >
                                            <FormattedMessage id='save' />
                                        </Button>
                                    </div>
                                </div>
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST_REQUISITES) && (
                            <TabPane key='requisites' tab={<FormattedMessage id='client_container.requisites' />}>
                                <div className={Styles.addRequisiteButton}>
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_SUPPLIERS_LIST_REQUISITES,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={() => {
                                            this.showRequisitesModal();
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='add' />
                                    </Button>
                                </div>
                                <RequisiteSettingContainer
                                    dataSource={requisitesDataSource}
                                    deleteRequisite={deleteSupplierRequisite}
                                    hideModal={this.hideRequisitesModal}
                                    id={id}
                                    loading={loading}
                                    modalVisible={requisitesModalVisible}
                                    postRequisite={postSupplierRequisite}
                                    requisiteData={requisiteData}
                                    showModal={this.showRequisitesModal}
                                    supplierReq={supplierReq}
                                    tree={this.state.tree}
                                    updateDataSource={this.updateRequisitesDataSource}
                                    updateRequisite={updateSupplierRequisite}
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST_ORDERS) && (
                            <TabPane key='orders' tab={<FormattedMessage id='supplier.orders' />}>
                                <StorageTable
                                    docType='ORDER'
                                    documentsList={orders}
                                    hideFilters
                                    isOrder
                                    listType='ORDER'
                                    pagination={pagination}
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST_DELIVERIES) && (
                            <TabPane key='supply' tab={<FormattedMessage id='supplier.supply' />}>
                                <StoreDocsDeliveryTable />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS) && (
                            <TabPane key='debt' tab={<FormattedMessage id='supplier.debt' />}>
                                <StoreDocsTable
                                    counterpartyType='supplier'
                                    disabled={
                                        !isGrantAccessed(
                                            user,
                                            grants.DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS,
                                            accesses.ROWO
                                        )
                                    }
                                    id={id}
                                    name={generalInfo.name}
                                    user={user}
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST_INTERACTIONS) && (
                            <TabPane key='interactions' tab={<FormattedMessage id='navigation.interactions' />}>
                                <InteractionsTable
                                    reqfilters={{
                                        counterpartyId: id
                                    }}
                                />
                            </TabPane>
                        )}
                    </Tabs>
                </Catcher>
                <Catcher>
                    <RefactoredCashOrderModal />
                </Catcher>
            </Layout>
        );
    }
}
