/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import { CheckOutlined, DeleteOutlined, LoadingOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, InputNumber, Menu, Modal, Spin, Table } from 'antd';
import { HamburgerMenu } from 'components';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, numeralFormatter, numeralParser } from 'utils';
import Styles from './styles.m.css';

const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

@injectIntl
class FavouriteDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataSource: [],
            fetched: false,
            page: 1,
            pageSize: 15,
            selectedRowKeys: [],
            selectedRows: []
        };

        this.storeGroups = [];
        this.treeData = [];

        this.columns = () => [
            {
                title: () => {
                    const {
                        orderId,
                        intl: { formatMessage }
                    } = this.props;
                    const { selectedRows, selectedRowKeys } = this.state;

                    const actionsMenu = (
                        <Menu>
                            <Menu.Item disabled={!selectedRows.length}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.favourite_remove'
                                            }),
                                            zIndex: 2001,
                                            onOk: async () => {
                                                await fetchAPI('DELETE', 'orders/frequent/details', {
                                                    ids: `[${this.state.selectedRows.map(
                                                        ({ frequentDetailId }) => frequentDetailId
                                                    )}]`
                                                });
                                                this.fetchData();
                                                this.props.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                disabled={!selectedRows.length}
                                onClick={() => {
                                    this.handleOk(selectedRows);
                                }}
                            >
                                <div>
                                    <CheckOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'order_form_table.add_to_order_tooltip' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} />;
                },
                key: 'actions',
                align: 'center',
                render: row => {
                    const actionsMenu = () => (
                        <Menu>
                            <Menu.Item>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.favourite_remove'
                                            }),
                                            zIndex: 2001,
                                            onOk: async () => {
                                                await fetchAPI('DELETE', 'orders/frequent/details', {
                                                    ids: `[${row.frequentDetailId}]`
                                                });
                                                this.fetchData();
                                                this.props.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => {
                                    this.handleOk([row]);
                                }}
                            >
                                <div>
                                    <CheckOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'order_form_table.add_to_order_tooltip' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <HamburgerMenu actionsMenu={actionsMenu}>
                            <Button
                                data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                icon={<MenuOutlined />}
                            />
                        </HamburgerMenu>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_form_table.store_group' />,
                key: 'storeGroupName',
                dataIndex: 'storeGroupName'
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'code',
                render: row => {
                    return (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700,
                                    textDecoration: row.detailCode && 'underline'
                                }}
                            >
                                {row.detailCode}
                            </div>
                            <div style={{ fontSize: 12 }}>{row.detailName}</div>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brand',
                render: row => {
                    return (
                        <div
                            style={{
                                fontWeight: 700,
                                textDecoration: 'underline'
                            }}
                        >
                            {row.brandName}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.store_supplier' />,
                key: 'businessSupplierName',
                align: 'center',
                dataIndex: 'supplierName'
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                width: '3%',
                align: 'right',
                render: row => {
                    return (
                        <InputNumber
                            className='detailNumberInput'
                            data-qa='input_number_purchasePrice_order_detail_edit_modal'
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={0}
                            onChange={value => {
                                row.purchasePrice = value;
                                this.setState({});
                            }}
                            parser={value => numeralParser(value)}
                            value={Math.round(row.purchasePrice * 100) / 100 || 0}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                width: '3%',
                align: 'right',
                render: row => {
                    return (
                        <InputNumber
                            className='detailNumberInput'
                            data-qa='input_number_price_order_detail_edit_modal'
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={1}
                            onChange={value => {
                                row.price = value;
                                this.setState({});
                            }}
                            parser={value => numeralParser(value)}
                            value={Math.round(row.price * 100) / 100 || 1}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'count',
                width: '3%',
                align: 'right',
                render: row => {
                    return (
                        <InputNumber
                            className='detailNumberInput'
                            data-qa='input_number_count_order_detail_edit_modal'
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={0.1}
                            onChange={value => {
                                row.count = value;
                                this.setState({});
                            }}
                            parser={value => numeralParser(value)}
                            value={Math.round(row.count * 10) / 10 || 1}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'sum',
                width: '5%',
                align: 'right',
                render: elem => {
                    const sum = elem.price * (elem.count || 1);

                    return (
                        <InputNumber
                            className='detailNumberInput'
                            data-qa='input_number_sum_order_favourite_detail_modal'
                            decimalSeparator=','
                            disabled
                            formatter={value => numeralFormatter(value)}
                            parser={value => numeralParser(value)}
                            style={{ color: 'black' }}
                            value={sum ? Math.round(sum * 100) / 100 : 1}
                        />
                    );
                }
            }
        ];
    }

    handleOk = async rows => {
        const { status } = await fetchAPI('GET', 'orders/status', { orderId: this.props.orderId }, null);
        if (status === 'success') {
            window.location.reload();

            return;
        }
        const payload = {
            insertMode: true,
            details: rows.map(
                ({ storeGroupId, detailName, detailCode, supplierId, brandId, count, purchasePrice, price }) => ({
                    storeGroupId,
                    name: detailName,
                    productCode: detailCode,
                    supplierId,
                    supplierBrandId: brandId,
                    count: count || 1,
                    purchasePrice,
                    price
                })
            )
        };

        await fetchAPI('PUT', `orders/${this.props.orderId}`, null, payload, {
            handleErrorInternally: true
        });
        this.props.updateDataSource();

        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            page: 1,
            pageSize: 25,
            selectedRows: [],
            selectedRowKeys: []
        });
        this.props.hideModal();
    };

    fetchData = async () => {
        this.setState({ fetched: false });
        const { page, pageSize } = this.state;
        const {
            details: { result, stats }
        } = await fetchAPI('GET', 'orders/frequent/details', { page, pageSize }, null, {
            handleErrorInternally: true
        });

        this.setState({
            dataSource: result,
            fetched: true,
            total: stats[0].count
        });
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.fetchData();
        }
    }

    render() {
        const { visible } = this.props;
        const { fetched, total, page, pageSize, selectedRowKeys } = this.state;

        const pagination = {
            pageSize,
            size: 'small',
            total: (Math.ceil(total) / pageSize) * pageSize,
            showSizeChanger: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchData();
            }
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        return (
            <Modal
                footer={null}
                maskClosable={false}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='details_table.favorite_details' />}
                visible={visible}
                width='95%'
            >
                {fetched ? (
                    <Table
                        bordered
                        columns={this.columns()}
                        dataSource={this.state.dataSource}
                        pagination={pagination}
                        rowKey='frequentDetailId'
                        rowSelection={rowSelection}
                    />
                ) : (
                    <Spin indicator={spinIcon} />
                )}
            </Modal>
        );
    }
}
export default FavouriteDetailsModal;
