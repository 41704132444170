export const cashboxTypes = Object.freeze({
    CASH: 'CASH',
    CREDIT_CARD: 'CREDIT_CARD',
    CASHLESS: 'CASHLESS',
    BANK_ACCOUNT: 'BANK_ACCOUNT',
    OTHER: 'OTHER'
});

export const paymentTypes = Object.freeze({
    CASH: 'CASH',
    CASHLESS: 'CASHLESS'
});

export const cashlessPaymentTypes = Object.freeze({
    POS_TERMINAL: 'POS_TERMINAL',
    IBAN: 'IBAN',
    QR: 'QR',
    ACQUIRING: 'ACQUIRING',
    SERVICE: 'SERVICE',
    THE_WEBSITE_AT_THE_LINK: 'THE_WEBSITE_AT_THE_LINK'
});
