import { ContainerOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import { Layout } from 'commons';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import book from 'routes/book';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import InteractionsTable from './components/InteractionsTable';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const InteractionsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ intl, user, isMobile }) => {
    const [query, setQuery] = useState('');
    const [shouldReset, setShouldReset] = useState();
    const [openDrawer, setOpenDrawer] = useState(false);
    const history = useHistory();

    const reset = useCallback(() => setShouldReset(null), []);
    const startReset = () => setShouldReset('reset');

    const resetFilters = () => {
        setQuery(undefined);
        startReset();
    };

    return (
        <Layout
            className='interactions_page'
            // description={<FormattedMessage id='navigation.relationships' />}
            controls={
                <Button
                    disabled={!isGrantAccessed(user, grants.CRM_INTERACTIONS_LIST, accesses.ROWO)}
                    onClick={() => history.push(book.addInteraction)}
                    type='primary'
                >
                    <FormattedMessage id='interactions.add_interaction' />
                </Button>
            }
            title={<FormattedMessage id='navigation.interactions' />}
        >
            <div>
                <div className={Styles.searchEmployeeTitle}>
                    <FormattedMessage id='search' />
                </div>
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <Input.Search
                        allowClear
                        onChange={event => {
                            setQuery(event.target.value);
                        }}
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'interactions.search_by'
                        })}
                        style={{ marginBottom: 8, width: isMobile ? '95%' : '25%' }}
                        value={query}
                    />

                    <Button
                        disabled={!isGrantAccessed(user, grants.CRM_INTERACTIONS_LIST, accesses.ROWO)}
                        icon={<UndoOutlined />}
                        onClick={resetFilters}
                    />
                    <Tooltip title={<FormattedMessage id='employee-page.statistics' />}>
                        <Button
                            disabled={!isGrantAccessed(user, grants.CRM_INTERACTIONS_LIST, accesses.ROWO)}
                            icon={<ContainerOutlined />}
                            onClick={() => setOpenDrawer(true)}
                        />
                    </Tooltip>
                </div>
            </div>
            <InteractionsTable
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                query={query}
                reset={reset}
                responsibleFilter={user.id}
                shouldReset={shouldReset}
            />

            {/* <AddInteractionModal closeModal={closeInteractionModal} open={addInteractionVisible} /> */}
            {/* <AddInteractionRowModal closeModal={closeInteractionRowModal} open={addInteractionRowVisible} /> */}
        </Layout>
    );
});

export default injectIntl(InteractionsPage);
