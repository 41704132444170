import { Select, Spin } from 'antd';
import { debounce, get } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

export function DebounceSelect({
    fetchOptions,
    debounceTimeout = 500,
    initialState,
    servicesStyle = {},
    type,
    employeesOptions,
    suppliersOptions,
    crews,
    ...props
}) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState(get(initialState, 'options', []));
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = value => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then(newOptions => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    const additionalOptions = type === 'EMPLOYEE' ? employeesOptions : suppliersOptions;

    useEffect(() => {
        setOptions(get(initialState, 'options') || additionalOptions);
    }, [additionalOptions, initialState, props.value]);

    useEffect(() => {
        if (type === 'EMPLOYEE') {
            setOptions(get(initialState, 'options') || employeesOptions);
        }
    }, [type, initialState, props.value, employeesOptions]);

    useEffect(() => {
        if (type === 'SUPPLIER') {
            setOptions(get(initialState, 'options') || suppliersOptions);
        }
    }, [suppliersOptions, type, initialState, props.value]);

    useEffect(() => {
        if (type === 'CREW') {
            setOptions(get(initialState, 'options') || crews);
        }
    }, [type, initialState, props.value, crews]);

    return (
        <Select
            filterOption={false}
            labelInValue
            notFoundContent={fetching ? <Spin size='small' /> : null}
            onSearch={debounceFetcher}
            style={{ maxWidth: 350, ...servicesStyle }}
            {...props}
            options={options}
        />
    );
}
