export const SD = {
    doc: {
        id: {
            ua: 'id ідентифікатор',
            en: 'id identifier',
            cz: 'id identifikátor',
            bg: 'id идентификатор'
        },
        type: {
            ua: 'тип',
            en: 'type',
            cz: 'typ',
            bg: 'тип'
        },
        status: {
            ua: 'статус',
            en: 'status',
            cz: 'stav',
            bg: 'статус'
        },
        supplierDocNumber: {
            ua: 'номер документа постачальника',
            en: 'supplier document number',
            cz: 'číslo dodacího dokumentu',
            bg: 'номер на документ на доставчика'
        },
        createdDatetime: {
            ua: 'дата створення',
            en: 'creation date',
            cz: 'datum vytvoření',
            bg: 'дата на създаване'
        },
        doneDatetime: {
            ua: 'дата завершення',
            en: 'completion date',
            cz: 'datum dokončení',
            bg: 'дата на приключване'
        },
        recordDatetime: {
            ua: 'дата запису',
            en: 'record date',
            cz: 'datum záznamu',
            bg: 'дата на запис'
        },
        paidDatetime: {
            ua: 'дата оплати',
            en: 'payment date',
            cz: 'datum platby',
            bg: 'дата на плащане'
        },
        businessSupplierId: {
            ua: 'id id ідентифікатор постачальника',
            en: 'id supplier identifier',
            cz: 'id identifikátor dodavatele',
            bg: 'id постачальника'
        },
        sum: {
            ua: 'сума',
            en: 'amount',
            cz: 'částka',
            bg: 'сума'
        },
        businessId: {
            ua: 'id ідентифікатор бізнесу',
            en: 'id business identifier',
            cz: 'id identifikátor podnikání',
            bg: 'id бізнесу'
        },
        managerId: {
            ua: 'id ідентифікатор менеджера',
            en: 'id manager identifier',
            cz: 'id identifikátor manažera',
            bg: 'id менеджера'
        },
        warehouseId: {
            ua: 'id ідентифікатор складу',
            en: 'id warehouse identifier',
            cz: 'id identifikátor skladu',
            bg: 'id складу'
        },
        documentType: {
            ua: 'тип документа',
            en: 'document type',
            cz: 'typ dokumentu',
            bg: 'тип на документа'
        },
        counterpartBusinessSupplierId: {
            ua: 'id ідентифікатор контрагента постачальника',
            en: 'id supplier counterpart identifier',
            cz: 'id identifikátor kontrahenta dodavatele',
            bg: 'id идентификатор на контрагента доставчик'
        },
        counterpartClientId: {
            ua: 'id ідентифікатор контрагента клієнта',
            en: 'id client counterpart identifier',
            cz: 'id identifikátor kontrahenta klienta',
            bg: 'id идентификатор на контрагента на клиента'
        },
        counterpartWarehouseId: {
            ua: 'id ідентифікатор складу контрагента',
            en: 'id counterpart warehouse identifier',
            cz: 'id identifikátor skladu kontrahenta',
            bg: 'id складу контрагента'
        },
        counterpartEmployeeId: {
            ua: 'id ідентифікатор співробітника контрагента',
            en: 'id counterpart employee identifier',
            cz: 'id identifikátor zaměstnance kontrahenta',
            bg: 'id співробітника контрагента'
        },
        operationCode: {
            ua: 'код операції',
            en: 'operation code',
            cz: 'kód operace',
            bg: 'код на операция'
        },
        context: {
            ua: 'контекст',
            en: 'context',
            cz: 'kontext',
            bg: 'контекст'
        },
        payUntilDatetime: {
            ua: 'дата оплати до',
            en: 'payment due date',
            cz: 'datum platby do',
            bg: 'дата на плащане до'
        },
        sellingSum: {
            ua: 'сума продажу',
            en: 'selling amount',
            cz: 'částka prodeje',
            bg: 'сума на продажба'
        },
        orderId: {
            ua: 'id ідентифікатор замовлення',
            en: 'id order identifier',
            cz: 'id identifikátor objednávky',
            bg: 'id замовлення'
        },
        externalId: {
            ua: 'зовнішній id ідентифікатор',
            en: 'external id identifier',
            cz: 'externí id identifikátor',
            bg: 'външен идентификатор'
        },
        transactionId: {
            ua: 'id ідентифікатор транзакції',
            en: 'id transaction identifier',
            cz: 'id identifikátor transakce',
            bg: 'id е идентификаторът на транзакцията'
        },
        comment: {
            ua: 'коментар',
            en: 'comment',
            cz: 'komentář',
            bg: 'коментар'
        },
        businessSupplierRequisiteId: {
            ua: 'id ідентифікатор реквізитів бізнес-постачальника',
            en: 'id business supplier requisites identifier',
            cz: 'id identifikátor náležitostí podnikatelského dodavatele',
            bg: 'id идентификатор на детайлите на бизнес доставчика'
        },
        clientRequisiteId: {
            ua: 'id ідентифікатор реквізитів клієнта',
            en: 'id client requisites identifier',
            cz: 'id identifikátor náležitostí klienta',
            bg: 'id идентификатор на детайлите на клиента'
        },
        businessRequisiteId: {
            ua: 'id ідентифікатор реквізитів бізнесу',
            en: 'id business requisites identifier',
            cz: 'id identifikátor náležitostí podnikání',
            bg: 'id идентификатор на бизнес подробности'
        },
        externalApiOrderStatus: {
            ua: 'статус замовлення зовнішнього API',
            en: 'external API order status',
            cz: 'stav objednávky externího API',
            bg: 'статус на поръчка от външния API'
        },
        externalApiDocId: {
            ua: 'id ідентифікатор документа зовнішнього API',
            en: 'external API document identifier',
            cz: 'id identifikátor dokumentu externího API',
            bg: 'id ИД на външния API документ'
        },
        externalApiExpenseDocId: {
            ua: 'id ідентифікатор витратного документа зовнішнього завантаженого через API',
            en: 'external API expense document identifier',
            cz: 'id identifikátor dokumentu výdajů externího nahraného přes API',
            bg: 'id идентификатор на разходния документ на външния, зареден през API'
        },
        counterpartBusinessSupplierName: {
            ua: 'назва контрагента постачальника',
            en: 'supplier counterpart name',
            cz: 'název kontrahenta dodavatele',
            bg: 'име на контрагент на доставчика'
        },
        counterpartClientName: {
            ua: 'назва контрагента клієнта',
            en: 'client counterpart name',
            cz: 'název kontrahenta klienta',
            bg: 'име на контрагент на клиента'
        },
        counterpartClientPhones: {
            ua: 'телефони контрагента клієнта',
            en: 'client counterpart phones',
            cz: 'telefony kontrahenta klienta',
            bg: 'телефони на контрагент на клиента'
        },
        counterpartWarehouseName: {
            ua: 'назва складу контрагента',
            en: 'counterpart warehouse name',
            cz: 'název skladu kontrahenta',
            bg: 'име на склад на контрагента'
        },
        counterpartEmployeeName: {
            ua: "ім'я співробітника контрагента",
            en: 'counterpart employee name',
            cz: 'jméno zaměstnance kontrahenta',
            bg: 'име на служител на контрагента'
        },
        counterpartEmployeePhone: {
            ua: 'телефон співробітника контрагента',
            en: 'counterpart employee phone',
            cz: 'telefon zaměstnance kontrahenta',
            bg: 'телефон на служител на контрагента'
        },
        documentNumber: {
            ua: 'номер документа',
            en: 'document number',
            cz: 'číslo dokumentu',
            bg: 'номер на документа'
        },
        remainSum: {
            ua: 'залишкова сума',
            en: 'remaining amount',
            cz: 'zbývající částka',
            bg: 'оставаща сума'
        },
        sumTax: {
            ua: 'сума податку ПДВ',
            en: 'VAT tax amount',
            cz: 'částka daně DPH',
            bg: 'сумата на ДДС'
        },
        showTax: {
            ua: 'показати податок ПДВ',
            en: 'show VAT tax',
            cz: 'ukázat daň DPH',
            bg: 'показване на ДДС'
        },
        sellingSumTax: {
            ua: 'сума податку ПДВ на продаж',
            en: 'selling VAT tax amount',
            cz: 'částka daně DPH při prodeji',
            bg: 'сумата на ДДС на продажба'
        },
        wrhAttribute: {
            ua: 'атрибут складу',
            en: 'warehouse attribute',
            cz: 'atribut skladu',
            bg: 'атрибут на склада'
        },
        counterpartWhsAttribute: {
            ua: 'атрибут складу контрагента',
            en: 'counterpart warehouse attribute',
            cz: 'atribut skladu kontrahenta',
            bg: 'атрибут на склада на контрагента'
        },
        incomeOnlyWithCell: {
            ua: 'дохід лише з коміркою',
            en: 'income only with cell',
            cz: 'příjem pouze s buňkou',
            bg: 'доход само с клетка'
        },
        ordNum: {
            ua: 'номер замовлення',
            en: 'order number',
            cz: 'číslo objednávky',
            bg: 'номер на поръчка'
        },
        businessSupplier: {
            ua: 'бізнес-постачальник',
            en: 'business supplier',
            cz: 'podnikatelský dodavatel',
            bg: 'бизнес доставчик'
        },
        manager: {
            id: {
                ua: 'id',
                en: 'id',
                cz: 'id',
                bg: 'ид'
            },
            avatar: {
                ua: 'аватар',
                en: 'avatar',
                cz: 'avatar',
                bg: 'аватар'
            },
            businessId: {
                ua: 'id бізнесу',
                en: 'business id',
                cz: 'id podnikání',
                bg: 'ид на бизнеса'
            },
            businessesAccess: {
                ua: 'доступ до бізнесів',
                en: 'business access',
                cz: 'přístup k podnikání',
                bg: 'достъп до бизнеса'
            },
            email: {
                ua: 'електронна пошта',
                en: 'email',
                cz: 'e-mail',
                bg: 'електронна поща'
            },
            enabled: {
                ua: 'активний',
                en: 'active',
                cz: 'aktivní',
                bg: 'активен'
            },
            isAdmin: {
                ua: 'є адміністратором',
                en: 'is admin',
                cz: 'je administrátorem',
                bg: 'е администратор'
            },
            language: {
                ua: 'мова',
                en: 'language',
                cz: 'jazyk',
                bg: 'език'
            },
            name: {
                ua: "ім'я",
                en: 'name',
                cz: 'jménem',
                bg: 'име'
            },
            phone: {
                ua: 'телефон',
                en: 'phone',
                cz: 'telefon',
                bg: 'телефон'
            },
            partnerId: {
                ua: 'id партнера',
                en: 'partner id',
                cz: 'id partnera',
                bg: 'ид на партньора'
            },
            roleId: {
                ua: 'id ролі',
                en: 'role id',
                cz: 'id role',
                bg: 'ид на ролята'
            },
            surname: {
                ua: 'прізвище',
                en: 'surname',
                cz: 'příjmení',
                bg: 'фамилия'
            },
            employeeId: {
                ua: 'id працівника',
                en: 'employee id',
                cz: 'id zaměstnance',
                bg: 'ид на работника'
            },
            isMechanic: {
                ua: 'є механіком',
                en: 'is mechanic',
                cz: 'je mechanikem',
                bg: 'е механик'
            },
            isCashier: {
                ua: 'є касиром',
                en: 'is cashier',
                cz: 'je pokladníkem',
                bg: 'е касиер'
            },
            taxService: {
                ua: 'податкова служба',
                en: 'tax service',
                cz: 'daňový úřad',
                bg: 'данъчна служба'
            },
            phoneCode: {
                code: {
                    ua: 'код',
                    en: 'code',
                    cz: 'kód',
                    bg: 'код'
                },
                country: {
                    ua: 'країна',
                    en: 'country',
                    cz: 'země',
                    bg: 'страна'
                }
            },
            medocUrl: {
                ua: 'medoc Url - посилання',
                en: 'medoc Url - link',
                cz: 'medoc Url - odkaz',
                bg: 'medoc Url - връзка'
            },
            medocEdrpou: {
                ua: 'medoc ЕДРПОУ',
                en: 'medoc EDRPOU',
                cz: 'medoc EDRPOU',
                bg: 'medoc ЕДРПОУ'
            },
            medocLogin: {
                ua: 'medoc Логін',
                en: 'medoc login',
                cz: 'medoc Přihlašovací',
                bg: 'medoc Логин'
            },
            medocDept: {
                ua: 'medoc Номер філії',
                en: 'medoc branch number',
                cz: 'medoc Číslo pobočky',
                bg: 'medoc Номер на клон'
            }
        },
        docProducts: {
            $id: {
                ua: 'ід',
                en: 'id',
                cz: 'id',
                bg: 'ид'
            },
            $orderId: {
                ua: 'ід замовлення',
                en: 'order id',
                cz: 'id objednávky',
                bg: 'ид на поръчка'
            },
            $orderNum: {
                ua: 'номер замовлення',
                en: 'order number',
                cz: 'číslo objednávky',
                bg: 'номер на поръчка'
            },
            $quantity: {
                ua: 'кількість',
                en: 'quantity',
                cz: 'množství',
                bg: 'количество'
            },
            $purchasePrice: {
                ua: 'ціна покупки',
                en: 'purchase price',
                cz: 'cena nákupu',
                bg: 'цена на покупка'
            },
            $purchaseSum: {
                ua: 'сума покупки',
                en: 'purchase amount',
                cz: 'částka nákupu',
                bg: 'сума на покупка'
            },
            $sellingPrice: {
                ua: 'ціна продажу',
                en: 'selling price',
                cz: 'cena prodeje',
                bg: 'цена на продажба'
            },
            $sellingSum: {
                ua: 'сума продажу',
                en: 'selling amount',
                cz: 'částka prodeje',
                bg: 'сума на продажба'
            },
            $expenseFromId: {
                ua: 'ід витрат',
                en: 'expense from id',
                cz: 'id výdajů',
                bg: 'ид на разходите'
            },
            $productId: {
                ua: 'ід продукту',
                en: 'product id',
                cz: 'id produktu',
                bg: 'ид на продукта'
            },
            $docId: {
                ua: 'ід документа',
                en: 'document id',
                cz: 'id dokumentu',
                bg: 'ид на документа'
            },
            $measureUnit: {
                ua: 'одиниця виміру',
                en: 'unit of measure',
                cz: 'jednotka měření',
                bg: 'мерна единица'
            },
            $returnQuantity: {
                ua: 'кількість повернення',
                en: 'return quantity',
                cz: 'množství vrácení',
                bg: 'количество на връщане'
            },
            $stockPrice: {
                ua: 'ціна на складі',
                en: 'stock price',
                cz: 'cena na skladě',
                bg: 'цена на склад'
            },
            $calculatedFrom: {
                ua: 'розраховано з',
                en: 'calculated from',
                cz: 'vypočítáno z',
                bg: 'изчислено от'
            },
            $ordersAppurtenancies: {
                ua: 'додатки до замовлень',
                en: 'attachments to orders',
                cz: 'přílohy k objednávkám',
                bg: 'добавки към поръчките'
            },
            $supplierPartNumber: {
                ua: 'код запчастини постачальника',
                en: 'supplier part number',
                cz: 'kód náhradního dílu dodavatele',
                bg: 'код на резервната част на доставчика'
            },
            $addToAddress: {
                ua: 'адреса для додавання',
                en: 'address to add',
                cz: 'adresa pro přidání',
                bg: 'адрес за добавяне'
            },
            $getFromAddress: {
                ua: 'адреса для отримання',
                en: 'address to receive',
                cz: 'adresa pro přijetí',
                bg: 'адрес за получаване'
            },
            $order: {
                ua: 'замовлення',
                en: 'order',
                cz: 'objednávka',
                bg: 'поръчка'
            },
            $uktz: {
                ua: 'Код УКТЗЕД',
                en: 'UCC code',
                cz: 'Kód UKTZED',
                bg: 'Код на УКТЗЕД'
            },
            $comment: {
                ua: 'коментар',
                en: 'comment',
                cz: 'komentář',
                bg: 'коментар'
            },
            $docProductUnitId: {
                ua: 'id одиниці продукту документа',
                en: 'id document product unit',
                cz: 'id identifikátor jednotky produktu dokumentu',
                bg: 'ид на продуктова единица на документа'
            },
            product: {
                $id: {
                    ua: 'id',
                    en: 'id',
                    cz: 'id',
                    bg: 'ид'
                },
                $groupId: {
                    ua: 'Id Групи товару',
                    en: 'Product group id',
                    cz: 'Id Skupiny zboží',
                    bg: 'ид на групата на продукта'
                },
                $name: {
                    ua: 'Назва',
                    en: 'Name',
                    cz: 'Název',
                    bg: 'Име'
                },
                $code: {
                    ua: 'код з/ч',
                    en: 'part code',
                    cz: 'kód z/č',
                    bg: 'код на резервната част'
                },
                $brandId: {
                    ua: 'Id бренда',
                    en: 'Brand id',
                    cz: 'Id značky',
                    bg: ' на марката'
                },
                $brandName: {
                    ua: 'Назва бренда',
                    en: 'Brand name',
                    cz: 'Název značky',
                    bg: 'Име на марката'
                },
                unit: {
                    en: {
                        ua: 'en',
                        en: 'en',
                        cz: 'en',
                        bg: 'en'
                    },
                    ua: {
                        ua: 'ua',
                        en: 'ua',
                        cz: 'ua',
                        bg: 'ua'
                    }
                },
                $measureUnit: {
                    ua: 'одиниця виміру',
                    en: 'unit of measure',
                    cz: 'jednotka měření',
                    bg: 'мерна единица'
                },
                $tradeCode: {
                    ua: 'Код УКТЗЕД',
                    en: 'UKTZED code',
                    cz: 'Kód UKTZED',
                    bg: 'Код на УКТЗЕД'
                },
                $certificate: {
                    ua: 'сертифікат',
                    en: 'certificate',
                    cz: 'certifikát',
                    bg: 'сертификат'
                },
                $businessId: {
                    ua: 'id бізнесу',
                    en: 'business id',
                    cz: 'id podnikání',
                    bg: 'ид на бизнеса'
                },
                $defaultBusinessSupplierId: {
                    ua: 'id постачальника за замовчуванням',
                    en: 'default supplier id',
                    cz: 'id dodavatele jako výchozí',
                    bg: 'ид на доставчика по подразбиране'
                },
                $saveOnStock: {
                    ua: 'зберігати на складі',
                    en: 'save in stock',
                    cz: 'uchovávat na skladě',
                    bg: 'съхраняване на склад'
                },
                $min: {
                    ua: 'мінімум',
                    en: 'minimum',
                    cz: 'minimum',
                    bg: 'минимум'
                },
                $max: {
                    ua: 'максимум',
                    en: 'maximum',
                    cz: 'maximum',
                    bg: 'максимум'
                },
                $defaultWarehouseId: {
                    ua: 'id складу за замовчуванням',
                    en: 'default warehouse id',
                    cz: 'id skladu jako výchozí',
                    bg: 'ид на склада по подразбиране'
                },
                $multiplicity: {
                    ua: 'множинність',
                    en: 'multiplicity',
                    cz: 'množství',
                    bg: 'множество'
                },
                $externalId: {
                    ua: 'зовнішній id',
                    en: 'external id',
                    cz: 'externí id',
                    bg: 'външен ид'
                },
                $height: {
                    ua: 'висота',
                    en: 'height',
                    cz: 'výška',
                    bg: 'височина'
                },
                $width: {
                    ua: 'ширина',
                    en: 'width',
                    cz: 'šířka',
                    bg: 'ширина'
                },
                $depth: {
                    ua: 'глибина',
                    en: 'depth',
                    cz: 'hloubka',
                    bg: 'дълбочина'
                },
                $volume: {
                    ua: "об'єм",
                    en: 'volume',
                    cz: 'objem',
                    bg: 'обем'
                },
                $weight: {
                    ua: 'вага',
                    en: 'weight',
                    cz: 'váha',
                    bg: 'тегло'
                },
                $length: {
                    ua: 'довжина',
                    en: 'length',
                    cz: 'délka',
                    bg: 'дължина'
                },
                $internalDiameter: {
                    ua: 'внутрішній діаметр',
                    en: 'internal diameter',
                    cz: 'vnitřní průměr',
                    bg: 'вътрешен диаметър'
                },
                $externalDiameter: {
                    ua: 'зовнішній діаметр',
                    en: 'external diameter',
                    cz: 'vnější průměr',
                    bg: 'външен диаметър'
                },
                $colorId: {
                    ua: 'id кольору',
                    en: 'color id',
                    cz: 'id barvy',
                    bg: 'ид на цвета'
                },
                $material: {
                    ua: 'матеріал',
                    en: 'material',
                    cz: 'materiál',
                    bg: 'материал'
                },
                $comment: {
                    ua: 'коментар',
                    en: 'comment',
                    cz: 'komentář',
                    bg: 'коментар'
                },
                $condition: {
                    ua: 'стан',
                    en: 'condition',
                    cz: 'stav',
                    bg: 'състояние'
                },
                $usedInVehicles: {
                    ua: 'використовується в транспортних засобах',
                    en: 'used in vehicles',
                    cz: 'používá se v dopravních prostředcích',
                    bg: 'използва се в превозни средства'
                },
                $right: {
                    ua: 'правий',
                    en: 'right',
                    cz: 'pravý',
                    bg: 'дясно'
                },
                $left: {
                    ua: 'лівий',
                    en: 'left',
                    cz: 'levý',
                    bg: 'ляво'
                },
                $front: {
                    ua: 'передній',
                    en: 'front',
                    cz: 'přední',
                    bg: 'преден'
                },
                $rear: {
                    ua: 'задній',
                    en: 'rear',
                    cz: 'zadní',
                    bg: 'заден'
                },
                $top: {
                    ua: 'верхній',
                    en: 'top',
                    cz: 'horní',
                    bg: 'горен'
                },
                $bottom: {
                    ua: 'нижній',
                    en: 'bottom',
                    cz: 'dolní',
                    bg: 'долен'
                },
                $external: {
                    ua: 'зовнішній',
                    en: 'external',
                    cz: 'vnější',
                    bg: 'външен'
                },
                $internal: {
                    ua: 'внутрішній',
                    en: 'internal',
                    cz: 'vnitřní',
                    bg: 'вътрешен'
                },
                $central: {
                    ua: 'центральний',
                    en: 'central',
                    cz: 'centrální',
                    bg: 'централен'
                },
                $cellAddresses: {
                    ua: 'адреси комірки',
                    en: 'cell addresses',
                    cz: 'adresy buněk',
                    bg: 'адреси на клетка'
                },
                $priceGroupNumber: {
                    ua: 'номер цінової групи',
                    en: 'price group number',
                    cz: 'číslo cenové skupiny',
                    bg: 'номер на ценовата група'
                },
                $fixedSellingPrice: {
                    ua: 'фіксована ціна продажу',
                    en: 'fixed selling price',
                    cz: 'pevná cena prodeje',
                    bg: 'фиксирана цена на продажба'
                },
                $minSellingPrice: {
                    ua: 'мінімальна ціна продажу',
                    en: 'minimum selling price',
                    cz: 'minimální cena prodeje',
                    bg: 'минимална цена на продажба'
                },
                $fixedPurchasePrice: {
                    ua: 'фіксована ціна покупки',
                    en: 'fixed purchase price',
                    cz: 'pevná cena nákupu',
                    bg: 'фиксирана цена на покупка'
                },
                $getSellingPriceFrom: {
                    ua: 'отримати ціну продажу з',
                    en: 'get selling price from',
                    cz: 'získat cenu prodeje z',
                    bg: 'вземи цената на продажба от'
                },
                $getPurchasePriceFrom: {
                    ua: 'отримати ціну покупки з',
                    en: 'get purchase price from',
                    cz: 'získat cenu nákupu z',
                    bg: 'вземи цената на покупка от'
                },
                $isRound: {
                    ua: 'округлення',
                    en: 'rounding',
                    cz: 'zaokrouhlení',
                    bg: 'закръгляне'
                },
                $decimalPlaces: {
                    ua: 'десяткові знаки',
                    en: 'decimal places',
                    cz: 'desetinná místa',
                    bg: 'десетични знаци'
                },
                $productUnitId: {
                    ua: 'id одиниці продукту',
                    en: 'product unit id',
                    cz: 'id identifikátor jednotky produktu',
                    bg: 'ид на продуктова единица'
                },
                $enabled: {
                    ua: 'включено',
                    en: 'enabled',
                    cz: 'povoleno',
                    bg: 'включено'
                },
                $markupGroupId: {
                    ua: 'id групи надбавки',
                    en: 'markup group id',
                    cz: 'id skupiny přirážek',
                    bg: 'ид на групата надбавки'
                },
                $source: {
                    ua: 'джерело',
                    en: 'source',
                    cz: 'zdroj',
                    bg: 'източник'
                },
                $purchaseCurrencyId: {
                    ua: 'id валюти покупки',
                    en: 'purchase currency id',
                    cz: 'id měny nákupu',
                    bg: 'id на валутата на покупката'
                },
                $sellingCurrencyId: {
                    ua: 'id валюти продажу',
                    en: 'selling currency id',
                    cz: 'id měny prodeje',
                    bg: 'id на валута за продажба'
                },
                $purchasePriceInCurrency: {
                    ua: 'ціна покупки у валюті',
                    en: 'purchase price in currency',
                    cz: 'cena nákupu v měně',
                    bg: 'покупна цена във валута'
                },
                $sellingPriceInCurrency: {
                    ua: 'ціна продажу у валюті',
                    en: 'selling price in currency',
                    cz: 'cena prodeje v měně',
                    bg: 'цена на продажба в валута'
                },
                $storageHashes: {
                    ua: 'хеші зберігання',
                    en: 'storage hashes',
                    cz: 'úložiště hash',
                    bg: 'хешове на съхранение'
                },
                brand: {
                    $id: {
                        ua: 'id',
                        en: 'id',
                        cz: 'id',
                        bg: 'id'
                    },
                    $name: {
                        ua: 'Назва бренду',
                        en: 'Brand name',
                        cz: 'Název značky',
                        bg: 'Име на марката'
                    },
                    $supplierId: {
                        ua: 'id постачальника',
                        en: 'supplier id',
                        cz: 'id dodavatele',
                        bg: 'id постачальника'
                    }
                }
            },
            $status: {
                ua: 'статус',
                en: 'status',
                cz: 'stav',
                bg: 'статус'
            },
            $stockSum: {
                ua: 'сума на складі',
                en: 'stock amount',
                cz: 'částka na skladě',
                bg: 'Сума на склада'
            },
            $displayCode: {
                ua: 'код відображення',
                en: 'display code',
                cz: 'kód zobrazení',
                bg: 'код за показване'
            }
        },
        docServices: {
            $storeDocServiceId: {
                ua: 'id ідентифікатор послуги документа складу',
                en: 'id document storage service id',
                cz: 'id identifikátor služby dokumentu skladu',
                bg: 'id идентификатор на услуга на документа за склад'
            },
            $name: {
                ua: 'назва',
                en: 'name',
                cz: 'název',
                bg: 'име'
            },
            $analyticsId: {
                ua: 'id ідентифікатор аналітики',
                en: 'id analytics identifier',
                cz: 'id identifikátor analytiky',
                bg: 'ид идентификатор на аналитика'
            },
            $storeDocId: {
                ua: 'id ідентифікатор документа складу',
                en: 'id document storage identifier',
                cz: 'id identifikátor dokumentu skladu',
                bg: 'id идентификатор на документ за склад'
            },
            $orderId: {
                ua: 'id ідентифікатор замовлення',
                en: 'id order identifier',
                cz: 'id identifikátor objednávky',
                bg: 'ид. номер на поръчката'
            },
            $docServiceUnitId: {
                ua: 'id ідентифікатор одиниці послуги документа',
                en: 'id service document unit identifier',
                cz: 'id identifikátor jednotky služby dokumentu',
                bg: 'идентификатор на единица услуга документа'
            },
            $measureUnit: {
                ua: 'одиниця виміру',
                en: 'unit of measure',
                cz: 'jednotka měření',
                bg: 'единица за измерване'
            },
            $price: {
                ua: 'ціна',
                en: 'price',
                cz: 'cena',
                bg: 'цена'
            },
            $count: {
                ua: 'кількість',
                en: 'count',
                cz: 'množství',
                bg: 'количество'
            },
            $sum: {
                ua: 'сума',
                en: 'amount',
                cz: 'částka',
                bg: 'сума'
            },
            $analyticsName: {
                ua: 'назва аналітики',
                en: 'analytics name',
                cz: 'název analytiky',
                bg: 'име на аналитика'
            }
        },
        tax: {
            ua: 'податок ПДВ',
            en: 'VAT tax',
            cz: 'daň DPH',
            bg: 'данък ДДС'
        },
        sellingTax: {
            ua: 'податок ПДВ на продаж',
            en: 'selling VAT tax',
            cz: 'daň DPH na prodej',
            bg: 'ДДС върху продажбите'
        },
        reportTypeId: {
            ua: 'id ідентифікатор типу звіту',
            en: 'id report type identifier',
            cz: 'id identifikátor typu hlášení',
            bg: 'ид идентификатор на типа отчет'
        },
        reportName: {
            ua: 'назва звіту',
            en: 'report name',
            cz: 'název hlášení',
            bg: 'име на отчета'
        },
        quantityDecimalPlaces: {
            ua: 'знаки після коми в кількостях',
            en: 'decimal places in quantities',
            cz: 'desetinná místa v množství',
            bg: 'знаци след запетая в количества'
        },
        priceDecimalPlaces: {
            ua: 'знаки після коми в ціні',
            en: 'decimal places in price',
            cz: 'desetinná místa v ceně',
            bg: 'знаци след запетая в цената'
        },
        sumDecimalPlaces: {
            ua: 'знаки після коми в сумі',
            en: 'decimal places in sum',
            cz: 'desetinná místa v částce',
            bg: 'знаци след запетая в сумата'
        },
        store: {
            ua: 'Зберегти',
            en: 'Save',
            cz: 'Uložit',
            bg: 'Запази'
        },
        restore: {
            ua: 'Повернути',
            en: 'Return',
            cz: 'Vrátit',
            bg: 'Върни'
        }
    },
    business: {
        name: {
            ua: 'Назва',
            en: 'Name',
            cz: 'Název',
            bg: 'Име'
        },
        address: {
            ua: 'Адреса',
            en: 'Address',
            cz: 'Adresa',
            bg: 'Адреса'
        },
        phones: {
            ua: 'Номер телефону',
            en: 'Phone number',
            cz: 'Telefonní číslo',
            bg: 'Телефонен номер'
        },
        logo: {
            original: {
                path: {
                    ua: 'посилання',
                    en: 'link',
                    cz: 'odkaz',
                    bg: 'връзка'
                },
                width: {
                    ua: 'ширина',
                    en: 'width',
                    cz: 'šířka',
                    bg: 'ширина'
                },
                height: {
                    ua: 'висота',
                    en: 'height',
                    cz: 'výška',
                    bg: 'висота'
                }
            }
        },
        primaryLogoUrl: {
            ua: 'URL посилання основного логотипу',
            en: 'URL link of primary logo',
            cz: 'URL odkaz na hlavní logo',
            bg: 'URL връзка за основен логотип'
        },
        secondaryLogoUrl: {
            ua: 'URL посилання додаткового логотипу',
            en: 'URL link of secondary logo',
            cz: 'URL odkaz na vedlejší logo',
            bg: 'URL връзка за допълнителен логотип'
        },
        domain: {
            ua: 'домен',
            en: 'domain',
            cz: 'doména',
            bg: 'домен'
        },
        normHourPrice: {
            ua: 'ціна нормо-години',
            en: 'normal hour price',
            cz: 'cena normo-hodiny',
            bg: 'цена за нормо-час'
        },
        phoneCode: {
            $code: {
                ua: 'код',
                en: 'code',
                cz: 'kód',
                bg: 'код'
            },
            $number: {
                ua: 'номер',
                en: 'number',
                cz: 'číslo',
                bg: 'номер'
            },
            $country: {
                ua: 'країна',
                en: 'country',
                cz: 'země',
                bg: 'Държава'
            }
        },
        packageStatus: {
            ua: 'статус пакету',
            en: 'package status',
            cz: 'stav balíčku',
            bg: 'статус на пакета'
        },
        base64PrimaryLogo: {
            ua: 'base64 основного логотипу',
            en: 'base64 primary logo',
            cz: 'base64 hlavního loga',
            bg: 'base64 на основното лого'
        }
    },
    businessRequisite: {
        id: {
            ua: 'id',
            en: 'id',
            cz: 'id',
            bg: 'id'
        },
        enabled: {
            ua: 'активний',
            en: 'active',
            cz: 'aktivní',
            bg: 'активен'
        },
        name: {
            ua: 'Назва',
            en: 'Name',
            cz: 'Název',
            bg: 'Име'
        },
        address: {
            ua: 'Адреса',
            en: 'Address',
            cz: 'Adresa',
            bg: 'Адреса'
        },
        bank: {
            ua: 'Банк',
            en: 'Bank',
            cz: 'Banka',
            bg: 'Банка'
        },
        ifi: {
            ua: 'Код банку',
            en: 'Bank code',
            cz: 'Kód banky',
            bg: 'Код на банката'
        },
        ca: {
            ua: 'рахунок IBAN',
            en: 'IBAN account',
            cz: 'IBAN účet',
            bg: 'IBAN сметка'
        },
        itn: {
            ua: 'Код ЄДРПОУ',
            en: 'EDRPOU code',
            cz: 'IČO',
            bg: 'Код ЄДРПОУ'
        },
        formType: {
            ua: 'Тип форми',
            en: 'Form type',
            cz: 'Typ formuláře',
            bg: 'Тип форми'
        },
        formName: {
            ua: 'Назва форми',
            en: 'Form name',
            cz: 'Název formuláře',
            bg: 'Име на формата'
        },
        isTaxPayer: {
            ua: 'Є платником податків',
            en: 'Is taxpayer',
            cz: 'Je plátcem daní',
            bg: 'Е платец на данъци'
        },
        calculationMethod: {
            ua: 'Метод розрахунку',
            en: 'Calculation method',
            cz: 'Metoda výpočtu',
            bg: 'Метод на разпределение'
        },
        taxRate: {
            ua: 'Податкова ставка',
            en: 'Tax rate',
            cz: 'Daňová sazba',
            bg: 'Данъчна ставка'
        },
        ipn: {
            ua: 'ІПН',
            en: 'IPN',
            cz: 'DPH',
            bg: 'ЕГН'
        },
        certificateNumber: {
            ua: 'Номер сертифікату',
            en: 'Certificate number',
            cz: 'Číslo certifikátu',
            bg: 'Номер на сертификат'
        },
        contractNumber: {
            ua: 'Номер контракту',
            en: 'Contract number',
            cz: 'Číslo smlouvy',
            bg: 'Номер на договора'
        },
        director: {
            ua: 'директор',
            en: 'director',
            cz: 'ředitel',
            bg: 'директор'
        },
        used: {
            ua: 'використовується',
            en: 'used',
            cz: 'používá se',
            bg: 'използва се'
        },
        disabled: {
            ua: 'відключений',
            en: 'disabled',
            cz: 'deaktivováno',
            bg: 'деактивиран'
        }
    },
    clientRequisite: {
        id: {
            ua: 'id',
            en: 'id',
            cz: 'id',
            bg: 'идентификационен номер'
        },
        enabled: {
            ua: 'активний',
            en: 'active',
            cz: 'aktivní',
            bg: 'активен'
        },
        name: {
            ua: 'назва',
            en: 'name',
            cz: 'název',
            bg: 'име'
        },
        address: {
            ua: 'адреса',
            en: 'address',
            cz: 'adresa',
            bg: 'адрес'
        },
        bank: {
            ua: 'Банк',
            en: 'Bank',
            cz: 'Banka',
            bg: 'Банка'
        },
        ifi: {
            ua: 'Код банку',
            en: 'Bank code',
            cz: 'Kód banky',
            bg: 'Код на банката'
        },
        ca: {
            ua: 'рахунок IBAN',
            en: 'IBAN account',
            cz: 'IBAN účet',
            bg: 'IBAN сметка'
        },
        itn: {
            ua: 'Код ЄДРПОУ',
            en: 'EDRPOU code',
            cz: 'IČO',
            bg: 'Код на ЕДРПОУ'
        },
        clientId: {
            ua: 'ідентифікатор клієнта',
            en: 'client identifier',
            cz: 'identifikátor klienta',
            bg: 'идентификатор на клиента'
        },
        formType: {
            ua: 'тип форми',
            en: 'form type',
            cz: 'typ formuláře',
            bg: 'тип на формата'
        },
        formName: {
            ua: 'назва форми',
            en: 'form name',
            cz: 'název formuláře',
            bg: 'име на формата'
        },
        isTaxPayer: {
            ua: 'є платником податків',
            en: 'is taxpayer',
            cz: 'je plátcem daní',
            bg: 'е платец на данъци'
        },
        calculationMethod: {
            ua: 'метод розрахунку',
            en: 'calculation method',
            cz: 'metoda výpočtu',
            bg: 'метод на разпределение'
        },
        taxRate: {
            ua: 'Податкова ставка',
            en: 'Tax rate',
            cz: 'Daňová sazba',
            bg: 'Данъчна ставка'
        },
        ipn: {
            ua: 'ІПН',
            en: 'IPN',
            cz: 'DPH',
            bg: 'ЕГН'
        },
        certificateNumber: {
            ua: 'номер сертифіката',
            en: 'certificate number',
            cz: 'číslo certifikátu',
            bg: 'номер на сертификат'
        },
        contractNumber: {
            ua: 'номер контракту',
            en: 'contract number',
            cz: 'číslo smlouvy',
            bg: 'номер на договора'
        },
        director: {
            ua: 'директор',
            en: 'director',
            cz: 'ředitel',
            bg: 'директор'
        },
        accountId: {
            ua: 'id ідентифікатор рахунку',
            en: 'id account identifier',
            cz: 'id identifikátor účtu',
            bg: 'идентификатор на сметка id'
        },
        disabled: {
            ua: 'відключений',
            en: 'disabled',
            cz: 'deaktivováno',
            bg: 'деактивиран'
        },
        contractDate: {
            ua: 'дата контракту',
            en: 'contract date',
            cz: 'datum smlouvy',
            bg: 'дата на договора'
        }
    },
    client: {
        clientId: {
            ua: 'ідентифікатор клієнта',
            en: 'client identifier',
            cz: 'identifikátor klienta',
            bg: 'идентификатор на клиента'
        },
        middleName: {
            ua: 'по батькові',
            en: 'middle name',
            cz: 'druhé jméno',
            bg: 'баща'
        },
        status: {
            ua: 'статус',
            en: 'status',
            cz: 'stav',
            bg: 'статус'
        },
        birthday: {
            ua: 'дата народження',
            en: 'date of birth',
            cz: 'datum narození',
            bg: 'датата на раждане'
        },
        sex: {
            ua: 'стать',
            en: 'gender',
            cz: 'pohlaví',
            bg: 'пол'
        },
        name: {
            ua: "ім'я",
            en: 'name',
            cz: 'jméno',
            bg: 'име'
        },
        disableSms: {
            ua: 'відключити SMS',
            en: 'disable SMS',
            cz: 'deaktivovat SMS',
            bg: 'изключи SMS'
        },
        disableAutoSms: {
            ua: 'відключити автоматичні SMS',
            en: 'disable automatic SMS',
            cz: 'deaktivovat automatické SMS',
            bg: 'изключи автоматични SMS'
        },
        surname: {
            ua: 'прізвище',
            en: 'surname',
            cz: 'příjmení',
            bg: 'фамилия'
        },
        phones: {
            ua: 'телефони',
            en: 'phones',
            cz: 'telefony',
            bg: 'телефони'
        },
        emails: {
            ua: 'електронні пошти',
            en: 'emails',
            cz: 'elektronické pošty',
            bg: 'електронни пощи'
        },
        paymentRespite: {
            ua: 'строк відстрочки платежу',
            en: 'payment respite term',
            cz: 'termín splatnosti platby',
            bg: 'срок на плащане'
        },
        sourceId: {
            ua: 'id ідентифікатор джерела',
            en: 'id source identifier',
            cz: 'id identifikátor zdroje',
            bg: 'идентификатор на източник id'
        },
        discount: {
            ua: 'знижка',
            en: 'discount',
            cz: 'sleva',
            bg: 'отстъпка'
        },
        laborsDiscount: {
            ua: 'знижка на роботи',
            en: 'labors discount',
            cz: 'sleva na práci',
            bg: 'отстъпка за работа'
        },
        detailsDiscount: {
            ua: 'знижка на деталі',
            en: 'details discount',
            cz: 'sleva na díly',
            bg: 'отстъпка за частите'
        },
        type: {
            ua: 'тип',
            en: 'type',
            cz: 'typ',
            bg: 'тип'
        },
        comment: {
            ua: 'коментар',
            en: 'comment',
            cz: 'komentář',
            bg: 'коментар'
        },
        markupGroupId: {
            ua: 'ідентифікатор групи націнки',
            en: 'markup group id',
            cz: 'identifikátor skupiny přirážky',
            bg: 'идентификатор на групата на надбавките'
        },
        phoneCode: {
            $code: {
                ua: 'код',
                en: 'code',
                cz: 'kód',
                bg: 'код'
            },
            $country: {
                ua: 'країна',
                en: 'country',
                cz: 'země',
                bg: 'държава'
            }
        },
        accountId: {
            ua: 'id ідентифікатор акаунту',
            en: 'account identifier',
            cz: 'id identifikátor účtu',
            bg: 'идентификатор на акаунта'
        },
        requisites: {
            $ca: {
                ua: 'IBAN',
                en: 'IBAN',
                cz: 'IBAN',
                bg: 'IBAN'
            },
            $id: {
                ua: 'id',
                en: 'id',
                cz: 'id',
                bg: 'идентификационен номер'
            },
            $ifi: {
                ua: 'Код Банку',
                en: 'Bank Code',
                cz: 'Kód banky',
                bg: 'Код на банката'
            },
            $ipn: {
                ua: 'ІПН',
                en: 'Tax ID',
                cz: 'DPH',
                bg: 'ЕГН'
            },
            $itn: {
                ua: 'Код ЄДРПОУ',
                en: 'EDRPOU Code',
                cz: 'IČO',
                bg: 'Код на ЕДРПОУ'
            },
            $bank: {
                ua: 'bank',
                en: 'bank',
                cz: 'banka',
                bg: 'банка'
            },
            $name: {
                ua: 'name',
                en: 'name',
                cz: 'název',
                bg: 'име'
            },
            $address: {
                ua: 'address',
                en: 'address',
                cz: 'adresa',
                bg: 'адреса'
            },
            $enabled: {
                ua: 'enabled',
                en: 'enabled',
                cz: 'povolené',
                bg: 'активиран'
            },
            $taxRate: {
                ua: 'taxRate',
                en: 'tax rate',
                cz: 'daňová sazba',
                bg: 'данъчна ставка'
            },
            $director: {
                ua: 'director',
                en: 'director',
                cz: 'ředitel',
                bg: 'директор'
            },
            $disabled: {
                ua: 'disabled',
                en: 'disabled',
                cz: 'deaktivováno',
                bg: 'деактивиран'
            },
            $formName: {
                ua: 'formName',
                en: 'form name',
                cz: 'název formuláře',
                bg: 'име на формата'
            },
            $formType: {
                ua: 'formType',
                en: 'form type',
                cz: 'typ formuláře',
                bg: 'тип на формата'
            },
            $accountId: {
                ua: 'accountId',
                en: 'account ID',
                cz: 'id identifikátor účtu',
                bg: 'идентификатор на акаунта'
            },
            $isTaxPayer: {
                ua: 'isTaxPayer',
                en: 'is taxpayer',
                cz: 'je plátcem daní',
                bg: 'е платец на изчисления'
            },
            $accountName: {
                ua: 'accountName',
                en: 'account name',
                cz: 'název účtu',
                bg: 'име на акаунта'
            },
            $contractDate: {
                ua: 'contractDate',
                en: 'contract date',
                cz: 'datum smlouvy',
                bg: 'дата на договора'
            },
            $contractNumber: {
                ua: 'contractNumber',
                en: 'contract number',
                cz: 'číslo smlouvy',
                bg: 'номер на договора'
            },
            $calculationMethod: {
                ua: 'calculationMethod',
                en: 'calculation method',
                cz: 'metoda výpočtu',
                bg: 'метод на разпределение'
            },
            $certificateNumber: {
                ua: 'certificateNumber',
                en: 'certificate number',
                cz: 'číslo certifikátu',
                bg: 'номер на сертификат'
            }
        },
        disabled: {
            ua: 'неактивний',
            en: 'inactive',
            cz: 'neaktivní',
            bg: 'неактивен'
        },
        source: {
            ua: 'джерело',
            en: 'source',
            cz: 'zdroj',
            bg: 'източник'
        },
        accountName: {
            ua: "ім'я рахунку",
            en: 'account name',
            cz: 'název účtu',
            bg: 'име на акаунта'
        },
        notifications: {
            ua: 'сповіщення',
            en: 'notifications',
            cz: 'oznámení',
            bg: 'известия'
        },
        reviews: {
            ua: 'відгуки',
            en: 'reviews',
            cz: 'recenze',
            bg: 'отзиви'
        }
    },
    clientVehicle: {
        enabled: {
            ua: 'активний',
            en: 'active',
            cz: 'aktivní',
            bg: 'активен'
        },
        id: {
            ua: 'id',
            en: 'id',
            cz: 'id',
            bg: 'идентификационен номер'
        },
        vin: {
            ua: 'VIN',
            en: 'VIN',
            cz: 'VIN',
            bg: 'VIN'
        },
        number: {
            ua: 'номер',
            en: 'number',
            cz: 'číslo',
            bg: 'номер'
        },
        registrationName: {
            ua: 'назва реєстрації',
            en: 'registration name',
            cz: 'název registrace',
            bg: 'име на регистрация'
        },
        vehicleMakeName: {
            ua: 'назва марки транспортного засобу',
            en: 'vehicle make name',
            cz: 'název značky vozidla',
            bg: 'име на марката на превозното средство'
        },
        vehicleModelName: {
            ua: 'назва моделі транспортного засобу',
            en: 'vehicle model name',
            cz: 'název modelu vozidla',
            bg: 'име на модела на превозното средство'
        },
        vehicleModificationName: {
            ua: 'назва модифікації транспортного засобу',
            en: 'vehicle modification name',
            cz: 'název varianty vozidla',
            bg: 'име на модификацията на превозното средство'
        },
        clientVehicleYear: {
            ua: 'рік випуску транспортного засобу',
            en: 'vehicle year of manufacture',
            cz: 'rok vyrábění vozidla',
            bg: 'година на производство на превозното средство'
        },
        vehiclegroupid: {
            ua: 'id групи транспортних засобів',
            en: 'vehicle group id',
            cz: 'id skupiny vozidel',
            bg: 'идентификатор на групата на превозни средства'
        },
        clientId: {
            ua: 'id клієнта',
            en: 'client id',
            cz: 'id klienta',
            bg: 'идентификатор на клиента'
        },
        vehicleMakeId: {
            ua: 'id марки транспортного засобу',
            en: 'vehicle make id',
            cz: 'id značky vozidla',
            bg: 'идентификатор на марката на превозното средство'
        }
    },
    order: {
        detailsDiscount: {
            ua: 'знижка на деталі',
            en: 'parts discount',
            cz: 'sleva na díly',
            bg: 'отстъпка за части'
        },
        beginDatetime: {
            ua: 'початок дати та часу',
            en: 'start date and time',
            cz: 'počátek data a času',
            bg: 'начало на дата и час'
        },
        businessComment: {
            ua: 'коментар до бізнесу',
            en: 'business comment',
            cz: 'komentář k podnikání',
            bg: 'коментар по бизнес'
        },
        clientEmail: {
            ua: 'електронна пошта клієнта',
            en: 'client email',
            cz: 'elektronická pošta klienta',
            bg: 'електронна поща на клиента'
        },
        clientPhone: {
            ua: 'телефон клієнта',
            en: 'client phone',
            cz: 'telefon klienta',
            bg: 'телефон на клиента'
        },
        clientId: {
            ua: 'id клієнта',
            en: 'client id',
            cz: 'id klienta',
            bg: 'идентификатор на клиента'
        },
        comment: {
            ua: 'коментар',
            en: 'comment',
            cz: 'komentář',
            bg: 'коментар'
        },
        courtesyCar: {
            ua: 'автомобіль на безкоштовне користування',
            en: 'courtesy car',
            cz: 'auto s bezplatným užíváním',
            bg: 'автомобил под наем'
        },
        clientRequisiteId: {
            ua: 'id реквізиту клієнта',
            en: 'client requisite id',
            cz: 'id rekvizitu klienta',
            bg: 'идентификатор на клиента'
        },
        clientVehicleId: {
            ua: 'id транспортного засобу клієнта',
            en: 'client vehicle id',
            cz: 'id vozidla klienta',
            bg: 'идентификатор на превозно средство на клиента'
        },
        clientAggregateId: {
            ua: 'id агрегату клієнта',
            en: 'client aggregate id',
            cz: 'id agregátu klienta',
            bg: 'идентификатор на агрегат на клиента'
        },
        diskMaterial: {
            ua: 'матеріал диска',
            en: 'disk material',
            cz: 'materiál disku',
            bg: 'материал на диска'
        },
        diskRadius: {
            ua: 'радіус диска',
            en: 'disk radius',
            cz: 'poloměr disku',
            bg: 'радиус на диска'
        },
        duration: {
            ua: 'тривалість',
            en: 'duration',
            cz: 'doba trvání',
            bg: 'продължителност'
        },
        employeeId: {
            ua: 'id співробітника',
            en: 'employee id',
            cz: 'id zaměstnance',
            bg: 'идентификатор на служителя'
        },
        managerId: {
            ua: 'id менеджера',
            en: 'manager id',
            cz: 'id manažera',
            bg: 'идентификатор на мениджъра'
        },
        appurtenanciesResponsibleId: {
            ua: 'id відповідального за обладнання',
            en: 'appurtenances responsible id',
            cz: 'id odpovědného za vybavení',
            bg: 'идентификатор на отговорника за оборудването'
        },
        odometerValue: {
            ua: 'показник одометра',
            en: 'odometer value',
            cz: 'hodnota odometru',
            bg: 'показател на одометъра'
        },
        paymentMethod: {
            ua: 'метод оплати',
            en: 'payment method',
            cz: 'způsob platby',
            bg: 'метод на плащане'
        },
        recommendation: {
            ua: 'рекомендація',
            en: 'recommendation',
            cz: 'doporučení',
            bg: 'препоръка'
        },
        businessRequisiteId: {
            ua: 'id реквізиту бізнесу',
            en: 'business requisite id',
            cz: 'id rekvizitu podnikání',
            bg: 'идентификатор на бизнес реквизити'
        },
        servicesDiscount: {
            ua: 'знижка на послуги',
            en: 'services discount',
            cz: 'sleva na služby',
            bg: 'отстъпка за услуги'
        },
        stationNum: {
            ua: 'номер станції',
            en: 'station number',
            cz: 'číslo stanice',
            bg: 'номер на станцията'
        },
        status: {
            ua: 'статус',
            en: 'status',
            cz: 'stav',
            bg: 'статус'
        },
        vehicleCondition: {
            ua: 'стан транспортного засобу',
            en: 'vehicle condition',
            cz: 'stav vozidla',
            bg: 'състояние на превозното средство'
        },
        id: {
            ua: 'id',
            en: 'id',
            cz: 'id',
            bg: 'идентификационен номер'
        },
        num: {
            ua: 'номер',
            en: 'number',
            cz: 'číslo',
            bg: 'номер'
        },
        datetime: {
            ua: 'дата та час',
            en: 'date and time',
            cz: 'datum a čas',
            bg: 'дата и час'
        },
        servicesSum: {
            ua: 'сума за послуги',
            en: 'services sum',
            cz: 'částka za služby',
            bg: 'сума за услуги'
        },
        detailsSum: {
            ua: 'сума за деталі',
            en: 'details sum',
            cz: 'částka za díly',
            bg: 'сума за частите'
        },
        businessId: {
            ua: 'id бізнесу',
            en: 'business id',
            cz: 'id podnikání',
            bg: 'идентификатор на бизнеса'
        },
        viewDatetime: {
            ua: 'дата та час перегляду',
            en: 'view date and time',
            cz: 'datum a čas přehledu',
            bg: 'дата и час на прегледа'
        },
        deliveryDatetime: {
            ua: 'дата та час доставки',
            en: 'delivery date and time',
            cz: 'datum a čas dodání',
            bg: 'дата и час на доставка'
        },
        successDatetime: {
            ua: 'дата та час завершення',
            en: 'success date and time',
            cz: 'datum a čas ukončení',
            bg: 'дата и час на завършване'
        },
        invoiceReportPrinted: {
            ua: 'звіт про рахунок-фактуру надруковано',
            en: 'invoice report printed',
            cz: 'zpráva o faktuře byla vytisknuta',
            bg: 'докладът за фактурата е отпечатан'
        },
        actOfAcceptanceReportPrinted: {
            ua: 'акт приймання надруковано',
            en: 'act of acceptance report printed',
            cz: 'akt přijetí byl vytisknut',
            bg: 'актът на приемането е отпечатан'
        },
        completedWorkReportPrinted: {
            ua: 'звіт про виконані роботи надруковано',
            en: 'completed work report printed',
            cz: 'report o vykonaných pracích byl vytisknut',
            bg: 'докладът за извършените работи е отпечатан'
        },
        replacementType: {
            ua: 'тип заміни',
            en: 'replacement type',
            cz: 'typ náhrady',
            bg: 'тип на замяната'
        },
        discount: {
            ua: 'знижка',
            en: 'discount',
            cz: 'slevy',
            bg: 'отстъпка'
        },
        orderBloke: {
            ua: 'блок замовлень',
            en: 'order block',
            cz: 'blok objednávek',
            bg: 'блок на поръчките'
        },
        systemComment: {
            ua: 'системний коментар',
            en: 'system comment',
            cz: 'systémový komentář',
            bg: 'системен коментар'
        },
        agreementComment: {
            ua: 'коментар до угоди',
            en: 'agreement comment',
            cz: 'komentář k dohodě',
            bg: 'коментар по споразумението'
        },
        storageHashes: {
            ua: 'хеші зберігання',
            en: 'storage hashes',
            cz: 'úložiště hash',
            bg: 'хешове на съхранение'
        },
        detailsTotalSum: {
            ua: 'загальна сума за деталі',
            en: 'details total sum',
            cz: 'celková částka za díly',
            bg: 'обща сума за части'
        },
        servicesTotalSum: {
            ua: 'загальна сума за послуги',
            en: 'services total sum',
            cz: 'celková částka za služby',
            bg: 'обща сума за услуги'
        },
        servicesSumDiscount: {
            ua: 'знижка на суму послуг',
            en: 'services sum discount',
            cz: 'sleva na částku služeb',
            bg: 'отстъпка за сумата на услугите'
        },
        detailsSumDiscount: {
            ua: 'знижка на суму деталей',
            en: 'details sum discount',
            cz: 'sleva na částku dílů',
            bg: 'отстъпка за сумата на частите'
        },
        servicesPriceSum: {
            ua: 'сума ціни послуг',
            en: 'services price sum',
            cz: 'částka cen služeb',
            bg: 'сума на цената на услугите'
        },
        appurtenanciesPriceSum: {
            ua: 'сума ціни обладнання',
            en: 'appurtenances price sum',
            cz: 'částka cen zařízení',
            bg: 'сума на цената на оборудването'
        },
        totalSum: {
            ua: 'загальна сума',
            en: 'total sum',
            cz: 'celková částka',
            bg: 'обща сума'
        },
        totalSumDiscount: {
            ua: 'знижка на загальну суму',
            en: 'total sum discount',
            cz: 'sleva na celkovou částku',
            bg: 'отстъпка за общата сума'
        },
        onlyAppurtenanciesTotalSum: {
            ua: 'загальна сума тільки за обладнання',
            en: 'only appurtenances total sum',
            cz: 'celková částka pouze za zařízení',
            bg: 'обща сума за оборудване'
        },
        onlyAppurtenanciesTotalSumDiscount: {
            ua: 'знижка на загальну суму тільки за обладнання',
            en: 'only appurtenances total sum discount',
            cz: 'sleva na celkovou částku pouze za zařízení',
            bg: 'отстъпка за общата сума за оборудване'
        },
        printNumber: {
            ua: 'номер друку',
            en: 'print number',
            cz: 'číslo tisku',
            bg: 'номер на печат'
        },
        agreementCompleted: {
            ua: 'угода виконана',
            en: 'agreement completed',
            cz: 'dohoda dokončena',
            bg: 'споразумението е завършено'
        },
        laborTimeMultiplier: {
            ua: 'множник часу на роботу',
            en: 'labor time multiplier',
            cz: 'násobek pracovní doby',
            bg: 'множител на времето за работа'
        },
        totalSumWithTax: {
            ua: 'загальна сума з податком',
            en: 'total sum with tax',
            cz: 'celková částka s daní',
            bg: 'обща сума с данък'
        },
        tax: {
            ua: 'податок',
            en: 'tax',
            cz: 'daň',
            bg: 'данък'
        },
        isTaxPayer: {
            ua: 'платник податків',
            en: 'is taxpayer',
            cz: 'daňový poplatník',
            bg: 'платец на данъци'
        },
        clientVehicleVin: {
            ua: 'VIN транспортного засобу клієнта',
            en: 'client vehicle VIN',
            cz: 'VIN vozidla zákazníka',
            bg: 'VIN на превозното средство на клиента'
        },
        bodyId: {
            ua: 'id кузова',
            en: 'body id',
            cz: 'id karoserie',
            bg: 'идентификатор на купето'
        },
        businessLocationId: {
            ua: 'id розташування бізнесу',
            en: 'business location id',
            cz: 'id umístění firmy',
            bg: 'идентификатор на местоположението на бизнеса'
        },
        businessLocationName: {
            ua: 'назва розташування бізнесу',
            en: 'business location name',
            cz: 'název umístění firmy',
            bg: 'име на местоположението на бизнеса'
        },
        remainingSum: {
            ua: 'залишкова сума',
            en: 'remaining sum',
            cz: 'zůstatková částka',
            bg: 'остатъчна сума'
        },
        orderImgId: {
            ua: 'id зображення замовлення',
            en: 'order image id',
            cz: 'id obrázku objednávky',
            bg: 'идентификатор на изображението на поръчката'
        },
        orderImgVehicleType: {
            ua: 'тип транспортного засобу за зображенням замовлення',
            en: 'vehicle type from order image',
            cz: 'typ vozidla podle obrázku objednávky',
            bg: 'тип на превозното средство от изображението на поръчката'
        },
        subStatus: {
            ua: 'допоміжний статус',
            en: 'sub status',
            cz: 'pomocný status',
            bg: 'допълнителен статус'
        },
        subStatusName: {
            ua: 'назва допоміжного статусу',
            en: 'sub status name',
            cz: 'název pomocného statusu',
            bg: 'име на допълнителния статус'
        },
        vehicleImageId: {
            ua: 'id зображення транспортного засобу',
            en: 'vehicle image id',
            cz: 'id obrázku vozidla',
            bg: 'идентификатор на изображението на превозното средство'
        },
        clientVehicleTypeId: {
            ua: 'id типу транспортного засобу клієнта',
            en: 'client vehicle type id',
            cz: 'id typu vozidla zákazníka',
            bg: 'идентификатор на типа превозно средство на клиента'
        },
        clientVehicleRadius: {
            ua: 'радіус транспортного засобу клієнта',
            en: 'client vehicle radius',
            cz: 'poloměr vozidla zákazníka',
            bg: 'радиус на превозното средство на клиента'
        },
        vehicleTypeName: {
            ua: 'назва типу транспортного засобу',
            en: 'vehicle type name',
            cz: 'název typu vozidla',
            bg: 'име на типа превозно средство'
        },
        normHourPrice: {
            ua: 'ціна за нормо-годину',
            en: 'norm hour price',
            cz: 'cena za normohodinu',
            bg: 'цена за нормо-час'
        },
        aggregateId: {
            ua: 'id агрегату',
            en: 'aggregate id',
            cz: 'id agregátu',
            bg: 'идентификатор на агрегата'
        },
        aggregateCode: {
            ua: 'код агрегату',
            en: 'aggregate code',
            cz: 'kód agregátu',
            bg: 'код на агрегата'
        },
        aggregateParentCode: {
            ua: 'код батьківського агрегату',
            en: 'parent aggregate code',
            cz: 'kód rodičovského agregátu',
            bg: 'код на родителския агрегат'
        },
        aggregateLevel: {
            ua: 'рівень агрегату',
            en: 'aggregate level',
            cz: 'úroveň agregátu',
            bg: 'ниво на агрегата'
        },
        aggregateName: {
            ua: 'назва агрегату',
            en: 'aggregate name',
            cz: 'název agregátu',
            bg: 'име на агрегата'
        },
        aggregateBrandId: {
            ua: 'id бренду агрегату',
            en: 'aggregate brand id',
            cz: 'id značky agregátu',
            bg: 'идентификатор на марката на агрегата'
        },
        aggregateBrandName: {
            ua: 'назва бренду агрегату',
            en: 'aggregate brand name',
            cz: 'název značky agregátu',
            bg: 'име на марката на агрегата'
        },
        aggregateNumber: {
            ua: 'номер агрегату',
            en: 'aggregate number',
            cz: 'číslo agregátu',
            bg: 'номер на агрегата'
        },
        aggregateCount: {
            ua: 'кількість агрегатів',
            en: 'number of aggregates',
            cz: 'počet agregátů',
            bg: 'брой агрегати'
        },
        aggregateComment: {
            ua: 'коментар до агрегату',
            en: 'aggregate comment',
            cz: 'komentář k agregátu',
            bg: 'коментар по агрегата'
        },
        aggregateVin: {
            ua: 'VIN агрегату',
            en: 'aggregate VIN',
            cz: 'VIN agregátu',
            bg: 'VIN на агрегата'
        },
        barcode: {
            ua: 'штрих-код',
            en: 'barcode',
            cz: 'čárový kód',
            bg: 'баркод'
        }
    },
    businessSupplier: {
        id: {
            ua: 'id',
            en: 'id',
            cz: 'id',
            bg: 'идентификационен номер'
        },
        name: {
            ua: 'назва',
            en: 'name',
            cz: 'název',
            bg: 'име'
        },
        businessId: {
            ua: 'id бізнесу',
            en: 'business id',
            cz: 'id firmy',
            bg: 'идентификатор на бизнеса'
        },
        contactName: {
            ua: "ім'я контакту",
            en: 'contact name',
            cz: 'jméno kontaktu',
            bg: 'име на контакт'
        },
        contactMiddlename: {
            ua: 'по-батькові контакту',
            en: 'contact middle name',
            cz: 'druhého jména kontaktu',
            bg: 'по-баща на контакта'
        },
        contactSurname: {
            ua: 'прізвище контакту',
            en: 'contact surname',
            cz: 'příjmení kontaktu',
            bg: 'фамилия на контакта'
        },
        phones: {
            ua: 'телефони',
            en: 'phones',
            cz: 'telefonní čísla',
            bg: 'телефони'
        },
        emails: {
            ua: 'електронні пошти',
            en: 'emails',
            cz: 'e-maily',
            bg: 'електронни пощи'
        },
        paymentRespite: {
            ua: 'термін оплати',
            en: 'payment term',
            cz: 'lhůta splatnosti',
            bg: 'срок на плащане'
        },
        comment: {
            ua: 'коментар',
            en: 'comment',
            cz: 'komentář',
            bg: 'коментар'
        },
        type: {
            ua: 'тип',
            en: 'type',
            cz: 'typ',
            bg: 'тип'
        },
        accountId: {
            ua: 'id рахунку',
            en: 'account id',
            cz: 'id účtu',
            bg: 'идентификатор на сметка'
        },
        incomeOrderDocsCount: {
            ua: 'кількість документів доходу',
            en: 'income order docs count',
            cz: 'počet dokumentů příjmu',
            bg: 'брой документи за приходи'
        },
        expenseOrderDocsCount: {
            ua: 'кількість документів витрат',
            en: 'expense order docs count',
            cz: 'počet dokumentů výdajů',
            bg: 'брой документи за разходи'
        },
        accountName: {
            ua: 'назва рахунку',
            en: 'account name',
            cz: 'název účtu',
            bg: 'име на сметката'
        },
        hide: {
            ua: 'сховати',
            en: 'hide',
            cz: 'skrýt',
            bg: 'скрий'
        },
        requisites: {
            $id: {
                ua: 'id',
                en: 'id',
                cz: 'id',
                bg: 'идентификационен номер'
            },
            $businessSupplierId: {
                ua: 'id постачальника бізнесу',
                en: 'business supplier id',
                cz: 'id dodavatele firmy',
                bg: 'идентификатор на доставчика на бизнеса'
            },
            $enabled: {
                ua: 'активований',
                en: 'enabled',
                cz: 'aktualizováno',
                bg: 'активиран'
            },
            $name: {
                ua: 'назва',
                en: 'name',
                cz: 'název',
                bg: 'име'
            },
            $address: {
                ua: 'адреса',
                en: 'address',
                cz: 'adresa',
                bg: 'адрес'
            },
            $bank: {
                ua: 'банк',
                en: 'bank',
                cz: 'banka',
                bg: 'банка'
            },
            $ifi: {
                ua: 'Код Банку',
                en: 'Bank Code',
                cz: 'Kód banky',
                bg: 'Код на банката'
            },
            $ca: {
                ua: 'IBAN',
                en: 'IBAN',
                cz: 'IBAN',
                bg: 'IBAN'
            },
            $itn: {
                ua: 'Код ЄДРПОУ',
                en: 'EDRPOU Code',
                cz: 'IČO',
                bg: 'Код на ЕДРПОУ'
            },
            $formType: {
                ua: 'тип форми',
                en: 'form type',
                cz: 'typ formuláře',
                bg: 'тип на формата'
            },
            $formName: {
                ua: 'назва форми',
                en: 'form name',
                cz: 'název formuláře',
                bg: 'име на формата'
            },
            $isTaxPayer: {
                ua: 'платник податків',
                en: 'taxpayer',
                cz: 'daňový poplatník',
                bg: 'платец на данъци'
            },
            $calculationMethod: {
                ua: 'метод розрахунку',
                en: 'calculation method',
                cz: 'metoda výpočtu',
                bg: 'метод на разпределение'
            },
            $taxRate: {
                ua: 'ставка податку',
                en: 'tax rate',
                cz: 'sazba daně',
                bg: 'данъчна ставка'
            },
            $ipn: {
                ua: 'ІПН',
                en: 'IPN',
                cz: 'DIČ',
                bg: 'ЕГН'
            },
            $certificateNumber: {
                ua: 'номер сертифікату',
                en: 'certificate number',
                cz: 'číslo osvědčení',
                bg: 'номер на сертификат'
            },
            $contractNumber: {
                ua: 'номер контракту',
                en: 'contract number',
                cz: 'číslo smlouvy',
                bg: 'номер на договора'
            },
            $contractDate: {
                ua: 'дата контракту',
                en: 'contract date',
                cz: 'datum smlouvy',
                bg: 'дата на договора'
            },
            $director: {
                ua: 'директор',
                en: 'director',
                cz: 'ředitel',
                bg: 'директор'
            },
            $accountID: {
                ua: 'ID рахунку',
                en: 'account ID',
                cz: 'ID účtu',
                bg: 'идентификатор на сметка'
            },
            $disabled: {
                ua: 'неактивований',
                en: 'inactive',
                cz: 'neaktivovaný',
                bg: 'неактивиран'
            },
            $accountName: {
                ua: 'назва рахунку',
                en: 'account name',
                cz: 'název účtu',
                bg: 'име на акаунта'
            }
        }
    },
    businessSupplierRequisite: {
        id: {
            ua: 'id',
            en: 'id',
            cz: 'id',
            bg: 'id'
        },
        businessSupplierId: {
            ua: 'id постачальника бізнесу',
            en: 'business supplier id',
            cz: 'id dodavatele firmy',
            bg: 'ИД на доставчика на бизнес'
        },
        enabled: {
            ua: 'активований',
            en: 'enabled',
            cz: 'aktualizováno',
            bg: 'активиран'
        },
        name: {
            ua: 'назва',
            en: 'name',
            cz: 'název',
            bg: 'име'
        },
        address: {
            ua: 'адреса',
            en: 'address',
            cz: 'adresa',
            bg: 'адрес'
        },
        bank: {
            ua: 'банк',
            en: 'bank',
            cz: 'banka',
            bg: 'банка'
        },
        ifi: {
            ua: 'Код Банку',
            en: 'Bank Code',
            cz: 'Kód banky',
            bg: 'Код на банката'
        },
        ca: {
            ua: 'IBAN',
            en: 'IBAN',
            cz: 'IBAN',
            bg: 'IBAN'
        },
        itn: {
            ua: 'Код ЄДРПОУ',
            en: 'EDRPOU Code',
            cz: 'IČO',
            bg: 'Код на ЕДРПОУ'
        },
        formType: {
            ua: 'тип форми',
            en: 'form type',
            cz: 'typ formuláře',
            bg: 'тип форма'
        },
        formName: {
            ua: 'назва форми',
            en: 'form name',
            cz: 'název formuláře',
            bg: 'име на формата'
        },
        isTaxPayer: {
            ua: 'платник податків',
            en: 'is taxpayer',
            cz: 'daňový poplatník',
            bg: 'данъкоплатец'
        },
        calculationMethod: {
            ua: 'метод розрахунку',
            en: 'calculation method',
            cz: 'metoda výpočtu',
            bg: 'метод на изчисление'
        },
        taxRate: {
            ua: 'ставка податку',
            en: 'tax rate',
            cz: 'sazba daně',
            bg: 'данъчна ставка'
        },
        ipn: {
            ua: 'ІПН',
            en: 'IPN',
            cz: 'DIČ',
            bg: 'ИД на данъка'
        },
        certificateNumber: {
            ua: 'номер сертифікату',
            en: 'certificate number',
            cz: 'číslo osvědčení',
            bg: 'номер на сертификата'
        },
        contractNumber: {
            ua: 'номер контракту',
            en: 'contract number',
            cz: 'číslo smlouvy',
            bg: 'номер на договора'
        },
        contractDate: {
            ua: 'дата контракту',
            en: 'contract date',
            cz: 'datum smlouvy',
            bg: 'дата на договора'
        },
        director: {
            ua: 'директор',
            en: 'director',
            cz: 'ředitel',
            bg: 'директор'
        },
        accountID: {
            ua: 'ID рахунку',
            en: 'account ID',
            cz: 'ID účtu',
            bg: 'ИД на акаунта'
        },
        disabled: {
            ua: 'неактивований',
            en: 'inactive',
            cz: 'neaktivovaný',
            bg: 'неактивен'
        },
        accountName: {
            ua: 'назва рахунку',
            en: 'account name',
            cz: 'název účtu',
            bg: 'име на акаунта'
        }
    },
    servicesSum: {
        ua: 'сума послуг',
        en: 'services sum',
        cz: 'částka služeb',
        bg: 'сума на услугите'
    }
};
