import { FilterFilled } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import classNames from 'classnames/bind';
import { Catcher } from 'commons';
import { DateRangePicker } from 'components';
import { clearUniversalFilters } from 'core/forms/universalFiltersForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import {
    createInviteOrders,
    fetchOrders,
    setOrdersPageFilter,
    setOrdersPageSort,
    setOrdersRequisiteFilter,
    setOrdersStatusFilter
} from 'core/orders/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { columnsConfig } from './rtlTableConfig';
import Styles from './styles.m.css';

const cx = classNames.bind(Styles);

const mapStateToProps = state => ({
    count: state.orders.count,
    orders: state.orders.data,
    filter: state.orders.filter,
    modal: state.modals.modal,
    sort: state.orders.sort,
    ordersFetching: state.ui.ordersFetching,
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    businessRequisites: selectBusinessRequisites(state)
});

const mapDispatchToProps = {
    fetchOrders,
    setOrdersStatusFilter,
    setOrdersPageFilter,
    createInviteOrders,
    setModal,
    resetModal,
    setOrdersPageSort,
    clearUniversalFilters,
    fetchBusinessRequisites,
    setOrdersRequisiteFilter
};

const dateFormat = 'DD-MM-YYYY';

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class RTLContainer extends Component {
    constructor(props) {
        super(props);
        const status = RTLContainer.getStatuses(props);

        this.state = {
            activeRoute: props.location.pathname,
            status,
            loading: false,
            selectedRowKeys: [],
            rtlDocs: [],
            rtsStats: 0,
            createEndDate: undefined,
            createStartDate: undefined,
            page: 1,
            pageSize: 25
        };
    }

    static getStatuses(properties) {
        const statusesMap = [
            {
                route: /orders\/appointments/,
                statuses: 'not_complete,required,call,reserve'
            },
            { route: /orders\/approve/, statuses: 'approve' },
            { route: /orders\/progress/, statuses: 'progress' },
            { route: /orders\/success/, statuses: 'success' },
            { route: /orders\/reviews/, statuses: 'review' },
            { route: /orders\/invitations/, statuses: 'invite' },
            { route: /orders\/cancel/, statuses: 'cancel' },

            { route: /orders\/stop/, statuses: 'stop' },
            { route: /orders\/processing/, statuses: 'processing' }
        ];
        const matchedRoutes = statusesMap.filter(statusConfig =>
            properties.location.pathname.match(statusConfig.route));
        const status = (_.first(matchedRoutes) || {}).statuses;

        return status;
    }

    componentDidMount() {
        this.props.setOrdersStatusFilter(this.state.status);
        this.props.clearUniversalFilters();
        this.props.setOrdersRequisiteFilter({ requisiteId: this.props.user.businessRequisitesId });
        this.props.fetchRtlDocs({ page: this.state.page, pageSize: this.state.pageSize, status: 'not_complete' });
        this.props.fetchBusinessRequisites();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.activeRoute !== this.props.location.pathname) {
            this.setState({ activeRoute: this.props.location.pathname });
        }
        if (prevState.activeRoute !== this.state.activeRoute) {
            // this.columnsConfig(status);
            window.location.reload();
            // this.props.fetchBusinessRequisites();
            // this.props.fetchOrders({
            //     ...this.props.filter,
            //     status: this.state.status
            // });
        }

        if (prevProps.orders !== this.props.orders) {
            // this.props.setOrdersRequisiteFilter({ requisiteId: this.props.user.businessRequisitesId });
            // this.props.fetchOrders(this.props.filter);

            return this.setState({
                selectedRowKeys: [],
                invited: []
            });
        }
        // if (this.state.ordersOrError === null) {
        //     // At this point, we're in the "commit" phase, so it's safe to load the new data.
        //     this.props.actions.fetchOrders(1);
        // }
    }

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.props.setFilters({ filterDocumentType: e.target.value });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.props.filterDocumentType}
            />
        );

        if (dataIndex === 'startDatetime') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        dateRange={[this.state.startDatetime, this.state.endDatetime]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                startDatetime: dateRange[0],
                                endDatetime: dateRange[1]
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.props.fetchRtlDocs({
                                endDatetime: this.state.endDatetime
                                    ? dayjs(this.state.createStartDate).toISOString()
                                    : undefined,
                                startDatetime: this.state.startDatetime
                                    ? dayjs(this.state.startDatetime).toISOString()
                                    : undefined
                            });
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }
        if (dataIndex === 'startSuccessDatetime') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        dateRange={[this.state.startSuccessDatetime, this.state.endSuccessDatetime]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                startSuccessDatetime: dateRange[0],
                                endSuccessDatetime: dateRange[1]
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.props.fetchRtlDocs({
                                startSuccessDatetime: this.state.startSuccessDatetime
                                    ? dayjs(this.state.startSuccessDatetime).toISOString()
                                    : undefined,
                                endSuccessDatetime: this.state.endSuccessDatetime
                                    ? dayjs(this.state.endSuccessDatetime).toISOString()
                                    : undefined
                            });
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        if (dataIndex === 'startDeliveryDatetime') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        dateRange={[this.state.startDeliveryDatetime, this.state.endDeliveryDatetime]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                startDeliveryDatetime: dateRange[0],
                                endDeliveryDatetime: dateRange[1]
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.props.fetchRtlDocs({
                                startDeliveryDatetime: this.state.startDeliveryDatetime
                                    ? dayjs(this.state.startDeliveryDatetime).toISOString()
                                    : undefined,
                                endDeliveryDatetime: this.state.endDeliveryDatetime
                                    ? dayjs(this.state.endDeliveryDatetime).toISOString()
                                    : undefined
                            });
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'datetimeFrom' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                marginTop: 6
                            }}
                        >
                            <Button
                                onClick={async () => {
                                    await this.handleReset(confirm, clearFilters, dataIndex);
                                }}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1, pageSize: 25 });
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        if (dataIndex === 'startSuccessDatetime') {
            this.setState({
                startSuccessDatetime: undefined,
                endSuccessDatetime: undefined
            });
        }
        if (dataIndex === 'startDatetime') {
            this.setState({
                startDatetime: undefined,
                endDatetime: undefined
            });
        }
        if (dataIndex === 'startDeliveryDatetime') {
            this.setState({
                startDeliveryDatetime: undefined,
                endDeliveryDatetime: undefined
            });
        }
        await this.setState({ [dataIndex]: undefined, page: 1 });
        this.props.fetchRtlDocs({
            page: 1,
            pageSize: 25
        });
    };

    onSelectChange = (selectedRowKeys, selectedOrders) => {
        const removedKeys = _.difference(this.state.selectedRowKeys, selectedRowKeys);
        const removedOrders = _.filter(this.props.orders, ({ key }) => removedKeys.includes(key)).map(order =>
            _.pick(order, ['clientId', 'vehicleId'])
        );

        // Filter invited orders
        const availableSelectedOrders = this.getAvailableOrdersForInvite(selectedOrders);
        // Filter duplicate orders
        const availableOrders = _.differenceWith(availableSelectedOrders, removedOrders, _.isEqual);

        // ~ -1 == false
        // ~ (>=0) == true
        const allOrders = this.props.orders.filter(({ clientId, vehicleId }) =>
            _.findIndex(availableOrders, { clientId, vehicleId })
        );
        const selectedKeys = _.map(allOrders, 'key');

        this.setState({ selectedRowKeys: selectedKeys });
    };

    getOrderCheckboxProps = order => {
        // Checkbox is disabled if clientId or vehicleId is missing
        // Checkbox is disabled if clientVehicleId is already invited
        const missingRequiredFields = !this.isOrderInvitable(order);
        const invited = this.isAlreadyInvited(order);

        return {
            defaultValue: false,
            disabled: missingRequiredFields || invited
        };
    };

    setIniviteModal = () => this.props.setModal(MODALS.INVITE);

    render() {
        const {
            isMobile,
            children,
            businessRequisites,
            rtlDocs,
            rtsStats,
            handleSearch,
            loading,
            isRTN,
            indicateStatus
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { activeRoute, selectedRowKeys, pageSize, page, selectedRows } = this.state;

        const columns = columnsConfig(
            this.getColumnSearchProps,
            this.openOrderDrawer,
            this.state.invited,
            this.invite,
            this.isOrderInvitable,
            this.isAlreadyInvited,
            activeRoute,
            this.props.sort,
            this.props.user,
            formatMessage,
            isMobile,
            businessRequisites,
            this.props.filter.requisiteId,
            requisiteId => {
                this.props.setOrdersRequisiteFilter({ requisiteId });
                this.props.fetchOrders(this.props.filter);
            },
            isRTN
        );

        const handleTableChange = (pagination, filters, sorter) => {
            if (!sorter) {
                return;
            }
            const sort = {
                field: sorter.field,
                order: sorter.order === 'ascend' ? 'asc' : 'desc'
            };
            if (!_.isEqual(sort, this.props.sort)) {
                this.props.setOrdersPageSort(sort);
                this.props.fetchOrders();
            }
        };

        const pagination = {
            pageSize,
            size: 'large',
            total: Math.ceil(rtsStats / pageSize) * this.state.pageSize,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.props.fetchRtlDocs({ page, pageSize, status: indicateStatus || 'not_complete' });
            }
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        const delayedConfig = ['call', 'required', 'not_complete', 'reserve', 'approve'];

        const _rowClassName = (beginDatetime, deliveryDatetime, status) =>
            cx({
                delayedRow: delayedConfig.includes(status)
                    ? beginDatetime
                        ? dayjs(beginDatetime).diff(dayjs(), 'days') <= -2
                        : true
                    : false,
                delayedProgressRow:
                    status === 'progress'
                        ? deliveryDatetime
                            ? dayjs(deliveryDatetime).diff(dayjs(), 'days') <= -1
                            : true
                        : false
            });

        return (
            <Catcher>
                <div>
                    {children}
                    <div
                        style={{
                            marginBottom: 12
                        }}
                    >
                        <Input
                            maxLength={99}
                            onChange={event => handleSearch(event.target.value)}
                            placeholder={formatMessage({ id: 'search' })}
                            style={{
                                width: !isMobile ? '50%' : '100%'
                            }}
                        />
                    </div>
                    <div style={{ overflowX: 'auto' }}>
                        {!isMobile ? (
                            <Table
                                bordered
                                className={Styles.ordersTable}
                                columns={columns}
                                dataSource={rtlDocs}
                                loading={loading}
                                locale={{
                                    emptyText: <FormattedMessage id='no_data' />
                                }}
                                onChange={handleTableChange}
                                // scroll={ scrollConfig(activeRoute) }
                                pagination={pagination}
                                rowClassName={({ datetime, deliveryDatetime, status }) =>
                                    _rowClassName(datetime, deliveryDatetime, status)
                                }
                                rowSelection={rowSelection}
                                size='small'
                            />
                        ) : (
                            <Table
                                bordered
                                columns={columns}
                                dataSource={rtlDocs}
                                loading={loading}
                                locale={{
                                    emptyText: <FormattedMessage id='no_data' />
                                }}
                                onChange={handleTableChange}
                                pagination={pagination}
                                rowSelection={rowSelection}
                                size='small'
                            />
                        )}
                    </div>
                </div>
            </Catcher>
        );
    }
}

export default RTLContainer;
