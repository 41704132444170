import { Form } from '@ant-design/compatible';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Select, Tooltip } from 'antd';
import { DecoratedInput, DecoratedInputNumber, DecoratedSelect, DecoratedTreeSelect } from 'forms/DecoratedFields';
import _, { get } from 'lodash';
import { selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';
import { cashboxTypes } from './config';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => {
    return {
        user: state.auth,
        businessRequisites: selectBusinessRequisites(state)
    };
};

@injectIntl
@Form.create()
@connect(mapStateToProps, void 0)
export class CashCreationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tree: undefined
        };

        props.getFormRefCB && props.getFormRefCB(this.props.form); // Callback to get form's refference
    }

    /**
     * This method generates styled and structured row
     * @param {*} label     The component which represents label(like any div, h1, h2, h3 and other tags)
     * @param {*} component The component to be placed as a main input or select(pass Form item in it)
     * @returns
     */
    // eslint-disable-next-line class-methods-use-this
    generateStyledContentRow(label, component, isHidden = false) {
        return (
            <Row
                className={Styles.row}
                style={{
                    display: isHidden ? 'none' : undefined
                }}
            >
                <Col className={Styles.label} span={8}>
                    {label}
                </Col>

                <Col className={Styles.content} span={16}>
                    {component}
                </Col>
            </Row>
        );
    }

    filterTreeByActive = nodes => {
        const filteredNodes = nodes.reduce((accumulator, node) => {
            if (node.active === true) {
                const filteredNode = {
                    value: node.id,
                    title: `(#${node.id}) ${node.name} `,
                    level: node.level,
                    disabled: node.level !== 4
                };

                if (node.children) {
                    const filteredChildren = this.filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNode.children = filteredChildren;
                    }
                }

                accumulator.push(filteredNode);
            }

            return accumulator;
        }, []);

        // Update the state outside of the reduce function
        this.setState({
            tree: filteredNodes
        });

        return filteredNodes;
    };

    validateUkrainianLetter = (_, value) => {
        const ukrainianLetterRegex = /^[А-ЩЬЮЯҐЄІЇа-щьюяґєії]{1}$/;
        if (!value) {
            return Promise.resolve(); // Allow empty value
        }
        if (ukrainianLetterRegex.test(value)) {
            return Promise.resolve(); // Valid single Ukrainian letter
        }
        const error = this.props.intl.formatMessage({ id: 'cash-creation-form.name.validation_ukr_letter' });

        return Promise.reject(new Error(error));
    };

    componentDidUpdate() {
        const { tree } = this.state;
        if (!tree && _.get(this.props.accounts, 'length')) {
            this.filterTreeByActive(this.props.accounts);
        }
    }

    render() {
        const {
            user,
            form,
            editMode,
            businessRequisites,
            intl: { formatMessage },
            accounts,
            cashbox
        } = this.props;

        const { tree } = this.state;

        const { getFieldDecorator, getFieldsError, getFieldValue, setFieldsValue } = form;

        const type = getFieldValue('type');

        const errors = getFieldsError();
        const formFieldsValues = form.getFieldsValue();

        return (
            <Form id='cash-creation-form'>
                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.name' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        field='name'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({ id: 'cash-creation-form.name.validation' })
                            }
                        ]}
                    />
                )}
                {/* ------------------------------------------------------------- */}

                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.type' />:
                    </span>,
                    <DecoratedSelect
                        className={Styles.formItemStyle}
                        disabled={editMode}
                        field='type'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={() => document.getElementById('cash-creation-form')}
                        initialValue={cashboxTypes.CASH}
                    >
                        {Object.values(cashboxTypes).map(item => (
                            <Option key={item} value={item}>
                                {formatMessage({ id: `cash-creation-form.type-${item}` })}
                            </Option>
                        ))}
                    </DecoratedSelect>
                )}

                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='directory_page.requisites' />:
                    </span>,
                    <DecoratedSelect
                        allowClear
                        className={Styles.formItemStyle}
                        // disabled={editMode}
                        field='businessRequisiteId'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={() => document.getElementById('cash-creation-form')}
                        initialValue={user.businessRequisitesId}
                    >
                        {businessRequisites
                            .filter(({ enabled }) => enabled)
                            .map(({ id, name }) => (
                                <Option key={id} value={id}>
                                    {name}
                                </Option>
                            ))}
                    </DecoratedSelect>
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='new-document-page.item.account' />:
                    </span>,
                    <DecoratedTreeSelect
                        className={Styles.formItemStyle}
                        field='accountId'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        optionLabel='title'
                        treeData={tree || []}

                        // style={{ color: 'var(--text)', width: '100%' }}
                    />
                )}
                {/* ------------------------------------------------------------- */}

                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.fiscal_number' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={
                            isForbidden(user, permissions.ACCESS_CASHBOX_CRUD) ||
                            editMode ||
                            ['BANK_ACCOUNT', 'OTHER'].includes(type)
                        }
                        field='fiscalNumber'
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        rules={
                            [
                                // {
                                //     len: 10,
                                //     message: formatMessage({
                                //         id: 'cash-creation-form.fiscal_number_too_short'
                                //     })
                                // },
                                // {
                                //     pattern: /^\d+$/,
                                //     message: formatMessage({
                                //         id: 'cash-creation-form.fiscal_number_digits_only'
                                //     })
                                // }
                            ]
                        }
                    />
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='Ключ ліцензії' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={
                            isForbidden(user, permissions.ACCESS_CASHBOX_CRUD) ||
                            editMode ||
                            ['BANK_ACCOUNT', 'OTHER'].includes(type)
                        }
                        field='licenseKey'
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        rules={
                            [
                                // {
                                //     len: 10,
                                //     message: formatMessage({
                                //         id: 'cash-creation-form.fiscal_number_too_short'
                                //     })
                                // },
                                // {
                                //     pattern: /^\d+$/,
                                //     message: formatMessage({
                                //         id: 'cash-creation-form.fiscal_number_digits_only'
                                //     })
                                // }
                            ]
                        }
                    />
                )}
                {/* ------------------------------------------------------------- */}

                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.description' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={editMode}
                        field='description'
                        formItem
                        getFieldDecorator={getFieldDecorator} // If you will not provide this you won't be aple to enter text into this input
                        rules={[]}
                    />
                )}
                {/* ------------------------------------------------------------- */}

                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.autoClosePeriod' />
                        <Tooltip getPopupContainer={trigger => trigger.parentNode} title='Формат Cron'>
                            <InfoCircleOutlined
                                style={{ color: 'rgba(0,0,0,.45)', fontSize: 14, marginRight: 2, marginLeft: 2 }}
                            />
                        </Tooltip>
                        :
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={
                            !_.get(formFieldsValues, 'fiscalNumber') ||
                            Boolean(_.get(errors, 'fiscalNumber')) ||
                            isForbidden(user, permissions.ACCESS_CASHBOX_CRUD)
                        }
                        field='autoClosePeriod'
                        formItem
                        getFieldDecorator={getFieldDecorator} // If you will not provide this you won't be aple to enter text into this input
                        rules={[]}
                    />
                )}
                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        PPO
                        <Tooltip
                            getPopupContainer={trigger => trigger.parentNode}
                            title={<FormattedMessage id='cash-creation-form.description_ppo' />}
                        >
                            <InfoCircleOutlined
                                style={{ color: 'rgba(0,0,0,.45)', fontSize: 14, marginRight: 2, marginLeft: 2 }}
                            />
                        </Tooltip>
                        :
                    </span>,
                    <DecoratedSelect
                        allowClear
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        placeholder='PPO'
                        // suffixIcon={
                        //     <Tooltip getPopupContainer={trigger => trigger.parentNode} title={<FormattedMessage id='cash-creation-form.description_ppo'/>}>
                        //         <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 14, marginRight: 16 }} />
                        //     </Tooltip>
                        // }
                        className={Styles.formItemStyle}
                        // disabled={editMode}
                        field='taxService'
                        getPopupContainer={() => document.getElementById('cash-creation-form')}
                        // initialValue={cashboxTypes.CASH}
                    >
                        <Option key='CASHDESK' value='CASHDESK'>
                            Cashdesk
                        </Option>
                        <Option key='CHECKBOX' value='CHECKBOX'>
                            Checkbox
                        </Option>
                    </DecoratedSelect>
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='locations.letter' />
                        <Tooltip
                            getPopupContainer={trigger => trigger.parentNode}
                            title={<FormattedMessage id='cash-creation-form.name.validation_ukr_letter' />}
                        >
                            <InfoCircleOutlined
                                style={{ color: 'rgba(0,0,0,.45)', fontSize: 14, marginRight: 2, marginLeft: 2 }}
                            />
                        </Tooltip>
                        :
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={isForbidden(user, permissions.ACCESS_CASHBOX_CRUD)}
                        field='taxLetter'
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        maxLength={1}
                        placeholder={formatMessage({
                            id: 'locations.letter'
                        })}
                        rules={[
                            { validator: this.validateUkrainianLetter },
                            {
                                required: _.get(formFieldsValues, 'taxService') === 'CASHDESK',
                                message: this.props.intl.formatMessage({
                                    id: 'business-package-form.activation_datetime_error'
                                })
                            }
                        ]}
                    />,
                    _.get(formFieldsValues, 'taxService') !== 'CASHDESK' && true
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='locations.percent' />:
                    </span>,
                    <DecoratedInputNumber
                        className={Styles.formItemStyle}
                        disabled={isForbidden(user, permissions.ACCESS_CASHBOX_CRUD)}
                        field='taxRate'
                        formatter={value => `${value}%`}
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        max={100}
                        min={-100}
                        parser={value => value.replace('%', '').replace(',', '.')}
                        rules={[
                            {
                                required: _.get(formFieldsValues, 'taxService') === 'CASHDESK',
                                message: this.props.intl.formatMessage({
                                    id: 'business-package-form.activation_datetime_error'
                                })
                            }
                        ]}
                    />,
                    _.get(formFieldsValues, 'taxService') !== 'CASHDESK' && true
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='locations.uniq_tax_code' />
                        <Tooltip
                            getPopupContainer={trigger => trigger.parentNode}
                            title={<FormattedMessage id='cash-creation-form.taxCode_desc' />}
                        >
                            <InfoCircleOutlined
                                style={{ color: 'rgba(0,0,0,.45)', fontSize: 14, marginRight: 2, marginLeft: 2 }}
                            />
                        </Tooltip>
                        :
                    </span>,
                    <DecoratedInputNumber
                        className={Styles.formItemStyle}
                        disabled={isForbidden(user, permissions.ACCESS_CASHBOX_CRUD)}
                        field='taxCode'
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        max={8}
                        min={0}
                        placeholder={formatMessage({
                            id: 'locations.uniq_tax_code'
                        })}
                        rules={[
                            {
                                required: _.get(formFieldsValues, 'taxService') === 'CHECKBOX',
                                message: this.props.intl.formatMessage({
                                    id: 'business-package-form.activation_datetime_error'
                                })
                            }
                        ]}
                    />,
                    _.get(formFieldsValues, 'taxService') !== 'CHECKBOX' && true
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='acquirer_id' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={isForbidden(user, permissions.ACCESS_CASHBOX_CRUD)}
                        field='acquirerId'
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        initialValue={get(cashbox, 'acquirerId')}
                        onChange={e => {
                            setFieldsValue({ acquirerId: e.target.value });
                        }}
                        placeholder={formatMessage({
                            id: 'acquirer_id'
                        })}
                    />
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='requisite-setting.bank' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={isForbidden(user, permissions.ACCESS_CASHBOX_CRUD)}
                        field='bank'
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        initialValue={get(cashbox, 'bank')}
                        onChange={e => {
                            setFieldsValue({ bank: e.target.value });
                        }}
                        placeholder={formatMessage({
                            id: 'requisite-setting.bank'
                        })}
                    />
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='terminal' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={isForbidden(user, permissions.ACCESS_CASHBOX_CRUD)}
                        field='terminal'
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        initialValue={get(cashbox, 'terminal')}
                        onChange={e => {
                            setFieldsValue({ terminal: e.target.value });
                        }}
                        placeholder={formatMessage({
                            id: 'terminal'
                        })}
                    />
                )}
                {/* {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='mask_type' />:
                    </span>,
                    <DecoratedSelect
                        allowClear
                        className={Styles.formItemStyle}
                        field='cardMaskType'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={() => document.getElementById('cash-creation-form')}
                        initialValue={get(cashbox, 'cardMaskType', 81)}
                        placeholder={formatMessage({
                            id: 'mask_type'
                        })}
                    >
                        <Option key='4' value={4}>
                            <FormattedMessage id='4_digits' /> (**** **** **** 1234)
                        </Option>
                        <Option key='6' value={6}>
                            <FormattedMessage id='6_digits' /> (12** **** **** 1234)
                        </Option>
                        <Option key='8.1' value={81}>
                            <FormattedMessage id='8_digits' /> (1234 **** **** 1234)
                        </Option>
                        <Option key='8.2' value={82}>
                            <FormattedMessage id='8_digits' /> (1234 12** **** **34)
                        </Option>
                    </DecoratedSelect>
                )} */}
            </Form>
        );
    }
}
