export const engineObjectsData = [
    {
        unitId: 500,
        systemId: 4,
        id: '04d330ff-0620-4f39-a191-f07d69a5782e',
        name: '发动机446',
        location: '[0.20456747710704803, -0.12476673722267151, -0.2175864428281784]',
        vertices: 11850,
        polygons: 7756,
        lod: ['LOD_4'],
        name_work: 'dtl446',
        name_ua: 'Датчик обертів колінчастого валу',
        group: 1181104,
        tooltip: {
            cz: 'Senzor polohy klikového hřídele',
            en: 'Crankshaft position sensor',
            ua: 'Датчик обертів колінчастого валу',
            bg: 'Датчик на позицията на коляновия вал'
        }
    },
    {
        unitId: 501,
        systemId: 4,
        id: 'db5ea5f8-fdf2-42d9-b468-1e8d39cff6d5',
        name: '______889',
        location: '[0.002195274457335472, -0.20652922987937927, 0.09768807888031006]',
        vertices: 420,
        polygons: 216,
        lod: ['LOD_4'],
        name_work: 'dtl889',
        name_ua: 'Дросельна заслінка випускного колектора',
        group: 1110500,
        tooltip: {
            cz: 'Odtahový ventil sání',
            en: 'Throttle valve of the exhaust manifold',
            ua: 'Дросельна заслінка випускного колектора',
            bg: 'Дроселна клапа на изпускателния колектор'
        }
    },
    {
        unitId: 502,
        systemId: 4,
        id: '7737fa4d-35a4-445e-8269-5a43c6661edc',
        name: '发动机888',
        location: '[0.0021961890161037445, -0.19635608792304993, 0.09769463539123535]',
        vertices: 991,
        polygons: 496,
        lod: ['LOD_4'],
        name_work: 'dtl888',
        name_ua: 'Дросельна заслінка випускного колектора',
        group: 1110500,
        tooltip: {
            cz: 'Odtahový ventil sání',
            en: 'Throttle valve of the exhaust manifold',
            ua: 'Дросельна заслінка випускного колектора',
            bg: 'Дроселна клапа на изпускателния колектор'
        }
    },
    {
        unitId: 503,
        systemId: 4,
        id: 'e56e7772-539b-4349-84c4-463264ff17dd',
        name: '发动机887',
        location: '[-0.0052690207958221436, -0.20089557766914368, 0.10197287797927856]',
        vertices: 5649,
        polygons: 5730,
        lod: ['LOD_4'],
        name_work: 'dtl887',
        name_ua: 'Дросельна заслінка випускного колектора',
        group: 1110500,
        tooltip: {
            cz: 'Odtahový ventil sání',
            en: 'Throttle valve of the exhaust manifold',
            ua: 'Дросельна заслінка випускного колектора',
            bg: 'Дроселна клапа на изпускателния колектор'
        }
    },
    {
        unitId: 504,
        systemId: 4,
        id: 'ada16ef2-8efc-4f1b-ac71-5113983d8f92',
        name: 'Cylinder07',
        location: '[0.00040953815914690495, -0.19250229001045227, 0.054834119975566864]',
        vertices: 3606,
        polygons: 6820,
        lod: ['LOD_4'],
        name_work: 'Cylinder07',
        name_ua: 'Випускний колектор',
        group: 1130900,
        tooltip: { cz: 'Sání', en: 'Exhaust manifold', ua: 'Випускний колектор', bg: 'Изпускателен колектор' }
    },
    {
        unitId: 505,
        systemId: 4,
        id: '500a35dc-432f-47f9-a410-e178ecda1330',
        name: 'Cylinder06',
        location: '[-0.015367133542895317, 0.2367381751537323, -0.11431235074996948]',
        vertices: 266,
        polygons: 208,
        lod: ['LOD_4'],
        name_work: 'Cylinder06',
        name_ua: 'Кріплення турбокомпресора',
        group: 1110402,
        tooltip: {
            cz: 'Montáž turbodmychadla',
            en: 'Turbocharger mount',
            ua: 'Кріплення турбокомпресора',
            bg: 'Монтаж на турбокомпресора'
        }
    },
    {
        unitId: 506,
        systemId: 4,
        id: '483687df-3328-454c-81f0-6fbcccb791c1',
        name: 'Cylinder05',
        location: '[-0.015367133542895317, 0.22752222418785095, -0.08827793598175049]',
        vertices: 266,
        polygons: 208,
        lod: ['LOD_4'],
        name_work: 'Cylinder05',
        name_ua: 'Кріплення турбокомпресора',
        group: 1110402,
        tooltip: {
            cz: 'Montáž turbodmychadla',
            en: 'Turbocharger mount',
            ua: 'Кріплення турбокомпресора',
            bg: 'Монтаж на турбокомпресора'
        }
    },
    {
        unitId: 507,
        systemId: 4,
        id: '58dc1901-1b63-47f3-9fb1-529130a21706',
        name: 'Cylinder04',
        location: '[-0.015367133542895317, 0.19887539744377136, -0.0762602686882019]',
        vertices: 266,
        polygons: 208,
        lod: ['LOD_4'],
        name_work: 'Cylinder04',
        name_ua: 'Кріплення турбокомпресора',
        group: 1110402,
        tooltip: {
            cz: 'Montáž turbodmychadla',
            en: 'Turbocharger mount',
            ua: 'Кріплення турбокомпресора',
            bg: 'Монтаж на турбокомпресора'
        }
    },
    {
        unitId: 508,
        systemId: 4,
        id: '6070a0b4-72eb-49f5-b46a-1c1b1042b962',
        name: 'Cylinder03',
        location: '[0.06850218772888184, 0.2447476089000702, -0.11431235074996948]',
        vertices: 266,
        polygons: 208,
        lod: ['LOD_4'],
        name_work: 'Cylinder03',
        name_ua: 'Кріплення турбокомпресора',
        group: 1110402,
        tooltip: {
            cz: 'Montáž turbodmychadla',
            en: 'Turbocharger mount',
            ua: 'Кріплення турбокомпресора',
            bg: 'Монтаж на турбокомпресора'
        }
    },
    {
        unitId: 509,
        systemId: 4,
        id: 'be02dc31-af85-4534-87b3-8aa44c847cfc',
        name: 'Cylinder02',
        location: '[0.06850218772888184, 0.19887539744377136, -0.0762602686882019]',
        vertices: 266,
        polygons: 208,
        lod: ['LOD_4'],
        name_work: 'Cylinder02',
        name_ua: 'Кріплення турбокомпресора',
        group: 1110402,
        tooltip: {
            cz: 'Montáž turbodmychadla',
            en: 'Turbocharger mount',
            ua: 'Кріплення турбокомпресора',
            bg: 'Монтаж на турбокомпресора'
        }
    },
    {
        unitId: 510,
        systemId: 4,
        id: '23d2d032-546e-4596-b9ac-3bfe70292c60',
        name: 'Cylinder01',
        location: '[0.06850218772888184, 0.22752222418785095, -0.08827793598175049]',
        vertices: 266,
        polygons: 208,
        lod: ['LOD_4'],
        name_work: 'Cylinder01',
        name_ua: 'Кріплення турбокомпресора',
        group: 1110402,
        tooltip: {
            cz: 'Montáž turbodmychadla',
            en: 'Turbocharger mount',
            ua: 'Кріплення турбокомпресора',
            bg: 'Монтаж на турбокомпресора'
        }
    },
    {
        unitId: 511,
        systemId: 4,
        id: 'bd4f15d7-2c8b-4e8a-a425-414dc312b85e',
        name: '发动机882',
        location: '[-0.10235169529914856, 0.16232523322105408, 0.001056814449839294]',
        vertices: 6612,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl882',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 512,
        systemId: 4,
        id: '294c181d-be8a-4c06-b8ee-170e7a60611a',
        name: '发动机881',
        location: '[-0.028265083208680153, 0.16232523322105408, 0.001056814449839294]',
        vertices: 6612,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl881',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 513,
        systemId: 4,
        id: 'd43998eb-cb7e-4f54-9595-57a151e8266f',
        name: '发动机880',
        location: '[0.08717420697212219, 0.16232523322105408, 0.0010563078103587031]',
        vertices: 6612,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl880',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 514,
        systemId: 4,
        id: 'ba1a178a-589d-4e3d-a2f3-dccb2fb1f8e5',
        name: '发动机879',
        location: '[-0.019306646659970284, 0.162335604429245, 0.00105621840339154]',
        vertices: 192,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl879',
        name_ua: 'Вал вихрових заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel výfukové klapky',
            en: 'Exhaust flap shaft',
            ua: 'Вал вихрових заслінок',
            bg: 'Вал на изпускателния клапан'
        }
    },
    {
        unitId: 515,
        systemId: 4,
        id: '00543cee-9497-46fa-848e-6e29274322ee',
        name: '发动机878',
        location: '[0.06503447145223618, 0.17667779326438904, 0.02423744834959507]',
        vertices: 1628,
        polygons: 774,
        lod: ['LOD_4'],
        name_work: 'dtl878',
        name_ua: 'Саморіз',
        group: 1010501,
        tooltip: { cz: 'Samosvorný šroub', en: 'Self-tapping screw', ua: 'Саморіз', bg: 'Самонарезен' }
    },
    {
        unitId: 516,
        systemId: 4,
        id: 'fc36daa4-a454-42b6-98a9-14692edcc06c',
        name: '发动机877',
        location: '[-0.028263403102755547, 0.16331204771995544, 0.0006159187760204077]',
        vertices: 9719,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl877',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 517,
        systemId: 4,
        id: 'a02ed7a0-b23f-4f45-9c63-45f1086e5748',
        name: '发动机876',
        location: '[-0.10235321521759033, 0.163312166929245, 0.0006159187760204077]',
        vertices: 9719,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl876',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 518,
        systemId: 4,
        id: '9fada578-81d2-46be-9563-a708be4aef5c',
        name: '发动机875',
        location: '[0.030316917225718498, 0.16331204771995544, 0.0006159187760204077]',
        vertices: 9719,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl875',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 519,
        systemId: 4,
        id: '0dd5b682-d48e-46fb-8955-dd7b2a56aefd',
        name: '发动机874',
        location: '[0.08717405796051025, 0.1633116900920868, 0.00061588897369802]',
        vertices: 9798,
        polygons: 4898,
        lod: ['LOD_3'],
        name_work: 'dtl874',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 520,
        systemId: 4,
        id: '961280d2-1a4d-4b29-b840-580038fd4956',
        name: '发动机873',
        location: '[-0.004144905135035515, 0.18186506628990173, 0.04989295452833176]',
        vertices: 13922,
        polygons: 16426,
        lod: ['LOD_4'],
        name_work: 'dtl873',
        name_ua: 'Впускний колектор',
        group: 1110800,
        tooltip: { cz: 'Sání', en: 'Intake manifold', ua: 'Впускний колектор', bg: 'Входен колектор' }
    },
    {
        unitId: 521,
        systemId: 4,
        id: '13601f58-fd16-4037-834b-2e82a2818d5e',
        name: '发动机872',
        location: '[-0.16920319199562073, 0.17400583624839783, 0.023712629452347755]',
        vertices: 730,
        polygons: 386,
        lod: ['LOD_4'],
        name_work: 'dtl872',
        name_ua: 'Термозахист трубки рециркуляції вихлопних газів',
        group: 1130700,
        tooltip: {
            cz: 'Tepelná ochrana potrubí recirkulace výfukových plynů',
            en: 'Thermal protection of the exhaust gas recirculation pipe',
            ua: 'Термозахист трубки рециркуляції вихлопних газів',
            bg: 'Термична защита на тръбата за рециркулиране на отработените газове'
        }
    },
    {
        unitId: 522,
        systemId: 4,
        id: '744eb78f-0d15-4054-8d69-bc61550b6d2c',
        name: '发动机871',
        location: '[-0.15320710837841034, 0.16966411471366882, -0.0009004233870655298]',
        vertices: 3760,
        polygons: 1977,
        lod: ['LOD_3'],
        name_work: 'dtl871',
        name_ua: 'Важіль кріплення вакуумного привода вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Páka pro montáž vakuového pohonu výfukových klapek',
            en: 'Lever for mounting the vacuum drive of the exhaust flaps',
            ua: 'Важіль кріплення вакуумного привода вихревих заслінок',
            bg: 'Лост за закрепване на вакуумното задвижване на вихровите клапани'
        }
    },
    {
        unitId: 523,
        systemId: 4,
        id: '9eb32bbb-3283-432d-adad-7ac0caf8b46b',
        name: '发动机870',
        location: '[-0.16235443949699402, 0.16822919249534607, 0.009579234756529331]',
        vertices: 1638,
        polygons: 774,
        lod: ['LOD_4'],
        name_work: 'dtl870',
        name_ua: 'Болти кріплення вакуумного привода вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Šrouby pro montáž vakuového pohonu výfukových klapek',
            en: 'Bolts for mounting the vacuum drive of the exhaust flaps',
            ua: 'Болти кріплення вакуумного привода вихревих заслінок',
            bg: 'Гайки за закрепване на вакуумното задвижване на вихровите клапани'
        }
    },
    {
        unitId: 524,
        systemId: 4,
        id: '63d783ab-849f-4247-9f7c-9f3b9fd5e51e',
        name: '发动机869',
        location: '[-0.14047303795814514, 0.1623365581035614, 0.0010561587987467647]',
        vertices: 1584,
        polygons: 816,
        lod: ['LOD_3'],
        name_work: 'dtl869',
        name_ua: 'Важіль вихрових заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Páka výfukové klapky',
            en: 'Exhaust flap lever',
            ua: 'Важіль вихрових заслінок',
            bg: 'Лост за изпускателния клапан'
        }
    },
    {
        unitId: 525,
        systemId: 4,
        id: 'abf1dd42-e53b-4c83-8d4a-5404cf71245a',
        name: '发动机867',
        location: '[-0.01930527575314045, 0.162335604429245, 0.0010563674150034785]',
        vertices: 190,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl867',
        name_ua: 'Вал вихрових заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel výfukové klapky',
            en: 'Exhaust flap shaft',
            ua: 'Вал вихрових заслінок',
            bg: 'Вал на изпускателния клапан'
        }
    },
    {
        unitId: 526,
        systemId: 4,
        id: '3990cd0b-6156-4b5c-9eca-08c973b4d8a5',
        name: '发动机866',
        location: '[0.08717420697212219, 0.1623346507549286, 0.0009550096001476049]',
        vertices: 6610,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl866',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 527,
        systemId: 4,
        id: '447da223-5bcf-4860-8fa0-04602f1674f6',
        name: '发动机865',
        location: '[0.06503447145223618, 0.17667779326438904, 0.02423744834959507]',
        vertices: 1628,
        polygons: 774,
        lod: ['LOD_4'],
        name_work: 'dtl865',
        name_ua: 'Саморіз',
        group: 1010501,
        tooltip: { cz: 'Samosvorný šroub', en: 'Self-tapping screw', ua: 'Саморіз', bg: 'Самонарезен' }
    },
    {
        unitId: 528,
        systemId: 4,
        id: '5ea91ca5-efa2-4ef0-a1d9-41803954a6b5',
        name: '发动机864',
        location: '[0.030316917225718498, 0.1623346507549286, 0.0009550096001476049]',
        vertices: 6610,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl864',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 529,
        systemId: 4,
        id: '6d20ff66-33fe-417e-abb7-b760c6a99414',
        name: '发动机863',
        location: '[-0.028263403102755547, 0.16331204771995544, 0.0006159187760204077]',
        vertices: 9719,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl863',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 530,
        systemId: 4,
        id: 'a748bb58-1f82-4fbc-b0d9-f87dbefcf387',
        name: '发动机862',
        location: '[-0.028265083208680153, 0.1623346507549286, 0.0009550096001476049]',
        vertices: 6610,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl862',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 531,
        systemId: 4,
        id: 'cc18336e-ab68-4367-ba55-e93fa077c3aa',
        name: '发动机861',
        location: '[-0.10235169529914856, 0.1623348891735077, 0.0009550096001476049]',
        vertices: 6610,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl861',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 532,
        systemId: 4,
        id: 'f74d825d-0064-4cca-823b-996826ce6e71',
        name: '发动机860',
        location: '[-0.10235321521759033, 0.163312166929245, 0.0006159187760204077]',
        vertices: 9719,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl860',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 533,
        systemId: 4,
        id: 'cca211b3-b476-419c-a466-4db92da556c3',
        name: '发动机859',
        location: '[0.030316917225718498, 0.16331204771995544, 0.0006159187760204077]',
        vertices: 9719,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl859',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 534,
        systemId: 4,
        id: '5c0e2a47-e8df-41b5-b099-bee1a7a8f2c0',
        name: '发动机858',
        location: '[0.08717405796051025, 0.1633116900920868, 0.00061588897369802]',
        vertices: 9798,
        polygons: 4898,
        lod: ['LOD_3'],
        name_work: 'dtl858',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 535,
        systemId: 4,
        id: '9f2f894f-cb97-4016-b064-a41f3a4023f8',
        name: '发动机856',
        location: '[-0.10235169529914856, 0.1623278558254242, 0.001056814449839294]',
        vertices: 4099,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl856',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 536,
        systemId: 4,
        id: 'c434fd50-1104-4c75-befa-5a8e9a60c01c',
        name: '发动机855',
        location: '[-0.028265083208680153, 0.1623278558254242, 0.001056814449839294]',
        vertices: 4094,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl855',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 537,
        systemId: 4,
        id: '6039e123-224c-40dd-8565-7335892b3b4a',
        name: '发动机854',
        location: '[0.030316917225718498, 0.1623278558254242, 0.0010563078103587031]',
        vertices: 4084,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl854',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 538,
        systemId: 4,
        id: 'ba185d22-4285-46ce-b7d9-fcd51c904d2e',
        name: '发动机853',
        location: '[0.08717420697212219, 0.1623278558254242, 0.0010563078103587031]',
        vertices: 4084,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl853',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 539,
        systemId: 4,
        id: '31371938-c601-4e6b-a9ef-c41d8d5d69aa',
        name: '发动机852',
        location: '[-0.019306646659970284, 0.162335604429245, 0.0010561587987467647]',
        vertices: 171,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl852',
        name_ua: 'Вал вихрових заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel výfukové klapky',
            en: 'Exhaust flap shaft',
            ua: 'Вал вихрових заслінок',
            bg: 'Вал на изпускателния клапан'
        }
    },
    {
        unitId: 540,
        systemId: 4,
        id: 'a39914e5-6b8c-49c4-9270-56cacac8c32e',
        name: '发动机851',
        location: '[0.22642362117767334, 0.1344272792339325, -0.1683228462934494]',
        vertices: 18207,
        polygons: 15426,
        lod: ['LOD_4'],
        name_work: 'dtl851',
        name_ua: 'Шків компрессора кондиціонера',
        group: 1120900,
        tooltip: {
            cz: 'Klínový klín',
            en: 'Air conditioner compressor pulley',
            ua: 'Шків компрессора кондиціонера',
            bg: 'Ролка на компресора на климатика'
        }
    },
    {
        unitId: 541,
        systemId: 4,
        id: '6b911c1f-b535-48d3-a01b-b34f01c458b2',
        name: '发动机850',
        location: '[0.22457966208457947, -0.021834643557667732, -0.09716460108757019]',
        vertices: 20690,
        polygons: 15276,
        lod: ['LOD_4'],
        name_work: 'dtl850',
        name_ua: 'Ремень полікліновий',
        group: 1040203,
        tooltip: { cz: 'Poly-V řemen', en: 'Poly-V belt', ua: 'Ремень полікліновий', bg: 'Поликлиничен колан' }
    },
    {
        unitId: 542,
        systemId: 4,
        id: '79ec56b7-acf3-4d8a-b917-64d3d722e60a',
        name: '发动机849',
        location: '[-0.19323872029781342, -0.044850025326013565, -0.18717019259929657]',
        vertices: 15023,
        polygons: 11323,
        lod: ['LOD_4'],
        name_work: 'dtl849',
        name_ua: 'Вінець маховика',
        tooltip: { cz: 'Kružnice setrvačníku', en: 'Flywheel ring', ua: 'Вінець маховика', bg: 'Ринг на маховика' }
    },
    {
        unitId: 543,
        systemId: 4,
        id: 'aeceedd1-aab8-4be4-80be-fe522ba7ba67',
        name: '发动机848',
        location: '[0.12500602006912231, -0.04493347182869911, -0.013788229785859585]',
        vertices: 1154,
        polygons: 970,
        lod: ['LOD_2'],
        name_work: 'dtl848',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 544,
        systemId: 4,
        id: '9867c6ec-2ccf-4d7d-b823-c0a189e1905b',
        name: '发动机847',
        location: '[0.12500199675559998, -0.044940028339624405, -0.005285268649458885]',
        vertices: 582,
        polygons: 404,
        lod: ['LOD_2'],
        name_work: 'dtl847',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 545,
        systemId: 4,
        id: 'e91ba28f-0af6-49b2-ba4f-e0f7720ea833',
        name: '发动机846',
        location: '[0.12500283122062683, -0.04494074359536171, -0.009334302507340908]',
        vertices: 888,
        polygons: 712,
        lod: ['LOD_2'],
        name_work: 'dtl846',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 546,
        systemId: 4,
        id: 'd2e5836c-47d7-44eb-b363-520a1fa5c036',
        name: '发动机845',
        location: '[0.12500345706939697, -0.045629773288965225, -0.02544412575662136]',
        vertices: 597,
        polygons: 460,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl845',
        name_ua: 'Палець поршня',
        group: 1140104,
        tooltip: { cz: 'Pístní čep', en: 'Piston pin', ua: 'Палець поршня', bg: 'Бутален болт' }
    },
    {
        unitId: 547,
        systemId: 4,
        id: 'hhhhhhhhhhhhhh',
        name: '发动机844',
        location: '[0.04229198023676872, -0.04494265094399452, -0.09444963932037354]',
        vertices: 10675,
        polygons: 7426,
        lod: ['LOD_3'],
        name_work: 'dtl844',
        name_ua: 'Поршень',
        group: 1140101,
        tooltip: { cz: 'Píst', en: 'Piston', ua: 'Поршень', bg: 'Бутало' }
    },
    {
        unitId: 548,
        systemId: 4,
        id: 'bb1257e7-21a0-48c7-ada3-05d3cb7c036c',
        name: '发动机843',
        location: '[0.0422896184027195, -0.04494229331612587, -0.08452513813972473]',
        vertices: 27490,
        polygons: 16922,
        lod: ['LOD_2'],
        name_work: 'dtl843',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 549,
        systemId: 4,
        id: 'd6c9beaa-7b7e-40e3-a4f5-6ba468898177',
        name: '发动机842',
        location: '[0.04228702187538147, -0.04493609443306923, -0.08367276191711426]',
        vertices: 1173,
        polygons: 970,
        lod: ['LOD_2'],
        name_work: 'dtl842',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 550,
        systemId: 4,
        id: '5b48cb40-6a6b-42c4-879b-642e3351a8c1',
        name: '发动机841',
        location: '[0.042293012142181396, -0.04493573680520058, -0.08537846803665161]',
        vertices: 1194,
        polygons: 970,
        lod: ['LOD_2'],
        name_work: 'dtl841',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 551,
        systemId: 4,
        id: 'aee54588-2e5a-4c0b-8690-131574976713',
        name: '发动机840',
        location: '[0.04229053109884262, -0.044941578060388565, -0.07687518000602722]',
        vertices: 571,
        polygons: 404,
        lod: ['LOD_2'],
        name_work: 'dtl840',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 552,
        systemId: 4,
        id: '3f550967-5527-410d-9b33-ba7d30ad5b11',
        name: '发动机839',
        location: '[0.04229141026735306, -0.044943127781152725, -0.08092448115348816]',
        vertices: 913,
        polygons: 712,
        lod: ['LOD_2'],
        name_work: 'dtl839',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 553,
        systemId: 4,
        id: '3e64ca2c-f6ae-482b-8a69-b24b1da2092e',
        name: '发动机838',
        location: '[0.042288873344659805, -0.04563203826546669, -0.09703409671783447]',
        vertices: 593,
        polygons: 460,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl838',
        name_ua: 'Палець поршня',
        group: 1140104,
        tooltip: { cz: 'Pístní čep', en: 'Piston pin', ua: 'Палець поршня', bg: 'Бутален болт' }
    },
    {
        unitId: 554,
        systemId: 4,
        id: '705000eb-38e4-4d8d-b09d-0e16192188b6',
        name: '发动机837',
        location: '[-0.1231122761964798, -0.04494265094399452, -0.02285942994058132]',
        vertices: 10686,
        polygons: 7426,
        lod: ['LOD_3'],
        name_work: 'dtl837',
        name_ua: 'Поршень',
        group: 1140101,
        tooltip: { cz: 'Píst', en: 'Piston', ua: 'Поршень', bg: 'Бутало' }
    },
    {
        unitId: 555,
        systemId: 4,
        id: '1b547cb6-4fb2-4acc-9f40-ccebdc9213b9',
        name: '发动机836',
        location: '[-0.12311142683029175, -0.044943127781152725, -0.012934810481965542]',
        vertices: 27429,
        polygons: 16922,
        lod: ['LOD_2'],
        name_work: 'dtl836',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 556,
        systemId: 4,
        id: 'c01f71db-e307-4094-b047-7aaffea01e2b',
        name: '发动机835',
        location: '[-0.1231156587600708, -0.04493609443306923, -0.012082225643098354]',
        vertices: 1190,
        polygons: 970,
        lod: ['LOD_2'],
        name_work: 'dtl835',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 557,
        systemId: 4,
        id: '0bbd48d6-8c96-4ee8-9cff-2475b4e03a0f',
        name: '发动机834',
        location: '[-0.1231081485748291, -0.04493490234017372, -0.013787931762635708]',
        vertices: 1168,
        polygons: 970,
        lod: ['LOD_2'],
        name_work: 'dtl834',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 558,
        systemId: 4,
        id: '4516ff53-5852-4656-b195-d47c411feb61',
        name: '发动机833',
        location: '[-0.12311215698719025, -0.044941458851099014, -0.005285089835524559]',
        vertices: 574,
        polygons: 404,
        lod: ['LOD_2'],
        name_work: 'dtl833',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 559,
        systemId: 4,
        id: '1322cde3-d558-427d-8d77-5e0314456741',
        name: '发动机832',
        location: '[-0.12311135232448578, -0.044941458851099014, -0.009334034286439419]',
        vertices: 891,
        polygons: 712,
        lod: ['LOD_2'],
        name_work: 'dtl832',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 560,
        systemId: 4,
        id: '61770b7e-0081-431a-b8b7-518439cecc5d',
        name: '发动机831',
        location: '[-0.1231122761964798, -0.04563060775399208, -0.02544388733804226]',
        vertices: 585,
        polygons: 460,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl831',
        name_ua: 'Палець поршня',
        group: 1140104,
        tooltip: { cz: 'Pístní čep', en: 'Piston pin', ua: 'Палець поршня', bg: 'Бутален болт' }
    },
    {
        unitId: 561,
        systemId: 4,
        id: '7b76e073-649b-4241-9f15-5d5d796b3ad7',
        name: '发动机830',
        location: '[-0.04041013494133949, -0.04494265094399452, -0.09444931149482727]',
        vertices: 10705,
        polygons: 7426,
        lod: ['LOD_3'],
        name_work: 'dtl830',
        name_ua: 'Поршень',
        group: 1140101,
        tooltip: { cz: 'Píst', en: 'Piston', ua: 'Поршень', bg: 'Бутало' }
    },
    {
        unitId: 562,
        systemId: 4,
        id: 'ce4412b6-8862-4ace-a0aa-b07a89f93370',
        name: '发动机829',
        location: '[-0.04041093960404396, -0.04494229331612587, -0.0845249593257904]',
        vertices: 27378,
        polygons: 16922,
        lod: ['LOD_2'],
        name_work: 'dtl829',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 563,
        systemId: 4,
        id: '0f583f14-4023-4b6d-aed9-e898148b373d',
        name: '发动机828',
        location: '[-0.04041507467627525, -0.04493609443306923, -0.08367252349853516]',
        vertices: 1201,
        polygons: 970,
        lod: ['LOD_2'],
        name_work: 'dtl828',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 564,
        systemId: 4,
        id: '56d7a5df-8263-469d-b842-0716864ec28a',
        name: '发动机827',
        location: '[-0.040407564491033554, -0.04493573680520058, -0.08537814021110535]',
        vertices: 1170,
        polygons: 970,
        lod: ['LOD_2'],
        name_work: 'dtl827',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 565,
        systemId: 4,
        id: '553702a1-27e6-44f0-a09f-143d7893c3a3',
        name: '发动机826',
        location: '[-0.04041158780455589, -0.044941578060388565, -0.07687491178512573]',
        vertices: 561,
        polygons: 404,
        lod: ['LOD_2'],
        name_work: 'dtl826',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 566,
        systemId: 4,
        id: 'f8636413-5c9a-4236-9c27-02c07a75f107',
        name: '发动机825',
        location: '[-0.0404122956097126, -0.044943127781152725, -0.08092421293258667]',
        vertices: 891,
        polygons: 712,
        lod: ['LOD_2'],
        name_work: 'dtl825',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 567,
        systemId: 4,
        id: 'dd90f7bf-056b-4d70-b975-2bcb5228e68c',
        name: '发动机824',
        location: '[-0.0404101200401783, -0.04563203826546669, -0.09703376889228821]',
        vertices: 582,
        polygons: 460,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl824',
        name_ua: 'Палець поршня',
        group: 1140104,
        tooltip: { cz: 'Pístní čep', en: 'Piston pin', ua: 'Палець поршня', bg: 'Бутален болт' }
    },
    {
        unitId: 568,
        systemId: 4,
        id: 'af2ee571-191f-412e-b3f1-9d1838262ea2',
        name: '发动机823',
        location: '[-0.0404101200401783, -0.045234475284814835, -0.16972176730632782]',
        vertices: 3670,
        polygons: 2690,
        lod: ['LOD_3'],
        name_work: 'dtl823',
        name_ua: 'Шатун',
        group: 1141202,
        tooltip: { cz: 'Ojnice', en: 'Connecting rod', ua: 'Шатун', bg: 'Свързващ прът' }
    },
    {
        unitId: 569,
        systemId: 4,
        id: '85577cbc-1214-46fd-adc6-91e3532ee6e6',
        name: '发动机822',
        location: '[-0.040410082787275314, -0.044942889362573624, -0.23375847935676575]',
        vertices: 281,
        polygons: 190,
        lod: ['LOD_2'],
        name_work: 'dtl822',
        name_ua: 'Вкладиш шатунний',
        group: 1140203,
        tooltip: { cz: 'Ložisko ojnice', en: 'Connecting rod bearing', ua: 'Вкладиш шатунний', bg: 'Поставете биелата' }
    },
    {
        unitId: 570,
        systemId: 4,
        id: '1308948c-98a4-4be2-abf4-f7d66fd12cf4',
        name: '发动机821',
        location: '[-0.040410157293081284, -0.07119134068489075, -0.2280011922121048]',
        vertices: 816,
        polygons: 558,
        lod: ['LOD_3'],
        name_work: 'dtl821',
        name_ua: 'Болт кріплення бугеля шатуна',
        group: 1141205,
        tooltip: {
            cz: 'Šroub pro montáž víka ojnice',
            en: 'Bolt for mounting the connecting rod cap',
            ua: 'Болт кріплення бугеля шатуна',
            bg: 'Болт за закрепване на бугела на шатун'
        }
    },
    {
        unitId: 571,
        systemId: 4,
        id: 'e8e05fe1-a566-452a-99e0-377b984da6cc',
        name: '发动机820',
        location: '[-0.040410127490758896, -0.018641861155629158, -0.22771386802196503]',
        vertices: 816,
        polygons: 558,
        lod: ['LOD_3'],
        name_work: 'dtl820',
        name_ua: 'Болт кріплення бугеля шатуна',
        group: 1141205,
        tooltip: {
            cz: 'Šroub pro montáž víka ojnice',
            en: 'Bolt for mounting the connecting rod cap',
            ua: 'Болт кріплення бугеля шатуна',
            bg: 'Болт за закрепване на бугела на шатун'
        }
    },
    {
        unitId: 572,
        systemId: 4,
        id: 'cab509d5-a98d-49e9-910b-fcb6a0f8e600',
        name: '发动机819',
        location: '[-0.040410082787275314, -0.04494265094399452, -0.21222130954265594]',
        vertices: 282,
        polygons: 190,
        lod: ['LOD_2'],
        name_work: 'dtl819',
        name_ua: 'Вкладиш шатунний',
        group: 1140203,
        tooltip: { cz: 'Ložisko ojnice', en: 'Connecting rod bearing', ua: 'Вкладиш шатунний', bg: 'Поставете биелата' }
    },
    {
        unitId: 573,
        systemId: 4,
        id: '4a813583-d547-42d7-9568-e7631190d174',
        name: '发动机818',
        location: '[0.04229316487908363, -0.04523221030831337, -0.1697208434343338]',
        vertices: 3731,
        polygons: 2690,
        lod: ['LOD_3'],
        name_work: 'dtl818',
        name_ua: 'Шатун',
        group: 1141202,
        tooltip: { cz: 'Ojnice', en: 'Connecting rod', ua: 'Шатун', bg: 'Свързващ прът' }
    },
    {
        unitId: 574,
        systemId: 4,
        id: '0be5912e-4198-4152-9673-77030dfb05f7',
        name: '发动机817',
        location: '[0.04229200258851051, -0.04494050517678261, -0.2337574064731598]',
        vertices: 285,
        polygons: 190,
        lod: ['LOD_2'],
        name_work: 'dtl817',
        name_ua: 'Вкладиш шатунний',
        group: 1140203,
        tooltip: { cz: 'Ložisko ojnice', en: 'Connecting rod bearing', ua: 'Вкладиш шатунний', bg: 'Поставете биелата' }
    },
    {
        unitId: 575,
        systemId: 4,
        id: '6c7ad052-3b64-49a3-9cc1-1bfb5c4fdc1e',
        name: '发动机816',
        location: '[0.04229201748967171, -0.07118931412696838, -0.22800026834011078]',
        vertices: 821,
        polygons: 558,
        lod: ['LOD_3'],
        name_work: 'dtl816',
        name_ua: 'Болт кріплення бугеля шатуна',
        group: 1141205,
        tooltip: {
            cz: 'Šroub pro montáž víka ojnice',
            en: 'Bolt for mounting the connecting rod cap',
            ua: 'Болт кріплення бугеля шатуна',
            bg: 'Болт за закрепване на бугела на шатун'
        }
    },
    {
        unitId: 576,
        systemId: 4,
        id: '9a513679-5ca8-4514-b2c3-899faf934b2e',
        name: '发动机815',
        location: '[0.042292047291994095, -0.018639715388417244, -0.227712944149971]',
        vertices: 821,
        polygons: 558,
        lod: ['LOD_3'],
        name_work: 'dtl815',
        name_ua: 'Болт кріплення бугеля шатуна',
        group: 1141205,
        tooltip: {
            cz: 'Šroub pro montáž víka ojnice',
            en: 'Bolt for mounting the connecting rod cap',
            ua: 'Болт кріплення бугеля шатуна',
            bg: 'Болт за закрепване на бугела на шатун'
        }
    },
    {
        unitId: 577,
        systemId: 4,
        id: 'ac72fb44-99a1-4179-b2b7-68de12e6a829',
        name: '发动机814',
        location: '[0.04229207709431648, -0.04494038596749306, -0.21222038567066193]',
        vertices: 285,
        polygons: 190,
        lod: ['LOD_2'],
        name_work: 'dtl814',
        name_ua: 'Вкладиш шатунний',
        group: 1140203,
        tooltip: { cz: 'Ložisko ojnice', en: 'Connecting rod bearing', ua: 'Вкладиш шатунний', bg: 'Поставете биелата' }
    },
    {
        unitId: 578,
        systemId: 4,
        id: 'c5a9aa68-d08c-4b53-b743-c0ad2ab37c90',
        name: '发动机813',
        location: '[-0.12311218678951263, -0.044942889362573624, -0.16216929256916046]',
        vertices: 283,
        polygons: 190,
        lod: ['LOD_2'],
        name_work: 'dtl813',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 579,
        systemId: 4,
        id: '9d76fb34-39c8-4318-b8ff-e49d9714b3ae',
        name: '发动机812',
        location: '[-0.12311224639415741, -0.018641861155629158, -0.15612466633319855]',
        vertices: 823,
        polygons: 558,
        lod: ['LOD_3'],
        name_work: 'dtl812',
        name_ua: 'Болт кріплення бугеля шатуна',
        group: 1141205,
        tooltip: {
            cz: 'Šroub pro montáž víka ojnice',
            en: 'Bolt for mounting the connecting rod cap',
            ua: 'Болт кріплення бугеля шатуна',
            bg: 'Болт за закрепване на бугела на шатун'
        }
    },
    {
        unitId: 580,
        systemId: 4,
        id: 'd32f7847-aa33-47cb-a038-ddeb67dbecb2',
        name: '发动机811',
        location: '[-0.1231122761964798, -0.07119134068489075, -0.15641199052333832]',
        vertices: 823,
        polygons: 558,
        lod: ['LOD_3'],
        name_work: 'dtl811',
        name_ua: 'Болт кріплення бугеля шатуна',
        group: 1141205,
        tooltip: {
            cz: 'Šroub pro montáž víka ojnice',
            en: 'Bolt for mounting the connecting rod cap',
            ua: 'Болт кріплення бугеля шатуна',
            bg: 'Болт за закрепване на бугела на шатун'
        }
    },
    {
        unitId: 581,
        systemId: 4,
        id: '76c6d742-9ccc-42b1-8c24-f241be078b49',
        name: '发动机810',
        location: '[-0.1231122761964798, -0.04523423686623573, -0.0981324315071106]',
        vertices: 3701,
        polygons: 2690,
        lod: ['LOD_3'],
        name_work: 'dtl810',
        name_ua: 'Шатун',
        group: 1141202,
        tooltip: { cz: 'Ojnice', en: 'Connecting rod', ua: 'Шатун', bg: 'Свързващ прът' }
    },
    {
        unitId: 582,
        systemId: 4,
        id: '547733ba-bea1-4e35-8e4a-6fa71dc5e9c3',
        name: '发动机809',
        location: '[-0.12311223149299622, -0.04494265094399452, -0.1406322568655014]',
        vertices: 285,
        polygons: 190,
        lod: ['LOD_2'],
        name_work: 'dtl809',
        name_ua: 'Вкладиш шатунний',
        group: 1140203,
        tooltip: { cz: 'Ložisko ojnice', en: 'Connecting rod bearing', ua: 'Вкладиш шатунний', bg: 'Поставете биелата' }
    },
    {
        unitId: 583,
        systemId: 4,
        id: 'be2d5b7e-79d1-404a-92be-68a6e5d83890',
        name: '发动机808',
        location: '[0.12477374076843262, -0.044804129749536514, -0.14063172042369843]',
        vertices: 302,
        polygons: 190,
        lod: ['LOD_2'],
        name_work: 'dtl808',
        name_ua: 'Вкладиш шатунний',
        group: 1140203,
        tooltip: { cz: 'Ložisko ojnice', en: 'Connecting rod bearing', ua: 'Вкладиш шатунний', bg: 'Поставете биелата' }
    },
    {
        unitId: 584,
        systemId: 4,
        id: 'be62fcb2-5d1a-47df-a1cd-4a4a099ff88f',
        name: '发动机807',
        location: '[0.12477263063192368, -0.07110550999641418, -0.1561240404844284]',
        vertices: 813,
        polygons: 558,
        lod: ['LOD_3'],
        name_work: 'dtl807',
        name_ua: 'Болт кріплення бугеля шатуна',
        group: 1141205,
        tooltip: {
            cz: 'Šroub pro montáž víka ojnice',
            en: 'Bolt for mounting the connecting rod cap',
            ua: 'Болт кріплення бугеля шатуна',
            bg: 'Болт за закрепване на бугела на шатун'
        }
    },
    {
        unitId: 585,
        systemId: 4,
        id: '97654e3a-7bab-4755-9f1d-2259a5eaba4f',
        name: '发动机806',
        location: '[0.12477263063192368, -0.018557580187916756, -0.15641169250011444]',
        vertices: 813,
        polygons: 558,
        lod: ['LOD_3'],
        name_work: 'dtl806',
        name_ua: 'Болт кріплення бугеля шатуна',
        group: 1141205,
        tooltip: {
            cz: 'Šroub pro montáž víka ojnice',
            en: 'Bolt for mounting the connecting rod cap',
            ua: 'Болт кріплення бугеля шатуна',
            bg: 'Болт за закрепване на бугела на шатун'
        }
    },
    {
        unitId: 586,
        systemId: 4,
        id: '43373dab-98c4-4058-bdbc-86f703aa2c3b',
        name: '发动机805',
        location: '[0.12477760761976242, -0.04451313987374306, -0.09813195466995239]',
        vertices: 3725,
        polygons: 2690,
        lod: ['LOD_3'],
        name_work: 'dtl805',
        name_ua: 'Шатун',
        group: 1141202,
        tooltip: { cz: 'Ojnice', en: 'Connecting rod', ua: 'Шатун', bg: 'Свързващ прът' }
    },
    {
        unitId: 587,
        systemId: 4,
        id: '50699115-b372-4cad-93bb-a34f8b540300',
        name: '发动机804',
        location: '[0.12477189302444458, -0.044805679470300674, -0.16216881573200226]',
        vertices: 300,
        polygons: 190,
        lod: ['LOD_2'],
        name_work: 'dtl804',
        name_ua: 'Вкладиш шатунний',
        group: 1140203,
        tooltip: { cz: 'Ložisko ojnice', en: 'Connecting rod bearing', ua: 'Вкладиш шатунний', bg: 'Поставете биелата' }
    },
    {
        unitId: 588,
        systemId: 4,
        id: 'e365138a-bacb-4000-9b41-65224e040101',
        name: '发动机803',
        location: '[-0.13961197435855865, -0.029421718791127205, 0.010048204101622105]',
        vertices: 763,
        polygons: 576,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl803',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 589,
        systemId: 4,
        id: '3ac15b8b-5600-4f33-88be-214f50fe8112',
        name: '发动机802',
        location: '[-0.05690982565283775, -0.0294210035353899, 0.010048204101622105]',
        vertices: 762,
        polygons: 576,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl802',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 590,
        systemId: 4,
        id: '90f7313f-9a9e-453d-a44d-ade9cd4a0ae5',
        name: '发动机801',
        location: '[0.025792285799980164, -0.029421838000416756, 0.010048204101622105]',
        vertices: 751,
        polygons: 576,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl801',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 591,
        systemId: 4,
        id: '0d2df6a6-1a36-4f07-a161-402f2a86f893',
        name: '发动机800',
        location: '[0.10849441587924957, -0.029421838000416756, 0.010048204101622105]',
        vertices: 762,
        polygons: 576,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl800',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 592,
        systemId: 4,
        id: '0c4b2f22-5d59-4026-9491-cdbe2e2c3afb',
        name: '发动机799',
        location: '[-0.10644324123859406, -0.029421718791127205, 0.010048204101622105]',
        vertices: 763,
        polygons: 576,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl799',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 593,
        systemId: 4,
        id: 'd2bcb528-ed35-4b97-922b-0233a43d8480',
        name: '发动机798',
        location: '[-0.023741109296679497, -0.029421718791127205, 0.010048204101622105]',
        vertices: 762,
        polygons: 576,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl798',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 594,
        systemId: 4,
        id: '5e81757f-57a7-4843-a3fe-e001cbcb2af3',
        name: '发动机797',
        location: '[0.05895945057272911, -0.029421838000416756, 0.010048204101622105]',
        vertices: 762,
        polygons: 576,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl797',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 595,
        systemId: 4,
        id: '68872d5c-fba6-4333-8cdc-c280a75a2ade',
        name: '发动机796',
        location: '[0.1416631042957306, -0.029421838000416756, 0.010048204101622105]',
        vertices: 763,
        polygons: 576,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl796',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 596,
        systemId: 4,
        id: 'ecf0b82b-68fe-43be-864f-4dfcd0ada9a7',
        name: '发动机795',
        location: '[-0.13961093127727509, -0.015145453624427319, 0.05119340866804123]',
        vertices: 541,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl795',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 597,
        systemId: 4,
        id: 'abe2dc64-77c9-42d3-91e9-b6ef96970a86',
        name: '发动机794',
        location: '[-0.05690881982445717, -0.01514569204300642, 0.05119340866804123]',
        vertices: 545,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl794',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 598,
        systemId: 4,
        id: '8d9f1cad-53c8-4942-a412-d9ee4e12d8f0',
        name: '发动机793',
        location: '[0.02579331584274769, -0.01514557283371687, 0.05119340866804123]',
        vertices: 548,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl793',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 599,
        systemId: 4,
        id: '76164c36-18f4-42a3-9704-0a2f7d683dba',
        name: '发动机792',
        location: '[-0.1064453274011612, -0.015145453624427319, 0.05119340866804123]',
        vertices: 541,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl792',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 600,
        systemId: 4,
        id: 'a6f855a3-d793-446d-ac56-835601b34127',
        name: '发动机791',
        location: '[-0.02374165691435337, -0.01514557283371687, 0.05119340866804123]',
        vertices: 545,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl791',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 601,
        systemId: 4,
        id: 'c53990ae-c8a0-483d-8467-fd8717b97b73',
        name: '发动机790',
        location: '[0.05896203592419624, -0.01514557283371687, 0.05119340866804123]',
        vertices: 545,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl790',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 602,
        systemId: 4,
        id: '0a5dce52-ef24-4ff5-b7df-444fbaa959f0',
        name: '发动机789',
        location: '[0.14166103303432465, -0.01514569204300642, 0.05119340866804123]',
        vertices: 541,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl789',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 603,
        systemId: 4,
        id: '0187ae99-c833-43c3-94d2-7748324e6fc7',
        name: '发动机788',
        location: '[0.10849699378013611, -0.01514569204300642, 0.05119340866804123]',
        vertices: 545,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl788',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 604,
        systemId: 4,
        id: '0eb47b1f-7519-42fd-826a-bf1264c908d6',
        name: '发动机787',
        location: '[-0.1389659196138382, -0.07912781834602356, 0.05160742253065109]',
        vertices: 531,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl787',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 605,
        systemId: 4,
        id: '78c87256-a8c1-43ca-8f5d-4f03900b17f7',
        name: '发动机786',
        location: '[-0.05626380816102028, -0.07912781834602356, 0.05160742253065109]',
        vertices: 531,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl786',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 606,
        systemId: 4,
        id: 'c45dbf33-9aaf-4a76-a16c-c6a69e2fbc85',
        name: '发动机785',
        location: '[0.026439888402819633, -0.07912781834602356, 0.05160742253065109]',
        vertices: 537,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl785',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 607,
        systemId: 4,
        id: 'a9873107-2850-42d2-8b88-f4345acc832e',
        name: '发动机784',
        location: '[0.10914354771375656, -0.07912781834602356, 0.05160742253065109]',
        vertices: 531,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl784',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 608,
        systemId: 4,
        id: '9530665d-5b7b-41e1-8452-8a92067e64e9',
        name: '发动机783',
        location: '[-0.1070903092622757, -0.07912781834602356, 0.05160742253065109]',
        vertices: 531,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl783',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 609,
        systemId: 4,
        id: '0ca7a25b-d7f9-4e0a-90e3-797c4d5134e7',
        name: '发动机782',
        location: '[-0.024388207122683525, -0.07912781834602356, 0.05160742253065109]',
        vertices: 531,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl782',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 610,
        systemId: 4,
        id: 'e05595df-d2a6-47d2-8d39-dbf3374a601f',
        name: '发动机781',
        location: '[0.05831550434231758, -0.07912781834602356, 0.05160742253065109]',
        vertices: 531,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl781',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 611,
        systemId: 4,
        id: '12953a52-a71f-49f6-a50d-16cb0d02b554',
        name: '发动机780',
        location: '[0.14101606607437134, -0.07912793755531311, 0.05160742253065109]',
        vertices: 531,
        polygons: 384,
        lod: ['LOD_2'],
        name_work: 'dtl780',
        name_ua: 'Втулка клапана направляюча',
        group: 1140507,
        tooltip: {
            cz: 'Objímka vodícího ventilu',
            en: 'Valve guide bushing',
            ua: 'Втулка клапана направляюча',
            bg: 'Втулка на клапана'
        }
    },
    {
        unitId: 612,
        systemId: 4,
        id: '93013a9c-2f6e-4dff-bdef-2f9b814fc81c',
        name: '发动机779',
        location: '[0.14101606607437134, -0.06387367844581604, 0.009988241828978062]',
        vertices: 485,
        polygons: 324,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl779',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 613,
        systemId: 4,
        id: 'bb009fab-52bf-442e-b85e-aa2b0be6f75d',
        name: '发动机778',
        location: '[0.10914354771375656, -0.06387355923652649, 0.009988241828978062]',
        vertices: 485,
        polygons: 324,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl778',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 614,
        systemId: 4,
        id: 'e7912cd9-0c80-48ee-978b-3b32e9cc3252',
        name: '发动机777',
        location: '[0.0583154633641243, -0.06387355923652649, 0.009988241828978062]',
        vertices: 490,
        polygons: 324,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl777',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 615,
        systemId: 4,
        id: '296ad055-6b8f-4217-8a79-cf5f9878923a',
        name: '发动机776',
        location: '[0.0264398492872715, -0.06387355923652649, 0.009988241828978062]',
        vertices: 486,
        polygons: 324,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl776',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 616,
        systemId: 4,
        id: 'cfc007b8-0f4f-4041-abf7-e910e7edb21b',
        name: '发动机775',
        location: '[-0.024388207122683525, -0.06387355923652649, 0.009988241828978062]',
        vertices: 485,
        polygons: 324,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl775',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 617,
        systemId: 4,
        id: 'eb532c06-bd53-41eb-aaa7-4c8c43b51804',
        name: '发动机774',
        location: '[-0.05626380816102028, -0.06387355923652649, 0.009988241828978062]',
        vertices: 485,
        polygons: 324,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl774',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 618,
        systemId: 4,
        id: '7340c0a5-1fff-413f-b7e2-e29c01ce9d73',
        name: '发动机773',
        location: '[-0.1070903092622757, -0.06387355923652649, 0.009988241828978062]',
        vertices: 485,
        polygons: 324,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl773',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 619,
        systemId: 4,
        id: '218e3a12-2721-4316-a871-7ab40b88f186',
        name: '发动机772',
        location: '[-0.1389659196138382, -0.06387355923652649, 0.009988241828978062]',
        vertices: 485,
        polygons: 324,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl772',
        name_ua: 'Сідло клапана',
        group: 1140510,
        tooltip: { cz: 'Sedlo ventilu', en: 'Valve seat', ua: 'Сідло клапана', bg: 'Седло клапана' }
    },
    {
        unitId: 620,
        systemId: 4,
        id: '9e190138-c6c2-435e-b377-90e82a1dce28',
        name: '发动机771',
        location: '[-0.1396140456199646, -0.01109138410538435, 0.06296747922897339]',
        vertices: 901,
        polygons: 696,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl771',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 621,
        systemId: 4,
        id: '64a76461-7a1c-4987-985b-eaf45d99ae18',
        name: '发动机770',
        location: '[-0.1396140456199646, -0.01067033689469099, 0.06418919563293457]',
        vertices: 1934,
        polygons: 1512,
        lod: ['LOD_1'],
        name_work: 'dtl770',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 622,
        systemId: 4,
        id: '12045e2a-66f8-4acf-8f36-1caa0bdde612',
        name: '发动机769',
        location: '[-0.1396140456199646, -0.008551868610084057, 0.07034170627593994]',
        vertices: 748,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl769',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 623,
        systemId: 4,
        id: 'c6ecf8e2-0617-48d8-9a52-c1b95ecc4c5c',
        name: '发动机768',
        location: '[-0.10644686222076416, -0.011090668849647045, 0.06296747922897339]',
        vertices: 928,
        polygons: 696,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl768',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 624,
        systemId: 4,
        id: 'af8f0c25-06a2-45a2-853a-1a00d4756b43',
        name: '发动机767',
        location: '[-0.10644686222076416, -0.010669621638953686, 0.06418919563293457]',
        vertices: 1930,
        polygons: 1512,
        lod: ['LOD_1'],
        name_work: 'dtl767',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 625,
        systemId: 4,
        id: 'b92ff5c5-7f8a-4a0d-9c15-c1def8c8af1b',
        name: '发动机766',
        location: '[-0.10644686222076416, -0.008551034145057201, 0.07034170627593994]',
        vertices: 746,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl766',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 626,
        systemId: 4,
        id: 'c6da7cc4-9d31-47a8-8619-cac491ffefad',
        name: '发动机765',
        location: '[-0.056911904364824295, -0.011090668849647045, 0.06296747922897339]',
        vertices: 924,
        polygons: 696,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl765',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 627,
        systemId: 4,
        id: '0bbc9c4a-5bb4-4faa-8ff5-a3e6ff1582dd',
        name: '发动机764',
        location: '[-0.056911904364824295, -0.010669621638953686, 0.06418919563293457]',
        vertices: 1930,
        polygons: 1512,
        lod: ['LOD_1'],
        name_work: 'dtl764',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 628,
        systemId: 4,
        id: '74e6ead2-d9de-4ac9-9735-5fbd53395e0b',
        name: '发动机763',
        location: '[-0.056911904364824295, -0.008551034145057201, 0.07034170627593994]',
        vertices: 746,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl763',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 629,
        systemId: 4,
        id: '9851390a-515c-4596-821f-d30bbd2eb677',
        name: '发动机762',
        location: '[-0.02374630607664585, -0.011090668849647045, 0.06296747922897339]',
        vertices: 924,
        polygons: 696,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl762',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 630,
        systemId: 4,
        id: '6b28d885-5d4b-4bb8-ac5e-8910fa1a2761',
        name: '发动机761',
        location: '[-0.02374630607664585, -0.010669502429664135, 0.06418919563293457]',
        vertices: 1930,
        polygons: 1512,
        lod: ['LOD_1'],
        name_work: 'dtl761',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 631,
        systemId: 4,
        id: 'fe6010fa-eeee-4eed-ad54-ab20a5254c22',
        name: '发动机760',
        location: '[-0.02374630607664585, -0.00855091493576765, 0.07034170627593994]',
        vertices: 748,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl760',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 632,
        systemId: 4,
        id: 'b7737271-4f09-4120-977d-dacdf6be33a9',
        name: '发动机759',
        location: '[0.025788642466068268, -0.011090668849647045, 0.06296747922897339]',
        vertices: 928,
        polygons: 696,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl759',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 633,
        systemId: 4,
        id: '88b94cf7-cff4-4383-b8d9-8c815f23eced',
        name: '发动机758',
        location: '[0.02578866109251976, -0.010669502429664135, 0.06418919563293457]',
        vertices: 1936,
        polygons: 1512,
        lod: ['LOD_1'],
        name_work: 'dtl758',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 634,
        systemId: 4,
        id: 'c48da94f-f69d-468a-b9cb-c633dd274cb7',
        name: '发动机757',
        location: '[0.02578866109251976, -0.008551034145057201, 0.07034170627593994]',
        vertices: 748,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl757',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 635,
        systemId: 4,
        id: '5452185e-659f-4c62-8094-ea75dd0a5311',
        name: '发动机756',
        location: '[0.05895736441016197, -0.011090668849647045, 0.06296747922897339]',
        vertices: 924,
        polygons: 696,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl756',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 636,
        systemId: 4,
        id: 'c14f7f97-ef07-4046-9499-aaadafda6299',
        name: '发动机755',
        location: '[0.05895736441016197, -0.010669502429664135, 0.06418919563293457]',
        vertices: 1930,
        polygons: 1512,
        lod: ['LOD_1'],
        name_work: 'dtl755',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 637,
        systemId: 4,
        id: 'e988a6d8-9bf6-4f9c-a246-611f6c4208e7',
        name: '发动机754',
        location: '[0.05895736441016197, -0.008551034145057201, 0.07034170627593994]',
        vertices: 748,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl754',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 638,
        systemId: 4,
        id: '0ac740da-09a9-4a7f-a7d9-e5aa489e3040',
        name: '发动机753',
        location: '[0.10849233716726303, -0.011090668849647045, 0.06296747922897339]',
        vertices: 924,
        polygons: 696,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl753',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 639,
        systemId: 4,
        id: '09ba7f7d-d4fa-4966-988b-be9614d111c4',
        name: '发动机752',
        location: '[0.10849233716726303, -0.010669502429664135, 0.06418919563293457]',
        vertices: 1930,
        polygons: 1512,
        lod: ['LOD_1'],
        name_work: 'dtl752',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 640,
        systemId: 4,
        id: '9b384a9e-b021-46f7-8666-41765ff98586',
        name: '发动机751',
        location: '[0.10849233716726303, -0.008551034145057201, 0.07034170627593994]',
        vertices: 746,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl751',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 641,
        systemId: 4,
        id: '36037a5f-8f65-4e6f-855f-9c578daae506',
        name: '发动机750',
        location: '[0.14165791869163513, -0.011090668849647045, 0.06296747922897339]',
        vertices: 928,
        polygons: 696,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl750',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 642,
        systemId: 4,
        id: '1623bc95-8576-47fa-a364-4533e219aa9f',
        name: '发动机749',
        location: '[0.14165791869163513, -0.010669502429664135, 0.06418919563293457]',
        vertices: 1930,
        polygons: 1512,
        lod: ['LOD_1'],
        name_work: 'dtl749',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 643,
        systemId: 4,
        id: '3a0f18ae-ca46-4ab5-b5b2-924761e9770c',
        name: '发动机748',
        location: '[0.14165791869163513, -0.008551034145057201, 0.07034170627593994]',
        vertices: 746,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl748',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 644,
        systemId: 4,
        id: 'bcc0b8d8-8a84-4ee1-b919-a3253777e397',
        name: '发动机747',
        location: '[-0.13896584510803223, -0.08338156342506409, 0.06330466270446777]',
        vertices: 1032,
        polygons: 738,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl747',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 645,
        systemId: 4,
        id: 'd4f37eaf-895c-4ecf-a721-ddce420de3b1',
        name: '发动机746',
        location: '[-0.13896401226520538, -0.08468332886695862, 0.06689172983169556]',
        vertices: 2139,
        polygons: 1556,
        lod: ['LOD_1'],
        name_work: 'dtl746',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 646,
        systemId: 4,
        id: '80a927a1-94ce-4ce0-98a0-aa21db27ead1',
        name: '发动机745',
        location: '[-0.1389659196138382, -0.08610263466835022, 0.07079720497131348]',
        vertices: 731,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl745',
        name_ua: 'Сальник клапана',
        group: 1140802,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 647,
        systemId: 4,
        id: '3a9025b0-c27c-4219-a8bb-36128e5beaf3',
        name: '发动机744',
        location: '[-0.05626373365521431, -0.08338168263435364, 0.06330466270446777]',
        vertices: 1032,
        polygons: 738,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl744',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 648,
        systemId: 4,
        id: '94edc1bd-f0da-4a2e-a094-60e7c242f033',
        name: '发动机743',
        location: '[-0.056261900812387466, -0.08468332886695862, 0.06689172983169556]',
        vertices: 2136,
        polygons: 1556,
        lod: ['LOD_1'],
        name_work: 'dtl743',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 649,
        systemId: 4,
        id: '30d11bf8-762f-4c8a-bb16-953cbdc3fe6e',
        name: '发动机742',
        location: '[-0.05626380816102028, -0.08610263466835022, 0.07079720497131348]',
        vertices: 731,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl742',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 650,
        systemId: 4,
        id: '3a66cf04-08a8-490f-bcd7-dde8d2dc8328',
        name: '发动机741',
        location: '[-0.024388128891587257, -0.08338168263435364, 0.06330466270446777]',
        vertices: 1032,
        polygons: 738,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl741',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 651,
        systemId: 4,
        id: '5cc17244-d991-4117-8b8f-674480f02ea9',
        name: '发动机740',
        location: '[-0.024387823417782784, -0.08468332886695862, 0.06689172983169556]',
        vertices: 2136,
        polygons: 1556,
        lod: ['LOD_1'],
        name_work: 'dtl740',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 652,
        systemId: 4,
        id: 'a245990a-a946-4c4f-8813-1a09f85ae2fb',
        name: '发动机739',
        location: '[-0.024388207122683525, -0.08610263466835022, 0.07079720497131348]',
        vertices: 731,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl739',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 653,
        systemId: 4,
        id: 'd4ac12a2-42b2-488f-b4ba-b2477d77a946',
        name: '发动机738',
        location: '[0.026438437402248383, -0.08338168263435364, 0.06330466270446777]',
        vertices: 1032,
        polygons: 738,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl738',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 654,
        systemId: 4,
        id: '9b44b248-934e-4320-bbbf-0bb7a86c6caf',
        name: '发动机737',
        location: '[0.026440268382430077, -0.08468332886695862, 0.06689172983169556]',
        vertices: 2133,
        polygons: 1556,
        lod: ['LOD_1'],
        name_work: 'dtl737',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 655,
        systemId: 4,
        id: '51dac5d9-e375-4878-a2fd-ab9f33218ab4',
        name: '发动机736',
        location: '[0.026439888402819633, -0.08610263466835022, 0.07079720497131348]',
        vertices: 731,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl736',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 656,
        systemId: 4,
        id: '17978981-72cc-4cdd-8dd7-ff59637b8501',
        name: '发动机735',
        location: '[0.05831405147910118, -0.08338168263435364, 0.06330466270446777]',
        vertices: 1032,
        polygons: 738,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl735',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 657,
        systemId: 4,
        id: '0b413721-a24f-4da7-bbf2-f9b9aec6905f',
        name: '发动机734',
        location: '[0.058315884321928024, -0.08468332886695862, 0.06689172983169556]',
        vertices: 2136,
        polygons: 1556,
        lod: ['LOD_1'],
        name_work: 'dtl734',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 658,
        systemId: 4,
        id: 'fe39014b-a551-4252-b1e5-1754f11d318f',
        name: '发动机733',
        location: '[0.05831550434231758, -0.08610263466835022, 0.07079720497131348]',
        vertices: 731,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl733',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 659,
        systemId: 4,
        id: 'b70d53ce-f819-4d1e-9547-df347de82423',
        name: '发动机732',
        location: '[0.10914205759763718, -0.08338168263435364, 0.06330466270446777]',
        vertices: 1032,
        polygons: 738,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl732',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 660,
        systemId: 4,
        id: '193e52be-dbd8-458e-810b-152f8b589d01',
        name: '发动机731',
        location: '[0.10914236307144165, -0.08468332886695862, 0.06689172983169556]',
        vertices: 2136,
        polygons: 1556,
        lod: ['LOD_1'],
        name_work: 'dtl731',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 661,
        systemId: 4,
        id: 'afeb8ecd-1201-4df4-8147-0f3022734998',
        name: '发动机730',
        location: '[0.10914351046085358, -0.08610263466835022, 0.07079720497131348]',
        vertices: 731,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl730',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 662,
        systemId: 4,
        id: '7be27a6f-6eac-4427-9d0d-fa15fb06f982',
        name: '发动机729',
        location: '[0.14101773500442505, -0.08338168263435364, 0.06330466270446777]',
        vertices: 1032,
        polygons: 738,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl729',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 663,
        systemId: 4,
        id: 'b038e7e3-b7a7-4c80-967d-59a3d4514afb',
        name: '发动机728',
        location: '[0.14101484417915344, -0.08468332886695862, 0.06689172983169556]',
        vertices: 2139,
        polygons: 1556,
        lod: ['LOD_1'],
        name_work: 'dtl728',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 664,
        systemId: 4,
        id: 'a82571d4-29db-49a4-8dd3-ac66c902c07e',
        name: '发动机727',
        location: '[0.14101606607437134, -0.08610263466835022, 0.07079720497131348]',
        vertices: 731,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl727',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 665,
        systemId: 4,
        id: '079a7e56-3873-4d4c-aa2b-7447472fb13d',
        name: '发动机726',
        location: '[-0.10709016025066376, -0.08338156342506409, 0.06330466270446777]',
        vertices: 1032,
        polygons: 738,
        lod: ['LOD_2', 'LOD_1'],
        name_work: 'dtl726',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 666,
        systemId: 4,
        id: '82e90a89-aff2-4de6-9810-ca88dc16c2e6',
        name: '发动机725',
        location: '[-0.10709153115749359, -0.08468332886695862, 0.06689172983169556]',
        vertices: 2139,
        polygons: 1556,
        lod: ['LOD_1'],
        name_work: 'dtl725',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 667,
        systemId: 4,
        id: '5605f74d-02e9-42fe-9b50-d188b0dd57e0',
        name: '发动机724',
        location: '[-0.1070903092622757, -0.08610263466835022, 0.07079720497131348]',
        vertices: 731,
        polygons: 576,
        lod: ['LOD_1'],
        name_work: 'dtl724',
        name_ua: 'Сальник клапана',
        group: 1140411,
        tooltip: { cz: 'Těsnění ventilu', en: 'Valve seal', ua: 'Сальник клапана', bg: 'Уплътнение на клапана' }
    },
    {
        unitId: 668,
        systemId: 4,
        id: '1217eacb-d0d7-4d36-bd86-1f6b8b9b662a',
        name: '发动机723',
        location: '[-0.12302811443805695, -0.0473506785929203, 0.04155343025922775]',
        vertices: 3639,
        polygons: 2748,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl723',
        name_ua: 'Свічка',
        group: 1090100,
        tooltip: { cz: 'Světlo', en: 'Spark plug', ua: 'Свічка', bg: 'Запалителна свещ' }
    },
    {
        unitId: 669,
        systemId: 4,
        id: 'ac4abf9a-f440-4c78-9d90-c94e927a7ae7',
        name: '发动机722',
        location: '[-0.04032600298523903, -0.04735055938363075, 0.04155343025922775]',
        vertices: 3639,
        polygons: 2748,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl722',
        name_ua: 'Свічка',
        group: 1090100,
        tooltip: { cz: 'Světlo', en: 'Spark plug', ua: 'Свічка', bg: 'Запалителна свещ' }
    },
    {
        unitId: 670,
        systemId: 4,
        id: 'a179cf74-78a1-41cd-b5e7-0db5a5e287a0',
        name: '发动机721',
        location: '[0.04237767681479454, -0.0473506785929203, 0.04155343025922775]',
        vertices: 3639,
        polygons: 2748,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl721',
        name_ua: 'Свічка',
        group: 1090100,
        tooltip: { cz: 'Světlo', en: 'Spark plug', ua: 'Свічка', bg: 'Запалителна свещ' }
    },
    {
        unitId: 671,
        systemId: 4,
        id: 'cbc127a8-6e4c-4751-9894-775252689644',
        name: '发动机720',
        location: '[0.1250782310962677, -0.0473506785929203, 0.04155343025922775]',
        vertices: 3639,
        polygons: 2748,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl720',
        name_ua: 'Свічка',
        group: 1090100,
        tooltip: { cz: 'Světlo', en: 'Spark plug', ua: 'Свічка', bg: 'Запалителна свещ' }
    },
    {
        unitId: 672,
        systemId: 4,
        id: 'c82f028f-db0c-4833-ac93-ae50dace5d16',
        name: '发动机719',
        location: '[0.12500344216823578, -0.04494038596749306, -0.02285969816148281]',
        vertices: 10668,
        polygons: 7426,
        lod: ['LOD_3'],
        name_work: 'dtl719',
        name_ua: 'Поршень',
        group: 1140101,
        tooltip: { cz: 'Píst', en: 'Piston', ua: 'Поршень', bg: 'Бутало' }
    },
    {
        unitId: 673,
        systemId: 4,
        id: '8265dacb-4100-41b6-b4fe-fdf0da83deb9',
        name: '发动机718',
        location: '[0.2253425419330597, -0.18387272953987122, -0.14411742985248566]',
        vertices: 342,
        polygons: 200,
        lod: ['LOD_4'],
        name_work: 'dtl718',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 674,
        systemId: 4,
        id: '3a4b0461-ecb9-4aac-9db7-28289d208337',
        name: '发动机717',
        location: '[0.23162946105003357, -0.1838723123073578, -0.14411728084087372]',
        vertices: 1043,
        polygons: 980,
        lod: ['LOD_4'],
        name_work: 'dtl717',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 675,
        systemId: 4,
        id: 'a53fec69-5ee7-4365-baf7-112233887049',
        name: '发动机716',
        location: '[0.22458112239837646, -0.1838723123073578, -0.14411728084087372]',
        vertices: 6053,
        polygons: 4994,
        lod: ['LOD_4'],
        name_work: 'dtl716',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 676,
        systemId: 4,
        id: '3f0c6aa9-ea95-4045-aae8-f91d46351a0d',
        name: '发动机715',
        location: '[0.10849550366401672, -0.016220958903431892, 0.0480644628405571]',
        vertices: 923,
        polygons: 606,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl715',
        name_ua: 'Клапан впускний',
        group: 1140402,
        tooltip: { cz: 'Sací ventil', en: 'Intake valve', ua: 'Клапан впускний', bg: 'Входящ клапан' }
    },
    {
        unitId: 677,
        systemId: 4,
        id: '846b5f28-d025-4af7-bf6f-f8efb955d465',
        name: '发动机714',
        location: '[0.14166422188282013, -0.016220958903431892, 0.0480644628405571]',
        vertices: 910,
        polygons: 606,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl714',
        name_ua: 'Клапан впускний',
        group: 1140402,
        tooltip: { cz: 'Sací ventil', en: 'Intake valve', ua: 'Клапан впускний', bg: 'Входящ клапан' }
    },
    {
        unitId: 678,
        systemId: 4,
        id: '41e59354-20b3-47f2-944b-0c7eb6f13006',
        name: '发动机713',
        location: '[0.10892706364393234, -0.004492196254432201, 0.08213311433792114]',
        vertices: 939,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl713',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 679,
        systemId: 4,
        id: '683586cb-4bd7-4bc2-8f8e-facf5cd715f9',
        name: '发动机712',
        location: '[0.14123108983039856, -0.004492196254432201, 0.08213311433792114]',
        vertices: 939,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl712',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 680,
        systemId: 4,
        id: '6663754a-3ecc-46e5-a408-4c183284a16a',
        name: '发动机711',
        location: '[0.10849546641111374, -0.003273638663813472, 0.08567953109741211]',
        vertices: 915,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl711',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 681,
        systemId: 4,
        id: '0bbe91ed-4a5d-4fa2-be7b-1d5640b35a4d',
        name: '发动机710',
        location: '[0.14165952801704407, -0.003273638663813472, 0.08567953109741211]',
        vertices: 916,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl710',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 682,
        systemId: 4,
        id: 'f23afac3-7e8b-43a6-81ea-3bc7337904dc',
        name: '发动机709',
        location: '[0.14166414737701416, -0.003620776114985347, 0.08465814590454102]',
        vertices: 766,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl709',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 683,
        systemId: 4,
        id: '82648f1f-48fd-4060-ac06-0cf24e35b9cc',
        name: '发动机708',
        location: '[0.10849542915821075, -0.003619107184931636, 0.08465874195098877]',
        vertices: 766,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl708',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 684,
        systemId: 4,
        id: 'c3228079-e3f5-4d8a-96c5-a5c8a540dc9a',
        name: '发动机707',
        location: '[0.2227725088596344, -0.19130423665046692, -0.04760593920946121]',
        vertices: 5089,
        polygons: 3957,
        lod: ['LOD_4'],
        name_work: 'dtl707',
        name_ua: 'Обгінна муфта генератора',
        group: 1171400,
        tooltip: {
            cz: 'Poklop kluzného součásti generátoru',
            en: 'Generator overrunning clutch',
            ua: 'Обгінна муфта генератора',
            bg: 'Съединител за генератора'
        }
    },
    {
        unitId: 685,
        systemId: 4,
        id: 'f503c5ec-4837-4145-b824-e938b206a2b7',
        name: '发动机706',
        location: '[0.2281559556722641, 0.061109986156225204, -0.05536282807588577]',
        vertices: 1497,
        polygons: 1188,
        lod: ['LOD_4'],
        name_work: 'dtl706',
        name_ua: 'Шків помпи',
        group: 1100206,
        tooltip: { cz: 'Kolo čerpadla', en: 'Pump pulley', ua: 'Шків помпи', bg: 'Помпена ролка' }
    },
    {
        unitId: 686,
        systemId: 4,
        id: '649ae96a-f951-4895-845e-7a9c7b9f1154',
        name: '发动机705',
        location: '[0.1973792016506195, 0.06111058220267296, -0.055361874401569366]',
        vertices: 920,
        polygons: 671,
        lod: ['LOD_3'],
        name_work: 'dtl705',
        name_ua: 'Вал помпи',
        group: 1100205,
        tooltip: { cz: 'Hřídel čerpadla', en: 'Pump shaft', ua: 'Вал помпи', bg: 'Вал на помпата' }
    },
    {
        unitId: 687,
        systemId: 4,
        id: '0cd4f3bc-d06f-4981-8318-887b2189ca3c',
        name: '发动机704',
        location: '[0.2344875931739807, 0.055307354778051376, -0.054191745817661285]',
        vertices: 1964,
        polygons: 1318,
        lod: ['LOD_4'],
        name_work: 'dtl704',
        name_ua: 'Шків помпи',
        group: 1100206,
        tooltip: { cz: 'Kolo čerpadla', en: 'Pump pulley', ua: 'Шків помпи', bg: 'Помпена ролка' }
    },
    {
        unitId: 688,
        systemId: 4,
        id: '6b7deb97-1cce-48da-9b34-667552e1e102',
        name: '发动机703',
        location: '[0.23413847386837006, 0.06869611144065857, -0.07780575752258301]',
        vertices: 444,
        polygons: 278,
        lod: ['LOD_4'],
        name_work: 'dtl703',
        name_ua: 'Болт кріплення шківа помпи',
        group: 1100206,
        tooltip: {
            cz: 'Šroub montáže kola čerpadla',
            en: 'Bolt for mounting the pump pulley',
            ua: 'Болт кріплення шківа помпи',
            bg: 'Болт за закрепване на ролката на помпата'
        }
    },
    {
        unitId: 689,
        systemId: 4,
        id: '0bfcfbf0-a72a-4b6b-91c1-80650a7e770e',
        name: '发动机702',
        location: '[0.23413999378681183, 0.07675489783287048, -0.037571556866168976]',
        vertices: 444,
        polygons: 278,
        lod: ['LOD_4'],
        name_work: 'dtl702',
        name_ua: 'Болт кріплення шківа помпи',
        group: 1100206,
        tooltip: {
            cz: 'Šroub montáže kola čerpadla',
            en: 'Bolt for mounting the pump pulley',
            ua: 'Болт кріплення шківа помпи',
            bg: 'Болт за закрепване на ролката на помпата'
        }
    },
    {
        unitId: 690,
        systemId: 4,
        id: '2a727617-973b-4c62-afad-12face4230b0',
        name: '发动机701',
        location: '[0.23413847386837006, 0.037880029529333115, -0.05071086436510086]',
        vertices: 444,
        polygons: 278,
        lod: ['LOD_4'],
        name_work: 'dtl701',
        name_ua: 'Болт кріплення шківа помпи',
        group: 1100206,
        tooltip: {
            cz: 'Šroub montáže kola čerpadla',
            en: 'Bolt for mounting the pump pulley',
            ua: 'Болт кріплення шківа помпи',
            bg: 'Болт за закрепване на ролката на помпата'
        }
    },
    {
        unitId: 691,
        systemId: 4,
        id: '689b0c63-2947-4740-b3f8-c7f1a4d56058',
        name: '发动机700',
        location: '[0.12499693036079407, -0.044933829456567764, -0.012082493863999844]',
        vertices: 1184,
        polygons: 970,
        lod: ['LOD_2'],
        name_work: 'dtl700',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 692,
        systemId: 4,
        id: '69657dc9-0823-4b31-acab-8f6179560299',
        name: '发动机699',
        location: '[0.2208106815814972, 0.11626443266868591, 0.06268179416656494]',
        vertices: 823,
        polygons: 550,
        lod: ['LOD_4'],
        name_work: 'dtl699',
        name_ua: 'Болт кріплення ролика',
        group: 1140302,
        tooltip: {
            cz: 'Šroub montáže válce',
            en: 'Bolt for mounting the roller',
            ua: 'Болт кріплення ролика',
            bg: 'Болт за монтаж на ролка'
        }
    },
    {
        unitId: 693,
        systemId: 4,
        id: 'bbf77388-c0b7-4a60-89bb-296217eddf5d',
        name: '发动机698',
        location: '[0.23784741759300232, 0.11624476313591003, 0.06265991926193237]',
        vertices: 377,
        polygons: 288,
        lod: ['LOD_4'],
        name_work: 'dtl698',
        name_ua: 'Нерухомий ролик привідного ременя',
        group: 1040306,
        tooltip: {
            cz: 'Pevná kladka pohonného řemene',
            en: 'Fixed roller for the drive belt',
            ua: 'Нерухомий ролик привідного ременя',
            bg: 'Неподвижна ролка на задвижващия ремък'
        }
    },
    {
        unitId: 694,
        systemId: 4,
        id: '0c8b1633-0473-4498-b354-b0021bf4625c',
        name: '发动机697',
        location: '[0.22457975149154663, 0.11624488234519958, 0.06265991926193237]',
        vertices: 4018,
        polygons: 3600,
        lod: ['LOD_4'],
        name_work: 'dtl697',
        name_ua: 'Ролик привідного ременя',
        group: 1040302,
        tooltip: {
            cz: 'Kladka pohonného řemene',
            en: 'Drive belt roller',
            ua: 'Ролик привідного ременя',
            bg: 'Ролка на задвижващия ремък'
        }
    },
    {
        unitId: 695,
        systemId: 4,
        id: 'e03f7b31-2fba-4369-b570-f4d630eb33c2',
        name: '发动机696',
        location: '[0.20627477765083313, -0.11204865574836731, -0.06570106744766235]',
        vertices: 321,
        polygons: 190,
        lod: ['LOD_4'],
        name_work: 'dtl696',
        name_ua: 'Болт кріплення ролика',
        group: 1140302,
        tooltip: {
            cz: 'Šroub montáže válce',
            en: 'Bolt for mounting the roller',
            ua: 'Болт кріплення ролика',
            bg: 'Болт за монтаж на ролка'
        }
    },
    {
        unitId: 696,
        systemId: 4,
        id: 'd580e23e-d182-48ad-994d-a2a407abde28',
        name: '发动机695',
        location: '[0.22469213604927063, -0.11204758286476135, -0.06570079922676086]',
        vertices: 1033,
        polygons: 816,
        lod: ['LOD_4'],
        name_work: 'dtl695',
        name_ua: 'Ролик привідного ременя',
        group: 1040302,
        tooltip: {
            cz: 'Kladka pohonného řemene',
            en: 'Drive belt roller',
            ua: 'Ролик привідного ременя',
            bg: 'Ролка на задвижващия ремък'
        }
    },
    {
        unitId: 697,
        systemId: 4,
        id: 'bc47e4d9-f44f-474b-9d92-1e57acd8f677',
        name: '发动机694',
        location: '[0.23147383332252502, -0.11204829812049866, -0.06570103764533997]',
        vertices: 474,
        polygons: 456,
        lod: ['LOD_4'],
        name_work: 'dtl694',
        name_ua: 'Ролик привідного ременя',
        group: 1040302,
        tooltip: {
            cz: 'Kladka pohonného řemene',
            en: 'Drive belt roller',
            ua: 'Ролик привідного ременя',
            bg: 'Ролка на задвижващия ремък'
        }
    },
    {
        unitId: 698,
        systemId: 4,
        id: '8fbe96b2-ec2b-4f62-af68-aa709780bf6f',
        name: '发动机693',
        location: '[0.22273828089237213, -0.045040521770715714, -0.18717049062252045]',
        vertices: 779,
        polygons: 548,
        lod: ['LOD_4'],
        name_work: 'dtl693',
        name_ua: 'Болт кріплення шківа колінчастого валу',
        group: 1140901,
        tooltip: {
            cz: 'Šroub montáže šroubu hřídele',
            en: 'Bolt for mounting the crankshaft pulley',
            ua: 'Болт кріплення шківа колінчастого валу',
            bg: 'Болт за закрепване на шайбата на коляновия вал'
        }
    },
    {
        unitId: 699,
        systemId: 4,
        id: '86230ece-22f5-43ee-94d4-09ebb81f357b',
        name: '发动机692',
        location: '[0.23074394464492798, -0.04485074058175087, -0.1871703416109085]',
        vertices: 281,
        polygons: 192,
        lod: ['LOD_4'],
        name_work: 'dtl692',
        name_ua: 'Шайба болта кріплення шківа колінвала',
        group: 1140901,
        tooltip: {
            cz: 'Podložka šroubu hřídele',
            en: 'Washer for the crankshaft pulley bolt',
            ua: 'Шайба болта кріплення шківа колінвала',
            bg: 'Шайба на болта на ролката на коляновия вал'
        }
    },
    {
        unitId: 700,
        systemId: 4,
        id: '14fbdd19-f7d5-40a7-b530-bd2c21aea42f',
        name: '发动机691',
        location: '[0.21949195861816406, -0.044851694256067276, -0.18717019259929657]',
        vertices: 5672,
        polygons: 4538,
        lod: ['LOD_4'],
        name_work: 'dtl691',
        name_ua: 'Шків колінчастого валу',
        group: 1140901,
        tooltip: {
            cz: 'Kolo hřídele',
            en: 'Crankshaft pulley',
            ua: 'Шків колінчастого валу',
            bg: 'шайба на коляновия вал'
        }
    },
    {
        unitId: 701,
        systemId: 4,
        id: 'fadb70f0-edc6-46fd-9e3c-549364bd97ca',
        name: '发动机690',
        location: '[0.22582107782363892, -0.04485097900032997, -0.18717019259929657]',
        vertices: 2128,
        polygons: 1820,
        lod: ['LOD_4'],
        name_work: 'dtl690',
        name_ua: 'Шків колінчастого валу',
        group: 1140901,
        tooltip: {
            cz: 'Kolo hřídele',
            en: 'Crankshaft pulley',
            ua: 'Шків колінчастого валу',
            bg: 'шайба на коляновия вал'
        }
    },
    {
        unitId: 702,
        systemId: 4,
        id: '561b42e6-473a-4738-9871-67e03144a91b',
        name: '发动机689',
        location: '[0.22544436156749725, -0.04485097900032997, -0.18717019259929657]',
        vertices: 8870,
        polygons: 7678,
        lod: ['LOD_4'],
        name_work: 'dtl689',
        name_ua: 'Шків колінчастого валу',
        group: 1140901,
        tooltip: {
            cz: 'Kolo hřídele',
            en: 'Crankshaft pulley',
            ua: 'Шків колінчастого валу',
            bg: 'шайба на коляновия вал'
        }
    },
    {
        unitId: 703,
        systemId: 4,
        id: '1e048c4e-2da1-403f-9c56-73be8f56901b',
        name: '发动机688',
        location: '[-0.19298073649406433, -0.04485097900032997, -0.18717004358768463]',
        vertices: 678,
        polygons: 528,
        lod: ['LOD_3'],
        name_work: 'dtl688',
        name_ua: 'Маховик',
        group: 1080301,
        tooltip: { cz: 'Setrvačník', en: 'Flywheel', ua: 'Маховик', bg: 'Маховик' }
    },
    {
        unitId: 704,
        systemId: 4,
        id: 'e5806c80-5b8a-4eba-a21d-2bdc21e9be26',
        name: '发动机687',
        location: '[0.12500113248825073, -0.044940028339624405, -0.012935048900544643]',
        vertices: 27431,
        polygons: 16922,
        lod: ['LOD_2'],
        name_work: 'dtl687',
        name_ua: 'Кільце поршневе',
        group: 1140102,
        tooltip: { cz: 'Pístní kroužek', en: 'Piston ring', ua: 'Кільце поршневе', bg: 'Бутален пръстен' }
    },
    {
        unitId: 705,
        systemId: 4,
        id: '2e24f2fc-34d4-438e-aefb-5493b3f74269',
        name: '发动机686',
        location: '[0.014110214076936245, -0.044851694256067276, -0.18717019259929657]',
        vertices: 24013,
        polygons: 19095,
        lod: ['LOD_3'],
        name_work: 'dtl686',
        name_ua: 'Вал колінчастий',
        group: 1141201,
        tooltip: { cz: 'Kolka kolo', en: 'Crankshaft', ua: 'Вал колінчастий', bg: 'Колянов вал' }
    },
    {
        unitId: 706,
        systemId: 4,
        id: 'c584c212-ce5e-49f8-84ab-8eca6d07eee4',
        name: '发动机685',
        location: '[-0.18899491429328918, -0.0173055250197649, -0.174906387925148]',
        vertices: 1850,
        polygons: 1400,
        lod: ['LOD_3'],
        name_work: 'dtl685',
        name_ua: 'Болт маховика',
        group: 1080303,
        tooltip: { cz: 'Šroub setrvačníku', en: 'Flywheel bolt', ua: 'Болт маховика', bg: 'Болт на маховика' }
    },
    {
        unitId: 707,
        systemId: 4,
        id: '1f9e2552-4c17-4bb5-883d-272585446d1b',
        name: '发动机684',
        location: '[-0.18899491429328918, -0.04169980064034462, -0.15718330442905426]',
        vertices: 1850,
        polygons: 1400,
        lod: ['LOD_3'],
        name_work: 'dtl684',
        name_ua: 'Болт маховика',
        group: 1080303,
        tooltip: { cz: 'Šroub setrvačníku', en: 'Flywheel bolt', ua: 'Болт маховика', bg: 'Болт на маховика' }
    },
    {
        unitId: 708,
        systemId: 4,
        id: '7527c334-aa46-4e88-9d8a-5d87c2908bae',
        name: '发动机683',
        location: '[-0.18899491429328918, -0.06924393773078918, -0.1694474071264267]',
        vertices: 1850,
        polygons: 1400,
        lod: ['LOD_3'],
        name_work: 'dtl683',
        name_ua: 'Болт маховика',
        group: 1080303,
        tooltip: { cz: 'Šroub setrvačníku', en: 'Flywheel bolt', ua: 'Болт маховика', bg: 'Болт на маховика' }
    },
    {
        unitId: 709,
        systemId: 4,
        id: 'e8c5ff5b-5bee-47f1-8d82-5eb8cbf5eaa3',
        name: '发动机682',
        location: '[-0.18899491429328918, -0.07239523530006409, -0.19943414628505707]',
        vertices: 1850,
        polygons: 1400,
        lod: ['LOD_3'],
        name_work: 'dtl682',
        name_ua: 'Болт маховика',
        group: 1080303,
        tooltip: { cz: 'Šroub setrvačníku', en: 'Flywheel bolt', ua: 'Болт маховика', bg: 'Болт на маховика' }
    },
    {
        unitId: 710,
        systemId: 4,
        id: 'b049ddf3-efbb-4882-a846-89ba04b59bcf',
        name: '发动机681',
        location: '[-0.18899491429328918, -0.048005614429712296, -0.21715708076953888]',
        vertices: 1850,
        polygons: 1400,
        lod: ['LOD_3'],
        name_work: 'dtl681',
        name_ua: 'Болт маховика',
        group: 1080303,
        tooltip: { cz: 'Šroub setrvačníku', en: 'Flywheel bolt', ua: 'Болт маховика', bg: 'Болт на маховика' }
    },
    {
        unitId: 711,
        systemId: 4,
        id: 'dcfa0d50-7184-4512-ac5d-8f0972458528',
        name: '发动机680',
        location: '[-0.18899491429328918, -0.02045956440269947, -0.20489327609539032]',
        vertices: 1850,
        polygons: 1400,
        lod: ['LOD_3'],
        name_work: 'dtl680',
        name_ua: 'Болт маховика',
        group: 1080303,
        tooltip: { cz: 'Šroub setrvačníku', en: 'Flywheel bolt', ua: 'Болт маховика', bg: 'Болт на маховика' }
    },
    {
        unitId: 712,
        systemId: 4,
        id: '9e97968e-d791-488d-98c9-89408705c41d',
        name: '发动机679',
        location: '[0.19253183901309967, -0.04471031203866005, -0.18717019259929657]',
        vertices: 2821,
        polygons: 2112,
        lod: ['LOD_3'],
        name_work: 'dtl679',
        name_ua: 'Шестерня ГРМ',
        group: 1140403,
        tooltip: { cz: 'Ozubené kolo', en: 'Timing gear', ua: 'Шестерня ГРМ', bg: 'Зъбно колело' }
    },
    {
        unitId: 713,
        systemId: 4,
        id: '0abf2141-19da-4db1-95c5-0547dc31ac30',
        name: '发动机678',
        location: '[0.21579617261886597, -0.04517081752419472, -0.18717019259929657]',
        vertices: 4459,
        polygons: 3280,
        lod: ['LOD_3'],
        name_work: 'dtl678',
        name_ua: 'Синхронізуючий диск',
        group: 1140901,
        tooltip: {
            cz: 'Synchronizační disk',
            en: 'Front crankshaft seal',
            ua: 'Синхронізуючий диск',
            bg: 'Синхронизиращ диск'
        }
    },
    {
        unitId: 714,
        systemId: 4,
        id: '65b32f9c-6767-4ed3-ad44-51deb068e74a',
        name: '发动机677',
        location: '[0.21385356783866882, -0.044851694256067276, -0.18717004358768463]',
        vertices: 5464,
        polygons: 4232,
        lod: ['LOD_3'],
        name_work: 'dtl677',
        name_ua: 'Сальник колінвалу передній',
        group: 1140803,
        tooltip: {
            cz: 'Přední těsnění klikové hřídele',
            en: 'Front crankshaft seal',
            ua: 'Сальник колінвалу передній',
            bg: 'Преден сальник на коляновия вал'
        }
    },
    {
        unitId: 715,
        systemId: 4,
        id: '466e5482-cec3-4288-8ca3-c79b1a53cfe8',
        name: '发动机676',
        location: '[0.2137243151664734, -0.044851694256067276, -0.18717004358768463]',
        vertices: 981,
        polygons: 960,
        lod: ['LOD_3'],
        name_work: 'dtl676',
        name_ua: 'Сальник колінвалу передній',
        group: 1140803,
        tooltip: {
            cz: 'Přední těsnění klikové hřídele',
            en: 'Front crankshaft seal',
            ua: 'Сальник колінвалу передній',
            bg: 'Преден сальник на коляновия вал'
        }
    },
    {
        unitId: 716,
        systemId: 4,
        id: '1cd49e4d-947c-4b34-a73f-117e5bea6c04',
        name: '发动机675',
        location: '[0.2126864492893219, -0.044851694256067276, -0.18717004358768463]',
        vertices: 1466,
        polygons: 1152,
        lod: ['LOD_3'],
        name_work: 'dtl675',
        name_ua: 'Сальник колінвалу передній',
        group: 1140803,
        tooltip: {
            cz: 'Přední těsnění klikové hřídele',
            en: 'Front crankshaft seal',
            ua: 'Сальник колінвалу передній',
            bg: 'Преден сальник на коляновия вал'
        }
    },
    {
        unitId: 717,
        systemId: 4,
        id: '9527b0d4-3822-42bb-83bc-87cc57f16826',
        name: '发动机674',
        location: '[0.14101912081241608, -0.07780781388282776, 0.04798334091901779]',
        vertices: 1011,
        polygons: 682,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl674',
        name_ua: 'Клапан випускний',
        group: 1140402,
        tooltip: { cz: 'Výfukový ventil', en: 'Exhaust valve', ua: 'Клапан випускний', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 718,
        systemId: 4,
        id: 'eaf30d50-bde3-4430-91a0-b7273c066de0',
        name: '发动机673',
        location: '[0.10914347320795059, -0.07780781388282776, 0.04798286408185959]',
        vertices: 1017,
        polygons: 682,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl673',
        name_ua: 'Клапан випускний',
        group: 1140402,
        tooltip: { cz: 'Výfukový ventil', en: 'Exhaust valve', ua: 'Клапан випускний', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 719,
        systemId: 4,
        id: 'c2030988-2003-4f99-899f-62a4aa46eedb',
        name: '发动机672',
        location: '[0.10935697704553604, -0.09030643105506897, 0.08232766389846802]',
        vertices: 916,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl672',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 720,
        systemId: 4,
        id: '1fae407a-75df-4114-99c9-a15943d0b548',
        name: '发动机671',
        location: '[0.1408010721206665, -0.09030559659004211, 0.08232873678207397]',
        vertices: 917,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl671',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 721,
        systemId: 4,
        id: 'c92604dc-f221-4d54-9a47-12940cba12ea',
        name: '发动机670',
        location: '[0.10914351046085358, -0.09126606583595276, 0.08495873212814331]',
        vertices: 746,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl670',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 722,
        systemId: 4,
        id: 'b600e653-8db5-4228-bb5e-fb78fa956a7d',
        name: '发动机669',
        location: '[0.14101912081241608, -0.09126678109169006, 0.08495795726776123]',
        vertices: 746,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl669',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 723,
        systemId: 4,
        id: 'e2601d9d-0b59-4ab8-a960-69744c5b2dc6',
        name: '发动机668',
        location: '[0.14101912081241608, -0.09163990616798401, 0.08597207069396973]',
        vertices: 897,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl668',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 724,
        systemId: 4,
        id: '84364cb5-b27e-4160-b2bd-e0a792254f0f',
        name: '发动机667',
        location: '[0.10914351046085358, -0.09163990616798401, 0.08597207069396973]',
        vertices: 897,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl667',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 725,
        systemId: 4,
        id: 'c6252a4b-9ea9-4c7e-8e34-c85faeebbe11',
        name: '发动机666',
        location: '[0.01852843351662159, 0.004049983806908131, 0.10708945989608765]',
        vertices: 18927,
        polygons: 13368,
        lod: ['LOD_3'],
        name_work: 'dtl666',
        name_ua: 'Розподільчий вал',
        group: 1140401,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Розподільчий вал',
            bg: 'Разпределителен вал'
        }
    },
    {
        unitId: 726,
        systemId: 4,
        id: '9ff6853f-d666-42ae-ad51-06abf3ff1db2',
        name: '发动机665',
        location: '[0.1984550654888153, 0.004084196873009205, 0.10721063613891602]',
        vertices: 16553,
        polygons: 17208,
        lod: ['LOD_3'],
        name_work: 'dtl665',
        name_ua: 'Зірка розподільчого валу',
        group: 1140414,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Зірка розподільчого валу',
            bg: 'Звезда на разпределителния вал'
        }
    },
    {
        unitId: 727,
        systemId: 4,
        id: 'f4051799-0c88-4f1e-a751-e266995e4096',
        name: '发动机664',
        location: '[0.21013686060905457, 0.004089799709618092, 0.10712701082229614]',
        vertices: 5520,
        polygons: 3970,
        lod: ['LOD_3'],
        name_work: 'dtl664',
        name_ua: 'Фазорегулятор в зборі на впускному розподільчому валу',
        group: 1140403,
        tooltip: {
            cz: 'Sestava regulátoru fáze na vstupním vačkovém hřídeli',
            en: 'Phase regulator assembly on the intake camshaft',
            ua: 'Фазорегулятор в зборі на впускному розподільчому валу',
            bg: 'Фазовият регулатор е монтиран на всмукателния разпределителен вал'
        }
    },
    {
        unitId: 728,
        systemId: 4,
        id: '7386d547-d067-42cf-a3d6-fa6b955b96f5',
        name: '发动机663',
        location: '[0.2201065719127655, 0.0039683254435658455, 0.10702019929885864]',
        vertices: 920,
        polygons: 900,
        lod: ['LOD_3'],
        name_work: 'dtl663',
        name_ua: 'Шестерня розподільчого валу',
        group: 1140403,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Шестерня розподільчого валу',
            bg: 'Зъбно колело на разпределителния вал'
        }
    },
    {
        unitId: 729,
        systemId: 4,
        id: '7d96ccdf-a7cf-42ef-9e96-ce7c5348795e',
        name: '发动机662',
        location: '[0.2131277322769165, 0.003712264122441411, 0.10722649097442627]',
        vertices: 610,
        polygons: 592,
        lod: ['LOD_3'],
        name_work: 'dtl662',
        name_ua: 'Шестерня розподільчого валу',
        group: 1140403,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Шестерня розподільчого валу',
            bg: 'Зъбно колело на разпределителния вал'
        }
    },
    {
        unitId: 730,
        systemId: 4,
        id: 'b22346dc-824c-48c6-b546-7028faf1affa',
        name: '发动机661',
        location: '[0.21026739478111267, 0.0040925415232777596, 0.1071118712425232]',
        vertices: 4799,
        polygons: 3092,
        lod: ['LOD_3'],
        name_work: 'dtl661',
        name_ua: 'Шестерня розподільчого валу',
        group: 1140403,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Шестерня розподільчого валу',
            bg: 'Зъбно колело на разпределителния вал'
        }
    },
    {
        unitId: 731,
        systemId: 4,
        id: 'f7cca826-dd52-406f-8326-38235b93d03f',
        name: '发动机660',
        location: '[0.207781583070755, -0.017995508387684822, 0.0905962586402893]',
        vertices: 2009,
        polygons: 1388,
        lod: ['LOD_3'],
        name_work: 'dtl660',
        name_ua: 'Шестерня розподільчого валу',
        group: 1140403,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Шестерня розподільчого валу',
            bg: 'Зъбно колело на разпределителния вал'
        }
    },
    {
        unitId: 732,
        systemId: 4,
        id: 'bd2f290f-79fc-408f-b5ca-9049f5d1826d',
        name: '发动机659',
        location: '[0.207781583070755, -0.007479819469153881, 0.1321520060300827]',
        vertices: 2019,
        polygons: 1388,
        lod: ['LOD_3'],
        name_work: 'dtl659',
        name_ua: 'Шестерня розподільчого валу',
        group: 1140403,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Шестерня розподільчого валу',
            bg: 'Зъбно колело на разпределителния вал'
        }
    },
    {
        unitId: 733,
        systemId: 4,
        id: '61def79f-9a46-4270-884e-7dce0225dd71',
        name: '发动机658',
        location: '[0.2116798758506775, -0.027425797656178474, 0.09686970710754395]',
        vertices: 247,
        polygons: 240,
        lod: ['LOD_3'],
        name_work: 'dtl658',
        name_ua: 'Шестерня розподільчого валу',
        group: 1140403,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Шестерня розподільчого валу',
            bg: 'Зъбно колело на разпределителния вал'
        }
    },
    {
        unitId: 734,
        systemId: 4,
        id: 'e7f024bf-9f17-4c49-ac9a-42a96441fd21',
        name: '发动机657',
        location: '[0.20711950957775116, 0.0040702493861317635, 0.10709846019744873]',
        vertices: 848,
        polygons: 646,
        lod: ['LOD_3'],
        name_work: 'dtl657',
        name_ua: 'Болт кріплення шестерні розподільчого валу',
        group: 1140409,
        tooltip: {
            cz: 'Šroub pro montáž ozubeného kola vačkového hřídele',
            en: 'Bolt for mounting the camshaft gear',
            ua: 'Болт кріплення шестерні розподільчого валу',
            bg: 'Монтажен болт на зъбното колело на разпределителния вал'
        }
    },
    {
        unitId: 735,
        systemId: 4,
        id: '53ccffae-a606-44e8-a461-de67cdad169e',
        name: '发动机656',
        location: '[0.207781583070755, 0.015665380284190178, 0.08209341764450073]',
        vertices: 2022,
        polygons: 1388,
        lod: ['LOD_3'],
        name_work: 'dtl656',
        name_ua: 'Шестерня розподільчого валу',
        group: 1140403,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Шестерня розподільчого валу',
            bg: 'Зъбно колело на разпределителния вал'
        }
    },
    {
        unitId: 736,
        systemId: 4,
        id: 'b645fd34-04a9-4c55-af0a-d5f8fe6ede24',
        name: '发动机655',
        location: '[0.207781583070755, 0.028241721913218498, 0.1204754114151001]',
        vertices: 2028,
        polygons: 1388,
        lod: ['LOD_3'],
        name_work: 'dtl655',
        name_ua: 'Шестерня розподільчого валу',
        group: 1140403,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Шестерня розподільчого валу',
            bg: 'Зъбно колело на разпределителния вал'
        }
    },
    {
        unitId: 737,
        systemId: 4,
        id: 'bfe4a6b8-064b-426f-9754-ce4be92e0648',
        name: '发动机654',
        location: '[0.01213317085057497, -0.09912136197090149, 0.10703819990158081]',
        vertices: 20732,
        polygons: 14876,
        lod: ['LOD_3'],
        name_work: 'dtl654',
        name_ua: 'Розподільчий вал',
        group: 1140401,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Розподільчий вал',
            bg: 'Разпределителен вал'
        }
    },
    {
        unitId: 738,
        systemId: 4,
        id: 'da4b23b5-4930-4d50-a6c9-3e8069766a09',
        name: '发动机653',
        location: '[0.19787336885929108, -0.09912195801734924, 0.10709893703460693]',
        vertices: 4121,
        polygons: 2976,
        lod: ['LOD_3'],
        name_work: 'dtl653',
        name_ua: 'Зірка розподільчого валу',
        group: 1140414,
        tooltip: {
            cz: 'Ozubené kolo vačkového hřídele',
            en: 'Camshaft gear',
            ua: 'Зірка розподільчого валу',
            bg: 'Звезда на разпределителния вал'
        }
    },
    {
        unitId: 739,
        systemId: 4,
        id: 'bc41a4d0-1612-4a96-892a-4ac32a3ec65d',
        name: '发动机652',
        location: '[0.19337217509746552, -0.09912195801734924, 0.10709846019744873]',
        vertices: 848,
        polygons: 646,
        lod: ['LOD_3'],
        name_work: 'dtl652',
        name_ua: 'Болт кріплення шестерні розподільчого валу',
        group: 1140409,
        tooltip: {
            cz: 'Šroub pro montáž ozubeného kola vačkového hřídele',
            en: 'Bolt for mounting the camshaft gear',
            ua: 'Болт кріплення шестерні розподільчого валу',
            bg: 'Монтажен болт на зъбното колело на разпределителния вал'
        }
    },
    {
        unitId: 740,
        systemId: 4,
        id: '15d2d8f3-1aa8-448b-9a6a-186c6052695d',
        name: '发动机651',
        location: '[0.05831538885831833, -0.07780781388282776, 0.047983162105083466]',
        vertices: 1038,
        polygons: 682,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl651',
        name_ua: 'Клапан випускний',
        group: 1140402,
        tooltip: { cz: 'Výfukový ventil', en: 'Exhaust valve', ua: 'Клапан випускний', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 741,
        systemId: 4,
        id: '55ca68de-1fae-4ccc-abf6-60c2174df871',
        name: '发动机650',
        location: '[0.026439810171723366, -0.0778069794178009, 0.04798286408185959]',
        vertices: 1043,
        polygons: 682,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl650',
        name_ua: 'Клапан випускний',
        group: 1140402,
        tooltip: { cz: 'Výfukový ventil', en: 'Exhaust valve', ua: 'Клапан випускний', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 742,
        systemId: 4,
        id: '985a5e16-2e1d-43bb-9f2e-d63c0883807b',
        name: '发动机649',
        location: '[0.026653282344341278, -0.09030559659004211, 0.08232873678207397]',
        vertices: 915,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl649',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 743,
        systemId: 4,
        id: 'c31a6a0b-68a8-407f-abf5-65e5784e137b',
        name: '发动机648',
        location: '[0.05810050666332245, -0.09030559659004211, 0.08232873678207397]',
        vertices: 917,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl648',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 744,
        systemId: 4,
        id: '523e54c2-df30-4693-9547-26c9dd5ca029',
        name: '发动机647',
        location: '[0.026439810171723366, -0.09163990616798401, 0.08597159385681152]',
        vertices: 902,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl647',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 745,
        systemId: 4,
        id: 'd65c2d86-d066-44ff-b7b2-51b0b363032c',
        name: '发动机646',
        location: '[0.058315426111221313, -0.09163990616798401, 0.08597159385681152]',
        vertices: 901,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl646',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 746,
        systemId: 4,
        id: '112abe57-2297-4fbb-995b-0ea473ea1ae5',
        name: '发动机645',
        location: '[0.026439888402819633, -0.0912652313709259, 0.08495795726776123]',
        vertices: 746,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl645',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 747,
        systemId: 4,
        id: '36e4a193-3746-41ab-a409-20864e73fc76',
        name: '发动机644',
        location: '[0.05831550434231758, -0.09126606583595276, 0.08495873212814331]',
        vertices: 746,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl644',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 748,
        systemId: 4,
        id: 'b2ddcce1-1c55-4033-ba33-2f622bfead68',
        name: '发动机643',
        location: '[-0.056260835379362106, -0.07504251599311829, 0.04036950320005417]',
        vertices: 1038,
        polygons: 682,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl643',
        name_ua: 'Клапан випускний',
        group: 1140402,
        tooltip: { cz: 'Výfukový ventil', en: 'Exhaust valve', ua: 'Клапан випускний', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 749,
        systemId: 4,
        id: 'dfdd17a5-92a1-4768-aec1-941cf1402cf3',
        name: '发动机642',
        location: '[-0.024388281628489494, -0.07504251599311829, 0.04036950320005417]',
        vertices: 1038,
        polygons: 682,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl642',
        name_ua: 'Клапан випускний',
        group: 1140402,
        tooltip: { cz: 'Výfukový ventil', en: 'Exhaust valve', ua: 'Клапан випускний', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 750,
        systemId: 4,
        id: 'bc3ac890-03ef-4c10-ac1a-c56cc60b5889',
        name: '发动机641',
        location: '[-0.056047212332487106, -0.08754244446754456, 0.07471674680709839]',
        vertices: 917,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl641',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 751,
        systemId: 4,
        id: '49376dfb-e9b0-4e0e-bbd9-ff6b85ef6676',
        name: '发动机640',
        location: '[-0.02460320107638836, -0.08754172921180725, 0.07471472024917603]',
        vertices: 916,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl640',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 752,
        systemId: 4,
        id: '69eab868-9086-459c-bb12-21654766ba84',
        name: '发动机639',
        location: '[-0.05626076087355614, -0.0888761579990387, 0.07835978269577026]',
        vertices: 897,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl639',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 753,
        systemId: 4,
        id: '0db99007-d853-4cdf-9db4-5f883e435751',
        name: '发动机638',
        location: '[-0.024388207122683525, -0.0888761579990387, 0.07835978269577026]',
        vertices: 897,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl638',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 754,
        systemId: 4,
        id: 'c00c4e3a-f835-41bb-8f70-5b5f2627b810',
        name: '发动机637',
        location: '[-0.024388207122683525, -0.08850136399269104, 0.07734549045562744]',
        vertices: 746,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl637',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 755,
        systemId: 4,
        id: 'bd59b2f0-1a1c-4777-921b-f930cd44bc36',
        name: '发动机636',
        location: '[-0.05626380816102028, -0.08850148320198059, 0.07734549045562744]',
        vertices: 746,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl636',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 756,
        systemId: 4,
        id: '05b0c22e-556f-473e-a316-2d20a6ca4dd5',
        name: '发动机635',
        location: '[-0.13896606862545013, -0.07780781388282776, 0.047983162105083466]',
        vertices: 1037,
        polygons: 682,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl635',
        name_ua: 'Клапан випускний',
        group: 1140402,
        tooltip: { cz: 'Výfukový ventil', en: 'Exhaust valve', ua: 'Клапан випускний', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 757,
        systemId: 4,
        id: 'be204e98-ad82-4bd8-9e99-e219aaef5106',
        name: '发动机634',
        location: '[-0.10709045827388763, -0.07780781388282776, 0.047983162105083466]',
        vertices: 1037,
        polygons: 682,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl634',
        name_ua: 'Клапан випускний',
        group: 1140402,
        tooltip: { cz: 'Výfukový ventil', en: 'Exhaust valve', ua: 'Клапан випускний', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 758,
        systemId: 4,
        id: 'ed2785c7-ff38-4a32-adc6-04845e6264ad',
        name: '发动机633',
        location: '[-0.1387494057416916, -0.09030559659004211, 0.08232873678207397]',
        vertices: 917,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl633',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 759,
        systemId: 4,
        id: '3be9e59d-2851-4759-9fea-31efc3a4632a',
        name: '发动机632',
        location: '[-0.10730530321598053, -0.09030702710151672, 0.08232778310775757]',
        vertices: 915,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl632',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 760,
        systemId: 4,
        id: 'aaa27012-54d0-4a37-867f-862dbdc6aebe',
        name: '发动机631',
        location: '[-0.1389659196138382, -0.09163990616798401, 0.08597159385681152]',
        vertices: 897,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl631',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 761,
        systemId: 4,
        id: 'd46edb12-1bdd-461e-b148-ce65888ee889',
        name: '发动机630',
        location: '[-0.1070903092622757, -0.09164133667945862, 0.0859718918800354]',
        vertices: 897,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl630',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 762,
        systemId: 4,
        id: 'cfa4015f-eaff-49f9-b735-a147fd17c609',
        name: '发动机629',
        location: '[-0.13896439969539642, -0.09126594662666321, 0.08495873212814331]',
        vertices: 746,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl629',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 763,
        systemId: 4,
        id: 'f5f65b4d-ec5b-4827-afd4-b2bedd4fc6b6',
        name: '发动机628',
        location: '[-0.1070903092622757, -0.09126594662666321, 0.08495873212814331]',
        vertices: 746,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl628',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 764,
        systemId: 4,
        id: '35424916-1d30-4419-a301-9d446a2878ae',
        name: '发动机627',
        location: '[0.05896211415529251, -0.018851669505238533, 0.040404729545116425]',
        vertices: 886,
        polygons: 606,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl627',
        name_ua: 'Клапан впускний',
        group: 1140402,
        tooltip: { cz: 'Sací ventil', en: 'Intake valve', ua: 'Клапан впускний', bg: 'Входящ клапан' }
    },
    {
        unitId: 765,
        systemId: 4,
        id: '4f1c2a6e-9c08-42fc-9ed5-5fc96cc55032',
        name: '发动机626',
        location: '[0.025793392211198807, -0.018851669505238533, 0.040404729545116425]',
        vertices: 895,
        polygons: 606,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl626',
        name_ua: 'Клапан впускний',
        group: 1140402,
        tooltip: { cz: 'Sací ventil', en: 'Intake valve', ua: 'Клапан впускний', bg: 'Входящ клапан' }
    },
    {
        unitId: 766,
        systemId: 4,
        id: '0ba76fcc-cd6f-4a66-9f73-34379d279e5e',
        name: '发动机625',
        location: '[0.058528974652290344, -0.00712111871689558, 0.0744742751121521]',
        vertices: 939,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl625',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 767,
        systemId: 4,
        id: '91838047-8313-413c-950c-d9bbb6cb7038',
        name: '发动机624',
        location: '[0.026224948465824127, -0.00712111871689558, 0.0744742751121521]',
        vertices: 935,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl624',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 768,
        systemId: 4,
        id: '9867a315-6cfa-4807-a148-fbd5ef9afd5b',
        name: '发动机623',
        location: '[0.05895740166306496, -0.005903515033423901, 0.07801961898803711]',
        vertices: 900,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl623',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 769,
        systemId: 4,
        id: 'aeb273ca-74d2-40e4-a502-19f2f56588ed',
        name: '发动机622',
        location: '[0.025793371722102165, -0.005903276614844799, 0.07802027463912964]',
        vertices: 905,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl622',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 770,
        systemId: 4,
        id: '051af13d-ffc3-4ac4-9532-80642fb3f2f8',
        name: '发动机621',
        location: '[0.02579333446919918, -0.006249579600989819, 0.07699912786483765]',
        vertices: 753,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl621',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 771,
        systemId: 4,
        id: '47b903f9-a920-4e8d-a2de-9bb786373f32',
        name: '发动机620',
        location: '[0.05896203592419624, -0.0062512485310435295, 0.07699942588806152]',
        vertices: 766,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl620',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 772,
        systemId: 4,
        id: '8899db5b-ee99-45d2-aa33-b321044885b7',
        name: '发动机619',
        location: '[-0.0569087453186512, -0.016220958903431892, 0.0480647012591362]',
        vertices: 897,
        polygons: 606,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl619',
        name_ua: 'Клапан впускний',
        group: 1140402,
        tooltip: { cz: 'Sací ventil', en: 'Intake valve', ua: 'Клапан впускний', bg: 'Входящ клапан' }
    },
    {
        unitId: 773,
        systemId: 4,
        id: 'ca990701-0ece-451c-8514-398ef23fb957',
        name: '发动机618',
        location: '[-0.023741574957966805, -0.016222389414906502, 0.0480647012591362]',
        vertices: 886,
        polygons: 606,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl618',
        name_ua: 'Клапан впускний',
        group: 1140402,
        tooltip: { cz: 'Sací ventil', en: 'Intake valve', ua: 'Клапан впускний', bg: 'Входящ клапан' }
    },
    {
        unitId: 774,
        systemId: 4,
        id: 'd22333ce-1718-439a-b7a3-d1ce65f0fe07',
        name: '发动机617',
        location: '[-0.056478749960660934, -0.0044919578358531, 0.08213311433792114]',
        vertices: 939,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl617',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 775,
        systemId: 4,
        id: '1559da74-a862-4eb5-a152-49f7de5910c4',
        name: '发动机616',
        location: '[-0.024173134937882423, -0.004492077045142651, 0.08213293552398682]',
        vertices: 939,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl616',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 776,
        systemId: 4,
        id: '2887af6c-76a3-483d-8d78-ae505f8e184d',
        name: '发动机615',
        location: '[-0.05690876021981239, -0.0032734002452343702, 0.08567953109741211]',
        vertices: 904,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl615',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 777,
        systemId: 4,
        id: '75d63a07-2c9b-49f7-a6fb-e10910f1d932',
        name: '发动机614',
        location: '[-0.02374318055808544, -0.0032734002452343702, 0.08567953109741211]',
        vertices: 902,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl614',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 778,
        systemId: 4,
        id: 'e7777a2f-d255-4dc0-abd8-896e088ed231',
        name: '发动机613',
        location: '[-0.05690879747271538, -0.003618987975642085, 0.08465826511383057]',
        vertices: 766,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl613',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 779,
        systemId: 4,
        id: 'e0d55ddd-cd42-4d83-a3eb-60433a3a8b28',
        name: '发动机612',
        location: '[-0.023741653189063072, -0.003620656905695796, 0.08465796709060669]',
        vertices: 766,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl612',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 780,
        systemId: 4,
        id: '8dd46c90-4c0f-428e-8f5b-f7742bd02255',
        name: '发动机611',
        location: '[-0.10644368827342987, -0.016220958903431892, 0.0480647012591362]',
        vertices: 898,
        polygons: 606,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl611',
        name_ua: 'Клапан впускний',
        group: 1140402,
        tooltip: { cz: 'Sací ventil', en: 'Intake valve', ua: 'Клапан впускний', bg: 'Входящ клапан' }
    },
    {
        unitId: 781,
        systemId: 4,
        id: 'c7d1d9b8-06c1-4ed4-9588-cf195549703e',
        name: '发动机610',
        location: '[-0.1396108716726303, -0.016220958903431892, 0.0480644628405571]',
        vertices: 893,
        polygons: 606,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl610',
        name_ua: 'Клапан впускний',
        group: 1140402,
        tooltip: { cz: 'Sací ventil', en: 'Intake valve', ua: 'Клапан впускний', bg: 'Входящ клапан' }
    },
    {
        unitId: 782,
        systemId: 4,
        id: '5d62dbdc-fbb4-4186-83bf-8803c9457c24',
        name: '发动机609',
        location: '[-0.13918085396289825, -0.004491242580115795, 0.08213353157043457]',
        vertices: 939,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl609',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 783,
        systemId: 4,
        id: '8428bd50-4c21-4efc-af0b-cdde38a553a2',
        name: '发动机608',
        location: '[-0.10687525570392609, -0.0044919578358531, 0.08213311433792114]',
        vertices: 939,
        polygons: 668,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl608',
        name_ua: 'Штовхач клапану',
        group: 1140406,
        tooltip: { cz: 'Poháněč ventilu', en: 'Valve lifter', ua: 'Штовхач клапану', bg: 'Клапан тласкач' }
    },
    {
        unitId: 784,
        systemId: 4,
        id: 'e2eedc8a-139d-4603-b975-47e6bca895c0',
        name: '发动机607',
        location: '[-0.1396108716726303, -0.003273519454523921, 0.08567953109741211]',
        vertices: 904,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl607',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 785,
        systemId: 4,
        id: '28ef9548-e5c3-4d14-9fde-b940c7ef8def',
        name: '发动机606',
        location: '[-0.10644528269767761, -0.0032734002452343702, 0.08567953109741211]',
        vertices: 904,
        polygons: 600,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl606',
        name_ua: 'Тарілка клапана',
        group: 1140412,
        tooltip: { cz: 'Klapka ventilu', en: 'Valve plate', ua: 'Тарілка клапана', bg: 'Табан на клапана' }
    },
    {
        unitId: 786,
        systemId: 4,
        id: '4d8ffd93-d3c2-4c30-bc1d-db62e718fa6a',
        name: '发动机605',
        location: '[-0.13961093127727509, -0.003620656905695796, 0.08465874195098877]',
        vertices: 766,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl605',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 787,
        systemId: 4,
        id: 'a28c5eeb-4584-4203-b9e7-e47e935a7326',
        name: '发动机604',
        location: '[-0.10644376277923584, -0.003618987975642085, 0.08465826511383057]',
        vertices: 766,
        polygons: 496,
        lod: ['LOD_1'],
        name_work: 'dtl604',
        name_ua: 'Сухар клапана',
        group: 1140411,
        tooltip: { cz: 'Držák ventilu', en: 'Valve keeper', ua: 'Сухар клапана', bg: 'Клапан на клапана' }
    },
    {
        unitId: 788,
        systemId: 4,
        id: '07215f7f-c2df-484b-9c01-c7cad562fc09',
        name: '发动机602',
        location: '[0.14101606607437134, -0.08724191784858704, 0.07394921779632568]',
        vertices: 6470,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl602',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 789,
        systemId: 4,
        id: '8c574f10-46aa-4739-88a5-f98cb5a3d06a',
        name: '发动机601',
        location: '[0.10914663970470428, -0.08724191784858704, 0.07394921779632568]',
        vertices: 6470,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl601',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 790,
        systemId: 4,
        id: '54d4eab3-8ea8-4f40-9f96-e7ad1670b651',
        name: '发动机600',
        location: '[0.05834975838661194, -0.08724191784858704, 0.07394921779632568]',
        vertices: 6470,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl600',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 791,
        systemId: 4,
        id: 'c7ae87e9-5107-4ab8-8a76-ec68ec81d9e5',
        name: '发动机599',
        location: '[0.0264273751527071, -0.08724191784858704, 0.07394921779632568]',
        vertices: 6471,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl599',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 792,
        systemId: 4,
        id: '1011667d-779b-4669-b64e-1996cf679cf0',
        name: '发动机598',
        location: '[-0.024366384372115135, -0.08724191784858704, 0.07394921779632568]',
        vertices: 6470,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl598',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 793,
        systemId: 4,
        id: '401e2dd3-18e0-42eb-8e73-bd14f78394c1',
        name: '发动机597',
        location: '[-0.05628876015543938, -0.08724203705787659, 0.07394921779632568]',
        vertices: 6470,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl597',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 794,
        systemId: 4,
        id: '0c6f066f-cc92-4825-ad1f-cd2de5b36736',
        name: '发动机596',
        location: '[-0.10713395476341248, -0.08724203705787659, 0.07394921779632568]',
        vertices: 6470,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl596',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 795,
        systemId: 4,
        id: 'b2cf1268-4149-47d3-9701-560701852027',
        name: '发动机595',
        location: '[-0.13900330662727356, -0.08724203705787659, 0.07394921779632568]',
        vertices: 6470,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl595',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 796,
        systemId: 4,
        id: '7ab91be0-fba9-488a-a729-fc0819a79a1f',
        name: '发动机594',
        location: '[0.14163455367088318, -0.007216962985694408, 0.07430380582809448]',
        vertices: 5766,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl594',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 797,
        systemId: 4,
        id: '4e30c0df-f0cd-4d01-bda2-dab10c6e79fc',
        name: '发动机593',
        location: '[0.10847360640764236, -0.007216962985694408, 0.07430380582809448]',
        vertices: 5766,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl593',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 798,
        systemId: 4,
        id: '6e12aebd-e2fe-4dca-91ed-b80ec9002622',
        name: '发动机592',
        location: '[0.058954235166311264, -0.007216962985694408, 0.07430380582809448]',
        vertices: 5766,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl592',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 799,
        systemId: 4,
        id: '2b2517de-45c6-4d68-a6ca-13c01ca192d4',
        name: '发动机591',
        location: '[0.02578086033463478, -0.007216962985694408, 0.07430380582809448]',
        vertices: 5766,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl591',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 800,
        systemId: 4,
        id: '7d5e79e6-4191-4987-a3f9-b08d1d8ac57a',
        name: '发动机590',
        location: '[-0.02373695932328701, -0.007216962985694408, 0.07430380582809448]',
        vertices: 5766,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl590',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 801,
        systemId: 4,
        id: '013d64ab-673e-49f4-a141-5fb7264e8791',
        name: '发动机589',
        location: '[-0.0568900890648365, -0.007216843776404858, 0.07430380582809448]',
        vertices: 5766,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl589',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 802,
        systemId: 4,
        id: '17546808-feaa-4273-bba6-280a6d751ad6',
        name: '发动机588',
        location: '[-0.10642975568771362, -0.007216843776404858, 0.07430380582809448]',
        vertices: 5766,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl588',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 803,
        systemId: 4,
        id: '758c108c-834e-470f-b7af-c01aafc26300',
        name: '发动机587',
        location: '[-0.1395345777273178, -0.007216843776404858, 0.07430380582809448]',
        vertices: 5766,
        polygons: 4052,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl587',
        name_ua: 'Пружина клапана',
        group: 1140408,
        tooltip: { cz: 'Pružina ventilu', en: 'Valve spring', ua: 'Пружина клапана', bg: 'Клапанна пружина' }
    },
    {
        unitId: 804,
        systemId: 4,
        id: '194870e3-46a3-46b1-b23f-58679551e730',
        name: '发动机586',
        location: '[0.19850176572799683, -0.05044511333107948, -0.029390519484877586]',
        vertices: 67957,
        polygons: 36400,
        lod: ['LOD_3'],
        name_work: 'dtl586',
        name_ua: 'Ланцюг ГРМ',
        group: 1040401,
        tooltip: { cz: 'Řetězové řízení', en: 'Timing chain', ua: 'Ланцюг ГРМ', bg: 'Времеви ремък' }
    },
    {
        unitId: 805,
        systemId: 4,
        id: 'd19001fb-8ea5-4938-bbe8-eb4fc12ae876',
        name: '发动机585',
        location: '[-0.02428070642054081, 0.06627842783927917, 0.060506515204906464]',
        vertices: 56,
        polygons: 28,
        lod: ['LOD_3'],
        name_work: 'dtl585',
        name_ua: 'Вал вихревих заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel škrtící klapky',
            en: 'Throttle valve shaft',
            ua: 'Вал вихревих заслінок',
            bg: 'Вал на клапаните'
        }
    },
    {
        unitId: 806,
        systemId: 4,
        id: '8c916210-7e43-4cba-ae18-a87bc1f28756',
        name: '发动机584',
        location: '[-0.11872178316116333, 0.06340548396110535, 0.05910664051771164]',
        vertices: 3424,
        polygons: 2465,
        lod: ['LOD_3'],
        name_work: 'dtl584',
        name_ua: 'Вал вихревих заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel škrtící klapky',
            en: 'Throttle valve shaft',
            ua: 'Вал вихревих заслінок',
            bg: 'Вал на клапаните'
        }
    },
    {
        unitId: 807,
        systemId: 4,
        id: '06519b1b-16a4-438b-88c2-709cecf5a879',
        name: '发动机583',
        location: '[-0.04032577946782112, 0.06340917944908142, 0.0591040775179863]',
        vertices: 3424,
        polygons: 2465,
        lod: ['LOD_3'],
        name_work: 'dtl583',
        name_ua: 'Вал вихревих заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel škrtící klapky',
            en: 'Throttle valve shaft',
            ua: 'Вал вихревих заслінок',
            bg: 'Вал на клапаните'
        }
    },
    {
        unitId: 808,
        systemId: 4,
        id: '1334630b-1b04-4cbf-ab24-e7158163294d',
        name: '发动机582',
        location: '[0.0423763245344162, 0.06341788172721863, 0.05910175293684006]',
        vertices: 3441,
        polygons: 2465,
        lod: ['LOD_3'],
        name_work: 'dtl582',
        name_ua: 'Вал вихрових клапанів',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel vírových ventilů',
            en: 'Swirl valve shaft',
            ua: 'Вал вихрових клапанів',
            bg: 'Вал на спирателните клапани'
        }
    },
    {
        unitId: 809,
        systemId: 4,
        id: '942dfded-bfd3-484f-807b-5519e8f3a35c',
        name: '发动机581',
        location: '[0.12077230960130692, 0.06342503428459167, 0.05909942835569382]',
        vertices: 3424,
        polygons: 2465,
        lod: ['LOD_3'],
        name_work: 'dtl581',
        name_ua: 'Вал вихревих заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel škrtící klapky',
            en: 'Throttle valve shaft',
            ua: 'Вал вихревих заслінок',
            bg: 'Вал на клапаните'
        }
    },
    {
        unitId: 810,
        systemId: 4,
        id: '569db3cd-8b7f-4819-b00c-d6bdb6e7a5ab',
        name: '发动机580',
        location: '[0.14730483293533325, 0.06627890467643738, 0.060506515204906464]',
        vertices: 1002,
        polygons: 728,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl580',
        name_ua: 'Пробка головки блоку циліндрів',
        group: 1140509,
        tooltip: {
            cz: 'Zátka hlavy válců',
            en: 'Cylinder head plug',
            ua: 'Пробка головки блоку циліндрів',
            bg: 'Запушалка на главата на цилиндрите'
        }
    },
    {
        unitId: 811,
        systemId: 4,
        id: '97a021d0-4dc3-473f-9cf7-1a5a57372bd8',
        name: '发动机579',
        location: '[0.042355727404356, 0.06352612376213074, 0.054433755576610565]',
        vertices: 4227,
        polygons: 3054,
        lod: ['LOD_3'],
        name_work: 'dtl579',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 812,
        systemId: 4,
        id: '91d0a63e-fde0-4e5e-8bf6-a249380f3402',
        name: '发动机578',
        location: '[0.12075163424015045, 0.06353244185447693, 0.05443137139081955]',
        vertices: 4227,
        polygons: 3054,
        lod: ['LOD_3'],
        name_work: 'dtl578',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 813,
        systemId: 4,
        id: '3ae44bde-28c5-4ddb-9b53-6688e9561fa9',
        name: '发动机577',
        location: '[-0.21207062900066376, 0.05816718563437462, 0.05593358725309372]',
        vertices: 1537,
        polygons: 1090,
        lod: ['LOD_3'],
        name_work: 'dtl577',
        name_ua: 'Направлюча клапана керування',
        group: 1150700,
        tooltip: {
            cz: 'Vedení ovládacího ventilu',
            en: 'Control valve guide',
            ua: 'Направлюча клапана керування',
            bg: 'Направляваща клапан за управление'
        }
    },
    {
        unitId: 814,
        systemId: 4,
        id: '05d28484-935a-4492-b7ba-a66b98e79917',
        name: '发动机576',
        location: '[-0.1989585906267166, 0.059149231761693954, 0.06303471326828003]',
        vertices: 2973,
        polygons: 2085,
        lod: ['LOD_3'],
        name_work: 'dtl576',
        name_ua: 'Важіль направляючої клапана керування',
        group: 1150700,
        tooltip: {
            cz: 'Páka vedení ovládacího ventilu',
            en: 'Control valve guide lever',
            ua: 'Важіль направляючої клапана керування',
            bg: 'Лост на направляващата клапан за управление'
        }
    },
    {
        unitId: 815,
        systemId: 4,
        id: '1a3e759c-cea2-4089-9854-645ccb0b4530',
        name: '发动机575',
        location: '[-0.18539811670780182, 0.06627842783927917, 0.060506515204906464]',
        vertices: 1121,
        polygons: 816,
        lod: ['LOD_4'],
        name_work: 'dtl575',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 816,
        systemId: 4,
        id: '84eb0aed-a1ba-4ea8-8bbb-3024f73918ba',
        name: '发动机574',
        location: '[-0.18029503524303436, 0.06627878546714783, 0.06051307171583176]',
        vertices: 458,
        polygons: 384,
        lod: ['LOD_4'],
        name_work: 'dtl574',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 817,
        systemId: 4,
        id: 'e756f73c-34e0-4f74-941f-75f11886158b',
        name: '发动机573',
        location: '[-0.11874237656593323, 0.0635121762752533, 0.054438523948192596]',
        vertices: 4227,
        polygons: 3054,
        lod: ['LOD_3'],
        name_work: 'dtl573',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 818,
        systemId: 4,
        id: 'a6514211-13ab-47a3-b1d2-da18ede56b40',
        name: '发动机572',
        location: '[-0.04034797474741936, 0.06351909041404724, 0.054436080157756805]',
        vertices: 4227,
        polygons: 3054,
        lod: ['LOD_3'],
        name_work: 'dtl572',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 819,
        systemId: 4,
        id: '34a14852-c072-48a7-872a-1d826eb59404',
        name: '发动机570',
        location: '[-0.10405518114566803, 0.1472083032131195, -0.07607924938201904]',
        vertices: 1166,
        polygons: 715,
        lod: ['LOD_4'],
        name_work: 'dtl570',
        name_ua: 'Саморіз кріплення датчика абсолютного тиску',
        group: 1180403,
        tooltip: {
            cz: 'Samořezný šroub pro upevnění snímače absolutního tlaku',
            en: 'Self-tapping screw for absolute pressure sensor mounting',
            ua: 'Саморіз кріплення датчика абсолютного тиску',
            bg: 'Самонарезен винт за закрепване на сензора за абсолютен натиск'
        }
    },
    {
        unitId: 820,
        systemId: 4,
        id: '02230e54-6cb2-464e-91f1-e4beee42ff7b',
        name: '发动机569',
        location: '[-0.2284693866968155, 0.11780223250389099, 0.06973516941070557]',
        vertices: 7823,
        polygons: 5384,
        lod: ['LOD_3'],
        name_work: 'dtl569',
        name_ua: 'Прокладка дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Guma klapky',
            en: 'Throttle valve gasket',
            ua: 'Прокладка дросельної заслінки',
            bg: 'Уплътнение на дроселната клапа'
        }
    },
    {
        unitId: 821,
        systemId: 4,
        id: '5fe24f29-db3c-4830-8bd8-acde0eb60205',
        name: '发动机568',
        location: '[-0.2444886714220047, 0.12473055720329285, 0.07742208242416382]',
        vertices: 840,
        polygons: 496,
        lod: ['LOD_3'],
        name_work: 'dtl568',
        name_ua: 'Вал дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Hřídel škrtící klapky',
            en: 'Throttle valve shaft',
            ua: 'Вал дросельної заслінки',
            bg: 'Вал на дроселната клапа'
        }
    },
    {
        unitId: 822,
        systemId: 4,
        id: '03146a87-7bda-4e42-8f44-93c9fa51c799',
        name: '发动机567',
        location: '[-0.24460037052631378, 0.12223395705223083, 0.07748663425445557]',
        vertices: 463,
        polygons: 209,
        lod: ['LOD_3'],
        name_work: 'dtl567',
        name_ua: 'Гвинт дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Šroub škrtící klapky',
            en: 'Throttle valve screw',
            ua: 'Гвинт дросельної заслінки',
            bg: 'Винт на дроселната клапа'
        }
    },
    {
        unitId: 823,
        systemId: 4,
        id: '15627d7b-7ef9-4963-9e2c-dc0b40580b4a',
        name: '发动机566',
        location: '[-0.24460037052631378, 0.10069677233695984, 0.07748663425445557]',
        vertices: 463,
        polygons: 209,
        lod: ['LOD_3'],
        name_work: 'dtl566',
        name_ua: 'Гвинт дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Šroub škrtící klapky',
            en: 'Throttle valve screw',
            ua: 'Гвинт дросельної заслінки',
            bg: 'Винт на дроселната клапа'
        }
    },
    {
        unitId: 824,
        systemId: 4,
        id: 'd3d15fd8-ad2a-4527-8d06-241225917cec',
        name: '发动机565',
        location: '[-0.24448974430561066, 0.11150678992271423, 0.07742226123809814]',
        vertices: 359,
        polygons: 216,
        lod: ['LOD_3'],
        name_work: 'dtl565',
        name_ua: 'Дросельна заслінка',
        group: 1110500,
        tooltip: { cz: 'Škrtící klapka', en: 'Throttle valve', ua: 'Дросельна заслінка', bg: 'Дроселна клапа' }
    },
    {
        unitId: 825,
        systemId: 4,
        id: '041713e9-ca1e-4b87-8860-bbd248ab841c',
        name: '发动机564',
        location: '[-0.25418806076049805, 0.11882945895195007, 0.06320881843566895]',
        vertices: 9026,
        polygons: 5730,
        lod: ['LOD_4'],
        name_work: 'dtl564',
        name_ua: 'Корпус дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Tělo škrtící klapky',
            en: 'Throttle body',
            ua: 'Корпус дросельної заслінки',
            bg: 'Корпус на дроселната клапа'
        }
    },
    {
        unitId: 826,
        systemId: 4,
        id: '2de81212-5d7f-443c-9dc3-6362bfa291c4',
        name: '发动机562',
        location: '[0.06505338847637177, 0.176678866147995, 0.024245912209153175]',
        vertices: 1271,
        polygons: 774,
        lod: ['LOD_4'],
        name_work: 'dtl562',
        name_ua: 'Саморіз',
        group: 1010501,
        tooltip: { cz: 'Samosvorný šroub', en: 'Self-tapping screw', ua: 'Саморіз', bg: 'Самонарезен' }
    },
    {
        unitId: 827,
        systemId: 4,
        id: '23b6851c-5d1a-4522-a194-9f012c499336',
        name: '发动机561',
        location: '[-0.028263555839657784, 0.16331204771995544, 0.0006159187760204077]',
        vertices: 6201,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl561',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 828,
        systemId: 4,
        id: '3a49951c-5cfb-49c7-8e61-b37863d5febd',
        name: '发动机560',
        location: '[-0.10235321521759033, 0.163312166929245, 0.0006159187760204077]',
        vertices: 6201,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl560',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 829,
        systemId: 4,
        id: '2c63b911-01af-49e4-8831-e12cf6a82db2',
        name: '发动机559',
        location: '[0.030316917225718498, 0.16331204771995544, 0.0006159187760204077]',
        vertices: 6201,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl559',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 830,
        systemId: 4,
        id: '0ca1f56e-0744-40df-8f92-2df652f0e26e',
        name: '发动机558',
        location: '[0.08717405796051025, 0.16331180930137634, 0.0006158293690532446]',
        vertices: 6271,
        polygons: 4898,
        lod: ['LOD_3'],
        name_work: 'dtl558',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 831,
        systemId: 4,
        id: '1fe5ad78-d730-460c-81f8-6778956b3615',
        name: '发动机556',
        location: '[-0.009097298607230186, 0.14335224032402039, 0.02494114078581333]',
        vertices: 38052,
        polygons: 29843,
        lod: ['LOD_4'],
        name_work: 'dtl556',
        name_ua: 'Впускний колектор',
        group: 1110800,
        tooltip: { cz: 'Sání', en: 'Intake manifold', ua: 'Впускний колектор', bg: 'Входен колектор' }
    },
    {
        unitId: 832,
        systemId: 4,
        id: '06556604-eeb6-4830-8e65-ae08bca13a46',
        name: '发动机554',
        location: '[-0.16287289559841156, 0.004080620594322681, 0.10702615976333618]',
        vertices: 1281,
        polygons: 884,
        lod: ['LOD_3'],
        name_work: 'dtl554',
        name_ua: 'Датчик положення розподільчого валу',
        group: 1181002,
        tooltip: {
            cz: 'Senzor polohy vačkového hřídele',
            en: 'Camshaft position sensor',
            ua: 'Датчик положення розподільчого валу',
            bg: 'Сензор за позиция на разпределителния вал'
        }
    },
    {
        unitId: 833,
        systemId: 4,
        id: '65928e5e-a622-4b5d-a430-e1ef3c638474',
        name: '发动机553',
        location: '[-0.12302811443805695, -0.1169603168964386, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl553',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 834,
        systemId: 4,
        id: 'd79ee6dc-91b0-4c84-9d24-e1cd3f0b7b84',
        name: '发动机552',
        location: '[-0.12302811443805695, -0.08163943886756897, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl552',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 835,
        systemId: 4,
        id: 'a384c927-18ec-472d-9118-df940f32a104',
        name: '发动机551',
        location: '[-0.04032600298523903, -0.11696115136146545, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl551',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 836,
        systemId: 4,
        id: '152f3c65-b25b-4a2b-8f86-739afbfecfb9',
        name: '发动机550',
        location: '[-0.12302812933921814, -0.013582619838416576, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl550',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 837,
        systemId: 4,
        id: '623bb8ac-41fa-47c7-89b8-1d5c7c1147d0',
        name: '发动机549',
        location: '[-0.12302811443805695, 0.021738378331065178, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl549',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 838,
        systemId: 4,
        id: '3d2aa7e7-73cc-4d96-8615-70b08eb33bb1',
        name: '发动机548',
        location: '[-0.04032440111041069, -0.08164027333259583, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl548',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 839,
        systemId: 4,
        id: '642a077b-e45d-4a18-aa53-047e10da78b1',
        name: '发动机547',
        location: '[-0.040325988084077835, -0.013582619838416576, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl547',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 840,
        systemId: 4,
        id: '6dd3d577-234d-4809-8933-9714dea9b15c',
        name: '发动机546',
        location: '[-0.040325965732336044, 0.021737663075327873, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl546',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 841,
        systemId: 4,
        id: '302afed2-c040-4419-87ef-b8e2cd4dd087',
        name: '发动机545',
        location: '[0.04237769916653633, -0.11696138978004456, 0.11241048574447632]',
        vertices: 693,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl545',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 842,
        systemId: 4,
        id: '9a2926a0-fd3c-4313-bf5a-b3c9b183d516',
        name: '发动机544',
        location: '[0.04237769916653633, -0.08164027333259583, 0.11241048574447632]',
        vertices: 693,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl544',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 843,
        systemId: 4,
        id: '6c84cd46-bbe4-4a69-ace5-b7fcd91ab0a7',
        name: '发动机543',
        location: '[0.04237767681479454, -0.013582619838416576, 0.11241048574447632]',
        vertices: 693,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl543',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 844,
        systemId: 4,
        id: '7480f3e0-9850-4b02-beb4-caab9b3c1842',
        name: '发动机542',
        location: '[0.04237769916653633, 0.021737663075327873, 0.11241048574447632]',
        vertices: 693,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl542',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 845,
        systemId: 4,
        id: '9ffcc9c1-cbf0-42ec-951e-85faa35bfbb4',
        name: '发动机541',
        location: '[0.1250782608985901, -0.11696138978004456, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl541',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 846,
        systemId: 4,
        id: '634788d2-5404-4432-9e90-5ead7715d248',
        name: '发动机540',
        location: '[0.1250782310962677, -0.08164027333259583, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl540',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 847,
        systemId: 4,
        id: '932f044f-7cbd-4570-a7fe-998781c6d776',
        name: '发动机539',
        location: '[0.1250782459974289, -0.013582619838416576, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl539',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 848,
        systemId: 4,
        id: '94205e20-b419-4530-91a7-7204f9c0fd9e',
        name: '发动机538',
        location: '[0.1250782608985901, 0.021737663075327873, 0.11241048574447632]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl538',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 849,
        systemId: 4,
        id: '3fb29700-b634-4c16-9866-6a20a8a7255a',
        name: '发动机537',
        location: '[0.1849517673254013, -0.116961270570755, 0.11239784955978394]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl537',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 850,
        systemId: 4,
        id: '77e016f1-f95b-4122-a7ee-b8e0c07ce651',
        name: '发动机536',
        location: '[0.1849517673254013, -0.08164027333259583, 0.11239784955978394]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl536',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 851,
        systemId: 4,
        id: '98a872a4-716f-46c3-9f3e-d233324e15b2',
        name: '发动机535',
        location: '[-0.12302812933921814, 0.004080262966454029, 0.11736410856246948]',
        vertices: 1108,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl535',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 852,
        systemId: 4,
        id: '75295683-47c0-4fdc-af90-dcd206cfb670',
        name: '发动机534',
        location: '[-0.12302811443805695, -0.09929707646369934, 0.11736410856246948]',
        vertices: 1108,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl534',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 853,
        systemId: 4,
        id: '13780c04-3154-455b-b495-7608cd3151fa',
        name: '发动机533',
        location: '[-0.040325988084077835, 0.004079547710716724, 0.11736410856246948]',
        vertices: 1108,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl533',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 854,
        systemId: 4,
        id: 'f3bfbf9b-925c-4681-b6df-209da4007c92',
        name: '发动机532',
        location: '[-0.04032447561621666, -0.0992979109287262, 0.11736410856246948]',
        vertices: 1108,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl532',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 855,
        systemId: 4,
        id: '946d1993-6708-46ed-a572-0fda81fcf8c8',
        name: '发动机531',
        location: '[0.04237769916653633, 0.004079547710716724, 0.11736410856246948]',
        vertices: 1109,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl531',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 856,
        systemId: 4,
        id: 'e259ac8a-f7aa-4744-966a-a98ec8adf8e2',
        name: '发动机530',
        location: '[0.04237769916653633, -0.0992979109287262, 0.11736410856246948]',
        vertices: 1109,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl530',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 857,
        systemId: 4,
        id: 'e9d8de80-e3c7-435e-b0b8-9e5d8f86958d',
        name: '发动机529',
        location: '[0.1250782310962677, 0.004079547710716724, 0.11736410856246948]',
        vertices: 1108,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl529',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 858,
        systemId: 4,
        id: 'a3f131c3-a11b-4e03-99d8-b479e01a337f',
        name: '发动机528',
        location: '[0.1250782608985901, -0.0992979109287262, 0.11736410856246948]',
        vertices: 1108,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl528',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 859,
        systemId: 4,
        id: '01f9d6e9-c6ec-4605-95fd-4f6085a96f69',
        name: '发动机527',
        location: '[0.1849517673254013, -0.09929803013801575, 0.11736410856246948]',
        vertices: 1108,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl527',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 860,
        systemId: 4,
        id: '19eaef89-f908-403a-88fd-bedeee1e24af',
        name: '发动机526',
        location: '[-0.3037129044532776, 0.05397769436240196, 0.08307915925979614]',
        vertices: 865,
        polygons: 664,
        lod: ['LOD_4'],
        name_work: 'dtl526',
        name_ua: 'Трубка рециркуляції вихлопних газів',
        group: 1130700,
        tooltip: {
            cz: 'Potrubí recirkulace výfukových plynů',
            en: 'Exhaust gas recirculation tube',
            ua: 'Трубка рециркуляції вихлопних газів',
            bg: 'Тръба за рециркулация на отработени газове'
        }
    },
    {
        unitId: 861,
        systemId: 4,
        id: '4d810056-6974-46c3-989c-cf3e76359e33',
        name: '发动机525',
        location: '[-0.25815367698669434, 0.16996285319328308, 0.028966421261429787]',
        vertices: 1139,
        polygons: 772,
        lod: ['LOD_4'],
        name_work: 'dtl525',
        name_ua: 'Термозахист трубки рециркуляції вихлопних газів',
        group: 1130700,
        tooltip: {
            cz: 'Tepelná ochrana potrubí recirkulace výfukových plynů',
            en: 'Thermal protection of the exhaust gas recirculation pipe',
            ua: 'Термозахист трубки рециркуляції вихлопних газів',
            bg: 'Термична защита на тръбата за рециркулиране на отработените газове'
        }
    },
    {
        unitId: 862,
        systemId: 4,
        id: '309ccb55-7b7d-42c4-8435-c3c03e2939eb',
        name: '发动机524',
        location: '[-0.24466051161289215, 0.135260671377182, 0.03276222199201584]',
        vertices: 1221,
        polygons: 890,
        lod: ['LOD_4'],
        name_work: 'dtl524',
        name_ua: 'Трубка рециркуляції вихлопних газів',
        group: 1130700,
        tooltip: {
            cz: 'Potrubí recirkulace výfukových plynů',
            en: 'Exhaust gas recirculation tube',
            ua: 'Трубка рециркуляції вихлопних газів',
            bg: 'Тръба за рециркулация на отработени газове'
        }
    },
    {
        unitId: 863,
        systemId: 4,
        id: 'f6e58ce0-bcd6-4f3f-8775-596997ab6ea8',
        name: '发动机523',
        location: '[-0.16911011934280396, 0.17400583624839783, 0.023781830444931984]',
        vertices: 527,
        polygons: 386,
        lod: ['LOD_4'],
        name_work: 'dtl523',
        name_ua: 'Трубка рециркуляції вихлопних газів',
        group: 1130700,
        tooltip: {
            cz: 'Potrubí recirkulace výfukových plynů',
            en: 'Exhaust gas recirculation tube',
            ua: 'Трубка рециркуляції вихлопних газів',
            bg: 'Тръба за рециркулация на отработени газове'
        }
    },
    {
        unitId: 864,
        systemId: 4,
        id: 'b859273e-097c-4fdd-bf9c-1b423ca3df9b',
        name: '发动机522',
        location: '[-0.2993229031562805, 0.04669841751456261, 0.09943151473999023]',
        vertices: 247,
        polygons: 180,
        lod: ['LOD_4'],
        name_work: 'dtl522',
        name_ua: 'Хомут трубки рециркуляції вихлопних газів',
        group: 1130503,
        tooltip: {
            cz: 'Svorka potrubí recirkulace výfukových plynů',
            en: 'Clamp for exhaust gas recirculation tube',
            ua: 'Хомут трубки рециркуляції вихлопних газів',
            bg: 'Скобаза тръба за рециркулация на отработени газове'
        }
    },
    {
        unitId: 865,
        systemId: 4,
        id: '89821ebb-dc74-47ff-9e45-11130cf3d89a',
        name: '发动机521',
        location: '[-0.27151060104370117, 0.10787543654441833, 0.09833121299743652]',
        vertices: 1017,
        polygons: 660,
        lod: ['LOD_4'],
        name_work: 'dtl521',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 866,
        systemId: 4,
        id: 'a65b46b9-bfab-4cbd-9487-002d34d1587e',
        name: '发动机520',
        location: '[-0.2877374291419983, 0.059344615787267685, 0.11845666170120239]',
        vertices: 3602,
        polygons: 2154,
        lod: ['LOD_4'],
        name_work: 'dtl520',
        name_ua: 'Датчик тиску вихлопних газів',
        group: 1180403,
        tooltip: {
            cz: 'Senzor tlaku výfukových plynů',
            en: 'Exhaust gas pressure sensor',
            ua: 'Датчик тиску вихлопних газів',
            bg: 'Сензор за налягане на отработени газове'
        }
    },
    {
        unitId: 867,
        systemId: 4,
        id: '2ffe62c4-a485-4023-a18d-1bd5bfbf61db',
        name: '发动机517',
        location: '[-0.1364264041185379, 0.16215738654136658, -0.19608987867832184]',
        vertices: 617,
        polygons: 420,
        lod: ['LOD_4'],
        name_work: 'dtl517',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 868,
        systemId: 4,
        id: 'a1f5c7da-d9d4-448e-be10-c534634a8f17',
        name: '发动机516',
        location: '[0.12657804787158966, 0.12684091925621033, -0.017437821254134178]',
        vertices: 1108,
        polygons: 689,
        lod: ['LOD_4'],
        name_work: 'dtl516',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 869,
        systemId: 4,
        id: '1be26d7d-c303-4fc9-87b3-1b98e7c8f074',
        name: '发动机515',
        location: '[-0.1786474734544754, 0.14294442534446716, -0.14155213534832]',
        vertices: 1753,
        polygons: 1350,
        lod: ['LOD_4'],
        name_work: 'dtl515',
        name_ua: 'Шланг системи смазки двигуна',
        group: 1100302,
        tooltip: {
            cz: 'Hadicový systém mazání motoru',
            en: 'Engine lubrication system hose',
            ua: 'Шланг системи смазки двигуна',
            bg: 'Гумен маркуч на системата за смазване на двигателя'
        }
    },
    {
        unitId: 870,
        systemId: 4,
        id: '877ad931-cc99-4dee-85c1-a31236a96ac8',
        name: '发动机514',
        location: '[-0.20862260460853577, 0.14709052443504333, -0.1092972457408905]',
        vertices: 7279,
        polygons: 3938,
        lod: ['LOD_4'],
        name_work: 'dtl514',
        name_ua: 'Кріплення шланга системи смазки двигуна',
        group: 1100302,
        tooltip: {
            cz: 'Upevnění hadice mazacího systému motoru',
            en: 'Engine lubrication system hose mounting',
            ua: 'Кріплення шланга системи смазки двигуна',
            bg: 'Закрепване на гумения маркуч на системата за смазване на двигателя'
        }
    },
    {
        unitId: 871,
        systemId: 4,
        id: '8d87731b-f535-4f4e-9da3-94b946e9f336',
        name: '发动机513',
        location: '[-0.2006262242794037, 0.17981204390525818, 0.019870275631546974]',
        vertices: 2611,
        polygons: 1890,
        lod: ['LOD_4'],
        name_work: 'dtl513',
        name_ua: 'Трубка забірна системи змащення двигуна',
        group: 1141603,
        tooltip: {
            cz: 'Sací trubka mazacího systému motoru',
            en: 'Engine lubrication system intake tube',
            ua: 'Трубка забірна системи змащення двигуна',
            bg: 'Всмукателна тръба на системата за смазване на двигателя'
        }
    },
    {
        unitId: 872,
        systemId: 4,
        id: 'a4216f82-5e3d-4f0b-b926-37a7847760ac',
        name: '发动机512',
        location: '[-0.1327185183763504, 0.13140782713890076, -0.1933339685201645]',
        vertices: 611,
        polygons: 420,
        lod: ['LOD_4'],
        name_work: 'dtl512',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 873,
        systemId: 4,
        id: 'e1a2c04c-a773-49d7-a115-0f7b32b71835',
        name: '发动机511',
        location: '[-0.21438446640968323, 0.166738361120224, -0.067638099193573]',
        vertices: 3770,
        polygons: 2690,
        lod: ['LOD_4'],
        name_work: 'dtl511',
        name_ua: 'Зʼєднувач трубки смазки двигуна',
        group: 1100305,
        tooltip: {
            cz: 'Spojovací hadice mazání motoru',
            en: 'Engine lubrication tube connector',
            ua: 'Зʼєднувач трубки смазки двигуна',
            bg: 'Съединител на тръбата за смазване на двигателя'
        }
    },
    {
        unitId: 874,
        systemId: 4,
        id: '69d11aaf-258d-421f-a656-6be570a0f9de',
        name: '发动机510',
        location: '[-0.22181366384029388, 0.1580958068370819, -0.08530783653259277]',
        vertices: 400,
        polygons: 220,
        lod: ['LOD_4'],
        name_work: 'dtl510',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 875,
        systemId: 4,
        id: 'c21d28ec-6d1d-4ea6-8d42-1c24a8a8d474',
        name: '发动机509',
        location: '[-0.20640045404434204, 0.17420050501823425, -0.06687283515930176]',
        vertices: 221,
        polygons: 128,
        lod: ['LOD_4'],
        name_work: 'dtl509',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 876,
        systemId: 4,
        id: 'f0430495-0e66-48cc-a6f1-5e5fc7d833fd',
        name: '发动机508',
        location: '[-0.17774324119091034, 0.11706587672233582, -0.10094383358955383]',
        vertices: 1085,
        polygons: 694,
        lod: ['LOD_4'],
        name_work: 'dtl508',
        name_ua: 'Кріплення шлангів системи охолодження',
        group: 1100300,
        tooltip: {
            cz: 'Upevnění hadic chladícího systému',
            en: 'Cooling system hose mounting',
            ua: 'Кріплення шлангів системи охолодження',
            bg: 'Закрепване на маркучите на охладителната система'
        }
    },
    {
        unitId: 877,
        systemId: 4,
        id: '2878c556-d8de-420c-b905-61ba6c3d1c8a',
        name: '发动机507',
        location: '[-0.2155514657497406, 0.16644880175590515, 0.10913687944412231]',
        vertices: 199,
        polygons: 144,
        lod: ['LOD_4'],
        name_work: 'dtl507',
        name_ua: 'Хомут трубки забірної системи змащення двигуна',
        group: 1141603,
        tooltip: {
            cz: 'Svorka sacího potrubí mazacího systému motoru',
            en: 'Engine lubrication system intake tube clamp',
            ua: 'Хомут трубки забірної системи змащення двигуна',
            bg: 'Скоба за всмукателната тръба на системата за смазване на двигателя'
        }
    },
    {
        unitId: 878,
        systemId: 4,
        id: 'f1fc905a-8763-4690-a0a6-ba4530252291',
        name: '发动机506',
        location: '[-0.16697464883327484, 0.09389194846153259, -0.09299251437187195]',
        vertices: 294,
        polygons: 178,
        lod: ['LOD_4'],
        name_work: 'dtl506',
        name_ua: 'Гайка кріплення стартера',
        group: 1170700,
        tooltip: {
            cz: 'Matice upevnění startéru',
            en: 'Starter mounting nut',
            ua: 'Гайка кріплення стартера',
            bg: 'Гайка за закрепване на стартера'
        }
    },
    {
        unitId: 879,
        systemId: 4,
        id: 'b691da52-81d9-4c02-ade9-63339431dd24',
        name: '发动机505',
        location: '[0.07855764776468277, 0.026651589199900627, 0.1407528966665268]',
        vertices: 349,
        polygons: 288,
        lod: ['LOD_3'],
        name_work: 'dtl505',
        name_ua: 'Ущільнення щупа рівня мастила',
        group: 1141402,
        tooltip: {
            cz: 'Těsnění měrky hladiny oleje',
            en: 'Dipstick seal',
            ua: 'Ущільнення щупа рівня мастила',
            bg: 'Уплътнение на сондата за нивото на маслото'
        }
    },
    {
        unitId: 880,
        systemId: 4,
        id: '2f4d93f7-bbdd-4498-8645-7461a9980f4b',
        name: '发动机504',
        location: '[0.07855764776468277, 0.027202455326914787, 0.1588486284017563]',
        vertices: 3068,
        polygons: 2586,
        lod: ['LOD_4'],
        name_work: 'dtl504',
        name_ua: 'Щуп рівня мастила',
        group: 1141402,
        tooltip: {
            cz: 'Měrka hladiny oleje',
            en: 'Oil level dipstick',
            ua: 'Щуп рівня мастила',
            bg: 'Сонда за нивото на маслото'
        }
    },
    {
        unitId: 881,
        systemId: 4,
        id: '226233ef-8da6-4e78-bb25-e0df9db43a57',
        name: '发动机503',
        location: '[0.11517278850078583, -0.10687974095344543, -0.28055503964424133]',
        vertices: 393,
        polygons: 262,
        lod: ['LOD_3'],
        name_work: 'dtl503',
        name_ua: 'Болт кріплення масляного дефлектора',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejového deflektoru',
            en: 'Oil deflector mounting bolt',
            ua: 'Болт кріплення масляного дефлектора',
            bg: 'Болт за закрепване на масления дефлектор'
        }
    },
    {
        unitId: 882,
        systemId: 4,
        id: '5a2da579-d58d-487d-aac1-abde7ce0c070',
        name: '发动机502',
        location: '[0.09708034247159958, -0.03365388885140419, -0.28055503964424133]',
        vertices: 393,
        polygons: 262,
        lod: ['LOD_3'],
        name_work: 'dtl502',
        name_ua: 'Болт кріплення масляного дефлектора',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejového deflektoru',
            en: 'Oil deflector mounting bolt',
            ua: 'Болт кріплення масляного дефлектора',
            bg: 'Болт за закрепване на масления дефлектор'
        }
    },
    {
        unitId: 883,
        systemId: 4,
        id: 'f5dc53c0-73dc-4d26-b7ce-c7f749367377',
        name: '发动机501',
        location: '[0.0018881153082475066, -0.1137714684009552, -0.2902033030986786]',
        vertices: 393,
        polygons: 262,
        lod: ['LOD_3'],
        name_work: 'dtl501',
        name_ua: 'Болт кріплення масляного дефлектора',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejového deflektoru',
            en: 'Oil deflector mounting bolt',
            ua: 'Болт кріплення масляного дефлектора',
            bg: 'Болт за закрепване на масления дефлектор'
        }
    },
    {
        unitId: 884,
        systemId: 4,
        id: '28632ac2-5126-4cca-88fd-4d7885ef8f21',
        name: '发动机500',
        location: '[-0.04807838425040245, -0.10687974095344543, -0.28055503964424133]',
        vertices: 393,
        polygons: 262,
        lod: ['LOD_3'],
        name_work: 'dtl500',
        name_ua: 'Болт кріплення масляного дефлектора',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejového deflektoru',
            en: 'Oil deflector mounting bolt',
            ua: 'Болт кріплення масляного дефлектора',
            bg: 'Болт за закрепване на масления дефлектор'
        }
    },
    {
        unitId: 885,
        systemId: 4,
        id: '0a1ba9b9-44b8-42b4-b4c0-4bdfdb9453c9',
        name: '发动机499',
        location: '[-0.048078346997499466, 0.026564685627818108, -0.28055503964424133]',
        vertices: 393,
        polygons: 262,
        lod: ['LOD_3'],
        name_work: 'dtl499',
        name_ua: 'Болт кріплення масляного дефлектора',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejového deflektoru',
            en: 'Oil deflector mounting bolt',
            ua: 'Болт кріплення масляного дефлектора',
            bg: 'Болт за закрепване на масления дефлектор'
        }
    },
    {
        unitId: 886,
        systemId: 4,
        id: '57e137a0-9095-4557-bf69-b21167897a55',
        name: '发动机498',
        location: '[0.0018858261173591018, -0.11523488163948059, -0.3461136519908905]',
        vertices: 164,
        polygons: 120,
        lod: ['LOD_3'],
        name_work: 'dtl498',
        name_ua: 'Прокладка пробки зливу мастила',
        group: 1141501,
        tooltip: {
            cz: 'Guma zátky vypouštění oleje',
            en: 'Oil drain plug gasket',
            ua: 'Прокладка пробки зливу мастила',
            bg: 'Уплътнение на водещия болт за източване на маслото'
        }
    },
    {
        unitId: 887,
        systemId: 4,
        id: 'f6ff1731-e7a9-4270-868e-d7db6f58fecd',
        name: '发动机497',
        location: '[0.0018858261173591018, -0.11368432641029358, -0.3461136519908905]',
        vertices: 719,
        polygons: 498,
        lod: ['LOD_4'],
        name_work: 'dtl497',
        name_ua: 'Пробка зливу мастила',
        group: 1141501,
        tooltip: {
            cz: 'Zátka výtoku oleje',
            en: 'Oil drain plug',
            ua: 'Пробка зливу мастила',
            bg: 'Запушалка за източване на маслото'
        }
    },
    {
        unitId: 888,
        systemId: 4,
        id: '1ede08f0-55ff-44ce-b981-ffd5925ce9d6',
        name: '发动机496',
        location: '[0.016597243025898933, -0.04523936286568642, -0.27973639965057373]',
        vertices: 2238,
        polygons: 1760,
        lod: ['LOD_3'],
        name_work: 'dtl496',
        name_ua: 'Масляний дефлектор',
        group: 1141401,
        tooltip: { cz: 'Olejový deflektor', en: 'Oil deflector', ua: 'Масляний дефлектор', bg: 'Маслен дефлектор' }
    },
    {
        unitId: 889,
        systemId: 4,
        id: '844edb06-350b-49aa-be00-ab800f82fbcd',
        name: '发动机495',
        location: '[0.005494493991136551, -0.023311646655201912, -0.31380683183670044]',
        vertices: 58209,
        polygons: 40008,
        lod: ['LOD_4'],
        name_work: 'dtl495',
        name_ua: 'Масляний піддон',
        group: 1141401,
        tooltip: { cz: 'Olejová vana', en: 'Oil pan', ua: 'Масляний піддон', bg: 'Маслен съд' }
    },
    {
        unitId: 890,
        systemId: 4,
        id: '316c7764-7495-4e29-be8c-c7663a88909a',
        name: '发动机494',
        location: '[-0.16696225106716156, 0.03483530506491661, -0.28904059529304504]',
        vertices: 459,
        polygons: 286,
        lod: ['LOD_3'],
        name_work: 'dtl494',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 891,
        systemId: 4,
        id: 'f7a85ca6-6026-4ef2-bd84-866366b4e7e2',
        name: '发动机493',
        location: '[-0.16696229577064514, -0.12324574589729309, -0.28904059529304504]',
        vertices: 459,
        polygons: 286,
        lod: ['LOD_3'],
        name_work: 'dtl493',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 892,
        systemId: 4,
        id: '5b60557a-02c8-4351-819d-15f7ea20ba95',
        name: '发动机492',
        location: '[0.17332319915294647, -0.12557366490364075, -0.26190391182899475]',
        vertices: 435,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl492',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 893,
        systemId: 4,
        id: '461e7ceb-dfac-426a-8da5-c9885243ba93',
        name: '发动机491',
        location: '[0.12077207863330841, -0.1318613588809967, -0.26190391182899475]',
        vertices: 435,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl491',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 894,
        systemId: 4,
        id: '8dacc498-63f1-4412-b34b-de1ad72e8cca',
        name: '发动机490',
        location: '[0.06692931056022644, -0.13703075051307678, -0.26190391182899475]',
        vertices: 434,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl490',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 895,
        systemId: 4,
        id: '691213cb-8da7-40e8-8648-54f3e455cba7',
        name: '发动机489',
        location: '[0.0018881153082475066, -0.13961532711982727, -0.26190391182899475]',
        vertices: 434,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl489',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 896,
        systemId: 4,
        id: 'ffe5e250-ee04-4f4e-874e-c2819810728f',
        name: '发动机488',
        location: '[-0.07995393127202988, -0.13556626439094543, -0.26190391182899475]',
        vertices: 435,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl488',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 897,
        systemId: 4,
        id: '02648836-d630-4667-bd5e-63056f0bb346',
        name: '发动机487',
        location: '[-0.13508820533752441, 0.07704779505729675, -0.26190391182899475]',
        vertices: 435,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl487',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 898,
        systemId: 4,
        id: 'cc46c76b-2973-4c20-8216-e9cf15ceebc4',
        name: '发动机486',
        location: '[-0.05066299811005592, 0.07705006003379822, -0.26190391182899475]',
        vertices: 435,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl486',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 899,
        systemId: 4,
        id: 'dbbc1702-35b7-4f52-b3a2-9b453102d14e',
        name: '发动机485',
        location: '[-0.0006964185740798712, 0.05292591080069542, -0.26190391182899475]',
        vertices: 434,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl485',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 900,
        systemId: 4,
        id: '976503b1-3594-4f99-bd63-157d2e1882ae',
        name: '发动机484',
        location: '[0.043237682431936264, 0.06584832072257996, -0.26190391182899475]',
        vertices: 434,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl484',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 901,
        systemId: 4,
        id: '3cfdb294-ed37-45b2-84ee-0f52e4e85848',
        name: '发动机483',
        location: '[0.10311125218868256, 0.10160014033317566, -0.26190391182899475]',
        vertices: 435,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl483',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 902,
        systemId: 4,
        id: '0884a0d3-2eee-46b3-91a7-f67a16b1aa99',
        name: '发动机482',
        location: '[0.17737242579460144, 0.0947093665599823, -0.26190391182899475]',
        vertices: 435,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl482',
        name_ua: 'Болт кріплення масляного піддона',
        group: 1141401,
        tooltip: {
            cz: 'Šroub upevnění olejové vany',
            en: 'Oil pan mounting bolt',
            ua: 'Болт кріплення масляного піддона',
            bg: 'Болт за закрепване на масления съд'
        }
    },
    {
        unitId: 903,
        systemId: 4,
        id: 'cb913712-de1b-4b85-bf0f-3303182f8f1b',
        name: '发动机481',
        location: '[-0.2440626472234726, 0.052757348865270615, 0.026143843308091164]',
        vertices: 1162,
        polygons: 918,
        lod: ['LOD_4'],
        name_work: 'dtl481',
        name_ua: 'Шланг вакуумний',
        group: 1190702,
        tooltip: { cz: 'Hadicový vakuu', en: 'Vacuum hose', ua: 'Шланг вакуумний', bg: 'Вакуумен маркуч' }
    },
    {
        unitId: 904,
        systemId: 4,
        id: 'ec90c79c-1109-476c-b491-c5bbaa6af662',
        name: '发动机480',
        location: '[-0.2309228628873825, 0.03871127590537071, 0.023893171921372414]',
        vertices: 896,
        polygons: 496,
        lod: ['LOD_4'],
        name_work: 'dtl480',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 905,
        systemId: 4,
        id: 'f8ddd54d-d534-494f-9523-58c2595bf50f',
        name: '发动机479',
        location: '[-0.2502760887145996, 0.0646030604839325, 0.04566328972578049]',
        vertices: 920,
        polygons: 496,
        lod: ['LOD_4'],
        name_work: 'dtl479',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 906,
        systemId: 4,
        id: '109d21e1-2327-4ed9-a6b6-3209a002146a',
        name: '发动机478',
        location: '[-0.20332184433937073, -0.07974293828010559, 0.06394767761230469]',
        vertices: 438,
        polygons: 384,
        lod: ['LOD_4'],
        name_work: 'dtl478',
        name_ua: 'Втулка кріплення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Vložka upevnění desky řízení uzavíracích ventilů',
            en: 'Swirl flap control plate mounting sleeve',
            ua: 'Втулка кріплення плити  управління вихровими заслінками',
            bg: 'Втулка за закрепване на плочата за управление на спирателните клапани'
        }
    },
    {
        unitId: 907,
        systemId: 4,
        id: '4c2a0138-38cd-4c72-87d5-f52624a4bbd9',
        name: '发动机477',
        location: '[-0.20332184433937073, -0.031069548800587654, 0.06394767761230469]',
        vertices: 439,
        polygons: 384,
        lod: ['LOD_4'],
        name_work: 'dtl477',
        name_ua: 'Втулка кріплення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Vložka upevnění desky řízení uzavíracích ventilů',
            en: 'Swirl flap control plate mounting sleeve',
            ua: 'Втулка кріплення плити  управління вихровими заслінками',
            bg: 'Втулка за закрепване на плочата за управление на спирателните клапани'
        }
    },
    {
        unitId: 908,
        systemId: 4,
        id: '21ad0a3e-2fea-4cac-aec6-9e89ecd46e99',
        name: '发动机476',
        location: '[-0.20332184433937073, -0.025900157168507576, 0.007090145722031593]',
        vertices: 439,
        polygons: 384,
        lod: ['LOD_4'],
        name_work: 'dtl476',
        name_ua: 'Втулка кріплення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Vložka upevnění desky řízení uzavíracích ventilů',
            en: 'Swirl flap control plate mounting sleeve',
            ua: 'Втулка кріплення плити  управління вихровими заслінками',
            bg: 'Втулка за закрепване на плочата за управление на спирателните клапани'
        }
    },
    {
        unitId: 909,
        systemId: 4,
        id: '1d2b0858-dabf-462d-b8e7-1da6fa03db82',
        name: '发动机475',
        location: '[-0.20332184433937073, -0.0844813883304596, 0.0083823436871171]',
        vertices: 439,
        polygons: 384,
        lod: ['LOD_4'],
        name_work: 'dtl475',
        name_ua: 'Втулка кріплення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Vložka upevnění desky řízení uzavíracích ventilů',
            en: 'Swirl flap control plate mounting sleeve',
            ua: 'Втулка кріплення плити  управління вихровими заслінками',
            bg: 'Втулка за закрепване на плочата за управление на спирателните клапани'
        }
    },
    {
        unitId: 910,
        systemId: 4,
        id: '290acb4a-887b-4cfc-8966-4f0ee9d8b5d1',
        name: '发动机474',
        location: '[-0.2181403487920761, -0.08301165699958801, 0.03896474093198776]',
        vertices: 764,
        polygons: 552,
        lod: ['LOD_3'],
        name_work: 'dtl474',
        name_ua: 'Ущільнення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Těsnění desky řízení uzavíracích ventilů',
            en: 'Swirl flap control plate seal',
            ua: 'Ущільнення плити  управління вихровими заслінками',
            bg: 'Уплътнение на плочата за управление на вихровите клапани'
        }
    },
    {
        unitId: 911,
        systemId: 4,
        id: '6bfb2e19-b608-46ce-bcd5-d4072d505b82',
        name: '发动机473',
        location: '[-0.24161164462566376, -0.06466701626777649, 0.03847258538007736]',
        vertices: 18918,
        polygons: 11404,
        lod: ['LOD_4'],
        name_work: 'dtl473',
        name_ua: 'Плита управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Deska řízení uzavíracích ventilů',
            en: 'Swirl flap control plate',
            ua: 'Плита управління вихровими заслінками',
            bg: 'Плоча за управление на вихровите клапани'
        }
    },
    {
        unitId: 912,
        systemId: 4,
        id: 'c08952b7-81cd-4021-9a42-059d22f1f220',
        name: '发动机472',
        location: '[-0.19974274933338165, -0.06113365665078163, 0.038892798125743866]',
        vertices: 721,
        polygons: 484,
        lod: ['LOD_3'],
        name_work: 'dtl472',
        name_ua: 'Ущільнення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Těsnění desky řízení uzavíracích ventilů',
            en: 'Swirl flap control plate seal',
            ua: 'Ущільнення плити  управління вихровими заслінками',
            bg: 'Уплътнение на плочата за управление на вихровите клапани'
        }
    },
    {
        unitId: 913,
        systemId: 4,
        id: '4b85dc5d-63fb-42e2-abb3-bb9fbae607e0',
        name: '发动机471',
        location: '[-0.2543211579322815, -0.1258327066898346, 0.07859307527542114]',
        vertices: 467,
        polygons: 336,
        lod: ['LOD_4'],
        name_work: 'dtl471',
        name_ua: 'Різьба кріплення плити управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Závit upevnění desky řízení uzavíracích ventilů',
            en: 'Thread for mounting the swirl flap control plate',
            ua: 'Різьба кріплення плити управління вихровими заслінками',
            bg: 'Резба за монтаж на плочата за управление на вихровите клапани'
        }
    },
    {
        unitId: 914,
        systemId: 4,
        id: '1dbfbd5f-cc5c-4a4b-aad5-0baef510b98b',
        name: '发动机470',
        location: '[-0.22333680093288422, -0.04942039027810097, 0.007187807932496071]',
        vertices: 1023,
        polygons: 568,
        lod: ['LOD_4'],
        name_work: 'dtl470',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 915,
        systemId: 4,
        id: '8b160059-c38c-4442-a43c-754e94ea0657',
        name: '发动机469',
        location: '[0.08614302426576614, 0.0740099847316742, 0.002302134409546852]',
        vertices: 984,
        polygons: 568,
        lod: ['LOD_4'],
        name_work: 'dtl469',
        name_ua: 'Хомут',
        group: 1010901,
        tooltip: { cz: 'Svorka', en: 'Clamp', ua: 'Хомут', bg: 'Скоба' }
    },
    {
        unitId: 916,
        systemId: 4,
        id: 'dec695a5-f9e2-40e3-a49a-ac672b5b7460',
        name: '发动机468',
        location: '[-0.06508789956569672, 0.018627135083079338, -0.002837991341948509]',
        vertices: 6218,
        polygons: 4866,
        lod: ['LOD_4'],
        name_work: 'dtl468',
        name_ua: 'Шланг системи охолодження',
        group: 1100302,
        tooltip: {
            cz: 'Hadicový systém chlazení',
            en: 'Cooling system hose',
            ua: 'Шланг системи охолодження',
            bg: 'Шланг на охлаждащата система'
        }
    },
    {
        unitId: 917,
        systemId: 4,
        id: '0bd0dbef-92bf-4f8d-b44c-128c88a941a8',
        name: '发动机467',
        location: '[0.12306760996580124, 0.08755823969841003, -0.0418856218457222]',
        vertices: 2866,
        polygons: 1770,
        lod: ['LOD_3'],
        name_work: 'dtl467',
        name_ua: 'Ущільнення термостата',
        group: 1100404,
        tooltip: {
            cz: 'Těsnění termostatu',
            en: 'Thermostat seal',
            ua: 'Ущільнення термостата',
            bg: 'Уплътнение на термостата'
        }
    },
    {
        unitId: 918,
        systemId: 4,
        id: '3444ea0c-a0c7-4bc9-9316-73308a2ebc43',
        name: '发动机466',
        location: '[0.12743772566318512, 0.11953791975975037, -0.04212421923875809]',
        vertices: 10843,
        polygons: 8347,
        lod: ['LOD_4'],
        name_work: 'dtl466',
        name_ua: 'Патрубок термостата',
        group: 1100402,
        tooltip: {
            cz: 'Těleso termostatu',
            en: 'Thermostat hose',
            ua: 'Патрубок термостата',
            bg: 'Тръбопровод на термостата'
        }
    },
    {
        unitId: 919,
        systemId: 4,
        id: 'c584ecc8-de57-463a-8212-8fa250422673',
        name: '发动机465',
        location: '[0.12318190187215805, 0.07933065295219421, -0.04773605614900589]',
        vertices: 6079,
        polygons: 4632,
        lod: ['LOD_3'],
        name_work: 'dtl465',
        name_ua: 'Корпус термостата',
        group: 1100402,
        tooltip: {
            cz: 'Upevnění termostatu',
            en: 'Thermostat housing',
            ua: 'Корпус термостата',
            bg: 'Корпус на термостата'
        }
    },
    {
        unitId: 920,
        systemId: 4,
        id: '8588eeac-f5c7-4139-9504-e9b325c4e66e',
        name: '发动机464',
        location: '[0.12299039959907532, 0.07700952887535095, -0.04735160619020462]',
        vertices: 1613,
        polygons: 1260,
        lod: ['LOD_3'],
        name_work: 'dtl464',
        name_ua: 'Болт кріплення термостата',
        group: 1100401,
        tooltip: {
            cz: 'Šroub upevnění termostatu',
            en: 'Thermostat mounting bolt',
            ua: 'Болт кріплення термостата',
            bg: 'Болт за монтаж на термостата'
        }
    },
    {
        unitId: 921,
        systemId: 4,
        id: 'dae60124-8a02-4f7e-8b42-f9b175587aa6',
        name: '发动机463',
        location: '[0.12301024049520493, 0.07485350966453552, -0.047351397573947906]',
        vertices: 15181,
        polygons: 11664,
        lod: ['LOD_3'],
        name_work: 'dtl463',
        name_ua: 'Термостат',
        group: 1100401,
        tooltip: { cz: 'Termostat', en: 'Thermostat', ua: 'Термостат', bg: 'Термостат' }
    },
    {
        unitId: 922,
        systemId: 4,
        id: 'bb310f7f-8224-4b09-a563-e5aff490421c',
        name: '发动机462',
        location: '[0.18124647438526154, 0.06111058220267296, -0.05536336451768875]',
        vertices: 1459,
        polygons: 1224,
        lod: ['LOD_3'],
        name_work: 'dtl462',
        name_ua: 'Підшипник помпи',
        group: 1100204,
        tooltip: { cz: 'Ložisko pumpy', en: 'Pump bearing', ua: 'Підшипник помпи', bg: 'Лагер на помпата' }
    },
    {
        unitId: 923,
        systemId: 4,
        id: '5bd8df20-f8f5-455d-87df-83cea41ef38b',
        name: '发动机461',
        location: '[0.18547917902469635, 0.06111058220267296, -0.05536336451768875]',
        vertices: 719,
        polygons: 576,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl461',
        name_ua: 'Кущільнення корпуса помпи',
        group: 1100202,
        tooltip: '#N/A'
    },
    {
        unitId: 924,
        systemId: 4,
        id: '7fd85e2c-dc85-49ba-a151-e41f06c6275a',
        name: '发动机460',
        location: '[0.20325727760791779, 0.07262119650840759, -0.04969615489244461]',
        vertices: 10189,
        polygons: 7048,
        lod: ['LOD_4'],
        name_work: 'dtl460',
        name_ua: 'Корпус помпи',
        group: 1100207,
        tooltip: { cz: 'Těleso pumpy', en: 'Pump housing', ua: 'Корпус помпи', bg: 'Корпус на помпата' }
    },
    {
        unitId: 925,
        systemId: 4,
        id: 'c85287a8-51c2-486a-a2f7-f3cd07524cb7',
        name: '发动机459',
        location: '[0.21639494597911835, 0.06998404860496521, -0.07733112573623657]',
        vertices: 225,
        polygons: 158,
        lod: ['LOD_3'],
        name_work: 'dtl459',
        name_ua: 'Заглушка корпусу помпи',
        group: 1100202,
        tooltip: {
            cz: 'Zátka tělesa pumpy',
            en: 'Pump housing plug',
            ua: 'Заглушка корпусу помпи',
            bg: 'Щепсел на корпуса на помпата'
        }
    },
    {
        unitId: 926,
        systemId: 4,
        id: '42b1f0fc-fd77-4513-b040-bf198142c1e1',
        name: '发动机458',
        location: '[0.1611262410879135, 0.06111070141196251, -0.05536351352930069]',
        vertices: 3227,
        polygons: 2207,
        lod: ['LOD_3'],
        name_work: 'dtl458',
        name_ua: 'Крильчатка насоса (помпи)',
        group: 1100203,
        tooltip: {
            cz: 'Kolo čerpadla (pumpa)',
            en: 'Pump impeller (pump)',
            ua: 'Крильчатка насоса (помпи)',
            bg: 'Работно колело на помпата'
        }
    },
    {
        unitId: 927,
        systemId: 4,
        id: '5270b8ab-44be-4a79-8558-7cba5bf17608',
        name: '发动机457',
        location: '[0.19490355253219604, 0.066709965467453, -0.007981694303452969]',
        vertices: 290,
        polygons: 190,
        lod: ['LOD_4'],
        name_work: 'dtl457',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 928,
        systemId: 4,
        id: '5e9341ee-630a-472e-a526-f26815de64ee',
        name: '发动机456',
        location: '[0.19490355253219604, 0.10900828242301941, -0.055363304913043976]',
        vertices: 290,
        polygons: 190,
        lod: ['LOD_3'],
        name_work: 'dtl456',
        name_ua: 'Болт кріплення помпи',
        group: 1100201,
        tooltip: {
            cz: 'Šroub upevnění pumpy',
            en: 'Pump mounting bolt',
            ua: 'Болт кріплення помпи',
            bg: 'Болт за монтаж на помпата'
        }
    },
    {
        unitId: 929,
        systemId: 4,
        id: 'a782e9a4-0195-4425-8652-430f5686b225',
        name: '发动机455',
        location: '[0.19490355253219604, 0.04301806911826134, -0.09051164984703064]',
        vertices: 290,
        polygons: 190,
        lod: ['LOD_3'],
        name_work: 'dtl455',
        name_ua: 'Болт кріплення помпи',
        group: 1100201,
        tooltip: {
            cz: 'Šroub upevnění pumpy',
            en: 'Pump mounting bolt',
            ua: 'Болт кріплення помпи',
            bg: 'Болт за монтаж на помпата'
        }
    },
    {
        unitId: 930,
        systemId: 4,
        id: '2fad7f6e-1419-4ea1-ad75-b2c9671e5250',
        name: '发动机454',
        location: '[0.1465296745300293, 0.08841785788536072, -0.024608289822936058]',
        vertices: 289,
        polygons: 190,
        lod: ['LOD_4'],
        name_work: 'dtl454',
        name_ua: 'Болт кріплення термостата',
        group: 1100402,
        tooltip: {
            cz: 'Šroub upevnění termostatu',
            en: 'Thermostat mounting bolt',
            ua: 'Болт кріплення термостата',
            bg: 'Болт за монтаж на термостата'
        }
    },
    {
        unitId: 931,
        systemId: 4,
        id: 'a95cc748-c938-4eb7-97cd-0b5d5ed3ca13',
        name: '发动机453',
        location: '[0.10311125218868256, 0.08841785788536072, -0.024263834580779076]',
        vertices: 289,
        polygons: 190,
        lod: ['LOD_4'],
        name_work: 'dtl453',
        name_ua: 'Болт кріплення термостата',
        group: 1100402,
        tooltip: {
            cz: 'Šroub upevnění termostatu',
            en: 'Thermostat mounting bolt',
            ua: 'Болт кріплення термостата',
            bg: 'Болт за монтаж на термостата'
        }
    },
    {
        unitId: 932,
        systemId: 4,
        id: '6b6eafd6-6baf-449b-aada-5705e62d4c6d',
        name: '发动机452',
        location: '[0.09949369728565216, 0.08841785788536072, -0.0698360800743103]',
        vertices: 289,
        polygons: 190,
        lod: ['LOD_4'],
        name_work: 'dtl452',
        name_ua: 'Болт кріплення термостата',
        group: 1100402,
        tooltip: {
            cz: 'Šroub upevnění termostatu',
            en: 'Thermostat mounting bolt',
            ua: 'Болт кріплення термостата',
            bg: 'Болт за монтаж на термостата'
        }
    },
    {
        unitId: 933,
        systemId: 4,
        id: '87a5e3b2-1a56-4504-b3c2-99ce71189b67',
        name: '发动机451',
        location: '[-0.2005215734243393, -0.02590075321495533, 0.007090115919709206]',
        vertices: 292,
        polygons: 190,
        lod: ['LOD_4'],
        name_work: 'dtl451',
        name_ua: 'Болт кріплення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Šroub upevnění desky řízení uzavíracích ventilů',
            en: 'Swirl valve control plate mounting bolt',
            ua: 'Болт кріплення плити  управління вихровими заслінками',
            bg: 'Болт за монтаж на плочата за управление на вихровите клапани'
        }
    },
    {
        unitId: 934,
        systemId: 4,
        id: '96ddcba0-ecb9-4920-9e17-88b2bd68550a',
        name: '发动机450',
        location: '[-0.2005215734243393, -0.031069906428456306, 0.06394797563552856]',
        vertices: 292,
        polygons: 190,
        lod: ['LOD_4'],
        name_work: 'dtl450',
        name_ua: 'Болт кріплення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Šroub upevnění desky řízení uzavíracích ventilů',
            en: 'Swirl valve control plate mounting bolt',
            ua: 'Болт кріплення плити  управління вихровими заслінками',
            bg: 'Болт за монтаж на плочата за управление на вихровите клапани'
        }
    },
    {
        unitId: 935,
        systemId: 4,
        id: '8d142f99-078f-4ac5-80cc-2b695ef9fe6a',
        name: '发动机449',
        location: '[-0.2005215585231781, -0.08448198437690735, 0.008382164873182774]',
        vertices: 292,
        polygons: 190,
        lod: ['LOD_4'],
        name_work: 'dtl449',
        name_ua: 'Болт кріплення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Šroub upevnění desky řízení uzavíracích ventilů',
            en: 'Swirl valve control plate mounting bolt',
            ua: 'Болт кріплення плити  управління вихровими заслінками',
            bg: 'Болт за монтаж на плочата за управление на вихровите клапани'
        }
    },
    {
        unitId: 936,
        systemId: 4,
        id: 'bdb69b0a-f0fa-4ade-a53d-8e37350a3da0',
        name: '发动机448',
        location: '[-0.2005215585231781, -0.07974353432655334, 0.06394797563552856]',
        vertices: 292,
        polygons: 190,
        lod: ['LOD_4'],
        name_work: 'dtl448',
        name_ua: 'Болт кріплення плити  управління вихровими заслінками',
        group: 1150702,
        tooltip: {
            cz: 'Šroub upevnění desky řízení uzavíracích ventilů',
            en: 'Swirl valve control plate mounting bolt',
            ua: 'Болт кріплення плити  управління вихровими заслінками',
            bg: 'Болт за монтаж на плочата за управление на вихровите клапани'
        }
    },
    {
        unitId: 937,
        systemId: 4,
        id: '7b682180-c098-454b-8d59-4664b573f7ad',
        name: '发动机447',
        location: '[-0.2181403487920761, -0.09287562966346741, 0.03896503895521164]',
        vertices: 2913,
        polygons: 2142,
        lod: ['LOD_4'],
        name_work: 'dtl447',
        name_ua: 'Датчик температури охолоджуючої рідини',
        group: 1181201,
        tooltip: {
            cz: 'Senzor teploty chladící kapaliny',
            en: 'Coolant temperature sensor',
            ua: 'Датчик температури охолоджуючої рідини',
            bg: 'Сензор за температурата на охладителната течност'
        }
    },
    {
        unitId: 938,
        systemId: 4,
        id: 'bc6a9ac2-7689-4e75-9112-7980ad4540bd',
        name: '对象001',
        location: '[0.0629742294549942, 0.21528670191764832, -0.049657560884952545]',
        vertices: 5382,
        polygons: 9344,
        lod: ['LOD_4'],
        name_work: '对象001',
        name_ua: 'Патрубок турбіни',
        group: 1110200,
        tooltip: { cz: 'Potrubí turbodmychadla', en: 'Turbine hose', ua: 'Патрубок турбіни', bg: 'Тръба на турбината' }
    },
    {
        unitId: 939,
        systemId: 4,
        id: '4fd2c82c-db77-4c44-b255-8d23eacf5610',
        name: '发动机445',
        location: '[-0.16265761852264404, 0.007914510555565357, 0.15298621356487274]',
        vertices: 11476,
        polygons: 7535,
        lod: ['LOD_4'],
        name_work: 'dtl445',
        name_ua: 'Датчик положення розподільчого валу',
        group: 1181002,
        tooltip: {
            cz: 'Senzor polohy vačkového hřídele',
            en: 'Camshaft position sensor',
            ua: 'Датчик положення розподільчого валу',
            bg: 'Сензор за позиция на разпределителния вал'
        }
    },
    {
        unitId: 940,
        systemId: 4,
        id: '688f7676-6590-427c-8c27-e503ce6caed2',
        name: '发动机444',
        location: '[0.2189025729894638, -0.12501361966133118, -0.19736932218074799]',
        vertices: 424,
        polygons: 290,
        lod: ['LOD_4'],
        name_work: 'dtl444',
        name_ua: 'Болт кріплення датчика обертів колінчастого валу',
        group: 1010203,
        tooltip: {
            cz: 'Šroub upevnění senzoru otáček klikového hřídele',
            en: 'Crankshaft position sensor mounting bolt',
            ua: 'Болт кріплення датчика обертів колінчастого валу',
            bg: 'Болт за монтаж на сензора за обороти на коляновия вал'
        }
    },
    {
        unitId: 941,
        systemId: 4,
        id: '838b73e9-f53d-4eb8-8d59-e8afbee69c0a',
        name: '发动机443',
        location: '[0.21891196072101593, -0.10758247971534729, -0.2381078451871872]',
        vertices: 424,
        polygons: 290,
        lod: ['LOD_4'],
        name_work: 'dtl443',
        name_ua: 'Болт кріплення датчика обертів колінчастого валу',
        group: 1010203,
        tooltip: {
            cz: 'Šroub upevnění senzoru otáček klikového hřídele',
            en: 'Crankshaft position sensor mounting bolt',
            ua: 'Болт кріплення датчика обертів колінчастого валу',
            bg: 'Болт за монтаж на сензора за обороти на коляновия вал'
        }
    },
    {
        unitId: 942,
        systemId: 4,
        id: '9133ebcc-78cc-4f22-9279-399d13de4f53',
        name: '发动机442',
        location: '[-0.16265611350536346, -0.013149055652320385, 0.15893088281154633]',
        vertices: 424,
        polygons: 290,
        lod: ['LOD_4'],
        name_work: 'dtl442',
        name_ua: 'Болт датчика положення розподільчого валу',
        group: 1181002,
        tooltip: {
            cz: 'Šroub senzoru polohy vačkového hřídele',
            en: 'Camshaft position sensor bolt',
            ua: 'Болт датчика положення розподільчого валу',
            bg: 'Болт на сензора за положения на разпределителния вал'
        }
    },
    {
        unitId: 943,
        systemId: 4,
        id: '6311e6ad-611b-49b9-be8b-54bbc1d89c46',
        name: '发动机441',
        location: '[0.004041282460093498, 0.035566773265600204, -0.009704775176942348]',
        vertices: 307,
        polygons: 204,
        lod: ['LOD_3'],
        name_work: 'dtl441',
        name_ua: 'Болт кріплення датчика детонації',
        group: 1181700,
        tooltip: {
            cz: 'Šroub upevnění senzoru detonace',
            en: 'Knock sensor mounting bolt',
            ua: 'Болт кріплення датчика детонації',
            bg: 'Болт за монтаж на сензора за детонация'
        }
    },
    {
        unitId: 944,
        systemId: 4,
        id: 'f7dcb974-975c-4b12-9674-658454bcd6cf',
        name: '发动机440',
        location: '[0.06137359142303467, 0.09940382838249207, 0.018297964707016945]',
        vertices: 3508,
        polygons: 1954,
        lod: ['LOD_3'],
        name_work: 'dtl440',
        name_ua: 'Датчик детонації',
        group: 1181700,
        tooltip: { cz: 'Senzor detonace', en: 'Knock sensor', ua: 'Датчик детонації', bg: 'Сензор за детонация' }
    },
    {
        unitId: 945,
        systemId: 4,
        id: 'ddfd753b-8b27-4691-b172-818eb1546570',
        name: '发动机439',
        location: '[0.20765270292758942, -0.05346718803048134, 0.027339214459061623]',
        vertices: 603,
        polygons: 374,
        lod: ['LOD_4'],
        name_work: 'dtl439',
        name_ua: 'Болти кріплення бокової подушки двигуна',
        group: 1141002,
        tooltip: {
            cz: 'Šrouby upevnění boční opory motoru',
            en: 'Engine side mount bolts',
            ua: 'Болти кріплення бокової подушки двигуна',
            bg: 'Болтове закрепване на страничната опора на двигателя'
        }
    },
    {
        unitId: 946,
        systemId: 4,
        id: '79a6bfb0-659a-493d-ae57-3f506017593f',
        name: '发动机438',
        location: '[0.1959347426891327, 0.0625753104686737, -0.1796753853559494]',
        vertices: 430,
        polygons: 302,
        lod: ['LOD_4'],
        name_work: 'dtl438',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 947,
        systemId: 4,
        id: '92aca30e-a0b2-4387-9512-7e9c0d0712a4',
        name: '发动机437',
        location: '[0.2091197818517685, -0.0901658833026886, -0.10360625386238098]',
        vertices: 733,
        polygons: 543,
        lod: ['LOD_4'],
        name_work: 'dtl437',
        name_ua: 'Натягувач ланцюга ГРМ',
        group: 1040405,
        tooltip: {
            cz: 'Napínák řetězu rozvodu',
            en: 'Timing chain tensioner',
            ua: 'Натягувач ланцюга ГРМ',
            bg: 'Обтегач на ангренажната верига'
        }
    },
    {
        unitId: 948,
        systemId: 4,
        id: '239f25be-c22f-441a-9518-7203085c2e9e',
        name: '发动机436',
        location: '[0.19421446323394775, -0.12092164158821106, -0.23282890021800995]',
        vertices: 256,
        polygons: 240,
        lod: ['LOD_2'],
        name_work: 'dtl436',
        name_ua: 'Втулка блоку циліндрів',
        group: 1141701,
        tooltip: {
            cz: 'Vložka válcové hlavy',
            en: 'Cylinder block bushing',
            ua: 'Втулка блоку циліндрів',
            bg: 'Втулка на цилиндровия блок'
        }
    },
    {
        unitId: 949,
        systemId: 4,
        id: 'd5c0db91-edd9-4534-9f76-74227f140ab9',
        name: '发动机435',
        location: '[0.19421453773975372, 0.04474123939871788, -0.13117365539073944]',
        vertices: 256,
        polygons: 240,
        lod: ['LOD_2'],
        name_work: 'dtl435',
        name_ua: 'Втулка блоку циліндрів',
        group: 1141701,
        tooltip: {
            cz: 'Vložka válcové hlavy',
            en: 'Cylinder block bushing',
            ua: 'Втулка блоку циліндрів',
            bg: 'Втулка на цилиндровия блок'
        }
    },
    {
        unitId: 950,
        systemId: 4,
        id: 'ab9033ee-88c2-4081-87fd-10e83cb8ba0d',
        name: '发动机434',
        location: '[0.21566274762153625, -0.08361867070198059, -0.018319493159651756]',
        vertices: 353,
        polygons: 230,
        lod: ['LOD_4'],
        name_work: 'dtl434',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 951,
        systemId: 4,
        id: '8f8a1e13-a910-432f-80ff-9482be5d247f',
        name: '发动机433',
        location: '[0.22145597636699677, -0.031063469126820564, -0.0898405909538269]',
        vertices: 49091,
        polygons: 33262,
        lod: ['LOD_4'],
        name_work: 'dtl433',
        name_ua: 'Кришка ланцюга ГРМ',
        group: 1141002,
        tooltip: {
            cz: 'Kryt řetězu rozvodu',
            en: 'Timing chain cover',
            ua: 'Кришка ланцюга ГРМ',
            bg: 'Капак на веригата за ГРМ'
        }
    },
    {
        unitId: 952,
        systemId: 4,
        id: '64660071-df4b-470b-a578-d2b9246989de',
        name: '发动机432',
        location: '[0.17418226599693298, -0.026932628825306892, 0.17373405396938324]',
        vertices: 732,
        polygons: 576,
        lod: ['LOD_3'],
        name_work: 'dtl432',
        name_ua: 'Ущільнення датчика положення розподільчого валу',
        group: 1181002,
        tooltip: {
            cz: 'Těsnění senzoru polohy vačkového hřídele',
            en: 'Camshaft position sensor seal',
            ua: 'Ущільнення датчика положення розподільчого валу',
            bg: 'Уплътнение на сензора на разпределителния вал'
        }
    },
    {
        unitId: 953,
        systemId: 4,
        id: '73bf5479-99c9-4dbb-af86-11040b1b8c54',
        name: '发动机431',
        location: '[0.17418226599693298, -0.026932628825306892, 0.16292135417461395]',
        vertices: 1418,
        polygons: 1092,
        lod: ['LOD_3'],
        name_work: 'dtl431',
        name_ua: 'Електромагнітний клапан розподільчого валу',
        group: 1181002,
        tooltip: {
            cz: 'Elektromagnetický ventil vačkového hřídele',
            en: 'Electromagnetic valve for camshaft',
            ua: 'Електромагнітний клапан розподільчого валу',
            bg: 'Електромагнитен клапан на разпределителния вал'
        }
    },
    {
        unitId: 954,
        systemId: 4,
        id: 'c490bed2-162f-4c6d-b248-50c4f61849fd',
        name: '发动机430',
        location: '[0.17418226599693298, -0.026932628825306892, 0.16603778302669525]',
        vertices: 4480,
        polygons: 3702,
        lod: ['LOD_3'],
        name_work: 'dtl430',
        name_ua: 'Електромагнітний клапан розподільчого валу',
        group: 1181002,
        tooltip: {
            cz: 'Elektromagnetický ventil vačkového hřídele',
            en: 'Electromagnetic valve for camshaft',
            ua: 'Електромагнітний клапан розподільчого валу',
            bg: 'Електромагнитен клапан на разпределителния вал'
        }
    },
    {
        unitId: 955,
        systemId: 4,
        id: '93de438f-0a2f-42a9-b3bb-67ca9d3173c2',
        name: '发动机429',
        location: '[0.027547389268875122, -0.04773751273751259, 0.10922294855117798]',
        vertices: 99738,
        polygons: 75501,
        lod: ['LOD_3'],
        name_work: 'dtl429',
        name_ua: 'Прокладка клапаної кришки',
        group: 1140603,
        tooltip: {
            cz: 'Těsnění víka ventilů',
            en: 'Valve cover gasket',
            ua: 'Прокладка клапаної кришки',
            bg: 'Уплътнение на клапанната капачка'
        }
    },
    {
        unitId: 956,
        systemId: 4,
        id: 'a1d830fd-2abd-485b-a26f-ae5d8d6136c1',
        name: '发动机428',
        location: '[-0.10381707549095154, -0.06458058953285217, 0.1385565847158432]',
        vertices: 678,
        polygons: 446,
        lod: ['LOD_2'],
        name_work: 'dtl428',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 957,
        systemId: 4,
        id: '35c44b7d-456a-4dcb-94d0-400829008834',
        name: '发动机427',
        location: '[0.14429083466529846, -0.06458142399787903, 0.1385565847158432]',
        vertices: 678,
        polygons: 446,
        lod: ['LOD_2'],
        name_work: 'dtl427',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 958,
        systemId: 4,
        id: '7f2ef920-3adf-4171-a92e-b301f80c34d5',
        name: '发动机426',
        location: '[0.06158874183893204, -0.06458142399787903, 0.1385565847158432]',
        vertices: 678,
        polygons: 446,
        lod: ['LOD_2'],
        name_work: 'dtl426',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 959,
        systemId: 4,
        id: '7d3d2530-9b34-4ca1-9c5d-17ff07070422',
        name: '发动机425',
        location: '[-0.021113401278853416, -0.06458142399787903, 0.1385565847158432]',
        vertices: 678,
        polygons: 446,
        lod: ['LOD_2'],
        name_work: 'dtl425',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 960,
        systemId: 4,
        id: '26cb7d72-f5f2-4435-a12c-00b67b4af084',
        name: '发动机424',
        location: '[-0.16265611350536346, -0.013149651698768139, 0.15251241624355316]',
        vertices: 678,
        polygons: 446,
        lod: ['LOD_3'],
        name_work: 'dtl424',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 961,
        systemId: 4,
        id: '83b13949-558f-4368-9505-4a6bbf425e1e',
        name: '发动机423',
        location: '[-0.028695305809378624, -0.09872058033943176, 0.1382976621389389]',
        vertices: 14141,
        polygons: 9946,
        lod: ['LOD_3'],
        name_work: 'dtl423',
        name_ua: 'Маслознімна пластина',
        group: 1141104,
        tooltip: { cz: 'Olejová stěrka', en: 'Oil scraper plate', ua: 'Маслознімна пластина', bg: 'Маслен печат' }
    },
    {
        unitId: 962,
        systemId: 4,
        id: '7197b9e6-f474-4d35-8f95-4b381a87d712',
        name: '发动机422',
        location: '[0.024287445470690727, -0.051105890423059464, 0.1384686678647995]',
        vertices: 84111,
        polygons: 55511,
        lod: ['LOD_4'],
        name_work: 'dtl422',
        name_ua: 'Кришка ГРМ',
        group: 1140504,
        tooltip: { cz: 'Přední kryt rozvodu', en: 'Timing cover', ua: 'Кришка ГРМ', bg: 'Капак на ГРМ' }
    },
    {
        unitId: 963,
        systemId: 4,
        id: '78334ea6-86ba-4604-a1d8-7aa0ac0224fe',
        name: '发动机421',
        location: '[-0.05841581150889397, -0.03296366706490517, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl421',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 964,
        systemId: 4,
        id: '277e947e-bfb6-4306-a99d-3bcbc9881566',
        name: '发动机420',
        location: '[0.10698843002319336, -0.062253985553979874, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl420',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 965,
        systemId: 4,
        id: '8851acd0-76c1-46b7-9160-66e86dcac7af',
        name: '发动机419',
        location: '[0.05745498463511467, -0.13634219765663147, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl419',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 966,
        systemId: 4,
        id: '0a81b820-b620-499a-ab10-69877dec3d37',
        name: '发动机418',
        location: '[0.06477735936641693, 0.04112383350729942, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl418',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 967,
        systemId: 4,
        id: '30948d1d-619b-4a45-9e49-409d5a3a7c53',
        name: '发动机417',
        location: '[0.16772352159023285, 0.04801448807120323, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl417',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 968,
        systemId: 4,
        id: '644ff1cc-2d26-4bc0-8f14-b4b4060b8b45',
        name: '发动机416',
        location: '[0.1642773300409317, -0.1406489908695221, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl416',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 969,
        systemId: 4,
        id: '6d8df9a3-47e3-4fc5-8123-979db027e356',
        name: '发动机415',
        location: '[-0.05540115758776665, -0.13634219765663147, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl415',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 970,
        systemId: 4,
        id: 'a7ae76e2-44b1-40ce-b6d0-46f3785efdc9',
        name: '发动机414',
        location: '[-0.017926262691617012, 0.04112299904227257, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl414',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 971,
        systemId: 4,
        id: 'd3af5c66-3cf9-4cf8-8d90-70168de00900',
        name: '发动机413',
        location: '[0.2267354428768158, 0.0501691959798336, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl413',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 972,
        systemId: 4,
        id: 'd4192b5d-2e71-4c75-808f-ecf3b3713f33',
        name: '发动机412',
        location: '[0.2362109124660492, -0.07000783085823059, 0.11900079250335693]',
        vertices: 228,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl412',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 973,
        systemId: 4,
        id: 'f5bd3555-624c-42c6-95ae-9ba2061f5f4a',
        name: '发动机411',
        location: '[0.2267354428768158, -0.1350490152835846, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl411',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 974,
        systemId: 4,
        id: 'dca5a1af-a461-447f-9428-8928cdb4b573',
        name: '发动机410',
        location: '[-0.1686859279870987, -0.13634148240089417, 0.11900079250335693]',
        vertices: 229,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl410',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 975,
        systemId: 4,
        id: '41b7e1dc-5c1a-4f53-a18a-5e5c02302f1e',
        name: '发动机409',
        location: '[-0.18763825297355652, -0.04183677211403847, 0.11900079250335693]',
        vertices: 228,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl409',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 976,
        systemId: 4,
        id: '45f52b5b-73c0-4770-9430-5f2182282bc2',
        name: '发动机408',
        location: '[-0.17686967551708221, 0.04112371429800987, 0.11900079250335693]',
        vertices: 228,
        polygons: 152,
        lod: ['LOD_3'],
        name_work: 'dtl408',
        name_ua: 'Втулка болту кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Pohybové víčko pro šroub',
            en: 'Plug bolt for cylinder head cover',
            ua: 'Втулка болту кришки блоку циліндрів',
            bg: 'Втулка за болта на капака на цилиндрите'
        }
    },
    {
        unitId: 977,
        systemId: 4,
        id: 'bd51a1fe-84c2-4997-a035-78db687ecf91',
        name: '发动机407',
        location: '[0.13240033388137817, -0.09929719567298889, 0.174428328871727]',
        vertices: 722,
        polygons: 564,
        lod: ['LOD_3'],
        name_work: 'dtl407',
        name_ua: 'Ущільнення кришки маслозаливної',
        group: 1141403,
        tooltip: {
            cz: 'Těsnění víka olejového',
            en: 'Oil filler cap seal',
            ua: 'Ущільнення кришки маслозаливної',
            bg: 'Уплътнение на капака за наливане на масло'
        }
    },
    {
        unitId: 978,
        systemId: 4,
        id: '09673cca-2fb3-4ee3-a6ce-d9cc4a2c0f70',
        name: '发动机406',
        location: '[0.13240033388137817, -0.09929719567298889, 0.17607353627681732]',
        vertices: 11322,
        polygons: 7727,
        lod: ['LOD_4'],
        name_work: 'dtl406',
        name_ua: 'Кришка маслозаливна',
        group: 1141403,
        tooltip: {
            cz: 'Kryt olejového víka',
            en: 'Oil filler cap',
            ua: 'Кришка маслозаливна',
            bg: 'Капак за наливане на масло'
        }
    },
    {
        unitId: 979,
        systemId: 4,
        id: '22556920-d68c-4657-a508-24b6870675c0',
        name: '发动机405',
        location: '[-0.19806444644927979, -4.9027494242182e-05, 0.03172748535871506]',
        vertices: 1133,
        polygons: 848,
        lod: ['LOD_3'],
        name_work: 'dtl405',
        name_ua: 'Прокладка клапана рециркуляції вихлопних газів (EGR)',
        group: 1130400,
        tooltip: {
            cz: 'Guma ventilu pro recirkulaci výfukových plynů (EGR)',
            en: 'EGR exhaust gas recirculation valve gasket',
            ua: 'Прокладка клапана рециркуляції вихлопних газів (EGR)',
            bg: 'Уплътнение на клапана за рециркулация на отработените газове (EGR)'
        }
    },
    {
        unitId: 980,
        systemId: 4,
        id: '4f148bc4-6ef7-4a09-b2ed-dff4d59cf54c',
        name: '发动机404',
        location: '[0.20334497094154358, -0.02116146869957447, 0.04973774403333664]',
        vertices: 603,
        polygons: 374,
        lod: ['LOD_4'],
        name_work: 'dtl404',
        name_ua: 'Болти кріплення бокової подушки двигуна',
        group: 1141002,
        tooltip: {
            cz: 'Šrouby upevnění boční opory motoru',
            en: 'Engine side mount bolts',
            ua: 'Болти кріплення бокової подушки двигуна',
            bg: 'Болтове закрепване на страничната опора на двигателя'
        }
    },
    {
        unitId: 981,
        systemId: 4,
        id: '42d3fb85-92b8-4215-b14e-7132a57aec8b',
        name: '发动机403',
        location: '[0.2076527178287506, -0.06880214810371399, 0.04973774403333664]',
        vertices: 603,
        polygons: 374,
        lod: ['LOD_4'],
        name_work: 'dtl403',
        name_ua: 'Болти кріплення бокової подушки двигуна',
        group: 1141002,
        tooltip: {
            cz: 'Šrouby upevnění boční opory motoru',
            en: 'Engine side mount bolts',
            ua: 'Болти кріплення бокової подушки двигуна',
            bg: 'Болтове закрепване на страничната опора на двигателя'
        }
    },
    {
        unitId: 982,
        systemId: 4,
        id: '2cb293d1-47e6-4a00-bc81-74ec91cd3d1d',
        name: '发动机402',
        location: '[0.19352266192436218, -0.03795984759926796, -0.15098793804645538]',
        vertices: 677,
        polygons: 370,
        lod: ['LOD_3'],
        name_work: 'dtl402',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 983,
        systemId: 4,
        id: '0e8a7709-c453-4024-91c2-3ead566b870e',
        name: '发动机401',
        location: '[0.1905093938112259, 0.04474123939871788, -0.13117365539073944]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl401',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 984,
        systemId: 4,
        id: 'b5eeacdb-bc8b-4e54-b594-1b827e528dfb',
        name: '发动机400',
        location: '[0.19050931930541992, -0.12092164158821106, -0.23282860219478607]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl400',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 985,
        systemId: 4,
        id: 'd24a10c6-2249-4432-a0a1-dd85f0b9d19c',
        name: '发动机399',
        location: '[0.19567707180976868, -0.02745083160698414, -0.06139371544122696]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl399',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 986,
        systemId: 4,
        id: '6b76571d-897d-4642-8956-f15f35b96481',
        name: '发动机398',
        location: '[0.19395552575588226, -0.16115257143974304, 0.07342857122421265]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl398',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 987,
        systemId: 4,
        id: '3b98d2db-bc72-4384-bcc9-3c07d979608e',
        name: '发动机397',
        location: '[0.19395552575588226, -0.13513627648353577, 0.0165706817060709]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl397',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 988,
        systemId: 4,
        id: '354ff01e-ebb2-4371-8386-375729f5a1f9',
        name: '发动机396',
        location: '[0.19395552575588226, -0.10774067044258118, -0.024780726060271263]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl396',
        name_ua: 'Болти кріплення бокової подушки двигуна',
        group: 1141002,
        tooltip: {
            cz: 'Šrouby upevnění boční opory motoru',
            en: 'Engine side mount bolts',
            ua: 'Болти кріплення бокової подушки двигуна',
            bg: 'Болтове закрепване на страничната опора на двигателя'
        }
    },
    {
        unitId: 989,
        systemId: 4,
        id: '5ada23db-4dc7-4496-b374-0f9236bda741',
        name: '发动机395',
        location: '[0.19395552575588226, -0.13229301571846008, -0.09972965717315674]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl395',
        name_ua: 'Болти кріплення бокової подушки двигуна',
        group: 1141002,
        tooltip: {
            cz: 'Šrouby upevnění boční opory motoru',
            en: 'Engine side mount bolts',
            ua: 'Болти кріплення бокової подушки двигуна',
            bg: 'Болтове закрепване на страничната опора на двигателя'
        }
    },
    {
        unitId: 990,
        systemId: 4,
        id: 'a62b83be-0916-4498-ac0a-7323d5cf173f',
        name: '发动机394',
        location: '[0.19395552575588226, -0.12048998475074768, -0.16735605895519257]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl394',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 991,
        systemId: 4,
        id: '61d99c93-8d45-4462-9de5-018669abb09f',
        name: '发动机393',
        location: '[0.19395552575588226, -0.11290886998176575, -0.27331826090812683]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl393',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 992,
        systemId: 4,
        id: 'a1d8da6e-d875-45ae-94f8-12e152685b71',
        name: '发动机392',
        location: '[0.19395552575588226, 0.023635832592844963, -0.27977943420410156]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl392',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 993,
        systemId: 4,
        id: 'ea6723da-69d5-4f08-8ae5-aa45f102dbbe',
        name: '发动机391',
        location: '[0.19395552575588226, 0.08118245005607605, -0.27590277791023254]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl391',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 994,
        systemId: 4,
        id: 'c8622083-4b22-4d17-ad5c-0da341b6f020',
        name: '发动机390',
        location: '[0.19395552575588226, 0.09910199046134949, -0.2342931479215622]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl390',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 995,
        systemId: 4,
        id: 'cc9c14e8-5b6b-43b1-86dc-6610507bec2b',
        name: '发动机389',
        location: '[0.19395552575588226, 0.029235927388072014, -0.08120778203010559]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl389',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 996,
        systemId: 4,
        id: '21f510d7-9574-4514-af99-285fa3741c0b',
        name: '发动机388',
        location: '[0.19395552575588226, 0.04775675758719444, -0.006258642300963402]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl388',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 997,
        systemId: 4,
        id: 'c4883923-4082-40a5-8be1-8e417095391c',
        name: '发动机387',
        location: '[0.19395552575588226, 0.06912145018577576, 0.03380029648542404]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl387',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 998,
        systemId: 4,
        id: '67167917-e4fa-4d1e-a12f-658c08a638ef',
        name: '发动机386',
        location: '[0.19395552575588226, 0.0628330409526825, 0.08721214532852173]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_4'],
        name_work: 'dtl386',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 999,
        systemId: 4,
        id: '295a5f4a-98f8-4bfb-9a77-1ff0a0beb7f0',
        name: '发动机385',
        location: '[-0.17139862477779388, -0.11790516972541809, -0.19793863594532013]',
        vertices: 474,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl385',
        name_ua: 'Болти кріплення заднього сальника колінвалу ',
        group: 1140803,
        tooltip: {
            cz: 'Šrouby pro uchycení zadního těsnění klikového hřídele',
            en: 'Rear crankshaft seal mounting bolts',
            ua: 'Болти кріплення заднього сальника колінвалу',
            bg: 'Болтове закрепване на задния сальник на коляновия вал'
        }
    },
    {
        unitId: 1000,
        systemId: 4,
        id: 'fd2ca006-c91c-4111-b3da-6a52f1f14ccb',
        name: '发动机384',
        location: '[-0.17252038419246674, -0.09869435429573059, -0.2479044497013092]',
        vertices: 474,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl384',
        name_ua: 'Болти кріплення заднього сальника колінвалу ',
        group: 1140803,
        tooltip: {
            cz: 'Šrouby pro uchycení zadního těsnění klikového hřídele',
            en: 'Rear crankshaft seal mounting bolts',
            ua: 'Болти кріплення заднього сальника колінвалу',
            bg: 'Болтове закрепване на задния сальник на коляновия вал'
        }
    },
    {
        unitId: 1001,
        systemId: 4,
        id: 'f9954b13-fd77-472d-98b1-4e6883970bc3',
        name: '发动机383',
        location: '[-0.17252038419246674, 0.008991208858788013, -0.24790474772453308]',
        vertices: 474,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl383',
        name_ua: 'Болти кріплення заднього сальника колінвалу ',
        group: 1140803,
        tooltip: {
            cz: 'Šrouby pro uchycení zadního těsnění klikového hřídele',
            en: 'Rear crankshaft seal mounting bolts',
            ua: 'Болти кріплення заднього сальника колінвалу',
            bg: 'Болтове закрепване на задния сальник на коляновия вал'
        }
    },
    {
        unitId: 1002,
        systemId: 4,
        id: '8808facb-aef4-44f2-806d-40a070170a5c',
        name: '发动机382',
        location: '[-0.17140023410320282, 0.02820226363837719, -0.19793863594532013]',
        vertices: 474,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl382',
        name_ua: 'Болти кріплення заднього сальника колінвалу ',
        group: 1140803,
        tooltip: {
            cz: 'Šrouby pro uchycení zadního těsnění klikového hřídele',
            en: 'Rear crankshaft seal mounting bolts',
            ua: 'Болти кріплення заднього сальника колінвалу',
            bg: 'Болтове закрепване на задния сальник на коляновия вал'
        }
    },
    {
        unitId: 1003,
        systemId: 4,
        id: '7abef25e-7476-4c8e-baf3-2057e4bdf7d7',
        name: '发动机381',
        location: '[-0.17140023410320282, 0.0042527588084340096, -0.13720421493053436]',
        vertices: 474,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl381',
        name_ua: 'Болти кріплення заднього сальника колінвалу ',
        group: 1140803,
        tooltip: {
            cz: 'Šrouby pro uchycení zadního těsnění klikového hřídele',
            en: 'Rear crankshaft seal mounting bolts',
            ua: 'Болти кріплення заднього сальника колінвалу',
            bg: 'Болтове закрепване на задния сальник на коляновия вал'
        }
    },
    {
        unitId: 1004,
        systemId: 4,
        id: 'f5e54cd9-ad57-4224-8bb5-87aaa1c3b11a',
        name: '发动机380',
        location: '[-0.17140023410320282, -0.07758870720863342, -0.12514351308345795]',
        vertices: 474,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl380',
        name_ua: 'Болти кріплення заднього сальника колінвалу ',
        group: 1140803,
        tooltip: {
            cz: 'Šrouby pro uchycení zadního těsnění klikového hřídele',
            en: 'Rear crankshaft seal mounting bolts',
            ua: 'Болти кріплення заднього сальника колінвалу',
            bg: 'Болтове закрепване на задния сальник на коляновия вал'
        }
    },
    {
        unitId: 1005,
        systemId: 4,
        id: 'a9d0c5d8-e80e-4cf0-82a5-38ef9ba8c1d1',
        name: '发动机379',
        location: '[0.2004166543483734, -0.04485252872109413, -0.27331826090812683]',
        vertices: 533,
        polygons: 358,
        lod: ['LOD_4'],
        name_work: 'dtl379',
        name_ua: 'Болт кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu válce',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки блоку циліндрів',
            bg: 'Болт на капака на цилиндровия блок'
        }
    },
    {
        unitId: 1006,
        systemId: 4,
        id: 'c9889eb6-6420-4d9f-9593-eff23e4a9ac3',
        name: '发动机378',
        location: '[0.24113638699054718, 0.0016678246902301908, 0.07314431667327881]',
        vertices: 582,
        polygons: 351,
        lod: ['LOD_3'],
        name_work: 'dtl378',
        name_ua: 'Кріплення кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Montáž krycího plechu válců',
            en: 'Cylinder head cover mounting',
            ua: 'Кріплення кришки блоку циліндрів',
            bg: 'Закрепване на капака на цилиндровия блок'
        }
    },
    {
        unitId: 1007,
        systemId: 4,
        id: 'c96bd565-4136-47a6-8e45-9b5d434b79e9',
        name: '发动机377',
        location: '[0.24113640189170837, -0.043733153492212296, 0.07314431667327881]',
        vertices: 582,
        polygons: 351,
        lod: ['LOD_3'],
        name_work: 'dtl377',
        name_ua: 'Кріплення кришки блоку циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Montáž krycího plechu válců',
            en: 'Cylinder head cover mounting',
            ua: 'Кріплення кришки блоку циліндрів',
            bg: 'Закрепване на капака на цилиндровия блок'
        }
    },
    {
        unitId: 1008,
        systemId: 4,
        id: '29aa50b5-ce7b-4a8a-909b-c396ddf090e3',
        name: '发动机376',
        location: '[-0.1876405030488968, -0.04187670722603798, 0.126107856631279]',
        vertices: 842,
        polygons: 644,
        lod: ['LOD_4'],
        name_work: 'dtl376',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1009,
        systemId: 4,
        id: '3e1334c0-cef4-4a25-a3f6-c5abb679a886',
        name: '发动机375',
        location: '[0.06477628648281097, 0.041123952716588974, 0.12180060148239136]',
        vertices: 852,
        polygons: 644,
        lod: ['LOD_4'],
        name_work: 'dtl375',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1010,
        systemId: 4,
        id: 'afd438f6-8685-4173-b027-c3cb669e6e62',
        name: '发动机374',
        location: '[0.16772237420082092, 0.04801472648978233, 0.12180060148239136]',
        vertices: 854,
        polygons: 644,
        lod: ['LOD_4'],
        name_work: 'dtl374',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1011,
        systemId: 4,
        id: 'b0f1bf9c-3ad7-442e-ab04-556942d09a81',
        name: '发动机373',
        location: '[-0.01792740635573864, 0.04112323746085167, 0.12180060148239136]',
        vertices: 852,
        polygons: 644,
        lod: ['LOD_4'],
        name_work: 'dtl373',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1012,
        systemId: 4,
        id: '07b100e8-ff8d-430a-ad47-0ca92668cc54',
        name: '发动机372',
        location: '[0.22673429548740387, 0.050169434398412704, 0.12180060148239136]',
        vertices: 854,
        polygons: 644,
        lod: ['LOD_4'],
        name_work: 'dtl372',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1013,
        systemId: 4,
        id: '78b46467-765c-416d-88c7-eade8acff984',
        name: '发动机371',
        location: '[0.22673429548740387, -0.1350490152835846, 0.12180060148239136]',
        vertices: 854,
        polygons: 644,
        lod: ['LOD_4'],
        name_work: 'dtl371',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1014,
        systemId: 4,
        id: '720886e5-94e4-4ea3-ab8b-79d57cbb4ee0',
        name: '发动机370',
        location: '[-0.16868698596954346, -0.1363411247730255, 0.12180060148239136]',
        vertices: 854,
        polygons: 644,
        lod: ['LOD_4'],
        name_work: 'dtl370',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1015,
        systemId: 4,
        id: '2d7caa9a-49f5-4121-9f55-aaea909bd2b5',
        name: '发动机369',
        location: '[-0.176870658993721, 0.041124310344457626, 0.11533957719802856]',
        vertices: 661,
        polygons: 500,
        lod: ['LOD_3'],
        name_work: 'dtl369',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1016,
        systemId: 4,
        id: '57687ee8-012c-4fd1-ae48-67af60fcb889',
        name: '发动机368',
        location: '[-0.05841676518321037, -0.032963428646326065, 0.11533957719802856]',
        vertices: 661,
        polygons: 500,
        lod: ['LOD_3'],
        name_work: 'dtl368',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1017,
        systemId: 4,
        id: 'c425111f-ff5e-42bf-bb36-59cf4da64237',
        name: '发动机367',
        location: '[0.10698747634887695, -0.06225374713540077, 0.11533957719802856]',
        vertices: 661,
        polygons: 500,
        lod: ['LOD_3'],
        name_work: 'dtl367',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1018,
        systemId: 4,
        id: '484785bc-700a-4c6b-828b-cdf463652f8d',
        name: '发动机366',
        location: '[0.05745406821370125, -0.13634172081947327, 0.11533957719802856]',
        vertices: 661,
        polygons: 500,
        lod: ['LOD_4'],
        name_work: 'dtl366',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1019,
        systemId: 4,
        id: '6f896a7d-a1d4-45cf-8d8c-8294ed964cb0',
        name: '发动机365',
        location: '[0.16427640616893768, -0.14064869284629822, 0.11533957719802856]',
        vertices: 661,
        polygons: 500,
        lod: ['LOD_4'],
        name_work: 'dtl365',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1020,
        systemId: 4,
        id: '4695634f-32bb-4f59-ba9d-326a6e489f38',
        name: '发动机364',
        location: '[-0.05540222302079201, -0.13634172081947327, 0.11533957719802856]',
        vertices: 661,
        polygons: 500,
        lod: ['LOD_4'],
        name_work: 'dtl364',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1021,
        systemId: 4,
        id: 'beff06e3-2101-4b6e-bbf7-06bb6359a201',
        name: '发动机363',
        location: '[0.2362099289894104, -0.07000759243965149, 0.11533957719802856]',
        vertices: 661,
        polygons: 500,
        lod: ['LOD_4'],
        name_work: 'dtl363',
        name_ua: 'Болт кришки головки циліндрів',
        group: 1140513,
        tooltip: {
            cz: 'Šroub krytu hlavy válců',
            en: 'Cylinder head cover bolt',
            ua: 'Болт кришки головки циліндрів',
            bg: 'Болт за капака на главата на цилиндрите'
        }
    },
    {
        unitId: 1022,
        systemId: 4,
        id: 'a154e295-0b7a-4e78-bcfa-44a4f261de59',
        name: '发动机362',
        location: '[-0.17967171967029572, -0.04484942927956581, -0.1926680952310562]',
        vertices: 40998,
        polygons: 29342,
        lod: ['LOD_3'],
        name_work: 'dtl362',
        name_ua: 'Сальник задній колінвалу ',
        group: 1140803,
        tooltip: {
            cz: 'Zadní utěsnění klikového hřídele',
            en: 'Rear crankshaft oil seal',
            ua: 'Сальник задній колінвалу',
            bg: 'Заден семеринг на коляновия вал'
        }
    },
    {
        unitId: 1023,
        systemId: 4,
        id: '16fd0b85-a228-4348-9194-9de0f8f636f6',
        name: '发动机361',
        location: '[-0.1092434823513031, -0.15025368332862854, 0.09668874740600586]',
        vertices: 255,
        polygons: 166,
        lod: ['LOD_4'],
        name_work: 'dtl361',
        name_ua: 'Болт кріплення кронштейна',
        group: 1040302,
        tooltip: {
            cz: 'Šroub pro upevnění držáku',
            en: 'Mounting bolt for the bracket',
            ua: 'Болт кріплення кронштейна',
            bg: 'Монтажен болт на скобата'
        }
    },
    {
        unitId: 1024,
        systemId: 4,
        id: '8c271c3d-c4cd-44a7-afd2-0c343b345a97',
        name: '发动机360',
        location: '[0.17978407442569733, 0.0683034360408783, 0.09754997491836548]',
        vertices: 252,
        polygons: 166,
        lod: ['LOD_4'],
        name_work: 'dtl360',
        name_ua: 'Болт кріплення кронштейна',
        group: 1040302,
        tooltip: {
            cz: 'Šroub pro upevnění držáku',
            en: 'Mounting bolt for the bracket',
            ua: 'Болт кріплення кронштейна',
            bg: 'Монтажен болт на скобата'
        }
    },
    {
        unitId: 1025,
        systemId: 4,
        id: 'c22eeb9f-49a7-498c-bdac-3486475842cb',
        name: '发动机359',
        location: '[-0.10924561321735382, -0.15253683924674988, 0.12985418736934662]',
        vertices: 792,
        polygons: 594,
        lod: ['LOD_4'],
        name_work: 'dtl359',
        name_ua: 'Задній підвісний кронштейн',
        group: 1140302,
        tooltip: {
            cz: 'Zadní zavěšený držák',
            en: 'Front suspension bracket',
            ua: 'Задній підвісний кронштейн',
            bg: 'Заден висещ кронштейн'
        }
    },
    {
        unitId: 1026,
        systemId: 4,
        id: 'f600600e-1985-4c1a-913d-a7bb67d70b0d',
        name: '发动机358',
        location: '[0.17913572490215302, 0.07317158579826355, 0.12335121631622314]',
        vertices: 482,
        polygons: 358,
        lod: ['LOD_4'],
        name_work: 'dtl358',
        name_ua: 'Передній підвісний кронштейн',
        group: 1140302,
        tooltip: {
            cz: 'Přední zavěšený držák',
            en: 'Fuel rail mounting',
            ua: 'Передній підвісний кронштейн',
            bg: 'Преден висящ носач'
        }
    },
    {
        unitId: 1027,
        systemId: 4,
        id: '7406ec3c-1991-4777-9f38-9b310f21fbbb',
        name: '发动机357',
        location: '[-0.08818574249744415, 0.09579595923423767, 0.14557819068431854]',
        vertices: 363,
        polygons: 200,
        lod: ['LOD_3'],
        name_work: 'dtl357',
        name_ua: 'Кріплення паливної рейки',
        group: 1150400,
        tooltip: {
            cz: 'Montáž palivové lišty',
            en: 'Fuel rail mounting',
            ua: 'Кріплення паливної рейки',
            bg: 'Закрепване на горивната шина'
        }
    },
    {
        unitId: 1028,
        systemId: 4,
        id: '3364541c-fae5-45e2-a630-fd741b7d66d0',
        name: '发动机356',
        location: '[0.08238783478736877, 0.09578034281730652, 0.1456015557050705]',
        vertices: 363,
        polygons: 200,
        lod: ['LOD_3'],
        name_work: 'dtl356',
        name_ua: 'Кріплення паливної рейки',
        group: 1150400,
        tooltip: {
            cz: 'Montáž palivové lišty',
            en: 'Fuel rail mounting',
            ua: 'Кріплення паливної рейки',
            bg: 'Закрепване на горивната шина'
        }
    },
    {
        unitId: 1029,
        systemId: 4,
        id: '43168461-9a44-4f97-88a5-1ec7907c3881',
        name: '发动机355',
        location: '[0.13739636540412903, 0.08416280150413513, 0.1305295079946518]',
        vertices: 543,
        polygons: 380,
        lod: ['LOD_3'],
        name_work: 'dtl355',
        name_ua: 'Паливна рейка',
        group: 1150400,
        tooltip: { cz: 'Palivová lišta', en: 'Fuel rail', ua: 'Паливна рейка', bg: 'Горивна шина' }
    },
    {
        unitId: 1030,
        systemId: 4,
        id: '18cc00eb-a731-4862-b1c3-f75667b38310',
        name: '发动机354',
        location: '[-0.13577866554260254, 0.08418676257133484, 0.13048605620861053]',
        vertices: 843,
        polygons: 568,
        lod: ['LOD_3'],
        name_work: 'dtl354',
        name_ua: 'Паливна рейка',
        group: 1150400,
        tooltip: { cz: 'Palivová lišta', en: 'Fuel rail', ua: 'Паливна рейка', bg: 'Горивна шина' }
    },
    {
        unitId: 1031,
        systemId: 4,
        id: '98afa7db-08df-4e76-a2ef-4dec7424b1bd',
        name: '发动机353',
        location: '[-0.12302635610103607, 0.07152256369590759, 0.11811453104019165]',
        vertices: 1409,
        polygons: 952,
        lod: ['LOD_3'],
        name_work: 'dtl353',
        name_ua: 'Зʼєднувач паливної форсунки',
        group: 1150604,
        tooltip: {
            cz: 'Spojka palivové trysky',
            en: 'Fuel rail mounting',
            ua: 'Зʼєднувач паливної форсунки',
            bg: 'Конектор за горивен инжектор'
        }
    },
    {
        unitId: 1032,
        systemId: 4,
        id: '394da484-5701-41b3-a204-72e00ee5e766',
        name: '发动机352',
        location: '[-0.04032577946782112, 0.07151481509208679, 0.11812567710876465]',
        vertices: 1409,
        polygons: 952,
        lod: ['LOD_3'],
        name_work: 'dtl352',
        name_ua: 'Кріплення паливної рейки',
        group: 1150400,
        tooltip: {
            cz: 'Montáž palivové lišty',
            en: 'Fuel rail mounting',
            ua: 'Кріплення паливної рейки',
            bg: 'Закрепване на горивната шина'
        }
    },
    {
        unitId: 1033,
        systemId: 4,
        id: '6e3d7088-2bae-458d-b4ec-221749153e0c',
        name: '发动机351',
        location: '[0.04237792640924454, 0.07150706648826599, 0.11813700199127197]',
        vertices: 1409,
        polygons: 952,
        lod: ['LOD_3'],
        name_work: 'dtl351',
        name_ua: 'Зʼєднувач паливної форсунки',
        group: 1150604,
        tooltip: {
            cz: 'Spojka palivové trysky',
            en: 'Fuel rail mounting',
            ua: 'Зʼєднувач паливної форсунки',
            bg: 'Конектор за горивен инжектор'
        }
    },
    {
        unitId: 1034,
        systemId: 4,
        id: '2cfe50e8-e32f-466f-afdf-8771b426336f',
        name: '发动机350',
        location: '[0.12507840991020203, 0.07149919867515564, 0.11814844608306885]',
        vertices: 1409,
        polygons: 952,
        lod: ['LOD_3'],
        name_work: 'dtl350',
        name_ua: 'Зʼєднувач паливної форсунки',
        group: 1150604,
        tooltip: {
            cz: 'Spojka palivové trysky',
            en: 'Fuel rail mounting',
            ua: 'Зʼєднувач паливної форсунки',
            bg: 'Конектор за горивен инжектор'
        }
    },
    {
        unitId: 1035,
        systemId: 4,
        id: '68bbba8c-faad-46d9-bf94-d55cfaed5367',
        name: '发动机349',
        location: '[-0.08942978829145432, 0.06915447115898132, 0.11426961421966553]',
        vertices: 918,
        polygons: 626,
        lod: ['LOD_3'],
        name_work: 'dtl349',
        name_ua: 'Кріплення паливної рейки',
        group: 1150400,
        tooltip: {
            cz: 'Montáž palivové lišty',
            en: 'Fuel rail mounting',
            ua: 'Кріплення паливної рейки',
            bg: 'Закрепване на горивната шина'
        }
    },
    {
        unitId: 1036,
        systemId: 4,
        id: '31b8c7cd-dfee-4ab8-8740-01be87b23d14',
        name: '发动机348',
        location: '[0.09406538307666779, 0.06913730502128601, 0.11429464817047119]',
        vertices: 918,
        polygons: 626,
        lod: ['LOD_3'],
        name_work: 'dtl348',
        name_ua: 'Кріплення паливної рейки',
        group: 1150400,
        tooltip: {
            cz: 'Montáž palivové lišty',
            en: 'Fuel rail mounting',
            ua: 'Кріплення паливної рейки',
            bg: 'Закрепване на горивната шина'
        }
    },
    {
        unitId: 1037,
        systemId: 4,
        id: '55052031-d386-4b54-be5f-34acfabf4af9',
        name: '发动机347',
        location: '[-0.1550225168466568, 0.08147892355918884, 0.12750445306301117]',
        vertices: 2367,
        polygons: 1462,
        lod: ['LOD_3'],
        name_work: 'dtl347',
        name_ua: 'Паливна рейка',
        group: 1150400,
        tooltip: { cz: 'Palivová lišta', en: 'Fuel rail', ua: 'Паливна рейка', bg: 'Горивна шина' }
    },
    {
        unitId: 1038,
        systemId: 4,
        id: '0b4e3f06-2d22-4a5f-81a7-64c445bd7691',
        name: '发动机346',
        location: '[0.0010241627460345626, 0.08417484164237976, 0.13050465285778046]',
        vertices: 578,
        polygons: 336,
        lod: ['LOD_3'],
        name_work: 'dtl346',
        name_ua: 'Паливна рейка',
        group: 1150400,
        tooltip: { cz: 'Palivová lišta', en: 'Fuel rail', ua: 'Паливна рейка', bg: 'Горивна шина' }
    },
    {
        unitId: 1039,
        systemId: 4,
        id: 'd2c73cf5-c9d4-4194-ad81-1df7ec4a798f',
        name: '发动机345',
        location: '[0.12507978081703186, 0.0717298686504364, 0.11662787199020386]',
        vertices: 388,
        polygons: 288,
        lod: ['LOD_2'],
        name_work: 'dtl345',
        name_ua: 'Ущільнення гнізда форсунки',
        group: 1140829,
        tooltip: {
            cz: 'Utěsnění soklu trysky',
            en: 'Sealing of the injector socket',
            ua: 'Ущільнення гнізда форсунки',
            bg: 'Уплътняване на седалката на дюзата'
        }
    },
    {
        unitId: 1040,
        systemId: 4,
        id: '99fa0778-0733-4d26-aaaa-06f857dd121a',
        name: '发动机344',
        location: '[0.12507978081703186, 0.03414556011557579, 0.07488667964935303]',
        vertices: 396,
        polygons: 288,
        lod: ['LOD_2'],
        name_work: 'dtl344',
        name_ua: 'Ущільнення гнізда форсунки',
        group: 1140829,
        tooltip: {
            cz: 'Utěsnění soklu trysky',
            en: 'Sealing of the injector socket',
            ua: 'Ущільнення гнізда форсунки',
            bg: 'Уплътняване на седалката на дюзата'
        }
    },
    {
        unitId: 1041,
        systemId: 4,
        id: '1d8b7ed5-9a20-47ca-b54b-a2e8c46a101c',
        name: '发动机343',
        location: '[0.12508153915405273, 0.06305167078971863, 0.10570228099822998]',
        vertices: 476,
        polygons: 286,
        lod: ['LOD_2'],
        name_work: 'dtl343',
        name_ua: 'Фіксатор форсунки',
        group: 1150601,
        tooltip: { cz: 'Držák trysky', en: 'Injector retainer', ua: 'Фіксатор форсунки', bg: 'Фиксатор на дюзата' }
    },
    {
        unitId: 1042,
        systemId: 4,
        id: 'e7809424-bf81-4db0-a7e7-f455920383cf',
        name: '发动机342',
        location: '[0.1263747215270996, 0.04620453342795372, 0.09819722175598145]',
        vertices: 7733,
        polygons: 4556,
        lod: ['LOD_3'],
        name_work: 'dtl342',
        name_ua: 'Паливна форсунка',
        group: 1150601,
        tooltip: { cz: 'Palivová tryska', en: 'Fuel injector', ua: 'Паливна форсунка', bg: 'Горивна инжектор' }
    },
    {
        unitId: 1043,
        systemId: 4,
        id: '33223d8d-fcd6-48e9-a495-ff24c7d913ff',
        name: '发动机341',
        location: '[0.04237930104136467, 0.0717298686504364, 0.11662787199020386]',
        vertices: 388,
        polygons: 288,
        lod: ['LOD_2'],
        name_work: 'dtl341',
        name_ua: 'Ущільнення гнізда форсунки',
        group: 1140829,
        tooltip: {
            cz: 'Utěsnění soklu trysky',
            en: 'Sealing of the injector socket',
            ua: 'Ущільнення гнізда форсунки',
            bg: 'Уплътняване на седалката на дюзата'
        }
    },
    {
        unitId: 1044,
        systemId: 4,
        id: 'a438c4ea-22dc-4503-bb08-ec8ac2062dfc',
        name: '发动机340',
        location: '[0.0423792228102684, 0.03414556011557579, 0.07488667964935303]',
        vertices: 397,
        polygons: 288,
        lod: ['LOD_2'],
        name_work: 'dtl340',
        name_ua: 'Ущільнення гнізда форсунки',
        group: 1140829,
        tooltip: {
            cz: 'Utěsnění soklu trysky',
            en: 'Sealing of the injector socket',
            ua: 'Ущільнення гнізда форсунки',
            bg: 'Уплътняване на седалката на дюзата'
        }
    },
    {
        unitId: 1045,
        systemId: 4,
        id: 'cb24ffb4-82cc-4fc3-84b9-67d2fe0e4d96',
        name: '发动机339',
        location: '[0.04237945005297661, 0.06305167078971863, 0.10570228099822998]',
        vertices: 478,
        polygons: 286,
        lod: ['LOD_2'],
        name_work: 'dtl339',
        name_ua: 'Фіксатор форсунки',
        group: 1150601,
        tooltip: { cz: 'Držák trysky', en: 'Injector retainer', ua: 'Фіксатор форсунки', bg: 'Фиксатор на дюзата' }
    },
    {
        unitId: 1046,
        systemId: 4,
        id: '4a4c6f25-ab04-497e-88c9-8a8d32d14e2f',
        name: '发动机338',
        location: '[0.04367415979504585, 0.04620465263724327, 0.09819722175598145]',
        vertices: 7717,
        polygons: 4556,
        lod: ['LOD_3'],
        name_work: 'dtl338',
        name_ua: 'Паливна форсунка',
        group: 1150601,
        tooltip: { cz: 'Palivová tryska', en: 'Fuel injector', ua: 'Паливна форсунка', bg: 'Горивна инжектор' }
    },
    {
        unitId: 1047,
        systemId: 4,
        id: '11162c17-f541-49c1-9793-f45271cd4dec',
        name: '发动机337',
        location: '[-0.04032592847943306, 0.07172998785972595, 0.11662834882736206]',
        vertices: 388,
        polygons: 288,
        lod: ['LOD_2'],
        name_work: 'dtl337',
        name_ua: 'Ущільнення гнізда форсунки',
        group: 1140829,
        tooltip: {
            cz: 'Utěsnění soklu trysky',
            en: 'Sealing of the injector socket',
            ua: 'Ущільнення гнізда форсунки',
            bg: 'Уплътняване на седалката на дюзата'
        }
    },
    {
        unitId: 1048,
        systemId: 4,
        id: '002ff073-cc38-49c5-a9b1-9441a4bead6e',
        name: '发动机336',
        location: '[-0.04032600298523903, 0.03414556011557579, 0.07488685846328735]',
        vertices: 396,
        polygons: 288,
        lod: ['LOD_2'],
        name_work: 'dtl336',
        name_ua: 'Ущільнення гнізда форсунки',
        group: 1140829,
        tooltip: {
            cz: 'Utěsnění soklu trysky',
            en: 'Sealing of the injector socket',
            ua: 'Ущільнення гнізда форсунки',
            bg: 'Уплътняване на седалката на дюзата'
        }
    },
    {
        unitId: 1049,
        systemId: 4,
        id: '6d0c5c28-a19a-4fee-9011-96a9c2c44018',
        name: '发动机335',
        location: '[-0.04032425209879875, 0.06305095553398132, 0.10570275783538818]',
        vertices: 476,
        polygons: 286,
        lod: ['LOD_2'],
        name_work: 'dtl335',
        name_ua: 'Фіксатор форсунки',
        group: 1150601,
        tooltip: { cz: 'Držák trysky', en: 'Injector retainer', ua: 'Фіксатор форсунки', bg: 'Фиксатор на дюзата' }
    },
    {
        unitId: 1050,
        systemId: 4,
        id: 'e6fe6a5e-198e-4cc5-a99f-805483e90595',
        name: '发动机334',
        location: '[-0.03903106972575188, 0.046203818172216415, 0.09819769859313965]',
        vertices: 7733,
        polygons: 4556,
        lod: ['LOD_3'],
        name_work: 'dtl334',
        name_ua: 'Паливна форсунка',
        group: 1150601,
        tooltip: { cz: 'Palivová tryska', en: 'Fuel injector', ua: 'Паливна форсунка', bg: 'Горивна инжектор' }
    },
    {
        unitId: 1051,
        systemId: 4,
        id: '952cc50f-b30f-4076-848e-7d4011bd998d',
        name: '发动机333',
        location: '[-0.1230265200138092, 0.0717298686504364, 0.11662834882736206]',
        vertices: 387,
        polygons: 288,
        lod: ['LOD_2'],
        name_work: 'dtl333',
        name_ua: 'Ущільнення гнізда форсунки',
        group: 1140829,
        tooltip: {
            cz: 'Utěsnění soklu trysky',
            en: 'Sealing of the injector socket',
            ua: 'Ущільнення гнізда форсунки',
            bg: 'Уплътняване на седалката на дюзата'
        }
    },
    {
        unitId: 1052,
        systemId: 4,
        id: '3b233208-e1ef-4b23-9ca6-e947b7d18a3b',
        name: '发动机332',
        location: '[-0.12302659451961517, 0.03414556011557579, 0.07488685846328735]',
        vertices: 397,
        polygons: 288,
        lod: ['LOD_2'],
        name_work: 'dtl332',
        name_ua: 'Ущільнення гнізда форсунки',
        group: 1140829,
        tooltip: {
            cz: 'Utěsnění soklu trysky',
            en: 'Sealing of the injector socket',
            ua: 'Ущільнення гнізда форсунки',
            bg: 'Уплътняване на седалката на дюзата'
        }
    },
    {
        unitId: 1053,
        systemId: 4,
        id: 'ad9fd8ab-e29b-4f84-a827-7979008af299',
        name: '发动机331',
        location: '[-0.12302635610103607, 0.06305178999900818, 0.10570275783538818]',
        vertices: 478,
        polygons: 286,
        lod: ['LOD_2'],
        name_work: 'dtl331',
        name_ua: 'Фіксатор форсунки',
        group: 1150601,
        tooltip: { cz: 'Držák trysky', en: 'Injector retainer', ua: 'Фіксатор форсунки', bg: 'Фиксатор на дюзата' }
    },
    {
        unitId: 1054,
        systemId: 4,
        id: '00783a90-99b1-4a7b-8b51-7b8ac22a79a6',
        name: '发动机330',
        location: '[-0.12173165380954742, 0.04620465263724327, 0.09819769859313965]',
        vertices: 7733,
        polygons: 4556,
        lod: ['LOD_3'],
        name_work: 'dtl330',
        name_ua: 'Паливна форсунка',
        group: 1150601,
        tooltip: { cz: 'Palivová tryska', en: 'Fuel injector', ua: 'Паливна форсунка', bg: 'Горивна инжектор' }
    },
    {
        unitId: 1055,
        systemId: 4,
        id: 'd09a0986-4d9d-4158-beb3-14b2a1f70bdc',
        name: '发动机329',
        location: '[0.12507978081703186, 0.07142361998558044, 0.11478263139724731]',
        vertices: 2376,
        polygons: 1512,
        lod: ['LOD_3'],
        name_work: 'dtl329',
        name_ua: 'Фіксатор ізолятора форсунки',
        group: 1150601,
        tooltip: {
            cz: 'Držák izolátoru trysky',
            en: 'Insulator holder for the injector',
            ua: 'Фіксатор ізолятора форсунки',
            bg: 'Фиксатор на изолатора на инжектора'
        }
    },
    {
        unitId: 1056,
        systemId: 4,
        id: '1cabce90-a0cb-4936-b04b-89825ac5fb18',
        name: '发动机328',
        location: '[0.0423792228102684, 0.07142361998558044, 0.11478263139724731]',
        vertices: 2376,
        polygons: 1512,
        lod: ['LOD_3'],
        name_work: 'dtl328',
        name_ua: 'Фіксатор ізолятора форсунки',
        group: 1150601,
        tooltip: {
            cz: 'Držák izolátoru trysky',
            en: 'Insulator holder for the injector',
            ua: 'Фіксатор ізолятора форсунки',
            bg: 'Фиксатор на изолатора на инжектора'
        }
    },
    {
        unitId: 1057,
        systemId: 4,
        id: 'd0389b0a-2bb7-48a0-be4c-d6d2ee20fd6b',
        name: '发动机327',
        location: '[-0.04032600298523903, 0.07142278552055359, 0.11478263139724731]',
        vertices: 2376,
        polygons: 1512,
        lod: ['LOD_3'],
        name_work: 'dtl327',
        name_ua: 'Фіксатор ізолятора форсунки',
        group: 1150601,
        tooltip: {
            cz: 'Držák izolátoru trysky',
            en: 'Insulator holder for the injector',
            ua: 'Фіксатор ізолятора форсунки',
            bg: 'Фиксатор на изолатора на инжектора'
        }
    },
    {
        unitId: 1058,
        systemId: 4,
        id: '5c2f35f8-3c0c-40c4-9ab0-f434f6997fd6',
        name: '发动机326',
        location: '[-0.12302811443805695, 0.07142361998558044, 0.11478245258331299]',
        vertices: 2376,
        polygons: 1512,
        lod: ['LOD_3'],
        name_work: 'dtl326',
        name_ua: 'Фіксатор ізолятора форсунки',
        group: 1150601,
        tooltip: {
            cz: 'Držák izolátoru trysky',
            en: 'Insulator holder for the injector',
            ua: 'Фіксатор ізолятора форсунки',
            bg: 'Фиксатор на изолатора на инжектора'
        }
    },
    {
        unitId: 1059,
        systemId: 4,
        id: '17a0d3b3-b059-4175-bb43-176c07f814c9',
        name: '发动机325',
        location: '[0.09277570992708206, 0.0470709465444088, 0.09786981344223022]',
        vertices: 449,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl325',
        name_ua: 'Болт кріплення паливної рейки',
        group: 1150400,
        tooltip: {
            cz: 'Šroub pro upevnění palivové lišty',
            en: 'Bolt for fuel rail mounting',
            ua: 'Болт кріплення паливної рейки',
            bg: 'Монтажен болт на горивната шина'
        }
    },
    {
        unitId: 1060,
        systemId: 4,
        id: 'b9c2be33-e445-47c2-9cf7-4d91047d3161',
        name: '发动机324',
        location: '[-0.09072251617908478, 0.0470866821706295, 0.09784489870071411]',
        vertices: 449,
        polygons: 278,
        lod: ['LOD_3'],
        name_work: 'dtl324',
        name_ua: 'Болт кріплення паливної рейки',
        group: 1150400,
        tooltip: {
            cz: 'Šroub pro upevnění palivové lišty',
            en: 'Bolt for fuel rail mounting',
            ua: 'Болт кріплення паливної рейки',
            bg: 'Монтажен болт на горивната шина'
        }
    },
    {
        unitId: 1061,
        systemId: 4,
        id: '150a4228-0521-4807-9d99-0135010afb6f',
        name: '发动机323',
        location: '[-0.2014242708683014, 0.13304457068443298, 0.10577690601348877]',
        vertices: 627,
        polygons: 418,
        lod: ['LOD_4'],
        name_work: 'dtl323',
        name_ua: 'Болт',
        group: 1010203,
        tooltip: { cz: 'Šroub', en: 'Bolt', ua: 'Болт', bg: 'Болт' }
    },
    {
        unitId: 1062,
        systemId: 4,
        id: '575a17a2-1c6b-4c99-88ac-a991270653e5',
        name: '发动机322',
        location: '[-0.08845505863428116, 0.11042162775993347, 0.11163395643234253]',
        vertices: 35212,
        polygons: 20277,
        lod: ['LOD_4'],
        name_work: 'dtl322',
        name_ua: 'Збірка проводів',
        group: 1161600,
        tooltip: { cz: 'Montáž vodičů', en: 'Wire assembly', ua: 'Збірка проводів', bg: 'Монтаж на проводници' }
    },
    {
        unitId: 1063,
        systemId: 4,
        id: '430d3590-bdec-4e73-88d2-4021ce1fe508',
        name: '发动机321',
        location: '[0.13002924621105194, -0.040174756199121475, 0.10849100351333618]',
        vertices: 6166,
        polygons: 4860,
        lod: ['LOD_3'],
        name_work: 'dtl321',
        name_ua: 'Катушка запалювання',
        group: 1090301,
        tooltip: { cz: 'Zapalovací cívka', en: 'Ignition coil', ua: 'Катушка запалювання', bg: 'Запалителна бобина' }
    },
    {
        unitId: 1064,
        systemId: 4,
        id: 'fb34be7f-8ab3-474e-aa80-6c5877ad52f7',
        name: '发动机320',
        location: '[0.04732869938015938, -0.040174756199121475, 0.10849100351333618]',
        vertices: 6172,
        polygons: 4860,
        lod: ['LOD_3'],
        name_work: 'dtl320',
        name_ua: 'Катушка запалювання',
        group: 1090301,
        tooltip: { cz: 'Zapalovací cívka', en: 'Ignition coil', ua: 'Катушка запалювання', bg: 'Запалителна бобина' }
    },
    {
        unitId: 1065,
        systemId: 4,
        id: 'feca363d-fb2c-4c4e-9e5c-8fba07593a77',
        name: '发动机319',
        location: '[-0.03537498414516449, -0.040174756199121475, 0.10849100351333618]',
        vertices: 6169,
        polygons: 4860,
        lod: ['LOD_3'],
        name_work: 'dtl319',
        name_ua: 'Катушка запалювання',
        group: 1090301,
        tooltip: { cz: 'Zapalovací cívka', en: 'Ignition coil', ua: 'Катушка запалювання', bg: 'Запалителна бобина' }
    },
    {
        unitId: 1066,
        systemId: 4,
        id: 'd0e4f3fd-17b2-4ab3-b597-865f7b1b572c',
        name: '发动机318',
        location: '[-0.11807712912559509, -0.040174756199121475, 0.10849100351333618]',
        vertices: 6166,
        polygons: 4860,
        lod: ['LOD_3'],
        name_work: 'dtl318',
        name_ua: 'Катушка запалювання',
        group: 1090301,
        tooltip: { cz: 'Zapalovací cívka', en: 'Ignition coil', ua: 'Катушка запалювання', bg: 'Запалителна бобина' }
    },
    {
        unitId: 1067,
        systemId: 4,
        id: '6cabfabb-c564-41e5-bd11-aa379877e66f',
        name: '发动机317',
        location: '[-0.10381707549095154, -0.06458070874214172, 0.15358935296535492]',
        vertices: 524,
        polygons: 358,
        lod: ['LOD_3'],
        name_work: 'dtl317',
        name_ua: 'Болт кріплення катушки запалювання',
        group: 1090301,
        tooltip: {
            cz: 'Šroub pro upevnění zapalovací cívky',
            en: 'Bolt for mounting the ignition coil',
            ua: 'Болт кріплення катушки запалювання',
            bg: 'Монтажен болт на запалителната бобина'
        }
    },
    {
        unitId: 1068,
        systemId: 4,
        id: 'edd26985-6100-40a4-bf96-d8ade737823f',
        name: '发动机316',
        location: '[-0.021113401278853416, -0.06458130478858948, 0.15358935296535492]',
        vertices: 526,
        polygons: 358,
        lod: ['LOD_3'],
        name_work: 'dtl316',
        name_ua: 'Болт кріплення катушки запалювання',
        group: 1090301,
        tooltip: {
            cz: 'Šroub pro upevnění zapalovací cívky',
            en: 'Bolt for mounting the ignition coil',
            ua: 'Болт кріплення катушки запалювання',
            bg: 'Монтажен болт на запалителната бобина'
        }
    },
    {
        unitId: 1069,
        systemId: 4,
        id: 'c33c23c5-a60b-45f5-a4a2-749509ad2e89',
        name: '发动机315',
        location: '[0.06158874183893204, -0.06458142399787903, 0.15358935296535492]',
        vertices: 526,
        polygons: 358,
        lod: ['LOD_3'],
        name_work: 'dtl315',
        name_ua: 'Болт кріплення катушки запалювання',
        group: 1090301,
        tooltip: {
            cz: 'Šroub pro upevnění zapalovací cívky',
            en: 'Bolt for mounting the ignition coil',
            ua: 'Болт кріплення катушки запалювання',
            bg: 'Монтажен болт на запалителната бобина'
        }
    },
    {
        unitId: 1070,
        systemId: 4,
        id: 'd7d862fe-f368-4970-ad11-b4901a43c6a5',
        name: '发动机314',
        location: '[0.14429086446762085, -0.06458142399787903, 0.15358935296535492]',
        vertices: 524,
        polygons: 358,
        lod: ['LOD_3'],
        name_work: 'dtl314',
        name_ua: 'Болт кріплення катушки запалювання',
        group: 1090301,
        tooltip: {
            cz: 'Šroub pro upevnění zapalovací cívky',
            en: 'Bolt for mounting the ignition coil',
            ua: 'Болт кріплення катушки запалювання',
            bg: 'Монтажен болт на запалителната бобина'
        }
    },
    {
        unitId: 1071,
        systemId: 4,
        id: '9d40b9f9-0a8d-40c0-ad89-a0e4add5e026',
        name: '发动机313',
        location: '[0.06477628648281097, 0.04887744411826134, -0.007120167836546898]',
        vertices: 133,
        polygons: 96,
        lod: ['LOD_2'],
        name_work: 'dtl313',
        name_ua: 'Втулка клапана вентиляції картерних газів',
        group: 1141104,
        tooltip: {
            cz: 'Přívodka ventilu ventilace oleje',
            en: 'Valve guide for crankcase ventilation',
            ua: 'Втулка клапана вентиляції картерних газів',
            bg: 'Маншон на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1072,
        systemId: 4,
        id: 'c646a31d-b3eb-47ee-a642-322614d5feb0',
        name: '发动机312',
        location: '[0.0268698800355196, 0.04887744411826134, -0.007120167836546898]',
        vertices: 133,
        polygons: 96,
        lod: ['LOD_2'],
        name_work: 'dtl312',
        name_ua: 'Втулка клапана вентиляції картерних газів',
        group: 1141104,
        tooltip: {
            cz: 'Přívodka ventilu ventilace oleje',
            en: 'Valve guide for crankcase ventilation',
            ua: 'Втулка клапана вентиляції картерних газів',
            bg: 'Маншон на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1073,
        systemId: 4,
        id: '202340b3-2094-4416-a4ed-001b4996dcdd',
        name: '发动机311',
        location: '[-0.018787389621138573, 0.04887744411826134, -0.007120167836546898]',
        vertices: 133,
        polygons: 96,
        lod: ['LOD_2'],
        name_work: 'dtl311',
        name_ua: 'Втулка клапана вентиляції картерних газів',
        group: 1141104,
        tooltip: {
            cz: 'Přívodka ventilu ventilace oleje',
            en: 'Valve guide for crankcase ventilation',
            ua: 'Втулка клапана вентиляції картерних газів',
            bg: 'Маншон на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1074,
        systemId: 4,
        id: '18164bc2-9fa3-4658-9825-37080029c3e9',
        name: '发动机310',
        location: '[-0.06961541622877121, 0.048877324908971786, -0.007120167836546898]',
        vertices: 133,
        polygons: 96,
        lod: ['LOD_2'],
        name_work: 'dtl310',
        name_ua: 'Втулка клапана вентиляції картерних газів',
        group: 1141104,
        tooltip: {
            cz: 'Přívodka ventilu ventilace oleje',
            en: 'Valve guide for crankcase ventilation',
            ua: 'Втулка клапана вентиляції картерних газів',
            bg: 'Маншон на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1075,
        systemId: 4,
        id: 'd999321c-f0f8-4d5b-8aee-d46ff1c97786',
        name: '发动机309',
        location: '[-0.06961541622877121, 0.048877324908971786, -0.07086983323097229]',
        vertices: 133,
        polygons: 96,
        lod: ['LOD_2'],
        name_work: 'dtl309',
        name_ua: 'Втулка клапана вентиляції картерних газів',
        group: 1141104,
        tooltip: {
            cz: 'Přívodka ventilu ventilace oleje',
            en: 'Valve guide for crankcase ventilation',
            ua: 'Втулка клапана вентиляції картерних газів',
            bg: 'Маншон на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1076,
        systemId: 4,
        id: '83c41ecc-f7b1-444f-a6cc-c26138feb623',
        name: '发动机308',
        location: '[-0.008450401946902275, 0.04887744411826134, -0.10877540707588196]',
        vertices: 133,
        polygons: 96,
        lod: ['LOD_2'],
        name_work: 'dtl308',
        name_ua: 'Втулка клапана вентиляції картерних газів',
        group: 1141104,
        tooltip: {
            cz: 'Přívodka ventilu ventilace oleje',
            en: 'Valve guide for crankcase ventilation',
            ua: 'Втулка клапана вентиляції картерних газів',
            bg: 'Маншон на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1077,
        systemId: 4,
        id: '478bd3cd-bbca-4768-973d-79b59b7d7691',
        name: '发动机307',
        location: '[0.06477628648281097, 0.04887744411826134, -0.10877540707588196]',
        vertices: 133,
        polygons: 96,
        lod: ['LOD_2'],
        name_work: 'dtl307',
        name_ua: 'Втулка клапана вентиляції картерних газів',
        group: 1141104,
        tooltip: {
            cz: 'Přívodka ventilu ventilace oleje',
            en: 'Valve guide for crankcase ventilation',
            ua: 'Втулка клапана вентиляції картерних газів',
            bg: 'Маншон на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1078,
        systemId: 4,
        id: 'aff55804-b816-4c01-b8ea-6f444b14d0b6',
        name: '发动机306',
        location: '[0.06477628648281097, 0.04887744411826134, -0.057947613298892975]',
        vertices: 133,
        polygons: 96,
        lod: ['LOD_2'],
        name_work: 'dtl306',
        name_ua: 'Втулка клапана вентиляції картерних газів',
        group: 1141104,
        tooltip: {
            cz: 'Přívodka ventilu ventilace oleje',
            en: 'Valve guide for crankcase ventilation',
            ua: 'Втулка клапана вентиляції картерних газів',
            bg: 'Маншон на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1079,
        systemId: 4,
        id: '16db7cc9-a4a0-4166-b9df-3721b6d5828b',
        name: '发动机305',
        location: '[-0.0024215001612901688, 0.05012568458914757, -0.047825105488300323]',
        vertices: 14968,
        polygons: 10823,
        lod: ['LOD_3'],
        name_work: 'dtl305',
        name_ua: 'Маслознімний щит вентиляції картерних газів',
        group: 1141104,
        tooltip: {
            cz: 'Přívodka ventilu ventilace oleje',
            en: 'Oil scraper shield for crankcase ventilation',
            ua: 'Маслознімний щит вентиляції картерних газів',
            bg: 'Маслоотстраняващ щит за вентилация на картерните газове'
        }
    },
    {
        unitId: 1080,
        systemId: 4,
        id: '6e64d43e-8a94-4e36-b357-0f4e3adacff6',
        name: '发动机304',
        location: '[-0.040585484355688095, 0.06886407732963562, -0.032254137098789215]',
        vertices: 361,
        polygons: 288,
        lod: ['LOD_2'],
        name_work: 'dtl304',
        name_ua: 'Ущільнення клапана вентиляції картерних газів',
        group: 1140817,
        tooltip: {
            cz: 'Utěsnění ventilu ventilace oleje',
            en: 'Sealing of the crankcase ventilation valve',
            ua: 'Ущільнення клапана вентиляції картерних газів',
            bg: 'Уплътнение на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1081,
        systemId: 4,
        id: 'efa030aa-3fcb-4024-9ff3-6621f05501f7',
        name: '发动机303',
        location: '[-0.0002464370336383581, 0.04719361290335655, -0.05795464664697647]',
        vertices: 3370,
        polygons: 2474,
        lod: ['LOD_2'],
        name_work: 'dtl303',
        name_ua: 'Ущільнення клапана вентиляції картерних газів',
        group: 1140817,
        tooltip: {
            cz: 'Utěsnění ventilu ventilace oleje',
            en: 'Sealing of the crankcase ventilation valve',
            ua: 'Ущільнення клапана вентиляції картерних газів',
            bg: 'Уплътнение на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1082,
        systemId: 4,
        id: 'ca4043ce-fa52-4800-bf3f-1b8ab59f37df',
        name: '发动机302',
        location: '[-0.04058540239930153, 0.06886407732963562, -0.024737661704421043]',
        vertices: 1885,
        polygons: 1414,
        lod: ['LOD_3'],
        name_work: 'dtl302',
        name_ua: 'Гайка клапана вентиляції картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Matice ventilu ventilace oleje',
            en: 'Nut for crankcase ventilation valve',
            ua: 'Гайка клапана вентиляції картерних газів',
            bg: 'Гайка на клапана за вентилация на картерните газове'
        }
    },
    {
        unitId: 1083,
        systemId: 4,
        id: 'f8effe2a-b3b7-47e0-a34d-af55ca6a77db',
        name: '发动机301',
        location: '[-0.04629272595047951, 0.06886407732963562, -0.020716017112135887]',
        vertices: 1782,
        polygons: 1412,
        lod: ['LOD_3'],
        name_work: 'dtl301',
        name_ua: 'Клапан вентиляції картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Ventil ventilace oleje',
            en: 'Crankcase ventilation valve',
            ua: 'Клапан вентиляції картерних газів',
            bg: 'Клапан за вентилация на картерните газове'
        }
    },
    {
        unitId: 1084,
        systemId: 4,
        id: '5501f7d9-f35f-4927-8bf2-bc317f5241d8',
        name: '发动机300',
        location: '[-0.22403816878795624, 0.006985274143517017, 0.05761086195707321]',
        vertices: 13729,
        polygons: 9476,
        lod: ['LOD_4'],
        name_work: 'dtl300',
        name_ua: 'Клапан рециркуляції вихлопних газів (EGR)',
        group: 1130400,
        tooltip: {
            cz: 'Ventil pro recirkulaci spalin (EGR)',
            en: 'Exhaust gas recirculation (EGR) valve',
            ua: 'Клапан рециркуляції вихлопних газів (EGR)',
            bg: 'Клапан за рециркулация на отработените газове (EGR)'
        }
    },
    {
        unitId: 1085,
        systemId: 4,
        id: 'd1060bae-0e77-4b95-864c-50fec9a0cc0d',
        name: '发动机299',
        location: '[-0.2001742571592331, 0.021051375195384026, 0.01743214763700962]',
        vertices: 447,
        polygons: 300,
        lod: ['LOD_4'],
        name_work: 'dtl299',
        name_ua: 'Болт кріплення клапану рециркуляції',
        group: 1130400,
        tooltip: {
            cz: 'Šroub upevnění ventilu recirkulace',
            en: 'Recirculation valve mounting bolt',
            ua: 'Болт кріплення клапану рециркуляції',
            bg: 'Закрепващ болт на рециркулационния клапан'
        }
    },
    {
        unitId: 1086,
        systemId: 4,
        id: '19ed7005-98ba-481c-bd12-a3165617dfe3',
        name: '发动机298',
        location: '[-0.2001742571592331, -0.02116146869957447, 0.03336935490369797]',
        vertices: 447,
        polygons: 300,
        lod: ['LOD_4'],
        name_work: 'dtl298',
        name_ua: 'Болт кріплення клапану рециркуляції',
        group: 1130400,
        tooltip: {
            cz: 'Šroub upevnění ventilu recirkulace',
            en: 'Recirculation valve mounting bolt',
            ua: 'Болт кріплення клапану рециркуляції',
            bg: 'Закрепващ болт на рециркулационния клапан'
        }
    },
    {
        unitId: 1087,
        systemId: 4,
        id: 'cae5a0c8-cda2-4c3f-b3da-e5f0f71733fe',
        name: '发动机297',
        location: '[0.06477628648281097, 0.04521593078970909, -0.007120167836546898]',
        vertices: 466,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl297',
        name_ua: 'Болт кріплення клапана картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Šroub upevnění ventilu ventilace oleje',
            en: 'Bolt for crankcase ventilation valve mounting',
            ua: 'Болт кріплення клапана картерних газів',
            bg: 'Болт за закрепване на картерния газов клапан'
        }
    },
    {
        unitId: 1088,
        systemId: 4,
        id: '756e98b4-6365-499d-baf3-b014fc38b694',
        name: '发动机296',
        location: '[0.0268698800355196, 0.04521593078970909, -0.007120167836546898]',
        vertices: 467,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl296',
        name_ua: 'Болт кріплення клапана картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Šroub upevnění ventilu ventilace oleje',
            en: 'Bolt for crankcase ventilation valve mounting',
            ua: 'Болт кріплення клапана картерних газів',
            bg: 'Болт за закрепване на картерния газов клапан'
        }
    },
    {
        unitId: 1089,
        systemId: 4,
        id: '6729158c-6885-4dc1-a806-c6fc6a4af53c',
        name: '发动机295',
        location: '[-0.018787389621138573, 0.04521593078970909, -0.007120167836546898]',
        vertices: 466,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl295',
        name_ua: 'Болт кріплення клапана картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Šroub upevnění ventilu ventilace oleje',
            en: 'Bolt for crankcase ventilation valve mounting',
            ua: 'Болт кріплення клапана картерних газів',
            bg: 'Болт за закрепване на картерния газов клапан'
        }
    },
    {
        unitId: 1090,
        systemId: 4,
        id: '99a32749-7b84-441e-b1dd-ab8b688cbf20',
        name: '发动机294',
        location: '[-0.06961541622877121, 0.04521604999899864, -0.007120167836546898]',
        vertices: 466,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl294',
        name_ua: 'Болт кріплення клапана картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Šroub upevnění ventilu ventilace oleje',
            en: 'Bolt for crankcase ventilation valve mounting',
            ua: 'Болт кріплення клапана картерних газів',
            bg: 'Болт за закрепване на картерния газов клапан'
        }
    },
    {
        unitId: 1091,
        systemId: 4,
        id: '6656c412-1300-49e7-ac06-0c1ef671f535',
        name: '发动机293',
        location: '[-0.06961541622877121, 0.04521604999899864, -0.07086983323097229]',
        vertices: 466,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl293',
        name_ua: 'Болт кріплення клапана картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Šroub upevnění ventilu ventilace oleje',
            en: 'Bolt for crankcase ventilation valve mounting',
            ua: 'Болт кріплення клапана картерних газів',
            bg: 'Болт за закрепване на картерния газов клапан'
        }
    },
    {
        unitId: 1092,
        systemId: 4,
        id: '61ca08e5-945a-4f1e-bcef-45555481d970',
        name: '发动机292',
        location: '[0.06477628648281097, 0.04521593078970909, -0.057947613298892975]',
        vertices: 466,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl292',
        name_ua: 'Болт кріплення клапана картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Šroub upevnění ventilu ventilace oleje',
            en: 'Bolt for crankcase ventilation valve mounting',
            ua: 'Болт кріплення клапана картерних газів',
            bg: 'Болт за закрепване на картерния газов клапан'
        }
    },
    {
        unitId: 1093,
        systemId: 4,
        id: '4ac43df0-1c25-4372-a3a5-7b95f0acb380',
        name: '发动机291',
        location: '[0.06477628648281097, 0.04521593078970909, -0.10877540707588196]',
        vertices: 466,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl291',
        name_ua: 'Болт кріплення клапана картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Šroub upevnění ventilu ventilace oleje',
            en: 'Bolt for crankcase ventilation valve mounting',
            ua: 'Болт кріплення клапана картерних газів',
            bg: 'Болт за закрепване на картерния газов клапан'
        }
    },
    {
        unitId: 1094,
        systemId: 4,
        id: '21d85004-0374-433f-a003-4c1b8f779da3',
        name: '发动机290',
        location: '[-0.008450401946902275, 0.04521593078970909, -0.10877540707588196]',
        vertices: 466,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl290',
        name_ua: 'Болт кріплення клапана картерних газів',
        group: 1141101,
        tooltip: {
            cz: 'Šroub upevnění ventilu ventilace oleje',
            en: 'Bolt for crankcase ventilation valve mounting',
            ua: 'Болт кріплення клапана картерних газів',
            bg: 'Болт за закрепване на картерния газов клапан'
        }
    },
    {
        unitId: 1095,
        systemId: 4,
        id: '6cd68cb9-3016-4cd8-998c-f0d6b798a576',
        name: '发动机289',
        location: '[-0.07382874935865402, 0.08200153708457947, -0.042542465031147]',
        vertices: 1522,
        polygons: 1212,
        lod: ['LOD_3'],
        name_work: 'dtl289',
        name_ua: 'Шланг системи охолодження',
        group: 1100302,
        tooltip: {
            cz: 'Hadicový systém chlazení',
            en: 'Cooling system hose',
            ua: 'Шланг системи охолодження',
            bg: 'Шланг на охлаждащата система'
        }
    },
    {
        unitId: 1096,
        systemId: 4,
        id: '3ea8c433-237a-41a0-a8da-7f68866d2951',
        name: '发动机288',
        location: '[-0.17988725006580353, 0.06703564524650574, 0.03509228676557541]',
        vertices: 1134,
        polygons: 798,
        lod: ['LOD_4'],
        name_work: 'dtl288',
        name_ua: 'Конектор клапану EGR',
        tooltip: {
            cz: 'Konektor ventilu EGR',
            en: 'Connector for EGR valve',
            ua: 'Конектор клапану EGR',
            bg: 'Съединител за EGR клапан'
        }
    },
    {
        unitId: 1097,
        systemId: 4,
        id: 'cc9e97c1-6c2b-4594-8cc6-cac0a2e81c96',
        name: '发动机287',
        location: '[0.1744198501110077, -0.017692478373646736, 0.140936478972435]',
        vertices: 15255,
        polygons: 10045,
        lod: ['LOD_4'],
        name_work: 'dtl287',
        name_ua: 'Електромагнітний клапан розподільчого валу',
        group: 1150702,
        tooltip: {
            cz: 'Elektromagnetický ventil vačkového hřídele',
            en: 'Electromagnetic valve for camshaft',
            ua: 'Електромагнітний клапан розподільчого валу',
            bg: 'Електромагнитен клапан на разпределителния вал'
        }
    },
    {
        unitId: 1098,
        systemId: 4,
        id: '82dec54d-2bd3-4e63-9396-d9faf11dc532',
        name: '发动机286',
        location: '[0.1814214140176773, 0.0001169118404504843, 0.13239692151546478]',
        vertices: 470,
        polygons: 326,
        lod: ['LOD_3'],
        name_work: 'dtl286',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 1099,
        systemId: 4,
        id: 'e2c09972-b2bc-446e-9b56-37b8b3478077',
        name: '发动机285',
        location: '[0.1876644790172577, -0.13961461186408997, 0.05404501408338547]',
        vertices: 152,
        polygons: 92,
        lod: ['LOD_3'],
        name_work: 'dtl285',
        name_ua: 'Головка блоку циліндрів',
        group: 1040502,
        tooltip: {
            cz: 'Hlava bloku válců',
            en: 'Cylinder head',
            ua: 'Головка блоку циліндрів',
            bg: 'Главата на цилиндровия блок'
        }
    },
    {
        unitId: 1100,
        systemId: 4,
        id: 'ee3efedd-da14-48e8-91dc-50cd0cf77203',
        name: '发动机284',
        location: '[0.19065962731838226, 0.043019142001867294, 0.03336935490369797]',
        vertices: 749,
        polygons: 600,
        lod: ['LOD_3'],
        name_work: 'dtl284',
        name_ua: 'Болт кріплення заспокоювача ланцюга',
        group: 1040407,
        tooltip: {
            cz: 'Šroub pro upevnění napínače řetězu',
            en: 'Bolt for chain tensioner mounting',
            ua: 'Болт кріплення заспокоювача ланцюга',
            bg: 'Болт за закрепване на стабилизатора на веригата'
        }
    },
    {
        unitId: 1101,
        systemId: 4,
        id: 'f83ae0d9-e468-4401-9e7d-5e4bdb2d3112',
        name: '发动机283',
        location: '[0.19065962731838226, 0.0008065375732257962, -0.1337583214044571]',
        vertices: 749,
        polygons: 600,
        lod: ['LOD_3'],
        name_work: 'dtl283',
        name_ua: 'Болт кріплення заспокоювача ланцюга',
        group: 1040407,
        tooltip: {
            cz: 'Šroub pro upevnění napínače řetězu',
            en: 'Bolt for chain tensioner mounting',
            ua: 'Болт кріплення заспокоювача ланцюга',
            bg: 'Болт за закрепване на стабилизатора на веригата'
        }
    },
    {
        unitId: 1102,
        systemId: 4,
        id: '973feaf9-7db9-4c0a-b187-89ffdc36c95d',
        name: '发动机282',
        location: '[0.19701161980628967, 0.017494885250926018, -0.04860890656709671]',
        vertices: 6227,
        polygons: 4084,
        lod: ['LOD_3'],
        name_work: 'dtl282',
        name_ua: 'Заспокоювач ланцюга',
        group: 1040407,
        tooltip: { cz: 'Napínač řetězu', en: 'Chain tensioner', ua: 'Заспокоювач ланцюга', bg: 'Натяжитель цепи' }
    },
    {
        unitId: 1103,
        systemId: 4,
        id: '3f81093f-7f7d-4a49-978d-f759366c00b2',
        name: '发动机281',
        location: '[0.18926019966602325, -0.08801296353340149, -0.13272438943386078]',
        vertices: 608,
        polygons: 430,
        lod: ['LOD_3'],
        name_work: 'dtl281',
        name_ua: 'Натягувач ланцюга ГРМ',
        group: 1040405,
        tooltip: {
            cz: 'Napínák řetězu rozvodu',
            en: 'Timing chain tensioner',
            ua: 'Натягувач ланцюга ГРМ',
            bg: 'Обтегач на ангренажната верига'
        }
    },
    {
        unitId: 1104,
        systemId: 4,
        id: '04a27c9e-a6f5-456f-bf12-6843869fe0f7',
        name: '发动机280',
        location: '[0.19012175500392914, -0.10656967759132385, -0.10037082433700562]',
        vertices: 608,
        polygons: 430,
        lod: ['LOD_3'],
        name_work: 'dtl280',
        name_ua: 'Натягувач ланцюга ГРМ',
        group: 1040405,
        tooltip: {
            cz: 'Napínák řetězu rozvodu',
            en: 'Timing chain tensioner',
            ua: 'Натягувач ланцюга ГРМ',
            bg: 'Обтегач на ангренажната верига'
        }
    },
    {
        unitId: 1105,
        systemId: 4,
        id: 'e52e6b18-c93b-4885-b52e-75a873c403b2',
        name: '发动机279',
        location: '[0.19701161980628967, -0.10337856411933899, -0.04530561715364456]',
        vertices: 2860,
        polygons: 2044,
        lod: ['LOD_3'],
        name_work: 'dtl279',
        name_ua: 'Натягувач ланцюга ГРМ',
        group: 1040405,
        tooltip: {
            cz: 'Napínák řetězu rozvodu',
            en: 'Timing chain tensioner',
            ua: 'Натягувач ланцюга ГРМ',
            bg: 'Обтегач на ангренажната верига'
        }
    },
    {
        unitId: 1106,
        systemId: 4,
        id: 'abba54e3-f0a6-46c6-8d30-a067dfdd2121',
        name: '发动机278',
        location: '[0.1976577639579773, -0.09889915585517883, -0.11507734656333923]',
        vertices: 26762,
        polygons: 15729,
        lod: ['LOD_4'],
        name_work: 'dtl278',
        name_ua: 'Натягувач ланцюга ГРМ',
        group: 1040405,
        tooltip: {
            cz: 'Napínák řetězu rozvodu',
            en: 'Timing chain tensioner',
            ua: 'Натягувач ланцюга ГРМ',
            bg: 'Обтегач на ангренажната верига'
        }
    },
    {
        unitId: 1107,
        systemId: 4,
        id: '3c9192e9-2e9e-420b-85e2-1a824ac41e13',
        name: '发动机277',
        location: '[-0.17665162682533264, 0.0998755395412445, -0.20439980924129486]',
        vertices: 246,
        polygons: 144,
        lod: ['LOD_3'],
        name_work: 'dtl277',
        name_ua: 'Втулка кріплення стартера',
        group: 1170700,
        tooltip: {
            cz: 'Přívodka pro upevnění startéru',
            en: 'Starter mounting bushing',
            ua: 'Втулка кріплення стартера',
            bg: 'Маншон за закрепване на стартера'
        }
    },
    {
        unitId: 1108,
        systemId: 4,
        id: '93c43985-a646-4c7d-8103-b4fa9d469774',
        name: '发动机276',
        location: '[-0.17665009200572968, -0.1783815324306488, -0.09843742847442627]',
        vertices: 246,
        polygons: 144,
        lod: ['LOD_3'],
        name_work: 'dtl276',
        name_ua: 'Втулка кріплення',
        group: 1170700,
        tooltip: { cz: 'Montážní pouzdro', en: 'Bushing for mounting', ua: 'Втулка кріплення', bg: 'Монтажна втулка' }
    },
    {
        unitId: 1109,
        systemId: 4,
        id: '4d598361-460c-494e-8b1e-6d631ddf84fb',
        name: '发动机275',
        location: '[-0.16696229577064514, 0.10021063685417175, -0.18211708962917328]',
        vertices: 249,
        polygons: 144,
        lod: ['LOD_3'],
        name_work: 'dtl275',
        name_ua: 'Втулка кріплення стартера',
        group: 1010208,
        tooltip: {
            cz: 'Přívodka pro upevnění startéru',
            en: 'Starter mounting bushing',
            ua: 'Втулка кріплення стартера',
            bg: 'Маншон за закрепване на стартера'
        }
    },
    {
        unitId: 1110,
        systemId: 4,
        id: 'd2bf9067-7b3a-4693-b519-8ba7f0fa7df1',
        name: '发动机274',
        location: '[-0.16696220636367798, 0.06646522879600525, -0.10519745945930481]',
        vertices: 249,
        polygons: 144,
        lod: ['LOD_3'],
        name_work: 'dtl274',
        name_ua: 'Втулка кріплення стартера',
        group: 1170700,
        tooltip: {
            cz: 'Přívodka pro upevnění startéru',
            en: 'Starter mounting bushing',
            ua: 'Втулка кріплення стартера',
            bg: 'Маншон за закрепване на стартера'
        }
    },
    {
        unitId: 1111,
        systemId: 4,
        id: 'bd193eb3-e40d-424e-9153-2ea38f42eed5',
        name: '发动机273',
        location: '[-0.11010697484016418, -0.11893931031227112, -0.02176559530198574]',
        vertices: 393,
        polygons: 245,
        lod: ['LOD_2'],
        name_work: 'dtl273',
        name_ua: 'Заглушка блоку циліндрів',
        group: 1141702,
        tooltip: {
            cz: 'Zátka bloku válců',
            en: 'Cylinder block plug',
            ua: 'Заглушка блоку циліндрів',
            bg: 'Щепсел на цилиндровия блок'
        }
    },
    {
        unitId: 1112,
        systemId: 4,
        id: 'b328151e-5551-4fff-a119-2d5b0074c313',
        name: '发动机272',
        location: '[0.14575347304344177, -0.13423052430152893, -0.2224910706281662]',
        vertices: 458,
        polygons: 332,
        lod: ['LOD_2'],
        name_work: 'dtl272',
        name_ua: 'Болт заглушка блоку циліндрів',
        group: 1141702,
        tooltip: {
            cz: 'Šroub zátky bloku válců',
            en: 'Cylinder block plug bolt',
            ua: 'Болт заглушка блоку циліндрів',
            bg: 'Болт на пробката на цилиндровия блок'
        }
    },
    {
        unitId: 1113,
        systemId: 4,
        id: 'e1dbad04-0ebe-4dad-bbb5-c6e7852f770e',
        name: '发动机271',
        location: '[0.0031787510961294174, -0.03510931506752968, -0.13074304163455963]',
        vertices: 235615,
        polygons: 149909,
        lod: ['LOD_4'],
        name_work: 'dtl271',
        name_ua: 'Блок циліндрів',
        group: 1141701,
        tooltip: { cz: 'Blok válců', en: 'Cylinder block', ua: 'Блок циліндрів', bg: 'Блок цилиндров' }
    },
    {
        unitId: 1114,
        systemId: 4,
        id: 'c935ee66-4846-47f1-96d2-056e8b91bf36',
        name: '发动机270',
        location: '[0.16643087565898895, -5.4987958719721064e-05, 0.0006331445183604956]',
        vertices: 257,
        polygons: 240,
        lod: ['LOD_2'],
        name_work: 'dtl270',
        name_ua: 'Втулка блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Vložka válcové hlavy',
            en: 'Cylinder block bushing',
            ua: 'Втулка блоку циліндрів',
            bg: 'Втулка на цилиндровия блок'
        }
    },
    {
        unitId: 1115,
        systemId: 4,
        id: '84d55f79-7dd8-4e99-9074-d0dc0a4e21e2',
        name: '发动机269',
        location: '[-0.16437920928001404, -5.4272702982416376e-05, 0.0006331445183604956]',
        vertices: 257,
        polygons: 240,
        lod: ['LOD_2'],
        name_work: 'dtl269',
        name_ua: 'Втулка блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Vložka válcové hlavy',
            en: 'Cylinder block bushing',
            ua: 'Втулка блоку циліндрів',
            bg: 'Втулка на цилиндровия блок'
        }
    },
    {
        unitId: 1116,
        systemId: 4,
        id: '1dbe5d6a-ebdd-48aa-96f7-273d91a278ac',
        name: '发动机268',
        location: '[0.16643118858337402, -0.009531411342322826, -0.20584695041179657]',
        vertices: 914,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl268',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1117,
        systemId: 4,
        id: '656502cf-22c6-4208-b46b-c850e0f63da0',
        name: '发动机267',
        location: '[0.16643118858337402, -0.08017352223396301, -0.20584650337696075]',
        vertices: 914,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl267',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1118,
        systemId: 4,
        id: '591a44dd-8a15-43bc-99ee-313e2c6c91a2',
        name: '发动机266',
        location: '[0.08372904360294342, -0.009531411342322826, -0.20584695041179657]',
        vertices: 914,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl266',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1119,
        systemId: 4,
        id: '545101e8-827e-4c5f-ae66-7b9d515734ca',
        name: '发动机265',
        location: '[0.08372899889945984, -0.08017352223396301, -0.20584650337696075]',
        vertices: 914,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl265',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1120,
        systemId: 4,
        id: '1c46082d-9fe9-4fa2-8d9e-a011ceb7a5cf',
        name: '发动机264',
        location: '[0.0010269287740811706, -0.009531411342322826, -0.20584695041179657]',
        vertices: 917,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl264',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1121,
        systemId: 4,
        id: '64beacf8-9460-4a5c-87f4-590263f1b221',
        name: '发动机263',
        location: '[0.0010269101476296782, -0.08017352223396301, -0.20584650337696075]',
        vertices: 917,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl263',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1122,
        systemId: 4,
        id: '4bcb1bae-a4bc-42d4-ac21-acbffb1ee8ef',
        name: '发动机262',
        location: '[-0.08167675882577896, -0.009531292133033276, -0.20584695041179657]',
        vertices: 914,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl262',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1123,
        systemId: 4,
        id: 'af4f19d3-106d-469d-a145-a1757c948f2b',
        name: '发动机261',
        location: '[-0.08167679607868195, -0.08017352223396301, -0.20584695041179657]',
        vertices: 914,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl261',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1124,
        systemId: 4,
        id: '827a532a-6142-4525-9817-4dccb71aae30',
        name: '发动机260',
        location: '[-0.16437889635562897, -0.009531411342322826, -0.20584695041179657]',
        vertices: 914,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl260',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1125,
        systemId: 4,
        id: '1ffd7354-1924-4a2c-a62a-a529de5ecbc0',
        name: '发动机259',
        location: '[-0.16437889635562897, -0.08017268776893616, -0.20584695041179657]',
        vertices: 914,
        polygons: 688,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl259',
        name_ua: 'Болт кріплення корінних підшипників',
        group: 1141700,
        tooltip: {
            cz: 'Šroub upevnění hlavních ložisek',
            en: 'Bolt for mounting main bearings',
            ua: 'Болт кріплення корінних підшипників',
            bg: 'Монтажен болт на основния лагер'
        }
    },
    {
        unitId: 1126,
        systemId: 4,
        id: '945324cf-7b68-444d-85b0-ddff951286be',
        name: '发动机258',
        location: '[0.001583377248607576, -0.04486456885933876, -0.22421212494373322]',
        vertices: 20292,
        polygons: 12157,
        lod: ['LOD_3'],
        name_work: 'dtl258',
        name_ua: 'Корінна опора колінчастого валу',
        group: 1141701,
        tooltip: {
            cz: 'Hlavní opora klikového hřídele',
            en: 'Main bearing support',
            ua: 'Корінна опора колінчастого валу',
            bg: 'Основната опора на коляновия вал'
        }
    },
    {
        unitId: 1127,
        systemId: 4,
        id: '9b9d052f-feb7-4854-963f-06b69169c9a7',
        name: '发动机257',
        location: '[0.10151487588882446, 0.057968225330114365, -0.01397979911416769]',
        vertices: 2136,
        polygons: 1702,
        lod: ['LOD_4'],
        name_work: 'dtl257',
        name_ua: 'Патрубок термостата',
        group: 1100402,
        tooltip: {
            cz: 'Těleso termostatu',
            en: 'Thermostat hose',
            ua: 'Патрубок термостата',
            bg: 'Тръбопровод на термостата'
        }
    },
    {
        unitId: 1128,
        systemId: 4,
        id: '8956e8a6-245b-4951-802d-02f01fdcd668',
        name: '发动机256',
        location: '[-0.16437965631484985, -0.08964863419532776, 0.055324487388134]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_3'],
        name_work: 'dtl256',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1129,
        systemId: 4,
        id: 'c8b23185-9053-4f7b-8f32-d7af8a55e536',
        name: '发动机255',
        location: '[-0.16437828540802002, -0.08964863419532776, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl255',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1130,
        systemId: 4,
        id: '6c0c9971-3f0e-4617-8e4b-a4da8c3578b9',
        name: '发动机254',
        location: '[-0.16437965631484985, -5.4272702982416376e-05, 0.0553242489695549]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_2'],
        name_work: 'dtl254',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1131,
        systemId: 4,
        id: 'a939fdab-afad-4042-93ad-020ead69a939',
        name: '发动机253',
        location: '[-0.16437828540802002, -5.4272702982416376e-05, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl253',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1132,
        systemId: 4,
        id: 'f29129ed-7139-4c2c-a9e2-fbc22324b09a',
        name: '发动机252',
        location: '[-0.08167603611946106, -0.08964875340461731, 0.055324487388134]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_2'],
        name_work: 'dtl252',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1133,
        systemId: 4,
        id: '15835271-b298-41e9-9eb5-f97d301bfa04',
        name: '发动机251',
        location: '[-0.0816761925816536, -0.08964875340461731, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl251',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1134,
        systemId: 4,
        id: '69444d89-3094-42c0-a50f-5c24831491c7',
        name: '发动机250',
        location: '[-0.08167599886655807, -5.4987958719721064e-05, 0.0553242489695549]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_2'],
        name_work: 'dtl250',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1135,
        systemId: 4,
        id: 'c703e1fe-f1f8-423e-a033-8a5d4624bd3b',
        name: '发动机249',
        location: '[-0.08167614787817001, -5.4987958719721064e-05, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl249',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1136,
        systemId: 4,
        id: '768d36a4-a1e1-4537-9f09-617720e92a72',
        name: '发动机248',
        location: '[0.0010261464631184936, -0.08964875340461731, 0.055324487388134]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_2'],
        name_work: 'dtl248',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1137,
        systemId: 4,
        id: '9fc53604-c17a-4681-b6a5-09c0b5b8aaf9',
        name: '发动机247',
        location: '[0.0010259173577651381, -0.08964875340461731, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl247',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1138,
        systemId: 4,
        id: '75851572-f901-4906-99d3-247c96036dbc',
        name: '发动机246',
        location: '[0.0010261278366670012, -5.4987958719721064e-05, 0.0553242489695549]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_2'],
        name_work: 'dtl246',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1139,
        systemId: 4,
        id: 'a4eb83c0-d362-42d9-b27e-1a5c0a7db123',
        name: '发动机245',
        location: '[0.0010259750997647643, -5.4987958719721064e-05, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl245',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1140,
        systemId: 4,
        id: '786cbd10-5f56-435d-8b3c-6dae72a7f836',
        name: '发动机244',
        location: '[0.08372823894023895, -0.08964875340461731, 0.055324487388134]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_2'],
        name_work: 'dtl244',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1141,
        systemId: 4,
        id: 'f25c9a88-e01e-46a3-93cb-40041e6cdad1',
        name: '发动机243',
        location: '[0.08372808992862701, -0.08964875340461731, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl243',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1142,
        systemId: 4,
        id: '56001c28-cb09-43c2-bd34-04b45d1f3875',
        name: '发动机242',
        location: '[0.08372823894023895, -5.4987958719721064e-05, 0.0553242489695549]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_2'],
        name_work: 'dtl242',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1143,
        systemId: 4,
        id: 'f7b9fef4-0e48-4106-ad91-f859e398fa57',
        name: '发动机241',
        location: '[0.08372808992862701, -5.4987958719721064e-05, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl241',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1144,
        systemId: 4,
        id: '2723f2ba-7664-4a55-98d9-a6b5ba444038',
        name: '发动机240',
        location: '[0.16643187403678894, -0.08964875340461731, 0.055324487388134]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_2'],
        name_work: 'dtl240',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1145,
        systemId: 4,
        id: 'fe396c36-603a-4e31-a8e7-fb279238a96b',
        name: '发动机239',
        location: '[0.16643019020557404, -0.08964875340461731, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl239',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1146,
        systemId: 4,
        id: 'b38f03d4-d45b-4493-b400-4e132e74ff8d',
        name: '发动机238',
        location: '[0.1664319485425949, -5.4987958719721064e-05, 0.0553242489695549]',
        vertices: 392,
        polygons: 336,
        lod: ['LOD_2'],
        name_work: 'dtl238',
        name_ua: 'Шайба головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Podložka hlavy bloku válců',
            en: 'Cylinder head washer',
            ua: 'Шайба головки блоку циліндрів',
            bg: 'Шайба на цилиндровата глава'
        }
    },
    {
        unitId: 1147,
        systemId: 4,
        id: '6b9365ad-e3dd-40ce-8613-112174c5c465',
        name: '发动机237',
        location: '[0.16643019020557404, -5.4987958719721064e-05, 0.006324851885437965]',
        vertices: 1094,
        polygons: 830,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl237',
        name_ua: 'Блот головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Blok hlavy bloku válců',
            en: 'Cylinder head bolt',
            ua: 'Блот головки блоку циліндрів',
            bg: 'Блот главата на цилиндровия блок'
        }
    },
    {
        unitId: 1148,
        systemId: 4,
        id: '12b50f50-5990-486a-806d-b844cbaf9496',
        name: '发动机236',
        location: '[0.17448310554027557, -0.014312061481177807, 0.12102562189102173]',
        vertices: 7152,
        polygons: 5181,
        lod: ['LOD_3'],
        name_work: 'dtl236',
        name_ua: 'Кришка опори розподільчого валу',
        group: 1140504,
        tooltip: {
            cz: 'Kryt podpory vačkového hřídele',
            en: 'Camshaft support cap',
            ua: 'Кришка опори розподільчого валу',
            bg: 'Капак на опората на разпределителния вал'
        }
    },
    {
        unitId: 1149,
        systemId: 4,
        id: '01093d67-8034-4572-9bc1-da1832c49fe1',
        name: '发动机235',
        location: '[0.16452381014823914, -0.016329558566212654, 0.11709004640579224]',
        vertices: 563,
        polygons: 236,
        lod: ['LOD_2'],
        name_work: 'dtl235',
        name_ua: 'Втулка головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Bush hlavy válce',
            en: 'Cylinder head bushing',
            ua: 'Втулка головки блоку циліндрів',
            bg: 'Втулка на цилиндровата глава'
        }
    },
    {
        unitId: 1150,
        systemId: 4,
        id: '95fb8328-b14c-4db4-84b9-4a3e8bad9d9f',
        name: '发动机234',
        location: '[0.1849508285522461, -0.013579878024756908, 0.12167137861251831]',
        vertices: 371,
        polygons: 264,
        lod: ['LOD_3'],
        name_work: 'dtl234',
        name_ua: 'Втулка кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Bush pro upevnění vík ložisek vačkového hřídele',
            en: 'Bushing for mounting the camshaft caps',
            ua: 'Втулка кріплення кришок опор розподільчих валів',
            bg: 'Втулка за закрепване на капаците на опорите на разпределителния вал'
        }
    },
    {
        unitId: 1151,
        systemId: 4,
        id: 'b8180cf0-bb9b-4459-af13-2cf65fea6421',
        name: '发动机233',
        location: '[0.18301253020763397, 0.06994101405143738, 0.07601279020309448]',
        vertices: 341,
        polygons: 238,
        lod: ['LOD_3'],
        name_work: 'dtl233',
        name_ua: 'Втулка кріплення головки блоку циліндрів',
        group: 1140500,
        tooltip: {
            cz: 'Bush pro upevnění hlavy válce',
            en: 'Bushing for mounting the cylinder head',
            ua: 'Втулка кріплення головки блоку циліндрів',
            bg: 'Монтажна втулка на цилиндровата глава'
        }
    },
    {
        unitId: 1152,
        systemId: 4,
        id: '0166296d-906b-45e4-9de6-6a32bb8561de',
        name: '发动机232',
        location: '[-0.13853439688682556, -0.13697406649589539, 0.008817278780043125]',
        vertices: 457,
        polygons: 332,
        lod: ['LOD_3'],
        name_work: 'dtl232',
        name_ua: 'Шпильки випускного колектора',
        group: 1130500,
        tooltip: {
            cz: 'Šrouby výfukového svodu',
            en: 'Exhaust manifold studs',
            ua: 'Шпильки випускного колектора',
            bg: 'Щифтове на изпускателния колектор'
        }
    },
    {
        unitId: 1153,
        systemId: 4,
        id: 'd50c2d3c-9376-44a4-a119-a02b8846bc09',
        name: '发动机231',
        location: '[-0.14456528425216675, -0.13697406649589539, 0.06481379270553589]',
        vertices: 457,
        polygons: 332,
        lod: ['LOD_3'],
        name_work: 'dtl231',
        name_ua: 'Шпильки випускного колектора',
        group: 1130500,
        tooltip: {
            cz: 'Šrouby výfukového svodu',
            en: 'Exhaust manifold studs',
            ua: 'Шпильки випускного колектора',
            bg: 'Щифтове на изпускателния колектор'
        }
    },
    {
        unitId: 1154,
        systemId: 4,
        id: '85c3b4a1-fafe-444e-885b-76c8153af52f',
        name: '发动机230',
        location: '[0.14661680161952972, -0.13697484135627747, 0.06481379270553589]',
        vertices: 457,
        polygons: 332,
        lod: ['LOD_3'],
        name_work: 'dtl230',
        name_ua: 'Шпильки випускного колектора ',
        group: 1130500,
        tooltip: {
            cz: 'Šrouby výfukového svodu',
            en: 'Exhaust manifold studs',
            ua: 'Шпильки випускного колектора',
            bg: 'Щифтове на изпускателния колектор'
        }
    },
    {
        unitId: 1155,
        systemId: 4,
        id: '0d60290c-b81c-421e-8113-70cf533889be',
        name: '发动机229',
        location: '[0.06994398683309555, -0.13697490096092224, 0.008817278780043125]',
        vertices: 457,
        polygons: 332,
        lod: ['LOD_3'],
        name_work: 'dtl229',
        name_ua: 'Шпильки випускного колектора ',
        group: 1130500,
        tooltip: {
            cz: 'Šrouby výfukového svodu',
            en: 'Exhaust manifold studs',
            ua: 'Шпильки випускного колектора',
            bg: 'Щифтове на изпускателния колектор'
        }
    },
    {
        unitId: 1156,
        systemId: 4,
        id: 'fd7084b3-d767-4b65-8a8a-4a4bc1a4f1e9',
        name: '发动机228',
        location: '[-0.06789246946573257, -0.13697484135627747, 0.008817278780043125]',
        vertices: 458,
        polygons: 332,
        lod: ['LOD_3'],
        name_work: 'dtl228',
        name_ua: 'Шпильки випускного колектора ',
        group: 1130500,
        tooltip: {
            cz: 'Šrouby výfukového svodu',
            en: 'Exhaust manifold studs',
            ua: 'Шпильки випускного колектора',
            bg: 'Щифтове на изпускателния колектор'
        }
    },
    {
        unitId: 1157,
        systemId: 4,
        id: '7daa500c-8dfb-4986-83d9-87b9ba1a6658',
        name: '发动机227',
        location: '[0.001025077304802835, -0.13697490096092224, 0.06481379270553589]',
        vertices: 457,
        polygons: 332,
        lod: ['LOD_3'],
        name_work: 'dtl227',
        name_ua: 'Шпильки випускного колектора ',
        group: 1130500,
        tooltip: {
            cz: 'Šrouby výfukového svodu',
            en: 'Exhaust manifold studs',
            ua: 'Шпильки випускного колектора',
            bg: 'Щифтове на изпускателния колектор'
        }
    },
    {
        unitId: 1158,
        systemId: 4,
        id: 'ed929741-ef49-4b58-80d7-6a4b0c52f4ad',
        name: '发动机226',
        location: '[-0.19711486995220184, -0.1369750201702118, 0.007955783046782017]',
        vertices: 448,
        polygons: 332,
        lod: ['LOD_3'],
        name_work: 'dtl226',
        name_ua: 'Шпильки випускного колектора ',
        group: 1130500,
        tooltip: {
            cz: 'Šrouby výfukového svodu',
            en: 'Exhaust manifold studs',
            ua: 'Шпильки випускного колектора',
            bg: 'Щифтове на изпускателния колектор'
        }
    },
    {
        unitId: 1159,
        systemId: 4,
        id: '55b2b392-c123-46fe-a500-6dacc0b2952f',
        name: '发动机225',
        location: '[-0.08167710155248642, -0.0473506785929203, 0.05363147705793381]',
        vertices: 873,
        polygons: 590,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl225',
        name_ua: 'Заглушка головки блоку циліндрів',
        group: 1140509,
        tooltip: {
            cz: 'Zátka hlavy válce',
            en: 'Cylinder head plug',
            ua: 'Заглушка головки блоку циліндрів',
            bg: 'Щепсел на цилиндровата глава'
        }
    },
    {
        unitId: 1160,
        systemId: 4,
        id: 'a2e35d9e-a35c-44b2-b518-db305971336e',
        name: '发动机224',
        location: '[0.08372873812913895, -0.0473506785929203, 0.05363147705793381]',
        vertices: 873,
        polygons: 590,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl224',
        name_ua: 'Заглушка головки блоку циліндрів',
        group: 1140509,
        tooltip: {
            cz: 'Zátka hlavy válce',
            en: 'Cylinder head plug',
            ua: 'Заглушка головки блоку циліндрів',
            bg: 'Щепсел на цилиндровата глава'
        }
    },
    {
        unitId: 1161,
        systemId: 4,
        id: 'e5649d24-af3a-4ad5-b785-deb48a2be7d3',
        name: '发动机223',
        location: '[-0.18593384325504303, -0.05432847514748573, 0.07428991794586182]',
        vertices: 865,
        polygons: 590,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl223',
        name_ua: 'Заглушка головки блоку циліндрів',
        group: 1140509,
        tooltip: {
            cz: 'Zátka hlavy válce',
            en: 'Cylinder head plug',
            ua: 'Заглушка головки блоку циліндрів',
            bg: 'Щепсел на цилиндровата глава'
        }
    },
    {
        unitId: 1162,
        systemId: 4,
        id: 'cd505358-4c03-43d6-8473-e6f6e71a5343',
        name: '发动机222',
        location: '[0.18195460736751556, -0.023315342143177986, 0.020016485825181007]',
        vertices: 859,
        polygons: 590,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl222',
        name_ua: 'Заглушка головки блоку циліндрів',
        group: 1140509,
        tooltip: {
            cz: 'Zátka hlavy válce',
            en: 'Cylinder head plug',
            ua: 'Заглушка головки блоку циліндрів',
            bg: 'Щепсел на цилиндровата глава'
        }
    },
    {
        unitId: 1163,
        systemId: 4,
        id: 'b574d8b7-68c8-4863-9e5f-5e80e69e880c',
        name: '发动机221',
        location: '[0.18301253020763397, 0.07209476828575134, 0.07601279020309448]',
        vertices: 132,
        polygons: 96,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl221',
        name_ua: 'Ущільнення заглушки впускного колектора',
        group: 1140604,
        tooltip: {
            cz: 'Těsnění zátky sacího svodu',
            en: 'Sealing for intake manifold plug',
            ua: 'Ущільнення заглушки впускного колектора',
            bg: 'Уплътняване на пробката на всмукателния колектор'
        }
    },
    {
        unitId: 1164,
        systemId: 4,
        id: '4329261e-c221-4f0a-a636-576e24e0d0a8',
        name: '发动机220',
        location: '[-0.18596746027469635, 0.016743509098887444, 0.07601296901702881]',
        vertices: 68,
        polygons: 44,
        lod: ['LOD_2'],
        name_work: 'dtl220',
        name_ua: 'Втулка головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Bush hlavy válce',
            en: 'Cylinder head bushing',
            ua: 'Втулка головки блоку циліндрів',
            bg: 'Втулка на цилиндровата глава'
        }
    },
    {
        unitId: 1165,
        systemId: 4,
        id: 'c0b990d5-1faf-4c37-874d-4b8645aad555',
        name: '发动机219',
        location: '[-0.1766633689403534, -0.11196163296699524, 0.07601296901702881]',
        vertices: 68,
        polygons: 44,
        lod: ['LOD_2'],
        name_work: 'dtl219',
        name_ua: 'Втулка головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Bush hlavy válce',
            en: 'Cylinder head bushing',
            ua: 'Втулка головки блоку циліндрів',
            bg: 'Втулка на цилиндровата глава'
        }
    },
    {
        unitId: 1166,
        systemId: 4,
        id: '2f5b0dcd-cf2e-4377-b06f-4987b5b85554',
        name: '发动机218',
        location: '[0.1778436154127121, -0.15318289399147034, 0.038969092071056366]',
        vertices: 70,
        polygons: 44,
        lod: ['LOD_2'],
        name_work: 'dtl218',
        name_ua: 'Втулка головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Bush hlavy válce',
            en: 'Cylinder head bushing',
            ua: 'Втулка головки блоку циліндрів',
            bg: 'Втулка на цилиндровата глава'
        }
    },
    {
        unitId: 1167,
        systemId: 4,
        id: '1edd4fac-0285-4fdf-823b-3014b8b318e5',
        name: '发动机217',
        location: '[0.18084639310836792, 0.029073325917124748, 0.10702627897262573]',
        vertices: 151,
        polygons: 92,
        lod: ['LOD_3'],
        name_work: 'dtl217',
        name_ua: 'Направляюча кріплення кришки опори розподільчого вала',
        group: 1140503,
        tooltip: {
            cz: 'Vedení pro upevnění víka vačkového hřídele',
            en: 'Guide for mounting the camshaft support cover',
            ua: 'Направляюча кріплення кришки опори розподільчого вала',
            bg: 'Направляващо закрепване на опорния капак на разпределителния вал'
        }
    },
    {
        unitId: 1168,
        systemId: 4,
        id: 'edeef634-8b8c-4271-890a-e8d7d28c6b39',
        name: '发动机216',
        location: '[0.184979647397995, -0.04458335414528847, 0.10702627897262573]',
        vertices: 151,
        polygons: 92,
        lod: ['LOD_3'],
        name_work: 'dtl216',
        name_ua: 'Направляюча кріплення кришки опори розподільчого вала',
        group: 1140503,
        tooltip: {
            cz: 'Vedení pro upevnění víka vačkového hřídele',
            en: 'Guide for mounting the camshaft support cover',
            ua: 'Направляюча кріплення кришки опори розподільчого вала',
            bg: 'Направляващо закрепване на опорния капак на разпределителния вал'
        }
    },
    {
        unitId: 1169,
        systemId: 4,
        id: '9c885df9-11b6-4a0c-b8ba-4c455aebf6f6',
        name: '发动机215',
        location: '[0.1849517524242401, -0.013582619838416576, 0.1123918890953064]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl215',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 1170,
        systemId: 4,
        id: 'a5259b8e-27c3-4552-babb-42685b4f1c26',
        name: '发动机214',
        location: '[0.1849517673254013, 0.021737663075327873, 0.1123918890953064]',
        vertices: 698,
        polygons: 510,
        lod: ['LOD_3'],
        name_work: 'dtl214',
        name_ua: 'Болт кріплення кришок опор розподільчих валів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub upevnění krytů ložisek vačkových hřídelí',
            en: 'Bolts for mounting camshaft bearing caps',
            ua: 'Болт кріплення кришок опор розподільчих валів',
            bg: 'Болт за закрепване на капачките на лагерите на разпределителните валове'
        }
    },
    {
        unitId: 1171,
        systemId: 4,
        id: '3e070b70-55d9-45f8-a943-8d223dfe35dc',
        name: '发动机213',
        location: '[-0.006511924788355827, -0.04097488895058632, 0.053613416850566864]',
        vertices: 312527,
        polygons: 204021,
        lod: '["LOD_4", "LOD_3"]',
        name_work: 'dtl213',
        name_ua: 'Головка блоку циліндрів',
        group: 1140502,
        tooltip: {
            cz: 'Hlava bloku válců',
            en: 'Cylinder head',
            ua: 'Головка блоку циліндрів',
            bg: 'Главата на цилиндровия блок'
        }
    },
    {
        unitId: 1172,
        systemId: 4,
        id: 'bb0c65d0-2663-429b-89ad-aa56860b9ed7',
        name: '发动机212',
        location: '[0.00606394000351429, -0.03559068217873573, -1.3088980267639272e-05]',
        vertices: 7125,
        polygons: 5400,
        lod: ['LOD_3', 'LOD_2'],
        name_work: 'dtl212',
        name_ua: 'Прокладка ГБЦ',
        group: 1140603,
        tooltip: {
            cz: 'Těsnění hlavy válce',
            en: 'Cylinder head gasket',
            ua: 'Прокладка ГБЦ',
            bg: 'Гарнитура на цилиндровата глава'
        }
    },
    {
        unitId: 1173,
        systemId: 4,
        id: 'f581a0d0-516b-4901-8104-959b00111bbd',
        name: '发动机211',
        location: '[0.22457899153232574, -0.11204734444618225, -0.06570106744766235]',
        vertices: 705,
        polygons: 576,
        lod: ['LOD_4'],
        name_work: 'dtl211',
        name_ua: 'Ролик привідного ременя',
        group: 1040302,
        tooltip: {
            cz: 'Kladka pohonného řemene',
            en: 'Drive belt roller',
            ua: 'Ролик привідного ременя',
            bg: 'Ролка на задвижващия ремък'
        }
    },
    {
        unitId: 1174,
        systemId: 4,
        id: '1af69167-0023-43a4-b562-794a52be4897',
        name: '发动机210',
        location: '[0.2168256938457489, -0.11204734444618225, -0.06570106744766235]',
        vertices: 300,
        polygons: 200,
        lod: ['LOD_4'],
        name_work: 'dtl210',
        name_ua: 'Ролик привідного ременя',
        group: 1040302,
        tooltip: {
            cz: 'Kladka pohonného řemene',
            en: 'Drive belt roller',
            ua: 'Ролик привідного ременя',
            bg: 'Ролка на задвижващия ремък'
        }
    },
    {
        unitId: 1175,
        systemId: 4,
        id: 'f0dbaea7-0b9c-4299-a347-6f0351108348',
        name: '发动机209',
        location: '[0.2165672928094864, -0.1120472252368927, -0.06570106744766235]',
        vertices: 701,
        polygons: 480,
        lod: ['LOD_4'],
        name_work: 'dtl209',
        name_ua: 'Ролик привідного ременя',
        group: 1040302,
        tooltip: {
            cz: 'Kladka pohonného řemene',
            en: 'Drive belt roller',
            ua: 'Ролик привідного ременя',
            bg: 'Ролка на задвижващия ремък'
        }
    },
    {
        unitId: 1176,
        systemId: 4,
        id: '7f0211c0-7822-4626-8645-f08efbbad46e',
        name: '发动机208',
        location: '[-0.07788483798503876, 0.14544972777366638, -0.20993493497371674]',
        vertices: 4595,
        polygons: 3286,
        lod: ['LOD_4'],
        name_work: 'dtl208',
        name_ua: 'Корпус фільтра мастила ',
        group: 1020206,
        tooltip: {
            cz: 'Kryt olejového filtru',
            en: 'Oil filter housing',
            ua: 'Корпус фільтра мастила',
            bg: 'Корпус на масления филтър'
        }
    },
    {
        unitId: 1177,
        systemId: 4,
        id: 'f8478b99-e1c6-43bc-85a1-d54d733c174b',
        name: '发动机207',
        location: '[-0.07823212444782257, 0.14544972777366638, -0.2084418684244156]',
        vertices: 3478,
        polygons: 3078,
        lod: ['LOD_4'],
        name_work: 'dtl207',
        name_ua: 'Корпус фільтра мастила ',
        group: 1020206,
        tooltip: {
            cz: 'Kryt olejového filtru',
            en: 'Oil filter housing',
            ua: 'Корпус фільтра мастила',
            bg: 'Корпус на масления филтър'
        }
    },
    {
        unitId: 1178,
        systemId: 4,
        id: '6d475106-aaf7-4871-b649-4722a4a5122a',
        name: '发动机206',
        location: '[-0.06912720948457718, 0.11099204421043396, -0.21341286599636078]',
        vertices: 204,
        polygons: 128,
        lod: ['LOD_4'],
        name_work: 'dtl206',
        name_ua: 'Корпус фільтра мастила ',
        group: 1020206,
        tooltip: {
            cz: 'Kryt olejového filtru',
            en: 'Oil filter housing',
            ua: 'Корпус фільтра мастила',
            bg: 'Корпус на масления филтър'
        }
    },
    {
        unitId: 1179,
        systemId: 4,
        id: '1a96c6bf-eb04-46a7-a8e8-4b6a742bded4',
        name: '发动机205',
        location: '[-0.07830552011728287, 0.14545175433158875, -0.2094583958387375]',
        vertices: 1406,
        polygons: 1148,
        lod: ['LOD_3'],
        name_work: 'dtl205',
        name_ua: 'Внутрішня частинка корпусу місляного фільтра',
        group: 1020206,
        tooltip: {
            cz: 'Vnitřní část krytu olejového filtru',
            en: 'Inner part of the oil filter housing',
            ua: 'Внутрішня частинка корпусу місляного фільтра',
            bg: 'Вътрешната част на корпуса на филтъра'
        }
    },
    {
        unitId: 1180,
        systemId: 4,
        id: '42796e55-0093-49e8-b2d9-6db21863a3a4',
        name: '发动机204',
        location: '[-0.07823212444782257, 0.14544948935508728, -0.19147317111492157]',
        vertices: 3670,
        polygons: 3334,
        lod: ['LOD_4'],
        name_work: 'dtl204',
        name_ua: 'Корпус фільтра мастила ',
        group: 1020206,
        tooltip: {
            cz: 'Kryt olejového filtru',
            en: 'Oil filter housing',
            ua: 'Корпус фільтра мастила',
            bg: 'Корпус на масления филтър'
        }
    },
    {
        unitId: 1181,
        systemId: 4,
        id: '0841db7e-0c32-49b1-8f63-2cc773ce3e5b',
        name: '发动机203',
        location: '[-0.13703157007694244, 0.1596643626689911, -0.19531534612178802]',
        vertices: 1082,
        polygons: 912,
        lod: ['LOD_4'],
        name_work: 'dtl203',
        name_ua: 'Штуцер  масляного фільтра',
        group: 1020206,
        tooltip: {
            cz: 'Hrdlo olejového filtru',
            en: 'Oil filter nipple',
            ua: 'Штуцер  масляного фільтра',
            bg: 'Фитинг за маслен филтър'
        }
    },
    {
        unitId: 1182,
        systemId: 4,
        id: 'e78d20a2-ccd2-4bbe-a278-0505137b3151',
        name: '发动机202',
        location: '[-0.13703157007694244, 0.13123604655265808, -0.19531534612178802]',
        vertices: 1082,
        polygons: 912,
        lod: ['LOD_4'],
        name_work: 'dtl202',
        name_ua: 'Штуцер  масляного фільтра',
        group: 1020206,
        tooltip: {
            cz: 'Hrdlo olejového filtru',
            en: 'Oil filter nipple',
            ua: 'Штуцер  масляного фільтра',
            bg: 'Фитинг за маслен филтър'
        }
    },
    {
        unitId: 1183,
        systemId: 4,
        id: 'cc7d3b95-a56c-4440-8ef8-992241007f13',
        name: '发动机201',
        location: '[-0.07823212444782257, 0.14544972777366638, -0.17340798676013947]',
        vertices: 480,
        polygons: 352,
        lod: ['LOD_4'],
        name_work: 'dtl201',
        name_ua: 'Корпус фільтра мастила ',
        group: 1020206,
        tooltip: {
            cz: 'Kryt olejového filtru',
            en: 'Oil filter housing',
            ua: 'Корпус фільтра мастила',
            bg: 'Корпус на масления филтър'
        }
    },
    {
        unitId: 1184,
        systemId: 4,
        id: 'dcef257d-6899-49b0-8527-6cef8f18974f',
        name: '发动机200',
        location: '[-0.08098893612623215, 0.12822017073631287, -0.25580450892448425]',
        vertices: 700,
        polygons: 632,
        lod: ['LOD_4'],
        name_work: 'dtl200',
        name_ua: 'Масляний фільтр',
        group: 1020201,
        tooltip: { cz: 'Olejový filtr', en: 'Oil filter', ua: 'Масляний фільтр', bg: 'Маслен филтър' }
    },
    {
        unitId: 1185,
        systemId: 4,
        id: 'a1cbafc2-4aac-4870-99d4-cc597f8eb030',
        name: '发动机199',
        location: '[-0.08098893612623215, 0.12822017073631287, -0.2605126202106476]',
        vertices: 2318,
        polygons: 1982,
        lod: ['LOD_4'],
        name_work: 'dtl199',
        name_ua: 'Масляний фільтр',
        group: 1020201,
        tooltip: { cz: 'Olejový filtr', en: 'Oil filter', ua: 'Масляний фільтр', bg: 'Маслен филтър' }
    },
    {
        unitId: 1186,
        systemId: 4,
        id: '8349e8dd-b304-4157-a62d-200feb9cffc8',
        name: '发动机198',
        location: '[-0.08098893612623215, 0.12822017073631287, -0.2532730996608734]',
        vertices: 2308,
        polygons: 1824,
        lod: ['LOD_4'],
        name_work: 'dtl198',
        name_ua: 'Масляний фільтр',
        group: 1020201,
        tooltip: { cz: 'Olejový filtr', en: 'Oil filter', ua: 'Масляний фільтр', bg: 'Маслен филтър' }
    },
    {
        unitId: 1187,
        systemId: 4,
        id: 'c5399da3-5835-497c-b393-208d5e24770a',
        name: '发动机197',
        location: '[-0.08098893612623215, 0.12822017073631287, -0.2498430758714676]',
        vertices: 538,
        polygons: 480,
        lod: ['LOD_4'],
        name_work: 'dtl197',
        name_ua: 'Масляний фільтр',
        group: 1020201,
        tooltip: { cz: 'Olejový filtr', en: 'Oil filter', ua: 'Масляний фільтр', bg: 'Маслен филтър' }
    },
    {
        unitId: 1188,
        systemId: 4,
        id: 'fcb53b16-d730-4871-8f2e-a701d925ef53',
        name: '发动机196',
        location: '[-0.08098893612623215, 0.12822017073631287, -0.25068947672843933]',
        vertices: 3254,
        polygons: 2784,
        lod: ['LOD_4'],
        name_work: 'dtl196',
        name_ua: 'Масляний фільтр',
        group: 1020201,
        tooltip: { cz: 'Olejový filtr', en: 'Oil filter', ua: 'Масляний фільтр', bg: 'Маслен филтър' }
    },
    {
        unitId: 1189,
        systemId: 4,
        id: '1c400694-8449-419c-9033-7aecb808e908',
        name: '发动机195',
        location: '[-0.08098893612623215, 0.12822017073631287, -0.28889837861061096]',
        vertices: 4285,
        polygons: 3468,
        lod: ['LOD_4'],
        name_work: 'dtl195',
        name_ua: 'Масляний фільтр',
        group: 1020201,
        tooltip: { cz: 'Olejový filtr', en: 'Oil filter', ua: 'Масляний фільтр', bg: 'Маслен филтър' }
    },
    {
        unitId: 1190,
        systemId: 4,
        id: '80bb0999-2ac4-48a0-b705-fb63dc033d4a',
        name: '发动机194',
        location: '[-0.08046220242977142, 0.13398966193199158, -0.23069803416728973]',
        vertices: 13929,
        polygons: 8668,
        lod: ['LOD_4'],
        name_work: 'dtl194',
        name_ua: 'Кронштейн масляного фільтра',
        group: 1140302,
        tooltip: {
            cz: 'Držák olejového filtru',
            en: 'Oil filter bracket',
            ua: 'Кронштейн масляного фільтра',
            bg: 'Скоба за маслен филтър'
        }
    },
    {
        unitId: 1191,
        systemId: 4,
        id: '783198c2-94b9-450c-8253-ae813fb02c58',
        name: '发动机193',
        location: '[-0.080466628074646, 0.08712741732597351, -0.2306772619485855]',
        vertices: 928,
        polygons: 704,
        lod: ['LOD_3'],
        name_work: 'dtl193',
        name_ua: 'Прокладка головки блоку циліндрів',
        group: 1140601,
        tooltip: {
            cz: 'Těsnění hlavy válce',
            en: 'Head gasket',
            ua: 'Прокладка головки блоку циліндрів',
            bg: 'Гарнитура на главата на цилиндровия блок'
        }
    },
    {
        unitId: 1192,
        systemId: 4,
        id: 'bb2b3bfc-e105-45ad-bb17-f26296f864e5',
        name: '发动机192',
        location: '[-0.07823212444782257, 0.14544972777366638, -0.19348151981830597]',
        vertices: 949,
        polygons: 664,
        lod: ['LOD_4'],
        name_work: 'dtl192',
        name_ua: 'Болт',
        group: 1010203,
        tooltip: { cz: 'Šroub', en: 'Bolt', ua: 'Болт', bg: 'Болт' }
    },
    {
        unitId: 1193,
        systemId: 4,
        id: 'c2a677cc-dc2f-4f16-9f46-47dd1a685302',
        name: '发动机191',
        location: '[-0.12475113570690155, 0.08872172236442566, -0.2517812252044678]',
        vertices: 306,
        polygons: 204,
        lod: ['LOD_4'],
        name_work: 'dtl191',
        name_ua: 'Болт',
        group: 1141409,
        tooltip: { cz: 'Šroub', en: 'Bolt', ua: 'Болт', bg: 'Болт' }
    },
    {
        unitId: 1194,
        systemId: 4,
        id: 'fa55492d-c1aa-47c9-a9b3-315465a7bb16',
        name: '发动机190',
        location: '[-0.12604279816150665, 0.08872172236442566, -0.21043021976947784]',
        vertices: 306,
        polygons: 204,
        lod: ['LOD_4'],
        name_work: 'dtl190',
        name_ua: 'Болт',
        group: 1141409,
        tooltip: { cz: 'Šroub', en: 'Bolt', ua: 'Болт', bg: 'Болт' }
    },
    {
        unitId: 1195,
        systemId: 4,
        id: 'c9e2577c-a586-4fc1-b451-5e2afb0926d3',
        name: '发动机189',
        location: '[-0.034725189208984375, 0.0887208878993988, -0.20956872403621674]',
        vertices: 305,
        polygons: 204,
        lod: ['LOD_4'],
        name_work: 'dtl189',
        name_ua: 'Болт',
        group: 1141409,
        tooltip: { cz: 'Šroub', en: 'Bolt', ua: 'Болт', bg: 'Болт' }
    },
    {
        unitId: 1196,
        systemId: 4,
        id: '5182ca0b-0463-4101-b4d5-70351732f5c3',
        name: '发动机188',
        location: '[-0.03730972483754158, 0.0887208878993988, -0.2474738359451294]',
        vertices: 305,
        polygons: 204,
        lod: ['LOD_4'],
        name_work: 'dtl188',
        name_ua: 'Болт',
        group: 1141409,
        tooltip: { cz: 'Šroub', en: 'Bolt', ua: 'Болт', bg: 'Болт' }
    },
    {
        unitId: 1197,
        systemId: 4,
        id: '336b3202-7b6d-4479-91dd-6cfe5173e4c5',
        name: '发动机187',
        location: '[-0.13267166912555695, 0.12185534834861755, -0.2283736616373062]',
        vertices: 1471,
        polygons: 874,
        lod: ['LOD_4'],
        name_work: 'dtl187',
        name_ua: 'Датчик тиску мастила',
        group: 1180401,
        tooltip: {
            cz: 'Senzor tlaku oleje',
            en: 'Oil pressure sensor',
            ua: 'Датчик тиску мастила',
            bg: 'Датчик на налягането на маслото'
        }
    },
    {
        unitId: 1198,
        systemId: 4,
        id: '06300056-723c-4d18-ac0e-7c1989310a21',
        name: '发动机186',
        location: '[-0.24860398471355438, 0.06671032309532166, 0.04867612570524216]',
        vertices: 345,
        polygons: 288,
        lod: ['LOD_4'],
        name_work: 'dtl186',
        name_ua: 'Штуцер трубок змащування двигуна',
        group: 1141603,
        tooltip: {
            cz: 'Odtok oleje z motoru',
            en: 'Nipple for engine lubrication pipes',
            ua: 'Штуцер трубок змащування двигуна',
            bg: 'Нипел за тръбички за смазване на двигателя'
        }
    },
    {
        unitId: 1199,
        systemId: 4,
        id: '877e9520-3698-4278-9676-f7dce70f6609',
        name: '发动机185',
        location: '[-0.2158178836107254, 0.1662115752696991, 0.1078234314918518]',
        vertices: 345,
        polygons: 288,
        lod: ['LOD_4'],
        name_work: 'dtl185',
        name_ua: 'Штуцер трубок змащування двигуна',
        group: 1141603,
        tooltip: {
            cz: 'Odtok oleje z motoru',
            en: 'Nipple for engine lubrication pipes',
            ua: 'Штуцер трубок змащування двигуна',
            bg: 'Нипел за тръбички за смазване на двигателя'
        }
    },
    {
        unitId: 1200,
        systemId: 4,
        id: '3d79a79c-6946-4c16-849d-903ba06b3378',
        name: '发动机184',
        location: '[-0.24449129402637482, 0.06111093983054161, 0.07741677761077881]',
        vertices: 289,
        polygons: 236,
        lod: ['LOD_4'],
        name_work: 'dtl184',
        name_ua: 'Заглушка корпусу  дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Zátka tělesa škrticí klapky',
            en: 'Throttle valve body plug',
            ua: 'Заглушка корпусу  дросельної заслінки',
            bg: 'Запушалка на корпуса на дроселната клапа'
        }
    },
    {
        unitId: 1201,
        systemId: 4,
        id: 'c50e162d-3cee-433f-99f4-6b3f9a23288f',
        name: '发动机183',
        location: '[-0.2536184787750244, 0.17809948325157166, 0.00649108923971653]',
        vertices: 325,
        polygons: 238,
        lod: ['LOD_4'],
        name_work: 'dtl183',
        name_ua: 'Кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Upevnění pohonu škrticí klapky',
            en: 'Throttle valve drive mounting',
            ua: 'Кріплення привода дросельної заслінки',
            bg: 'Монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1202,
        systemId: 4,
        id: '8226bf82-bb22-4650-8900-dae63656b081',
        name: '发动机182',
        location: '[-0.23117463290691376, 0.17809948325157166, 0.04893391579389572]',
        vertices: 325,
        polygons: 238,
        lod: ['LOD_4'],
        name_work: 'dtl182',
        name_ua: 'Кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Upevnění pohonu škrticí klapky',
            en: 'Throttle valve drive mounting',
            ua: 'Кріплення привода дросельної заслінки',
            bg: 'Монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1203,
        systemId: 4,
        id: '6c6de09e-9ffe-46f9-b292-1869cf37c55d',
        name: '发动机181',
        location: '[-0.21261948347091675, 0.17809948325157166, 0.08241051435470581]',
        vertices: 325,
        polygons: 238,
        lod: ['LOD_4'],
        name_work: 'dtl181',
        name_ua: 'Кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Upevnění pohonu škrticí klapky',
            en: 'Throttle valve drive mounting',
            ua: 'Кріплення привода дросельної заслінки',
            bg: 'Монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1204,
        systemId: 4,
        id: '2b6ab5b9-3c7f-43d6-a3d0-c03f09dc6d44',
        name: '发动机180',
        location: '[-0.2986758351325989, 0.17809948325157166, 0.03146689385175705]',
        vertices: 325,
        polygons: 238,
        lod: ['LOD_4'],
        name_work: 'dtl180',
        name_ua: 'Кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Upevnění pohonu škrticí klapky',
            en: 'Throttle valve drive mounting',
            ua: 'Кріплення привода дросельної заслінки',
            bg: 'Монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1205,
        systemId: 4,
        id: '20bd19ff-60cf-4846-a20d-df487116d197',
        name: '发动机179',
        location: '[-0.2759748697280884, 0.17809948325157166, 0.07650566101074219]',
        vertices: 325,
        polygons: 238,
        lod: ['LOD_4'],
        name_work: 'dtl179',
        name_ua: 'Кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Upevnění pohonu škrticí klapky',
            en: 'Throttle valve drive mounting',
            ua: 'Кріплення привода дросельної заслінки',
            bg: 'Монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1206,
        systemId: 4,
        id: 'ab73e93a-88a1-4a0a-a669-1ad3a3e9078b',
        name: '发动机178',
        location: '[-0.25714874267578125, 0.17809948325157166, 0.10709404945373535]',
        vertices: 325,
        polygons: 238,
        lod: ['LOD_4'],
        name_work: 'dtl178',
        name_ua: 'Кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Upevnění pohonu škrticí klapky',
            en: 'Throttle valve drive mounting',
            ua: 'Кріплення привода дросельної заслінки',
            bg: 'Монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1207,
        systemId: 4,
        id: '26951c9e-a4ac-4bc2-afff-81f979aca15e',
        name: '发动机177',
        location: '[-0.2529580593109131, 0.17431768774986267, 0.10126274824142456]',
        vertices: 306,
        polygons: 166,
        lod: ['LOD_4'],
        name_work: 'dtl177',
        name_ua: 'Кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Upevnění pohonu škrticí klapky',
            en: 'Throttle valve drive mounting',
            ua: 'Кріплення привода дросельної заслінки',
            bg: 'Монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1208,
        systemId: 4,
        id: 'd0c73178-d2f6-446c-80fc-d303881fd83b',
        name: '发动机176',
        location: '[-0.25574707984924316, 0.18733534216880798, 0.05689787119626999]',
        vertices: 10584,
        polygons: 7254,
        lod: ['LOD_4'],
        name_work: 'dtl176',
        name_ua: 'Привод дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Pohon škrticí klapky',
            en: 'Throttle valve drive',
            ua: 'Привод дросельної заслінки',
            bg: 'Привод на дроселната клапа'
        }
    },
    {
        unitId: 1209,
        systemId: 4,
        id: '7a92d19a-078c-43aa-8eac-afb28e00cdd0',
        name: '发动机175',
        location: '[-0.21237412095069885, 0.06671056151390076, 0.1140393614768982]',
        vertices: 165,
        polygons: 92,
        lod: ['LOD_4'],
        name_work: 'dtl175',
        name_ua: 'Автозапчастина',
        group: 1000000,
        tooltip: { cz: 'Auto díl', en: 'Auto part', ua: 'Автозапчастина', bg: 'Авточасти' }
    },
    {
        unitId: 1210,
        systemId: 4,
        id: '564c144d-e236-47f3-a22f-ecfb9cf3dc0b',
        name: '发动机174',
        location: '[-0.258725643157959, 0.08393928408622742, 0.040989093482494354]',
        vertices: 619,
        polygons: 318,
        lod: ['LOD_3'],
        name_work: 'dtl174',
        name_ua: 'Болт кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Šroub pro upevnění pohonu škrticí klapky',
            en: 'Bolt for throttle valve drive mounting',
            ua: 'Болт кріплення привода дросельної заслінки',
            bg: 'Болт за монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1211,
        systemId: 4,
        id: 'e47f008a-babc-4b29-ba6d-722a2c0abc22',
        name: '发动机173',
        location: '[-0.2533383369445801, 0.1547541320323944, 0.05070900171995163]',
        vertices: 619,
        polygons: 318,
        lod: ['LOD_3'],
        name_work: 'dtl173',
        name_ua: 'Болт кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Šroub pro upevnění pohonu škrticí klapky',
            en: 'Bolt for throttle valve drive mounting',
            ua: 'Болт кріплення привода дросельної заслінки',
            bg: 'Болт за монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1212,
        systemId: 4,
        id: '65d7606b-0fcb-4afb-9bc6-ded0504c57ab',
        name: '发动机172',
        location: '[-0.22531235218048096, 0.08393928408622742, 0.10126686096191406]',
        vertices: 619,
        polygons: 318,
        lod: ['LOD_3'],
        name_work: 'dtl172',
        name_ua: 'Болт кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Šroub pro upevnění pohonu škrticí klapky',
            en: 'Bolt for throttle valve drive mounting',
            ua: 'Болт кріплення привода дросельної заслінки',
            bg: 'Болт за монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1213,
        systemId: 4,
        id: '8c12c1f9-4e95-4414-9265-7cdf14af77bb',
        name: '发动机171',
        location: '[-0.22531235218048096, 0.15199682116508484, 0.10126686096191406]',
        vertices: 619,
        polygons: 318,
        lod: ['LOD_3'],
        name_work: 'dtl171',
        name_ua: 'Болт кріплення привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Šroub pro upevnění pohonu škrticí klapky',
            en: 'Bolt for throttle valve drive mounting',
            ua: 'Болт кріплення привода дросельної заслінки',
            bg: 'Болт за монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1214,
        systemId: 4,
        id: '8b72c0d7-d5ed-40fd-9806-013777b8720c',
        name: '发动机170',
        location: '[-0.23803575336933136, -0.051746759563684464, 0.10932624340057373]',
        vertices: 13252,
        polygons: 8493,
        lod: ['LOD_4'],
        name_work: 'dtl170',
        name_ua: 'Вакуумний клапан впускного колектора',
        group: 1150702,
        tooltip: {
            cz: 'Vakuový ventil sacího svodu',
            en: 'Vacuum valve for intake manifold',
            ua: 'Вакуумний клапан впускного колектора',
            bg: 'Вакуумен клапан на всмукателния колектор'
        }
    },
    {
        unitId: 1215,
        systemId: 4,
        id: '9d66bf9a-3c56-4868-bbfc-ed17fe4f8a44',
        name: '发动机169',
        location: '[-0.2366567999124527, -0.09482136368751526, 0.0915154218673706]',
        vertices: 382,
        polygons: 270,
        lod: ['LOD_4'],
        name_work: 'dtl169',
        name_ua: 'Гайка кріплення вакуумних клапанів впускного колектора',
        group: 1150702,
        tooltip: {
            cz: 'Matice pro upevnění vakuových ventilů sacího svodu',
            en: 'Nut for mounting vacuum valves of the intake manifold',
            ua: 'Гайка кріплення вакуумних клапанів впускного колектора',
            bg: 'Гайката за закрепване на вакуумните клапани на всмукателния колектор'
        }
    },
    {
        unitId: 1216,
        systemId: 4,
        id: '7c0881b7-cee1-4dab-b0e4-33df1a50589c',
        name: '发动机168',
        location: '[-0.23665674030780792, -0.051746997982263565, 0.0915154218673706]',
        vertices: 382,
        polygons: 270,
        lod: ['LOD_4'],
        name_work: 'dtl168',
        name_ua: 'Гайка кріплення вакуумних клапанів впускного колектора',
        group: 1150702,
        tooltip: {
            cz: 'Matice pro upevnění vakuových ventilů sacího svodu',
            en: 'Nut for mounting vacuum valves of the intake manifold',
            ua: 'Гайка кріплення вакуумних клапанів впускного колектора',
            bg: 'Гайката за закрепване на вакуумните клапани на всмукателния колектор'
        }
    },
    {
        unitId: 1217,
        systemId: 4,
        id: 'c85a4442-8806-4288-86d2-c07b338d9934',
        name: '发动机167',
        location: '[-0.2336425930261612, -0.06036153808236122, 0.10211145877838135]',
        vertices: 2924,
        polygons: 2458,
        lod: ['LOD_4'],
        name_work: 'dtl167',
        name_ua: 'Плита кріплення вакуумних клапанів впускного колектора',
        group: 1150702,
        tooltip: {
            cz: 'Deska pro upevnění vakuových ventilů sacího svodu',
            en: 'Plate for mounting vacuum valves of the intake manifold',
            ua: 'Плита кріплення вакуумних клапанів впускного колектора',
            bg: 'Монтажна плоча на вакуумните клапани на всмукателния колектор'
        }
    },
    {
        unitId: 1218,
        systemId: 4,
        id: '5f1e4f1d-9db4-4659-955c-4d3721ed800b',
        name: '发动机166',
        location: '[-0.23803575336933136, -0.0948207676410675, 0.10973548889160156]',
        vertices: 13116,
        polygons: 8319,
        lod: ['LOD_4'],
        name_work: 'dtl166',
        name_ua: 'Вакуумний клапан впускного колектора',
        group: 1150702,
        tooltip: {
            cz: 'Vakuový ventil sacího svodu',
            en: 'Vacuum valve for intake manifold',
            ua: 'Вакуумний клапан впускного колектора',
            bg: 'Вакуумен клапан на всмукателния колектор'
        }
    },
    {
        unitId: 1219,
        systemId: 4,
        id: 'edba7a78-b196-4cfd-99fe-e94b9053fac5',
        name: '发动机165',
        location: '[-0.18139275908470154, 0.054477300494909286, 0.03509228676557541]',
        vertices: 950,
        polygons: 744,
        lod: ['LOD_2'],
        name_work: 'dtl165',
        name_ua: 'Ущільнення блоку циліндрів',
        group: 1140603,
        tooltip: {
            cz: 'Těsnění motoru',
            en: 'Sealing for the engine block',
            ua: 'Ущільнення блоку циліндрів',
            bg: 'Уплътнение на двигателя'
        }
    },
    {
        unitId: 1220,
        systemId: 4,
        id: '04869114-889d-4818-bf49-5c7fdbb6acc6',
        name: '发动机164',
        location: '[-0.11871987581253052, 0.05447682365775108, 0.05595969408750534]',
        vertices: 980,
        polygons: 900,
        lod: ['LOD_2'],
        name_work: 'dtl164',
        name_ua: 'Ущільнення вихревих заслінок',
        group: 1150702,
        tooltip: {
            cz: 'Těsnění vírových klapek',
            en: 'Sealing for swirl flaps',
            ua: 'Ущільнення вихревих заслінок',
            bg: 'Уплътнение на вихрови клапи'
        }
    },
    {
        unitId: 1221,
        systemId: 4,
        id: 'ebdfd873-6c7a-4865-8ad5-0ec07d2f7579',
        name: '发动机163',
        location: '[-0.04032387211918831, 0.05447682365775108, 0.05595969408750534]',
        vertices: 980,
        polygons: 900,
        lod: ['LOD_2'],
        name_work: 'dtl163',
        name_ua: 'Ущільнення вихревих заслінок',
        group: 1150702,
        tooltip: {
            cz: 'Těsnění vírových klapek',
            en: 'Sealing for swirl flaps',
            ua: 'Ущільнення вихревих заслінок',
            bg: 'Уплътнение на вихрови клапи'
        }
    },
    {
        unitId: 1222,
        systemId: 4,
        id: '1627ace6-0173-4a64-8009-aa8d18f1c4ad',
        name: '发动机162',
        location: '[0.04237670823931694, 0.05447682365775108, 0.05595969408750534]',
        vertices: 982,
        polygons: 900,
        lod: ['LOD_2'],
        name_work: 'dtl162',
        name_ua: 'Ущільнення вихревих заслінок',
        group: 1150702,
        tooltip: {
            cz: 'Těsnění vírových klapek',
            en: 'Sealing for swirl flaps',
            ua: 'Ущільнення вихревих заслінок',
            bg: 'Уплътнение на вихрови клапи'
        }
    },
    {
        unitId: 1223,
        systemId: 4,
        id: '823b579e-feea-42f7-b1aa-285b44f22167',
        name: '发动机161',
        location: '[0.12077108770608902, 0.05447682365775108, 0.05595969408750534]',
        vertices: 980,
        polygons: 900,
        lod: ['LOD_2'],
        name_work: 'dtl161',
        name_ua: 'Ущільнення вихревих заслінок',
        group: 1150702,
        tooltip: {
            cz: 'Těsnění vírových klapek',
            en: 'Sealing for swirl flaps',
            ua: 'Ущільнення вихревих заслінок',
            bg: 'Уплътнение на вихрови клапи'
        }
    },
    {
        unitId: 1224,
        systemId: 4,
        id: 'a2965a75-a7e6-48dd-a179-a0c559dd252f',
        name: '发动机160',
        location: '[-0.1688682585954666, 0.12164899706840515, -0.039352692663669586]',
        vertices: 2809,
        polygons: 2172,
        lod: ['LOD_4'],
        name_work: 'dtl160',
        name_ua: 'Вакуумний привід вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Vakuový pohon vírových klapek',
            en: 'Vacuum drive for swirl flaps',
            ua: 'Вакуумний привід вихревих заслінок',
            bg: 'Вакуумен привод на завихрители'
        }
    },
    {
        unitId: 1225,
        systemId: 4,
        id: 'cf64cd3e-15a4-4a4e-adc7-ecbcd7a2ecf1',
        name: '发动机159',
        location: '[-0.16886018216609955, 0.11153146624565125, -0.040066637098789215]',
        vertices: 6832,
        polygons: 4783,
        lod: ['LOD_4'],
        name_work: 'dtl159',
        name_ua: 'Вакуумний привід вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Vakuový pohon vírových klapek',
            en: 'Vacuum drive for swirl flaps',
            ua: 'Вакуумний привід вихревих заслінок',
            bg: 'Вакуумен привод на завихрители'
        }
    },
    {
        unitId: 1226,
        systemId: 4,
        id: 'fb247bb0-8d75-4b8a-aca0-50b326e06132',
        name: '发动机158',
        location: '[-0.1692439317703247, 0.14693209528923035, -0.024068808183073997]',
        vertices: 8026,
        polygons: 6028,
        lod: ['LOD_4'],
        name_work: 'dtl158',
        name_ua: 'Вакуумний привід вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Vakuový pohon vírových klapek',
            en: 'Vacuum drive for swirl flaps',
            ua: 'Вакуумний привід вихревих заслінок',
            bg: 'Вакуумен привод на завихрители'
        }
    },
    {
        unitId: 1227,
        systemId: 4,
        id: '69d3cadf-e629-4dbc-a572-db8d94329015',
        name: '发动机157',
        location: '[-0.1691102683544159, 0.11352977156639099, -0.04253388196229935]',
        vertices: 3583,
        polygons: 2678,
        lod: ['LOD_4'],
        name_work: 'dtl157',
        name_ua: 'Вакуумний привід вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Vakuový pohon vírových klapek',
            en: 'Vacuum drive for swirl flaps',
            ua: 'Вакуумний привід вихревих заслінок',
            bg: 'Вакуумен привод на завихрители'
        }
    },
    {
        unitId: 1228,
        systemId: 4,
        id: 'a2246625-9c8f-4324-88c6-77ea8654456a',
        name: '发动机156',
        location: '[-0.16879212856292725, 0.14849278330802917, -0.02410939894616604]',
        vertices: 6246,
        polygons: 3552,
        lod: ['LOD_3'],
        name_work: 'dtl156',
        name_ua: 'Вакуумний привід вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Vakuový pohon vírových klapek',
            en: 'Vacuum drive for swirl flaps',
            ua: 'Вакуумний привід вихревих заслінок',
            bg: 'Вакуумен привод на завихрители'
        }
    },
    {
        unitId: 1229,
        systemId: 4,
        id: '63a7b80d-feec-467f-bd4e-35fbcb0c8870',
        name: '发动机155',
        location: '[-0.15320710837841034, 0.16966411471366882, -0.0009004233870655298]',
        vertices: 2406,
        polygons: 1977,
        lod: ['LOD_3'],
        name_work: 'dtl155',
        name_ua: 'Важіль вакуумного привода вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Páka vakuového pohonu vírových klapek',
            en: 'Lever of the vacuum drive for swirl flaps',
            ua: 'Важіль вакуумного привода вихревих заслінок',
            bg: 'Лостът на вакуумното задвижване на вихровите клапи'
        }
    },
    {
        unitId: 1230,
        systemId: 4,
        id: '03b8756d-cb71-47a9-bfde-194fa984186b',
        name: '发动机154',
        location: '[-0.1659744381904602, 0.13396582007408142, -0.011575466953217983]',
        vertices: 1281,
        polygons: 774,
        lod: ['LOD_4'],
        name_work: 'dtl154',
        name_ua: 'Болти кріплення вакуумного привода вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Šrouby pro montáž vakuového pohonu výfukových klapek',
            en: 'Bolts for mounting the vacuum drive of the exhaust flaps',
            ua: 'Болти кріплення вакуумного привода вихревих заслінок',
            bg: 'Гайки за закрепване на вакуумното задвижване на вихровите клапани'
        }
    },
    {
        unitId: 1231,
        systemId: 4,
        id: '70ef682d-2521-4bbb-9ce1-b07357e193a4',
        name: '发动机153',
        location: '[-0.16235536336898804, 0.1682123839855194, 0.00952803436666727]',
        vertices: 1280,
        polygons: 774,
        lod: ['LOD_4'],
        name_work: 'dtl153',
        name_ua: 'Болти кріплення вакуумного привода вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Šrouby pro montáž vakuového pohonu výfukových klapek',
            en: 'Bolts for mounting the vacuum drive of the exhaust flaps',
            ua: 'Болти кріплення вакуумного привода вихревих заслінок',
            bg: 'Гайки за закрепване на вакуумното задвижване на вихровите клапани'
        }
    },
    {
        unitId: 1232,
        systemId: 4,
        id: '735dc693-bdf4-4806-98c2-49492e12bb59',
        name: '发动机152',
        location: '[-0.14047303795814514, 0.1623365581035614, 0.0010561587987467647]',
        vertices: 1081,
        polygons: 816,
        lod: ['LOD_3'],
        name_work: 'dtl152',
        name_ua: 'Втулка привода вихревих заслінок',
        group: 1110800,
        tooltip: {
            cz: 'Bush pohonu vírových klapek',
            en: 'Drive bush of the swirl dampers',
            ua: 'Втулка привода вихревих заслінок',
            bg: 'Втулка на привод на завихрителите'
        }
    },
    {
        unitId: 1233,
        systemId: 4,
        id: 'e62bc214-c90b-4d0b-8f6c-d9b3c0335362',
        name: '发动机151',
        location: '[-0.20877373218536377, 0.08393868803977966, 0.09209907054901123]',
        vertices: 522,
        polygons: 336,
        lod: ['LOD_4'],
        name_work: 'dtl151',
        name_ua: 'Різьба кріплення дросельної заслінки',
        group: 1140500,
        tooltip: {
            cz: 'Závit pro upevnění škrticí klapky',
            en: 'Throttle valve mounting thread',
            ua: 'Різьба кріплення дросельної заслінки',
            bg: 'Резба за монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1234,
        systemId: 4,
        id: '21083322-9281-4418-b3e9-a4cebf04acf4',
        name: '发动机150',
        location: '[-0.20877380669116974, 0.15199622511863708, 0.09209907054901123]',
        vertices: 522,
        polygons: 336,
        lod: ['LOD_4'],
        name_work: 'dtl150',
        name_ua: 'Різьба кріплення дросельної заслінки',
        group: 1140500,
        tooltip: {
            cz: 'Závit pro upevnění škrticí klapky',
            en: 'Throttle valve mounting thread',
            ua: 'Різьба кріплення дросельної заслінки',
            bg: 'Резба за монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1235,
        systemId: 4,
        id: 'eaf2ed94-2ef5-4d1e-96d7-a07a0798d585',
        name: '发动机149',
        location: '[-0.242187038064003, 0.08393868803977966, 0.0318216010928154]',
        vertices: 522,
        polygons: 336,
        lod: ['LOD_4'],
        name_work: 'dtl149',
        name_ua: 'Різьба кріплення дросельної заслінки',
        group: 1140500,
        tooltip: {
            cz: 'Závit pro upevnění škrticí klapky',
            en: 'Throttle valve mounting thread',
            ua: 'Різьба кріплення дросельної заслінки',
            bg: 'Резба за монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1236,
        systemId: 4,
        id: '741ca7fa-2ae8-4ac7-aa83-021a89ad551c',
        name: '发动机148',
        location: '[-0.23679809272289276, 0.15475353598594666, 0.0415412113070488]',
        vertices: 524,
        polygons: 336,
        lod: ['LOD_4'],
        name_work: 'dtl148',
        name_ua: 'Різьба кріплення дросельної заслінки',
        group: 1140500,
        tooltip: {
            cz: 'Závit pro upevnění škrticí klapky',
            en: 'Throttle valve mounting thread',
            ua: 'Різьба кріплення дросельної заслінки',
            bg: 'Резба за монтаж на дроселната клапа'
        }
    },
    {
        unitId: 1237,
        systemId: 4,
        id: 'a76061df-7b7c-422a-be35-bbf8ff9f5ed1',
        name: '发动机147',
        location: '[-0.20142243802547455, 0.13304385542869568, 0.09927284717559814]',
        vertices: 467,
        polygons: 336,
        lod: ['LOD_3'],
        name_work: 'dtl147',
        name_ua: 'Втулка',
        group: 1010208,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1238,
        systemId: 4,
        id: 'e5b27757-f54d-4036-bdc4-231c2b50846c',
        name: '发动机146',
        location: '[-0.19797180593013763, 0.06412455439567566, 0.007094228640198708]',
        vertices: 152,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl146',
        name_ua: 'Втулка',
        group: 1010208,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1239,
        systemId: 4,
        id: '9c523b44-942f-48c8-b0c4-b6a1c8696f6a',
        name: '发动机145',
        location: '[-0.18505020439624786, 0.06412455439567566, 0.0923810601234436]',
        vertices: 152,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl145',
        name_ua: 'Втулка',
        group: 1010208,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1240,
        systemId: 4,
        id: '57c3b228-f4ea-4059-b2aa-5b2184390efc',
        name: '发动机144',
        location: '[0.14145208895206451, 0.06412515044212341, 0.029492849484086037]',
        vertices: 152,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl144',
        name_ua: 'Втулка',
        group: 1010208,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1241,
        systemId: 4,
        id: '71a51867-a265-4244-84eb-b0e11244efd6',
        name: '发动机143',
        location: '[0.08114859461784363, 0.06412586569786072, 0.07429009675979614]',
        vertices: 152,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl143',
        name_ua: 'Втулка',
        group: 1010208,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1242,
        systemId: 4,
        id: '2d734d8a-b253-40d0-9540-82db6618431f',
        name: '发动机142',
        location: '[0.0010295793181285262, 0.06412598490715027, 0.03638457506895065]',
        vertices: 152,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl142',
        name_ua: 'Втулка',
        group: 1010208,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1243,
        systemId: 4,
        id: '32a07f2a-1b22-434a-8456-a0797ef08332',
        name: '发动机141',
        location: '[-0.07908792048692703, 0.06412526965141296, 0.03638457506895065]',
        vertices: 152,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl141',
        name_ua: 'Втулка',
        group: 1010208,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1244,
        systemId: 4,
        id: 'bff7254c-6837-4ca9-aa1d-c8a6543c800f',
        name: '发动机140',
        location: '[-0.07908792048692703, 0.06412526965141296, 0.07429009675979614]',
        vertices: 152,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl140',
        name_ua: 'Втулка',
        group: 1010208,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1245,
        systemId: 4,
        id: 'cc648fd1-85ff-40c7-bc60-3e0d1034d7f3',
        name: '发动机139',
        location: '[0.038072966039180756, 0.08006307482719421, -0.15917213261127472]',
        vertices: 152,
        polygons: 100,
        lod: ['LOD_2'],
        name_work: 'dtl139',
        name_ua: 'Втулка',
        group: 1010208,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1246,
        systemId: 4,
        id: 'dd415c3b-984e-4d69-8c68-ef13ff2c0289',
        name: '发动机138',
        location: '[-0.21540573239326477, 0.07973060011863708, 0.020577603951096535]',
        vertices: 9537,
        polygons: 6438,
        lod: ['LOD_4'],
        name_work: 'dtl138',
        name_ua: 'Клапан керування',
        group: 1150702,
        tooltip: { cz: 'Regulační ventil', en: 'Control valve', ua: 'Клапан керування', bg: 'Клапан за управление' }
    },
    {
        unitId: 1247,
        systemId: 4,
        id: '2a067a9f-0c01-44c7-b18f-3451081ab0b7',
        name: '发动机137',
        location: '[-0.2146136611700058, 0.09083506464958191, -0.03209174424409866]',
        vertices: 8917,
        polygons: 6284,
        lod: ['LOD_4'],
        name_work: 'dtl137',
        name_ua: 'Клапан керування',
        group: 1150702,
        tooltip: { cz: 'Regulační ventil', en: 'Control valve', ua: 'Клапан керування', bg: 'Клапан за управление' }
    },
    {
        unitId: 1248,
        systemId: 4,
        id: '407acf50-74f3-4699-9754-1f3d8e006661',
        name: '发动机136',
        location: '[-0.2146136611700058, 0.09070071578025818, -0.017598843201994896]',
        vertices: 4000,
        polygons: 3172,
        lod: ['LOD_4'],
        name_work: 'dtl136',
        name_ua: 'Клапан керування',
        group: 1150702,
        tooltip: { cz: 'Regulační ventil', en: 'Control valve', ua: 'Клапан керування', bg: 'Клапан за управление' }
    },
    {
        unitId: 1249,
        systemId: 4,
        id: 'ec126f73-e994-41a1-896d-c160545df855',
        name: '发动机135',
        location: '[-0.21314933896064758, 0.07532939314842224, 0.02395772375166416]',
        vertices: 6273,
        polygons: 3907,
        lod: ['LOD_4'],
        name_work: 'dtl135',
        name_ua: 'Клапан керування',
        group: 1150702,
        tooltip: { cz: 'Regulační ventil', en: 'Control valve', ua: 'Клапан керування', bg: 'Клапан за управление' }
    },
    {
        unitId: 1250,
        systemId: 4,
        id: '2894b72d-e5c4-4834-b9ca-05f88fc7e863',
        name: '发动机134',
        location: '[-0.21767151355743408, 0.10326001048088074, 0.013236992992460728]',
        vertices: 1290,
        polygons: 774,
        lod: ['LOD_4'],
        name_work: 'dtl134',
        name_ua: 'Саморіз',
        group: 1010501,
        tooltip: { cz: 'Samosvorný šroub', en: 'Self-tapping screw', ua: 'Саморіз', bg: 'Самонарезен' }
    },
    {
        unitId: 1251,
        systemId: 4,
        id: '501fc295-9b72-4057-b1fb-6f23cfb67196',
        name: '发动机133',
        location: '[-0.2081088274717331, 0.06709393858909607, 0.07082390785217285]',
        vertices: 1290,
        polygons: 774,
        lod: ['LOD_3'],
        name_work: 'dtl133',
        name_ua: 'Болт кріплення важіля вихрових клапанів',
        group: 1150703,
        tooltip: {
            cz: 'Šroub pro upevnění páky vírových ventilů',
            en: 'Bolt for mounting the lever of the swirl valves',
            ua: 'Болт кріплення важіля вихрових клапанів',
            bg: 'Болт за монтаж на лоста на завихрителите'
        }
    },
    {
        unitId: 1252,
        systemId: 4,
        id: '06324a44-257f-41ed-9604-06ce5640b088',
        name: '发动机132',
        location: '[0.0010241627460345626, 0.149154394865036, 0.11133354902267456]',
        vertices: 881,
        polygons: 716,
        lod: ['LOD_4'],
        name_work: 'dtl132',
        name_ua: 'Заглушка впускного колектора',
        group: 1110800,
        tooltip: {
            cz: 'Zátka sacího svodu',
            en: 'Cap of the intake manifold',
            ua: 'Заглушка впускного колектора',
            bg: 'Запушалка на всмукателния колектор'
        }
    },
    {
        unitId: 1253,
        systemId: 4,
        id: '8659f90e-565b-4595-8c0f-d8e5e25e11f7',
        name: '发动机131',
        location: '[-0.14973527193069458, 0.06653639674186707, 0.0875135064125061]',
        vertices: 3080,
        polygons: 2080,
        lod: ['LOD_3'],
        name_work: 'dtl131',
        name_ua: 'Кліпса кріплення',
        group: 1010701,
        tooltip: { cz: 'Upevňovací klips', en: 'Mounting clip', ua: 'Кліпса кріплення', bg: 'Клипса за монтаж' }
    },
    {
        unitId: 1254,
        systemId: 4,
        id: 'da71a5e6-fc31-4b84-8a70-953db03d0651',
        name: '发动机130',
        location: '[-0.1635189950466156, 0.11150762438774109, 0.08891350030899048]',
        vertices: 6503,
        polygons: 3972,
        lod: ['LOD_4'],
        name_work: 'dtl130',
        name_ua: 'Втулка  ',
        group: 1140512,
        tooltip: { cz: 'Bush', en: 'Bush', ua: 'Втулка', bg: 'Втулка' }
    },
    {
        unitId: 1255,
        systemId: 4,
        id: 'a269dd12-a0a1-4aaa-97be-883f6461cc99',
        name: '发动机129',
        location: '[-0.1260269284248352, 0.176678866147995, -0.014428979717195034]',
        vertices: 1273,
        polygons: 774,
        lod: ['LOD_4'],
        name_work: 'dtl129',
        name_ua: 'Саморіз',
        group: 1010501,
        tooltip: { cz: 'Samosvorný šroub', en: 'Self-tapping screw', ua: 'Саморіз', bg: 'Самонарезен' }
    },
    {
        unitId: 1256,
        systemId: 4,
        id: 'b0d82053-f1d3-434a-8283-aadae6b38205',
        name: '发动机128',
        location: '[-0.2274598628282547, 0.03498479351401329, 0.09363240003585815]',
        vertices: 635,
        polygons: 456,
        lod: ['LOD_4'],
        name_work: 'dtl128',
        name_ua: 'Термозахист вакуумних шлангів',
        group: 1190702,
        tooltip: {
            cz: 'Tepelná ochrana vakuových hadic',
            en: 'Thermal protection of vacuum hoses',
            ua: 'Термозахист вакуумних шлангів',
            bg: 'Термична защита на вакуумни маркучи'
        }
    },
    {
        unitId: 1257,
        systemId: 4,
        id: '08c50117-3f5f-4ff0-bce1-41a79accae03',
        name: '发动机127',
        location: '[-0.23086942732334137, 0.010819521732628345, 0.05103295296430588]',
        vertices: 3039,
        polygons: 2362,
        lod: ['LOD_4'],
        name_work: 'dtl127',
        name_ua: 'Шланг вакуумний',
        group: 1190702,
        tooltip: { cz: 'Hadicový vakuu', en: 'Vacuum hose', ua: 'Шланг вакуумний', bg: 'Вакуумен маркуч' }
    },
    {
        unitId: 1258,
        systemId: 4,
        id: 'c3db332b-6764-4277-af77-27c8555a81dc',
        name: '发动机126',
        location: '[-0.20889557898044586, 0.021455733105540276, 0.04018097370862961]',
        vertices: 2717,
        polygons: 2070,
        lod: ['LOD_4'],
        name_work: 'dtl126',
        name_ua: 'Шланг вакуумний',
        group: 1190702,
        tooltip: { cz: 'Hadicový vakuu', en: 'Vacuum hose', ua: 'Шланг вакуумний', bg: 'Вакуумен маркуч' }
    },
    {
        unitId: 1259,
        systemId: 4,
        id: 'fd70fba6-1eda-4bbc-8efa-338771f3ad37',
        name: '发动机125',
        location: '[-0.10051318258047104, 0.004012671299278736, 0.10411858558654785]',
        vertices: 4544,
        polygons: 3474,
        lod: ['LOD_4'],
        name_work: 'dtl125',
        name_ua: 'Шланг вакуумний',
        group: 1190702,
        tooltip: { cz: 'Hadicový vakuu', en: 'Vacuum hose', ua: 'Шланг вакуумний', bg: 'Вакуумен маркуч' }
    },
    {
        unitId: 1260,
        systemId: 4,
        id: '2b529fa3-a81b-4559-8522-c9cff591bb83',
        name: '发动机124',
        location: '[-0.20735397934913635, -0.08621791005134583, 0.12866567075252533]',
        vertices: 710,
        polygons: 528,
        lod: ['LOD_4'],
        name_work: 'dtl124',
        name_ua: 'Шланг вакуумний',
        group: 1190702,
        tooltip: { cz: 'Hadicový vakuu', en: 'Vacuum hose', ua: 'Шланг вакуумний', bg: 'Вакуумен маркуч' }
    },
    {
        unitId: 1261,
        systemId: 4,
        id: 'd10c0c01-c87b-488f-829c-f7f26942af23',
        name: '发动机123',
        location: '[-0.20735383033752441, -0.06034723296761513, 0.12866567075252533]',
        vertices: 683,
        polygons: 528,
        lod: ['LOD_4'],
        name_work: 'dtl123',
        name_ua: 'Шланг вакуумний',
        group: 1190702,
        tooltip: { cz: 'Hadicový vakuu', en: 'Vacuum hose', ua: 'Шланг вакуумний', bg: 'Вакуумен маркуч' }
    },
    {
        unitId: 1262,
        systemId: 4,
        id: 'dad7c91e-9621-47e1-9761-ee624d1c2eaa',
        name: '发动机122',
        location: '[-0.215767964720726, -0.008669289760291576, 0.12197118997573853]',
        vertices: 8522,
        polygons: 5732,
        lod: ['LOD_4'],
        name_work: 'dtl122',
        name_ua: 'Кріплення вакуумних трубок',
        group: 1011400,
        tooltip: {
            cz: 'Upevnění vakuových trubek',
            en: 'Mounting for vacuum tubes',
            ua: 'Кріплення вакуумних трубок',
            bg: 'Закрепване на вакуумни тръби'
        }
    },
    {
        unitId: 1263,
        systemId: 4,
        id: 'a0409759-2006-4125-8d96-d33e7cb5c0dd',
        name: '发动机121',
        location: '[-0.20302262902259827, -0.06466665863990784, 0.11707371473312378]',
        vertices: 1169,
        polygons: 782,
        lod: ['LOD_4'],
        name_work: 'dtl121',
        name_ua: 'Зʼєднувач вакуумних трубок',
        group: 1190702,
        tooltip: {
            cz: 'Spojka vakuových trubek',
            en: 'Connector for vacuum tubes',
            ua: 'Зʼєднувач вакуумних трубок',
            bg: 'Конектор за вакуумна тръба'
        }
    },
    {
        unitId: 1264,
        systemId: 4,
        id: '58aa031f-2872-450c-a68f-c5bea97fdb4e',
        name: '发动机120',
        location: '[0.14144757390022278, 0.059602465480566025, 0.029492611065506935]',
        vertices: 586,
        polygons: 344,
        lod: ['LOD_3'],
        name_work: 'dtl120',
        name_ua: 'Болт кріплення головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub pro upevnění hlavy válce',
            en: 'Bolt for mounting the cylinder head',
            ua: 'Болт кріплення головки блоку циліндрів',
            bg: 'Болт за монтаж на главата на цилиндрите'
        }
    },
    {
        unitId: 1265,
        systemId: 4,
        id: 'd3e96820-878c-427e-9492-4d5262061f8b',
        name: '发动机119',
        location: '[0.001025077304802835, 0.059602465480566025, 0.03638433665037155]',
        vertices: 586,
        polygons: 344,
        lod: ['LOD_3'],
        name_work: 'dtl119',
        name_ua: 'Болт кріплення головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub pro upevnění hlavy válce',
            en: 'Bolt for mounting the cylinder head',
            ua: 'Болт кріплення головки блоку циліндрів',
            bg: 'Болт за монтаж на главата на цилиндрите'
        }
    },
    {
        unitId: 1266,
        systemId: 4,
        id: '28ddd1b7-4c26-4964-8303-344debc0805e',
        name: '发动机118',
        location: '[0.038069915026426315, 0.07553872466087341, -0.15917246043682098]',
        vertices: 587,
        polygons: 344,
        lod: ['LOD_3'],
        name_work: 'dtl118',
        name_ua: 'Болт кріплення головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub pro upevnění hlavy válce',
            en: 'Bolt for mounting the cylinder head',
            ua: 'Болт кріплення головки блоку циліндрів',
            bg: 'Болт за монтаж на главата на цилиндрите'
        }
    },
    {
        unitId: 1267,
        systemId: 4,
        id: '54236b9c-eaba-4822-890e-5503f2975895',
        name: '发动机117',
        location: '[-0.0790908932685852, 0.05960186943411827, 0.03638433665037155]',
        vertices: 586,
        polygons: 344,
        lod: ['LOD_3'],
        name_work: 'dtl117',
        name_ua: 'Болт кріплення головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub pro upevnění hlavy válce',
            en: 'Bolt for mounting the cylinder head',
            ua: 'Болт кріплення головки блоку циліндрів',
            bg: 'Болт за монтаж на главата на цилиндрите'
        }
    },
    {
        unitId: 1268,
        systemId: 4,
        id: '3fc1e058-9cd5-47ad-8458-f8306cba48e5',
        name: '发动机116',
        location: '[-0.0790908932685852, 0.059602465480566025, 0.07428991794586182]',
        vertices: 586,
        polygons: 344,
        lod: ['LOD_3'],
        name_work: 'dtl116',
        name_ua: 'Болт кріплення головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub pro upevnění hlavy válce',
            en: 'Bolt for mounting the cylinder head',
            ua: 'Болт кріплення головки блоку циліндрів',
            bg: 'Болт за монтаж на главата на цилиндрите'
        }
    },
    {
        unitId: 1269,
        systemId: 4,
        id: 'e31a9394-5601-495c-8d15-a8d6740b9380',
        name: '发动机115',
        location: '[0.08114409446716309, 0.059602465480566025, 0.07428991794586182]',
        vertices: 586,
        polygons: 344,
        lod: ['LOD_3'],
        name_work: 'dtl115',
        name_ua: 'Болт кріплення головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub pro upevnění hlavy válce',
            en: 'Bolt for mounting the cylinder head',
            ua: 'Болт кріплення головки блоку циліндрів',
            bg: 'Болт за монтаж на главата на цилиндрите'
        }
    },
    {
        unitId: 1270,
        systemId: 4,
        id: 'f96258ae-e950-41db-867e-1ca7f1828f40',
        name: '发动机114',
        location: '[-0.1850547045469284, 0.059602703899145126, 0.09238117933273315]',
        vertices: 586,
        polygons: 344,
        lod: ['LOD_3'],
        name_work: 'dtl114',
        name_ua: 'Болт кріплення головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub pro upevnění hlavy válce',
            en: 'Bolt for mounting the cylinder head',
            ua: 'Болт кріплення головки блоку циліндрів',
            bg: 'Болт за монтаж на главата на цилиндрите'
        }
    },
    {
        unitId: 1271,
        systemId: 4,
        id: '61d1e735-7e96-421a-bb7f-6f6a08041243',
        name: '发动机113',
        location: '[-0.19797630608081818, 0.059602703899145126, 0.007094258442521095]',
        vertices: 586,
        polygons: 344,
        lod: ['LOD_3'],
        name_work: 'dtl113',
        name_ua: 'Болт кріплення головки блоку циліндрів',
        group: 1140503,
        tooltip: {
            cz: 'Šroub pro upevnění hlavy válce',
            en: 'Bolt for mounting the cylinder head',
            ua: 'Болт кріплення головки блоку циліндрів',
            bg: 'Болт за монтаж на главата на цилиндрите'
        }
    },
    {
        unitId: 1272,
        systemId: 4,
        id: '65043139-850e-4a3e-9c6b-a9df44832b6b',
        name: '发动机112',
        location: '[-0.2560441493988037, -0.11204829812049866, 0.0813971757888794]',
        vertices: 1204,
        polygons: 780,
        lod: ['LOD_4'],
        name_work: 'dtl112',
        name_ua: 'Болт кріплення',
        group: 1150702,
        tooltip: { cz: 'Upevňovací šroub', en: 'Mounting bolt', ua: 'Болт кріплення', bg: 'Монтажен болт' }
    },
    {
        unitId: 1273,
        systemId: 4,
        id: '4e51e0cc-6336-46e2-97a7-385292e83725',
        name: '发动机111',
        location: '[-0.21124543249607086, -0.11204829812049866, 0.0813971757888794]',
        vertices: 1204,
        polygons: 780,
        lod: ['LOD_4'],
        name_work: 'dtl111',
        name_ua: 'Болт кріплення',
        group: 1150702,
        tooltip: { cz: 'Upevňovací šroub', en: 'Mounting bolt', ua: 'Болт кріплення', bg: 'Монтажен болт' }
    },
    {
        unitId: 1274,
        systemId: 4,
        id: 'ae89172d-cc93-48bf-aa83-cf463618f0e6',
        name: '发动机110',
        location: '[-0.2560442090034485, -0.03451469913125038, 0.0813971757888794]',
        vertices: 1204,
        polygons: 780,
        lod: ['LOD_4'],
        name_work: 'dtl110',
        name_ua: 'Болт кріплення',
        group: 1150702,
        tooltip: { cz: 'Upevňovací šroub', en: 'Mounting bolt', ua: 'Болт кріплення', bg: 'Монтажен болт' }
    },
    {
        unitId: 1275,
        systemId: 4,
        id: 'f866996c-ffe2-4c8c-bc18-d2fb8d627095',
        name: '发动机109',
        location: '[-0.21124538779258728, -0.03451469913125038, 0.0813971757888794]',
        vertices: 1204,
        polygons: 780,
        lod: ['LOD_4'],
        name_work: 'dtl109',
        name_ua: 'Болт кріплення',
        group: 1150702,
        tooltip: { cz: 'Upevňovací šroub', en: 'Mounting bolt', ua: 'Болт кріплення', bg: 'Монтажен болт' }
    },
    {
        unitId: 1276,
        systemId: 4,
        id: '40d04d63-1771-4bd2-842a-5c1b6afb55a3',
        name: '发动机108',
        location: '[-0.21232716739177704, 0.03506144508719444, 0.17301808297634125]',
        vertices: 1066,
        polygons: 788,
        lod: ['LOD_4'],
        name_work: 'dtl108',
        name_ua: 'Трубка картерних газів',
        group: 1141100,
        tooltip: {
            cz: 'Trubka odvětrání karty',
            en: 'Crankcase ventilation tube',
            ua: 'Трубка картерних газів',
            bg: 'Тръба за картерни газове'
        }
    },
    {
        unitId: 1277,
        systemId: 4,
        id: '27efa9ee-9a9c-42ac-b9d2-6e6c231994d1',
        name: '发动机107',
        location: '[-0.21050511300563812, -0.02949550934135914, 0.16708789765834808]',
        vertices: 2107,
        polygons: 1556,
        lod: ['LOD_4'],
        name_work: 'dtl107',
        name_ua: 'Трубка картерних газів',
        group: 1141100,
        tooltip: {
            cz: 'Trubka odvětrání karty',
            en: 'Crankcase ventilation tube',
            ua: 'Трубка картерних газів',
            bg: 'Тръба за картерни газове'
        }
    },
    {
        unitId: 1278,
        systemId: 4,
        id: 'decc2765-f33f-4979-948a-d10ee6665db7',
        name: '发动机106',
        location: '[-0.1518959105014801, -0.1619960367679596, 0.15870289504528046]',
        vertices: 818,
        polygons: 586,
        lod: ['LOD_4'],
        name_work: 'dtl106',
        name_ua: 'Трубка картерних газів',
        group: 1141100,
        tooltip: {
            cz: 'Trubka odvětrání karty',
            en: 'Crankcase ventilation tube',
            ua: 'Трубка картерних газів',
            bg: 'Тръба за картерни газове'
        }
    },
    {
        unitId: 1279,
        systemId: 4,
        id: '0451e66a-4bf3-483e-9152-427eeed46791',
        name: '发动机105',
        location: '[-0.26908600330352783, 0.1115085780620575, 0.14831958711147308]',
        vertices: 842,
        polygons: 480,
        lod: ['LOD_4'],
        name_work: 'dtl105',
        name_ua: 'Трубка картерних газів',
        group: 1141100,
        tooltip: {
            cz: 'Trubka odvětrání karty',
            en: 'Crankcase ventilation tube',
            ua: 'Трубка картерних газів',
            bg: 'Тръба за картерни газове'
        }
    },
    {
        unitId: 1280,
        systemId: 4,
        id: 'adb50df7-bc9c-4b1d-9bf3-01bdd790e59d',
        name: '发动机104',
        location: '[-0.19244278967380524, -0.044851575046777725, 0.15802572667598724]',
        vertices: 2513,
        polygons: 1898,
        lod: ['LOD_4'],
        name_work: 'dtl104',
        name_ua: 'Кріплення трубки картерних газів',
        group: 1141100,
        tooltip: {
            cz: 'Upevnění trubky odvětrání karty',
            en: 'Mounting for crankcase ventilation tube',
            ua: 'Кріплення трубки картерних газів',
            bg: 'Закрепване на тръбата за картерни газове'
        }
    },
    {
        unitId: 1281,
        systemId: 4,
        id: '19366157-bec1-4ee7-a3fe-bcf53f24c2c9',
        name: '发动机103',
        location: '[-0.10381707549095154, -0.06458058953285217, 0.18348686397075653]',
        vertices: 1482,
        polygons: 988,
        lod: ['LOD_4'],
        name_work: 'dtl103',
        name_ua: 'Кліпса кріплення декоративної кришки',
        group: 1141001,
        tooltip: {
            cz: 'Spona pro upevnění dekorativního krytu',
            en: 'Clip for mounting decorative cover',
            ua: 'Кліпса кріплення декоративної кришки',
            bg: 'Клипса за монтаж на декоративна капачка'
        }
    },
    {
        unitId: 1282,
        systemId: 4,
        id: '5cce8ea8-1464-4829-a652-1ee9d214cf60',
        name: '发动机102',
        location: '[0.06158874183893204, -0.06458070874214172, 0.18348686397075653]',
        vertices: 1489,
        polygons: 988,
        lod: ['LOD_4'],
        name_work: 'dtl102',
        name_ua: 'Кліпса кріплення декоративної кришки',
        group: 1141001,
        tooltip: {
            cz: 'Spona pro upevnění dekorativního krytu',
            en: 'Clip for mounting decorative cover',
            ua: 'Кліпса кріплення декоративної кришки',
            bg: 'Клипса за монтаж на декоративна капачка'
        }
    },
    {
        unitId: 1283,
        systemId: 4,
        id: '51554f5f-adc5-4e9a-9345-f84a78a9c9b5',
        name: '发动机101',
        location: '[0.09734053164720535, 0.14243373274803162, 0.14458684623241425]',
        vertices: 2363,
        polygons: 1529,
        lod: ['LOD_4'],
        name_work: 'dtl101',
        name_ua: 'Кріплення кліпси кріплення декоративної кришки',
        group: 1141001,
        tooltip: {
            cz: 'Upevnění spony dekorativního krytu',
            en: 'Mounting clip for decorative cover',
            ua: 'Кріплення кліпси кріплення декоративної кришки',
            bg: 'Монтаж за клипс на декоративната капачка'
        }
    },
    {
        unitId: 1284,
        systemId: 4,
        id: '233a39b9-b239-4fdf-b7c2-deb4f876de7f',
        name: '发动机100',
        location: '[-0.10106110572814941, 0.14760443568229675, 0.1477745622396469]',
        vertices: 2363,
        polygons: 1529,
        lod: ['LOD_4'],
        name_work: 'dtl100',
        name_ua: 'Кріплення кліпси кріплення декоративної кришки',
        group: 1141001,
        tooltip: {
            cz: 'Upevnění spony dekorativního krytu',
            en: 'Mounting clip for decorative cover',
            ua: 'Кріплення кліпси кріплення декоративної кришки',
            bg: 'Монтаж за клипс на декоративната капачка'
        }
    },
    {
        unitId: 1285,
        systemId: 4,
        id: 'c86828b8-7cf7-4923-bf7b-be5d62174f00',
        name: '发动机99',
        location: '[-0.10104294866323471, 0.15086588263511658, 0.16060768067836761]',
        vertices: 1477,
        polygons: 861,
        lod: ['LOD_4'],
        name_work: 'dtl99',
        name_ua: 'Болт кріплення кліпси кріплення декоративної кришки',
        group: 1141001,
        tooltip: {
            cz: 'Šroub upevnění spony dekorativního krytu',
            en: 'Bolt for mounting clip of decorative cover',
            ua: 'Болт кріплення кліпси кріплення декоративної кришки',
            bg: 'Болт за монтаж на клипса на декоративната капачка'
        }
    },
    {
        unitId: 1286,
        systemId: 4,
        id: 'd85ff2f1-dd2b-478c-ac38-47eb308d852d',
        name: '发动机98',
        location: '[0.09735868871212006, 0.14569661021232605, 0.15741996467113495]',
        vertices: 1484,
        polygons: 861,
        lod: ['LOD_4'],
        name_work: 'dtl98',
        name_ua: 'Болт кріплення кліпси кріплення декоративної кришки',
        group: 1141001,
        tooltip: {
            cz: 'Šroub upevnění spony dekorativního krytu',
            en: 'Bolt for mounting clip of decorative cover',
            ua: 'Болт кріплення кліпси кріплення декоративної кришки',
            bg: 'Болт за монтаж на клипса на декоративната капачка'
        }
    },
    {
        unitId: 1287,
        systemId: 4,
        id: '2c5de1cc-47d2-466a-b21e-6bee630f4361',
        name: '发动机97',
        location: '[-0.10106080025434494, 0.15091487765312195, 0.14927874505519867]',
        vertices: 375,
        polygons: 256,
        lod: ['LOD_4'],
        name_work: 'dtl97',
        name_ua: 'Кліпса кріплення декоративної кришки',
        group: 1141001,
        tooltip: {
            cz: 'Spona pro upevnění dekorativního krytu',
            en: 'Clip for mounting decorative cover',
            ua: 'Кліпса кріплення декоративної кришки',
            bg: 'Клипса за монтаж на декоративна капачка'
        }
    },
    {
        unitId: 1288,
        systemId: 4,
        id: 'df109ef2-a1dc-4a58-836d-76563e7ac21c',
        name: '发动机96',
        location: '[0.09733930975198746, 0.14574536681175232, 0.14609168469905853]',
        vertices: 375,
        polygons: 256,
        lod: ['LOD_4'],
        name_work: 'dtl96',
        name_ua: 'Кліпса кріплення декоративної кришки',
        group: 1141001,
        tooltip: {
            cz: 'Spona pro upevnění dekorativního krytu',
            en: 'Clip for mounting decorative cover',
            ua: 'Кліпса кріплення декоративної кришки',
            bg: 'Клипса за монтаж на декоративна капачка'
        }
    },
    {
        unitId: 1289,
        systemId: 4,
        id: 'fef469e9-0866-4e10-847a-dfd0f88505dc',
        name: '发动机95',
        location: '[0.06158870458602905, -0.06458142399787903, 0.19554375112056732]',
        vertices: 4692,
        polygons: 3311,
        lod: ['LOD_4'],
        name_work: 'dtl95',
        name_ua: 'кліпси кожуха',
        group: 1141001,
        tooltip: { cz: 'Spony krytu', en: 'Cover clips', ua: 'Кліпси кожуха', bg: 'Клипове за капак' }
    },
    {
        unitId: 1290,
        systemId: 4,
        id: '0d2e7eec-7a28-4dd2-9c10-04564f71e9d2',
        name: '发动机94',
        location: '[-0.10381707549095154, -0.06458070874214172, 0.19554375112056732]',
        vertices: 4692,
        polygons: 3311,
        lod: ['LOD_4'],
        name_work: 'dtl94',
        name_ua: 'кліпси кожуха',
        group: 1141001,
        tooltip: { cz: 'Spony krytu', en: 'Cover clips', ua: 'Кліпси кожуха', bg: 'Клипове за капак' }
    },
    {
        unitId: 1291,
        systemId: 4,
        id: '266f4bfd-5775-433f-aa40-fa88c739155a',
        name: '发动机93',
        location: '[0.17074766755104065, 0.12891551852226257, -0.1517922431230545]',
        vertices: 5783,
        polygons: 4180,
        lod: ['LOD_3'],
        name_work: 'dtl93',
        name_ua: 'Муфта кондиціонера',
        group: 1121000,
        tooltip: {
            cz: 'Spojka klimatizace',
            en: 'Air conditioning coupling',
            ua: 'Муфта кондиціонера',
            bg: 'Муфта на климатика'
        }
    },
    {
        unitId: 1292,
        systemId: 4,
        id: 'afe668f7-2c91-4d1c-a25a-711acc56e0e4',
        name: '发动机92',
        location: '[0.14443814754486084, 0.12575861811637878, -0.16859550774097443]',
        vertices: 20443,
        polygons: 12797,
        lod: ['LOD_4'],
        name_work: 'dtl92',
        name_ua: 'Компрессор кондиціонера',
        group: 1120900,
        tooltip: {
            cz: 'Kompresor klimatizace',
            en: 'Air conditioning compressor',
            ua: 'Компрессор кондиціонера',
            bg: 'Компресор на климатика'
        }
    },
    {
        unitId: 1293,
        systemId: 4,
        id: '7784ef47-063d-4a5f-b109-137820bd5d5f',
        name: '发动机91',
        location: '[0.15213041007518768, 0.14379224181175232, -0.2220497876405716]',
        vertices: 451,
        polygons: 258,
        lod: ['LOD_2'],
        name_work: 'dtl91',
        name_ua: 'Болти кріплення компрессора кондиціонера',
        group: 1120900,
        tooltip: {
            cz: 'Šrouby upevnění kompresoru klimatizace',
            en: 'Bolts for mounting air conditioning compressor',
            ua: 'Болти кріплення компрессора кондиціонера',
            bg: 'Болтове за монтаж на компресора на климатика'
        }
    },
    {
        unitId: 1294,
        systemId: 4,
        id: 'ac0dfbf1-8b66-493b-b0ec-5c482c5f0af9',
        name: '发动机90',
        location: '[0.15213041007518768, 0.09894129633903503, -0.12131333351135254]',
        vertices: 451,
        polygons: 258,
        lod: ['LOD_2'],
        name_work: 'dtl90',
        name_ua: 'Болти кріплення компрессора кондиціонера',
        group: 1120900,
        tooltip: {
            cz: 'Šrouby upevnění kompresoru klimatizace',
            en: 'Bolts for mounting air conditioning compressor',
            ua: 'Болти кріплення компрессора кондиціонера',
            bg: 'Болтове за монтаж на компресора на климатика'
        }
    },
    {
        unitId: 1295,
        systemId: 4,
        id: '01d2e1d8-18e2-4c82-961a-ec9768d06af5',
        name: '发动机89',
        location: '[0.09294722229242325, 0.14379224181175232, -0.22204996645450592]',
        vertices: 450,
        polygons: 258,
        lod: ['LOD_2'],
        name_work: 'dtl89',
        name_ua: 'Болти кріплення компрессора кондиціонера',
        group: 1120900,
        tooltip: {
            cz: 'Šrouby upevnění kompresoru klimatizace',
            en: 'Bolts for mounting air conditioning compressor',
            ua: 'Болти кріплення компрессора кондиціонера',
            bg: 'Болтове за монтаж на компресора на климатика'
        }
    },
    {
        unitId: 1296,
        systemId: 4,
        id: '6e0839a9-78c3-4b22-bd46-b6e88de0fd43',
        name: '发动机88',
        location: '[0.08278150856494904, 0.09631320834159851, -0.11541083455085754]',
        vertices: 450,
        polygons: 258,
        lod: ['LOD_2'],
        name_work: 'dtl88',
        name_ua: 'Болти кріплення компрессора кондиціонера',
        group: 1120900,
        tooltip: {
            cz: 'Šrouby upevnění kompresoru klimatizace',
            en: 'Bolts for mounting air conditioning compressor',
            ua: 'Болти кріплення компрессора кондиціонера',
            bg: 'Болтове за монтаж на компресора на климатика'
        }
    },
    {
        unitId: 1297,
        systemId: 4,
        id: '39f3dfc7-2672-48d8-923e-e5cd4f4c4563',
        name: '发动机87',
        location: '[-0.08176148682832718, -0.04494193568825722, -0.19947122037410736]',
        vertices: 379,
        polygons: 252,
        lod: ['LOD_2'],
        name_work: 'dtl87',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1298,
        systemId: 4,
        id: '54c869eb-9a0d-4dac-9142-2e5feced81fb',
        name: '发动机86',
        location: '[-0.1644635796546936, -0.04494098201394081, -0.19947122037410736]',
        vertices: 379,
        polygons: 252,
        lod: ['LOD_2'],
        name_work: 'dtl86',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1299,
        systemId: 4,
        id: '2afdf807-dc20-4ede-a9b5-8f89958f4aa1',
        name: '发动机85',
        location: '[0.0009406397584825754, -0.04494193568825722, -0.19947122037410736]',
        vertices: 381,
        polygons: 252,
        lod: ['LOD_2'],
        name_work: 'dtl85',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1300,
        systemId: 4,
        id: 'b57ef84d-b229-408a-9c21-5ac3197b634a',
        name: '发动机84',
        location: '[0.08364277333021164, -0.04494122043251991, -0.19947122037410736]',
        vertices: 379,
        polygons: 252,
        lod: ['LOD_2'],
        name_work: 'dtl84',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1301,
        systemId: 4,
        id: 'a18ec7fb-7243-4c6a-9635-2d6f54c96112',
        name: '发动机83',
        location: '[0.16634489595890045, -0.04494193568825722, -0.19947122037410736]',
        vertices: 379,
        polygons: 252,
        lod: ['LOD_2'],
        name_work: 'dtl83',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1302,
        systemId: 4,
        id: 'db719542-9cb7-4478-8010-6b2080c7738c',
        name: '发动机82',
        location: '[-0.08176308870315552, -0.044943127781152725, -0.17491890490055084]',
        vertices: 955,
        polygons: 584,
        lod: ['LOD_3'],
        name_work: 'dtl82',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1303,
        systemId: 4,
        id: '8fd193de-3044-4d40-bcfa-9d148bbe6e87',
        name: '发动机81',
        location: '[-0.16446362435817719, -0.04494229331612587, -0.17491890490055084]',
        vertices: 955,
        polygons: 584,
        lod: ['LOD_3'],
        name_work: 'dtl81',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1304,
        systemId: 4,
        id: '418c16bb-d207-4f6b-877c-42810a4cde9b',
        name: '发动机80',
        location: '[0.08364273607730865, -0.044943127781152725, -0.17491890490055084]',
        vertices: 955,
        polygons: 584,
        lod: ['LOD_3'],
        name_work: 'dtl80',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1305,
        systemId: 4,
        id: '51aa7f9d-37b0-4916-8d23-68250af6cab8',
        name: '发动机79',
        location: '[0.16634485125541687, -0.044943008571863174, -0.17491890490055084]',
        vertices: 955,
        polygons: 584,
        lod: ['LOD_3'],
        name_work: 'dtl79',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1306,
        systemId: 4,
        id: '51ac4c33-3526-47f3-a823-a362db57a7b7',
        name: '发动机78',
        location: '[0.0009385610464960337, -0.044941339641809464, -0.17039604485034943]',
        vertices: 2497,
        polygons: 1524,
        lod: ['LOD_3'],
        name_work: 'dtl78',
        name_ua: 'Вкладиш корінний',
        group: 1140202,
        tooltip: { cz: 'Hlavní ložisko', en: 'Main bearing', ua: 'Вкладиш корінний', bg: 'Главен вложка' }
    },
    {
        unitId: 1307,
        systemId: 4,
        id: '3fc7ab40-ae88-46d1-9b41-eab508f7b945',
        name: '发动机77',
        location: '[0.1995980590581894, 0.09040030837059021, 0.004423672333359718]',
        vertices: 400,
        polygons: 260,
        lod: ['LOD_4'],
        name_work: 'dtl77',
        name_ua: 'Болт кріплення ролику',
        group: 1040308,
        tooltip: {
            cz: 'Šroub upevnění válce',
            en: 'Bolt for mounting the roller',
            ua: 'Болт кріплення ролику',
            bg: 'Болт за монтаж на ролката'
        }
    },
    {
        unitId: 1308,
        systemId: 4,
        id: '932e9489-3c97-42fd-a807-a7848ff97102',
        name: '发动机76',
        location: '[0.15910704433918, 0.08609184622764587, 0.0867815613746643]',
        vertices: 400,
        polygons: 260,
        lod: ['LOD_4'],
        name_work: 'dtl76',
        name_ua: 'Болт кріплення ролику',
        group: 1040308,
        tooltip: {
            cz: 'Šroub upevnění válce',
            en: 'Bolt for mounting the roller',
            ua: 'Болт кріплення ролику',
            bg: 'Болт за монтаж на ролката'
        }
    },
    {
        unitId: 1309,
        systemId: 4,
        id: 'bd49d98b-ea92-4fa4-8d24-24118d838b9a',
        name: '发动机75',
        location: '[0.1995980590581894, 0.09040030837059021, 0.10022056102752686]',
        vertices: 400,
        polygons: 260,
        lod: ['LOD_4'],
        name_work: 'dtl75',
        name_ua: 'Болт кріплення ролику',
        group: 1040308,
        tooltip: {
            cz: 'Šroub upevnění válce',
            en: 'Bolt for mounting the roller',
            ua: 'Болт кріплення ролику',
            bg: 'Болт за монтаж на ролката'
        }
    },
    {
        unitId: 1310,
        systemId: 4,
        id: '00d162a4-ccb7-419e-b675-8f4b556f8981',
        name: '发动机74',
        location: '[0.22759412229061127, 0.11624583601951599, 0.06265991926193237]',
        vertices: 439,
        polygons: 384,
        lod: ['LOD_4'],
        name_work: 'dtl74',
        name_ua: 'Нерухомий ролик привідного ременя',
        group: 1040306,
        tooltip: {
            cz: 'Pevná kladka pohonného řemene',
            en: 'Fixed roller for the drive belt',
            ua: 'Нерухомий ролик привідного ременя',
            bg: 'Неподвижна ролка на задвижващия ремък'
        }
    },
    {
        unitId: 1311,
        systemId: 4,
        id: 'b819e704-b6bb-45b9-a6fd-0bce05e3e1ee',
        name: '发动机73',
        location: '[0.21855147182941437, 0.11624488234519958, 0.06265991926193237]',
        vertices: 649,
        polygons: 576,
        lod: ['LOD_4'],
        name_work: 'dtl73',
        name_ua: 'Нерухомий ролик привідного ременя',
        group: 1040306,
        tooltip: {
            cz: 'Pevná kladka pohonného řemene',
            en: 'Fixed roller for the drive belt',
            ua: 'Нерухомий ролик привідного ременя',
            bg: 'Неподвижна ролка на задвижващия ремък'
        }
    },
    {
        unitId: 1312,
        systemId: 4,
        id: '71b3f026-3db3-45c4-a1d7-a2ea9ecf6432',
        name: '发动机72',
        location: '[0.23061154782772064, 0.11624476313591003, 0.06265991926193237]',
        vertices: 651,
        polygons: 576,
        lod: ['LOD_4'],
        name_work: 'dtl72',
        name_ua: 'Нерухомий ролик привідного ременя',
        group: 1040306,
        tooltip: {
            cz: 'Pevná kladka pohonného řemene',
            en: 'Fixed roller for the drive belt',
            ua: 'Нерухомий ролик привідного ременя',
            bg: 'Неподвижна ролка на задвижващия ремък'
        }
    },
    {
        unitId: 1313,
        systemId: 4,
        id: '925a4733-2804-4074-b8c9-9700acff017f',
        name: '发动机71',
        location: '[0.18216215074062347, 0.11150762438774109, 0.05232065171003342]',
        vertices: 4970,
        polygons: 3771,
        lod: ['LOD_4'],
        name_work: 'dtl71',
        name_ua: 'Нерухомий ролик привідного ременя',
        group: 1040306,
        tooltip: {
            cz: 'Pevná kladka pohonného řemene',
            en: 'Fixed roller for the drive belt',
            ua: 'Нерухомий ролик привідного ременя',
            bg: 'Неподвижна ролка на задвижващия ремък'
        }
    },
    {
        unitId: 1314,
        systemId: 4,
        id: '5695bad8-a73b-45f2-9df1-e4da83c2fe1b',
        name: '发动机70',
        location: '[0.1591062694787979, 0.0929858386516571, 0.08678120374679565]',
        vertices: 314,
        polygons: 240,
        lod: ['LOD_4'],
        name_work: 'dtl70',
        name_ua: 'Втулка головки блоку циліндрів',
        group: 1140512,
        tooltip: {
            cz: 'Bush hlavy válce',
            en: 'Cylinder head bushing',
            ua: 'Втулка головки блоку циліндрів',
            bg: 'Втулка на цилиндровата глава'
        }
    },
    {
        unitId: 1315,
        systemId: 4,
        id: '5abf3040-c977-40a9-9c1b-a91ca050849f',
        name: '发动机69',
        location: '[0.22457914054393768, -0.18387731909751892, -0.14412690699100494]',
        vertices: 5128,
        polygons: 3982,
        lod: ['LOD_4'],
        name_work: 'dtl69',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1316,
        systemId: 4,
        id: '40393556-fb54-46c6-ae0a-f799963bfe02',
        name: '发动机68',
        location: '[0.20977887511253357, -0.17251667380332947, -0.15787850320339203]',
        vertices: 8384,
        polygons: 5672,
        lod: ['LOD_4'],
        name_work: 'dtl68',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1317,
        systemId: 4,
        id: '78c639d3-15fb-4a82-9bf6-0df484827657',
        name: '发动机67',
        location: '[0.19366911053657532, -0.167298823595047, -0.15634910762310028]',
        vertices: 2224,
        polygons: 1583,
        lod: ['LOD_4'],
        name_work: 'dtl67',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1318,
        systemId: 4,
        id: 'c59589e1-a8af-462a-91c9-b01507254907',
        name: '发动机66',
        location: '[0.19366911053657532, -0.16733035445213318, -0.17605935037136078]',
        vertices: 2585,
        polygons: 1773,
        lod: ['LOD_4'],
        name_work: 'dtl66',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1319,
        systemId: 4,
        id: '89315ddf-4eec-455a-8941-9eeea755f3df',
        name: '发动机65',
        location: '[0.19409970939159393, -0.1665431559085846, -0.15457047522068024]',
        vertices: 2229,
        polygons: 1573,
        lod: ['LOD_4'],
        name_work: 'dtl65',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1320,
        systemId: 4,
        id: '63781561-1f18-4727-bba6-50822f2db6da',
        name: '发动机64',
        location: '[0.19409970939159393, -0.16722753643989563, -0.17856208980083466]',
        vertices: 2042,
        polygons: 1496,
        lod: ['LOD_4'],
        name_work: 'dtl64',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1321,
        systemId: 4,
        id: '847ff303-da25-4aaa-b1f6-67a595c90656',
        name: '发动机63',
        location: '[0.17665830254554749, -0.1682160198688507, -0.16468559205532074]',
        vertices: 502,
        polygons: 424,
        lod: ['LOD_4'],
        name_work: 'dtl63',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1322,
        systemId: 4,
        id: '4e8165d1-980b-4c5e-a80a-c9a15e6d5040',
        name: '发动机62',
        location: '[0.18333494663238525, -0.16821613907814026, -0.16461144387722015]',
        vertices: 412,
        polygons: 311,
        lod: ['LOD_4'],
        name_work: 'dtl62',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1323,
        systemId: 4,
        id: '4506ad6c-8d46-44cd-970e-3b0fd7e44948',
        name: '发动机61',
        location: '[0.15017026662826538, -0.16821691393852234, -0.16461144387722015]',
        vertices: 402,
        polygons: 311,
        lod: ['LOD_4'],
        name_work: 'dtl61',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1324,
        systemId: 4,
        id: 'd7f8b86e-4a7f-49c0-a6d7-bbeb490f3f87',
        name: '发动机60',
        location: '[0.1426296830177307, -0.16821590065956116, -0.16468559205532074]',
        vertices: 347,
        polygons: 356,
        lod: ['LOD_4'],
        name_work: 'dtl60',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1325,
        systemId: 4,
        id: 'e1be37c5-842e-4db8-81e1-c046487d0257',
        name: '发动机59',
        location: '[0.17334149777889252, -0.1689693033695221, -0.1650809496641159]',
        vertices: 7030,
        polygons: 4720,
        lod: ['LOD_4'],
        name_work: 'dtl59',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1326,
        systemId: 4,
        id: 'e527477c-f044-4f9c-b7b5-4eafa84e53af',
        name: '发动机58',
        location: '[0.1729632467031479, -0.1676258146762848, -0.16516976058483124]',
        vertices: 4198,
        polygons: 3188,
        lod: ['LOD_4'],
        name_work: 'dtl58',
        name_ua: 'Натяжний ролик привідного ременя',
        group: 1040303,
        tooltip: {
            cz: 'Napínací kladka pohonného řemene',
            en: 'Belt tensioner pulley',
            ua: 'Натяжний ролик привідного ременя',
            bg: 'Верижен ролков обтегач'
        }
    },
    {
        unitId: 1327,
        systemId: 4,
        id: '620590a8-e3e5-41e8-ae3d-3c01430bb38d',
        name: '发动机57',
        location: '[0.1892605423927307, -0.143320232629776, -0.19492365419864655]',
        vertices: 394,
        polygons: 260,
        lod: ['LOD_4'],
        name_work: 'dtl57',
        name_ua: 'Болт кріплення ролику',
        group: 1040308,
        tooltip: {
            cz: 'Šroub upevnění válce',
            en: 'Bolt for mounting the roller',
            ua: 'Болт кріплення ролику',
            bg: 'Болт за монтаж на ролката'
        }
    },
    {
        unitId: 1328,
        systemId: 4,
        id: '731ed655-7007-4ebb-83fa-f2552c598cbd',
        name: '发动机56',
        location: '[0.1892605423927307, -0.143320232629776, -0.13522286713123322]',
        vertices: 394,
        polygons: 260,
        lod: ['LOD_4'],
        name_work: 'dtl56',
        name_ua: 'Болт кріплення ролику',
        group: 1040308,
        tooltip: {
            cz: 'Šroub upevnění válce',
            en: 'Bolt for mounting the roller',
            ua: 'Болт кріплення ролику',
            bg: 'Болт за монтаж на ролката'
        }
    },
    {
        unitId: 1329,
        systemId: 4,
        id: 'a460c136-bdbd-4075-9b52-b2ee829abbc5',
        name: '发动机55',
        location: '[0.09868208318948746, -0.19604167342185974, -0.04672382026910782]',
        vertices: 6830,
        polygons: 5205,
        lod: ['LOD_4'],
        name_work: 'dtl55',
        name_ua: 'Кожух генератора',
        group: 1170200,
        tooltip: { cz: 'Kryt generátoru', en: 'Generator cover', ua: 'Кожух генератора', bg: 'Кожух на генератора' }
    },
    {
        unitId: 1330,
        systemId: 4,
        id: '9726a8bd-6ef9-4c27-8e06-77b40edf4d84',
        name: '发动机54',
        location: '[0.15730589628219604, -0.19857653975486755, -0.04769761115312576]',
        vertices: 11927,
        polygons: 8500,
        lod: ['LOD_4'],
        name_work: 'dtl54',
        name_ua: 'Генератор',
        group: 1170100,
        tooltip: { cz: 'Generátor', en: 'Generator', ua: 'Генератор', bg: 'Генератор' }
    },
    {
        unitId: 1331,
        systemId: 4,
        id: '515e484d-0e93-4cf8-b9f7-5051b3c1357d',
        name: '发动机53',
        location: '[0.1774563491344452, -0.17838189005851746, 0.0277742687612772]',
        vertices: 414,
        polygons: 270,
        lod: ['LOD_4'],
        name_work: 'dtl53',
        name_ua: 'Болт кріплення генератора',
        group: 1170400,
        tooltip: {
            cz: 'Šroub upevnění generátoru',
            en: 'Bolt for mounting the generator',
            ua: 'Болт кріплення генератора',
            bg: 'Болт за монтаж на генератора'
        }
    },
    {
        unitId: 1332,
        systemId: 4,
        id: 'f47f8106-af73-4119-bca8-aa7fdf097518',
        name: '发动机52',
        location: '[0.1821957230567932, -0.17838093638420105, -0.12298539280891418]',
        vertices: 418,
        polygons: 270,
        lod: ['LOD_4'],
        name_work: 'dtl52',
        name_ua: 'Болт кріплення генератора',
        group: 1170400,
        tooltip: {
            cz: 'Šroub upevnění generátoru',
            en: 'Bolt for mounting the generator',
            ua: 'Болт кріплення генератора',
            bg: 'Болт за монтаж на генератора'
        }
    },
    {
        unitId: 1333,
        systemId: 4,
        id: '73496841-2288-4330-affc-1dc62fc2fc97',
        name: '发动机51',
        location: '[0.12112150341272354, -0.21714821457862854, -0.12746842205524445]',
        vertices: 412,
        polygons: 276,
        lod: ['LOD_4'],
        name_work: 'dtl51',
        name_ua: 'Кріплення генератора',
        group: 1170200,
        tooltip: {
            cz: 'Upevnění generátoru',
            en: 'Generator mounting',
            ua: 'Кріплення генератора',
            bg: 'Монтаж на генератора'
        }
    },
    {
        unitId: 1334,
        systemId: 4,
        id: '3397e2e5-f8aa-4242-8863-22c5b09885da',
        name: '发动机50',
        location: '[0.11835829168558121, -0.17975983023643494, -0.12298539280891418]',
        vertices: 437,
        polygons: 270,
        lod: ['LOD_4'],
        name_work: 'dtl50',
        name_ua: 'Болт кріплення генератора',
        group: 1170400,
        tooltip: {
            cz: 'Šroub upevnění generátoru',
            en: 'Bolt for mounting the generator',
            ua: 'Болт кріплення генератора',
            bg: 'Болт за монтаж на генератора'
        }
    },
    {
        unitId: 1335,
        systemId: 4,
        id: '1b363dd1-e07a-41ba-afed-1652c88bda59',
        name: '发动机49',
        location: '[0.1636340171098709, -0.234226256608963, -0.013127989135682583]',
        vertices: 945,
        polygons: 628,
        lod: ['LOD_4'],
        name_work: 'dtl49',
        name_ua: 'Кріплення кожуху  генератора',
        group: 1170200,
        tooltip: {
            cz: 'Upevnění krytu generátoru',
            en: 'Cover mounting for the generator',
            ua: 'Кріплення кожуху  генератора',
            bg: 'Монтаж на кожуха на генератора'
        }
    },
    {
        unitId: 1336,
        systemId: 4,
        id: '745f9729-ba0d-42c0-a91e-7259e79d0a44',
        name: '发动机48',
        location: '[0.18327970802783966, -0.258716344833374, -0.033276744186878204]',
        vertices: 366,
        polygons: 232,
        lod: ['LOD_4'],
        name_work: 'dtl48',
        name_ua: 'Кріплення генератора',
        group: 1170200,
        tooltip: {
            cz: 'Upevnění generátoru',
            en: 'Generator mounting',
            ua: 'Кріплення генератора',
            bg: 'Монтаж на генератора'
        }
    },
    {
        unitId: 1337,
        systemId: 4,
        id: 'ede557fd-6905-4e4e-9b46-ea70f67f82b9',
        name: '发动机47',
        location: '[0.14064055681228638, -0.269450306892395, -0.007965541444718838]',
        vertices: 343,
        polygons: 236,
        lod: ['LOD_4'],
        name_work: 'dtl47',
        name_ua: 'Кріплення генератора',
        group: 1170200,
        tooltip: {
            cz: 'Upevnění generátoru',
            en: 'Generator mounting',
            ua: 'Кріплення генератора',
            bg: 'Монтаж на генератора'
        }
    },
    {
        unitId: 1338,
        systemId: 4,
        id: 'a8ab069e-5e0b-45c3-b92b-04ee31b65184',
        name: '发动机46',
        location: '[0.15901029109954834, -0.2764627933502197, -0.034138865768909454]',
        vertices: 342,
        polygons: 236,
        lod: ['LOD_4'],
        name_work: 'dtl46',
        name_ua: 'Кріплення генератора',
        group: 1170200,
        tooltip: {
            cz: 'Upevnění generátoru',
            en: 'Generator mounting',
            ua: 'Кріплення генератора',
            bg: 'Монтаж на генератора'
        }
    },
    {
        unitId: 1339,
        systemId: 4,
        id: 'f0431ffd-1803-4422-a597-41334c43d492',
        name: '发动机45',
        location: '[0.15901029109954834, -0.2792510390281677, -0.033391810953617096]',
        vertices: 320,
        polygons: 174,
        lod: ['LOD_4'],
        name_work: 'dtl45',
        name_ua: 'Гайка кріплення кожуху вентиляції генератора',
        group: 1170200,
        tooltip: {
            cz: 'Matice upevnění krytu ventilace generátoru',
            en: 'Nut for mounting the ventilation cover of the generator',
            ua: 'Гайка кріплення кожуху вентиляції генератора',
            bg: 'Гайката за закрепване на вентилационния капак на генератора'
        }
    },
    {
        unitId: 1340,
        systemId: 4,
        id: 'eec7bf99-8d85-4774-8024-a1a95fc1945b',
        name: '发动机44',
        location: '[0.14064055681228638, -0.27223795652389526, -0.007218396291136742]',
        vertices: 321,
        polygons: 174,
        lod: ['LOD_4'],
        name_work: 'dtl44',
        name_ua: 'Гайка кріплення кожуху вентиляції генератора',
        group: 1170200,
        tooltip: {
            cz: 'Matice upevnění krytu ventilace generátoru',
            en: 'Nut for mounting the ventilation cover of the generator',
            ua: 'Гайка кріплення кожуху вентиляції генератора',
            bg: 'Гайката за закрепване на вентилационния капак на генератора'
        }
    },
    {
        unitId: 1341,
        systemId: 4,
        id: '252eb104-ef2e-4052-b674-a079de95051b',
        name: '发动机43',
        location: '[0.12444120645523071, -0.24310454726219177, -0.08131778240203857]',
        vertices: 3901,
        polygons: 2415,
        lod: ['LOD_4'],
        name_work: 'dtl43',
        name_ua: 'Короб вентиляції генератора',
        group: 1170200,
        tooltip: {
            cz: 'Skříň ventilace generátoru',
            en: 'Generator ventilation box',
            ua: 'Короб вентиляції генератора',
            bg: 'Кутия за вентилация на генератора'
        }
    },
    {
        unitId: 1342,
        systemId: 4,
        id: 'e55a10d2-093d-4ca6-a39c-9782ecb22c21',
        name: '发动机42',
        location: '[0.19809797406196594, -0.201212078332901, 0.014497661031782627]',
        vertices: 461,
        polygons: 304,
        lod: ['LOD_4'],
        name_work: 'dtl42',
        name_ua: 'Болт кріплення генератора',
        group: 1170400,
        tooltip: {
            cz: 'Šroub upevnění generátoru',
            en: 'Bolt for mounting the generator',
            ua: 'Болт кріплення генератора',
            bg: 'Болт за монтаж на генератора'
        }
    },
    {
        unitId: 1343,
        systemId: 4,
        id: '709270d8-faf6-46cd-85c4-ce9358f6447b',
        name: '发动机41',
        location: '[0.18638212978839874, -0.25871741771698, -0.03327668458223343]',
        vertices: 461,
        polygons: 304,
        lod: ['LOD_4'],
        name_work: 'dtl41',
        name_ua: 'Болт кріплення генератора',
        group: 1170400,
        tooltip: {
            cz: 'Šroub upevnění generátoru',
            en: 'Bolt for mounting the generator',
            ua: 'Болт кріплення генератора',
            bg: 'Болт за монтаж на генератора'
        }
    },
    {
        unitId: 1344,
        systemId: 4,
        id: 'f5c20990-89f4-42f4-81b0-8d9afa08bccf',
        name: '发动机40',
        location: '[-0.16699768602848053, 0.10194525122642517, -0.18030937016010284]',
        vertices: 414,
        polygons: 224,
        lod: ['LOD_4'],
        name_work: 'dtl40',
        name_ua: 'Болт кріплення стартера',
        group: 1170700,
        tooltip: {
            cz: 'Šroub upevnění startéru',
            en: 'Bolt for mounting the starter',
            ua: 'Болт кріплення стартера',
            bg: 'Болт за монтаж на стартера'
        }
    },
    {
        unitId: 1345,
        systemId: 4,
        id: '4afee9e1-40c4-496f-a69d-2c1257670267',
        name: '发动机39',
        location: '[-0.16696488857269287, 0.07386264204978943, -0.10240530967712402]',
        vertices: 445,
        polygons: 248,
        lod: ['LOD_4'],
        name_work: 'dtl39',
        name_ua: 'Болт кріплення стартера',
        group: 1170700,
        tooltip: {
            cz: 'Šroub upevnění startéru',
            en: 'Bolt for mounting the starter',
            ua: 'Болт кріплення стартера',
            bg: 'Болт за монтаж на стартера'
        }
    },
    {
        unitId: 1346,
        systemId: 4,
        id: '96ac6329-4c90-40d3-8687-2d3845e5a1d4',
        name: '发动机38',
        location: '[-0.14297257363796234, 0.10394081473350525, -0.140106663107872]',
        vertices: 10201,
        polygons: 7026,
        lod: ['LOD_4'],
        name_work: 'dtl38',
        name_ua: 'Стартер',
        group: 1170700,
        tooltip: { cz: 'Startér', en: 'Starter', ua: 'Стартер', bg: 'Стартер' }
    },
    {
        unitId: 1347,
        systemId: 4,
        id: 'e37e2430-d4ad-4273-8632-bcdf376e4b46',
        name: '发动机37',
        location: '[-0.08058411628007889, 0.1494644582271576, -0.0971587598323822]',
        vertices: 268,
        polygons: 154,
        lod: ['LOD_4'],
        name_work: 'dtl37',
        name_ua: 'Гайка кріплення стартера',
        group: 1170700,
        tooltip: {
            cz: 'Matice upevnění startéru',
            en: 'Starter mounting nut',
            ua: 'Гайка кріплення стартера',
            bg: 'Гайка за закрепване на стартера'
        }
    },
    {
        unitId: 1348,
        systemId: 4,
        id: '3f86455f-914f-49ab-af01-1cd35f6857ae',
        name: '发动机36',
        location: '[0.1154809445142746, -0.25997400283813477, -0.06113792210817337]',
        vertices: 291,
        polygons: 154,
        lod: ['LOD_4'],
        name_work: 'dtl36',
        name_ua: 'Гайка кріплення стартера',
        group: 1170700,
        tooltip: {
            cz: 'Matice upevnění startéru',
            en: 'Starter mounting nut',
            ua: 'Гайка кріплення стартера',
            bg: 'Гайка за закрепване на стартера'
        }
    },
    {
        unitId: 1349,
        systemId: 4,
        id: '7c0501d4-7643-4af1-a436-a8b892fc2a5b',
        name: '发动机35',
        location: '[0.042355727404356, 0.06352612376213074, 0.054433755576610565]',
        vertices: 4227,
        polygons: 3054,
        lod: ['LOD_3'],
        name_work: 'dtl35',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1350,
        systemId: 4,
        id: 'e42eb33d-949b-42f3-8aaa-08dd0cc11da4',
        name: '发动机34',
        location: '[0.1473049819469452, 0.06627902388572693, 0.06050627678632736]',
        vertices: 987,
        polygons: 728,
        lod: ['LOD_3'],
        name_work: 'dtl34',
        name_ua: 'Заглушка впускного колектора',
        group: 1140509,
        tooltip: {
            cz: 'Zátka sacího svodu',
            en: 'Cap of the intake manifold',
            ua: 'Заглушка впускного колектора',
            bg: 'Запушалка на всмукателния колектор'
        }
    },
    {
        unitId: 1351,
        systemId: 4,
        id: '2a6a6efc-4dbd-4746-b26e-d7b85cabfae6',
        name: '发动机33',
        location: '[0.12075163424015045, 0.06353244185447693, 0.05443137139081955]',
        vertices: 4227,
        polygons: 3054,
        lod: ['LOD_3'],
        name_work: 'dtl33',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1352,
        systemId: 4,
        id: '18f0d8ac-4d76-4ae2-8ed4-d7b98f2ea915',
        name: '发动机32',
        location: '[0.12077230960130692, 0.06342503428459167, 0.05909942835569382]',
        vertices: 3424,
        polygons: 2465,
        lod: ['LOD_3'],
        name_work: 'dtl32',
        name_ua: 'Вал вихревих заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel škrtící klapky',
            en: 'Throttle valve shaft',
            ua: 'Вал вихревих заслінок',
            bg: 'Вал на клапаните'
        }
    },
    {
        unitId: 1353,
        systemId: 4,
        id: 'c0db8f49-9826-4e4d-b7b4-5fbb9443d51a',
        name: '发动机31',
        location: '[0.0423763245344162, 0.06341871619224548, 0.05910169333219528]',
        vertices: 3441,
        polygons: 2465,
        lod: ['LOD_3'],
        name_work: 'dtl31',
        name_ua: 'Вал вихрових клапанів',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel vírových ventilů',
            en: 'Swirl valve shaft',
            ua: 'Вал вихрових клапанів',
            bg: 'Вал на спирателните клапани'
        }
    },
    {
        unitId: 1354,
        systemId: 4,
        id: 'a199d3d2-3c20-471d-aa86-592fa0ef615c',
        name: '发动机30',
        location: '[-0.04032577946782112, 0.06341072916984558, 0.0591040775179863]',
        vertices: 3424,
        polygons: 2465,
        lod: ['LOD_3'],
        name_work: 'dtl30',
        name_ua: 'Вал вихрових клапанів',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel vírových ventilů',
            en: 'Swirl valve shaft',
            ua: 'Вал вихрових клапанів',
            bg: 'Вал на спирателните клапани'
        }
    },
    {
        unitId: 1355,
        systemId: 4,
        id: '33782b00-6933-4636-9448-41d603876970',
        name: '发动机29',
        location: '[-0.11872178316116333, 0.06340464949607849, 0.05910664051771164]',
        vertices: 3424,
        polygons: 2465,
        lod: ['LOD_3'],
        name_work: 'dtl29',
        name_ua: 'Вал вихрових клапанів',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel vírových ventilů',
            en: 'Swirl valve shaft',
            ua: 'Вал вихрових клапанів',
            bg: 'Вал на спирателните клапани'
        }
    },
    {
        unitId: 1356,
        systemId: 4,
        id: 'd863ae33-05e6-4271-8889-822449e5a612',
        name: '发动机28',
        location: '[-0.21207062900066376, 0.05816718563437462, 0.05593358725309372]',
        vertices: 1537,
        polygons: 1090,
        lod: ['LOD_3'],
        name_work: 'dtl28',
        name_ua: 'Важіль направляючої клапана керування',
        group: 1150700,
        tooltip: {
            cz: 'Páka vedení ovládacího ventilu',
            en: 'Control valve guide lever',
            ua: 'Важіль направляючої клапана керування',
            bg: 'Лост на направляващата клапан за управление'
        }
    },
    {
        unitId: 1357,
        systemId: 4,
        id: '6e837aec-af59-474c-b505-da87f3845ba2',
        name: '发动机27',
        location: '[-0.1989585906267166, 0.059149231761693954, 0.06303471326828003]',
        vertices: 2973,
        polygons: 2085,
        lod: ['LOD_4'],
        name_work: 'dtl27',
        name_ua: 'Важіль направляючої клапана керування',
        group: 1150700,
        tooltip: {
            cz: 'Páka vedení ovládacího ventilu',
            en: 'Control valve guide lever',
            ua: 'Важіль направляючої клапана керування',
            bg: 'Лост на направляващата клапан за управление'
        }
    },
    {
        unitId: 1358,
        systemId: 4,
        id: '03ae619d-64c6-47aa-a2cf-41b1c14c3ac9',
        name: '发动机26',
        location: '[-0.18539811670780182, 0.06627842783927917, 0.060506515204906464]',
        vertices: 1121,
        polygons: 816,
        lod: ['LOD_3'],
        name_work: 'dtl26',
        name_ua: 'Втулка валу вихрових клапанів',
        group: 1150703,
        tooltip: {
            cz: 'Objímka hřídele vířivého ventilu',
            en: 'Valve shaft bushing of the swirl valves',
            ua: 'Втулка валу вихрових клапанів',
            bg: 'Втулка на вала на вихровия клапан'
        }
    },
    {
        unitId: 1359,
        systemId: 4,
        id: 'da5689be-aed0-486a-9ad3-eb0ec14a2586',
        name: '发动机25',
        location: '[-0.18029503524303436, 0.06627878546714783, 0.06051307171583176]',
        vertices: 458,
        polygons: 384,
        lod: ['LOD_3'],
        name_work: 'dtl25',
        name_ua: 'Втулка валу вихрових клапанів',
        group: 1150703,
        tooltip: {
            cz: 'Objímka hřídele vířivého ventilu',
            en: 'Valve shaft bushing of the swirl valves',
            ua: 'Втулка валу вихрових клапанів',
            bg: 'Втулка на вала на вихровия клапан'
        }
    },
    {
        unitId: 1360,
        systemId: 4,
        id: '97dec62e-0630-4858-9ff2-8657d86a79b9',
        name: '发动机24',
        location: '[-0.024280553683638573, 0.06627866625785828, 0.06050627678632736]',
        vertices: 56,
        polygons: 28,
        lod: ['LOD_3'],
        name_work: 'dtl24',
        name_ua: 'Вал вихрових клапанів',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel vírových ventilů',
            en: 'Swirl valve shaft',
            ua: 'Вал вихрових клапанів',
            bg: 'Вал на спирателните клапани'
        }
    },
    {
        unitId: 1361,
        systemId: 4,
        id: '59a12221-6aaa-4aeb-bb18-892e5d24cccc',
        name: '发动机23',
        location: '[-0.11874237656593323, 0.0635121762752533, 0.054438523948192596]',
        vertices: 4227,
        polygons: 3054,
        lod: ['LOD_3'],
        name_work: 'dtl23',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1362,
        systemId: 4,
        id: '7c93c22c-e1b9-4333-ba52-9ef7f6af1971',
        name: '发动机22',
        location: '[-0.04034797474741936, 0.06351909041404724, 0.054436080157756805]',
        vertices: 4227,
        polygons: 3054,
        lod: ['LOD_3'],
        name_work: 'dtl22',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1363,
        systemId: 4,
        id: '853fd967-44e1-40ea-b4d0-a5e8c29ddc6b',
        name: '发动机21',
        location: '[-0.11613588035106659, 0.14639946818351746, -0.06641075015068054]',
        vertices: 2053,
        polygons: 1032,
        lod: ['LOD_4'],
        name_work: 'dtl21',
        name_ua: 'Датчик абсолютного тиску повітря',
        group: 1180403,
        tooltip: {
            cz: 'Snímač absolutního tlaku vzduchu',
            en: 'Absolute air pressure sensor',
            ua: 'Датчик абсолютного тиску повітря',
            bg: 'Датчик за абсолютното налягане на въздуха'
        }
    },
    {
        unitId: 1364,
        systemId: 4,
        id: '72afc22b-36ee-40e3-82b1-2ce50af1d22f',
        name: '发动机20',
        location: '[-0.10405518114566803, 0.1472083032131195, -0.07607924938201904]',
        vertices: 1166,
        polygons: 715,
        lod: ['LOD_4'],
        name_work: 'dtl20',
        name_ua: 'Саморіз кріплення датчика абсолютного тиску',
        group: 1180403,
        tooltip: {
            cz: 'Samořezný šroub pro upevnění snímače absolutního tlaku',
            en: 'Self-tapping screw for absolute pressure sensor mounting',
            ua: 'Саморіз кріплення датчика абсолютного тиску',
            bg: 'Самонарезен винт за закрепване на сензора за абсолютен натиск'
        }
    },
    {
        unitId: 1365,
        systemId: 4,
        id: '292089e4-3135-4fa1-bf7e-01d7df23c55b',
        name: '发动机19',
        location: '[-0.2284693866968155, 0.11780223250389099, 0.06973516941070557]',
        vertices: 7823,
        polygons: 5384,
        lod: ['LOD_3'],
        name_work: 'dtl19',
        name_ua: 'Прокладка привода дросельної заслінки',
        group: 1110500,
        tooltip: {
            cz: 'Těsnění pohonu škrtící klapky',
            en: 'Gasket for throttle valve drive',
            ua: 'Прокладка привода дросельної заслінки',
            bg: 'Гарнитура за привода на дроселната клапа'
        }
    },
    {
        unitId: 1366,
        systemId: 4,
        id: '5e0cae3d-b786-4908-8010-e48126b6be48',
        name: '发动机13',
        location: '[-0.049640048295259476, 0.10547634959220886, -0.02017155848443508]',
        vertices: 69562,
        polygons: 44630,
        lod: ['LOD_4'],
        name_work: 'dtl13',
        name_ua: 'Впускний колектор',
        group: 1110800,
        tooltip: { cz: 'Sání', en: 'Intake manifold', ua: 'Впускний колектор', bg: 'Входен колектор' }
    },
    {
        unitId: 1367,
        systemId: 4,
        id: 'a2318a55-a1d5-40f0-b170-f742d91c9ded',
        name: '发动机12',
        location: '[-0.01930527575314045, 0.16233572363853455, 0.0010563972173258662]',
        vertices: 172,
        polygons: 100,
        lod: ['LOD_3'],
        name_work: 'dtl12',
        name_ua: 'Вал вихрових заслінок',
        group: 1150703,
        tooltip: {
            cz: 'Hřídel výfukové klapky',
            en: 'Exhaust flap shaft',
            ua: 'Вал вихрових заслінок',
            bg: 'Вал на изпускателния клапан'
        }
    },
    {
        unitId: 1368,
        systemId: 4,
        id: '39e436ce-0032-4f30-b605-1125a9b5c1a5',
        name: '发动机11',
        location: '[0.08717420697212219, 0.1623346507549286, 0.0010640266118571162]',
        vertices: 4074,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl11',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1369,
        systemId: 4,
        id: 'b08734fd-e764-42c3-9765-dccf92a0f12a',
        name: '发动机10',
        location: '[0.06505338847637177, 0.176678866147995, 0.024245912209153175]',
        vertices: 1271,
        polygons: 774,
        lod: ['LOD_4'],
        name_work: 'dtl10',
        name_ua: 'Саморіз',
        group: 1010501,
        tooltip: { cz: 'Samosvorný šroub', en: 'Self-tapping screw', ua: 'Саморіз', bg: 'Самонарезен' }
    },
    {
        unitId: 1370,
        systemId: 4,
        id: '22c9a8ca-bb6e-4cdb-8efa-f0c0468e6b34',
        name: '发动机09',
        location: '[0.030316917225718498, 0.1623346507549286, 0.0010640266118571162]',
        vertices: 4074,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl09',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1371,
        systemId: 4,
        id: '88c05284-111e-4dfa-9e59-a99e9bff4e07',
        name: '发动机08',
        location: '[-0.028263555839657784, 0.16331204771995544, 0.0006159187760204077]',
        vertices: 6201,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl08',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1372,
        systemId: 4,
        id: 'baa5fd3a-9148-4481-9599-775944a143b1',
        name: '发动机07',
        location: '[-0.028265083208680153, 0.1623346507549286, 0.0010640266118571162]',
        vertices: 4074,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl07',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1373,
        systemId: 4,
        id: 'ca428b0d-2206-4a23-bdac-0a013776a4ad',
        name: '发动机06',
        location: '[-0.10235169529914856, 0.1623348891735077, 0.0010640266118571162]',
        vertices: 4066,
        polygons: 3252,
        lod: ['LOD_3'],
        name_work: 'dtl06',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1374,
        systemId: 4,
        id: '27a9897f-5c01-49d0-9d60-c2b7c2ce0a28',
        name: '发动机05',
        location: '[-0.10235321521759033, 0.163312166929245, 0.0006159187760204077]',
        vertices: 6201,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl05',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1375,
        systemId: 4,
        id: '8b917a69-cd22-4f5c-b150-00676aba4fca',
        name: '发动机04',
        location: '[0.030316917225718498, 0.16331204771995544, 0.0006159187760204077]',
        vertices: 6201,
        polygons: 4888,
        lod: ['LOD_3'],
        name_work: 'dtl04',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1376,
        systemId: 4,
        id: '4e3b6add-b820-42b8-9175-b6ac96d8428f',
        name: '发动机03',
        location: '[0.08717405796051025, 0.16331180930137634, 0.0006158293690532446]',
        vertices: 6271,
        polygons: 4898,
        lod: ['LOD_3'],
        name_work: 'dtl03',
        name_ua: 'Вихрева заслінка',
        group: 1150702,
        tooltip: { cz: 'Výfuková klapka', en: 'Exhaust flap', ua: 'Вихрева заслінка', bg: 'Изпускателен клапан' }
    },
    {
        unitId: 1377,
        systemId: 4,
        id: '6b76d4e6-68d6-4e31-a1e9-57c2709d6cd5',
        name: 'Torus01',
        location: '[0.0629742294549942, 0.21528670191764832, -0.049657560884952545]',
        vertices: 8672,
        polygons: 16544,
        lod: ['LOD_4'],
        name_work: 'Torus01',
        name_ua: 'Турбіна',
        group: 1110401,
        tooltip: { cz: 'Turbína', en: 'Turbocharger', ua: 'Турбіна', bg: 'Турбина' }
    }
];
